/* eslint-disable complexity */
import { prop, propOr, path, pathOr, map, compose, prepend, reduce, add, filter, equals, head } from 'ramda'
import { createSelector } from 'reselect'

import moment from 'utils/moment'
import noun from 'utils/noun'

export const getIsLoaded = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

export const getIsLoading = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const getInvoice = createSelector(
  state => state,
  state => propOr({}, 'data', state)
)

export const startDateSelector = createSelector(
  state => state,
  state => propOr({}, 'startDate', state)
)

export const endDateSelector = createSelector(
  state => state,
  state => propOr({}, 'endDate', state)
)

export const invoiceItemsSelector = createSelector(
  state => propOr([], 'items', state),
  state => propOr([], 'statuses', state),
  state => propOr([], 'entities', state),
  (items, statuses, entities) =>
    map(item => {
      const date = prop('DATE', item)
      const dateDelivery = path(['DELIVERY', 'DATE'], item)
      const dateDeliveryPossible = path(['DELIVERY', 'DATE_POSSIBLE'], item)
      const typeId = propOr('', 'TYPE', item)
      const chainCode = pathOr('', ['STATUS_CHAIN', 'CHAIN'], item)
      const currentStatusCode = pathOr('', ['STATUS_CHAIN', 'GROUP'], item)
      const storeId = propOr('', 'STORE', item)
      const userId = propOr('', 'USER', item)
      const contractorId = propOr('', 'CONTRACTOR', item)
      const count = propOr('', 'CNT', item)
      const addressId = pathOr('', ['DELIVERY', 'ADDRESS'], item)
      const state = compose(
        head,
        filter(status => equals(propOr('', 'CODE', status), currentStatusCode)),
        pathOr([], ['STATUS_CHAIN', chainCode])
      )(entities)
      return {
        id: prop('ID', item),
        date: date ? moment(date, 'X').format('DD.MM.YYYY') : '',
        type: pathOr('MAIN', ['TYPE', typeId, 'CODE'], entities),
        state,
        store: pathOr({}, ['STORE', storeId], entities),
        num: propOr('', 'NUM', item),
        tax: propOr(0, 'TAX', item),
        cnt: `${count} ${noun(count, ['товар', 'товара', 'товаров'])}`,
        sum: add(
          Number(propOr(0, 'SUM', item)),
          Number(propOr(0, 'ADDED_SUM', item))
        ),
        loyaltyPoints: propOr('', 'LOYALTY_POINTS', item),
        user: pathOr({}, ['USER', userId], entities),
        contractor: pathOr({}, ['CONTRACTOR', contractorId], entities),
        documents: propOr({}, 'DOCUMENTS', item),
        address:
          pathOr('', ['ADDRESS', addressId, 'ADDRESS'], entities) ||
          pathOr('', ['DELIVERY', 'TYPE', 'NAME'], item),
        deliveryExpeditor: pathOr({}, ['DELIVERY', 'EXPEDITOR'], item),
        deliveryDate: dateDelivery
          ? moment(dateDelivery, 'X').format('DD.MM.YYYY')
          : '',
        deliveryDatePossible: dateDeliveryPossible
          ? moment(dateDeliveryPossible, 'X').format('DD.MM.YYYY')
          : '',
        isPoints: pathOr('', ['PAYMENTS', 'CODE'], item) === 'LOYALTY',
        actions: compose(
          filter(type => equals(type, 'confirm') || equals(type, 'delete')),
          propOr([], 'ACTIONS')
        )(item),
        isCanceled: equals(propOr('CANCELED', 'CODE', state), 'CANCELED')
      }
    })(items)
)

export const itemsCountSelector = createSelector(
  state => state,
  state => propOr(0, 'allItemsCount', state)
)

export const changedItemIdSelector = createSelector(
  state => state,
  state => propOr([], 'successChangedId', state)
)

export const limitSelector = createSelector(
  state => state,
  state => propOr(0, 'limit', state)
)

export const statusesSelector = createSelector(
  state => propOr([], 'statuses', state),
  state => propOr(0, 'tabItemsCount', state),
  (statuses, itemsCount) =>
    compose(
      prepend({
        id: 'all',
        title: 'Все',
        count: 0
      }),
      map(status => ({
        id: propOr('', 'CODE', status),
        title: propOr('', 'TITLE', status),
        count: equals(status.CODE, 'wait_confirm') ? itemsCount : 0 // счетчик только у статуса Ждет подтверждения
      }))
    )(statuses)
)

const prepareFilterItems = createSelector(
  items => items,
  items =>
    compose(
      prepend({
        optionValue: '0',
        title: 'Все'
      }),
      map(item => ({
        optionValue: propOr('', 'ID', item),
        title: propOr('', 'TITLE', item)
      }))
    )(items)
)

export const filtersSelector = createSelector(
  state => propOr([], 'filters', state),
  filters =>
    compose(
      reduce((acc, item) => ({ ...acc, ...item }), {}),
      map(filterItem => ({
        [propOr('', 'CODE', filterItem)]: {
          title: propOr('', 'TITLE', filterItem),
          items: prepareFilterItems(propOr([], 'VALUES', filterItem))
        }
      }))
    )(filters)
)
