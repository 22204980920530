import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, number, string, array, func } from 'prop-types'
import { prop, equals } from 'ramda'
import CustomScroll from 'react-custom-scroll'

import Icon from 'components/Icon'
import Link from 'components/Link'
import ItemMenu from 'components/MenuMainColumn/ItemMenu'
import { setAnalyticsProps } from 'helpers/metaTags'

import styles from './MenuMainColumn.scss'

class MenuMainColumn extends PureComponent {
  static propTypes = {
    catalogItems: array,
    isUserWithoutPassword: bool,
    isMobile: bool,
    isBookmark: bool,
    bookmarkAccess: bool,
    selectedItemId: number,
    mainColumnHeight: number,
    activeSection: string,
    bookmark: number,
    onSelect: func,
    selectCatalogItem: func,
    onHideCatalog: func
  }

  static defaultProps = {
    isMobile: false,
    catalogItems: [],
    onSelect: () => {},
    selectCatalogItem: () => {},
    onHideCatalog: () => {}
  }

  handleHideRightMenu = () => this.props.selectCatalogItem(undefined)

  render() {
    const {
      catalogItems,
      isBookmark,
      isUserWithoutPassword,
      selectedItemId,
      activeSection,
      onSelect,
      onHideCatalog,
      bookmark,
      bookmarkAccess,
      isMobile
    } = this.props
    return (
      <div className={cx(styles.mainColumn, {
        [styles.mainColumnMobile]: isMobile
      })}>
        {!isMobile && (
          <div
            role='presentation'
            className={cx(styles.closeButton, {
              [styles.closeButton_bookmark]: isBookmark
            })}
            onClick={onHideCatalog}
            {...setAnalyticsProps({ type: 'action', group: 'buttonCloseMenu' })}
          >
            <Icon icon='close'
              className={styles.closeButton_icon} />
            <div className={styles.closeButton_text}>Каталог товаров</div>
          </div>
        )}
        <div className={styles.scrolled}>
          <CustomScroll
            heightRelativeToParent='100%'
            className={cx({ [styles.customScroll]: isMobile })}
          >
            {!isBookmark && (
              <div
                role='presentation'
                className={styles.menuBlock}
                onFocus={this.handleHideRightMenu}
                onClick={onHideCatalog}
              >
                <Link to='/brand'>
                  <div className={styles.brandsBlock}>
                    <div className={styles.menuIcon}>
                      <Icon
                        icon='catalog-brand'
                        className={styles.menuIcon_brand}
                      />
                    </div>
                    <div className={styles.menuText}>Бренды</div>
                  </div>
                </Link>
              </div>
            )}
            {bookmarkAccess && !isBookmark && !isUserWithoutPassword && (
              <div
                role='presentation'
                className={styles.menuBlock}
                onFocus={this.handleHideRightMenu}
                onClick={onHideCatalog}
              >
                <Link to='/bookmark'>
                  <div className={styles.bookmarkBlockTitle}>
                    <div className={styles.menuIcon}>
                      <Icon
                        icon='bookmark'
                        className={styles.menuIcon_bookmark}
                      />
                    </div>
                    <div className={styles.menuTextBookmark}>Закладка</div>
                  </div>
                </Link>
              </div>
            )}
            {isBookmark && (
              <div
                role='presentation'
                className={styles.catalogBlock}
                onMouseOver={this.handleHideRightMenu}
                onFocus={this.handleHideRightMenu}
              >
                <div className={styles.catalogText}>
                  <Link to='/catalog'>
                    Обратно в каталог
                  </Link>
                </div>
              </div>
            )}
            <ul className={styles.list}>
              {catalogItems.map(item => (
                <ItemMenu
                  key={prop('ID', item)}
                  item={item}
                  onHideCatalog={onHideCatalog}
                  isBookmark={isBookmark}
                  bookmark={bookmark}
                  isSelected={equals(
                    prop('ID', item),
                    selectedItemId || activeSection
                  )}
                  onSelect={onSelect}
                  isMobile={isMobile}
                />
              ))}
            </ul>
          </CustomScroll>
        </div>
      </div>
    )
  }
}

export default MenuMainColumn
