import React from 'react'

import { string, func } from 'prop-types'
import { connect } from 'react-redux'

import Modal from 'containers/Modal'
import {
  cartErrorMessageError,
  cartErrorTitleError,
  cartResetError
} from 'redux/modules/productList'

import s from './CartError.scss'

const CartError = ({ title = 'Ошибка', message = '', onReset = () => {} }) => {
  return (
    <Modal id='cart-error'
      size='small'
      title={title}
      onClose={onReset}>
      <div className={s.error}>{message}</div>
    </Modal>
  )
}

CartError.propTypes = {
  onReset: func,
  title: string,
  message: string
}

export default connect(
  ({ productList }) => ({
    title: cartErrorTitleError(productList),
    message: cartErrorMessageError(productList)
  }),
  {
    onReset: cartResetError
  }
)(CartError)
