import { prop, map, path } from 'ramda'
import { createSelector } from 'reselect'

export const getProducts = createSelector(
  state => state,
  state => prop('products', state)
)

export const getIsLoading = createSelector(
  state => state,
  state => prop('isLoading', state)
)

export const getProduct = createSelector(
  state => state,
  state => prop('product', state)
)

export const getIsError = createSelector(
  state => state,
  state => prop('isError', state)
)

export const getCount = createSelector(
  state => state,
  state => prop('count', state)
)

export const getHeaders = createSelector(
  state => state,
  state => prop('headers', state)
)

export const getInvoices = createSelector(
  state => state,
  ({ invoices, entities }) => {
    return map(item => {
      const addressId = path(['DELIVERY', 'ADDRESS'], item)
      const contractorId = prop('CONTRACTOR', item)

      return {
        id: prop('ID', item),
        num: prop('NUM', item),
        address: path(['ADDRESS', addressId, 'ADDRESS'], entities),
        shipmentDate: path(['DATE'], item),
        deliveryDate: path(['DELIVERY', 'DATE'], item),
        contractor: path(['CONTRACTOR', contractorId, 'TITLE'], entities),
        sum: prop('SUM', item),
        cnt: prop('CNT', item)
      }
    }, invoices)
  }
)
