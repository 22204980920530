/* eslint-disable complexity */
import React, { useRef, useState } from 'react'

import cx from 'classnames'
import { length, pathOr, prop, propOr, toLower } from 'ramda'
import { Link } from 'react-router-dom'
import { useClickAway } from 'react-use'

import Icon from 'components/Icon'

import s from './Labels.scss'

const Label = props => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef(null)

  const type = prop('type', props)

  const typeIcon = { BOOKMARK: true }

  const isExpanded = length(propOr([], 'values', props)) > 1

  const text = isExpanded
    ? pathOr('', ['entities', type, 'TITLE'], props)
    : pathOr(
      '',
      ['entities', type, 'VALUES', pathOr(0, ['values', 0], props), 'TITLE'],
      props
    )

  const tooltip = isExpanded
    ? ''
    : pathOr(
      '',
      [
        'entities',
        type,
        'VALUES',
        pathOr(0, ['values', 0], props),
        'TOOLTIP'
      ],
      props
    )

  const backgroundColor = isExpanded
    ? pathOr('#888', ['entities', type, 'COLOR'], props)
    : pathOr(
      '#888',
      ['entities', type, 'VALUES', pathOr(0, ['values', 0], props), 'COLOR'],
      props
    )

  const getLinkByType = ({ groupType, itemCode }) => {
    switch (groupType) {
      case 'MARK':
        return `/catalog/mark/${itemCode}`
      case 'BOOKMARK':
        return `/${groupType}/${itemCode}/catalog/sections`
      default:
        return `/${groupType}/${itemCode}`
    }
  }

  const groupType = pathOr('', ['entities', type, 'TYPE'], props)

  const link = isExpanded
    ? ''
    : getLinkByType({ groupType, itemCode: pathOr('', ['values', 0], props) })

  const Tag = isExpanded ? 'div' : Link

  const onToggle = _isOpen => () => isExpanded && setIsOpen(_isOpen)

  useClickAway(ref, () => setIsOpen(false))

  return (
    <div
      className={cx(s.label, {
        [s[`label${toLower(type)}`]]: !!type,
        [s.labelExpanded]: isExpanded
      })}
      onMouseOver={onToggle(true)}
      onFocus={onToggle(true)}
      onMouseOut={onToggle(false)}
      onBlur={onToggle(false)}
    >
      <Tag
        className={s.name}
        style={{ backgroundColor }}
        title={tooltip}
        to={toLower(link)}
      >
        {pathOr(false, [type], typeIcon) ? (
          <Icon icon='bookmark'
            className={s.icon} />
        ) : (
          text
        )}
      </Tag>
      {isExpanded && (
        <div ref={ref}
          className={s.toggle}
          style={{ backgroundColor }}>
          <Icon icon='down'
            size='small'
            className={s.icon} />
        </div>
      )}
      <div
        className={cx(s.hint, {
          [s.hintOpen]: isOpen
        })}
      >
        {propOr([], 'values', props).map(item => (
          <Link
            to={toLower(getLinkByType({ groupType, itemCode: item }))}
            key={item}
          >
            <span className={s.hintItem}>{pathOr('', ['entities', type, 'VALUES', item, 'TITLE'], props)}</span>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Label
