import { equals, prop } from 'ramda'
import { createSelector } from 'reselect'

import { getClientId } from 'helpers/getTheme'

export const clientIdSelector = createSelector(
  state => prop('apiAuth', state),
  state => prop('router', state),
  (apiAuth, router) => getClientId({ apiAuth, router })
)

export const isOptClientSelector = createSelector(
  clientIdSelector,
  clientId => equals(clientId, 'relefopt')
)

export const isKdClientSelector = createSelector(
  clientIdSelector,
  clientId => equals(clientId, 'relefoffice')
)
