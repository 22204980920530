import React, {
  ChangeEvent,
  ChangeEventHandler,
  FC,
  KeyboardEvent,
  KeyboardEventHandler,
  ReactElement,
  useCallback,
  useRef,
  useState,
  SyntheticEvent
} from 'react'

import cx from 'classnames'
import { length, propOr } from 'ramda'
import { useClickAway } from 'react-use'

import Scrollbar from 'components/Scrollbar'
import Input from 'UI/Input'

import s from './Autocomplete.scss'

interface IkladrItem {}

interface IAutocomplete {
  items: IkladrItem[]
  onChange: (event: SyntheticEvent, key: boolean, params: {}) => void
  renderItem: (item: IkladrItem) => ReactElement
  onClickOutside?: () => void
  value: string
}

const Autocomplete: FC<IAutocomplete> = ({
  items,
  onChange,
  renderItem,
  value,
  onClickOutside,
  ...rest
}) => {
  const blockRef = useRef(null)

  const [selectedIndex, setSelectedIndex] = useState(-1)

  const [isShow, setIsShow] = useState(false)

  const itemsLength = length(items)

  const handleChangeInput: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event: ChangeEvent) => {
      onChange(event, false, { value, items, ...rest })
    },
    [onChange, value, items, rest]
  )

  const handleChange = useCallback(
    (index: number) => (event: SyntheticEvent) => {
      const selected: boolean = propOr('', String(index), items)

      onChange(event, selected, { value, items, ...rest })
      setSelectedIndex(index)
      setIsShow(false)
    },
    [onChange, value, items, rest]
  )

  const setSelected = useCallback(
    (val: number) => {
      const totalItems = itemsLength - 1
      let selected = val < 0 ? totalItems : val
      if (val > totalItems) {
        selected = 0
      }
      setSelectedIndex(selected)
    },
    [itemsLength]
  )

  const isListHidden = useCallback(() => {
    return !itemsLength || (itemsLength === 1 && items.indexOf(value) !== -1)
  }, [itemsLength, items, value])

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      const keys: Record<string, () => void> = {
        ArrowDown: () => setSelected(selectedIndex + 1),
        ArrowUp: () => setSelected(selectedIndex - 1),
        Enter: () => {
          event.preventDefault()
          event.stopPropagation()
          handleChange(selectedIndex)(event)
        }
      }
      const fn = keys[event.key]

      if (typeof fn === 'function' && !isListHidden()) {
        fn()
      }
    },
    [handleChange, isListHidden, setSelected, selectedIndex]
  )

  const onHideList = useCallback(() => {
    setIsShow(false)
    // если необходимо проверить корректность данных в поле
    if (onClickOutside) onClickOutside()
  }, [onClickOutside])

  const onShowList = useCallback(() => setIsShow(true), [])

  const renderListItem = (item: IkladrItem, i: number) => (
    <div
      key={i}
      role='presentation'
      className={cx(s.item, {
        [s.item_selected]: selectedIndex === i
      })}
      onMouseDown={handleChange(i)}
      itemProp='itemListElement'
    >
      <meta itemProp='position'
        content={String(i)} />
      {renderItem(item)}
    </div>
  )

  const renderList = () => (
    <div className={s.list}
      itemScope
      itemType='http://schema.org/ItemList'>
      <Scrollbar maxHeight={270}
        minScrollHeight={15}>
        {items.map(renderListItem)}
      </Scrollbar>
    </div>
  )

  useClickAway(blockRef, onHideList)

  return (
    <div
      role='presentation'
      className={s.autocomplete}
      ref={blockRef}
      onClick={onShowList}
    >
      <Input
        autoComplete='off'
        onChange={handleChangeInput}
        onKeyDown={handleKeyDown}
        value={value}
        {...rest}
      />

      {isShow && !!itemsLength && renderList()}
    </div>
  )
}

export default Autocomplete
