/* eslint-disable complexity */
import R, {
  identity,
  map,
  mergeDeepRight,
  pathOr,
  propOr,
  compose,
  last,
  set,
  lensPath,
  keys,
  reduce,
  toLower,
  values,
  prop
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'
import { createSelector } from 'reselect'

import getErrorMessage from 'helpers/getErrorMessage'
import { showModal } from 'redux/modules/modal'
import { fetchBaskets } from 'redux/modules/personal'
import {
  getCheckedItems,
  getRangeSliderFrom,
  getRangeSliderTo,
  getRangeSliderMin,
  getRangeSliderMax,
  getRangeSliderIsDrag
} from 'redux/modules/productListFilter'
import {
  setMultiplicity as setMultiplicityProduct,
  deleteProduct as setDeleteProduct,
  setChecked
} from 'redux/modules/products'

import {
  initialState as basketParamsInitialState,
  setBasketParams,
  handleSetBasketParams
} from './basketParams'
import handleFetchSuccess, {
  handlePutProduct,
  handlePutProducts
} from './handleFetchSuccess'
import {
  switchItemInGroup,
  switchItemInGroupSuccess,
  switchItemInGroupFailed,
  handleSwitchItemInGroup,
  handleSwitchItemInGroupSuccess,
  handleSwitchItemInGroupFailed
} from './switchItemsInGroup'

export const emptyGroup = {
  product: {},
  favorite: {},
  compare: {},
  section: {},
  analog: {},
  absolute_analog: {},
  similar: {},
  filter: {},
  waitList: {},
  search: {},
  basket: {},
  bookmarkBasket: {},
  files: {},
  basketCatalog: null
}

export { setBasketParams, getBasketParams } from './basketParams'

export {
  cartSelector,
  cartsSelector,
  cartsMainSelector,
  cartActionSelector,
  removedItemsSelector,
  isLoadingProductsSelector,
  productsSelector,
  cartErrorTitleError,
  cartErrorMessageError,
  cartsContractorSelector,
  cartsContractorLoadingSelector,
  targetContractorIdSelector
} from './selector'

const CURRY_ARITY = 3
const emptyArray = []
const emptyObject = {}

const initialState = {
  itemsById: {},
  metadata: {},
  itemsPropsById: {},
  loadById: emptyGroup,
  itemsIdByGroup: emptyGroup,
  analogTitles: {},
  filtersBySectionId: {},
  itemsCountsMap: emptyGroup,
  basketParams: basketParamsInitialState,
  cart: {
    isLoading: false,
    isLoaded: false,
    action: {
      isActive: false,
      value: [0],
      action: 'add',
      type: 'main',
      items: [],
      from: '',
      to: ''
    },
    cart: 0,
    carts: {
      main: [],
      bookmark: []
    },
    removedItems: {},
    error: {}
  },
  targetContractorId: null,
  cartsContractor: {}
}

export const CART_ACTION = ['add', 'move', 'remove']
// const CART_TYPE = ['main', '918', 'bookmark', 'delay']

export const getItemById = R.curry((id, state) =>
  R.pathOr(emptyObject, ['itemsById', id], state)
)
export const getLoadByGroupAndId = R.curry((group, id, state) =>
  R.pathOr(emptyObject, ['loadById', group, id], state)
)
export const getItemsIdByGroupAndId = R.curry((group, id, state) =>
  R.pathOr(emptyArray, ['itemsIdByGroup', group, id], state)
)
export const getStatusByGroupAndId = R.curry((status, group, id, state) =>
  R.pathOr(false, [status], getLoadByGroupAndId(group, id, state))
)
export const getItemsByGroupAndId = R.curryN(CURRY_ARITY, (group, id, state) =>
  getItemsIdByGroupAndId(group, id, state).map(itemId =>
    getItemById(itemId, state)
  )
)

export const getItemCountByGroupAndId = R.curry((group, id, state) =>
  R.pathOr(0, ['itemsCountsMap', group, id], state)
)
export const getItemCountByBasketGroupAndId = R.curry(
  (group, basketGroup, id, state) =>
    R.pathOr(0, ['itemsCountsMap', group, basketGroup, id], state)
)
export const getLoyaltyItemCountByGroupAndId = R.curry((group, id, state) =>
  Math.max(1, R.pathOr(0, ['itemsCountsMap', group, id], state))
)

export const getLastSum = getStatusByGroupAndId('lastSum')
export const getIsLoadedByGroupAndId = getStatusByGroupAndId('isLoaded')
export const getIsFailedByGroupAndId = getStatusByGroupAndId('isFailed')
export const getIsIncorrectItemId = (group, id, state) =>
  (getIsLoadedByGroupAndId(group, id, state) &&
    R.isEmpty(getItemById(id, state))) ||
  getIsFailedByGroupAndId(group, id, state)
export const getSectionItemCountById = getItemCountByGroupAndId('section')
export const getLoyaltySectionItemCountById =
  getLoyaltyItemCountByGroupAndId('section')
export const getItemsIdByBasketType = getItemsIdByGroupAndId('basket')
export const getItemsIdByBasket = getItemsIdByGroupAndId('basket', 0)
export const getItemsIdByLoyaltyBasket = getItemsIdByGroupAndId(
  'loyaltyBasket',
  0
)
export const getItemsIdByBookmarkBasket =
  getItemsIdByGroupAndId('bookmarkBasket')
export const getItemsIdByWaitList = getItemsIdByGroupAndId('waitList', 0)
export const getIsProductLoaded = (id, state) => {
  const item = getItemById(id, state)
  const isLoaded = getIsLoadedByGroupAndId('product', id, state)

  return Boolean(isLoaded && item && item.DETAIL_PICTURE_PATH)
}
// Селектор, определяющий, загружался ли товар отдельным запросом
export const getIsProductFullLoaded = (id, state) => {
  const item = getItemById(id, state)
  const isLoaded = getIsLoadedByGroupAndId('product', id, state)
  return Boolean(isLoaded && item && R.path(['SECTION', 'ID'], item))
}
export const getBasketLastSum = getLastSum('basket', 0)
export const getLoyaltyBasketLastSum = getLastSum('loyaltyBasket', 0)
export const getBookmarkBasketLastSum = getLastSum('bookmarkBasket')
export const getIsSectionLoaded = getIsLoadedByGroupAndId('section')
export const getIsFavoriteLoaded = getIsLoadedByGroupAndId('favorite', 0)
export const getIsCompareLoaded = getIsLoadedByGroupAndId('compare', 0)
export const getIsFilterLoaded = getIsLoadedByGroupAndId('filter')
export const getIsSearchLoaded = getIsLoadedByGroupAndId('search')
export const getIsLoyaltySearchLoaded = getIsLoadedByGroupAndId('loyaltySearch')
export const getIsBasketTypeLoaded = getIsLoadedByGroupAndId('basket')
export const getIsBasketLoaded = getIsLoadedByGroupAndId('basket')
export const getIsLoyaltyBasketLoaded = getIsLoadedByGroupAndId(
  'basket',
  'loyalty'
)
export const getIsBookmarkBasketLoaded =
  getIsLoadedByGroupAndId('bookmarkBasket')
export const getIsBookmarkSearchLoaded =
  getIsLoadedByGroupAndId('bookmarkSearch')
export const getIsAnalogsLoaded = getIsLoadedByGroupAndId('analog')
export const getIsExpendablesLoaded = getIsLoadedByGroupAndId('expendables')
export const getItemAnalogsById = getItemsByGroupAndId('analog')
export const getItemAbsoluteAnalogsById =
  getItemsByGroupAndId('absolute_analog')
export const getItemsByCatalogId = getItemsByGroupAndId('section')
export const getItemsByFilterId = getItemsByGroupAndId('filter')
export const getItemsByCarouselId = getItemsByGroupAndId('carousel')
export const getItemsByBasketType = getItemsByGroupAndId('basket')
export const getItemsByBasket = getItemsByGroupAndId('basket', 0)
export const getItemsByWaitListBasket = getItemsByGroupAndId('waitList', 0)
export const getItemsByLoyaltyBasket = getItemsByGroupAndId('basket', 'loyalty')
export const getItemsByBookmarkBasket = getItemsByGroupAndId('bookmarkBasket')
export const getItemsByBookmarkSearch = getItemsByGroupAndId('bookmarkSearch')
export const getItemsByLoyaltySearch = getItemsByGroupAndId('loyaltySearch')
export const getIsFilesLoaded = (id, productList) =>
  R.pathOr(false, ['files', id, 'isLoaded'], productList)
export const getFiltersByGroupAndId = (group, id, state) =>
  R.pathOr(emptyArray, [group, id], state.filtersBySectionId)
export const productBarcodesSelector = (id, productList) =>
  R.pathOr([], ['itemsById', id, 'BARCODES'], productList)

// селектор для сопутствующих товаров в карточке товара
export const productAnalogsCarouselsSelector = createSelector(
  state => state,
  ({ productId, productList }) => {
    const titles = R.propOr({}, 'analogTitles', productList)
    return R.reduce(
      (acc, key) => {
        const itemsData = getItemsByGroupAndId(key, productId, productList)
        const analogsByKey = R.isEmpty(itemsData)
          ? {}
          : { [key]: { title: R.propOr('', key, titles), items: itemsData } }
        return {
          ...acc,
          ...analogsByKey
        }
      },
      {},
      R.keys(titles)
    )
  }
)

export const filtersSelector = createSelector(
  store => R.path(['productListFilter', 'baseFilter'], store),
  store => R.path(['productListFilter', 'loyaltyBaseFilter'], store),
  (store, props) => props.type,
  (store, props) =>
    props.bookmark ? `${props.bookmark}_${props.id}` : props.id,
  store => store.productList,
  (baseFilter, loyaltyBaseFilter, type, typeGroup, productList) =>
    R.indexBy(
      R.prop('id'),
      [baseFilter, loyaltyBaseFilter].concat(
        getFiltersByGroupAndId(type, typeGroup, productList)
      )
    )
)

export const filtersSortedSelector = createSelector(
  filtersSelector,
  (state, props) => props.onlyFilters,
  (filters, onlyFilters) => {
    const filtered =
      onlyFilters && onlyFilters.length
        ? filters.filter(item => onlyFilters.indexOf(item.id) !== -1)
        : filters
    return R.sortBy(R.prop('sort'), filtered)
  }
)

export const checkedItemsSelector = createSelector(
  filtersSelector,
  state => getCheckedItems(state.productListFilter),
  (filters, checkedObject) =>
    R.reduce(
      (acc, key) => {
        const query = R.path([key, 'query'], filters)
        const items = checkedObject[key]
        return items.length === 0 ? acc : { ...acc, [query]: items }
      },
      {},
      R.keys(checkedObject)
    )
)

export const queriesSelector = createSelector(filtersSelector, filters =>
  R.reduce(
    (acc, id) => ({ ...acc, [R.path([id, 'query'], filters)]: id }),
    {},
    R.keys(filters)
  )
)

export const rangeFromSelector = createSelector(
  state => state.productListFilter,
  productListFilter => getRangeSliderFrom(productListFilter)
)

export const metaDataSelector = createSelector(
  state => state.productList,
  productList => R.prop('metadata', productList)
)

export const rangeToSelector = createSelector(
  state => state.productListFilter,
  productListFilter => getRangeSliderTo(productListFilter)
)

export const rangeIsDragSelector = createSelector(
  state => state.productListFilter,
  productListFilter => getRangeSliderIsDrag(productListFilter)
)

export const priceRangeSelector = createSelector(
  state => state.productListFilter,
  rangeFromSelector,
  rangeToSelector,
  rangeIsDragSelector,
  (productListFilter, rangeFrom, rangeTo, rangeIsDrag) => {
    const rangeMin = getRangeSliderMin(productListFilter)
    const rangeMax = getRangeSliderMax(productListFilter)
    const priceRange = rangeIsDrag
      ? []
      : [
        rangeFrom <= rangeMin ? null : rangeFrom,
        rangeTo >= rangeMax ? null : rangeTo
      ]
    return priceRange
  }
)

export { switchItemInGroup, switchItemInGroupSuccess }

export const fetch = createAction('productList/FETCH')
export const fetchSuccess = createAction('productList/FETCH_SUCCESS')
const fetchFailure = createAction('productList/FETCH_FAILURE')
export const putProduct = createAction('productList/PUT_PRODUCT')
export const putProducts = createAction('productList/PUT_PRODUCTS')
export const setItemCounts = createAction('productList/SET_ITEMS_COUNTS')
export const setMetaData = createAction('productList/SET_METADATA')
export const selectItems = createAction('productList/SELECT_ITEMS')
export const resetBasket = createAction('productList/RESET_BASKET')
export const clearBasket = createAction('productList/CLEAR_BASKET')
const clearBasketSuccess = createAction('productList/CLEAR_BASKET_SUCCESS')
const clearBasketFailure = createAction('productList/CLEAR_BASKET_FAILURE')
export const clearCompare = createAction('productList/CLEAR_COMPARE')
export const setMultiplicity = createAction('productList/SET_MULTIPLICITY')
export const deleteProduct = createAction('productList/DELETE_PRODUCT')
export const setProduct = createAction('productList/SET_PRODUCT')
export const fetchFiles = createAction('productList/FETCH_FILES')
export const fetchFilesSuccess = createAction('productList/FETCH_FILES_SUCCESS')
export const fetchFilesFailure = createAction('productList/FETCH_FILES_FAILURE')
export const fetchModifiers = createAction('productList/FETCH_MODIFIERS')
const fetchModifiersSuccess = createAction('productList/FETCH_MODIFIERS_SUCCESS')
const fetchModifiersFailure = createAction('productList/FETCH_MODIFIERS_FAILURE')
export const setCartActive = createAction('cart/SET_CART_ACTIVE')
export const setRemovedItems = createAction('cart/SET_CART_ACTION')
export const setCartAction = createAction('cart/SET_CART_ACTION')
export const setCartActionStatus = createAction('cart/SET_CART_ACTION_STATUS')
export const setCartReset = createAction('cart/SET_CART_RESET')
export const setCarts = createAction('cart/SET_CARTS')
export const cartAdd = createAction('cart/CART_ADD')
const cartAddSuccess = createAction('cart/CART_ADD_SUCCESS')
const cartAddFailure = createAction('cart/CART_ADD_FAILURE')
export const cartUpdate = createAction('cart/CART_UPDATE')
const cartUpdateSuccess = createAction('cart/CART_UPDATE_SUCCESS')
const cartUpdateFailure = createAction('cart/CART_UPDATE_FAILURE')
export const cartSelect = createAction('cart/CART_SELECT')
const cartSelectSuccess = createAction('cart/CART_SELECT_SUCCESS')
const cartSelectFailure = createAction('cart/CART_SELECT_FAILURE')
export const cartDelete = createAction('cart/CART_DELETE')
const cartDeleteSuccess = createAction('cart/CART_DELETE_SUCCESS')
const cartDeleteFailure = createAction('cart/CART_DELETE_FAILURE')
export const fetchCartItems = createAction('cart/FETCH_CART_ITEMS')
const fetchCartItemsSuccess = createAction('cart/FETCH_CART_ITEMS_SUCCESS')
const fetchCartItemsFailure = createAction('cart/FETCH_CART_ITEMS_FAILURE')
export const cartResetError = createAction('cart/CART_RESET_ERROR')
export const fetchCartsContractor = createAction('cart/FETCH_CARTS_CONTRACTOR')
export const fetchCartsContractorSuccess = createAction(
  'cart/FETCH_CARTS_CONTRACTOR_SUCCESS'
)
const fetchCartsContractorFailure = createAction(
  'cart/FETCH_CARTS_CONTRACTOR_FAILURE'
)
export const setTargetContractorId = createAction(
  'cart/SET_TARGET_CONTRACTOR_ID'
)

const request =
  ({ clientApi }) =>
    ({ id, type, catalog = 'main', params = {} }) => {
      const contractorId = clientApi.getContractorId()
      const defaultBasketParams = { offset: 0 }
      const defaultParams = {
        basket: defaultBasketParams,
        orders: {
          filter: 'sale_cnt'
        },
        filter: {
          [`filter[${id}]`]: 'Y'
        },
        carousel: {},
        search: {
          q: id
        },
        loyaltySearch: {
          q: id
        },
        loyaltyBasket: {
          to: 'loyalty'
        },
        newDetails: {
          type: 'catalog'
        }
      }

      let keyId = id
      if (type === 'filter' && catalog !== 'main') {
        keyId = `${id}_${catalog}`
      }
      if (type === 'basket') {
        keyId = catalog || id
      }
      if (catalog === 'bookmark' && !R.includes(type, ['product', 'similar'])) {
        keyId = [catalog, params.bookmark_id || params.value]
      }

      const idsProduct =
      type === 'newDetails'
        ? id.map((item, i) => `filter[ID][${i}]=${item}`).join('&')
        : ''

      const catalogUrl = `${catalog}${
        catalog === 'bookmark' ? `/${params.bookmark_id}` : ''
      }`

      const url = {
        product: `/v3/catalog/${catalogUrl}/product/${id}/`,
        similar: `/v3/catalog/${catalogUrl}/product/${id}/similar/`,
        section: `/v3/catalog/${catalogUrl}/section/${id}/`,
        orders: '/v3/catalog/main/section/',
        basket: `/v3/sale/basket/${catalog}/get/`,
        favorite: '/v3/personal/favorite/catalog/',
        compare: '/v3/personal/compare/catalog/',
        search: '/v3/catalog/main/search/',
        loyaltySearch: '/v3/catalog/loyalty/search/catalog/',
        brand: `/v3/catalog/main/brand/${id}/products`,
        newDetails: `/v3/catalog/main/products/?${idsProduct}`,
        loyalty:
        id === 'all'
          ? '/v3/catalog/loyalty/section/'
          : `/v3/catalog/loyalty/section/${id}/`,
        loyaltyBasket: '/v3/sale/basket/loyalty/get/',
        loyaltyProduct: `/v3/catalog/loyalty/product/${id}/`,
        bookmark: `/v3/catalog/bookmark/section/${id}/?bookmark_id=${params.bookmark}`,
        bookmarkBasket: '/v3/catalog/bookmark/basket/',
        bookmarkSearch: `/v3/catalog/${catalogUrl}/search/`,
        carousel:
        catalog === 'loyalty'
          ? '/v3/catalog/loyalty/carousel/'
          : '/v3/catalog/main/carousel/',
        move: `/v3/sale/basket/${params.to}/move/`,
        copy: `/v3/sale/basket/${params.to}/copy/`
      }

      return clientApi
        .get(url[type], {
          params: {
            ...defaultParams[type],
            ...params,
            contractor_id: contractorId
          }
        })
        .then(data =>
          fetchSuccess({
            data,
            id: keyId,
            type,
            catalog,
            params,
            actionParams: {
              id,
              type,
              catalog,
              params
            }
          })
        )
        .catch(data =>
          fetchFailure({
            data,
            id: keyId,
            type,
            catalog
          })
        )
    }

const handleFetch = (
  state,
  { id, type, catalog = 'main', params = {} },
  { clientApi }
) => {
  const newState = R.set(
    R.lensPath(['loadById', type, id]),
    {
      isLoading: true,
      isLoaded: false
    },
    state
  )

  const effects = []

  effects.push(
    Effects.promise(request({ clientApi }), {
      id,
      type,
      catalog,
      params
    })
  )

  return loop(
    {
      ...newState,
      basketCatalog: type === 'basket' ? catalog : state.basketCatalog
    },
    Effects.batch(effects)
  )
}

const handleFetchFailure = (state, { id, type }) => {
  const loadLens = R.lensPath(['loadById', type, id])
  return R.set(
    loadLens,
    {
      isLoading: false,
      isLoaded: false,
      isFailed: true
    },
    state
  )
}

const handleSetItemCounts = (state, { type, items, catalog }) => {
  let itemsCountsMapLens = R.lensPath(['itemsCountsMap', type])
  if (type === 'basket') {
    itemsCountsMapLens = R.lensPath(['itemsCountsMap', type, catalog])
  }
  return R.over(
    itemsCountsMapLens,
    current => ({
      ...current,
      ...items
    }),
    state
  )
}

const handleSetMetaData = (state, payload) => ({
  ...state,
  metadata: {
    ...payload
  }
})

const requestClearBasket =
  ({ clientApi }) =>
    (payload = {}) => {
      const contractorId = clientApi.getContractorId()
      const catalog = R.propOr('main', 'catalog', payload)
      const params = R.propOr({}, 'params', payload)
      const id = R.prop('id', payload)
      return clientApi
        .post(`/v3/sale/basket/${catalog}/clear/`, {
          params: {
            contractor_id: contractorId,
            ...params
          }
        })
        .then(() =>
          clearBasketSuccess({
            id,
            type: 'basket',
            params,
            catalog
          })
        )
        .catch(clearBasketFailure)
    }

const handleClearBasket = (state, payload, { clientApi }) =>
  loop(state, Effects.promise(requestClearBasket({ clientApi }), payload))

const handleClearBasketSuccess = (
  state,
  { id, type = 'basket', params, catalog }
) =>
  loop(
    {
      ...state
    },
    Effects.call(fetch, {
      id,
      type,
      params,
      catalog
    })
  )

const handleClearBasketFailure = state => ({
  ...state
})

const handleClearCompare = state => ({
  ...state,
  itemsById: R.map(
    item => ({
      ...item,
      COMPARE: false
    }),
    state.itemsById
  )
})

const handleResetBasket = state => ({
  ...state,
  itemsIdByGroup: {
    ...state.itemsIdByGroup,
    basket: []
  }
})

const handleSetMultiplicity = (state, { id, value }) =>
  loop(
    {
      ...R.set(R.lensPath(['itemsById', id, 'isNotMultiple']), !value, state)
    },
    Effects.call(setMultiplicityProduct, { id, value })
  )

const handleDeleteProduct = (state, { id = [], type }) => {
  let newState = { ...state }
  R.map(item => {
    newState = R.set(
      R.lensPath(['itemsById', item, 'BASKETS', type]),
      {},
      newState
    )
    return true
  })(id)
  newState = R.set(
    R.lensPath(['itemsIdByGroup', 'basket', R.toLower(type)]),
    R.without(
      id,
      R.pathOr([], ['itemsIdByGroup', 'basket', R.toLower(type)], newState)
    ),
    newState
  )
  const effects = id.map(item =>
    Effects.call(setDeleteProduct, { id: item, type })
  )
  return loop(
    {
      ...newState
    },
    Effects.batch(effects)
  )
}

const handleSetProduct = (state, { id = [], type }) =>
  R.set(R.lensPath(['itemsIdByGroup', 'basket', R.toLower(type)]), id, state)

const requestFiles =
  ({ clientApi }) =>
    id =>
      clientApi
        .get(`/v3/catalog/main/product/${id}/files/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(data => fetchFilesSuccess({ ...data, id }))
        .catch(() => fetchFilesFailure({ id }))

const handleFetchFiles = (state, { id }, { clientApi }) =>
  loop(
    {
      ...state,
      files: {
        ...state.files,
        [id]: {
          isLoaded: false,
          isLoading: true,
          cert: [],
          photos: [],
          sprite: ''
        }
      }
    },
    Effects.promise(requestFiles({ clientApi }), id)
  )

const handleFetchFilesSuccess = (state, { data, id }) => ({
  ...state,
  files: {
    ...state.files,
    [id]: {
      isLoaded: true,
      isLoading: false,
      cert: R.pathOr([], ['response', 'CERTIFICATIONS'], data),
      photos: R.pathOr([], ['response', 'PHOTOS'], data),
      sprite: R.pathOr('', ['response', 'SPRITE'], data)
    }
  }
})

const handleFetchFilesFailure = (state, { id }) => ({
  ...state,
  files: {
    ...state.files,
    [id]: {
      isLoaded: true,
      isLoading: false,
      cert: [],
      photos: [],
      sprite: ''
    }
  }
})

const requestModifiers = ({ clientApi }) => ({ id, group }) =>
  clientApi
    .get(`/v3/catalog/main/product/${id}/modifiers/`, {
      params: {
        contractor_id: clientApi.getContractorId(),
        group
      }
    })
    .then(data => fetchModifiersSuccess({ ...data, id }))
    .catch(() => fetchModifiersFailure({ id }))

const handleFetchModifiers = (state, { id, group }, { clientApi }) =>
  loop(
    {
      ...state,
      modifiers: {
        ...state.files,
        [id]: {
          isLoaded: false,
          isLoading: true,
          modifiers: []
        }
      }
    },
    Effects.promise(requestModifiers({ clientApi }), ({ id, group }))
  )

const handleFetchModifiersSuccess = (state, { data, id }) => ({
  ...state,
  modifiers: {
    ...state.files,
    [id]: {
      isLoaded: true,
      isLoading: false,
      modifiers: R.pathOr([], ['response'], data)
    }
  }
})

const handleFetchModifiersFailure = (state, { id }) => ({
  ...state,
  modifiers: {
    ...state.files,
    [id]: {
      isLoaded: true,
      isLoading: false,
      modifiers: []
    }
  }
})

const handleSetCartActive = (state, cart) => ({
  ...state,
  cart: {
    ...state.cart,
    cart
  }
})

const handleSetRemovedItems = (state, payload) => {
  const items = mergeDeepRight(propOr({}, 'removedItems', state), payload)
  const itemsChecked = map(() => identity(false), items)
  return loop(
    {
      ...state,
      cart: {
        ...state.cart,
        removedItems: items
      }
    },
    Effects.call(setChecked, itemsChecked)
  )
}

const handleSetCartAction = (state, payload = {}) => ({
  ...state,
  cart: {
    ...state.cart,
    action: payload
  }
})

const handleSetCartActionStatus = (state, payload = {}) =>
  set(lensPath(['cart', 'action', 'isActive']), payload, state)

const handleSetCartReset = state =>
  set(lensPath(['cart', 'action', 'isActive']), false, state)

const handleSetCarts = (state, payload) => ({
  ...state,
  cart: {
    ...state.cart,
    carts: compose(
      reduce(
        (acc, item) => ({
          ...acc,
          [toLower(item)]: values(
            pathOr([], ['carts', item, 'VALUES'], payload)
          )
        }),
        {}
      ),
      keys,
      propOr({}, 'carts')
    )(payload)
  }
})

const requestCartAdd =
  ({ clientApi }) =>
    ({ catalog = 'main', ...params }) =>
      clientApi
        .post(`/v3/sale/basket/${catalog}/value/add/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(cartAddSuccess)
        .catch(cartAddFailure)

const handleCartAdd = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestCartAdd({ clientApi }), payload)
  )

const handleCartAddSuccess = (state, payload) => {
  const cart = Number(
    compose(propOr(0, 'ID'), last, pathOr([], ['data', 'response']))(payload)
  )
  const effects = []
  effects.push(Effects.call(fetchBaskets, {}))
  effects.push(Effects.call(fetchCartsContractor, {}))
  effects.push(Effects.call(setCartActive, cart))
  return loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: true
      }
    },
    Effects.batch(effects)
  )
}

const handleCartAddFailure = (state, payload) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: false,
        error: {
          title: 'Ошибка добавления корзины',
          message: getErrorMessage(payload)
        }
      }
    },
    Effects.call(showModal, 'cart-error')
  )

const requestCartUpdate =
  ({ clientApi }) =>
    ({ catalog = 'main', ...params }) =>
      clientApi
        .post(`/v3/sale/basket/${catalog}/value/update/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(cartUpdateSuccess)
        .catch(cartUpdateFailure)

const handleCartUpdate = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestCartUpdate({ clientApi }), payload)
  )

const handleCartUpdateSuccess = state =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: true
      }
    },
    Effects.call(fetchBaskets, {})
  )

const handleCartUpdateFailure = (state, payload) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: false,
        error: {
          title: 'Ошибка обновления корзины',
          message: getErrorMessage(payload)
        }
      }
    },
    Effects.call(showModal, 'cart-error')
  )

const requestCartSelect =
  ({ clientApi }) =>
    ({ catalog = 'main', ...params }) =>
      clientApi
        .get(`/v3/sale/basket/${catalog}/value/select/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(cartSelectSuccess)
        .catch(cartSelectFailure)

const handleCartSelect = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestCartSelect({ clientApi }), payload)
  )

const handleCartSelectSuccess = (state, payload) => ({
  ...state,
  cart: {
    ...state.cart,
    isLoading: false,
    isLoaded: true,
    carts: pathOr([], ['data', 'items'], payload)
  }
})

const handleCartSelectFailure = (state, payload) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: false,
        error: {
          title: 'Ошибка выбора корзины',
          message: getErrorMessage(payload)
        }
      }
    },
    Effects.call(showModal, 'cart-error')
  )

const requestCartDelete =
  ({ clientApi }) =>
    ({ catalog = 'main', ...params }) =>
      clientApi
        .post(`/v3/sale/basket/${catalog}/value/delete/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(cartDeleteSuccess)
        .catch(cartDeleteFailure)

const handleCartDelete = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestCartDelete({ clientApi }), payload)
  )

const handleCartDeleteSuccess = state =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: true
      }
    },
    Effects.call(fetchBaskets, {})
  )

const handleCartDeleteFailure = (state, payload) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoading: false,
        isLoaded: false,
        error: {
          title: 'Ошибка удаления корзины',
          message: getErrorMessage(payload)
        }
      }
    },
    Effects.call(showModal, 'cart-error')
  )

const requestCartItems =
  ({ clientApi }) =>
    ({ catalog = 'main', ...params }) =>
      clientApi
        .get(`/v3/sale/basket/${catalog}/get/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(fetchCartItemsSuccess)
        .catch(fetchCartItemsFailure)

const handleFetchCartItems = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      cart: {
        ...state.cart,
        isLoadingProducts: true,
        isLoadedProducts: false,
        products: []
      }
    },
    Effects.promise(requestCartItems({ clientApi }), payload)
  )

const handleFetchCartItemsSuccess = (state, payload) => ({
  ...state,
  cart: {
    ...state.cart,
    isLoadingProducts: false,
    isLoadedProducts: true,
    products: pathOr([], ['data', 'response', 'ITEMS'], payload)
  }
})

const handleFetchCartItemsFailure = state =>
  loop({
    ...state,
    cart: {
      ...state.cart,
      isLoadingProducts: false,
      isLoadedProducts: false,
      products: []
    }
  })

const handleCartResetError = state => ({
  ...state,
  cart: {
    ...state.cart,
    error: {}
  }
})

const requestCartsContractor =
  ({ clientApi }) =>
    ({ catalog = 'main', contractorId, ...params }) =>
      clientApi
        .get(`/v3/sale/basket/${catalog}/get/`, {
          params: {
            ...params,
            contractor_id: contractorId || clientApi.getContractorId()
          }
        })
        .then(data => fetchCartsContractorSuccess({ contractorId, ...data }))
        .catch(fetchCartsContractorFailure)

const handleFetchCartsContractor = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      cartsContractor: {
        ...state.cartsContractor,
        [prop('contractorId', payload) || clientApi.getContractorId()]: {
          isLoading: true,
          isLoaded: false,
          carts: {}
        }
      }
    },
    Effects.promise(requestCartsContractor({ clientApi }), payload)
  )

const handleFetchCartsContractorSuccess = (
  state,
  { contractorId, ...payload },
  { clientApi }
) => {
  return {
    ...state,
    cartsContractor: {
      ...state.cartsContractor,
      [contractorId || clientApi.getContractorId()]: {
        isLoading: false,
        isLoaded: true,
        carts: pathOr({}, ['data', 'response', 'NAV', 'BASKETS'], payload)
      }
    }
  }
}

const handleFetchCartsContractorFailure = state =>
  loop({
    ...state
  })

const handleSetTargetContractorId = (state, payload) => ({
  ...state,
  targetContractorId: payload
})

const reducer = createReducer(on => {
  on(resetBasket, handleResetBasket)
  on(clearBasket, handleClearBasket)
  on(clearBasketSuccess, handleClearBasketSuccess)
  on(clearBasketFailure, handleClearBasketFailure)
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(switchItemInGroup, handleSwitchItemInGroup)
  on(switchItemInGroupSuccess, handleSwitchItemInGroupSuccess)
  on(switchItemInGroupFailed, handleSwitchItemInGroupFailed)
  on(setItemCounts, handleSetItemCounts)
  on(setMetaData, handleSetMetaData)
  on(putProduct, handlePutProduct)
  on(putProducts, handlePutProducts)
  on(setBasketParams, handleSetBasketParams)
  on(clearCompare, handleClearCompare)
  on(setMultiplicity, handleSetMultiplicity)
  on(deleteProduct, handleDeleteProduct)
  on(setProduct, handleSetProduct)
  on(fetchFiles, handleFetchFiles)
  on(fetchFilesSuccess, handleFetchFilesSuccess)
  on(fetchFilesFailure, handleFetchFilesFailure)
  on(fetchModifiers, handleFetchModifiers)
  on(fetchModifiersSuccess, handleFetchModifiersSuccess)
  on(fetchModifiersFailure, handleFetchModifiersFailure)
  on(setCartActive, handleSetCartActive)
  on(setRemovedItems, handleSetRemovedItems)
  on(setCartAction, handleSetCartAction)
  on(setCartActionStatus, handleSetCartActionStatus)
  on(setCartReset, handleSetCartReset)
  on(setCarts, handleSetCarts)
  on(cartAdd, handleCartAdd)
  on(cartAddSuccess, handleCartAddSuccess)
  on(cartAddFailure, handleCartAddFailure)
  on(cartUpdate, handleCartUpdate)
  on(cartUpdateSuccess, handleCartUpdateSuccess)
  on(cartUpdateFailure, handleCartUpdateFailure)
  on(cartSelect, handleCartSelect)
  on(cartSelectSuccess, handleCartSelectSuccess)
  on(cartSelectFailure, handleCartSelectFailure)
  on(cartDelete, handleCartDelete)
  on(cartDeleteSuccess, handleCartDeleteSuccess)
  on(cartDeleteFailure, handleCartDeleteFailure)
  on(fetchCartItems, handleFetchCartItems)
  on(fetchCartItemsSuccess, handleFetchCartItemsSuccess)
  on(fetchCartItemsFailure, handleFetchCartItemsFailure)
  on(cartResetError, handleCartResetError)
  on(fetchCartsContractor, handleFetchCartsContractor)
  on(fetchCartsContractorSuccess, handleFetchCartsContractorSuccess)
  on(fetchCartsContractorFailure, handleFetchCartsContractorFailure)
  on(setTargetContractorId, handleSetTargetContractorId)
}, initialState)

export default reducer
