/* eslint-disable complexity */
import {
  compose,
  dec,
  equals,
  filter,
  find,
  findIndex,
  groupBy,
  head,
  isEmpty,
  join,
  length,
  map,
  multiply,
  path,
  pathOr,
  pipe,
  prop,
  propOr,
  reduce,
  toLower,
  values
} from 'ramda'
import { createSelector } from 'reselect'

import { daysLocal } from 'helpers/addressDelivery'
import renameKeys from 'helpers/objectModify'
import { snakeToCamel } from 'helpers/toCamelCase'
import moment from 'utils/moment'

const baseFormatParams = {
  maximumFractionDigits: 2,
  minimumFractionDigits: 0
}

const rubFormat = new Intl.NumberFormat('ru-RU', baseFormatParams)

const rubFormatCurrency = new Intl.NumberFormat('ru-RU', {
  ...baseFormatParams,
  currency: 'rub',
  style: 'currency'
})

const prepareProduct = (item, paymentType) => {
  const baseCount = propOr(0, 'INITIAL_QUANTITY', item)
  const count = propOr(0, 'QUANTITY', item)
  const price = propOr('', 'PRICE', item)
  const baseSum = multiply(price, baseCount)
  const sum = multiply(price, count)
  const isEqual = !baseCount || equals(baseCount, count)
  const sumAdd = propOr(0, 'SUM_ADD', item)

  return {
    article: propOr('', 'ARTICLE', item),
    baseCount,
    baseSum: `${rubFormat.format(baseSum)} ${paymentType}`,
    code: propOr('', 'CODE_1C', item),
    count,
    isEqual,
    itemId: prop('ID', item),
    name: propOr('', 'NAME', item),
    points: `${rubFormat.format(propOr(0, 'LOYALTY_POINTS', item))} Б`,
    preview: propOr('', 'PREVIEW_PICTURE_PATH', item),
    price: `${rubFormat.format(price)} ${paymentType}`,
    sumAdd: sumAdd ? rubFormatCurrency.format(sumAdd) : null,
    sum: `${rubFormat.format(sum)} ${paymentType}`,
    xmlId: prop('XML_ID', item)
  }
}

const workTime = day => {
  const start = prop('WORKS_TIME_START', day)
  const end = prop('WORKS_TIME_END', day)

  return start && end && `${start} - ${end}`
}

const brakeTime = day => {
  const start = prop('BREAK_TIME_START', day)
  const end = prop('BREAK_TIME_END', day)

  return start && end && `${start} - ${end}`
}

// функции из старой версии заказа детально orderSummary
const getStatusState = createSelector(
  (key, currentStatusIndex) => {
    if (key < currentStatusIndex) return 'completed' // пройденый этап
    if (key === currentStatusIndex) return 'current' // текущий этап
    return 'uncompleted' // непройденый этап
  },
  state => state
)

export const getStatusesChain = createSelector(
  state => state,
  ({ entities, invoice }) => {
    const chainCode = pathOr('', ['STATUS_CHAIN', 'CHAIN'], invoice)
    const statusesChain = pathOr([], ['STATUS_CHAIN', chainCode], entities)
    const chainLength = length(statusesChain)
    // если в цепочке один статус, его состояние Отменен
    if (equals(chainLength, 1)) {
      return statusesChain.map(item => ({
        ...item,
        state: 'canceled'
      }))
    }

    const chainLastIndex = dec(chainLength)
    const currentStatusCode = pathOr('', ['STATUS_CHAIN', 'GROUP'], invoice)
    const currentStatusIndex = findIndex(item =>
      equals(propOr('', 'CODE', item), currentStatusCode)
    )(statusesChain)

    return statusesChain.map((item, key) => ({
      ...item,
      state: equals(chainLastIndex, currentStatusIndex) // если текущий статус является последним, состояние Завершен
        ? 'completed'
        : getStatusState(key, currentStatusIndex)
    }))
  }
)

// селектор состояния загрузки данных заказа/реализации
export const invoiceLoading = createSelector(
  state => state,
  state => propOr(true, 'isLoading')(state)
)
export const isErrorInvoice = createSelector(
  state => state,
  state => propOr(false, 'isError')(state)
)

// селектор данных заказа/реализации
export const invoiceData = createSelector(
  state => state,
  (_, type) => type,
  (state, type) => {
    const entities = pathOr({}, [type, 'entities'], state)
    const invoice = pathOr({}, [type, 'invoice'], state)
    const addressId = pathOr('', ['DELIVERY', 'ADDRESS'], invoice)
    let bills = propOr([], 'BILL', invoice)
    const billsCount = length(bills)

    bills = isEmpty(bills)
      ? []
      : map(bill => {
        const baseCount = propOr(0, 'INITIAL_COUNT', bill)
        const baseSum = Number(propOr(0, 'INITIAL_SUM', bill))
        const count = propOr(0, 'COUNT', bill)
        const sum = Number(propOr(0, 'SUM', bill))
        const equalsCount = equals(baseCount, count)
        const equalsSum = equals(baseSum, sum)
        const paymentTypeId = path(['PRODUCT', 0, 'PAYMENT_TYPE'], bill)
        const paymentType = pathOr('cashless', ['PAYMENT_TYPE', paymentTypeId, 'CODE'], entities) === 'cashless'
          ? '₽' : 'Б'
        return {
          baseCount,
          baseSum: rubFormatCurrency.format(baseSum),
          bookmark: propOr(false, 'BOOKMARK', bill),
          count,
          date: moment(propOr(null, 'DATE', bill), 'X').format('DD.MM.YYYY HH:mm'),
          equalsCount,
          equalsSum,
          document: propOr({}, 'DOCUMENT', bill),
          id: prop('ID', bill),
          num: propOr('', 'NUM', bill),
          sum: rubFormatCurrency.format(sum),
          products: compose(
            values,
            map(item => ({
              title: pathOr('', ['PRODUCT_STATUS', path([0, 'STATUS'], item), 'TITLE'], entities),
              items: item.map(i => prepareProduct(i, paymentType))
            })),
            groupBy(item => propOr('', 'STATUS', item)),
            values,
            propOr([], 'PRODUCT')
          )(bill)
        }
      })(bills)

    const contractorId = propOr('', 'CONTRACTOR', invoice)
    const date = prop('DATE', invoice)
    const dateDelivery = pathOr(null, ['DELIVERY', 'DATE'], invoice)
    const deliveryTypeId = pathOr('', ['DELIVERY', 'TYPE'], invoice)
    const storeId = propOr('', 'STORE', invoice)
    const userId = propOr('', 'USER', invoice)

    const scheduleItem = compose(
      head,
      map(item => ({
        brakeTime: brakeTime(item),
        days: pipe(prop('days'), map(prop('shortName')), join(', '))(item),
        workTime: workTime(item)
      })),
      reduce(
        (acc, { DAY_NAME, ...el }) => {
          const group = find(i =>
            equals(
              prop('key', i),
              `${prop('WORKS_TIME_START', el)}_
        ${prop('WORKS_TIME_END', el)}_
        ${prop('BREAK_TIME_START', el)}_
        ${prop('BREAK_TIME_END', el)}`
            )
          )(acc)

          const dayInfo = {
            key: DAY_NAME,
            ...daysLocal[DAY_NAME]
          }

          if (group) {
            group.days.push(dayInfo)
            return acc
          }

          return [
            ...acc,
            {
              ...el,
              key: `${prop('WORKS_TIME_START', el)}_
        ${prop('WORKS_TIME_END', el)}_
        ${prop('BREAK_TIME_START', el)}_
        ${prop('BREAK_TIME_END', el)}`,
              days: [dayInfo]
            }
          ]
        },
        []
      ),
      filter(day => prop('IS_WORKS_DAY', day)),
      pathOr([], ['ADDRESS', addressId, 'WORK_SCHEDULE'])
    )(entities)

    const actions = new Set([...propOr([], 'ACTIONS')(invoice)])

    return {
      address: pathOr('', ['ADDRESS', addressId, 'ADDRESS'], entities),
      bills,
      billsCount,
      comment: propOr('', 'COMMENT', invoice),
      contractorName: pathOr(
        '',
        ['CONTRACTOR', contractorId, 'TITLE'],
        entities
      ),
      cnt: propOr(0, 'CNT', invoice),
      date: date ? moment(date, 'X').locale('ru').format('ll HH:mm') : '',
      dateDelivery: dateDelivery
        ? moment(dateDelivery, 'X').format('DD.MM.YYYY')
        : 'Определяется',
      document: propOr({}, 'DOCUMENT', invoice),
      deliveryType: pathOr('', ['DELIVERY', deliveryTypeId, 'NAME'], entities),
      expeditors: pathOr([], ['DELIVERY', 'EXPEDITOR'], invoice),
      cars: pathOr([], ['DELIVERY', 'CAR'], invoice),
      id: prop('ID', invoice),
      groupId: propOr(null, 'GROUP', invoice),
      isDelete: actions.has('delete'),
      isStepOne: actions.has('GROUP_EDIT_DELIVERY'),
      isStepTwo: actions.has('GROUP_EDIT_CONFIRM'),
      isPretension: actions.has('CREATE_PRETENSION'),
      isFeedback: actions.has('FEEDBACK'),
      num: propOr('', 'NUM', invoice),
      paymentType: equals(
        'loyalty',
        pathOr('', ['PAYMENT_TYPE', prop('PAYMENT_TYPE', invoice), 'CODE'], entities)
      ) ? 'Б' : '₽',
      sum: propOr('', 'SUM', invoice),
      shipmentList: map(renameKeys(toLower), pathOr([], ['SHIPMENT'], invoice)),
      numShipment: pathOr('', ['SHIPMENT', 0, 'NUM'], invoice),
      shipmentId: pathOr('', ['SHIPMENT', 0, 'ID'], invoice),
      showToolTip: Boolean(dateDelivery),
      numUpd: propOr('', 'NUM_UPD', invoice),
      storeName: pathOr('', ['STORE', storeId, 'NAME'], entities),
      userName: pathOr({}, ['USER', userId], entities),
      scheduleItem,
      statusesChain: getStatusesChain({ entities, invoice })
    }
  }
)

export const receiptData = createSelector(
  state => state,
  (_, type) => type,
  (state, type) =>
    compose(
      reduce(
        (acc, elem) => ({
          ...acc,
          [snakeToCamel(prop('CODE', elem))]: prop('VALUE', elem)
        }),
        {}
      ),
      map(item => {
        const code = prop('CODE', item)
        if (code.startsWith('total_loyalty_price'))
          return {
            ...item,
            VALUE: `${rubFormat.format(prop('VALUE', item))} Б`
          }
        if (code.startsWith('total'))
          return {
            ...item,
            VALUE: rubFormatCurrency.format(prop('VALUE', item))
          }
        if (code.startsWith('not_multi'))
          return {
            ...item,
            VALUE: rubFormatCurrency.format(prop('VALUE', item))
          }
        if (code.startsWith('bill_break'))
          return {
            ...item,
            VALUE: rubFormatCurrency.format(prop('VALUE', item))
          }
        if (code.startsWith('paid'))
          return {
            ...item,
            VALUE: rubFormatCurrency.format(prop('VALUE', item))
          }
        if (code.startsWith('loyalty'))
          return {
            ...item,
            VALUE: `${rubFormat.format(prop('VALUE', item))} Б`
          }
        return item
      }),
      pathOr([], [type, 'receipt'])
    )(state)
)

export const orderDataSelector = createSelector(
  state => state,
  (_, type) => type,
  (order, type) => compose(
    reduce(
      (acc, item) => ({ ...acc, [snakeToCamel(prop('CODE', item))]: Number(prop('VALUE', item)) }),
      {}
    ),
    pathOr([], [type, 'receipt'])
  )(order)
)

const productSelector = createSelector(
  invoiceDetails => path(['products'], invoiceDetails),
  (_, id) => id,
  (products, id) => path([id], products)
)

export const isLoadingProductsSelector = createSelector(
  productSelector,
  products => propOr(false, 'isLoading', products)
)

export const isLoadedProductsSelector = createSelector(
  productSelector,
  products => propOr(false, 'isLoaded', products)
)

export const productsSelector = createSelector(
  productSelector,
  products => {
    const productItems = propOr([], 'products', products)
    const paymentTypeId = path(['invoice', 'PAYMENT_TYPE'], products)
    const entities = propOr({}, 'entities', products)
    const paymentType = pathOr('', ['PAYMENT_TYPE', paymentTypeId, 'CODE'], entities) === 'cashless' ? '₽' : 'Б'
    return compose(
      map(({ items, title }) => ({
        title,
        items: map(item => prepareProduct(item, paymentType))(items)
      })),
      map(renameKeys(toLower)),
      values,
      reduce((acc, item) => {
        const sortKey = propOr('', 'STATUS', item)
        return {
          ...acc,
          [sortKey]: {
            ...pathOr('', ['PRODUCT_STATUS', sortKey], entities),
            items: [...pathOr([], [sortKey, 'items'], acc), item]
          }
        }
      }, {})
    )(productItems)
  }
)
