import React from 'react'

import { bool } from 'prop-types'

import CartMini from 'containers/Cart/CartMini'
import MiniBasket from 'containers/MiniBasket'

const WrapperBasket = ({ isLoyalty, ...props }) => {
  return isLoyalty ? <MiniBasket {...props} /> : <CartMini {...props} />
}

WrapperBasket.propTypes = {
  isLoyalty: bool
}

export default WrapperBasket
