import { pathOr, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

export const send = createAction('gtm/SEND')
export const fetch = createAction('gtm/FETCH')
export const fetchSuccess = createAction('gtm/FETCH_SUCCESS')
export const fetchFailure = createAction('gtm/FETCH_FAILURE')
export const addPurchase = createAction('gtm/ADD_PURCHASE')
export const removeProducts = createAction('gtm/REMOVE_PRODUCTS')
export const clearGtm = createAction('gtm/CLEAR_GTM')

const initialState = {
  isLoading: false,
  isLoaded: false,
  gtm: [],
  gtmType: '',
  purchase: [],
  removedProducts: []
}

export const request =
  ({ clientApi }) =>
    params => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/sale/basket/${params.catalog || 'main'}/get/`, {
          params: {
            contractor_id: contractorId,
            offset: 0,
            limit: 10000,
            ...params
          }
        })
        .then(data => fetchSuccess({ ...data, ...params }))
        .catch(fetchFailure)
    }

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), { ...payload })
  )

const handleFetchSuccess = (state, payload) => {
  const gtm = pathOr([], ['data', 'response', 'ITEMS'], payload)
  const gtmType = propOr('', 'gtmType', payload)
  const effects = []
  return loop(
    {
      ...state,
      isLoading: false,
      isLoaded: true,
      gtm,
      gtmType
    },
    Effects.batch(effects)
  )
}

const handleFetchFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  gtm: {}
})

const handleAddPurchase = (state, payload) => ({
  ...state,
  purchase: payload
})

const handleRemoveProducts = (state, payload) => ({
  ...state,
  removedProducts: payload
})

const handleClearGtm = state => ({
  ...state,
  ...initialState
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(addPurchase, handleAddPurchase)
  on(removeProducts, handleRemoveProducts)
  on(clearGtm, handleClearGtm)
}, initialState)

export default reducer
