import React from 'react'

import { bool, array } from 'prop-types'
import { compose, toLower, prop } from 'ramda'

import Link from 'components/Link'
import { setAnalyticsProps } from 'helpers/metaTags'

import styles from './MarksMenu.scss'

const MarksMenu = ({
  isLoyalty = false,
  isAuth = false,
  isOffers = false,
  marks = []
}) => {
  const renderItems = item => {
    const code = compose(toLower, prop('CODE'))(item)
    return (
      <li
        key={code}
        className={styles.menuItem}
        itemProp='itemListElement'
        itemScope
        itemType='http://schema.org/ItemList'
      >
        <meta itemProp='position'
          content={code} />
        <Link
          to={`/catalog/mark/${code}`}
          {...setAnalyticsProps({
            type: 'action',
            group: 'navigation',
            pos: 'top'
          })}
        >
          {prop('TITLE', item)}
        </Link>
      </li>
    )
  }

  if (isLoyalty) {
    return null
  }

  return (
    <div className={styles.menu}>
      <ul className={styles.menuList}>
        {isOffers && isAuth && (
          <li
            className={styles.menuItem}
            itemProp='itemListElement'
            itemScope
            itemType='http://schema.org/ItemList'
          >
            <Link
              to='/offers'
              {...setAnalyticsProps({
                type: 'action',
                group: 'navigation',
                pos: 'top'
              })}
            >
              Акции
            </Link>
          </li>
        )}
        {marks.map(renderItems, this)}
      </ul>
      <ul className={styles.menuList}>
        <li
          className={styles.menuItem}
          itemProp='itemListElement'
          itemScope
          itemType='http://schema.org/ItemList'
        >
          <Link
            to='/content/news'
            {...setAnalyticsProps({
              type: 'action',
              group: 'navigation',
              pos: 'top'
            })}
          >
            Новости
          </Link>
        </li>
        {isAuth && (
          <li
            className={styles.menuItem}
            itemProp='itemListElement'
            itemScope
            itemType='http://schema.org/ItemList'
          >
            <Link
              to='/content/update'
              {...setAnalyticsProps({
                type: 'action',
                group: 'navigation',
                pos: 'top'
              })}
            >
              Обновления
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

MarksMenu.propTypes = {
  isLoyalty: bool,
  isAuth: bool,
  isOffers: bool,
  marks: array
}

MarksMenu.defaultProps = {
  isLoyalty: false,
  isAuth: false,
  isOffers: false,
  marks: []
}

export default MarksMenu
