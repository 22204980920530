import { equals, filter, includes, map, pathOr, prop, propOr } from 'ramda'
import { compose } from 'redux'
import { createSelector } from 'reselect'

const CABINET_SHIPMENT = ['contract', 'economic_security']

const itemsSelector = createSelector(
  state => state,
  state => propOr([], 'items', state)
)

const prepareItems = (items = []) =>
  map(
    item => ({
      ...item,
      type: equals(prop('type', item), 'contract')
        ? `${prop('type', item)}_${pathOr(true, ['data', 'active'], item)}`
        : prop('type', item)
    }),
    items
  )

export const cabinetShipmentSelector = createSelector(itemsSelector, items =>
  compose(
    prepareItems,
    filter(item => includes(prop('type', item), CABINET_SHIPMENT))
  )(items)
)

export const basketShipmentSelector = createSelector(itemsSelector, items =>
  prepareItems(items)
)

export const dateUpdateSelector = createSelector(
  state => state,
  state => prop('dateUpdate', state)
)
