import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Loyalty = loadable(() =>
  import(/* webpackChunkName: "Loyalty" */ 'pages/Loyalty/Loyalty')
)

export default asyncConnect([
  {
    key: 'pageLoyalty',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'loyalty', loyaltyBasket: true })

      return updateData.update()
    }
  }
])(Loyalty)
