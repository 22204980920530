import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const FaqItem = loadable(() =>
  import(/* webpackChunkName: "FaqItem" */ 'pages/FaqItem/FaqItem')
)

export default asyncConnect([
  {
    key: 'pageFaqItem',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'faq' })
      return updateData.update()
    }
  }
])(FaqItem)
