import React, { Component } from 'react'

import { object, array } from 'prop-types'
import { pathOr } from 'ramda'

import InputError from 'components/InputError'
import Input from 'UI/Input'
import Select from 'UI/Select'

import styles from '../Registration.scss'

class RegistrationSelect extends Component {
  static propTypes = {
    input: object,
    meta: object,
    items: array
  }

  static defaultProps = {
    items: []
  }

  componentDidMount() {
    const { meta } = this.props
    if (meta.initial) {
      const initValue = pathOr('', ['initial', 'value'], meta)
      this.handleChange(initValue)
    }
  }

  handleChange = value => {
    const { input } = this.props
    if (value !== -1) {
      input.onChange(value)
    }
  }

  render() {
    const { input, meta, items } = this.props

    return (
      <div className={styles.select__wrapper}>
        <Select
          isDisabled={!!meta.error}
          onSelect={this.handleChange}
          options={items}
          selectedValue={pathOr('', ['initial', 'value'], meta)}
        />

        {!!meta.error && <InputError message={meta.error} />}
        <Input type='hidden'
          name={input.name}
          value={input.value} />
      </div>
    )
  }
}

export default RegistrationSelect
