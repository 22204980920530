import React, { PureComponent } from 'react'

import { string, func } from 'prop-types'

import Modal from 'containers/Modal'

import styles from './OutdatedBrowser.scss'

const BROWSERS = [
  {
    name: 'Chrome',
    logo: styles.chrome,
    url: 'https://www.google.com/chrome/browser/desktop/'
  },
  {
    name: 'Firefox',
    logo: styles.firefox,
    url: 'http://www.mozilla.org/firefox/new/'
  },
  {
    name: 'Safari',
    logo: styles.safari,
    url: 'http://www.apple.com/osx/'
  },
  {
    name: 'Opera',
    logo: styles.opera,
    url: 'http://www.opera.com/ru'
  },
  {
    name: 'Yandex',
    logo: styles.yandex,
    url: 'https://browser.yandex.ru/desktop/main/'
  }
]

const SUPPORTED_VERSIONS = {
  SSR: 1,
  Chrome: 50,
  Firefox: 41,
  IE: 11,
  Edge: 10,
  Safari: 9,
  Opera: 32,
  MSIE: 11
}

export default class OutdatedBrowser extends PureComponent {
  static propTypes = {
    userAgent: string,
    closeOutdatedBrowser: func
  }

  static defaultProps = {
    userAgent: ''
  }

  componentDidMount() {
    this.isSupported(this.props)
  }

  handleCloseModal = () => this.props.closeOutdatedBrowser()

  detectBrowserVersion = () => {
    if (typeof window === 'undefined') {
      return { name: 'SSR', version: 1 }
    }
    const { userAgent } = this.props
    let temporary
    let match =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || []

    if (/trident/i.test(match[1])) {
      temporary = /\brv[ :]+(\d+)/g.exec(userAgent) || []
      return { name: 'IE', version: Number(temporary[1]) }
    }

    if (match[1] === 'Chrome') {
      temporary = userAgent.match(/\b(OPR|Edge)\/(\d+)/)
      if (temporary !== null) {
        return {
          name: temporary[1].replace('OPR', 'Opera'),
          version: Number(temporary[2])
        }
      }
    }
    match = match[2]
      ? [match[1], match[2]]
      : [window.navigator.appName, window.navigator.appVersion, '-?']
    temporary = userAgent.match(/version\/(\d+)/i)

    if (temporary != null) {
      match.splice(1, 1, temporary[1])
    }
    return { name: match[0], version: Number(match[1]) }
  }

  isSupported = props => {
    const browser = this.detectBrowserVersion()
    const { showOutdatedBrowser, isOpenOutdatedBrowser } = props
    if (browser && isOpenOutdatedBrowser) {
      if (browser.version <= SUPPORTED_VERSIONS[browser.name]) {
        showOutdatedBrowser()
      }
    }
  }

  renderBrowser = ({ name, url, logo }) => (
    <div className={styles.browser}
      key={logo}>
      <a
        href={url}
        className={styles.button}
        target='_blank'
        rel='noopener noreferrer'
      >
        <i className={`${styles.logo} ${logo}`} />
      </a>
      <h2 className={styles.title}>{name}</h2>
    </div>
  )

  render() {
    return (
      <Modal id='outDatedBrowser'
        size='middle'
        onClose={this.handleCloseModal}>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Ваш браузер устарел</h1>
            <p>
              Для того чтобы продолжить работу на сайте вам необходимо обновить
              свой браузер
            </p>
          </div>
          <div className={styles.browsers}>
            {BROWSERS.map(this.renderBrowser)}
          </div>
        </div>
      </Modal>
    )
  }
}
