/* eslint-disable complexity */
import React, { FC, MouseEvent, useCallback } from 'react'

import cx from 'classnames'
import qs from 'qs'
import { compose, omit, set, lensProp, defaultTo } from 'ramda'
import { NavLink } from 'react-router-dom'

import s from './Link.scss'

const LinkColors = ['default', 'white', 'blue', 'pink'] as const
const LinkSizes = ['small', 'default', 'big'] as const
const LinkWeights = ['normal', 'semibold', 'bold'] as const

type LinkColorProps = (typeof LinkColors)[number]
type LinkSizeProps = (typeof LinkSizes)[number]
type LinkWeightProps = (typeof LinkWeights)[number]

interface IPath {
  pathname?: string
  search?: string
  hash?: string
  query?: string
}

type To = string | Partial<IPath>

interface ILink
  extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> {
  color?: LinkColorProps
  disabled?: boolean
  isUnderline?: boolean
  size?: LinkSizeProps
  weight?: LinkWeightProps
  to: To
}

const Link: FC<ILink> = ({
  children,
  color = 'default',
  disabled = false,
  isUnderline = false,
  onBlur = () => {},
  onClick = () => {},
  onFocus = () => {},
  onMouseDown = () => {},
  onMouseOut = () => {},
  onMouseOver = () => {},
  size = 'default',
  target,
  to,
  weight = 'normal',
  ...rest
}) => {
  const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>): void => {
    if (onClick) onClick(e)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // @ts-ignore
  const linkTo: any =
    typeof to === 'object'
      ? compose(
        set(lensProp('search'), `?${qs.stringify(to.query)}`),
        // @ts-ignore
        omit(['query'])
        // @ts-ignore
      )(to)
      : defaultTo('', to)
  return (
    <NavLink
      activeClassName={s.link_active}
      itemProp='url'
      onBlur={onBlur}
      onClick={handleClick}
      onFocus={onFocus}
      onMouseDown={onMouseDown}
      onMouseOut={onMouseOut}
      onMouseOver={onMouseOver}
      target={target}
      to={linkTo}
      className={cx(s.link, {
        [s.link_disabled]: disabled,
        [s.link_underline]: isUnderline,
        [s[`link_color_${color}`]]: !!color,
        [s[`link_size_${size}`]]: !!size,
        [s[`link_weight_${weight}`]]: !!weight
      })}
      {...rest}
    >
      {children}
    </NavLink>
  )
}

export default Link
