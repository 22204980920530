import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const isLoadedSelector = createSelector(
  state => state,
  state => propOr(false, 'loaded', state)
)

export const isLoadingSelector = createSelector(
  state => state,
  state => !propOr(false, 'loaded', state)
)
