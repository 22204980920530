import React, { PureComponent } from 'react'

import { push } from 'connected-react-router'
import { bool, string, array, object, func } from 'prop-types'
import { connect } from 'react-redux'

import HeaderOffers from 'components/HeaderOffers'
import { forClientRendering } from 'decorators/window'
import {
  fetchHeaderOffer,
  fetchMarkdown,
  offersHeaderSelector,
  isLoadedOffersHeader,
  isLoadingOffersHeader,
  newOffersSelector
} from 'redux/modules/offers'
import { pageLoadedSelector } from 'redux/modules/settings'

const TIMEOUT = 100

@connect(
  ({ offers, reduxAsyncConnect }) => ({
    offers: offersHeaderSelector(offers),
    newOffers: newOffersSelector(offers),
    isLoadedOffers: isLoadedOffersHeader(offers),
    isLoadingOffers: isLoadingOffersHeader(offers),
    isPageLoaded: pageLoadedSelector(reduxAsyncConnect)
  }),
  {
    fetchOffers: fetchHeaderOffer,
    fetchMarkdownPage: fetchMarkdown,
    navigateTo: push
  }
)
export default class HeaderOffersContainer extends PureComponent {
  static propTypes = {
    isLoadedOffers: bool,
    isLoadingOffers: bool,
    isPageLoaded: bool,
    newOffers: object,
    iconPath: string,
    offers: array,
    fetchOffers: func,
    fetchMarkdownPage: func,
    navigateTo: func
  }

  static defaultProps = {
    isLoadedOffers: false,
    isLoadingOffers: false,
    isPageLoaded: false,
    offers: []
  }

  componentDidMount() {
    const { isLoadedOffers } = this.props
    if (!isLoadedOffers) {
      this.fetchData()
    }
  }

  @forClientRendering
  fetchData() {
    const { isPageLoaded } = this.props
    if (isPageLoaded) {
      this.props.fetchOffers()
    } else {
      setTimeout(() => this.fetchData(), TIMEOUT)
    }
  }

  render() {
    return <HeaderOffers {...this.props} />
  }
}
