/* eslint-disable react/jsx-no-bind */
import React from 'react'

import { object, func, string, bool } from 'prop-types'
import {
  compose,
  equals,
  filter,
  path,
  pathOr,
  propOr,
  reduce,
  toLower,
  values
} from 'ramda'

import Tabs from 'containers/Tabs'

const CartTabs = ({ catalog, onMove, onSetCatalog, carts, isChangedContractor }) => {
  const items = compose(
    values,
    reduce(
      (acc, item) => ({
        ...acc,
        [item]: {
          id: toLower(item),
          catalog: toLower(item),
          cart: equals(item, 'BOOKMARK')
            ? pathOr(0, [0, 'ID'], values(pathOr({}, [item, 'VALUES'], carts)))
            : 0,
          title: equals(item, 'BOOKMARK')
            ? `${pathOr('', [item, 'TITLE'], carts)} ${pathOr(
              0,
              [0, 'TITLE'],
              values(pathOr({}, [item, 'VALUES'], carts))
            )}`
            : pathOr('', [item, 'TITLE'], carts)
        }
      }),
      {}
    ),
    filter(item => !!path([item], carts))
  )(['MAIN', 'BOOKMARK', 'DELAY', '918OK'])

  const onSetTab = item => {
    const toCatalog = propOr('main', 'catalog', item)
    const id = propOr(0, 'cart', item)
    onSetCatalog(toCatalog)
    if (toCatalog === 'main') {
      return
    }
    onMove({ id, toCatalog })
  }

  return (
    <Tabs
      name='cartTabs'
      active={isChangedContractor ? '' : catalog}
      fontSize={16}
      items={items}
      onClick={onSetTab}
    />
  )
}

CartTabs.propTypes = {
  isChangedContractor: bool,
  carts: object,
  catalog: string,
  onMove: func,
  onSetCatalog: func
}

export default CartTabs
