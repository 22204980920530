/* eslint-disable node/no-deprecated-api */
import url from 'url'

import { propOr } from 'ramda'

import config from 'config'

const getImagePath = path => {
  const preparePath = path ? url.parse(path) : ''
  const isAbsolutePath = propOr('', 'protocol', preparePath)
  return isAbsolutePath ? path : `${config.domainStatic}${path}`
}

export default getImagePath
