import loadable from '@loadable/component'
import R from 'ramda'
import { asyncConnect } from 'redux-connect'

import getProducts from 'helpers/getProducts'

const CatalogLoyalty = loadable(() =>
  import(
    /* webpackChunkName: "CatalogLoyalty" */ 'pages/CatalogLoyalty/CatalogLoyalty'
  )
)

export default asyncConnect([
  {
    key: 'pageCatalog',
    promise: ({ route, match, ...props }) => {
      const id = R.pathOr('', ['params', 'id'], match)
      const bookmark = R.pathOr('', ['params', 'bookmark'], match)
      const url = R.compose(
        R.replace(':bookmark', bookmark),
        R.replace(':id', id),
        R.prop('requestPath')
      )(route)
      const urlParams = R.compose(
        R.map(item => R.replace(':bookmark', bookmark, item)),
        R.map(item => R.replace(':id', id, item)),
        R.propOr({}, 'requestParams')
      )(route)
      const type = R.prop('type', route)
      return getProducts({ url, urlParams, type, useQuery: ['q'] })(props)
    }
  }
])(CatalogLoyalty)
