/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {ChangeEvent, FC} from 'react'

import s from './RadioButton.scss'

interface IRadioButtonProps {
  label?: string,
  value?: string,
  checked?: boolean,
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

const RadioButton: FC<IRadioButtonProps> = ({
  label,
  value,
  checked,
  onChange = () => {}
}) => (
  <label className={s.radio}>
    <input
      className={s.radio__field}
      checked={Boolean(checked)}
      value={value}
      onChange={onChange}
      type='radio'
    />
    <span className={s.radio__label}>{label}</span>
  </label>
)

export default RadioButton
