import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Home = loadable(() =>
  import(/* webpackChunkName: "Home" */ 'pages/Home/Home')
)

export default asyncConnect([
  {
    key: 'pageHome',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'homeCarousels' })
      return updateData.update()
    }
  }
])(Home)
