import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const isDesktopSelector = createSelector(
  state => state,
  state => propOr(false, 'isDesktop', state)
)

export const isTabletSelector = createSelector(
  state => state,
  state => propOr(false, 'isTablet', state)
)

export const isMobileSelector = createSelector(
  state => state,
  state => propOr(false, 'isMobile', state)
)
