/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, number, bool, object } from 'prop-types'
import { propOr, path } from 'ramda'
import { Link } from 'react-router-dom'

import CurrencyValue from 'components/CurrencyValue'
import Picture from 'components/Picture'
import TextTruncate from 'components/TextTruncate'
import basket from 'decorators/basket'
import product from 'decorators/product'
import { getBasket, getBasketForPoints } from 'helpers/products'
import formatAmount from 'utils/formatAmount'
import roundBy from 'utils/roundBy'

import styles from './MiniBasket.scss'

const ROUND_BY = 2

@basket
@product
class BasketItem extends PureComponent {
  static propTypes = {
    isLoyalty: bool,
    isBookmark: bool,
    isNotMultiple: bool,
    bookmark: number,
    item: object,
    pointsDimension: string,
    catalog: string,
    costMultiple: number
  }

  static defaultProps = {
    isNotMultiple: false,
    isLoyalty: false,
    isBookmark: false,
    bookmark: 0,
    catalog: 'main'
  }

  render() {
    const {
      bookmark,
      item,
      isLoyalty,
      isBookmark,
      pointsDimension,
      isNotMultiple,
      costMultiple,
      catalog
    } = this.props
    const price = Number(propOr(0, 'PRICE', item))
    const quantity = getBasket(item, [catalog, bookmark])
    const isPoints = !isLoyalty && getBasketForPoints(item, [catalog, bookmark])
    const rawSumPrice = quantity * price + (isNotMultiple ? costMultiple : 0)
    const sumPrice = price === 0 ? 0 : roundBy(rawSumPrice, ROUND_BY)
    const linkToProduct = `${isLoyalty ? '/loyalty' : ''}${
      isBookmark ? '/bookmark' : ''
    }/product/${isBookmark ? `${bookmark}/` : ''}${path(['ID'], item)}`
    return (
      <div className={styles.miniBasketItem}>
        <div className={styles.minBasketImage}>
          <div className={styles.minBasketImage_wrapper}>
            <Picture
              backgroundSize='contain'
              className='previewPicture'
              width={30}
              height={30}
              src={path(['PREVIEW_PICTURE_PATH'], item)}
            />
          </div>
        </div>
        <div
          className={cx(styles.miniBasketName, {
            [styles.isPoints]: isPoints
          })}
        >
          <TextTruncate
            component={Link}
            className={styles.miniBasketNameLink}
            to={linkToProduct}
          >
            {path(['NAME'], item)}
          </TextTruncate>
        </div>
        <div
          className={cx(styles.miniBasketCount, {
            [styles.isPoints]: isPoints
          })}
        >
          {quantity} шт.
        </div>
        <div className={styles.miniBasketPrice}>
          {isLoyalty ? (
            <span>
              {formatAmount(sumPrice)} {pointsDimension}
            </span>
          ) : (
            <CurrencyValue
              price={sumPrice}
              isPoints={isPoints}
              className={cx({
                [styles.isPoints]: isPoints
              })}
            />
          )}
        </div>
      </div>
    )
  }
}

export default BasketItem
