interface ISliderSettings {
  breakpoint: number
  settings: {
    slidesToShow?: number
    slidesToScroll?: number
    swipeToSlide?: boolean
    draggable?: boolean
    arrows?: boolean
    infinite?: boolean
  }
}

export const sliderProducts: ISliderSettings[] = [
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      infinite: false
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2.1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      infinite: false,
      draggable: true
    }
  },
  {
    breakpoint: 400,
    settings: {
      slidesToShow: 1.1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      infinite: false,
      draggable: true
    }
  }
]

export const sliderImages: ISliderSettings[] = [
  {
    breakpoint: 1199,
    settings: {
      swipeToSlide: true,
      draggable: true
    }
  },
  {
    breakpoint: 767,
    settings: {
      swipeToSlide: true,
      infinite: true,
      draggable: true
    }
  },
  {
    breakpoint: 470,
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      swipeToSlide: true,
      infinite: true,
      draggable: true
    }
  }
]

export const sliderCarts = [
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      arrows: false
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1.1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      infinite: true,
      draggable: true
    }
  }
]

export const sliderOffers = [
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      arrows: true,
      infinite: true
    }
  },
  {
    breakpoint: 620,
    settings: {
      slidesToShow: 1.7,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      infinite: true,
      draggable: true
    }
  }
]

export const sliderBrands = [
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 5,
      slidesToScroll: 5,
      swipeToSlide: true,
      arrows: true,
      infinite: true
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      swipeToSlide: true,
      arrows: false,
      infinite: true
    }
  }
]

export const sliderBanners = [
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: true
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false
    }
  }
]

export const sliderBookmarks = [
  {
    breakpoint: 1199,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
      swipeToSlide: true,
      draggable: true,
      arrows: false
    }
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      arrows: false,
      infinite: true,
      draggable: true
    }
  }
]
