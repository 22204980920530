/* eslint-disable complexity */
import React from 'react'

import cx from 'classnames'
import { number, oneOfType, string, object, array, bool } from 'prop-types'

import RubleSign from 'components/RubleSign'
import formatAmount from 'utils/formatAmount'

import styles from './CurrencyValue.scss'

const CurrencyValue = ({ price, showZero, className, isPoints, bold }) => {
  if (
    typeof price === 'undefined' ||
    Number.isNaN(Number(price)) ||
    price === '' ||
    price === '0' ||
    (!price && !(showZero && price === 0))
  ) {
    return null
  }

  return (
    <div
      className={cx(styles.currencyValue, className)}
      itemScope
      itemType='http://schema.org/PriceSpecification'
    >
      <span
        className={cx({
          [styles.bold]: !!bold
        })}
        itemProp='price'
        data-testid='price'
      >
        {formatAmount(parseFloat(price), true)}
      </span>
      <span className={styles.sign}
        itemProp='priceCurrency'>
        {isPoints ? 'Б' : <RubleSign />}
      </span>
    </div>
  )
}

CurrencyValue.propTypes = {
  price: oneOfType([string, number]),
  className: oneOfType([string, object, array]),
  showZero: bool,
  bold: bool,
  isPoints: bool
}

CurrencyValue.defaultProps = {
  showZero: false,
  bold: true,
  isPoints: false
}

export default CurrencyValue
