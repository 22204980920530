/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, func, number, string, array } from 'prop-types'
import { pathOr } from 'ramda'
import { Link } from 'react-router-dom'

import Icon from 'components/Icon'
import Interactions from 'components/Interactions'
import UserPanel from 'components/UserPanel'
import { Push as NotificationPush } from 'containers/Notification'
import Button from 'UI/Button'

import styles from './HeaderTop.scss'

class HeaderTop extends PureComponent {
  static propTypes = {
    isLoyalty: bool,
    isBookmark: bool,
    contractors: array,
    menuItems: array,
    activeContractor: string,
    showModal: func,
    setContractor: func,
    onSignOut: func,
    favoriteCount: number,
    compareCount: number,
    isTempUser: bool,
    personalTitle: string,
    isSuperuser: bool,
    isExistsSuperuser: bool
  }

  static defaultProps = {
    contractors: [],
    menuItems: [],
    isSuperuser: false,
    isExistsSuperuser: false
  }

  state = {
    menuActive: false,
    contractorName: ''
  }

  handleShowUserMenu = () => {
    this.setState({
      menuActive: true
    })
  }

  handleHideUserMenu = () => {
    this.setState({
      menuActive: false
    })
  }

  handleSignOut = () => {
    const { contractors, activeContractor, onSignOut } = this.props
    const currentContractor =
      contractors.find(item => item.ID === activeContractor) || {}

    this.setState({
      contractorName: pathOr('', ['TITLE'], currentContractor)
    })
    onSignOut()
  }

  onShowContractorModal = () => {
    this.setState({
      menuActive: false
    })
    this.props.showModal('changeContractor')
  }

  render() {
    const {
      contractors,
      activeContractor,
      isLoyalty,
      isBookmark,
      personalTitle,
      isSuperuser,
      isExistsSuperuser
    } = this.props
    const currentContractor =
      contractors.find(item => item.ID === activeContractor) || {}
    const { menuActive } = this.state

    let color = 'blue'
    let shape = 'flat'
    if (isLoyalty) {
      color = 'purple'
      shape = 'default'
    }
    if (isBookmark) {
      color = 'orange'
    }
    if (isExistsSuperuser) {
      color = 'red'
    }
    return (
      <div
        className={cx(styles.headerTop, {
          [styles.headerTop_superuser]: isExistsSuperuser
        })}
      >
        <div className={styles.headerBack}>
          {isLoyalty && (
            <Link to='/'>
              <div className={styles.linkToMain}>
                <Icon
                  icon='up'
                  size='small'
                  className={styles.linkToMainIcon}
                />
                Вернуться в основной каталог
              </div>
            </Link>
          )}
        </div>
        <div className={styles.headerInfo}>
          <NotificationPush isLoyalty={isLoyalty} />
          <Interactions
            className={styles.contractor}
            onDelayedMouseEnter={this.handleShowUserMenu}
            onDelayedMouseLeave={this.handleHideUserMenu}
          >
            <Button
              size='tiny'
              shape={shape}
              type='button'
              color={color}
              stretched
            >
              <Icon
                icon='personal'
                size='small'
                className={styles.personalIcon}
              />
              {this.state.contractorName ||
                pathOr(personalTitle, ['TITLE'], currentContractor)}
            </Button>

            {menuActive && (
              <div className={styles.dropdown}>
                <UserPanel
                  isLoyalty={isLoyalty}
                  menuItems={this.props.menuItems}
                  isTempUser={this.props.isTempUser}
                  favoriteCount={this.props.favoriteCount}
                  compareCount={this.props.compareCount}
                  contractors={contractors}
                  activeContractor={this.props.activeContractor}
                  setContractor={this.props.setContractor}
                  onSignOut={this.handleSignOut}
                  showModal={this.onShowContractorModal}
                  isSuperuser={isSuperuser}
                  isExistsSuperuser={isExistsSuperuser}
                  isHeader
                />
              </div>
            )}
          </Interactions>
        </div>
      </div>
    )
  }
}

export default HeaderTop
