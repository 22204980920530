/* eslint-disable complexity */
/* eslint-disable camelcase */
import { propOr, has, pathOr, keys, head } from 'ramda'

import config from 'config'

export const getThemeName = state => {
  const { change_client, credentials } = SERVER
    ? config.apiAuth
    : propOr({}, 'apiAuth', state)
  const query = pathOr({}, ['router', 'location', 'query'], state)
  const hasQueryParams =
    change_client && has('client_id', query) && has('client_secret', query)
  const { client_id } = hasQueryParams ? query : credentials
  return config.clientThemes[client_id] || 'default'
}

export const getClientId = state => {
  const clientThemes = keys(config.clientThemes)
  const { change_client, credentials } = SERVER
    ? config.apiAuth
    : propOr({}, 'apiAuth', state)
  const query = pathOr({}, ['router', 'location', 'query'], state)
  const hasQueryParams =
    change_client && has('client_id', query) && has('client_secret', query)
  const { client_id } = hasQueryParams ? query : credentials
  return clientThemes.indexOf(client_id) !== -1 ? client_id : head(clientThemes)
}
