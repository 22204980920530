import React, { FC } from 'react'

import Modal from 'containers/Modal'

import FormCallBack from './FormCallBack'

const CallBackModal: FC = () => (
  <>
    {/* @ts-ignore */}
    <Modal
      id='callbackForm'
      title='Заказать обратный звонок'
    >
      <FormCallBack />
    </Modal>
  </>
)

export default CallBackModal
