/* eslint-disable react/jsx-no-bind */
import React, { Dispatch, Fragment, SetStateAction, TouchEvent, useCallback, useEffect, useState } from 'react'

import { equals, isEmpty, pathOr, propOr } from 'ramda'
import { type RootStateOrAny, useSelector } from 'react-redux'

import DimmerWrapper from 'components/DimmerWrapper'
import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import Image from 'components/Image'
import config from 'config'
import { display } from 'decorators/device'
import { getManagers } from 'redux/modules/personal'
import { pathnameSelector } from 'redux/modules/settings'
import { getTheme } from 'redux/modules/theme'
import { isAuthSelector } from 'redux/modules/token'
import Button from 'UI/Button'
import Text from 'UI/Text'

import styles from './HeaderManagers.scss'

interface ManagerProps {
  email: string
  name: string
  phone: string
  photo: string
  position: string
  showPhoto: boolean
}

const Manager = ({
  email,
  name,
  phone,
  photo,
  position,
  showPhoto
}: ManagerProps) => {
  return (
    <li className={styles.managersItem}>
      {showPhoto && (
        <Image src={photo}
          backgroundSize='contain'
          height={90}
          width={90} />
      )}
      <div className={styles.managersInfo}>
        <div className={styles.managersInfoText}>
          <Text semibold>{name}</Text>
          <Text>{position}</Text>
        </div>
        <div className={styles.managersInfoContacts}>
          <Text>{phone}</Text>
          <a href={`mailto:${email}`}
            className={styles.managersInfoLink}>
            {email}
          </a>
        </div>
      </div>
    </li>
  )
}

const extractManagerProps = (manager: ManagerProps) => ({
  name: propOr('', 'TITLE', manager) as string,
  position: propOr('', 'POSITION', manager) as string,
  phone: propOr('', 'PHONE', manager) as string,
  email: propOr('', 'EMAIL', manager) as string,
  photo: propOr('', 'PHOTO', manager) as string,
  code: propOr('', 'POSITION_CODE', manager) as string
})

const useManagersSelectors = () => {
  const { theme } = useSelector(state => getTheme(state))

  const isAuth = useSelector(({ token }: RootStateOrAny) => isAuthSelector(token))

  const managers = useSelector(({ personal }: RootStateOrAny) => getManagers(personal) as ManagerProps[])

  const pathname = useSelector(({ router }: RootStateOrAny) => pathnameSelector(router))

  return { theme, isAuth, managers, pathname }
}

const useHandleTouch = (setTouched: Dispatch<SetStateAction<boolean>>) => {
  return useCallback((e: TouchEvent) => {
    e.stopPropagation()
    if (e.target instanceof HTMLElement && e.target.tagName !== 'BUTTON') {
      setTouched(prev => !prev)
    }
  }, [setTouched])
}

const getMenuProps = (isDesktop: boolean, touched: boolean) => {
  return isDesktop
    ? { text: 'Менеджеры' }
    : {
      emptyText: true,
      dropDownClassName: touched ? styles.managersDropDown : '',
      renderPopup: touched
    }
}

const getTagName = (isDesktop: boolean) => isDesktop ? Fragment : DimmerWrapper

const HeaderManagers = ({ isDesktop }: { isDesktop: boolean }) => {
  const { theme, isAuth, managers, pathname } = useManagersSelectors()

  const shouldReturnNull = () => !isAuth || isEmpty(managers)

  const [touched, setTouched] = useState(false)

  const handleGetThemeIcon = useCallback((icon: string) => {
    return pathOr('', ['themes', theme, 'icons', icon], config)
  }, [theme])

  const handleTouch = useHandleTouch(setTouched)

  const renderManagers = useCallback(() => {
    return managers.map((manager: ManagerProps) => {
      const { name, position, phone, email, photo, code } = extractManagerProps(manager)
      const showPhoto = equals(code, 'MANAGER')
      return (
        <Manager
          email={email}
          key={code}
          name={name}
          phone={phone}
          photo={photo}
          position={position}
          showPhoto={showPhoto}
        />
      )
    })
  }, [managers])

  useEffect(() => {
    setTouched(false)
  }, [pathname])

  const restProps = getMenuProps(isDesktop, touched)
  const Tag = getTagName(isDesktop)

  if (shouldReturnNull()) {
    return null
  }

  return (
    <div
      className={styles.iconWrapper}
      role='presentation'
      onTouchStart={handleTouch}
    >
      <HeaderMenuItem
        iconPath={handleGetThemeIcon('manager')}
        iconWrapper='manager'
        {...restProps}
      >
        <Tag>
          <ul className={styles.managersList}>
            {renderManagers()}
            {/* кнопка для мобильной версии */}
            {!isDesktop && (
              <Button stretched
                to='/offices'
                color='whiteBlue'
                size='small'>
                Адреса филиалов
              </Button>
            )}
          </ul>
        </Tag>
      </HeaderMenuItem>
    </div>
  )
}

export default display(HeaderManagers)
