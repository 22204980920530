import React from 'react'

import cx from 'classnames'
import { range } from 'ramda'

import s from './Placeholder.scss'

const PRODUCTS = 5

const CartProducts = () => {
  return (
    <div className={s.item}>
      {range(0, PRODUCTS).map(item => (
        <div className={cx(s.col, s.col12)}
          key={item}>
          <div className={s.row}>
            <div className={cx(s.col, s.col1, s.big)} />
            <div className={cx(s.col, s.col1, s.empty, s.big)} />
            <div className={cx(s.col, s.col4, s.big)} />
            <div className={cx(s.col, s.col1, s.empty, s.big)} />
            <div className={cx(s.col, s.col2, s.big)} />
            <div className={cx(s.col, s.col1, s.empty, s.big)} />
            <div className={cx(s.col, s.col2, s.big)} />
          </div>
        </div>
      ))}
    </div>
  )
}

CartProducts.propTypes = {}

export default CartProducts
