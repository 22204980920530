import { pathOr, propOr } from 'ramda'
import { Action } from 'redux-act'
import { SubmissionError } from 'redux-form'

import { hideModal, showModal } from 'redux/modules/modal'

type params = {
  clientApi: {
    post: (str: string, reqParams: {}) => Promise<object>
  }
  product: object
  contractorName: string
  email: string
}

const TIMEOUT = 2000
// пост запрос с данными формы
const submit =
  ({ product = {}, contractorName = '', email = '', clientApi }: params) =>
    (values: any, dispatch: (arg0: Action<null, null>) => void) => {
      const id = propOr(null, 'ID', product)
      const guid = propOr(null, 'XML_ID', product)

      return clientApi
        .post('/v3/form/send/product_feedback/', {
          params: {
            ...values,
            product_guid: guid,
            product_id: id,
            user_mail: email,
            contractor_name: contractorName
          }
        })
        .then((res: object) => {
          const success = pathOr(false, ['data', 'response', 'SUCCESS'], res)
          if (!success) {
            const errors = pathOr({}, ['data', 'errors'], res)
            throw new SubmissionError({ ...errors })
          }
          // @ts-ignore
          dispatch(showModal('leaveAFeedbackSuccess'))
          // @ts-ignore
          setTimeout(() => dispatch(hideModal('leaveAFeedbackSuccess')), TIMEOUT)
        })
        .catch(() => {
          // @ts-ignore
          dispatch(showModal('leaveAFeedbackFailure'))
          // @ts-ignore
          setTimeout(() => dispatch(hideModal('leaveAFeedbackFailure')), TIMEOUT)
        })
    }

export default submit
