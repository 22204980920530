/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { string, bool, element } from 'prop-types'
import { Link } from 'react-router-dom'

class HeaderMenuLink extends PureComponent {
  static propTypes = {
    className: string,
    itemClassName: string,
    linkTo: string,
    disabled: bool,
    pureLink: bool,
    target: string,
    children: element
  }

  static defaultProps = {
    className: '',
    linkTo: null,
    target: null
  }

  render() {
    const {
      linkTo,
      disabled,
      children,
      pureLink,
      target,
      className,
      itemClassName
    } = this.props

    const LinkComponent = pureLink ? 'a' : Link
    return (
      <div className={className}>
        {!disabled && linkTo ? (
          <LinkComponent
            to={linkTo}
            href={linkTo}
            disabled={disabled}
            target={target}
            rel={target === '_blank' ? 'noopener noreferrer' : null}
          >
            <span className={itemClassName}>{children}</span>
          </LinkComponent>
        ) : (
          <div className={itemClassName}>{children}</div>
        )}
      </div>
    )
  }
}

export default HeaderMenuLink
