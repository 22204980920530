import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string, func, number, array } from 'prop-types'
import { prop, equals, not, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { compose } from 'redux'

import config from 'config'
import Modal from 'containers/Modal'
import { showModal, hideModal } from 'redux/modules/modal'
import { socketConnect, tick } from 'redux/modules/socket'

import s from './Socket.scss'

const TIMEOUT = 1000

const isActiveSocket = WrapperComponent => props => {
  if (config.socket.isActive) {
    return <WrapperComponent {...props}/>
  }

  return null
}

class Socket extends PureComponent {
  static propTypes = {
    showModal: func,
    hideModal: func,
    tick: func,
    socketConnect: func,
    events: array,
    isActive: bool,
    isAuth: bool,
    countTry: number,
    modalMessage: string,
    modalTitle: string
  }

  static defaultProps = {
    showModal: () => {},
    hideModal: () => {},
    tick: () => {},
    events: [],
    isActive: false,
    modalMessage: '',
    modalTitle: ''
  }

  componentDidMount() {
    this.handleSocketConnect()
  }

  componentDidUpdate(prevProps) {
    if (not(equals(prevProps.countTry, this.props.countTry))) {
      this.props.tick()
    }
    if (not(equals(prevProps.isAuth, this.props.isAuth))) {
      this.handleSocketConnect()
    }
  }

  handleSocketConnect = () => {
    if (typeof window !== 'undefined' && this.props.isAuth) {
      setTimeout(() => {
        this.props.socketConnect()
      }, TIMEOUT)
    }
  }

  handleClose = () => {
    this.props.hideModal()
  }

  handleShow = name => () => {
    this.props.showModal(name)
  }

  render() {
    const { isActive, modalMessage, modalTitle, events } = this.props
    // чтобы открыть статус
    // document.querySelector('#socketStatus').style.display = 'flex'
    return (
      <>
        <div
          className={cx(s.socket, {
            [s.socketActive]: isActive
          })}
          id='socketStatus'
        >
          <div className={s.wrapper}>
            <div
              role='presentation'
              className={s.button}
              onClick={this.handleShow('socketEvents')}
            >
              События
            </div>
          </div>
        </div>
        <Modal title={modalTitle}
          id='socket'
          onClose={this.handleClose}>
          {modalMessage}
        </Modal>
        <Modal title='Cобытия'
          id='socketEvents'
          onClose={this.handleClose}>
          <>
            {events.map((item, key) => (
              <div
                className={s.event}
                // eslint-disable-next-line react/no-array-index-key
                key={key}
              >
                {JSON.stringify(item)}
              </div>
            ))}
          </>
        </Modal>
      </>
    )
  }
}

export default compose(
  connect(
    ({ socket, token }) => ({
      isActive: prop('isConnected', socket),
      modalMessage: pathOr('', ['modal', 'message'], socket),
      modalTitle: pathOr('', ['modal', 'title'], socket),
      helpId: prop('helpId', socket),
      countTry: prop('countTry', socket),
      events: prop('events', socket),
      isAuth: not(prop('isUserWithoutPassword', token))
    }),
    {
      showModal,
      hideModal,
      tick,
      socketConnect
    }
  ),
  isActiveSocket
)(Socket)
