/* eslint-disable complexity */
import R from 'ramda'
import { matchPath } from 'react-router'

import routes from '../routes'

const paramsOnAllPages = {
  'options[CATALOG_MENU]': '',
  'options[BACKGROUND]': ''
}

const getEqualPathFunc = pathname => str => pathname.indexOf(str) >= 0

const getBannerParams = ({ pathname }) => {
  const routeId = R.compose(
    R.pathOr(0, [0, 'params', 'id']),
    R.filter(item => R.pathOr(false, ['params', 'id'], item)),
    R.map(path => matchPath(pathname, { path })),
    R.map(item => R.prop('path', item)),
    R.path([0, 'routes'])
  )(routes)
  const equalPath = getEqualPathFunc(pathname)
  const isCatalog = equalPath('/catalog')
  const isCatalogList = equalPath('/catalog/')
  const isSearch = equalPath('/search')
  const isProduct = equalPath('/product/')
  const isBrand = equalPath('/brands/')
  const isLoyalty = equalPath('/loyalty')
  if (pathname === '/') {
    return {
      ...paramsOnAllPages,
      'options[MAIN]': '',
      'options[MAIN_RIGHT]': ''
    }
  }

  if (isSearch) {
    return {
      ...paramsOnAllPages,
      'options[CATALOG_SECTION]': 'search'
    }
  }

  if ((isCatalog || isCatalogList) && routeId) {
    return {
      ...paramsOnAllPages,
      'options[CATALOG_SECTION]': routeId
    }
  }

  if (isProduct && routeId) {
    return {
      ...paramsOnAllPages,
      'options[CATALOG_PRODUCT]': routeId
    }
  }

  if (isBrand && routeId) {
    return {
      ...paramsOnAllPages,
      'options[BRAND]': routeId
    }
  }

  if (isLoyalty) {
    return {
      ...paramsOnAllPages,
      'options[MAIN_SIDEBAR]': '',
      'options[LOYALTY]': ''
    }
  }

  return {
    ...paramsOnAllPages
  }
}

export default getBannerParams
