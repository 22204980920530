/* eslint-disable complexity */
import axios from 'axios'
import { compose, pathOr, propOr, values, path, reduce } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

const initialState = {
  vote: 0,
  vk: 0,
  fb: 0,
  ok: 0
}

export const vote = createAction('share/VOTE')
export const voteSuccess = createAction('share/VOTE_SUCCESS')
export const voteFailure = createAction('share/VOTE_FAILURE')

export const fetchShareCount = createAction('share/GET_SHARE_COUNT')
export const fetchShareCountSuccess = createAction(
  'share/GET_SHARE_COUNT_SUCCESS'
)
export const fetchShareCountFailure = createAction(
  'share/GET_SHARE_COUNT_FAILURE'
)

const parser = _data => {
  const data = path(['data', 'response'], _data)
  const social = path(['data', 'name'], _data)
  switch (social) {
    case 'vk':
      return { vk: parseInt(data.split(',')[1].trim(), 10) || 0 }
    case 'fb':
      return { fb: pathOr(0, ['share', 'share_count'], data) }
    case 'ok':
      return { ok: propOr(0, 'count', data) }
    case 'mailru':
      return { mailru: compose(pathOr(0, [0, 'shares']), values)(data) }
    default:
      return { [social]: 0 }
  }
}

const effects = url => [
  axios.get(`/share/vk/?url=${url}`),
  axios.get(`/share/fb/?url=${url}`),
  axios.get(`/share/ok/?url=${url}`),
  axios.get(`/share/mailru/?url=${url}`)
]

const request = url =>
  axios
    .all(effects(url))
    .then(fetchShareCountSuccess)
    .catch(fetchShareCountFailure)

const handleFetchShareCount = (state, url) =>
  loop(
    {
      ...state
    },
    Effects.promise(request, url)
  )

const handleFetchShareCountSuccess = (state, payload) => ({
  ...state,
  ...reduce(
    (acc, item) => ({
      ...acc,
      ...parser(item)
    }),
    {},
    payload
  )
})

const handleFetchShareCountFailure = (state, payload) => ({
  ...state,
  error: path(['data'], payload)
})

const requestVote =
  ({ clientApi }) =>
    ({ id, type, method }) => {
      const params = method === 'put' ? { params: { value: 1 } } : {}
      return clientApi[method](`/v1/votes/${type}/${id}/`, params)
        .then(voteSuccess)
        .catch(voteFailure)
    }

const handleVote = (state, { id, type, isSend }, { clientApi }) =>
  loop(
    {
      ...state,
      voteError: null
    },
    Effects.promise(requestVote({ clientApi }), {
      id,
      type,
      method: isSend ? 'put' : 'get'
    })
  )

const handleVoteSuccess = (state, payload) => {
  let voteValue = path(['data', 'response', 'RATING'], payload)
  let voteError
  if (!voteValue) {
    if (path(['data', 'response', 'SUCCESS'], payload) === 'Y') {
      voteValue += 1
    } else {
      voteError = path(['data', 'response', 'WARNING_CODE'], payload)
    }
  }

  return {
    ...state,
    vote: voteValue,
    voteError
  }
}

const handleVoteFailure = (state, payload) => ({
  ...state,
  voteError: getErrorMessage(payload)
})

const reducer = createReducer(on => {
  on(fetchShareCount, handleFetchShareCount)
  on(fetchShareCountSuccess, handleFetchShareCountSuccess)
  on(fetchShareCountFailure, handleFetchShareCountFailure)

  on(vote, handleVote)
  on(voteSuccess, handleVoteSuccess)
  on(voteFailure, handleVoteFailure)
}, initialState)

export default reducer
