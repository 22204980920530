import { path, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = createSelector(state => state, state => propOr(false, 'isLoaded', state))
export const getIsLoading = createSelector(state => state, state => propOr(false, 'isLoading', state))
export const getBanners = state => state.types
export const getBannerType = type => state => path(['types', type], state)
export const bannerMenuSelector = createSelector(
  ({ banners = {} }) => banners,
  ({ sectionId = 0 }) => sectionId,
  (banner, sectionId) => prop(sectionId, banner) || {}
)
