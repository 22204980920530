/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import debounce from 'lodash.debounce'
import { string, array, bool, func, object } from 'prop-types'
import R from 'ramda'
import { Field, Fields } from 'redux-form'

import InputPassword from 'components/InputPassword'
import InputWithErrors from 'components/InputWithErrors'
import PersonalConfirm from 'components/PersonalConfirm'
import RegistrationCheckbox from 'components/Registration/RegistrationCheckbox'
import RegistrationCountry from 'components/Registration/RegistrationCountry'
import RegistrationPhone from 'components/Registration/RegistrationPhone'
import RegistrationRussiaSpecific from 'components/Registration/RegistrationRussiaSpecific'
import RegistrationTextarea from 'components/Registration/RegistrationTextarea'
import SubmitMessage from 'components/Registration/SubmitMessage'
import { setAnalyticsProps } from 'helpers/metaTags'
import Button from 'UI/Button'
import Spacing from 'UI/Spacing'

import styles from './Registration.scss'

const TIMEOUT_300 = 300

class Registration extends PureComponent {
  static propTypes = {
    isKdClient: bool,
    isSuggestionsError: bool,
    disabled: bool,
    isSended: bool,
    countries: array,
    suggestionItems: array,
    message: string,
    errors: object,
    onSubmit: func,
    onSubmitError: func,
    change: func,
    fetchCompanyData: func,
    theme: string
  }

  static defaultProps = {
    countries: [],
    suggestionItems: [],
    message: '',
    isSuggestionsError: false,
    isSended: false,
    isKdClient: false,
    onSubmit: () => {}
  }

  state = {
    isFieldReadOnly: false
  }

  handleChangeAutocomplete = input => (event, selected) => {
    const { change } = this.props
    const title = R.path(['target', 'value'], event)

    input.onChange(title)

    if (!selected) {
      this.onFetchData({ query: title })
    } else {
      // заполняем обязательные поля данными из dadata
      change('title', R.propOr('', 'value', selected))
      change('inn', R.pathOr('', ['data', 'inn'], selected))
      change('ogrn', R.pathOr('', ['data', 'ogrn'], selected))
      change('address', R.pathOr('', ['data', 'address', 'value'], selected))
      change('kladr', R.pathOr('', ['data', 'address', 'data', 'kladr_id'], selected))

      // запрещаем редактировать автокомплит
      this.setState({ isFieldReadOnly: true })
    }
  }

  handleClearAutocomplete = () => {
    const { change } = this.props
    // очищаем поля, связанные с автокомплитом 
    change('title', '')
    change('address', '')
    change('inn', '')
    change('ogrn', '')
    change('kladr', '')
    // разрешаем редактировать автокомплит
    this.setState({ isFieldReadOnly: false })
  }

  onChangeCode = id => {
    const { countries, change } = this.props
    const index = R.findIndex(R.propEq('id', id))(countries)
    change('phoneCode', R.pathOr('', [index, 'phoneCode'], countries))
    // если после ввода всех данных сменили страну
    this.handleClearAutocomplete()
  }

  onFetchData = debounce(query => {
    this.props.fetchCompanyData(query)
  }, TIMEOUT_300)

  render() {
    const {
      isKdClient,
      isSended,
      message,
      countries,
      disabled,
      suggestionItems,
      errors,
      onSubmit,
      onSubmitError,
      isSuggestionsError
    } = this.props

    const { isFieldReadOnly } = this.state

    return (
      <div className={styles.registration}>
        {!isSended && R.isEmpty(message) && (
          <form
            className={styles.registration__form}
            onSubmit={onSubmit}
          >
            <div
              className={styles.registration__form_fields}
              { ...setAnalyticsProps({
                type: 'action',
                group: 'registration',
                action: 'form'
              }) }
            >
              <div className={styles.registration__title}>
                Регистрация в интернет-магазине<br/>
                {isKdClient ? 'Здесь вы купите всё для работы вашей организации' : 'для оптовых покупателей'}
              </div>
              <div className={styles.registration__field}>
                <div className={styles.registration__label}>Выберите страну</div>
                <Field
                  name='country_code'
                  component={RegistrationCountry}
                  items={countries}
                  handleChangeCode={this.onChangeCode}
                />
              </div>
              <div className={styles.registration__field}>
                <div className={styles.registration__label}>Контактная информация</div>
                <Spacing
                  margin='bottom'
                  size='small'
                >
                  <Field
                    name='name'
                    component={InputWithErrors}
                    placeholder='Имя'
                    errorSide='left'
                  />
                </Spacing>
                <Spacing
                  margin='bottom'
                  size='small'
                >
                  <Field
                    name='last_name'
                    component={InputWithErrors}
                    placeholder='Фамилия'
                  />
                </Spacing>

                <Fields
                  names={['phoneCode', 'phone']}
                  component={RegistrationPhone}
                  countries={countries}
                  isFieldSelect={false}
                />

                <RegistrationRussiaSpecific
                  isReadOnly={isFieldReadOnly}
                  onChangeAutocomplete={this.handleChangeAutocomplete}
                  onClearAutocomplete={this.handleClearAutocomplete}
                  suggestionItems={suggestionItems}
                  isShowAddress={isSuggestionsError}
                />

                <Field
                  name='email'
                  component={InputWithErrors}
                  placeholder='Email'
                />
                <Field
                  name='new_password'
                  component={InputPassword}
                  placeholder='Пароль'
                />
              </div>

              <div className={styles.registration__field}>
                <div className={styles.registration__label}>Сообщение</div>
                <Field
                  name='comment'
                  component={RegistrationTextarea}
                  placeholder='Комментарий'
                  maxLength='500'
                />
              </div>

              <div className={styles.registration__checkbox}>
                <Field
                  name='personal'
                  component={RegistrationCheckbox}
                >
                  <PersonalConfirm />
                </Field>
              </div>
            </div>
            <Button
              type='submit'
              color='blue'
              size='big'
              title='Отправить заявку'
              disabled={disabled}
              stretched
            />
          </form>
        )}

        {message && !isSended && (
          <SubmitMessage
            errors={errors}
            onSubmit={onSubmitError}
            isError
          />
        )}

        {isSended && (
          <SubmitMessage />
        )}
      </div>
    )
  }
}
export default Registration
