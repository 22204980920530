import React, { useCallback } from 'react'

import { func } from 'prop-types'

import { modalActions } from 'decorators/modal'
import Button from 'UI/Button'

const ContractorChangeButton = ({ showModal }) => {
  const onOpenModal = useCallback(() => {
    showModal('changeContractor')
  }, [])
  return (
    <Button
      color='lightBlue'
      icon='change'
      onClick={onOpenModal}
      stretched
    >
      Сменить контрагента
    </Button>
  )
}

ContractorChangeButton.propTypes = {
  showModal: func
}

export default modalActions(ContractorChangeButton)
