import React, { FC } from 'react'

import cx from 'classnames'

import styles from './Text.scss'
import { TextColorProps } from './types'

interface ITextProps {
  children: React.ReactNode;
  component?: React.JSX.ElementType;
  block?: boolean;
  color?: TextColorProps;
  underline?: boolean;
  lineThrough?: boolean;
  bold?: boolean;
  semibold?: boolean;
}

const Text: FC<ITextProps> = ({
  component = 'p',
  block,
  children,
  color,
  underline,
  lineThrough,
  bold,
  semibold
}) => {
  const Tag = component
  
  return (
    <Tag
      className={cx({
        [styles.text]: true,
        [styles[`text__color_${color}`]]: color,
        [styles.text__block]: block,
        [styles.text__underline]: underline,
        [styles.text__lineThrough]: lineThrough,
        [styles.text__bold]: bold,
        [styles.text__semibold]: semibold
      })}
    >
      {children}
    </Tag>
  )
}

export default Text
