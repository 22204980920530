import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Offices = loadable(() =>
  import(/* webpackChunkName: "Offices" */ 'pages/Offices/Offices')
)
export default asyncConnect([
  {
    key: 'pageOffices',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'offices' })
      return updateData.update()
    }
  }
])(Offices)
