import React, { Fragment, useCallback, useEffect } from 'react'

import { bool, func } from 'prop-types'

import Interactions from 'components/Interactions'
import Portal from 'components/Portal'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import { display } from 'decorators/device'

import s from './LayerOpacity.scss'

const LayerOpacity = ({ onClose = () => {}, isDesktop }) => {
  const Tag = isDesktop ? Fragment : DisableBodyScroll

  const onCloseLayerOpacity = useCallback(() => onClose(false), [onClose])

  useEffect(() => {
    window.addEventListener('scroll', onCloseLayerOpacity)
    window.addEventListener('resize', onCloseLayerOpacity)

    return () => {
      window.removeEventListener('scroll', onCloseLayerOpacity)
      window.removeEventListener('resize', onCloseLayerOpacity)
    }
  })

  return (
    <Tag>
      <Portal>
        <Interactions>
          <div
            role='presentation'
            className={s.layerOpacity}
            onClick={onCloseLayerOpacity}
          />
        </Interactions>
      </Portal>
    </Tag>
  )
}

LayerOpacity.propTypes = {
  isDesktop: bool,
  onClose: func
}

export default display(LayerOpacity)
