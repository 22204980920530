/* eslint-disable complexity */
import { propOr, pathOr, map, indexBy, prop } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import moment from 'utils/moment'

const DEFAULT_CATALOG_TYPE = 'main'

const initialState = {
  isLoading: false,
  isLoaded: false,
  sections: {},
  sectionsDetail: {},
  favorite: {},
  errorMessage: ''
}

export {
  getIsLoaded,
  getIsLoading,
  getSections,
  getSectionById,
  sectionsSelector,
  sectionsDataSelector,
  sectionsByIdSelector,
  sectionsIsLoadedSelector,
  sectionsIsLoadingSelector,
  sectionsMainSelector,
  sectionsFavoriteSelector,
  sectionBreadcrumbsSelector,
  sectionByIdSelector,
  favoriteHomeSelector,
  sectionDetailSelector,
  isLoadedSectionDetailSelector
} from './selector'

export const fetch = createAction('sections/GET')
export const fetchSuccess = createAction('sections/GET_SUCCESS')
export const fetchFailure = createAction('sections/GET_FAILURE')
export const fetchDetail = createAction('sections/GET')
export const fetchDetailSuccess = createAction('sections/GET_SUCCESS')
export const fetchDetailFailure = createAction('sections/GET_FAILURE')
export const fetchFavorite = createAction('sections/GET_FAVORITE')
export const fetchFavoriteSuccess = createAction(
  'sections/GET_FAVORITE_SUCCESS'
)
export const fetchFavoriteFailure = createAction(
  'sections/GET_FAVORITE_FAILURE'
)

export const request =
  ({ clientApi }) =>
    ({
      catalogType = DEFAULT_CATALOG_TYPE,
      bookmark = 0,
      sectionId = '',
      onSuccess = fetchSuccess,
      onFailure = fetchFailure
    }) => {
      const url = `/v3/catalog/${catalogType}${
        bookmark && bookmark > 0 ? `/${bookmark}` : ''
      }/sections/${sectionId && `${sectionId}/`}`
      return clientApi
        .get(url, {
          params: {
            contractor_id: clientApi.getContractorId(),
            bookmark,
            mode: 'cache',
            datereq: moment().format('HHDDMMYYYY')
          }
        })
        .then(data => onSuccess({ ...data, catalogType, bookmark, sectionId }))
        .catch(onFailure)
    }

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleFetchSuccess = (state, payload) => {
  const sortToNumber = x => ({
    ...x,
    SORT: parseInt(x.SORT, 10),
    DEPTH: x.DEPTH,
    CNT: x.COUNT
  })

  const data = indexBy(
    prop('ID'),
    pathOr([], ['data', 'response', 'ITEMS'], payload)
  )
  const catalogType = propOr(DEFAULT_CATALOG_TYPE, 'catalogType', payload)
  const bookmark = propOr(0, 'bookmark', payload)
  const items = map(sortToNumber, { ...data })
  if (catalogType === 'bookmark') {
    return {
      ...state,
      [catalogType]: {
        ...state.bookmark,
        [bookmark]: {
          isLoading: false,
          isLoaded: true,
          data: {
            ...items
          }
        }
      }
    }
  }
  return {
    ...state,
    [catalogType]: {
      isLoading: false,
      isLoaded: true,
      data: {
        ...items
      }
    }
  }
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errorMessage: getErrorMessage(payload)
})

const handleFetchDetail = (state, payload, { clientApi }) => {
  const sectionId = propOr('', 'sectionId', payload)
  return loop(
    {
      ...state,
      sectionsDetail: {
        ...state.sectionsDetail,
        [sectionId]: {}
      }
    },
    Effects.promise(request({ clientApi }), {
      ...payload,
      onSuccess: fetchDetailSuccess,
      onFailure: fetchDetailFailure
    })
  )
}

const handleFetchDetailSuccess = (state, payload) => {
  const sectionId = propOr('', 'sectionId', payload)
  return {
    ...state,
    sectionsDetail: {
      ...state.sectionsDetail,
      [sectionId]: pathOr({}, ['data', 'response', 'ITEM'], payload)
    }
  }
}

const handleFetchDetailFailure = (state, payload) => ({
  ...state,
  sectionsDetail: {
    ...state.sectionsDetail,
    errorMessage: getErrorMessage(payload)
  }
})

export const requestFavorite =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/catalog/main/sections/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            favorite: true,
            ...params
          }
        })
        .then(fetchFavoriteSuccess)
        .catch(fetchFavoriteFailure)

const handleFetchFavorite = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      favorite: {
        ...state.favorite,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestFavorite({ clientApi }), params)
  )

const handleFetchFavoriteSuccess = (state, payload) => ({
  ...state,
  favorite: {
    isLoading: false,
    isLoaded: true,
    data: pathOr({}, ['data', 'response', 'ITEMS'], payload)
  }
})

const handleFetchFavoriteFailure = (state, payload) => ({
  ...state,
  favorite: {
    isLoading: false,
    isLoaded: false,
    errorMessage: getErrorMessage(payload)
  }
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchDetail, handleFetchDetail)
  on(fetchDetailSuccess, handleFetchDetailSuccess)
  on(fetchDetailFailure, handleFetchDetailFailure)
  on(fetchFavorite, handleFetchFavorite)
  on(fetchFavoriteSuccess, handleFetchFavoriteSuccess)
  on(fetchFavoriteFailure, handleFetchFavoriteFailure)
}, initialState)

export default reducer
