import React from 'react'

import { bool } from 'prop-types'
import { connect } from 'react-redux'

import { getIsLoaded as getIsLoadedMenu } from 'redux/modules/marksMenu'
import { getIsLoaded as getIsLoadedPersonal } from 'redux/modules/personal'
import { sectionsIsLoadedSelector as getIsLoadedSections } from 'redux/modules/sections'

import s from './Monitoring.scss'

const Monitoring = props => {
  return (
    <div className={s.hidden}>
      {props.status && <div id='page-load'>true</div>}
    </div>
  )
}

Monitoring.propTypes = {
  status: bool
}

export default connect(({ personal, marksMenu, sections }) => ({
  status:
    getIsLoadedPersonal(personal) &&
    getIsLoadedMenu(marksMenu) &&
    getIsLoadedSections({ sections })
}))(Monitoring)
