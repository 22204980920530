/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { func, object, array, bool } from 'prop-types'
import { isEmpty } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { reduxForm, Field, formValueSelector } from 'redux-form'

import InputWithErrors from 'components/InputWithErrors'
import Modal from 'containers/Modal'
import { forUnauth } from 'decorators/user'
import { recoveryForm, onRecoverySubmit } from 'helpers/registration'
import {
  setValues,
  recoveryMessagesSelector,
  recoveryErrorsSelector,
  isSendedRecoverySelector
} from 'redux/modules/registration'

import styles from './PopupRecoveryPassword.scss'

const selector = formValueSelector('auth')

@forUnauth
@connect(
  state => {
    const { registration } = state
    const email = selector(state, 'email')
    return {
      recoveryMessages: recoveryMessagesSelector(registration),
      recoveryErrors: recoveryErrorsSelector(registration),
      isSendedRecovery: isSendedRecoverySelector(registration),
      initialValues: {
        email
      }
    }
  },
  { setValues }
)
@inject(({ helpers: { clientApi } }) => ({ clientApi }))
@reduxForm({ ...recoveryForm })
class PopupRecoveryPassword extends PureComponent {
  static propTypes = {
    handleSubmit: func,
    setValues: func,
    reset: func,
    clientApi: object,
    recoveryErrors: array,
    recoveryMessages: array,
    isSendedRecovery: bool,
    invalid: bool,
    pristine: bool,
    submitting: bool
  }

  static defaultProps = {
    recoveryErrors: [],
    recoveryMessages: [],
    isSendedRecovery: false
  }

  handleCloseModal = () => {
    this.props.reset()
    this.props.setValues({
      recoveryErrors: [],
      recoveryMessages: [],
      isSendedRecovery: false
    })
  }

  renderMessages = () => {
    const { recoveryMessages } = this.props
    return !isEmpty(recoveryMessages) && (
      <div className={styles.messages}>
        {recoveryMessages.map(message => (
          <div key={message}>{message}</div>
        ))}
      </div>
    )
  }

  renderErrors = () => {
    const { recoveryErrors } = this.props
    return !isEmpty(recoveryErrors) && (
      <div className={styles.errors}>
        {recoveryErrors.map(error => (
          <div key={error}>{error}</div>
        ))}
      </div>
    )
  }

  render() {
    const {
      isSendedRecovery,
      handleSubmit,
      invalid,
      pristine,
      submitting,
      clientApi
    } = this.props
    return (
      <Modal
        id='recoveryPassword'
        onClose={this.handleCloseModal}
        title='Восстановление пароля'
      >
        {isSendedRecovery ? (
          <>{this.renderMessages()}</>
        ) : (
          <>
            <div className={styles.description}>
                Укажите электронную почту, на которую зарегистрирован личный
                кабинет. На этот адрес будет отправлена инструкция по смене
                пароля
            </div>
            <form
              id='recoveryForm'
              className={styles.form}
              onSubmit={handleSubmit(onRecoverySubmit({ clientApi }))}
            >
              {this.renderErrors()}
              {this.renderMessages()}

              <div className={styles.input}>
                <Field
                  name='email'
                  component={InputWithErrors}
                  errorSide='bottom'
                  className={styles.inputEmail}
                  placeholder='Email'
                />
              </div>
              <div className={styles.buttonRelative}>
                <button
                  type='submit'
                  className={styles.submit}
                  disabled={invalid || pristine || submitting}
                >
                    Отправить заявку
                </button>
              </div>
            </form>
          </>
        )}
      </Modal>
    )
  }
}

export default PopupRecoveryPassword
