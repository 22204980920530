import { compose, map, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const metaSelector = createSelector(
  state => state,
  state => propOr({}, 'meta', state)
)

export const metaBreadcrumbsSelector = createSelector(metaSelector, meta =>
  compose(
    map(breadcrumb => ({
      title: propOr('', 'TITLE', breadcrumb),
      pathname: propOr('#', 'PATH', breadcrumb)
    })),
    propOr([], 'BREADCRUMBS')
  )(meta)
)
