/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { func, bool, array, string } from 'prop-types'
import { propOr, startsWith } from 'ramda'

import Icon from 'components/Icon'
import Link from 'components/Link'

import styles from './UserPanel.scss'

export default class UserPanel extends PureComponent {
  static propTypes = {
    onSignOut: func,
    isLoyalty: bool,
    menuItems: array,
    contractors: array,
    showModal: func,
    pathname: string,
    isSuperuser: bool,
    isExistsSuperuser: bool,
    isHeader: bool
  }

  static defaultProps = {
    onSignOut: () => {},
    showModal: () => {},
    menuItems: [],
    contractors: [],
    isSuperuser: false,
    isExistsSuperuser: false,
    isHeader: false
  }

  handleChangeContractor = () => {
    this.props.showModal('changeContractor')
  }

  handleSignOut = () => {
    this.props.onSignOut()
    if (typeof window !== 'undefined' && window.location) {
      window.location.replace('/')
    }
  }

  renderMenuItem = item => {
    const { isLoyalty, pathname, isHeader } = this.props
    const name = propOr('', 'name', item)
    const url = propOr('', 'url', item)
    const isCurrent = !isHeader && startsWith(url, pathname)
    return (
      <li
        itemProp='itemListElement'
        itemScope
        itemType='http://schema.org/ItemList'
        key={name}
        className={styles.item}
      >
        <Link to={url}>
          <div className={cx(styles.link, {
            [styles.linkLoyalty]: isLoyalty,
            [styles.linkActive]: isCurrent
          })}>
            <Icon
              icon={propOr('', 'icon', item)}
              size='small'
              className={styles.icon}
            />
            {propOr('', 'title', item)}
          </div>
        </Link>
      </li>
    )
  }

  render() {
    const {
      menuItems,
      isLoyalty,
      contractors,
      isSuperuser,
      isExistsSuperuser,
      isHeader
    } = this.props

    const showChangeContractor =
      contractors.length > 1 || isSuperuser || isExistsSuperuser
    return (
      <div className={styles.menu}>
        <ul>
          {showChangeContractor && (
            <li
              className={styles.item}
              itemProp='itemListElement'
              itemScope
              itemType='http://schema.org/ItemList'
            >
              <div
                role='presentation'
                className={cx(styles.link, {
                  [styles.linkLoyalty]: isLoyalty
                })}
                onClick={this.handleChangeContractor}
              >
                <Icon icon='change'
                  size='small'
                  className={styles.icon} />
                Сменить контрагента
              </div>
            </li>
          )}
          {menuItems.map(this.renderMenuItem)}
          <li
            className={cx(styles.item, styles.signOut, {
              [styles.signOut_highlighted]: isHeader
            })}
            itemProp='itemListElement'
            itemScope
            itemType='http://schema.org/ItemList'
          >
            <button
              type='button'
              className={cx(styles.link, styles.signout, {
                [styles.linkLoyalty]: isLoyalty
              })}
              onClick={this.handleSignOut}
            >
              <Icon icon='exit'
                size='small'
                className={styles.icon} />
              Выйти
            </button>
          </li>
        </ul>
      </div>
    )
  }
}
