/* eslint-disable complexity */
import {
  curry,
  findIndex,
  prop,
  propOr,
  pathOr,
  compose,
  join,
  isEmpty,
  filter,
  equals,
  toLower,
  dec,
  length
} from 'ramda'
import { createSelector } from 'reselect'

import { getCabinetBreadcrumbs } from 'helpers/cabinetConstants'
import renameKeys from 'helpers/objectModify'
import moment from 'utils/moment'
import roundBy from 'utils/roundBy'

const ROUND_2 = 2

const getDataByKey = createSelector(
  state => state,
  ({ key, obj }) => propOr({}, key, obj)
)

const keysToLower = renameKeys(toLower)

const getStatusState = createSelector(
  (key, currentStatusIndex) => {
    if (key < currentStatusIndex) return 'completed' // пройденый этап
    if (key === currentStatusIndex) return 'current' // текущий этап
    return 'uncompleted' // непройденый этап
  },
  state => state
)

const getStatusesChain = createSelector(
  state => state,
  ({ entities, order }) => {
    const chainCode = pathOr('', ['STATUS_CHAIN', 'CHAIN'], order)
    const statusesChain = pathOr([], ['STATUS_CHAIN', chainCode], entities)
    const chainLength = length(statusesChain)
    // если в цепочке один статус, его состояние Отменен
    if (equals(chainLength, 1)) {
      return statusesChain.map(item => ({
        ...item,
        state: 'canceled'
      }))
    }

    const chainLastIndex = dec(chainLength)
    const currentStatusCode = pathOr('', ['STATUS_CHAIN', 'GROUP'], order)
    const currentStatusIndex = findIndex(item =>
      equals(propOr('', 'CODE', item), currentStatusCode)
    )(statusesChain)

    return statusesChain.map((item, key) => ({
      ...item,
      state: equals(chainLastIndex, currentStatusIndex) // если текущий статус является последним, состояние Завершен
        ? 'completed'
        : getStatusState(key, currentStatusIndex)
    }))
  }
)

export const getIsLoaded = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)
export const getIsLoading = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const errorSelector = createSelector(
  state => state,
  state => propOr('', 'error', state)
)

export const getOrderData = createSelector(
  state => state,
  state => {
    const orderData = propOr({}, 'order', state)
    const order = propOr({}, 'BILL', orderData)
    const deliveryInfo = propOr({}, 'DELIVERY', orderData)
    const entities = propOr({}, 'ENTITIES', orderData)
    const userData = propOr({}, 'USER', entities)
    const typeData = propOr({}, 'TYPE', entities)
    const addressData = propOr({}, 'ADDRESS', entities)
    const contractorData = propOr({}, 'CONTRACTOR', entities)
    const storesData = propOr({}, 'STORE', entities)
    return {
      ...keysToLower(order),
      statusesChain: getStatusesChain({ entities, order }),
      delivery: deliveryInfo,
      address: getDataByKey({
        key: prop('ADDRESS', deliveryInfo),
        obj: addressData
      }),
      user: getDataByKey({ key: prop('USER', order), obj: userData }),
      contractor: getDataByKey({
        key: prop('CONTRACTOR', order),
        obj: contractorData
      }),
      store: getDataByKey({ key: prop('STORE', order), obj: storesData }),
      type: getDataByKey({ key: prop('TYPE', order), obj: typeData })
    }
  }
)

export const getOrderId = createSelector(getOrderData, order =>
  prop('id', order)
)
export const getOrderNum = createSelector(getOrderData, order =>
  propOr('', 'num', order)
)

export const getParam = curry((key, state) => state.params[key])
export const getSortOptions = createSelector(
  state => state,
  state => propOr([], 'sortOptions', state)
)

export const getOrderItems = createSelector(
  state => state,
  state => propOr([], 'products', state)
)

export const isPointsSelector = createSelector(
  state => state,
  state => propOr(false, 'isPoints', state)
)

const prepareNameData = createSelector(
  expeditor => propOr('', 'LAST_NAME', expeditor),
  expeditor => propOr('', 'NAME', expeditor),
  expeditor => propOr('', 'SECOND_NAME', expeditor),
  expeditor => propOr('', 'PHONE', expeditor),
  (lastName, name, secondName, phone) => {
    const expeditorName = compose(
      join(' '),
      filter(el => !isEmpty(el))
    )([lastName, name, secondName])
    return isEmpty(phone) ? expeditorName : `${expeditorName}, ${phone}`
  }
)
const prepareDeliveryData = createSelector(
  (type, address) =>
    compose(
      join(', '),
      filter(el => !isEmpty(el))
    )([type, address]),
  delivery => delivery
)

export const selectOrderInfo = createSelector(
  getOrderData,
  ({
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    cnt,
    sum = 0,
    actions = [],
    added_sum: addedSum = 0,
    loyalty_points: points = 0,
    delivery,
    address,
    documents,
    user,
    date,
    payments,
    ...order
  }) => ({
    ...order,
    date: date
      ? moment(date, 'X').locale('ru').format('ll').replace('.', '')
      : '',
    sum: roundBy(sum + addedSum, ROUND_2),
    addedSum: roundBy(addedSum, ROUND_2),
    points: roundBy(points, ROUND_2),
    user: prepareNameData(user),
    isBillForPoints: propOr('', 'CODE', payments) === 'LOYALTY',
    actions: filter(
      type => equals(type, 'confirm') || equals(type, 'delete'),
      actions
    ),
    expeditorInfo: prepareNameData(propOr({}, 'EXPEDITOR', delivery)),
    deliveryDate: prop('DATE', delivery)
      ? moment(prop('DATE', delivery), 'X').format('DD.MM.YYYY')
      : '',
    deliveryDatePossible: prop('DATE_POSSIBLE', delivery)
      ? moment(prop('DATE_POSSIBLE', delivery), 'X').format('DD.MM.YYYY')
      : '',
    deliveryInfo: prepareDeliveryData(
      pathOr('', ['TYPE', 'NAME'], delivery),
      propOr('', 'ADDRESS', address)
    ),
    docPrint: propOr(false, 'PRINTED', documents),
    certPrint: propOr(false, 'CERTIFICATION', documents),
    isBillActive: propOr(false, 'BILL', documents),
    isCanceled: equals(
      pathOr('CANCELED', ['status_chain', 'GROUP'], order),
      'CANCELED'
    )
  })
)

export const selectBreadcrumbs = createSelector(getOrderNum, num =>
  getCabinetBreadcrumbs([
    { title: 'Оформленные счета', pathname: '/cabinet/invoice' },
    { title: `Заказ ${num}` }
  ])
)

const selectSortIndex = createSelector(
  getSortOptions,
  getParam('sort'),
  getParam('order'),
  (items, sort, order) =>
    Math.max(
      0,
      findIndex(item => item.SORT === sort && item.ORDER === order)(items)
    )
)

export const selectSortOptions = createSelector(getSortOptions, options =>
  options.map(item => ({
    optionValue: item.TITLE,
    key: item.SORT,
    order: item.ORDER
  }))
)

export const selectParams = createSelector(
  selectSortIndex,
  getParam('limit'),
  getParam('page'),
  state => state.productsTotalCount,
  (sortIndex, limit, page, count) => ({
    sortIndex,
    limit,
    page,
    count
  })
)
