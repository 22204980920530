import { pathOr, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal, hideModal } from 'redux/modules/modal'

const initialState = {
  isLoading: false,
  isLoaded: false,
  item: {},
  sum: 0
}

export {
  isLoadedDiscounts,
  isLoadingDiscounts,
  getPeriod,
  getCurrentSum,
  getProgressStep,
  getCurrentStep,
  monthsDataSelector,
  diagramDataSelector,
  currentMonthSelector,
  estimateDaysSelector,
  updatingDateSelector,
  stepsSelector,
  errorMessageSelector,
  modalHtmlSelector,
  isLoadedModalSelector,
  errorTargetSelector,
  isCurrentPeriodSelector,
  basketStepsSelector
} from './selector'

export const fetch = createAction('discounts/FETCH')
export const fetchSuccess = createAction('discounts/FETCH_SUCCESS')
export const fetchFailure = createAction('discounts/FETCH_FAILURE')
export const setTarget = createAction('discounts/SET_TARGET')
export const setTargetSuccess = createAction('discounts/SET_TARGET_SUCCESS')
export const setTargetFailure = createAction('discounts/SET_TARGET_FAILURE')
export const fetchModalInfo = createAction('discounts/FETCH_MODAL_INFO')
export const fetchModalSuccess = createAction('discounts/FETCH_MODAL_SUCCESS')
export const fetchModalFailure = createAction('discounts/FETCH_MODAL_FAILURE')

const request =
  ({ clientApi }) =>
    () => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/contractor/discounts/', {
          params: { contractor_id: contractorId }
        })
        .then(fetchSuccess)
        .catch(fetchFailure)
    }

const requestSetTarget =
  ({ clientApi }) =>
    ({ stepId = '' }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post(`/v3/contractor/discounts/target/${stepId}/`, {
          params: {
            contractor_id: contractorId
          }
        })
        .then(setTargetSuccess)
        .catch(setTargetFailure)
    }

const requestModal =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v3/content/discount/instruction/')
        .then(fetchModalSuccess)
        .catch(fetchModalFailure)

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleSetTarget = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestSetTarget({ clientApi }), payload)
  )

export const handleFetchSuccess = (state, payload) =>
  loop(
    {
      ...state,
      item: pathOr({}, ['data', 'response', 'DISCOUNT'], payload),
      errorMessage: pathOr('', ['data', 'response', 'MESSAGE'], payload),
      isLoading: false,
      isLoaded: true
    },
    Effects.call(hideModal)
  )

export const handleFetchFailure = (state, payload) =>
  loop(
    {
      ...state,
      isLoading: false,
      isLoaded: false,
      errorMessage: pathOr('', ['data', 'response', 'MESSAGE'], payload)
    },
    Effects.call(showModal, 'refresh_failure')
  )

export const handleSetTargetSuccess = (state, payload) => {
  const isSuccess = pathOr(true, ['data', 'response', 'SUCCESS'], payload)
  return loop(
    {
      ...state,
      item: isSuccess
        ? pathOr({}, ['data', 'response', 'DISCOUNT'], payload)
        : propOr({}, 'item', state),
      isLoading: false,
      isLoaded: true,
      errorMessageTarget: pathOr(
        'Ошибка',
        ['data', 'response', 'MESSAGE'],
        payload
      )
    },
    isSuccess ? Effects.none() : Effects.call(showModal, 'discountsError')
  )
}

export const handleSetTargetFailure = (state, payload) =>
  loop(
    {
      ...state,
      isLoading: false,
      isLoaded: true,
      errorMessageTarget: pathOr(
        'Ошибка',
        ['data', 'response', 'MESSAGE'],
        payload
      )
    },
    Effects.call(showModal, 'discountsError')
  )

const handleFetchModal = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      modalHtml: {
        ...state.modalHtml,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestModal({ clientApi }), payload)
  )

export const handleFetchModalSuccess = (state, payload) => ({
  ...state,
  modalHtml: {
    ...state.modalHtml,
    item: pathOr('', ['data', 'response', 'ITEM'], payload),
    isLoading: false,
    isLoaded: true
  }
})
export const handleFetchModalFailure = state => ({
  ...state,
  modalHtml: {
    ...state.modalHtml,
    isLoading: false,
    isLoaded: false
  }
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(setTarget, handleSetTarget)
  on(setTargetSuccess, handleSetTargetSuccess)
  on(setTargetFailure, handleSetTargetFailure)
  on(fetchModalInfo, handleFetchModal)
  on(fetchModalSuccess, handleFetchModalSuccess)
  on(fetchModalFailure, handleFetchModalFailure)
}, initialState)

export default reducer
