import React, { useEffect } from 'react'

import { func, number, oneOfType, string } from 'prop-types'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'

import Portal from 'components/Portal'
import Modal from 'containers/Modal'
import ProductBarcodes from 'containers/ProductBarcodes'
import {
  setProductBarcodeModal,
  productBarcodeModalSelector
} from 'redux/modules/products'

const PopupProduct = ({ id, location, onSetProductBarcodeModal = () => {} }) => {
  const handleHidePopup = () => () => {
    onSetProductBarcodeModal('')
  }

  useEffect(() => {
    if (id) {
      onSetProductBarcodeModal('')
    }
  }, [location])

  if (!id) return null

  return (
    <Portal>
      <Modal id='barcode'
        size='big'
        onClose={handleHidePopup()}>
        <ProductBarcodes id={id}
          hideModal={handleHidePopup()} />
      </Modal>
    </Portal>
  )
}

PopupProduct.propTypes = {
  id: oneOfType([number, string]),
  location: string,
  onSetProductBarcodeModal: func
}

export default connect(
  ({ products, router }) => ({
    id: productBarcodeModalSelector(products),
    location: pathOr({}, ['location', 'pathname'], router)
  }),
  {
    onSetProductBarcodeModal: setProductBarcodeModal
  }
)(PopupProduct)
