import React from 'react'

import cx from 'classnames'
import { range } from 'ramda'

import s from './Placeholder.scss'

const ROWS = 3

const CartAction = () => {
  return (
    <div className={s.row}>
      {range(0, ROWS).map(item => (
        <div key={item}
          className={cx(s.col, s.col4, s.big)} />
      ))}
    </div>
  )
}

CartAction.propTypes = {}

export default CartAction
