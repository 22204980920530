import { path } from 'ramda'

export default payload => {
  const error = path(['data', 'error'], payload)
  const errorDescription = path(['data', 'error_description'], payload)

  const errorType = path(['response', 'data', 'meta', 'errorType'], payload)
  const errorDetail = path(['response', 'data', 'meta', 'errorDetail'], payload)

  const status = path(['status'], payload)
  const statusText = path(['statusText'], payload)

  if (error) {
    return `${error} - ${errorDescription}`
  }

  if (errorType) {
    return `${errorType} - ${errorDetail}`
  }

  return `${status} - ${statusText}`
}
