/* eslint-disable complexity */
/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import debounce from 'lodash.debounce'
import { func, string, bool, array, number, oneOfType } from 'prop-types'
import {
  composeP,
  length,
  values,
  groupBy,
  pathOr,
  propOr,
  equals,
  prop
} from 'ramda'
import { connect } from 'react-redux'

import Icon from 'components/Icon'
import TabsContent from 'components/TabsContent'
import Tabs from 'containers/Tabs'
import { hideModal } from 'redux/modules/modal'
import {
  getContractors,
  setContractor,
  getActiveContractor,
  userIdSelector,
  getSearchedContractors,
  searchContractors,
  setSearchContractor,
  clearSearchContractor,
  authContractor,
  gotoSuperuser,
  getIsExistsSuperuser,
  adminTokenSelector,
  getIsSuperuser
} from 'redux/modules/personal'
import Button from 'UI/Button'
import Input from 'UI/Input'
import Spacing from 'UI/Spacing'

import styles from './ChangeContractor.scss'

const NUMBER_350 = 350
const NUMBER_2 = 2
const tabsName = 'tabContractors'

@connect(
  ({ personal }) => ({
    contractors: getContractors(personal),
    userId: userIdSelector(personal),
    activeContractor: getActiveContractor(personal),
    searchedContractors: getSearchedContractors(personal),
    accessTokenAdmin: prop('access_token', adminTokenSelector(personal)),
    isExistsSuperuser: getIsExistsSuperuser(personal),
    isSuperuser: getIsSuperuser(personal)
  }),
  {
    hideModal,
    setContractor,
    searchContractors,
    setSearchContractor,
    clearSearchContractor,
    authContractor,
    gotoSuperuser
  }
)
class ChangeContractor extends PureComponent {
  static propTypes = {
    setContractor: func,
    searchContractors: func,
    setSearchContractor: func,
    clearSearchContractor: func,
    authContractor: func,
    gotoSuperuser: func,
    contractors: array,
    searchedContractors: array,
    isExistsSuperuser: bool,
    isSuperuser: bool,
    activeContractor: string,
    accessTokenAdmin: string,
    userId: oneOfType([string, number])
  }

  static defaultProps = {
    setContractor: () => {},
    searchContractors: () => {},
    setSearchContractor: () => {},
    clearSearchContractor: () => {},
    authContractor: () => {},
    gotoSuperuser: () => {},
    contractors: [],
    searchedContractors: [],
    isExistsSuperuser: false,
    isSuperuser: false
  }

  state = {
    activeTab: 'myContractors'
  }

  componentDidMount() {
    this.setActiveTab()
  }

  componentDidUpdate(prevProps) {
    if (this.props.contractors.length !== prevProps.contractors.length) {
      this.setActiveTab()
    }
  }

  handleGotoSuperuser = () => {
    this.props.gotoSuperuser().then(() => {
      window.location.reload()
    })
  }

  handleChangeContractor = contractorId => () => {
    const { userId } = this.props
    return composeP(
      () => this.onReloadPage(),
      () => this.props.setContractor({ contractorId, userId })
    )()
  }

  handleChangeContractorAdmin = targetId => () =>
    composeP(
      () => this.onReloadPage(),
      () => {
        const { accessTokenAdmin } = this.props
        return this.props.authContractor({
          targetId,
          accessToken: accessTokenAdmin
        })
      }
    )()

  handleChangeSearchContractor = event => {
    const search = event.target.value
    this.props.setSearchContractor(search)
    this.searchContractors(search)
  }

  handleSetTab = item =>  {
    this.setState({ activeTab: prop('id', item) })
  }

  setActiveTab = () => {
    const activeTab =
      this.props.contractors.length > 0 ? 'myContractors' : 'searchContractors'
    this.setState({ activeTab })
  }

  onReloadPage = () => {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.location.reload(true)
        return Promise.resolve()
      })
    }
    return false
  }

  searchContractors = debounce(search => {
    const { accessTokenAdmin } = this.props
    if (length(search) >= NUMBER_2) {
      this.props.searchContractors({ q: search, accessToken: accessTokenAdmin })
    } else {
      this.props.clearSearchContractor()
    }
  }, NUMBER_350)

  renderSearchedContractors = () => {
    const { searchedContractors } = this.props
    const groupedCode = groupBy(item => prop('CONTRACTOR_CODE', item))
    const constractors = values(groupedCode(searchedContractors))
    return (
      <div className={styles.contractors__data}>
        {constractors.map((groupItem, groupIndex) => (
          <div
            key={groupIndex}
            className={cx(styles.contractors__item, {
              [styles.contractors__item__odd]: groupIndex % NUMBER_2 !== 0,
              [styles.contractors__item__even]: groupIndex % NUMBER_2 === 0
            })}
          >
            <div
              className={cx([
                styles.contractors__data__item,
                styles.contractors__data__title
              ])}
            >
              <div className={styles.contractors__item_title}>
                Контрагент
              </div>
              <div className={styles.contractors__item_value}>
                {pathOr('', [0, 'CONTRACTOR_TITLE'], groupItem)}
              </div>
            </div>
            <div className={styles.contractors__data__description}>
              {groupItem.map((item, index) => (
                <div key={index}
                  className={styles.contractors__data__info}>
                  <div
                    className={cx([
                      styles.contractors__data__item,
                      styles.contractors__data__login
                    ])}
                  >
                    <div className={styles.contractors__item_title}>
                      Логин
                    </div>
                    <div className={styles.contractors__item_value}>
                      {prop('USER_LOGIN', item)}
                    </div>
                  </div>
                  <div
                    className={cx([
                      styles.contractors__data__item,
                      styles.contractors__data__mail
                    ])}
                  >
                    <div className={styles.contractors__item_title}>
                      Почта
                    </div>
                    <div className={styles.contractors__item_value}>
                      {prop('USER_EMAIL', item)}
                    </div>
                  </div>
                  <div
                    className={cx([
                      styles.contractors__data__item,
                      styles.contractors__data__kod
                    ])}
                  >
                    <div className={styles.contractors__item_title}>
                      Код
                    </div>
                    <div className={styles.contractors__item_value}>
                      {prop('CONTRACTOR_CODE', item)}
                    </div>
                  </div>
                  <div
                    role='presentation'
                    className={styles.contractors__data__change}
                    onClick={this.handleChangeContractorAdmin(
                      prop('USER_ID', item)
                    )}
                  >
                    Сменить
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
  }

  renderContractor = (item, index) => {
    const { activeContractor } = this.props
    const id = propOr('', 'ID', item)
    const isActive = equals(activeContractor, id)
    const title = propOr('', 'TITLE', item)
    const actionEl = isActive ? (
      <span>Текущий контрагент</span>
    ) : (
      <span role='presentation'
        onClick={this.handleChangeContractor(id)}>
        Сменить
      </span>
    )
    return (
      <div
        key={index}
        className={cx(styles.contractor__item, {
          [styles.contractor__item__odd]: index % NUMBER_2 !== 0,
          [styles.contractor__item__even]: index % NUMBER_2 === 0
        })}
      >
        <div className={styles.contractor__title}>
          <span className={styles.title}>{title}</span>
          {propOr(false, 'EDO', item) && (
            <span className={styles.edo}>Подключен к ЭДО</span>
          )}
        </div>
        <div
          className={cx(styles.contractor__action, {
            [styles.contractor__action__active]: isActive,
            [styles.contractor__action__inactive]: !isActive
          })}
        >
          {actionEl}
        </div>
      </div>
    )
  }

  render() {
    const { contractors, searchedContractors, isSuperuser, isExistsSuperuser } = this.props
    const { activeTab } = this.state

    const tabs = []
    if (contractors.length > 0) {
      tabs.push({
        id: 'myContractors',
        title: 'Мои контрагенты'
      })
    }
    if (isSuperuser || isExistsSuperuser) {
      tabs.push({
        id: 'searchContractors',
        title: 'Поиск контрагентов'
      })
    }

    return (
      <div className={styles.contractor}>
        <Tabs
          name={tabsName}
          defaultActive='myContractors'
          active={activeTab}
          items={tabs}
          onClick={this.handleSetTab}
        />
        {isExistsSuperuser && (
          <Spacing
            margin='all'
            size='tiny'
          >
            <Button
              color='white'
              size='tiny'
              icon='revert'
              onClick={this.handleGotoSuperuser}
              stretched
            >
              Вернуться в свою учетную запись
            </Button>
          </Spacing>
        )}
        <TabsContent
          name={tabsName}
          id='myContractors'
        >
          <div name='tabsContractors'
            id='myContractors'>
            <div className={styles.contractors__current}>
              {contractors.map(this.renderContractor, this)}
            </div>
          </div>
        </TabsContent>
        <TabsContent
          name={tabsName}
          id='searchContractors'
        >
          <div className={styles.input}>
            <Input
              type='text'
              placeholder='Поиск контрагента по имени, коду, почте'
              onChange={this.handleChangeSearchContractor}
            />
            <button type='submit'
              className={styles.input__submit}>
              <Icon className={styles.input__submitIcon}
                icon='search' />
            </button>
          </div>
          {searchedContractors && searchedContractors.length > 0 && (
            <div className={styles.contractors}>
              <div className={styles.contractors__header}>
                <div
                  className={cx(
                    styles.contractors__header__item,
                    styles.contractors__header__title
                  )}
                >
                  Контрагент
                </div>
                <div
                  className={cx(
                    styles.contractors__header__item,
                    styles.contractors__header__login
                  )}
                >
                  Логин
                </div>
                <div
                  className={cx(
                    styles.contractors__header__item,
                    styles.contractors__header__mail
                  )}
                >
                  Почта
                </div>
                <div
                  className={cx(
                    styles.contractors__header__item,
                    styles.contractors__header__kod
                  )}
                >
                  Код
                </div>
              </div>
              {this.renderSearchedContractors()}
            </div>
          )}
        </TabsContent>
      </div>
    )
  }
}

export default ChangeContractor
