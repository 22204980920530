import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { setContractor } from 'redux/modules/personal'

const initialState = {
  isLoading: false,
  isLoaded: false,
  isInvalid: false,
  isUserWithoutPassword: true,
  error: undefined
}

export {
  getIsLoaded,
  getIsLoading,
  getIsUserWithoutPassword,
  getError,
  isAuthSelector,
  tokenErrorSelector
} from './selector'

const ERROR_401 = 401

export const getTokenByPassword = createAction('token/GET_TOKEN_BY_PASSWORD')
export const getTokenByPasswordFailure = createAction('token/GET_TOKEN_FAILURE')
export const setToken = createAction('token/SET_TOKENS')
export const clearToken = createAction('token/CLEAR_TOKEN')
export const checkToken = createAction('token/CHECK_TOKEN')
export const checkTokenSuccess = createAction('token/CHECK_TOKEN_SUCCESS')
export const checkTokenFailure = createAction('token/CHECK_TOKEN_FAILURE')

export const resetError = createAction('token/RESET_ERROR')

export const request =
  ({ clientApi }) =>
    ({ username, password, onSuccess }) =>
      clientApi
        .getTokenByPassword({
          username,
          password
        })
        .then(payload => {
          onSuccess(payload)
          return setToken(payload.isUserWithoutPassword)
        })
        .catch(getTokenByPasswordFailure)

const handleGetTokenByPassword = (
  state,
  { username = '', password = '', onSuccess = () => {} },
  { clientApi }
) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      isUserWithoutPassword: true,
      error: undefined
    },
    Effects.promise(request({ clientApi }), {
      username,
      password,
      onSuccess
    })
  )
const handleGetTokenByPasswordFailure = (state, payload = {}) => {
  const response = R.prop('response', payload)
  return {
    ...state,
    isLoading: false,
    isLoaded: false,
    isUserWithoutPassword: true,
    error: response ? getErrorMessage(response) : 'ERR_INTERNET_DISCONNECTED'
  }
}

const handleSetToken = (state, isUserWithoutPassword) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  error: isUserWithoutPassword ? state.error : undefined,
  isUserWithoutPassword
})

const handleClearToken = (state, payload, { clientApi }) => {
  clientApi.clearToken()

  return loop(
    {
      ...state,
      isLoading: false,
      isLoaded: false
    },
    Effects.call(setContractor, '-1')
  )
}

const handleResetError = state => ({
  ...state,
  error: undefined
})

const handleCheckToken = state => ({
  ...state,
  isInvalid: false
})

const handleCheckTokenSuccess = state => ({ ...state, isInvalid: false })

const handleCheckTokenFailure = (state, payload = {}) => ({
  ...state,
  isInvalid: payload.status === ERROR_401
})

const reducer = createReducer(on => {
  on(getTokenByPassword, handleGetTokenByPassword)
  on(getTokenByPasswordFailure, handleGetTokenByPasswordFailure)

  on(setToken, handleSetToken)
  on(clearToken, handleClearToken)
  on(checkToken, handleCheckToken)
  on(checkTokenSuccess, handleCheckTokenSuccess)
  on(checkTokenFailure, handleCheckTokenFailure)

  on(resetError, handleResetError)
}, initialState)

export default reducer
