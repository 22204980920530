/* eslint-disable complexity */
import axios from 'axios'
import { push } from 'connected-react-router'
import { pathOr, prop, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { showModal } from 'redux/modules/modal'

export {
  getInvoice,
  getProducts,
  getIsLoading,
  getIsLoaded,
  getSortList,
  getCount,
  getWithoutInfo,
  getIsSubmitting,
  getIsError,
  getCreateErrors
} from './selector'

const initialState = {
  invoice: {
    name: '',
    date: -1,
    id: ''
  },
  status: '',
  sortList: [],
  products: [],
  createErrors: [],
  count: 0,
  isLoading: false,
  isLoaded: false,
  isSubmitting: false,
  isError: false,
  withoutInfo: false
}

export const reset = createAction('claimInvoice/FETCH_RESET')

export const fetchInvoice = createAction('claimInvoice/FETCH_INVOICE')
const fetchInvoiceSuccess = createAction('claimInvoice/FETCH_INVOICE_SUCCESS')
const fetchInvoiceFailure = createAction('claimInvoice/FETCH_INVOICE_FAILURE')

export const fetchClaimCreat = createAction('claimInvoice/FETCH_CLAIM_CREAT')
const fetchClaimCreatSuccess = createAction(
  'claimInvoice/FETCH_CLAIM_CREAT_SUCCESS'
)
const fetchClaimCreatFailure = createAction(
  'claimInvoice/FETCH_CLAIM_CREAT_FAILURE'
)

let source = null

const requestInvoice =
  ({ clientApi, cancelToken }) =>
    ({ id, ...params }) =>
      clientApi
        .get(`/v3/sale/orders/shipment/${id}/`, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          },
          cancelToken
        })
        .then(fetchInvoiceSuccess)
        .catch(fetchInvoiceFailure)

const requestClaimCreat =
  ({ clientApi }) =>
    id =>
      clientApi
        .post('/v3/contractor/pretensions/create/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            shipment: id
          }
        })
        .then(fetchClaimCreatSuccess)
        .catch(fetchClaimCreatFailure)

const handleReset = ({ isLoading }) => {
  if (isLoading && source && source.cancel) source.cancel()

  return { ...initialState }
}

const handleFetchInvoice = (state, params, { clientApi }) => {
  source = axios.CancelToken.source()

  return loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      isError: false
    },
    Effects.promise(
      requestInvoice({ clientApi, cancelToken: source.token }),
      params
    )
  )
}

const handleFetchInvoiceSuccess = (state, payload) => {
  const nav = pathOr([], ['data', 'response', 'NAV'], payload)
  const products = pathOr([], ['data', 'response', 'PRODUCTS'], payload)
  const invoice = pathOr('', ['data', 'response', 'ITEM'], payload)

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    sortList: propOr([], 'SORT', nav),
    count: propOr(0, 'CNT', nav),
    withoutInfo: propOr(false, 'WITHOUT_INFO', nav),
    isError: propOr(0, 'CNT', invoice) === 0,
    invoice: {
      name: propOr('', 'NUM', invoice),
      date: propOr(-1, 'DATE', invoice),
      id: ''
    },
    products
  }
}

const handleFetchInvoiceFailure = (state, { error }) => {
  if (axios.isCancel(error)) return { ...state }

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    isError: true,
    products: [],
    withoutInfo: false,
    count: 0,
    sortList: [],
    invoice: {
      name: '',
      date: -1,
      id: ''
    }
  }
}

const handleFetchClaimCreat = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isSubmitting: true
    },
    Effects.promise(requestClaimCreat({ clientApi }), params)
  )

const handleFetchClaimCreatSuccess = (state, payload) => {
  const effects = []
  const response = pathOr({}, ['data', 'response'], payload)
  const isSuccess = prop('SUCCESS', response) === 'Y'
  const newState = {}

  if (isSuccess) {
    effects.push(
      Effects.call(push, `/cabinet/claim/${prop('ID', response)}/draft`)
    )
  } else {
    const errors = pathOr({}, ['data', 'errors'], payload)
    newState.createErrors = Object.keys(errors).map(
      key => `${key} - ${propOr('', key, errors)}`
    )
  }

  return loop(
    {
      ...state,
      isSubmitting: false
    },
    Effects.batch(effects)
  )
}

const handleFetchClaimCreatFailure = (state, payload) =>
  loop(
    {
      ...state,
      isSubmitting: false,
      createErrors: [getErrorMessage(payload)]
    },
    Effects.call(showModal, 'claimInvoiceError')
  )

export default createReducer(on => {
  on(reset, handleReset)
  on(fetchInvoice, handleFetchInvoice)
  on(fetchInvoiceSuccess, handleFetchInvoiceSuccess)
  on(fetchInvoiceFailure, handleFetchInvoiceFailure)
  on(fetchClaimCreat, handleFetchClaimCreat)
  on(fetchClaimCreatSuccess, handleFetchClaimCreatSuccess)
  on(fetchClaimCreatFailure, handleFetchClaimCreatFailure)
}, initialState)
