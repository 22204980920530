import { pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

const initialState = {
  isLoading: false,
  isLoaded: false,
  items: [],
  summary: {}
}

export const fetch = createAction('events/FETCH')
const fetchSuccess = createAction('events/FETCH_SUCCESS')
const fetchFailure = createAction('events/FETCH_FAILURE')

const request =
  ({ clientApi }) =>
    () =>
      clientApi
        .get(`/v1/events/?contractor_id=${clientApi.getContractorId()}`)
        .then(fetchSuccess)
        .catch(fetchFailure)

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }))
  )

const handleFetchSuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'response'], payload)
  const summary = pathOr({}, ['ENTITIES', 'TYPE'], response)
  const items = pathOr([], ['ITEMS'], response)
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    items,
    summary
  }
}

const handleFetchFailure = state => state

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
}, initialState)

export default reducer
