/* eslint-disable */
function forClientRendering(target, key, descriptor) {
  const method = descriptor.value
  descriptor.value = function decorated(...args) {
    if (typeof window !== 'undefined') {
      method.apply(this, args)
    }
  }
  return descriptor
}

function forServerRendering(target, key, descriptor) {
  const method = descriptor.value
  descriptor.value = function decorated(...args) {
    if (typeof window === 'undefined') {
      method.apply(this, args)
    }
  }
  return descriptor
}

export { forServerRendering, forClientRendering }
