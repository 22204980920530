import React, { PureComponent } from 'react'

import { array, func } from 'prop-types'
import { prop, length, path } from 'ramda'
import { connect } from 'react-redux'
import SliderSlick from 'react-slick'

import ContentWrapper from 'components/ContentWrapper'
import Icon from 'components/Icon'
import Modal from 'containers/Modal'
import { socketSend, notificationPopup } from 'redux/modules/socket'
import moment from 'utils/moment'

import styles from './Notification.scss'

@connect(
  ({ socket }) => ({
    notifications: notificationPopup(socket)
  }),
  { socketSend }
)
export default class Popup extends PureComponent {
  static propTypes = {
    notifications: array,
    socketSend: func
  }

  static defaultProps = {
    socketSend: () => {},
    notifications: []
  }

  handleInit = () => this.onHandleSlideChange(0, 0)

  onHandleSlideChange = (_, index) => {
    const { notifications } = this.props
    const id = path([index, 'id'], notifications)
    this.props.socketSend({ type: 'alert', id })
  }

  renderSlide = (item = {}) => {
    const date = prop('dateStart', item) ? moment(prop('dateStart', item)).format('DD.MM.YYYY') : ''
    return (
      <div key={prop('id', item)}
        className={styles.slide}>
        <div className={styles.content}>
          <ContentWrapper html={prop('text', item)} />
        </div>
        <div className={styles.date}>
          {date}
        </div>
      </div>
    )
  }

  renderDot = i => (
    <div role='presentation'
      key={`dot${i}`}
      className={styles.sliderDot} />
  )

  render() {
    const { notifications } = this.props

    if (length(notifications) === 0) {
      return null
    }
    return (
      <Modal id='notificationPopup'
        size='middle'>
        <div className={styles.data}>
          <SliderSlick
            draggable={false}
            infinite={false}
            autoplaySpeed={6000}
            slidesToShow={1}
            slidesToScroll={1}
            nextArrow={<Icon icon='next' />}
            prevArrow={<Icon icon='prev' />}
            className={styles.slider}
            dotsClass={styles.bannerDots}
            customPaging={this.renderDot}
            beforeChange={this.onHandleSlideChange}
            onInit={this.handleInit}
            dots
            lazyLoad
          >
            {notifications.map(this.renderSlide)}
          </SliderSlick>
        </div>
      </Modal>
    )
  }
}
