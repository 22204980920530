import loadable from '@loadable/component'
import { path } from 'ramda'
import { asyncConnect } from 'redux-connect'

const InvoiceBill = loadable(() =>
  import(
    /* webpackChunkName: "InvoiceBill" */ 'pages/InvoiceBill/InvoiceBill'
  )
)

export default asyncConnect([
  {
    key: 'pageInvoiceBillContainer',
    promise: ({ helpers: { updateData }, match }) => {
      const id = path(['params', 'id'], match)
      updateData.set({ view: 'invoiceBill', id })

      return updateData.update()
    }
  }
])(InvoiceBill)