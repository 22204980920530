import { propOr } from 'ramda'
import { connect } from 'react-redux'

import OutdatedBrowser from 'components/OutdatedBrowser'
import {
  showOutdatedBrowser,
  closeOutdatedBrowser
} from 'redux/modules/settings'

export default connect(
  ({ userAgent, settings }) => ({
    userAgent,
    isOpenOutdatedBrowser: propOr(false, 'isOpenOutdatedBrowser', settings)
  }),
  { showOutdatedBrowser, closeOutdatedBrowser }
)(OutdatedBrowser)
