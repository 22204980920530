/* eslint-disable complexity */
import React from 'react'

import { string, number } from 'prop-types'

import s from './CapPreloader.scss'

const CapPreloader = ({ width, height, count }) => {
  const rectStyle = {
    width: width || '100%',
    height: height || '24px'
  }

  const rect = Array.from({ length: count || 1 }, (_, index) => (
    <div
      key={index}
      className={s.capPreloader__item}
      style={rectStyle}
    />
  ))

  return (
    <div
      className={s.capPreloader}>
      {rect}
    </div>
  )
}

CapPreloader.propTypes = {
  count: number,
  width: string,
  height: string
}

export default CapPreloader
