import {
  pathOr,
  prop,
  propOr,
  compose,
  equals,
  defaultTo,
  uniq,
  append,
  prepend,
  filter,
  trim
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import localforage from 'helpers/localforage'

const STORAGE_HISTORY = 'search_history'

const initialState = {
  searchValue: '',
  searchSuccess: '',
  urls: [],
  history: [],
  suggests: [],
  sections: [],
  products: [],
  isLoaded: false,
  isLoading: false
}

export {
  getIsLoaded,
  getIsLoading,
  querySelector,
  searchValueSelector,
  suggestionsSelector,
  sectionsSelector,
  productsSelector
} from './selector'

export const fetch = createAction('suggestions/FETCH')
export const initHistory = createAction('suggestions/INIT_HISTORY')
export const initHistorySuccess = createAction(
  'suggestions/INIT_HISTORY_SUCCESS'
)
export const initHistoryFailure = createAction(
  'suggestions/INIT_HISTORY_FAILURE'
)
export const clear = createAction('suggestions/CLEAR')
export const pushHistory = createAction('suggestions/PUSH_HISTORY')
export const pushHistorySuccess = createAction(
  'suggestions/PUSH_HISTORY_SUCCESS'
)
export const pushHistoryFailure = createAction(
  'suggestions/PUSH_HISTORY_FAILURE'
)
export const clearHistory = createAction('suggestions/CLEAR_HISTORY')
export const pushToUrl = createAction('suggestions/PUSH_TO_URL')
export const clearUrl = createAction('suggestions/CLEAR_URL')
export const clearValue = createAction('suggestions/CLEAR_VALUE')
export const setSearchValue = createAction('suggestions/SET_SEARCH_VALUE')
export const fetchSuccess = createAction('suggestions/FETCH_SUCCESS')
export const fetchSuccessExtended = createAction(
  'suggestions/FETCH_SUCCESS_EXTENDED'
)
export const fetchFailure = createAction('suggestions/FETCH_FAILURE')

const reqInitHistory = () =>
  localforage
    .getItem(STORAGE_HISTORY)
    .then(data => initHistorySuccess(defaultTo([], data)))
    .catch(initHistoryFailure)

export const handleInitHistory = state =>
  loop(
    {
      ...state
    },
    Effects.promise(reqInitHistory, {})
  )

export const handleInitHistorySuccess = (state, history) => ({
  ...state,
  history
})
export const handleInitHistoryFailure = state => ({ ...state })

export const request =
  ({ clientApi }) =>
    ({ value, type }) =>
      clientApi
        .get(
          `/v3/catalog/${
            type === 'loyalty' ? 'loyalty' : 'main'
          }/search/suggests/`,
          {
            params: {
              q: value
            }
          }
        )
        .then(data => fetchSuccess({ ...data, value }))
        .catch(fetchFailure)

export const requestExtended =
  ({ clientApi }) =>
    ({ value, type }) =>
      clientApi
        .get(
          `/v3/catalog/${
            type === 'loyalty' ? 'loyalty' : 'main'
          }/search/suggests/extended/`,
          {
            params: {
              q: value
            }
          }
        )
        .then(data => fetchSuccessExtended({ ...data, value }))
        .catch(fetchFailure)

const handleFetch = (state, { value, type }, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      valueLoading: value
    },
    Effects.batch([
      Effects.promise(request({ clientApi }), { value, type }),
      Effects.promise(requestExtended({ clientApi }), { value, type })
    ])
  )

const handleFetchSuccess = (state, payload) => {
  const value = prop('value', payload)
  const valueLoading = prop('valueLoading', state)
  if (equals(value, valueLoading)) {
    return {
      ...state,
      isLoading: false,
      isLoaded: true,
      searchSuccess: prop('value', payload),
      suggests: pathOr([], ['data', 'response', 'SUGGESTS'], payload)
    }
  }
  return {
    ...state
  }
}

const handleFetchSuccessExtended = (state, payload) => {
  const value = prop('value', payload)
  const valueLoading = prop('valueLoading', state)
  if (equals(value, valueLoading)) {
    return {
      ...state,
      sections: pathOr([], ['data', 'response', 'SECTIONS'], payload),
      products: pathOr([], ['data', 'response', 'PRODUCTS'], payload)
    }
  }
  return {
    ...state
  }
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  searchSuccess: '',
  error: getErrorMessage(payload),
  suggests: [],
  sections: [],
  products: []
})

const handleSetSearchValue = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  searchValue: prop('value', payload)
})

const handleClear = state => ({
  ...state,
  searchSuccess: '',
  suggests: [],
  sections: [],
  products: []
})

const handleClearValue = state => ({
  ...state,
  searchValue: '',
  suggests: [],
  sections: [],
  products: []
})

const reqPushHistory = (items = []) =>
  localforage
    .setItem(STORAGE_HISTORY, items)
    .then(pushHistorySuccess)
    .catch(pushHistoryFailure)

const handlePushHistory = (state, payload = '') => {
  const history = uniq(prepend(trim(payload), propOr([], 'history', state)))
  return loop(
    {
      ...state
    },
    Effects.promise(reqPushHistory, history)
  )
}

const handlePushHistorySuccess = (state, history) => ({
  ...state,
  history
})

const handlePushHistoryFailure = state => ({
  ...state
})

const handleClearHistory = (state, item = null) => {
  const items = item
    ? compose(
      filter(hist => !equals(hist, item)),
      propOr([], 'history')
    )(state)
    : []
  return loop(
    {
      ...state
    },
    Effects.promise(reqPushHistory, items)
  )
}

const handlePushToUrl = (state, url) => ({
  ...state,
  urls: append(url, propOr([], 'urls', state))
})

const handleClearUrl = state => ({
  ...state,
  urls: []
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(initHistory, handleInitHistory)
  on(initHistorySuccess, handleInitHistorySuccess)
  on(initHistoryFailure, handleInitHistoryFailure)
  on(clear, handleClear)
  on(clearValue, handleClearValue)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchSuccessExtended, handleFetchSuccessExtended)
  on(fetchFailure, handleFetchFailure)
  on(setSearchValue, handleSetSearchValue)
  on(pushHistory, handlePushHistory)
  on(pushHistorySuccess, handlePushHistorySuccess)
  on(pushHistoryFailure, handlePushHistoryFailure)
  on(clearHistory, handleClearHistory)
  on(pushToUrl, handlePushToUrl)
  on(clearUrl, handleClearUrl)
}, initialState)

export default reducer
