import { propOr, pathOr, map, toLower, compose, slice, isEmpty } from 'ramda'
import { createSelector } from 'reselect'

const MAX_SUPPLIER_PRODUCTS = 3

export const getMarksMenu = createSelector(
  state => state,
  state => propOr([], 'marks', state)
)
export const getIsLoaded = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

export const marksSuppliersSelector = createSelector(
  state => pathOr([], ['suppliers', 'data', 'ITEMS'], state),
  suppliers =>
    map(item => ({
      code: compose(toLower, propOr('', 'CODE'))(item),
      title: propOr('', 'TITLE', item),
      color: propOr('', 'COLOR', item),
      products: compose(
        slice(0, MAX_SUPPLIER_PRODUCTS),
        propOr([], 'TOP')
      )(item)
    }))(suppliers)
)

export const isLoadedSuppliersSelector = createSelector(
  state => state,
  state => pathOr(false, ['suppliers', 'isLoaded'], state)
)

export const isShowSuppliersSelector = createSelector(
  marksSuppliersSelector,
  suppliers => !isEmpty(suppliers)
)
