import React, { PureComponent } from 'react'

import cx from 'classnames'
import { object, func, number, bool } from 'prop-types'
import { propOr, equals } from 'ramda'

import Image from 'components/Image'
import Link from 'components/Link'
import { getMenuLink } from 'helpers/catalog'
import Spacing from 'UI/Spacing'

import styles from './CatalogListBlock.scss'

const FIRST_CATALOG_LEVEL = 1
const SECOND_CATALOG_LEVEL = 2

export default class CatalogListBlock extends PureComponent {
  static propTypes = {
    list: object,
    isBookmark: bool,
    withoutImages: bool,
    isPageCatalog: bool,
    bookmark: number,
    onHideCatalog: func
  }

  static defaultProps = {
    isPageCatalog: false,
    list: {},
    onHideCatalog: () => {}
  }

  handleClick = () => this.props.onHideCatalog()

  getLink = item => {
    const { isBookmark, bookmark } = this.props
    const link = propOr('', 'link', item)
    if (link) {
      return link
    }
    return getMenuLink({ isBookmark, bookmark, item })
  }

  renderLink = item => (
    <li
      key={item.ID}
      itemProp='itemListElement'
      itemScope
      itemType='http://schema.org/ItemList'
    >
      <meta itemProp='position'
        content={item.ID} />
      <Link
        to={this.getLink(item)}
        onClick={this.handleClick}
      >
        <div className={styles.linksSubitems}>
          {propOr('', 'NAME', item)}
          {propOr('', 'CNT', item) && (
            <span className={styles.count}>{propOr('', 'CNT', item)}</span>
          )}
        </div>
      </Link>
    </li>
  )

  render() {
    const { list, withoutImages, onHideCatalog, isPageCatalog } = this.props
    const link = this.getLink(list)
    const listDepth = propOr(FIRST_CATALOG_LEVEL, 'DEPTH', list)
    const isIcon =
      equals(listDepth, FIRST_CATALOG_LEVEL) ||
      equals(listDepth, SECOND_CATALOG_LEVEL)
    return (
      <li
        className={cx(styles.item, {
          [styles.item_catalogPage]: isPageCatalog
        })}
        itemProp='itemListElement'
        itemScope
        itemType='http://schema.org/ItemList'
      >
        <meta itemProp='position'
          content={listDepth} />
        {!withoutImages && (
          <Spacing margin='right'>
            <Link to={link}>
              <Image
                backgroundSize='contain'
                src={propOr('', 'PICTURE_PATH', list)}
                imgClassName={cx(styles.imageCategory, {
                  [styles.imageCategory_icon]: isIcon
                })}
              />
            </Link>
          </Spacing>
        )}

        <div className={styles.links}>
          <Link to={link}
            onClick={onHideCatalog}
            weight='bold'>
            <div className={styles.linksHead}>
              {propOr('', 'NAME', list)}
              <span className={styles.count}>{propOr('', 'CNT', list)}</span>
            </div>
          </Link>

          <div className={styles.linksItems}>
            <ul className={styles.subitems}>
              {propOr([], 'items', list).map(this.renderLink)}
            </ul>
          </div>
        </div>
      </li>
    )
  }
}
