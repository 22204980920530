import roundBy from 'utils/roundBy'

const units = [
  {
    size: 1,
    label: 'Б'
  },
  {
    size: 1024,
    label: 'Кб'
  },
  {
    size: 1048576,
    label: 'Мб'
  },
  {
    size: 1073741824,
    label: 'Гб'
  }
]

const formatFileSize = size => {
  let unit = units[0]

  units.map(val => {
    if (val.size < size) {
      unit = val
    }
    return val
  })
  const fileSize = roundBy(size / unit.size, 1)
  return `${fileSize} ${unit.label}`
}

export default formatFileSize
