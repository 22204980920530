import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
  if (typeof document !== 'undefined') {
    return createPortal(children, document.getElementById('portal'))
  }
  return children
}

export default Portal
