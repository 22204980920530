/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string, array, func, object } from 'prop-types'
import { isEmpty, equals, composeP, propOr, mergeAll } from 'ramda'

import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import Interactions from 'components/Interactions'
import Link from 'components/Link'
import Scrollbar from 'components/Scrollbar'
import { setAnalyticsProps } from 'helpers/metaTags'
import Preloader from 'UI/Preloader'

import styles from './HeaderOffers.scss'

const THREE_SECONDS = 3000

export default class HeaderOffers extends PureComponent {
  static propTypes = {
    isLoadedOffers: bool,
    isLoadingOffers: bool,
    iconPath: string,
    offers: array,
    newOffers: object,
    fetchOffers: func,
    fetchMarkdownPage: func,
    navigateTo: func
  }

  static defaultProps = {
    isLoadedOffers: false,
    isLoadingOffers: false,
    offers: [],
    newOffers: {
      count: 0,
      linkTo: '/offers/markdown'
    }
  }

  handleFetchOffers = () => {
    const { isLoadedOffers, fetchOffers } = this.props
    if (!isLoadedOffers) {
      fetchOffers()
    }
  }

  handleShowOffer =
    ({ link, type }) =>
      () => {
        const { navigateTo, fetchOffers, fetchMarkdownPage } = this.props
        if (!equals(type, 'markdown')) {
          navigateTo(link)
        } else {
          composeP(
            () =>
              setTimeout(() => {
                fetchOffers()
                fetchMarkdownPage()
              }, THREE_SECONDS),
            () => navigateTo(link)
          )()
        }
      }

  renderOfferItem = (type, item) => {
    const {
      offerId,
      offerName,
      link,
      dateFrom,
      dateTo,
      offerActive,
      offerNew,
      offerWinner,
      isPrizeAvailable,
      labelText,
      isHideCounter
    } = item
    const isShowDates = dateFrom && dateTo
    const analyticProps = equals(type, 'markdown')
      ? {
        type: 'banner',
        banner: 'offersMarkdownBanner',
        alt: offerName,
        pos: 'popup'
      }
      : {
        type: 'banner',
        banner: 'offersOfflineBanner',
        alt: offerName,
        pos: 'popup'
      }
    return (
      <div
        role='presentation'
        className={styles.offerInfo}
        key={offerId}
        onClick={this.handleShowOffer({ link, type })}
      >
        <div className={styles.offerLeft}>
          <div
            className={styles.offerTitle}
            {...setAnalyticsProps(analyticProps)}
          >
            {offerName}
          </div>
          {isShowDates && (
            <div className={styles.offerLeft_dates}>
              {dateFrom} - {dateTo}
            </div>
          )}
        </div>
        {!isHideCounter && (
          <div
            className={cx(styles.offerLabel, {
              [styles.offerLabelNew]: offerNew || equals(type, 'markdown'),
              [styles.offerLabelActive]: offerActive && !offerNew,
              [styles.offerLabelEnd]: offerWinner && isPrizeAvailable,
              [styles.offerLabelEnd_disabled]:
                offerWinner && !isPrizeAvailable
            })}
          >
            {!offerWinner ? labelText : 'Забрать подарок'}
          </div>
        )}
      </div>
    )
  }

  renderBlockTitle = ({ type, title }) => (
    <div
      className={cx(styles.blockTitle, {
        [styles.blockTitleBordered]: !equals(type, 'markdown')
      })}
    >
      <div className={styles.blockTitleColumn}>
        <div className={styles.blockTitleText}>{title}</div>
      </div>
      <Link to={`/offers/${type}`}
        color='blue'
        weight='semibold'>
        Показать все
      </Link>
    </div>
  )

  renderOffersDropdown = () => {
    const { offers, isLoadingOffers } = this.props

    if (isLoadingOffers) {
      return <Preloader active={isLoadingOffers} />
    }

    const offersObj = mergeAll(offers)

    if (isEmpty(offersObj)) {
      return (
        <div className={styles.offersEmpty}>Нет доступных акций</div>
      )
    }

    return offers.map(offerData => {
      const offer = propOr([], 'offers', offerData)
      const type = propOr('offline', 'offerType', offerData)
      return (
        <div key={type}>
          {!isEmpty(offer) && (
            <div className={styles.offersShortBlock}>
              {this.renderBlockTitle({
                type,
                title: propOr('', 'title', offerData)
              })}
              {offer.map(item => this.renderOfferItem(type, item))}
            </div>
          )}
        </div>
      )
    })
  }

  render() {
    const {
      newOffers: { count, linkTo },
      iconPath
    } = this.props
    return (
      <Interactions onDelayedMouseEnter={this.handleFetchOffers}>
        <HeaderMenuItem
          linkTo={linkTo}
          iconPath={iconPath}
          dropDownClassName={styles.dropdownOffers}
          iconWrapper='iconOffers'
          text='Акции'
          counter={count}
        >
          <Scrollbar maxHeight={420}
            minScrollHeight={15}>
            {this.renderOffersDropdown()}
          </Scrollbar>
        </HeaderMenuItem>
      </Interactions>
    )
  }
}
