import React, { memo } from 'react'

import cx from 'classnames'
import { pathOr } from 'ramda'
import { RootStateOrAny, useSelector } from 'react-redux'

import Icon, { IconTypeProps } from 'components/Icon'
import Link from 'components/Link'
import config from 'config'
import { isLoyaltySelector } from 'redux/modules/settings'

import s from './HeaderLogo.scss'

const LogoMobile = () => {
  const isLoyalty = useSelector(({ settings } : RootStateOrAny) => isLoyaltySelector(settings))

  const configTheme = isLoyalty ? 'violet' : 'default'

  const logoIcon: IconTypeProps = pathOr('', ['themes', configTheme, 'logo'], config)

  return (
    <Link to={isLoyalty ? '/loyalty' : '/'}>
      <span className={cx(s.logo, {
        [s.logoLoyalty]: isLoyalty
      })}>
        <Icon
          className={cx(s.logoIcon, {
            [s.logoIconLoyalty]: isLoyalty
          })}
          size='auto'
          icon={logoIcon}
        />
      </span>
    </Link>
  )
}

export default memo(LogoMobile)
