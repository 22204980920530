import React from 'react'

import { keys, path, toLower } from 'ramda'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'

import { metaSelector } from 'redux/modules/meta'
import { metaTitleSelector } from 'redux/modules/personal'

const MetaInfo = () => {
  const metaInfo = useSelector(({ meta }) => metaSelector(meta))
  const title = useSelector(({ personal }) => metaTitleSelector(personal))

  return (
    <Helmet>
      <title itemProp='name'>{title}</title>
      {keys(metaInfo).map(key => metaInfo[key] && (
        <meta
          key={key}
          name={toLower(key)}
          content={path([key], metaInfo)}
        />
      ))}
    </Helmet>
  )
}

export default MetaInfo
