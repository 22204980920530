import React, { PureComponent } from 'react'

import cx from 'classnames'
import { oneOf, string } from 'prop-types'

import styles from './InputError.scss'

class InputError extends PureComponent {
  static propTypes = {
    side: oneOf(['left', 'bottom']),
    message: string,
    className: string
  }

  render() {
    const { side, message, className } = this.props

    return (
      <div
        className={cx(styles.error, className, {
          [styles[`error_side_${side}`]]: side
        })}
        dangerouslySetInnerHTML={{ __html: message }} // eslint-disable-line react/no-danger
      />
    )
  }
}

export default InputError
