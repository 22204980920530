/* eslint-disable complexity */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react'

import cx from 'classnames'
import { push } from 'connected-react-router'
import {
  array,
  bool,
  func,
  number,
  object,
  oneOfType,
  string
} from 'prop-types'
import {
  compose,
  composeP,
  equals,
  includes,
  keys,
  length,
  pathOr,
  prop
} from 'ramda'
import { connect } from 'react-redux'

import CartsToContractor from 'containers/Cart/CartsToContractor'
import Modal from 'containers/Modal'
import { hideModal } from 'redux/modules/modal'
import {
  currentContractorIdSelector,
  contractorsSelector,
  userIdSelector,
  setContractor
} from 'redux/modules/personal'
import {
  getBasketParams,
  switchItemInGroup,
  cartActionSelector,
  cartSelector,
  setTargetContractorId,
  targetContractorIdSelector
} from 'redux/modules/productList'
import Select from 'UI/Select'
import noun from 'utils/noun'
import parse from 'utils/querystring'

import s from './CartMove.scss'

const CartMove = ({
  contractors = [],
  contractorId = 0,
  userId = 0,
  targetContractorId = 0,
  products = {},
  isBasket = false,
  basketParams,
  catalog = 'main',
  cart,
  page,
  onSwitchItemInGroup,
  onSetTargetContractorId = () => {},
  onSetContractor = () => {},
  onHideModal,
  onNavigate
}) => {
  const cnt = compose(length, keys)(products)

  const [isLoading, setIsLoading] = useState(false)

  const [targetCatalog, setTargetCatalog] = useState(catalog)

  const isChangedContractor = !equals(contractorId, targetContractorId)

  const onReloadPage = () => {
    if (typeof window !== 'undefined') {
      setTimeout(() => {
        window.location.reload(true)
        return Promise.resolve()
      })
    }
    return false
  }

  const onSuccessMove =
    (_toId = 0, _toCatalog = 'main') =>
      () => {
        setIsLoading(false)
        onHideModal()
        if (isBasket) {
          if (isChangedContractor) {
            composeP(
              () => onReloadPage(),
              () => onNavigate(`/basket/${_toCatalog}/${_toId}`),
              () => onSetContractor({ contractorId: targetContractorId, userId })
            )()
          } else {
            onNavigate(`/basket/${_toCatalog}/${_toId}`)
          }
        }
      }

  const onMove = ({ id, toCatalog = 'main' }) => {
    let params = {
      sort: prop('sort', basketParams),
      order: prop('order', basketParams),
      page,
      action: 'move',
      from_value: cart,
      from: catalog,
      to: toCatalog,
      value: id
    }

    if (isChangedContractor) {
      params = { ...params, to_contractor_id: targetContractorId }
    }
    setIsLoading(true)
    onSwitchItemInGroup({
      id: toCatalog,
      type: 'basket',
      items: products,
      params,
      settings: { catalog: toCatalog },
      onSuccess: onSuccessMove(id, toCatalog)
    })
  }

  const onSetTargetCatalog = _catalog => setTargetCatalog(_catalog)

  const isManyContractors = length(contractors) > 1

  const header = isManyContractors
    ? 'Выберите юридическое лицо (при необходимости) и корзину для перемещения товара'
    : 'Выберите корзину для перемещения товара'

  const selectedIndexContractor = targetContractorId || contractorId

  const onChangeContractor = idValue => {
    onSetTargetContractorId(idValue)
  }

  useEffect(() => {
    onSetTargetContractorId(contractorId)
  }, [contractorId, onSetTargetContractorId])

  return (
    <Modal title={header}
      size='big'
      id='cart-move'>
      <div
        className={cx(s.container, {
          [s.containerLoading]: isLoading
        })}
      >
        {isLoading && <div className={s.loading} />}
        <div className={s.desc}>
          Будет {noun(cnt, ['перемещен', 'перемещено', 'перемещено'])} {cnt}{' '}
          {noun(cnt, ['товар', 'товара', 'товаров'])}
        </div>
        {isManyContractors && (
          <Select
            onSelect={onChangeContractor}
            options={contractors}
            selectedValue={selectedIndexContractor}
          />
        )}
        <CartsToContractor
          contractorId={targetContractorId}
          catalog={targetCatalog}
          catalogInit={catalog}
          onSetCatalog={onSetTargetCatalog}
          onMove={onMove}
          isChangedContractor={isChangedContractor}
        />
      </div>
    </Modal>
  )
}

CartMove.propTypes = {
  onSwitchItemInGroup: func,
  onSetTargetContractorId: func,
  onHideModal: func,
  onSetContractor: func,
  onNavigate: func,
  isBasket: bool,
  contractors: array,
  cart: oneOfType([number, array]),
  userId: number,
  contractorId: oneOfType([number, string]),
  targetContractorId: oneOfType([number, string]),
  page: number,
  catalog: string,
  products: object,
  basketParams: object
}

export default connect(
  ({ productList, personal, router }, { isBookmark = false, bookmark }) => ({
    ...cartActionSelector(productList),
    basketParams: getBasketParams(productList),
    page: includes('/basket', pathOr('/', ['location', 'pathname'], router))
      ? Number(
        pathOr(1, 'p', parse(pathOr('', ['location', 'search'], router)))
      )
      : 1,
    cart: isBookmark ? bookmark : cartSelector(productList),
    contractors: contractorsSelector(personal),
    contractorId: currentContractorIdSelector(personal),
    targetContractorId: targetContractorIdSelector(productList),
    userId: userIdSelector(personal)
  }),
  {
    onSetTargetContractorId: setTargetContractorId,
    onSwitchItemInGroup: switchItemInGroup,
    onHideModal: hideModal,
    onSetContractor: setContractor,
    onNavigate: push
  }
)(CartMove)
