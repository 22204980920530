/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { replace } from 'connected-react-router'
import { bool, string, array, object, func } from 'prop-types'
import qs from 'qs'
import {
  prop,
  propOr,
  pathOr,
  path,
  head,
  equals,
  isEmpty,
  findIndex,
  toUpper
} from 'ramda'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import Link from 'components/Link'
import OrderDocumentsDropdown from 'components/OrderDocumentsDropdown'
import OrderStatus from 'components/OrderStatus'
import Modal from 'containers/Modal'

import styles from './InvoiceList.scss'

const typeToPrettyName = {
  '918OK': '9-18',
  BOOKMARK: 'Закладка'
}

class InvoiceList extends PureComponent {
  static propTypes = {
    dispatch: func,
    orderAction: func,
    items: array,
    sortBy: string,
    order: string,
    idItemAfterAction: array,
    errorMessage: string,
    location: object,
    query: object,
    isHeader: bool,
    isCabinetHome: bool
  }

  static defaultProps = {
    items: [],
    location: {},
    query: {},
    isHeader: false,
    isCabinetHome: false,
    idItemAfterAction: [],
    errorMessage: ''
  }

  handleClick = event => event.stopPropagation()

  setSortBy = sortBy => () => {
    const { dispatch, location, isCabinetHome, query } = this.props
    if (!isCabinetHome) {
      let order = 'DESC'
      if (this.props.sortBy === sortBy) {
        order = this.props.order === 'ASC' ? 'DESC' : 'ASC'
      }
      const prepareQuery = { ...query, sortBy, order }

      dispatch(
        replace({
          ...location,
          search: `?${qs.stringify(prepareQuery)}`
        })
      )
    }
  }

  onOrderAction = orderId => actionType => {
    this.props.orderAction({
      orderId,
      actionType
    })
  }

  renderRow = (item, key) => {
    const { isHeader, idItemAfterAction, query } = this.props
    const itemType = toUpper(propOr('MAIN', 'type', item))
    const itemId = propOr('', 'id', item)
    const userName = pathOr('', ['user', 'NAME'], item)
    const nameFirstLetter = userName ? `${head(userName)}.` : ''
    const expeditor = propOr({}, 'deliveryExpeditor', item)
    const to = {
      pathname: `/cabinet/invoice/bill/${prop('id', item)}`,
      state: query
    }
    const changedIndex = findIndex(id => equals(id, itemId))(idItemAfterAction)
    const storeName = pathOr('', ['store', 'NAME'], item)
    const isMain = pathOr(false, ['store', 'IS_MAIN'], item)
    const isPoints = propOr(false, 'isPoints', item)
    const tax = propOr(0, 'tax', item)
    return (
      <div
        key={key}
        className={cx(styles.tableRowWrapper, {
          [styles.tableRowWrapperHighlighted]: !isHeader,
          [styles.tableRowWrapperSmall]: isHeader
        })}
      >
        <div
          className={cx(styles.tableRow, {
            [styles.tableRowSmall]: isHeader,
            [styles.tableRowGray]: !equals(changedIndex, -1)
          })}
        >
          <Link to={to}>
            <span
              className={cx(styles.tableCell, {
                [styles.tableRowGray]: !equals(changedIndex, -1)
              })}
            >
              <div className={styles.invoiceNum}>
                <div className={styles.num}>{propOr('', 'num', item)}</div>
                <div className={styles.date}>{propOr('', 'date', item)}</div>
              </div>
            </span>
          </Link>
          <Link to={to}>
            <span
              className={cx(styles.tableCell, {
                [styles.tableRowGray]: !equals(changedIndex, -1)
              })}
            >
              {pathOr('', ['contractor', 'TITLE'], item)}
              <span className={styles.gray}>
                {pathOr('', ['user', 'LAST_NAME'], item)} {nameFirstLetter}
              </span>
            </span>
          </Link>
          {!isHeader && (
            <Link to={to}>
              <span
                className={cx(styles.tableCell, {
                  [styles.tableRowGray]: !equals(changedIndex, -1)
                })}
              >
                {propOr('', 'address', item)}
              </span>
            </Link>
          )}
          <div
            className={cx(styles.tableCell, {
              [styles.tableRowGray]: !equals(changedIndex, -1)
            })}
          >
            <OrderStatus
              num={propOr('', 'num', item)}
              code={path(['state', 'ID'], item)}
              actions={!isHeader ? propOr([], 'actions', item) : []}
              onOrderAction={this.onOrderAction(item.id)}
            >
              {pathOr('', ['state', 'NAME'], item)}
            </OrderStatus>
          </div>
          {!isHeader && (
            <Link to={to}>
              <span className={cx(styles.tableCell, {
                [styles.tableRowGray]: !equals(changedIndex, -1)
              })}>
                {propOr(false, 'isCanceled', item) && '-'}
                {!propOr(false, 'isCanceled', item) &&
                  prop('deliveryDate', item) &&
                  prop('deliveryDate', item)}
                {!propOr(false, 'isCanceled', item) &&
                  !prop('deliveryDate', item) &&
                  prop('deliveryDatePossible', item)}
                {!propOr(false, 'isCanceled', item) &&
                  !prop('deliveryDate', item) &&
                  !prop('deliveryDatePossible', item) &&
                  'Определяется'}
              </span>
            </Link>
          )}
          {!isHeader && (
            <Link to={to}>
              <span className={cx(styles.tableCell, {
                [styles.tableRowGray]: !equals(changedIndex, -1)
              })}>
                {propOr('', 'LAST_NAME', expeditor)}{' '}
                {propOr('', 'NAME', expeditor)}
              </span>
              <span className={styles.secondName}>
                {propOr('', 'SECOND_NAME', expeditor)}
              </span>
              <span className={styles.newLine}>
                {propOr('', 'PHONE', expeditor)}
              </span>
            </Link>
          )}
          <Link to={to}>
            <span className={cx(styles.tableCell, {
              [styles.tableRowGray]: !equals(changedIndex, -1)
            })}>
              <span className={styles.bold}>
                <CurrencyValue
                  price={propOr(0, 'sum', item)}
                  isPoints={isPoints}
                />
              </span>
              {!!tax && (
                <span className={cx(styles.gray, styles.taxInfo)}>
                  НДС:
                  <CurrencyValue
                    price={tax}
                    className={styles.taxValue}
                    bold={false}
                  />
                </span>
              )}
              <span className={styles.gray}>{propOr('', 'cnt', item)}</span>
            </span>
          </Link>
          {!isHeader && (
            <div
              role='presentation'
              className={styles.tableCell}
              onClick={this.handleClick}
            >
              <OrderDocumentsDropdown
                id={prop('id', item)}
                docPrint={pathOr(false, ['documents', 'PRINTED'], item)}
                certPrint={pathOr(false, ['documents', 'CERTIFICATION'], item)}
                isBillActive={pathOr(false, ['documents', 'BILL'], item)}
                mod='OrderTable'
                isShowText={false}
              />
            </div>
          )}
        </div>
        {!isHeader && (
          <div className={styles.store}>
            {storeName && (
              <span
                className={cx(styles.label, styles.labelStore, {
                  [styles.labelStoreMain]: isMain
                })}
              >
                {storeName}
              </span>
            )}
            {itemType !== 'MAIN' && (
              <span className={cx(styles.label, styles[`label${itemType}`])}>
                {prop(itemType, typeToPrettyName)}
              </span>
            )}
          </div>
        )}
      </div>
    )
  }

  renderEmpty = () => (
    <div className={cx(styles.invoiceList, styles.invoiceListEmpty)}>
      Список заказов пуст
    </div>
  )

  renderSortButton = (prettyName, codeName) => {
    const { sortBy, order, isCabinetHome } = this.props

    return (
      <button
        type='button'
        className={cx(styles.thSortButton, {
          [styles.thSortButtonActive]: sortBy === codeName,
          [styles.thSortButtonNotClicable]: isCabinetHome
        })}
        onClick={this.setSortBy(codeName)}
      >
        {prettyName}
        {sortBy === codeName && (
          <Icon
            className={cx(styles.thSortArrow, {
              [styles.thSortArrowDesc]: order === 'DESC'
            })}
            icon='down'
            size='small'
          />
        )}
      </button>
    )
  }

  renderList = () => {
    const { isHeader } = this.props
    return (
      <div
        className={cx(styles.table, styles.invoiceList, {
          [styles.invoiceListHeader]: isHeader
        })}
      >
        {!isHeader && (
          <div className={cx(styles.tableRow, styles.tableRowHeader)}>
            <div className={styles.th}>
              {this.renderSortButton('Счет', 'DATE')}
            </div>
            <div className={styles.th}>Контрагент</div>
            <div className={styles.th}>Адрес</div>
            <div className={styles.th}>
              {this.renderSortButton('Статус', 'STATUS')}
            </div>
            <div className={styles.th}>
              {this.renderSortButton('Дата доставки', 'DELIVERY_DATE')}
            </div>
            <div className={styles.th}>Экспедитор</div>
            <div className={styles.th}>
              {this.renderSortButton('Сумма', 'SUM')}
            </div>
            <div className={styles.th} />
          </div>
        )}
        {this.props.items.map(this.renderRow, this)}
      </div>
    )
  }

  render() {
    const { items, errorMessage } = this.props
    return (
      <div className={styles.invoiceListWrapper}>
        {isEmpty(items) ? this.renderEmpty() : this.renderList()}
        <Modal id='invoiceError'>
          <div className={styles.modalError}>{errorMessage}</div>
        </Modal>
      </div>
    )
  }
}

export default InvoiceList
