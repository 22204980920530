import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'

import formatAmount from 'utils/formatAmount'
import noun from 'utils/noun'

import styles from './PointsValue.scss'

class PointsValue extends PureComponent {
  static propTypes = {
    points: pt.number,
    capitalize: pt.bool,
    coloredTitle: pt.bool,
    mode: pt.string,
    size: pt.number,
    titleSize: pt.number
  }

  static defaultProps = {
    capitalize: true,
    coloredTitle: true,
    size: 18,
    titleSize: 18
  }

  render() {
    const { points, capitalize, coloredTitle, mode, size , titleSize} = this.props

    if (typeof points === 'undefined' || Number.isNaN(Number(points))) {
      return null
    }

    return (
      <span
        className={cx(styles.Points, {
          [styles.Points_capitalize]: capitalize,
          [styles.Points_coloredTitle]: coloredTitle,
          [styles[`Points_mode_${mode}`]]: mode,
          [styles[`Points__size_${size}`]]: size
        })}
        itemProp='currency'
      >
        <span className={styles.PointsValue}>{formatAmount(points)}</span>
        <div className={cx(styles.PointsTitle, {
          [styles[`PointsTitle__size_${titleSize}`]]: titleSize
        })}>
          {noun(points, ['балл', 'балла', 'баллов'])}
        </div>
      </span>
    )
  }
}

export default PointsValue
