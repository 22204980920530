import { compose, pathOr, values } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

const initialState = {
  bookmarks: [],
  isLoading: false,
  isLoaded: false,
  errors: '',
  warnings: ''
}

export {
  getIsLoaded,
  getIsLoading,
  getBookmarks,
  getErrorsBookmarks,
  getWarningsBookmarks,
  bookmarkNameSelector,
  bookmarksCatalogSelector
} from './selector'

export const fetch = createAction('bookmarks/GET_BOOKMARKS')
export const fetchBookmarksSuccess = createAction(
  'bookmarks/GET_BOOKMARKS_SUCCESS'
)
export const fetchBookmarksFailure = createAction(
  'bookmarks/GET_BOOKMARKS_FAILURE'
)

export const request =
  ({ clientApi }) =>
    params => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v2/contractor/bookmarks/', {
          params: {
            contractor_id: contractorId,
            ...params
          }
        })
        .then(fetchBookmarksSuccess)
        .catch(fetchBookmarksFailure)
    }

export const handleFetchBookmarks = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

export const handleFetchBookmarksSuccess = (state, payload) => {
  const bookmarks = compose(
    values,
    pathOr([], ['data', 'response', 'ITEMS'])
  )(payload)
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    bookmarks,
    warnings: pathOr('', ['data', 'warnings', 'not_bookmark'], payload)
  }
}

export const handleFetchBookmarksFailure = (state, payload) => ({
  ...state,
  bookmarks: [],
  isLoading: false,
  isLoaded: false,
  errors: getErrorMessage(payload)
})

const reducer = createReducer(on => {
  on(fetch, handleFetchBookmarks)
  on(fetchBookmarksSuccess, handleFetchBookmarksSuccess)
  on(fetchBookmarksFailure, handleFetchBookmarksFailure)
}, initialState)

export default reducer
