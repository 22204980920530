import React from 'react'

import { connect } from 'react-redux'

import Modal from 'components/Modal'
import { getIsActive, getName } from 'redux/modules/modal'

export default connect(({ modal }, { id }) => ({
  isActive: getIsActive(modal) && getName(modal) === id
}))(props => props.isActive && <Modal {...props} />)

