import React, { FC, CSSProperties } from 'react'

import cx from 'classnames'

import styles from './Icon.scss'
import { IconSizeProps, IconTypeProps } from './types'

interface IIconProps {
  size?: IconSizeProps
  icon: IconTypeProps
  style?: CSSProperties
  className?: string
  title?: string
  disabled?: boolean
  isToggler?: boolean
  isActive?: boolean
  onClick?: () => void
}

const Icon: FC<IIconProps> = ({
  size = 'default',
  icon = 'logo-default',
  className = '',
  title = '',
  style = {},
  disabled = false,
  isToggler = false,
  isActive = false,
  onClick = () => {}
}) => {
  return (
    <img
      className={cx(styles.icon, {
        [className]: !!className,
        [styles.toggler]: isToggler,
        [styles.togglerActive]: isActive,
        [styles.disabled]: disabled,
        [styles[size]]: !!size
      })}
      style={style}
      src={`/icons/${icon}.svg`}
      alt={icon}
      onClick={onClick}
      role='presentation'
      title={title}
    />
  )
}

export default Icon
