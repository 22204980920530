/* eslint-disable complexity */
import React, { useEffect, useCallback, useState } from 'react'

import { func, string } from 'prop-types'
import { isEmpty } from 'ramda'
import { compose } from 'redux'

import Icon from 'components/Icon'
import ModalForm from 'containers/FormIo/FoundMistakeModal'
import { forDesktop } from 'decorators/device'
import { forAuth } from 'decorators/user'
import Text from 'UI/Text'

import s from './ProductTabs.scss'

const KEY_CODE_13 = 13

const FoundMistake = ({ selectedText = '', onSetSelectedText = () => {} }) => {

  const [ isOpenModal, setIsOpenModal ] = useState(false)

  const onCloseModal = useCallback(() => {
    setIsOpenModal(false)
    onSetSelectedText('')
  }, [])

  const handleKeyDown = useCallback(e => {
    if (
      e.keyCode === KEY_CODE_13 &&
      e.ctrlKey &&
      !isEmpty(selectedText)
    ) {
      setIsOpenModal(true)
    }
  }, [selectedText])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <>
      <div className={s.mistake}>
        <Icon icon='text-icon' />
        <Text>
          Ошибка в тексте? Выделите нужный фрагмент и нажмите CTRL+ENTER
        </Text>
      </div>
      {isOpenModal && (
        <ModalForm
          selectedText={selectedText}
          onClose={onCloseModal}
        />
      )}
    </>
  )
}

FoundMistake.propTypes = {
  selectedText: string,
  onSetSelectedText: func
}

export default compose(
  forAuth,
  forDesktop
)(FoundMistake)
