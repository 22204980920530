import React, { memo } from 'react'

import { bool, string } from 'prop-types'
import { isEmpty, map, pathOr, propOr } from 'ramda'
import { useSelector } from 'react-redux'

import Icon from 'components/Icon'
import Link from 'components/Link'
import config from 'config'
import {
  expirationSelector,
  getForecastObj,
  loyaltyHeaderSelector
} from 'redux/modules/loyalty'
import { getTheme } from 'redux/modules/theme'
import Text from 'UI/Text'

import { LOYALTY_LINKS } from './constants'
import s from './TabBar.scss'

const LoyaltyOption = memo(({ to, title, icon, semibold }) => (
  <li className={s.loyaltyOptions__item}>
    <Link to={to}>
      <div className={s.loyaltyOption}>
        {icon && <span className={s.loyaltyOption__iconWrapper}>
          <Icon icon={icon}
            size='big' />
        </span>}
        <Text semibold={semibold}>{title}</Text>
      </div>
    </Link>
  </li>
))

LoyaltyOption.propTypes = {
  to: string.isRequired,
  title: string.isRequired,
  icon: string.isRequired,
  semibold: bool.isRequired
}

const LoyaltyOptions = () => {
  const expiration = useSelector(({ loyalty }) => expirationSelector(loyalty))
  const loyaltyBlock = useSelector(state => loyaltyHeaderSelector(state))
  const { theme } = useSelector(state => getTheme(state))
  const isShowExpiration = !isEmpty(expiration)
  const expirationForecast = isShowExpiration ? getForecastObj(expiration) : {}
  const dateExpiration = propOr('', 'dateExpiration', expirationForecast)
  const statusIcon = type => pathOr('', ['themes', theme, 'icons', type], config)
  const statusText = propOr('', 'statusText', loyaltyBlock)
  const points = propOr('', 'points', loyaltyBlock)
  const pointsDimension = propOr('', 'pointsDimension', loyaltyBlock)
  const sum = propOr('', 'sum', expirationForecast)

  return (
    <div className={s.loyaltyOptions}>
      <ul className={s.loyaltyOptions__list}>
        {map(linkItem => {
          const to = propOr('', 'to', linkItem)
          const title = propOr('', 'title', linkItem)
          const icon = propOr('', 'icon', linkItem)
          const semibold = propOr(false, 'semibold', linkItem)
          return (
            <LoyaltyOption
              to={to}
              title={title || statusText}
              icon={statusIcon(icon)}
              semibold={semibold}
            />
          )
        })(LOYALTY_LINKS)}
      </ul>
      <div className={s.loyaltyOptions__info}>
        <div className={s.loyaltyOption}>
          <Icon icon='wallet'
            size='middle' />
          <Text color='pink'
            bold>
            {`${points} ${pointsDimension}`}
          </Text>
        </div>
        <div className={s.loyaltyOption}>
          <Icon className={s.loyaltyOption__icon_flame}
            icon='flame'
            size='big' />
          <div className={s.loyaltyOption__textBlock}>
            <Text>{`Сгорит ${sum}`}</Text>
            <Text>{dateExpiration}</Text>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoyaltyOptions
