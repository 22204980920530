import React from 'react'

import cx from 'classnames'
import { number, string } from 'prop-types'

import styles from './LoyaltyCoefficient.scss'

const LoyaltyCoefficient = ({ coefficientTotal, className }) => {
  if (!coefficientTotal) return null
  const title = `${coefficientTotal}% баллами ДЖЭМ \nза этот товар!`

  return (
    <span className={cx(className, styles.loyaltyCoefficient)}
      title={title}>
      {`x${coefficientTotal}%`}
    </span>
  )
}
LoyaltyCoefficient.propTypes = {
  coefficientTotal: number,
  className: string
}

export default LoyaltyCoefficient
