import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

import objectToQuery from 'helpers/objectToQuery'
import { showModal } from 'redux/modules/modal'

import { parseBrandFilters } from './selector'

const initialState = {
  isLoading: false,
  isLoaded: false,
  items: [],
  activeSection: '',
  filters: [],
  sections: [],
  id: undefined,
  dateFilter: {},
  itemsOnPage: 48,
  selectedPage: 1,
  itemsCount: 0,
  topSections: [],
  modalItem: {}
}

export {
  getIsLoaded,
  getIsLoading,
  getCurrentSection,
  getSections,
  getSection,
  getItem,
  getSelectedSection,
  getPageStorageSections,
  getParentSection,
  getBreadCrumbs,
  getTitle
} from './selector'

export const fetch = createAction('storage/FETCH')
const fetchSuccess = createAction('storage/FETCH_SUCCESS')
const fetchFailure = createAction('storage/FETCH_FAILURE')
export const fetchSections = createAction('storageSections/FETCH_SECTIONS')
const fetchSectionsSuccess = createAction(
  'storageSections/FETCH_SECTIONS_SUCCESS'
)
const fetchSectionsFailure = createAction(
  'storageSections/FETCH_SECTIONS_FAILURE'
)
export const fetchTopSections = createAction('storageTop/FETCH_TOP_SECTIONS')
const fetchTopSectionsSuccess = createAction(
  'storageTop/FETCH_TOP_SECTIONS_SUCCESS'
)
const fetchTopSectionsFailure = createAction(
  'storageTop/FETCH_TOP_SECTIONS_FAILURE'
)
export const fetchElement = createAction('storage/elements/FETCH_ELEMENT')
const fetchElementSuccess = createAction(
  'storage/elements/FETCH_ELEMENT_SUCCESS'
)
const fetchElementFailure = createAction(
  'storage/elements/FETCH_ELEMENT_FAILURE'
)

export const setFilterChecked = createAction(
  'storageSections/SET_FILTER_CHECKED'
)
export const setFilterDate = createAction('storageSections/SET_FILTER_DATE')
export const setItemsPerPage = createAction(
  'storageSections/SET_ITEMS_PER_PAGE'
)
export const setSelectedPage = createAction(
  'storageSections/SET_ITEMS_PER_PAGE'
)
export const setActiveSection = createAction('storage/SET_ACTIVE_SECTION')

const requestTopSections =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v2/content/storage/section/top/', {})
        .then(fetchTopSectionsSuccess)
        .catch(fetchTopSectionsFailure)

const handleTopSectionsFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTopSections({ clientApi }), payload)
  )

export const handleTopSectionsFetchSuccess = (state, payload) => ({
  ...state,
  topSections: R.pathOr([], ['data', 'response', 'ITEMS'], payload),
  isLoading: false,
  isLoaded: true
})

export const handleTopSectionsFetchFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false
})

const request =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v2/content/storage/sections/', {})
        .then(fetchSuccess)
        .catch(fetchFailure)

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      id: payload.id
    },
    Effects.promise(request({ clientApi }), payload)
  )

export const handleFetchSuccess = (state, payload) => ({
  ...state,
  items: R.pathOr([], ['data', 'response', 'ITEMS'], payload),
  isLoading: false,
  isLoaded: true
})

export const handleFetchFailure = state => ({
  ...state,
  items: [],
  isLoading: false,
  isLoaded: false
})

const handleSetActiveSection = (state, { activeSection }) => ({
  ...state,
  activeSection
})

const requestSections =
  ({ clientApi }) =>
    ({ id, query }) =>
      clientApi
        .get(`/v2/content/storage/section/${id}/${objectToQuery(query)}`, {})
        .then(fetchSectionsSuccess)
        .catch(fetchSectionsFailure)

const handleFetchSections = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false,
      id: payload.id
    },
    Effects.promise(requestSections({ clientApi }), payload)
  )

const handleFetchSectionsSuccess = (state, payload) => {
  const filters = R.pathOr([], ['data', 'response', 'FILTER', 'UP_TM'], payload)
  const sections = R.pathOr([], ['data', 'response', 'ITEMS'], payload)
  const dateFilter = R.pathOr(
    [],
    ['data', 'response', 'FILTER', 'DATE'],
    payload
  )
  const itemsCount = R.pathOr([], ['data', 'response', 'NAV', 'CNT'], payload)

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    filters: parseBrandFilters(filters),
    sections,
    dateFilter,
    itemsCount
  }
}

const handleFetchSectionsFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  sections: [],
  itemsCount: 0
})

const handleFilterChecked = (
  state,
  { itemId, isItemChecked },
  { clientApi }
) => {
  const filters = R.propOr([], 'filters', state).map(item =>
    item.id === itemId
      ? {
        ...item,
        value: isItemChecked
      }
      : item
  )

  const checkedFilters = filters.filter(item => item.value).map(item => item.id)
  const requestQueryObject = {
    filter: {
      brand_id: checkedFilters,
      date_from: state.dateFilter.FROM,
      date_to: state.dateFilter.TO
    },
    count: state.itemsOnPage,
    page: state.selectedPage
  }

  return loop(
    {
      ...state,
      filters
    },
    Effects.promise(requestSections({ clientApi }), {
      query: requestQueryObject,
      id: state.id
    })
  )
}

const handleSetFilterDate = (state, { type, date }, { clientApi }) => {
  const filters = R.propOr([], 'filters', state)

  const checkedFilters = filters.filter(item => item.value).map(item => item.id)
  const dateFilter = {
    ...state.dateFilter,
    [type]: date
  }

  const requestQueryObject = {
    filter: {
      date_from: dateFilter.FROM,
      date_to: dateFilter.TO,
      brand_id: checkedFilters
    },
    count: state.itemsOnPage,
    page: state.selectedPage
  }

  return loop(
    {
      ...state,
      dateFilter
    },
    Effects.promise(requestSections({ clientApi }), {
      query: requestQueryObject,
      id: state.id
    })
  )
}

const handleSetItemsPerPage = (state, payload, { clientApi }) => {
  const filters = R.propOr([], 'filters', state)
  const checkedFilters = filters.filter(item => item.value).map(item => item.id)

  const requestQueryObject = {
    filter: {
      brand_id: checkedFilters,
      date_from: state.dateFilter.FROM,
      date_to: state.dateFilter.TO
    },
    count: payload,
    page: state.selectedPage
  }

  return loop(
    {
      ...state,
      itemsOnPage: payload
    },
    Effects.promise(requestSections({ clientApi }), {
      query: requestQueryObject,
      id: state.id
    })
  )
}

const handleSetSelectedPage = (state, page, { clientApi }) => {
  const filters = R.propOr([], 'filters', state)
  const checkedFilters = filters.filter(item => item.value).map(item => item.id)
  const requestQueryObject = {
    filter: {
      brand_id: checkedFilters,
      date_from: state.dateFilter.FROM,
      date_to: state.dateFilter.TO
    },
    page,
    count: state.itemsOnPage
  }

  return loop(
    {
      ...state,
      selectedPage: page
    },
    Effects.promise(requestSections({ clientApi }), {
      query: requestQueryObject,
      id: state.id
    })
  )
}

const requestElement =
  ({ clientApi }) =>
    ({ id }) =>
      clientApi
        .get(`/v2/content/storage/elements/${id}/`, {})
        .then(fetchElementSuccess)
        .catch(fetchElementFailure)

const handleElementFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestElement({ clientApi }), payload)
  )

export const handleElementFetchSuccess = (state, payload) =>
  loop(
    {
      ...state,
      modalItem: R.pathOr({}, ['data', 'response', 'ITEM'], payload),
      isLoading: false,
      isLoaded: true
    },
    Effects.call(showModal, 'storage')
  )

export const handleElementFetchFailure = state => ({
  ...state,
  modalItem: {},
  isLoading: false,
  isLoaded: false
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(setActiveSection, handleSetActiveSection)
  on(fetchSections, handleFetchSections)
  on(fetchSectionsSuccess, handleFetchSectionsSuccess)
  on(fetchSectionsFailure, handleFetchSectionsFailure)
  on(setFilterChecked, handleFilterChecked)
  on(setFilterDate, handleSetFilterDate)
  on(setItemsPerPage, handleSetItemsPerPage)
  on(setSelectedPage, handleSetSelectedPage)
  on(fetchTopSections, handleTopSectionsFetch)
  on(fetchTopSectionsSuccess, handleTopSectionsFetchSuccess)
  on(fetchTopSectionsFailure, handleTopSectionsFetchFailure)
  on(fetchElement, handleElementFetch)
  on(fetchElementSuccess, handleElementFetchSuccess)
  on(fetchElementFailure, handleElementFetchFailure)
}, initialState)

export default reducer
