import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

import { setMarks } from 'redux/modules/products'

const initialState = {
  carousel: {
    items: [],
    isLoading: false,
    isLoaded: false,
    isError: false
  },
  loyaltyCarousel: {
    items: [],
    isLoading: false,
    isLoaded: false,
    isError: false
  }
}

export { getCarousel, getIsLoaded, getIsError } from './selector'

export const fetchCarouselSuccess = createAction(
  'carousel/FETCH_CAROUSEL_SUCCESS'
)
export const fetchCarouselFailure = createAction(
  'carousel/FETCH_CAROUSEL_FAILURE'
)

const handleFetchCarouselSuccess = (state, { carousel, catalog }) => {
  const carouselName = catalog === 'loyalty' ? 'loyaltyCarousel' : 'carousel'
  const entities = R.compose(
    R.reduce(
      (acc, item) => ({
        ...R.mergeDeepRight(acc, R.propOr({}, 'ENTITIES', item))
      }),
      {}
    ),
    R.values
  )(carousel)
  return loop(
    {
      ...state,
      [carouselName]: {
        items: carousel,
        isLoading: false,
        isLoaded: true
      }
    },
    Effects.call(setMarks, entities)
  )
}

const handleFetchCarouselFailure = (state, { catalog }) => {
  const carouselName = catalog === 'loyalty' ? 'loyaltyCarousel' : 'carousel'
  return {
    ...state,
    [carouselName]: {
      ...state[carouselName],
      isLoading: false,
      isLoaded: false,
      isError: true
    }
  }
}

const reducer = createReducer(on => {
  on(fetchCarouselSuccess, handleFetchCarouselSuccess)
  on(fetchCarouselFailure, handleFetchCarouselFailure)
}, initialState)

export default reducer
