import React, { PureComponent } from 'react'

import { array, func, string } from 'prop-types'
import { replace, propOr, equals, prop } from 'ramda'

import Icon from 'components/Icon'
import Button from 'UI/Button'

import styles from './Certification.scss'

const DECIMAL = 2

const formatBytes = (bytes, decimals = DECIMAL) => {
  if (bytes === 0) return '0 байт'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['байт', 'кб', 'мб', 'гб', 'тб', 'пб', 'эб', 'зб', 'йб']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return replace(
    '.',
    ',',
    `${parseFloat((bytes / k ** i).toFixed(dm))}${sizes[i]}`
  )
}

export default class Certification extends PureComponent {
  static propTypes = {
    items: array,
    download: func,
    productId: string
  }

  static defaultProps = {
    items: []
  }

  handleDownloadArchive = () => {
    this.props.download(`/v3/catalog/main/product/${this.props.productId}/files/?action=download`, {
      method: 'get'
    })
  }

  // eslint-disable-next-line complexity
  renderRow = item => {
    const type = propOr('', 'TYPE', item)
    const downloadLink = propOr('', 'PATH', item)
    const isDownload = !equals('nalichiesertifikata', type) && downloadLink
    const format = propOr('', 1, type.split('/')).toUpperCase()

    return (
      <div key={prop('ID', item)}
        className={styles.tr}>
        <Icon className={styles.iconDoc}
          icon='document' />
        <div className={styles.tdName}>
          <div className={styles.tdNameText}>{propOr('', 'NAME', item)}</div>
          {isDownload && prop('SIZE', item) && (
            <div className={styles.tdNameSize}>
              {format} {formatBytes(prop('SIZE', item))}
            </div>
          )}
        </div>
        <div className={styles.tdDownload}>
          {isDownload ? (
            <a target='_blank'
              rel='noopener noreferrer'
              href={downloadLink}>
              <Icon className={styles.iconDownload}
                icon='download' />
              Скачать
            </a>
          ) : (
            <span className={styles.certificationInfo}>Наличие</span>
          )}
        </div>
      </div>
    )
  }

  renderDownloadArchive = () => {
    return (
      <div>
        <Button
          color='transparentBlue'
          icon='download'
          onClick={this.handleDownloadArchive}
          size='small'
          title='Скачать архив'
        />
      </div>
    )
  }

  render() {
    const { items } = this.props
    return (
      <div className={styles.certification}>
        {items.map(this.renderRow)}
        {this.renderDownloadArchive()}
      </div>
    )
  }
}
