import { compose, join, values, pick, lte, indexOf, propOr } from 'ramda'

const LIMIT_48 = 48
const LIMIT_64 = 64
const LIMIT_88 = 88

export const showLimits = [LIMIT_48, LIMIT_64, LIMIT_88]
export const showLimitsLoyalty = [LIMIT_48, LIMIT_64, LIMIT_88]

export const viewTypesAll = {
  list: {
    icon: 'icon-bars',
    title: 'Список'
  }
}

export const viewTypesShow = ['list', 'table']
export const viewTypesLoyalty = ['card', 'table']

export const orderList = [
  {
    optionValue: 'По популярности',
    key: 'popularity'
  },
  {
    optionValue: 'По цене от мин к макс',
    key: 'price',
    hideNoLoyaltyAccess: true,
    hideWithoutPassword: true
  },
  {
    optionValue: 'По цене от макс к мин',
    key: 'price|reverse',
    hideNoLoyaltyAccess: true,
    hideWithoutPassword: true
  },
  {
    optionValue: 'По алфавиту от А до Я',
    key: 'name'
  },
  {
    optionValue: 'По алфавиту от Я до А',
    key: 'name|reverse'
  }
]

export const defaultParams = {
  limit: 48,
  sort: 'popularity',
  order: 'desc'
}

export const defaultsBySection = {
  default: defaultParams,
  loyalty: {
    limit: 12,
    sort: 'popularity',
    order: 'desc'
  }
}

export const queryParamsKeys = ['offset', 'limit', 'sort', 'order']

export const getParamsKey = compose(join('_'), values, pick(queryParamsKeys))

export const filtersFromQuery = (filters = '') => {
  if (!filters) {
    return null
  }
  const parsed = filters.split(';').reduce((acc, item) => {
    const [key, value] = item.split(':')
    if (['min', 'max'].indexOf(key) !== -1) {
      acc[`${key.toUpperCase()}_PRICE`] = parseInt(value, 10)
    } else if (key === 'marks') {
      acc[key] = value.split(',')
    } else {
      acc[key.toUpperCase()] = value.split(',').map(val => val)
    }
    return acc
  }, {})
  return parsed
}

export const filterInStock = compose(
  lte(0),
  indexOf('IN_STOCK'),
  propOr([], 'marks')
)
