/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, object, array, string, func, number } from 'prop-types'
import { propOr, path, pathOr, isEmpty, keys, length } from 'ramda'
import { Link } from 'react-router-dom'
import { Element } from 'react-scroll'
import SliderSlick from 'react-slick'

import CapPreloader from 'components/CapPreloader'
import Icon from 'components/Icon'
import Product from 'components/Product/Product'
import PropertyTable from 'components/Product/PropertyTable'
import Certification from 'components/ProductPage/Certification'
import RubleSign from 'components/RubleSign'
import Scrollbar from 'components/Scrollbar'
import TabsContent from 'components/TabsContent'
import config from 'config'
import BrandSlider from 'containers/BrandSlider'
import Tabs from 'containers/Tabs'
import basket from 'decorators/basket'
import product from 'decorators/product'
import { setAnalyticsProps } from 'helpers/metaTags'
import {
  preparePropertySelector,
  productTabsSelector,
  prepareParamsSelector
} from 'redux/modules/products'
import Title from 'UI/Title'
import { sliderProducts } from 'utils/sliderConfig'

import FoundMistake from './FoundMistake'
import s from './ProductTabs.scss'
import ProductReview from '../ProductReview'

@basket
@product
export default class ProductTabs extends PureComponent {
  static propTypes = {
    isUserWithoutPassword: bool,
    item: object,
    analogsCarousels: object,
    isQuickView: bool,
    isLoyalty: bool,
    isBookmark: bool,
    isProductLoaded: bool,
    bookmark: number,
    cert: array,
    tabsName: string,
    handleHideModal: func,
    getTechDoc: func,
    setProductBarcodeModal: func,
    fetchProductReview: func,
    download: func,
    feedback: array,
    ratingTypes: array,
    sortTypes: array,
    id: string
  }

  static defaultProps = {
    isUserWithoutPassword: false,
    item: {},
    cert: [],
    analogsCarousels: {},
    isBookmark: false,
    tabsName: String(new Date().valueOf()),
    handleHideModal: () => {},
    getTechDoc: () => {},
    fetchProductReview: () => {},
    feedback: [],
    ratingTypes: [],
    sortTypes: [],
    id: ''
  }

  state = {
    tabActive: 'description',
    selectedText: ''

  }

  handleSelection = () => {
    const selectedText = window.getSelection().toString()
    this.setState({ selectedText })
  }

  handleSetSelectedText = selectedText => this.setState({ selectedText })

  handleSetTab = item => {
    this.setState({ tabActive: propOr('', 'id', item) })
  }

  handleBarcodeClick = () => {
    const { item, setProductBarcodeModal } = this.props
    setProductBarcodeModal(propOr('', 'ID', item))
  }

  renderProductList = type => {
    const { analogsCarousels, isBookmark, bookmark } = this.props
    const items = pathOr([], [type, 'items'], analogsCarousels)
    if (isEmpty(items)) {
      return null
    }
    const getLowPrice = () => Math.min(...items.map(({ PRICE }) => PRICE))
    return (
      <>
        <meta
          itemProp='offers'
          itemScope
          itemType='http://schema.org/AggregateOffer'
        />
        <meta itemProp='lowPrice'
          content={getLowPrice()} />
        <meta itemProp='priceCurrency'
          content={<RubleSign />} />

        <Title size={16}
          level={2}>
          {pathOr('', [type, 'title'], analogsCarousels)}
        </Title>

        <SliderSlick
          dots={false}
          infinite={false}
          draggable={false}
          speed={500}
          slidesToShow={3}
          slidesToScroll={3}
          className={cx(s.analogsSliderWrapper, {
            [s.analogsSliderWrapper__fadeEffect]: items.length > 1
          })}
          nextArrow={<Icon icon='next' />}
          prevArrow={<Icon icon='prev' />}
          responsive={sliderProducts}
          lazyLoad
        >
          {items.map((item, key) => (
            <Product
              id={item.ID}
              label={propOr({}, 'MARKS', item)}
              key={item.ID}
              serial={key}
              type='card'
              selectable={false}
              isBookmark={isBookmark}
              bookmark={bookmark}
              isQuickView
              isSlider
            />
          ))}
        </SliderSlick>
      </>
    )
  }

  render() {
    const {
      item,
      isUserWithoutPassword,
      isProductLoaded,
      analogsCarousels,
      isQuickView,
      isLoyalty,
      tabsName,
      isBookmark,
      bookmark,
      cert,
      fetchProductReview,
      feedback,
      ratingTypes,
      sortTypes,
      id
    } = this.props

    const properties = preparePropertySelector(item)
    const params = prepareParamsSelector(item)

    const feedbackCount = length(feedback)

    const tabs = productTabsSelector({
      isQuickView,
      analogsCarousels,
      feedbackCount
    })

    const detailText = propOr('', 'DETAIL_TEXT', item)
    const isShowTechDoc =
      !isUserWithoutPassword && propOr(false, 'TECH_DOC', item)
    const filteredTabs = tabs.filter(tab => {
      if (tab.id === 'description') {
        return isQuickView
          ? !!detailText
          : !!detailText || !!properties.length || isShowTechDoc
      }

      if (tab.id === 'params') {
        return !!properties.length
      }

      if (tab.id === 'brands') {
        return !isLoyalty
      }
      return true
    })
    const productSection = Number(path(['SECTION', 'ID'], item))
    if (!isProductLoaded) {
      return (
        <>
          <CapPreloader
            width='20%'
            height='50px'
          />
          <CapPreloader
            count={4}
            height='10px'
          />
        </>
      )
    }

    return isQuickView ? (
      <>
        <div className={cx(s.tabs, s.tabs_quickView)}>
          <Tabs
            name={tabsName}
            defaultActive='description'
            active={this.state.tabActive}
            items={filteredTabs}
            onClick={this.handleSetTab}
            isQuickView={isQuickView}
            isLoyalty={isLoyalty}
          />
        </div>

        <TabsContent name={tabsName}
          id='params'>
          <Scrollbar maxHeight={162}
            minScrollHeight={15}>
            <div className={s.propertyTableQuick}>
              <PropertyTable
                productSection={productSection}
                properties={properties}
                mode='ProductOneQuick'
                isBookmark={isBookmark}
                bookmark={bookmark}
                // eslint-disable-next-line react/jsx-handler-names
                handleHideModal={this.props.handleHideModal}
              />
            </div>
          </Scrollbar>
        </TabsContent>

        <TabsContent name={tabsName}
          id='description'>
          <Scrollbar maxHeight={162}
            minScrollHeight={15}>
            <span
              className={cx(
                s.descriptionText,
                s.descriptionText_quickView
              )}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: detailText || item.PREVIEW_TEXT
              }}
              itemProp='description'
            />
          </Scrollbar>
        </TabsContent>

        <Link
          color='blue'
          weight='semibold'
          to={`${
            isBookmark
              ? `/bookmark/${bookmark}/catalog/product/`
              : '/catalog/product/'
          }${item.ID}`}>
          <div className={s.moreInfoLink}>
            Больше информации
            <Icon className={s.iconScrollToProperty}
              icon='next' />
          </div>
        </Link>
      </>
    ) : (
      <div className={s.container}>
        {keys(analogsCarousels).map(key => (
          <Element
            key={`carousel${key}`}
            name={key}
            {...setAnalyticsProps({
              type: 'action',
              group: 'productCard',
              action: key,
              product: propOr('', 'CODE_1C', item),
              itemProp: 'offers'
            })}
          >
            <div className={s.analogs}>{this.renderProductList(key)}</div>
          </Element>
        ))}

        {!isLoyalty && (
          <Element name='brands'>
            <div className={s.brands}>
              <BrandSlider
                page='product'
                sectionId={productSection}
                sectionName={pathOr('', ['SECTION', 'NAME'], item)}
              />
            </div>
          </Element>
        )}
        <FoundMistake
          selectedText={this.state.selectedText}
          onSetSelectedText={this.handleSetSelectedText}
        />
        <Element name='description'>
          {(detailText || item.PREVIEW_TEXT) && (
            <Title size={16}
              level={2}>
              Информация о товаре
            </Title>
          )}
          <span
            className={s.descriptionText}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: detailText || item.PREVIEW_TEXT
            }}
            itemProp='description'
            role='presentation'
            onMouseUp={this.handleSelection}
          />
          <div className={s.descriptionProperties}>
            <div className={s.propertyTable}>
              <Title size={16}
                level={2}>
                Характеристики
              </Title>
              <PropertyTable
                properties={properties}
                productSection={productSection}
                isLoyalty={isLoyalty}
                isBookmark={isBookmark}
                bookmark={bookmark}
                mode='ProductTabs'
              />
            </div>
            <div className={s.propertyTable}>
              {!isEmpty(params) && !isUserWithoutPassword && (
                <div className={s.propertyTableParams}>
                  <Title
                    size={16}
                    level={2}
                  >
                    Дополнительные параметры
                  </Title>
                  <PropertyTable
                    properties={params}
                    productSection={productSection}
                    isLoyalty={isLoyalty}
                    isBookmark={isBookmark}
                    bookmark={bookmark}
                    mode='ProductTabs'
                  />
                  <div
                    role='presentation'
                    className={s.barcode}
                    onClick={this.handleBarcodeClick}
                  >
                    Характеристики и маркировка упаковок
                  </div>
                </div>
              )}
              {isShowTechDoc && (
                <div className={s.propertyTableDoc}>
                  <Title
                    size={16}
                    level={2}
                  >
                    Документы
                  </Title>
                  <Certification
                    items={cert}
                    download={this.props.download}
                    productId={id}
                  />
                </div>
              )}
            </div>
          </div>
        </Element>
        {/* Отзывы о товаре закомментировать */}
        {/* eslint-disable-next-line no-constant-condition */}
        {config.formIo.isActive && false ? (
          <Element name='productreview'>
            <Title size={16}
              level={2}>
              Отзывы о товаре
            </Title>
            <ProductReview
              feedback={feedback}
              fetchProductReview={fetchProductReview}
              productId={id}
              ratingTypes={ratingTypes}
              sortTypes={sortTypes}
            />
          </Element>
        ) : null}
      </div>
    )
  }
}
