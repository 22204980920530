import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Api = loadable(() => import(/* webpackChunkName: "Api" */'pages/Api/Api'))

export default asyncConnect([
  {
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'api' })
      return updateData.update()
    }
  }
])(
  Api
)