import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const notificationsSelector = createSelector(
  state => state,
  state => propOr([], 'notifications', state)
)

export const filtersSelector = createSelector(
  state => state,
  state => propOr([], 'filters', state)
)
