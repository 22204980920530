import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getCarousel = createSelector(
  (state, typeCarousel) => propOr({}, typeCarousel, state),
  state => propOr([], 'items', state)
)
export const getIsLoaded = createSelector(
  (state, typeCarousel) => propOr({}, typeCarousel, state),
  state => propOr(false, 'isLoaded', state)
)

export const getIsError = createSelector(
  (state, typeCarousel) => propOr({}, typeCarousel, state),
  state => propOr(false, 'isError', state)
)
