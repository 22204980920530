/* eslint-disable complexity */
const getStringValue = (name, string) => {
  const index = string.indexOf(name)
  const sliceArgs = [index + name.length]
  if (string.includes('(')) {
    sliceArgs.push(string.indexOf('('))
  }

  return string.slice(...sliceArgs).trim()
}

export const parseTitle = (title = '') => {
  const fields = {}
  const titleItems = title.split(',')
  const index = titleItems[0]
  const house = titleItems.find(string => string.includes('дом №'))
  const housing = titleItems.find(string => string.includes('корпус'))
  const structure = titleItems.find(string => string.includes('строение'))
  const apartment = titleItems.find(string => string.includes('кв.'))
  const office = titleItems.find(string => string.includes('офис'))

  if (!Number.isNaN(+index)) fields.index = index

  if (house) fields.house = getStringValue('дом №', house)

  if (housing) fields.housing = getStringValue('корпус', housing)

  if (structure) fields.structure = getStringValue('строение', structure)

  if (apartment) fields.apartment = getStringValue('кв.', apartment)

  if (office) fields.office = getStringValue('офис', office)

  return fields
}

export const daysLocal = {
  MONDAY: {
    name: 'Понедельник',
    shortName: 'Пн'
  },
  TUESDAY: {
    name: 'Вторник',
    shortName: 'Вт'
  },
  WEDNESDAY: {
    name: 'Среда',
    shortName: 'Ср'
  },
  THURSDAY: {
    name: 'Четверг',
    shortName: 'Чт'
  },
  FRIDAY: {
    name: 'Пятница',
    shortName: 'Пт'
  },
  SATURDAY: {
    name: 'Суббота',
    shortName: 'Сб'
  },
  SUNDAY: {
    name: 'Воскресенье',
    shortName: 'Вс'
  }
}
