/* eslint-disable complexity */
import R from 'ramda'
import { compose } from 'redux'

const parsUpper = pars =>
  R.ifElse(
    R.is(Array),
    R.map(item => R.ifElse(R.is(String), R.toUpper, R.identity)(item)),
    R.identity
  )(pars)

export const getBasketInfo = (item, name) =>
  R.compose(
    R.pick(['QUANTITY', 'STORE', 'FOR_POINTS']),
    R.pathOr({}, R.flatten(['BASKETS', parsUpper(name)]))
  )(item)

export const getBasket = (item, name) =>
  Number(R.prop('QUANTITY', getBasketInfo(item, name)))

export const getBasketForPoints = (item, name) =>
  Boolean(R.propOr(false, 'FOR_POINTS', getBasketInfo(item, name)))

export const getLimitsCount = currentStore =>
  compose(
    R.reduce(
      (acc, item) => ({
        min: Math.min(acc.min, R.prop('MIN', item)),
        max: Math.max(acc.max, R.propOr(0, 'MAX', item))
      }),
      { min: Infinity, max: 0 }
    ),
    R.propOr([], ['MULTIPLICITY'])
  )(currentStore)

export const getInitialLimitsCount = currentStore =>
  compose(
    R.reduce(
      (acc, item) => ({
        min: Math.min(acc.min, R.prop('MIN', item)),
        max: Math.max(acc.max, R.propOr(0, 'MAX', item))
      }),
      { min: Infinity, max: 0 }
    ),
    R.filter(item => !!R.prop('DEF', item)),
    R.propOr([], ['MULTIPLICITY'])
  )(currentStore)

export const isMultipleBuy = (cVal, cStore) => {
  const ret = { isMultiple: false, isBuy: true }
  const cMultiple = compose(
    R.head,
    R.filter(
      item =>
        Math.min(R.prop('MIN', item), R.prop('MAX', item)) <= cVal &&
        Math.max(R.prop('MIN', item), R.prop('MAX', item)) > cVal
    ),
    R.propOr([], 'MULTIPLICITY')
  )(cStore)
  if (R.isEmpty(cMultiple)) {
    return { ...ret, isBuy: false }
  }

  const isBuyMultiple = cVal % R.prop('STEP_MULTIPLE', cMultiple) === 0
  const isBuyNotMultiple = cVal % R.prop('STEP_NOT_MULTIPLE', cMultiple) === 0

  if (isBuyNotMultiple || isBuyMultiple) {
    return {
      ...ret,
      isMultiple: isBuyMultiple,
      price:
        isBuyNotMultiple && !isBuyMultiple
          ? R.propOr(0, 'PRICE_NOT_MULTIPLE', cMultiple)
          : 0
    }
  }
  return { ...ret, isBuy: false }
}

export const getInitialCount = (item, basketType) => {
  const inBasket = getBasket(item, basketType)
  if (inBasket) {
    return inBasket
  }
  const inDelay = getBasket(item, ['delay', 0])

  const store = R.compose(
    R.head,
    R.sortWith([R.ascend(R.prop('SELECTED')), R.ascend(R.prop('IS_MAIN'))]),
    R.filter(
      itm => R.propEq('SELECTED', true, itm) || R.propEq('IS_MAIN', true, itm)
    ),
    R.propOr([], 'STORES')
  )(item)

  const { min, max } = getInitialLimitsCount(store)
  if (inDelay && Number(max) === 0) {
    return inDelay
  }

  return R.clamp(
    Math.min(...[min].concat(max)),
    Math.max(...[min].concat(max)),
    max === 0 ? min : Math.min(...[min].concat(max))
  )
}

export const getOrderData = (item, basketType) => ({
  basketType,
  amount: getInitialCount(item, basketType),
  isForPoints: getBasketForPoints(item, basketType),
  isUpdating: {
    basket: false,
    favorite: false,
    compare: false
  }
})
