import React, { PureComponent } from 'react'

import { bool, number, array, func, object } from 'prop-types'
import { isEmpty, length } from 'ramda'
import CustomScroll from 'react-custom-scroll'
import { connect } from 'react-redux'

import BannerMenu from 'components/BannerMenu'
import CatalogList from 'components/CatalogList'
import { getBannerType, bannerMenuSelector } from 'redux/modules/banners'
import { sectionsByIdSelector } from 'redux/modules/sections'

import styles from './MenuRightColumn.scss'

const NUMBER_2 = 2
const NUMBER_3 = 3

@connect(({ sections, banners }, { selectedItemId, bookmark, isBookmark }) => {
  const bannersMenu = getBannerType('CATALOG_MENU')(banners)
  return {
    banner: bannerMenuSelector({
      banners: bannersMenu,
      sectionId: selectedItemId
    }),
    sections: sectionsByIdSelector({
      id: selectedItemId,
      catalogType: isBookmark ? 'bookmark' : 'main',
      sections,
      bookmark
    })
  }
})
export default class MenuRightColumn extends PureComponent {
  static propTypes = {
    sections: array,
    onHideCatalog: func,
    isBookmark: bool,
    bookmark: number,
    catalogHeight: number,
    selectedItemId: number,
    banner: object
  }

  static defaultProps = {
    sections: [],
    banner: {}
  }

  render() {
    const {
      sections,
      bookmark,
      isBookmark,
      onHideCatalog,
      catalogHeight,
      banner,
      selectedItemId
    } = this.props
    if (length(sections) === 0) return null

    return (
      <div className={styles.columnRight}>
        <CustomScroll heightRelativeToParent={`${catalogHeight}px`}>
          <div className={styles.rightColumn}>
            <div className={styles.catalogList}>
              <CatalogList
                columnCount={isEmpty(banner) ? NUMBER_3 : NUMBER_2}
                isBookmark={isBookmark}
                bookmark={bookmark}
                catalogArray={sections}
                onHideCatalog={onHideCatalog}
                withoutImages
              />
            </div>
            <BannerMenu banner={banner}
              sectionId={selectedItemId} />
          </div>
        </CustomScroll>
      </div>
    )
  }
}
