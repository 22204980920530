import React, { ElementType, FC, ReactNode } from 'react'

import TooltipDesktop from 'components/Tooltip/TooltipDesktop'
import TooltipMobile from 'components/Tooltip/TooltipMobile'
import { display } from 'decorators/device'

interface ITooltipBase {
  isMobile: boolean
}

export interface ITooltip {
  children?: ReactNode
  disabled?: boolean
  tag?: ElementType
  tooltip?: string
  tooltipNode?: ReactNode
}

const Tooltip: FC<ITooltipBase> = ({ isMobile, ...rest }) =>
  isMobile ? <TooltipMobile {...rest} /> : <TooltipDesktop {...rest} />

export default display(Tooltip)
