/* eslint-disable complexity */
/* eslint-disable react/jsx-no-bind */
import React, {FC, useCallback, useState} from 'react'

import cx from 'classnames'

import IconCheckbox from 'UI/Icons/IconCheckbox'
import flattenInput from 'utils/flattenInput'

import s from './Checkbox.scss'

interface ICheckboxProps {
  checked?: boolean
  children?: React.ReactNode | React.ReactNode[]
  disabled?: boolean
  id?: string
  inactive?: boolean
  name?: string
  onChange?: (checked: boolean, event: React.ChangeEvent<HTMLInputElement>) => void
  title?: string | undefined
  value?: string | number
}

const Checkbox: FC<ICheckboxProps> = ({
  checked = false,
  children,
  disabled = false,
  id,
  inactive = false,
  name= '',
  onChange = () => {},
  title,
  value
}) => {
  const [hoverState, setHoverState] = useState(false)
  const handleChange = useCallback(
    (event:React.ChangeEvent<HTMLInputElement>) => {
      if(!disabled && onChange) {
        onChange(!checked, event)
      }
    },
    [checked, disabled, onChange]
  )

  const out = children ?? title

  return (
    <div
      onMouseEnter={() => setHoverState(true)}
      onMouseLeave={() => setHoverState(false)}
      className={cx(s.checkbox, {
        [s.checkbox_disabled]: disabled,
        [s.checkbox_inactive]: inactive
      })}
    >
      <label htmlFor={id}>
        <input
          type='checkbox'
          id={id}
          checked={!!checked}
          className={s.checkbox__field}
          onChange={handleChange}
          name={name}
          value={value}
        />
        <div className={s.checkbox__label}>
          <IconCheckbox
            hoverState={hoverState}
            checked={checked}
            disabled={disabled}
          />
          {out}
        </div>
      </label>
    </div>
  )
}

export default Checkbox

interface IFieldCheckboxProps {
  value: any
}

export const FieldCheckbox = flattenInput(({ value, ...rest }: IFieldCheckboxProps) => (
  <Checkbox
    checked={!!value}
    {...rest}
  />
))
