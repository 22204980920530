import { compose, map, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import formatPhoneNumber from 'utils/formatPhoneNumber'

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getStatusConfirm = state => state.statusConfirm
export const getMessage = state => state.message
export const getFormData = createSelector(
  (form, props) => ({ form, props }),
  ({ form, props }) => prop(form, props)
)

const formatEmployee = item => ({
  ...item,
  PHONE: formatPhoneNumber(prop('PHONE')(item))
})

export const getEmployees = createSelector(
  state => state,
  state => compose(map(formatEmployee), propOr([], 'employees'))(state)
)
