/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { number, func, array, bool } from 'prop-types'
import { composeP, prop, propOr, length, compose, filter, slice } from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import ContentWrapper from 'components/ContentWrapper'
import Icon from 'components/Icon'
import Image from 'components/Image'
import Interactions from 'components/Interactions'
import Link from 'components/Link'
import Scrollbar from 'components/Scrollbar'
import { socketSend } from 'redux/modules/socket'
import moment from 'utils/moment'

import s from './Notification.scss'

const NUMBER_100 = 100
const MAX_HEIGHT = 415
// const SIZE_LIMIT = 10
const NUMBER_85 = 85
const NUMBER_25 = 25

const notificationPush = createSelector(
  state => state,
  state => compose(slice(0, NUMBER_100), propOr([], 'notificationPush'))(state)
)

const count = createSelector(notificationPush, state => length(state))

const countNewSelector = createSelector(notificationPush, state =>
  compose(
    length,
    filter(item => !propOr(false, 'VIEW', item))
  )(state)
)

@connect(
  ({ socket }) => ({
    countAll: count(socket),
    countNew: countNewSelector(socket),
    notifications: notificationPush(socket)
  }),
  {
    socketSend
  }
)
export default class Push extends PureComponent {
  static propTypes = {
    socketSend: func,
    notifications: array,
    isLoyalty: bool,
    countAll: number,
    countNew: number
  }

  static defaultProps = {
    socketSend: () => {},
    notifications: [],
    isLoyalty: false
  }

  state = { isVisible: false }

  handleShow = () => this.setState({ isVisible: true })

  handleHide = () => this.setState({ isVisible: false })

  handleMarkViewed = () => this.props.socketSend({ type: 'read' })

  handleFetchMore = () =>
    this.props.socketSend({ type: 'read' })

  handleShowMore = () =>
    composeP(
      () => this.handleMarkViewed(),
      () => this.handleFetchMore()
    )()

  renderLabel = () => {
    const { countNew, countAll, isLoyalty } = this.props
    const labelText = countAll ? 'Новых уведомлений нет' : 'Уведомлений нет'
    return (
      <Interactions
        className={s.pushBell}
        onDelayedMouseEnter={this.handleMarkViewed}
        mouseEnterDelay={600}
      >
        <Link to='/cabinet/notification'>
          <div className={cx(s.pushLabel, {
            [s.pushLabelHighlight]: countNew > 0,
            [s.pushLabelLoyalty]: isLoyalty
          })}>
            <Icon icon='bell'
              className={s.pushMessageBell} />
            {countNew > 0 ? (
              <span className={s.pushLabelText}>
                Новых уведомлений ({countNew < NUMBER_100 ? countNew : '99+'})
              </span>
            ) : (
              <span className={s.pushLabelText}>{labelText}</span>
            )}
          </div>
        </Link>
      </Interactions>
    )
  }

  renderMessages = item => {
    const date = prop('dateStart', item) ? moment(prop('dateStart', item)).format('DD.MM.YYYY') : ''
    return (
      <li
        itemProp='itemListElement'
        itemScope
        itemType='http://schema.org/ItemList'
        className={s.pushMessage}
        key={prop('id', item)}
      >
        <div className={s.pushMessageLink}>
          <div className={s.pushMessageIcon}>
            {prop('icon', item) ? (
              <Image
                backgroundSize='contain'
                src={prop('icon', item)}
                alt='Уведомление'
                className={s.pushMessageImage}
              />
            ) : (
              <Icon icon='bell'
                className={s.pushMessageBell} />
            )}
          </div>
          <div className={s.pushMessageContent}>
            <div className={s.pushMessageTitle}>
              <ContentWrapper
                html={prop('text', item)}
              />
            </div>
            <div className={s.pushMessageDate}>
              {date}
            </div>
          </div>
        </div>
      </li>
    )
  }

  renderList = () => {
    const { notifications, countAll } = this.props
    const { isVisible } = this.state
    const maxHeight =
      typeof window !== 'undefined'
        ? Math.min(MAX_HEIGHT, window.innerHeight - NUMBER_85)
        : MAX_HEIGHT
    const isShowMore = NUMBER_100 < countAll
    return (
      <div
        className={cx(s.pushPanel, {
          [s.pushPanelVisible]: isVisible
        })}
      >
        {isVisible && (
          <div className={s.pushMenu}>
            {notifications.length ? (
              <Scrollbar maxHeight={maxHeight}
                minScrollHeight={NUMBER_25}>
                <ul className={s.pushMessages}>
                  {notifications.map(this.renderMessages)}
                </ul>
                {isShowMore && (
                  <div
                    role='presentation'
                    className={s.pushShowMore}
                    onClick={this.handleShowMore}
                  >
                    Показать еще
                  </div>
                )}
              </Scrollbar>
            ) : (
              <div className={s.pushEmpty}>
                <Icon icon='bell'
                  className={s.pushEmptyIcon} />
                <div className={s.pushEmptyText}>
                  У вас нет ни одного уведомления
                </div>
              </div>
            )}
            <div className={s.pushFooter}>
              <Link to='/cabinet/notification'>
                <div className={s.pushFooterLink}>
                  <Icon icon='bell'
                    className={s.pushFooterBell} />
                  Все уведомления
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }

  render() {
    return (
      <Interactions
        className={s.push}
        onDelayedMouseEnter={this.handleShow}
        onDelayedMouseLeave={this.handleHide}
      >
        <div className={s.pushNotifications}>
          {this.renderLabel()}
          {this.renderList()}
        </div>
      </Interactions>
    )
  }
}
