import { pathOr, equals, compose } from 'ramda'

const checkIsButtonDelete = button =>
  compose(equals('DELETE'), pathOr('', [0, 'CODE']))(button)

const getIcon = code => {
  switch (code) {
    case 'ERROR':
      return 'notice-exclamation'
    case 'LOYALTY':
      return 'for-points'
    default:
      return 'notice-exclamation'
  }
}

export { getIcon, checkIsButtonDelete }
