import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Offer = loadable(() =>
  import(/* webpackChunkName: "Offer" */ 'pages/Offer/Offer')
)

export default asyncConnect([
  {
    key: 'pageOffer',
    promise: ({ match, helpers: { updateData } }) => {
      const id = pathOr(0, ['params', 'id'], match)
      updateData.set({ id, view: 'offer' })
      return updateData.update()
    }
  }
])(Offer)
