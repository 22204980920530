import { path, pathOr, prop, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

export {
  invoiceData,
  productsSelector,
  receiptData,
  invoiceLoading,
  orderDataSelector,
  isErrorInvoice
} from './selector'

export const fetchInvoice = createAction('invoice_details/FETCH')
const fetchInvoiceSuccess = createAction('invoice_details/FETCH_SUCCESS')
const fetchInvoiceFailure = createAction('invoice_details/FETCH_FAILURE')

export const fetchInvoiceItems = createAction('invoice_details/FETCH_PRODUCTS')
const fetchInvoiceItemsSuccess = createAction(
  'invoice_details/FETCH_PRODUCTS_SUCCESS'
)
const fetchInvoiceItemsFailure = createAction(
  'invoice_details/FETCH_PRODUCTS_FAILURE'
)

const initialState = {
  shipment: {},
  bill: {},
  products: {
    isError: false,
    isLoaded: false,
    isLoading: false
  },
  isError: false,
  isLoaded: false,
  isLoading: false
}

// Получить детализацию счета
const requestInvoice = ({ clientApi }) => ({ type = '', id = '' }) =>
  clientApi
    .get(`/v3/invoice/${type}/${id}/`, {
      params: {
        contractor_id: clientApi.getContractorId()
      }
    })
    .then(fetchInvoiceSuccess)
    .catch(fetchInvoiceFailure)

const handleFetchInvoice = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isError: false,
      isLoaded: false,
      isLoading: true
    },
    Effects.promise(requestInvoice({ clientApi }), payload)
  )

const handleFetchInvoiceSuccess = (state, { data }) => ({
  ...state,
  isError: false,
  isLoaded: true,
  isLoading: false,
  shipment: {
    invoice: pathOr({}, ['response', 'ITEM'], data),
    entities: pathOr({}, ['response', 'ENTITIES'], data),
    receipt: pathOr([], ['response', 'RECEIPT'], data)
  },
  errors: propOr({}, 'errors', data)
})

const handleFetchInvoiceFailure = state => ({
  ...state,
  shipment: {},
  isError: true,
  isLoaded: false,
  isLoading: false
})

// Получить товары счета
const getInvoiceItems = ({ clientApi }) => ({ id = '' }) =>
  clientApi
    .get(`/v3/invoice/bill/${id}/product/`, {
      params: {
        contractor_id: clientApi.getContractorId()
      }
    })
    .then(({ data }) => fetchInvoiceItemsSuccess({ data, id }))
    .catch(() => fetchInvoiceItemsFailure({ id }))

const handleFetchInvoiceItems = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      products: {
        ...state.products,
        [prop('id', payload)]: {
          isError: false,
          isLoaded: false,
          isLoading: true,
          products: [],
          entities: {}
        }
      }
    },
    Effects.promise(getInvoiceItems({ clientApi }), payload)
  )

const handleFetchInvoiceItemsSuccess = (state, { data, id }) => ({
  ...state,
  products: {
    ...path(['products'], state),
    [id]: {
      ...path(['products', id], state),
      products: pathOr([], ['response', 'PRODUCT'], data),
      entities: pathOr({}, ['response', 'ENTITIES'], data),
      invoice: pathOr({}, ['response', 'ITEM'], data),
      isError: false,
      isLoaded: true,
      isLoading: false
    }
  },
  bill: {
    invoice: pathOr({}, ['response', 'ITEM'], data),
    entities: pathOr({}, ['response', 'ENTITIES'], data),
    receipt: pathOr([], ['response', 'RECEIPT'], data)
  }
})

const handleFetchInvoiceItemsFailure = (state, { id }) => ({
  ...state,
  isError: true,
  products: {
    ...path(['products'], state),
    [id]: {
      isError: true,
      isLoaded: false,
      isLoading: false
    }
  },
  bill: {}
})

export default createReducer(on => {
  on(fetchInvoice, handleFetchInvoice)
  on(fetchInvoiceSuccess, handleFetchInvoiceSuccess)
  on(fetchInvoiceFailure, handleFetchInvoiceFailure)
  on(fetchInvoiceItems, handleFetchInvoiceItems)
  on(fetchInvoiceItemsSuccess, handleFetchInvoiceItemsSuccess)
  on(fetchInvoiceItemsFailure, handleFetchInvoiceItemsFailure)
}, initialState)
