/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import {
  oneOfType,
  bool,
  func,
  element,
  node,
  arrayOf,
  number
} from 'prop-types'

import styles from './AlwaysFixed.scss'

const END_SCROLL_POSITION = 1e6
const OFFSET_150 = 150
const SCROLL_WIDTH = 17
export default class AlwaysFixed extends PureComponent {
  static propTypes = {
    children: oneOfType([node, arrayOf(node)]),
    scrollPosition: number,
    selectedCount: number,
    getEndScrollPosition: func,
    isFixed: bool,
    isBottom: bool,
    hasTransition: bool,
    isModal: bool,
    onToggleVisible: func,
    showUpButton: func,
    isHideBeforeScroll: bool,
    isDisabledScroll: bool,
    takePlaceWhileFixed: element,
    isClickThrough: bool
  }

  static defaultProps = {
    isFixed: false,
    isBottom: false,
    hasTransition: false,
    isModal: false,
    isClickThrough: false,
    isDisabledScroll: false,
    isHideBeforeScroll: false,
    selectedCount: 0,
    getEndScrollPosition: () => END_SCROLL_POSITION,
    onToggleVisible: () => {},
    showUpButton: () => {}
  }

  constructor(props) {
    super(props)
    this.state = {
      isHidden: props.isHideBeforeScroll
    }
  }

  componentDidMount() {
    this.handleScroll()
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleScroll)
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCount !== prevProps.selectedCount) {
      this.handleScroll()
    }
  }

  componentWillUnmount() {
    this.props.onToggleVisible(false)
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleScroll)
  }

  handleScroll = () => {
    if (this.props.isHideBeforeScroll) {
      if (window.pageYOffset <= OFFSET_150 && !this.state.isHidden) {
        this.setState({
          isHidden: true
        })
      } else if (window.pageYOffset > OFFSET_150 && this.state.isHidden) {
        this.setState({
          isHidden: false
        })
      }
    }

    const isFixed = this.shouldBeFixed(window.pageYOffset)

    if (isFixed !== this.state.isFixed) {
      this.setState({
        isFixed
      })
      this.props.onToggleVisible(isFixed)
    }
  }

  shouldBeFixed = scroll => {
    const { scrollPosition, getEndScrollPosition, selectedCount } = this.props
    const endScrollPosition = getEndScrollPosition(scroll)

    // для BottomPanel:
    // если выбраны товары, показывать панель всегда
    // но показывать кнопку наверх, если проскроллили вниз
    this.props.showUpButton(scrollPosition <= scroll)

    return (
      (scrollPosition <= scroll || !!selectedCount) &&
      endScrollPosition >= scroll
    )
  }

  render() {
    const {
      children,
      isBottom,
      hasTransition,
      isModal,
      isDisabledScroll,
      takePlaceWhileFixed,
      isClickThrough
    } = this.props
    const { isFixed, isHidden } = this.state

    const layout = (
      <div
        className={cx({
          [styles.fixed]: isFixed || this.props.isFixed,
          [styles.fixed_hideColor]: isFixed,
          [styles.bottom]: isBottom,
          [styles.hidden]: isHidden,
          [styles.transition]: hasTransition,
          [styles.clickThrough]: isClickThrough
        })}
      >
        <div
          style={{
            paddingRight: (isModal || isDisabledScroll) && isFixed ? SCROLL_WIDTH : 0
          }}
        >
          {children}
        </div>
      </div>
    )

    if (!takePlaceWhileFixed) {
      return layout
    }

    return (
      <>
        {layout}
        {(isFixed || isHidden) && takePlaceWhileFixed}
      </>
    )
  }
}
