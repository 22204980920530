/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { func, bool, string, number, object } from 'prop-types'
import { pick, prop, propOr, path, pathOr, slice } from 'ramda'
import { Link as RouterLink } from 'react-router-dom'

import HonestSign from 'components/HonestSign'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Picture from 'components/Picture'
import Cap from 'components/Product/Cap'
import PropertyTable from 'components/Product/PropertyTable'
import ProductActions from 'components/ProductActions'
import ProductCode from 'components/ProductCode'
import ProductGalleryModal from 'components/ProductGalleryModal'
import ProductPrices from 'components/ProductPrices'
import ButtonMoreOptions from 'containers/ButtonMoreOptions'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import Labels from 'containers/Labels'
import basket from 'decorators/basket'
import product from 'decorators/product'
import { preparePropertySelector } from 'redux/modules/products'
import Checkbox from 'UI/Checkbox'
import Spacing from 'UI/Spacing'

import styles from './ProductList.scss'

const PROPERTIES_COUNT = 7

@basket
@product
export default class ProductList extends PureComponent {
  static propTypes = {
    isLoyalty: bool,
    removeFromFavorite: func,
    removeFromCompare: func,
    isUserWithoutPassword: bool,
    onItemSelect: func,
    setForPoints: func,
    withActions: bool,
    id: string,
    amount: number,
    checked: bool,
    delay: number,
    inFavorite: bool,
    inCompare: bool,
    isLast: bool,
    isBookmark: bool,
    bookmark: number,
    item: object,
    multipleBuy: object,
    groupType: string,
    showModal: func,
    setAnalogsModal: func,
    setProductModal: func,
    onChangeOrder: func,
    onChangeInBasket: func,
    setActiveStore: func,
    selectable: bool,
    isQuickView: bool,
    isMultiple: bool,
    isNotMultiple: bool,
    isBuyNotMultiple: bool,
    forPoints: bool,
    isForPoints: bool,
    isForPointsBasket: bool,
    stockDeliveryDate: string,
    activeStore: string,
    basketStore: string,
    remain: number,
    min: number,
    step: number,
    minInit: number,
    stepInit: number,
    basket: number,
    descriptionStores: object,
    isUpdating: object
  }

  static defaultProps = {
    removeFromFavorite: () => {},
    removeFromCompare: () => {},
    setActiveStore: () => {},
    setForPoints: () => {},
    selectable: true,
    isUserWithoutPassword: false,
    isLoyalty: false,
    checked: false,
    inFavorite: false,
    inCompare: false,
    isLast: false,
    isBookmark: false,
    withActions: false,
    isQuickView: false,
    isMultiple: true,
    isNotMultiple: false,
    isBuyNotMultiple: false,
    forPoints: false,
    isForPoints: false,
    isForPointsBasket: false
  }

  state = {
    isImagePopup: false
  }

  handleShowPopup = popup => e => {
    const MIDDLE_BUTTON = 1
    if (e.button === MIDDLE_BUTTON) return
    this.setState({
      [popup]: true
    })
  }

  handleHidePopup = popup => () => {
    this.setState({
      [popup]: false
    })
  }

  handleShowAnalogs = id => () => {
    this.props.setAnalogsModal(id)
  }

  handleShowQuickView = () => {
    const { isQuickView, setProductModal } = this.props
    if (isQuickView) {
      setProductModal(this.props.id)
    }
  }

  handleImageLinkClick = e => {
    const MAIN_BUTTON = 0
    if (e.button === MAIN_BUTTON) e.preventDefault()
  }

  handleSetStore = store => this.props.setActiveStore({ store })

  renderRightColumn() {
    const {
      onChangeInBasket,
      onChangeOrder,
      item,
      multipleBuy,
      isLoyalty,
      isUserWithoutPassword,
      isMultiple,
      isNotMultiple,
      isBuyNotMultiple,
      isBookmark,
      activeStore,
      forPoints,
      isForPoints,
      isForPointsBasket,
      setForPoints,
      bookmark
    } = this.props

    const analogsInfo = pick(['ACTIVE', 'ISSET_ANALOGS'], item)
    return (
      <div
        className={styles.orderBlock}
        itemProp='offers'
        itemScope
        itemType='http://schema.org/Offer'
      >
        <ProductPrices
          className={styles.prices}
          item={item}
          multipleBuy={multipleBuy}
          isLoyalty={isLoyalty}
          isUserWithoutPassword={isUserWithoutPassword}
          onShowAnalogs={this.handleShowAnalogs}
          isMultiple={isMultiple}
          isNotMultiple={isNotMultiple}
          isBuyNotMultiple={isBuyNotMultiple}
          activeStore={activeStore}
          forPoints={forPoints}
          isForPoints={isForPoints}
          setForPoints={setForPoints}
          amount={this.props.basket || this.props.amount}
        />

        <ProductActions
          productId={item.ID}
          inCompare={this.props.inCompare}
          inFavorite={this.props.inFavorite}
          delay={this.props.delay}
          analogsInfo={analogsInfo}
          onShowAnalogs={this.handleShowAnalogs}
          onUpdateGroup={onChangeInBasket}
          onChange={onChangeOrder}
          isBookmark={isBookmark}
          bookmark={bookmark}
          isMultiple={isMultiple}
          isNotMultiple={isNotMultiple}
          isBuyNotMultiple={isBuyNotMultiple}
          isForPoints={isForPoints}
          isForPointsBasket={isForPointsBasket}
          removeFromCompare={this.props.removeFromCompare}
          removeFromFavorite={this.props.removeFromFavorite}
          groupType='catalog'
          type={this.props.groupType}
          onSetStore={this.handleSetStore}
          activeStore={activeStore}
          basketStore={this.props.basketStore}
          item={this.props.item}
          min={this.props.min}
          remain={this.props.remain}
          step={this.props.step}
          minInit={this.props.minInit}
          stepInit={this.props.stepInit}
          amount={this.props.amount}
          basket={this.props.basket}
          isUpdating={this.props.isUpdating}
          descriptionStores={this.props.descriptionStores}
        />
      </div>
    )
  }

  render() {
    const {
      item,
      isLast,
      isBookmark,
      bookmark,
      isLoyalty,
      isUserWithoutPassword,
      selectable,
      isQuickView,
      stockDeliveryDate,
      activeStore,
      onChangeInBasket
    } = this.props

    const article = prop('ARTICLE', item)
    const barcode = prop('BARCODE', item)
    const prepareProperty = preparePropertySelector(item)
    const productSection = Number(path(['SECTION', 'ID'], item))
    const isHonestSign = pathOr(false, ['IS_HONEST_SIGN'], item)
    const outOfAssortment =
      !isUserWithoutPassword && !propOr(true, 'ACTIVE', item)
    const isActive = propOr(true, 'ACTIVE', item)
    const isGrayPreview = isUserWithoutPassword || !isActive ? false : outOfAssortment
    const property = slice(0, PROPERTIES_COUNT, prepareProperty)

    return (
      <div
        className={cx(styles.productList, {
          [styles.productList_last]: isLast,
          [styles.productList_hasStockNotice]: stockDeliveryDate
        })}
        itemScope
        itemType='http://schema.org/AggregateOffer'
        data-testid='productList'
      >
        <div className={styles.productHead}>
          <div className={styles.productLabel}
            data-testid='productLabel'>
            <div className={styles.checkbox}
              data-testid='productCheckbox'>
              {!isUserWithoutPassword && selectable && (
                <Checkbox
                  checked={this.props.checked}
                  onChange={this.props.onItemSelect}
                />
              )}
              {!isUserWithoutPassword && (
                <div className={styles.moreOptions}>
                  <ButtonMoreOptions
                    inFavorite={this.props.inFavorite}
                    inCompare={this.props.inCompare}
                    isBookmark={isBookmark}
                    bookmark={bookmark}
                    activeStore={activeStore}
                    onUpdateGroup={onChangeInBasket}
                    basket={this.props.basket}
                  />
                </div>
              )}
            </div>
            {!isLoyalty && <Labels type='list'
              item={item} />}
          </div>
          <div className={cx(styles.image, {
            [styles.image_grayscale]: !isActive
          })}>
            {isQuickView && (
              <div
                role='presentation'
                className={styles.imageHover}
                onClick={this.handleShowQuickView}
              >
                <Icon className={styles.imageHoverIcon}
                  icon='eye' />
                Быстрый просмотр
              </div>
            )}
            <div className={styles.imageContainer}>
              <RouterLink
                to={`${isLoyalty ? '/loyalty' : ''}${
                  isBookmark
                    ? `/bookmark/${bookmark}/catalog/product/`
                    : '/catalog/product/'
                }${item.ID}`}
                onClick={this.handleImageLinkClick}
              >
                <Picture
                  backgroundSize='contain'
                  imgClassName={cx(styles.listImage, styles.previewPicture, {
                    [styles.previewPicture_grayscale]: isGrayPreview,
                    [styles.previewPicture_zoom]: isLoyalty
                  })}
                  width={192}
                  height={192}
                  src={prop('PREVIEW_PICTURE_PATH', item)}
                  onClick={this.handleShowPopup('isImagePopup')}
                  isLazy={false}
                />
              </RouterLink>
            </div>
            {this.state.isImagePopup && (
              <DisableBodyScroll>
                <ProductGalleryModal
                  id={prop('ID', item)}
                  mainPicture={path(['DETAIL_PICTURE_PATH'], item)}
                  onClose={this.handleHidePopup('isImagePopup')}
                />
              </DisableBodyScroll>
            )}
          </div>
          {isHonestSign && <HonestSign />}
        </div>

        <div className={styles.info}>
          <div className={styles.propertyBlock}>
            <div className={styles.nameBlock}
              itemProp='url'>
              <Link
                to={
                  isBookmark
                    ? `/bookmark/${bookmark}/catalog/product/${prop(
                      'ID',
                      item
                    )}`
                    : `/catalog/product/${prop('ID', item)}`
                }
              >
                <span className={styles.name} 
                  data-testid='productName'>{prop('NAME', item)}</span>
              </Link>
              <div className={styles.group}>
                <ProductCode
                  name='Код'
                  type='code'
                  code={prop('CODE_1C', item)}
                  isLoyalty={isLoyalty}
                />

                {article && (
                  <ProductCode
                    name='Арт'
                    type='vendor'
                    code={article}
                    size='big'
                    isLoyalty={isLoyalty}
                  />
                )}

                {barcode && (
                  <ProductCode
                    name='Штрихкод'
                    type='barcode'
                    code={barcode}
                    size='big'
                    isLoyalty={isLoyalty}
                  />
                )}
              </div>
            </div>

            <div className={styles.propertyTable}>
              <PropertyTable
                mode='ProductList'
                productSection={productSection}
                properties={property}
                isBookmark={isBookmark}
                bookmark={bookmark}
              />
              <Spacing margin='top'
                size='small'>
                <Link
                  to={`${
                    isBookmark ? `/bookmark/${bookmark}` : ''
                  }/catalog/product/${prop('ID', item)}`}
                  color='blue'>
                    Все характеристики
                </Link>
              </Spacing>
            </div>
          </div>

          <div className={styles.rightColumn}>
            {this.props.withActions || outOfAssortment ? (
              this.renderRightColumn()
            ) : (
              <Cap
                showModal={this.props.showModal}
                price={parseFloat(pathOr(0, ['PRICE'], item))}
                multiplicity={pathOr(false, ['MULTIPLICITY_HTML'], item)}
                mode='ProductList'
                isStoreAvailable={pathOr(false, ['STORE', 'AVAILABLE'], item)}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
