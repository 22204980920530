import React, { memo, useCallback, useEffect, useState } from 'react'

import { func } from 'prop-types'
import cookie from 'react-cookie'
import { useHistory } from 'react-router'

import Link from 'components/Link'
import Wrapper from 'components/Wrapper'
import Button from 'UI/Button'
import Text from 'UI/Text'

import s from './CookiePopup.scss'

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAYS_IN_HALF_YEAR = 183

const halfYear = new Date(
  Date.now() + DAYS_IN_HALF_YEAR * HOURS_IN_DAY * MINUTES_IN_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND
)

const cookieOpts = {
  path: '/',
  expires: halfYear
}

const Content = ({ handleConfirm }) => {
  const history = useHistory()
  useEffect(() => {
    return history.listen(() => {
      handleConfirm()
    })
  }, [handleConfirm, history])

  return (
    <div className={s.wrapper}>
      <Wrapper>
        <div className={s.content}>
          <Text>
            Наш сайт использует Сookie-файлы для полноценной работы функционала
            и служб аналитики. Запретить обработку Cookie можно в настройках
            Вашего браузера. Продолжая пользоваться сайтом без изменения
            настроек, Вы даёте согласие на использование Cookie-файлов в
            соответствии с условиями &nbsp;
            <Link to='/documents/policy'
              color='blue'>
              Политики конфиденциальности
            </Link>
          </Text>
          <Button color='lightBlue'
            onClick={handleConfirm}>
            Согласен
          </Button>
        </div>
      </Wrapper>
    </div>
  )
}
Content.propTypes = {
  handleConfirm: func
}

const MemoContent = memo(Content)

const СookiePopup = () => {
  const [hidden, setHidden] = useState(Boolean(cookie.load('cookieNotification')))
  const handleConfirm = useCallback(() => {
    cookie.save('cookieNotification', halfYear, cookieOpts)
    setHidden(true)
  }, [])

  if (hidden) return null
  return <MemoContent handleConfirm={handleConfirm} />
}

export default memo(СookiePopup)
