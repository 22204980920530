import { compose, split, propOr, path } from 'ramda'
import { connect } from 'react-redux'

import MarksMenu from 'components/MarksMenu'
import { getMarksMenu } from 'redux/modules/marksMenu'

export default connect(
  ({
    settings: { mode },
    token: { isUserWithoutPassword },
    router,
    personal: { offers },
    marksMenu
  }) => {
    const page = compose(
      propOr('', 1),
      split('/'),
      path(['location', 'pathname'])
    )(router)

    return {
      isLoyalty: mode === 'loyalty',
      isAuth: !isUserWithoutPassword,
      isOffers: offers && offers.length > 0,
      page,
      marks: getMarksMenu(marksMenu)
    }
  }
)(MarksMenu)
