import React, { PureComponent } from 'react'

import { array } from 'prop-types'

import Text from 'UI/Text'

import styles from './BasketTooltip.scss'

const COUNT_PLACEHOLDER = '+999999'
const COUNT_MAX_LENGTH = 6

export default class BasketTooltip extends PureComponent {
  static propTypes = {
    items: array
  }

  static defaultProps = {
    items: []
  }

  getCount = count =>
    count.toString().length > COUNT_MAX_LENGTH ? COUNT_PLACEHOLDER : count

  renderRow = ({ id, name, count }) => (
    <tr key={id + name}
      className={styles.tableRow}>
      <td className={styles.cell}>
        <div className={styles.innerCellWrapper}>
          <Text>{id}</Text>

          <div className={styles.alpha} />
        </div>
      </td>

      <td className={styles.cell}>
        <div title={name}
          className={styles.innerCellWrapper}>
          <Text>{name}</Text>

          <div className={styles.alpha} />
        </div>
      </td>

      <td className={styles.cell}>
        <div className={styles.innerCellWrapper}
          title={count}>
          <Text>{this.getCount(count)}</Text>

          <div className={styles.alpha} />
        </div>
      </td>
    </tr>
  )

  render() {
    const { items } = this.props

    return (
      <div className={styles.container}>
        <table className={styles.table}>
          <thead className={styles.tableHead}>
            <tr className={styles.tableHeadRow}>
              <td className={styles.headerCell}>
                <Text color='inherit'>
                  Номер заказа
                </Text>
              </td>

              <td className={styles.headerCell}>
                <Text color='inherit'>
                  Наименование клиента
                </Text>
              </td>

              <td className={styles.headerCell}>
                <Text color='inherit'>
                  Кол-во
                </Text>
              </td>
            </tr>
          </thead>

          <tbody className={styles.tableBody}>
            {items.map(this.renderRow)}
          </tbody>
        </table>
      </div>
    )
  }
}
