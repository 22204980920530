import React from 'react'

import { bool } from 'prop-types'
import { compose } from 'redux'

import Modal from 'containers/Modal'
import Registration from 'containers/Registration'
import { display } from 'decorators/device'
import { forUnauth } from 'decorators/user'

const PopupRegistration = ({ isDesktop }) => (
  <Modal
    id='registration'
    size={isDesktop ? 'big' : 'auto'}
  >
    <Registration />
  </Modal>
)

PopupRegistration.propTypes = {
  isDesktop: bool
}

export default compose(
  display,
  forUnauth
)(PopupRegistration)
