import React from 'react'

import { array, number, string } from 'prop-types'
import { prop } from 'ramda'

import Picture from 'components/Picture'
import Button from 'UI/Button'

import s from './CartMenu.scss'

const CartMenu = ({ name, count, sum, points, favorite, compare, products }) => {
  return (
    <div className={s.cartMenu}>
      <div className={s.name}>
        <div className={s.nameTitle}>{name}</div>
        <div className={s.nameCount}>{count}</div>
        <div className={s.sum}>
          <div className={s.nameSum}>{sum}</div>
          <div className={s.namePoints}>{points}</div>
        </div>
      </div>
      <div className={s.action}>
        <Button color='main'>Сменить корзину</Button>
        <div className={s.favorite}>{favorite}</div>
        <div className={s.compare}>{compare}</div>
      </div>
      <div className={s.hr} />
      <div className={s.products}>
        {products.map(item => (
          <div key={prop('id', item)}
            className={s.product}>
            <div className={s.productImage}>
              <Picture
                backgroundSize='contain'
                src={prop('image', item)}
              />
            </div>
            <div className={s.productName}>{prop('name', item)}</div>
            <div className={s.productCount}>{prop('count', item)}</div>
            <div className={s.productSum}>{prop('sum', item)}</div>
          </div>
        ))}
      </div>
      <div className={s.hr} />
      <div className={s.link}>Перейти в корзину</div>
    </div>
  )
}

CartMenu.propTypes = {
  products: array,
  count: number,
  sum: number,
  points: number,
  favorite: number,
  compare: number,
  name: string
}

CartMenu.defaultProps = {
  products: []
}

export default CartMenu
