import React, { Component } from 'react'

import { object } from 'prop-types'

import { FieldTextarea } from 'UI/Textarea'

import styles from '../Registration.scss'

class RegistrationTextarea extends Component {
  static propTypes = {
    meta: object
  }

  render() {
    const { meta, ...props } = this.props
    const isError = meta.invalid && meta.touched

    return (
      <>
        <FieldTextarea className={styles.textarea}
          {...props} />
        {isError && <div className={styles.inputError}>{meta.error}</div>}
      </>
    )
  }
}

export default RegistrationTextarea
