import { prop, propOr, compose, values, join, dissoc } from 'ramda'

export const metaTags = ['description', 'keywords']

export const setAnalyticsProps = props => {
  const type = propOr('', 'type', props)
  const valuesFromProps = compose(join(''), values, dissoc('type'))(props)
  switch (type) {
    case 'action':
      return {
        'data-analytics': valuesFromProps,
        'data-group': prop('group', props),
        'data-action': prop('action', props),
        'data-product': prop('product', props),
        'data-pos': prop('pos', props)
      }
    case 'banner':
      return {
        'data-analytics': valuesFromProps,
        'data-banner': prop('banner', props),
        'data-banner-pos': prop('pos', props),
        'data-banner-alt': prop('alt', props)
      }
    default:
      return { 'data-analytics': valuesFromProps }
  }
}
