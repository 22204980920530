import {
  prop,
  propOr,
  path,
  pathOr,
  compose,
  values,
  map,
  pickBy,
  sortBy,
  find,
  propEq,
  filter,
  isEmpty
} from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading

export const parseBrandFilters = filters =>
  filters.map(item => ({ id: item.ID, title: item.TITLE, value: item.VALUE }))

export const getSections = createSelector(propOr([], 'items'), items =>
  compose(
    sortBy(prop('sort')),
    values(),
    map(item => ({
      id: prop('ID', item),
      icon: propOr('', propOr('', 'NAME', item).trim(), {
        'Печатные каталоги': 'storage-printed',
        Презентации: 'storage-presentations',
        Видео: 'storage-video',
        'Графические материалы': 'storage-graphic'
      }),
      url: `/storage/section/${item.ID}`,
      title: prop('NAME', item),
      picture: prop('PICTURE_PATH', item),
      sort: parseInt(prop('SORT', item), 10)
    })),
    pickBy(val => prop('DEPTH', val) === '1')
  )(items)
)

export const getCurrentSection = createSelector(
  propOr([], 'items'),
  (state, id) => id,
  (items, id) =>
    compose(
      prop(0),
      values(),
      map(item => ({
        id: prop('ID', item),
        title: prop('NAME', item),
        picture: prop('PICTURE_PATH', item)
      })),
      pickBy(val => prop('ID', val) === id)
    )(items)
)

export const getSection = createSelector(
  propOr([], 'items'),
  (state, id) => id,
  (items, id) =>
    compose(
      sortBy(prop('sort')),
      values(),
      map(item => ({
        id: prop('ID', item),
        title: prop('NAME', item),
        sort: parseInt(prop('SORT', item), 10)
      })),
      pickBy(val => prop('PARENT_ID', val) === id)
    )(items)
)

export const getSectionTop = createSelector(
  propOr({}, 'topSections'),
  (state, id) => id,
  (items, id) =>
    compose(
      values(),
      map(item => ({
        id: prop('ID', item),
        title: prop('TITLE', item),
        fileSize: path(['UP_FILE', 'SIZE'], item),
        fileUrl: path(['UP_FILE', 'PATH'], item)
      })),
      propOr([], id)
    )(items)
)

export const hasMaterials = (id, storage) =>
  storage.items.find(item => item.id === id && item.DEPTH === '2')

export const getItem = state => pathOr({}, ['modalItem'], state)

export const getSelectedSection = createSelector(
  storage => storage,
  storage =>
    compose(
      find(propEq('ID', prop('id', storage))),
      propOr([], 'items')
    )(storage)
)

export const getPageStorageSections = createSelector(
  storage => storage,
  storage =>
    compose(
      filter(propEq('PARENT_ID', prop('id', storage))),
      propOr([], 'items')
    )(storage)
)

export const getParentSection = createSelector(
  storage => storage,
  storage => getSelectedSection(storage),
  (storage, selectedSection) =>
    compose(
      find(propEq('ID', prop('PARENT_ID', selectedSection))),
      propOr([], 'items')
    )(storage)
)

export const getBreadCrumbs = createSelector(
  storage => getParentSection(storage),
  storage => getSelectedSection(storage),
  (parentSection = {}, selectedSection = {}) => {
    const breadCrumbs = [{ title: 'Контент-хранилище', pathname: '/storage' }]
    if (!isEmpty(parentSection)) {
      breadCrumbs.push({
        title: `${propOr('', 'NAME', parentSection)}`,
        pathname: `/storage/section/${propOr(0, 'ID', parentSection)}/`
      })
    }
    if (!isEmpty(selectedSection)) {
      breadCrumbs.push({
        title: `${propOr('', 'NAME', selectedSection)}`,
        pathname: `/storage/section/${propOr(0, 'ID', parentSection)}/`
      })
    }

    return breadCrumbs
  }
)

export const getTitle = createSelector(
  storage => getSelectedSection(storage),
  selectedSection => propOr('', 'NAME', selectedSection)
)
