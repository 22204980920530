import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const LoyaltyContent = loadable(() =>
  import(
    /* webpackChunkName: "LoyaltyContent" */ 'pages/LoyaltyContent/LoyaltyContent'
  )
)

export default asyncConnect([
  {
    key: 'pageLoyaltyContent',
    promise: ({ match, helpers: { updateData } }) => {
      const id = pathOr('', ['params', 'type'], match)
      updateData.set({ view: 'content', type: 'loyalty', id })

      return updateData.update()
    }
  }
])(LoyaltyContent)
