import React, { useState, useEffect } from 'react'

import cx from 'classnames'
import { push } from 'connected-react-router'
import { func, string, number } from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Icon from 'components/Icon'
import Portal from 'components/Portal'
import Modal from 'containers/Modal'
import { forAuth } from 'decorators/user'
import { hideModal } from 'redux/modules/modal'
import { cartSelector } from 'redux/modules/productList'
import {
  catalogTypeSelector,
  setNotification,
  initNotification
} from 'redux/modules/settings'

import s from './PopupSettingsCart.scss'

const TIMEOUT_TO_FETCH = 3000
const STEP_1 = 1
const STEP_2 = 2

const PopupSettingsCart = ({
  cart = 0,
  catalog = 'main',
  onHideModal,
  onPush,
  onSetNotification,
  onInitNotification
}) => {
  const [step, setStep] = useState(STEP_1)

  useEffect(() => {
    const timerFetchSettings = setTimeout(
      () => onInitNotification({}),
      TIMEOUT_TO_FETCH
    )
    return () => {
      clearTimeout(timerFetchSettings)
    }
  }, [])

  const onReset = () => {
    setStep(STEP_1)
  }

  const onYesClick = () => () => {
    setStep(STEP_2)
    onSetNotification({ key: 'check_change', value: false })
  }

  const onNoClick = () => () => {
    onHideModal()
  }

  const handleReset = () => () => {
    onReset()
  }

  const onNavigateCart = () => () => {
    onReset()
    onHideModal()
    onPush(`/basket/${catalog}/${cart}`)
  }

  return (
    <Portal>
      <Modal id='cartActionSettings'
        size='small'
        onClose={handleReset()}>
        <div className={s.content}>
          {step === STEP_1 && (
            <>
              <div className={cx(s.icon, s.iconStep1)}>
                <Icon className={s.iconInfo}
                  icon='notice-exclamation' />
              </div>
              <div className={s.desc}>
                Вы хотите выключить уведомления о добавлении и удалении товаров
                из корзин?
              </div>
              <div className={s.buttons}>
                <button
                  type='button'
                  className={cx(s.button, s.buttonYes)}
                  onClick={onYesClick()}
                >
                  Да
                </button>
                <button
                  type='button'
                  className={cx(s.button, s.buttonNo)}
                  onClick={onNoClick()}
                >
                  Нет
                </button>
              </div>
            </>
          )}
          {step === STEP_2 && (
            <>
              <div className={cx(s.icon, s.iconStep2)}>
                <Icon icon='check'
                  className={s.iconCheck} />
              </div>
              <div className={s.desc}>
                <p>Уведомления отключены.</p>
                <p>Включить уведомления можно</p>
                <p>
                  <div
                    role='presentation'
                    className={s.link}
                    onClick={onNavigateCart()}
                  >
                    в корзине
                  </div>
                </p>
              </div>
            </>
          )}
        </div>
      </Modal>
    </Portal>
  )
}

PopupSettingsCart.propTypes = {
  cart: number,
  catalog: string,
  onHideModal: func,
  onSetNotification: func,
  onInitNotification: func,
  onPush: func
}

export default compose(
  connect(
    ({ productList, settings }) => ({
      cart: cartSelector(productList),
      catalog: catalogTypeSelector(settings)
    }),
    {
      onHideModal: hideModal,
      onPush: push,
      onSetNotification: setNotification,
      onInitNotification: initNotification
    }
  ),
  forAuth
)(PopupSettingsCart)
