import React, { PureComponent } from 'react'

import { string, bool } from 'prop-types'
import { path } from 'ramda'
import { connect } from 'react-redux'

import Link from 'components/Link'
import SocialLinks from 'components/SocialLinks'
import config from 'config'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import { getThemeName } from 'helpers/getTheme'
import Spacing from 'UI/Spacing'

import styles from './ServerError.scss'

@connect(({ apiAuth, router }) => ({
  theme: getThemeName({ apiAuth, router })
}))
export default class ServerError extends PureComponent {
  static propTypes = {
    text: string,
    theme: string,
    subText: string,
    isWithoutRedirect: bool
  }

  static defaultProps = {
    theme: 'default',
    text: 'На сайте ведутся технические работы',
    subText:
      'В настоящее время наши программисты делают для вас сайт еще лучше!'
  }

  componentDidMount() {
    document.body.style.overflow = 'hidden'
  }

  handleReload = () => {
    if (typeof window !== 'undefined') {
      window.location.reload(true)
    }
  }

  render() {
    const { text, subText, isWithoutRedirect, theme } = this.props
    const { company, year } = path(['themes', theme], config)
    return (
      <DisableBodyScroll>
        <div className={styles.servererror}>
          <div className={styles.servererrorWrap}>
            <div className={styles.process}>
              <img
                width={140}
                height={140}
                alt='error'
                className={styles.servererrorImage}
                src='/images/technical/animated.gif'
              />
            </div>
            <div className={styles.version}>2.0</div>
            <div className={styles.wrapBg}>
              <div className={styles.textBase}>{text}</div>
              <div className={styles.subTextBase}>{subText}</div>
              {isWithoutRedirect && (
                <Spacing margin='top'
                  size='big'>
                  <Link
                    color='blue'
                    size='small'
                    onClick={this.handleReload}>
                    Обновить страницу
                  </Link>
                </Spacing>
              )}
            </div>
            <SocialLinks className={styles.socials} />
          </div>
          <div className={styles.footer}>
            {company}, {year}
          </div>
        </div>
      </DisableBodyScroll>
    )
  }
}
