import {
  prop,
  propOr,
  pathOr,
  compose,
  reduce,
  keys,
  map,
  indexOf,
  sortBy,
  reverse,
  length,
  slice,
  sum,
  append,
  includes,
  mergeAll,
  without,
  equals,
  isEmpty,
  filter,
  split,
  head,
  take,
  join,
  set,
  lensIndex
} from 'ramda'
import { createSelector } from 'reselect'

import moment from 'utils/moment'

const colors = [
  '#4F69F6',
  '#F15751',
  '#34AF31',
  '#9243F1',
  '#00A7FF',
  '#838383'
]
const MAX_LENGTH = 6
const LAST_LINE_INDEX = 5
const REST_LINE = 'Остальное'
const GROUP_NAMES = {
  CONTRACTOR: 'Контрагент',
  NODE: 'Склад отгрузки',
  COUNTRY: 'Страна производитель',
  SECTION: 'Раздел классификатора',
  TM: 'Торговая марка',
  FOR_POINTS: 'Тип оплаты'
}
const FIRST_PAGE = 1
const NUMBER_3 = 3
const NUMBER_48 = 48
const HUNDRED = 100

export const isDateRangeSelected = createSelector(
  query => propOr('', 'dateFrom', query),
  query => propOr('', 'dateTo', query),
  (dateFrom, dateTo) => !!dateFrom || !!dateTo
)

export const aggregationSelector = createSelector(
  state => pathOr({}, ['aggregation', 'items'], state),
  data =>
    compose(
      mergeAll,
      map(key => {
        const itemsFilter = compose(
          map(item => ({
            value: propOr('', 'ID', item),
            title: propOr('', 'NAME', item)
          })),
          propOr([], key)
        )(data)
        return {
          [key]: itemsFilter
        }
      }),
      keys
    )(data)
)

export const groupBySelector = createSelector(
  state => state,
  state =>
    compose(
      map(key => ({
        value: key,
        title: `Способ группировки: ${propOr('', key, GROUP_NAMES)}`
      })),
      without(['BOOKMARK']),
      keys
    )(state)
)

const prepareDataForDiagram = (diagramData, groupBy) => {
  const fullData = slice(0, LAST_LINE_INDEX, diagramData)
  const restData = slice(LAST_LINE_INDEX, Infinity, diagramData)
  const restObj = reduce(
    (acc, item) => ({
      [groupBy]: REST_LINE,
      SUM: sum([propOr(0, 'SUM', acc), propOr(0, 'SUM', item)])
    }),
    {}
  )(restData)
  return append(restObj, fullData)
}

export const diagramSelector = createSelector(
  (state, groupBy) => {
    // данные, отсортированные по убыванию суммы оборота
    const diagramData = compose(
      reverse,
      sortBy(prop('SUM')),
      pathOr([], ['diagram', 'items'])
    )(state)

    // общий оборот
    const sumValue = reduce(
      (acc, item) => sum([acc, propOr(0, 'SUM', item)]),
      0,
      diagramData
    )

    // если больше шести, то остальное объединяем в одну колонку
    const prepareData =
      length(diagramData) > MAX_LENGTH
        ? prepareDataForDiagram(diagramData, groupBy)
        : diagramData

    const result = map(item => {
      const index = indexOf(item, prepareData)
      const value = propOr(0, 'SUM', item)
      return {
        dataKey: propOr('', groupBy, item),
        value,
        color: propOr('', index, colors),
        percent: `${((value / sumValue) * HUNDRED).toFixed(0)}%`
      }
    })(prepareData)
    return {
      data: result,
      lineNames: compose(
        filter(item => !isEmpty(item)),
        reduce((acc, item) => [...acc, propOr('', 'dataKey', item)], [])
      )(result)
    }
  },
  state => state
)

const shortMonthSelector = createSelector(
  key => key,
  key => {
    const date = moment(key, 'X').locale('ru').format('MMMM YY')
    const dateArr = split(' ', date)
    const name = compose(take(NUMBER_3), head)(dateArr)
    return compose(join(' '), set(lensIndex(0), name))(dateArr)
  }
)

export const graphSelector = createSelector(
  (state, groupBy, diagramData) => {
    const graphData = pathOr({}, ['graph', 'items'], state)
    const periodType = pathOr('MONTH', ['graph', 'periodType'], state)
    // названия линий, отсортированные по обороту и сгруппированные по признаку "больше шести"
    const lineNames = propOr([], 'lineNames', diagramData)
    const dataItems = compose(
      map(key => {
        const keyData = propOr([], key, graphData)
        const keyFormatted = equals(periodType, 'MONTH')
          ? shortMonthSelector(key)
          : moment(key, 'X').format('DD.MM')
        const prepareData = reduce((acc, item) => {
          const name = propOr('', groupBy, item)
          return {
            ...acc,
            [name]: propOr('', 'SUM', item)
          }
        }, {})(keyData)
        return {
          date: keyFormatted,
          ...prepareData
        }
      }),
      keys
    )(graphData)

    // если нет продаж, по умолчанию ноль
    const objWhithoutNulls = reduce(
      (acc, name) => ({
        ...acc,
        [name]: 0
      }),
      {}
    )(lineNames)

    return {
      data: map(item =>
        compose(
          reduce((acc, key) => {
            // если больше шести, то группировка остального оборота в одну линию
            return key !== 'date' && !includes(key, lineNames)
              ? {
                ...acc,
                [REST_LINE]: sum([
                  propOr(0, key, item),
                  propOr(0, REST_LINE, acc)
                ])
              }
              : { ...acc, [key]: propOr(0, key, item) }
          }, objWhithoutNulls),
          keys
        )(item)
      )(dataItems),
      lineNames
    }
  },
  data => data
)

const tableDateSelector = createSelector(
  (date, periodName) => {
    const isMonth = equals(periodName, 'MONTH')
    const prepareDate = moment(date)
      .locale('ru')
      .format(isMonth ? 'MMMM YYYY' : 'DD.MM.YYYY')
    return isMonth ? split(' ', prepareDate) : prepareDate
  },
  data => data
)

export const tableListSelector = createSelector(
  state => pathOr('MONTH', ['graph', 'periodType'], state),
  state => pathOr([], ['list', 'items'], state),
  (periodName, items) =>
    reduce(
      (acc, item) => [
        ...acc,
        {
          ...item,
          DATE: tableDateSelector(propOr(0, 'DATE', item), periodName)
        }
      ],
      []
    )(items)
)

export const tableItemsCountSelector = createSelector(
  state => state,
  state => pathOr(0, ['list', 'nav'], state)
)

export const diagramViewSelector = createSelector(
  state => state,
  state => propOr('linear', 'diagramView', state)
)

export const orderSelector = createSelector(
  state => state,
  state => propOr('DESC', 'order', state)
)

export const pageSelector = createSelector(
  state => state,
  state => propOr(FIRST_PAGE, 'p', state)
)

export const limitSelector = createSelector(
  state => state,
  state => propOr(NUMBER_48, 'limit', state)
)

export const isLoadingGraphSelector = createSelector(
  state => state,
  state => pathOr(false, ['graph', 'isLoading'], state)
)

export const isLoadingDiagramSelector = createSelector(
  state => state,
  state => pathOr(false, ['diagram', 'isLoading'], state)
)
