import React, { FC } from 'react'

import { modalActions } from 'decorators/modal'
import Button from 'UI/Button'

import ShipmentModal from './ShipmentModal'

interface IShipmentButton {
  popupSuccess: string
  shipmentId: string
  showModal: (str: string) => void
}

const ShipmentButton: FC<IShipmentButton> = ({
  popupSuccess,
  shipmentId,
  showModal,
  ...rest
}) => {
  const showModalCallback = () => () => {
    showModal(`shipmentForm-${shipmentId}`)
  }

  return (
    <>
      <Button onClick={showModalCallback()}
        {...rest} />
      <ShipmentModal
        popupSuccess={popupSuccess}
        shipmentId={shipmentId}
      />
    </>
  )
}

export default modalActions(ShipmentButton)
