import React, { useCallback, useState } from 'react'

import Icon from 'components/Icon'
import InputWithErrors from 'components/InputWithErrors'

import s from './InputPassword.scss'

const InputPassword = props => {
  const [ isShowPassword, setActivePassword] = useState(false)

  const handleShowPassword = useCallback(() => setActivePassword(!isShowPassword), [isShowPassword])

  return (
    <div className={s.wrapper}>
      <InputWithErrors
        {...props}
        type={isShowPassword ? 'text' : 'password'}
      />
      <div
        role='presentation'
        className={s.icon}
        onClick={handleShowPassword}
      >
        <Icon
          icon={isShowPassword ? 'eye' : 'eye-closed'}
          className={s.iconEye}
        />
      </div>
    </div>
  )
}

export default InputPassword
