import { pathOr, compose, keys, uniq, map, values } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

export {
  getIsLoaded,
  getIsLoading,
  getQuestion,
  getBreadcrumbs,
  getIsVoted,
  getIsVoting,
  allQuestions,
  popularQuestionsSelector,
  questionsSelector,
  tagsSelector,
  checkedTagsSelector,
  sectionsSelector,
  filesSelector
} from './selector'

export const fetch = createAction('faq/GET')
export const fetchSuccess = createAction('faq/GET_SUCCESS')
export const fetchFailure = createAction('faq/GET_FAILURE')
export const fetchPopular = createAction('faq/FETCH_POPULAR')
export const fetchPopularSuccess = createAction('faq/FETCH_POPULAR_SUCCESS')
export const put = createAction('faq/PUT')
export const putSuccess = createAction('faq/PUT_SUCCESS')
export const putFailure = createAction('faq/PUT_FAILURE')
export const setFilters = createAction('faq/SET_FILTERS')

const initialState = {
  sections: [],
  questions: [],
  tags: [],
  checkedTags: [],
  files: [],
  populars: [],
  isVoted: {}
}

const request =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v1/marketing/faq/items/')
        .then(fetchSuccess)
        .catch(fetchFailure)

const requestPopular =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v1/marketing/faq/popular/')
        .then(fetchPopularSuccess)
        .catch(fetchFailure)

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleFetchPopular = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestPopular({ clientApi }), payload)
  )

const handleFetchPopularSuccess = (state, payload) => {
  const populars = compose(
    keys,
    pathOr({}, ['data', 'response', 'ITEMS'])
  )(payload)
  return {
    ...state,
    populars
  }
}

const handleFetchSuccess = (state, payload) => {
  const tagsPayload = values(
    pathOr([], ['data', 'response', 'ENTITIES', 'TAGS'], payload)
  )
  const sections = values(
    pathOr([], ['data', 'response', 'ENTITIES', 'SECTIONS'], payload)
  )
  const questions = values(pathOr([], ['data', 'response', 'ITEMS'], payload))
  const files = values(
    pathOr([], ['data', 'response', 'ENTITIES', 'FILES'], payload)
  )
  const tags = compose(
    uniq,
    map(item => ({
      name: item.UF_XML_ID,
      title: item.UF_NAME
    }))
  )(tagsPayload)
  const checkedTags = map(item => item.name, tags)
  return loop(
    {
      ...state,
      tags,
      checkedTags,
      sections,
      questions,
      files,
      isLoading: false,
      isLoaded: true
    },
    Effects.call(fetchPopular, {})
  )
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errorMessage: getErrorMessage(payload)
})

const requestPut =
  ({ clientApi }) =>
    data =>
      clientApi
        .post(`/v1/marketing/faq/${data.id}/`, {
          params: {
            ...data,
            action: 'raiting_up'
          }
        })
        .then(response => putSuccess({ response, id: data.id }))
        .catch(response => putFailure({ response, id: data.id }))

const handlePut = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isVoting: true,
      isVoted: {
        ...state.isVoted,
        [payload.id]: false
      }
    },
    Effects.promise(requestPut({ clientApi }), payload)
  )

const handlePutSuccess = (state, { id }, { clientApi }) =>
  loop(
    {
      ...state,
      isVoting: false,
      isVoted: {
        ...state.isVoted,
        [id]: true
      }
    },
    Effects.promise(request({ clientApi }))
  )

const handlePutFailure = (state, { response, id }) => ({
  ...state,
  isVoting: false,
  isVoted: {
    ...state.isVoted,
    [id]: false
  },
  errorMessage: getErrorMessage(response)
})

const handleSetFilters = (state, checkedTags) => ({
  ...state,
  checkedTags
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(put, handlePut)
  on(putSuccess, handlePutSuccess)
  on(putFailure, handlePutFailure)
  on(fetchPopular, handleFetchPopular)
  on(fetchPopularSuccess, handleFetchPopularSuccess)
  on(setFilters, handleSetFilters)
}, initialState)

export default reducer
