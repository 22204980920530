/* eslint-disable complexity */
import React, { FC } from 'react'

import cx from 'classnames'

import Sticky from 'components/Sticky'
import Wrapper from 'components/Wrapper'
import CartModal from 'containers/Cart/CartModal'
import HeaderBasket from 'containers/HeaderBasket'
import HeaderLogo from 'containers/HeaderLogo'
import HeaderManagers from 'containers/HeaderManagers'
import HeaderPhone from 'containers/HeaderPhone'
import { Push as NotificationPush } from 'containers/Notification'
import SearchLine from 'containers/SearchLine'
import Button from 'UI/Button'

import s from './HeaderMobile.scss'

interface IHeaderMobileProps {
  isManyCarts: boolean;
  isTempUser: boolean;
  isMobile: boolean;
  isAuth: boolean;
  isLoyalty: boolean;
  isHeaderFixed: boolean;
  userId: string | number;
  onChangeCarts(): void;
  onGetThemeIcon(): void;
  onBreakpointPassed(): void
}

const HeaderMobileComponent: FC<IHeaderMobileProps> = ({
  isManyCarts,
  isTempUser,
  isMobile,
  isAuth,
  isLoyalty,
  isHeaderFixed,
  userId,
  onChangeCarts,
  onGetThemeIcon,
  onBreakpointPassed
}) => (
  <header className={s.header}>
    {isTempUser && (
      <div className={s.header__temp}>
        <Wrapper>
          До заключения договора интернет-магазин работает в демо-режиме,
          отправка заказа заблокирована.
        </Wrapper>
      </div>
    )}
    {isAuth && (
      <div className={s.header__notifications}>
        <Wrapper>
          <div className={s.header__notifications_wrapper}>
            <NotificationPush isLoyalty={isLoyalty} />
          </div>
        </Wrapper>
      </div>
    )}
    <div className={cx(s.header__panel, {
      [s.header__panel_visible]: isHeaderFixed,
      [s.header__panel_loyalty]: isLoyalty
    })}/>
    <Wrapper>
      <div className={s.header__container}>
        <div className={s.header__info}>
          <HeaderLogo />
          {!isLoyalty && (
            <HeaderPhone
              isHideTitle={isMobile}
              className={cx(s.header__phone, {
                [s.header__phone_unauth]: !isAuth
              })}
            />
          )}
          {isAuth && userId && (
            <div className={cx(s.header__basket, {
              [s.header__basket_position]: isHeaderFixed,
              [s.header__basket_loyalty]: isLoyalty && !isHeaderFixed,
              [s.header__basket_fixed_loyalty]: isHeaderFixed && isLoyalty
            })}>
              <Sticky
                className={s.header__basket_content}
                absoluteClassName={s.header__basket_absolute}
                fixedClassName={s.header__basket_fixed}
                onBreakpointPassed={onBreakpointPassed}
              >
                {!isLoyalty && isHeaderFixed && (
                  <div className={s.header__button_change}>
                    <Button
                      color='main'
                      size='micro'
                      iconSize='default'
                      icon={isManyCarts ? 'cart-change-mini' : 'cart-add-mini'}
                      onClick={onChangeCarts}
                    />
                  </div>
                )}
                <HeaderManagers />
                <HeaderBasket
                  onGetThemeIcon={onGetThemeIcon}
                  isFixed={false}
                  hideMiniBasket
                />
              </Sticky>
            </div>
          )}
        </div>
        <div className={s.header__search}>
          <SearchLine />
        </div>
      </div>
    </Wrapper>
    <CartModal />
  </header>
)

export default HeaderMobileComponent
