import { compose, pathOr, prop, reduce, values } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

import localforage from 'helpers/localforage'
import settings from 'helpers/localStorageSettings'
import { hideModal, showModal } from 'redux/modules/modal'

const initialState = {
  loaded: false,
  scrollPosition: 0,
  headersHeight: 0,
  bottomPanelVisible: false,
  isDisabledBodyScroll: false,
  mode: '',
  isOpenOutdatedBrowser: true,
  isInitSettings: false,
  notifications: {},
  notificationsSettings: { ...settings },
  tabActive: '',
  menuItems: [
    {
      name: 'discounts',
      title: 'Персональные условия',
      icon: 'percent',
      url: '/cabinet/discount',
      access: ['user', 'discounts']
    },
    {
      name: 'graphic',
      title: 'График платежей',
      icon: 'calendar',
      url: '/cabinet/schedule',
      access: ['user', 'schedule']
    },
    {
      name: 'scores',
      title: 'Оформленные счета',
      icon: 'score',
      url: '/cabinet/invoice',
      access: ['user']
    },
    {
      name: 'company',
      title: 'Мои компании',
      icon: 'company',
      url: '/cabinet/company',
      access: ['user']
    },
    {
      name: 'bookkeeping',
      title: 'Акт сверки взаиморасчетов',
      icon: 'bookkeeping',
      url: '/cabinet/bookkeeping',
      access: ['user', 'bookkeeping']
    },
    {
      name: 'workers',
      title: 'Сотрудники',
      icon: 'personal',
      url: '/cabinet/employee',
      access: ['user']
    },
    {
      name: 'feedback',
      title: 'Обратная связь',
      icon: 'personal',
      url: '/cabinet/feedback',
      access: ['user', 'formio']
    },
    {
      name: 'claim',
      title: 'Обращения',
      icon: 'score',
      url: '/cabinet/claim',
      access: ['user', 'pretension']
    },
    {
      name: 'notifications',
      title: 'Мои уведомления',
      icon: 'bell',
      url: '/cabinet/notification',
      access: ['user']
    },
    {
      name: 'cabinetAnalytics',
      title: 'Анализ покупок',
      icon: 'analytics',
      url: '/cabinet/analytics',
      access: ['user']
    },
    {
      name: 'api',
      title: 'Обмены',
      icon: 'exchange',
      url: '/cabinet/exchange',
      access: ['user', 'api']
    }
  ],
  cabinetMenuItems: [
    {
      name: 'favorite',
      title: 'Избранное',
      icon: 'heartFull',
      url: '/favorite',
      color: 'red',
      access: [],
      showCounter: true
    },
    {
      name: 'compare',
      title: 'Сравнение',
      icon: 'compare',
      url: '/compare',
      color: 'blue',
      access: [],
      showCounter: true
    },
    {
      name: 'storage',
      title: 'Маркетинговые материалы',
      icon: 'storage-menu',
      url: '/storage',
      color: 'blue',
      access: ['client']
    }
  ],
  tabBarList: [
    { icon: 'bars', title: 'Каталог', id: 'catalog', access: [ 'main' ] },
    { icon: 'search', title: 'Поиск', id: 'search', access: [ 'main' ] },
    { icon: 'suitcase', title: 'Кабинет', id: 'cabinet', access: [ 'main', 'auth' ] },
    { icon: 'icon-offers', title: 'Акции', id: 'offers', access: [ 'main', 'offers', 'auth' ] },
    { icon: 'personal', title: 'Вход', id: 'auth', access: [ 'main', 'unauth' ] },
    { icon: 'icon-lk', title: 'Регистрация', id: 'registration', access: [ 'main', 'unauth' ] },
    { icon: 'user-options', title: 'Прочее', id: 'options', access: [ 'main' ] },
    { icon: 'rc-logo', title: 'Каталог РЦ', id: 'catalogRc', access: [ 'loyalty' ] },
    { icon: 'catalog-icon', title: 'Каталог ДЖЭМ', id: 'catalogLoyalty', access: [ 'loyalty' ] },
    { icon: 'user-options', title: 'Прочее', id: 'optionsLoyalty', access: [ 'loyalty' ] }
  ]
}

export {
  getScrollPosition,
  menuSelector,
  cabinetMenuSelector,
  getHeadersHeight,
  getBottomPanelVisible,
  catalogTypeSelector,
  bookmarkSelector,
  isBookmarkSelector,
  isLoyaltySelector,
  isDisabledBodyScrollSelector,
  bottomPanelVisibleSelector,
  notificationsSelector,
  countClickSelector,
  pageLoadedSelector,
  pathnameSelector,
  pathnameSearchSelector,
  tabActiveSelector,
  tabBarSelector,
  modeSelector
} from './selector'

export const setMode = createAction('settings/SET_MODE')
export const setTabActive = createAction('settings/SET_TAB_ACTIVE')
export const setBookmark = createAction('settings/SET_BOOKMARK')
export const setScrollPosition = createAction('settings/SET_SCROLL_POSITION')
export const setHeadersHeight = createAction('settings/SET_HEADERS_HEIGHT')
export const setCatalogFixTop = createAction('settings/SET_CATALOG_FIX_TOP')
export const setBottomPanelVisible = createAction(
  'settings/SET_BOTTOM_PANEL_VISIBLE'
)
export const setDisabledBodyScroll = createAction(
  'settings/SET_DISABLED_BODY_SCROLL'
)
export const showOutdatedBrowser = createAction(
  'settings/SHOW_OUTDATED_BROWSER'
)
export const closeOutdatedBrowser = createAction(
  'settings/CLOSE_OUTDATED_BROWSER'
)
const initLocalStorage = createAction('settings/INIT_LOCALSTORAGE')
const initLocalStorageSuccess = createAction(
  'settings/INIT_LOCALSTORAGE_SUCCESS'
)
const initLocalStorageFailure = createAction(
  'settings/INIT_LOCALSTORAGE_FAILURE'
)
export const setLocalStorage = createAction('settings/SET_LOCALSTORAGE')
const setLocalStorageSuccess = createAction('settings/SET_LOCALSTORAGE_SUCCESS')
const setLocalStorageFailure = createAction('settings/SET_LOCALSTORAGE_FAILURE')
export const initNotification = createAction('settings/INIT_NOTIFICATION')
const initNotificationSuccess = createAction(
  'settings/INIT_NOTIFICATION_SUCCESS'
)
const initNotificationFailure = createAction(
  'settings/INIT_NOTIFICATION_FAILURE'
)
export const setNotification = createAction('settings/SET_NOTIFICATION')
const setNotificationSuccess = createAction('settings/SET_NOTIFICATION_SUCCESS')
const setNotificationFailure = createAction('settings/SET_NOTIFICATION_FAILURE')

const prepareNotifications = data =>
  reduce((acc, item) => ({ ...acc, [prop('CODE', item)]: item }), {}, data)

const reqNotification =
  ({ clientApi }) =>
    params => {
      return clientApi
        .get('/v3/sale/messages/status/', {
          params: { ...params, contractor_id: clientApi.getContractorId() }
        })
        .then(initNotificationSuccess)
        .catch(initNotificationFailure)
    }

const getItemLocalStorage = ({ key }) =>
  localforage
    .getItem(key)
    .then(value => initLocalStorageSuccess({ key, value: !!value }))
    .catch(initLocalStorageFailure)

const setItemLocalStorage = ({ key, value }) =>
  localforage
    .setItem(key, value)
    .then(() => setLocalStorageSuccess({ key, value }))
    .catch(setLocalStorageFailure)

const handleInitLocalStorage = state => {
  const effects = []
  values(state.notificationsSettings).forEach(key =>
    effects.push(Effects.promise(getItemLocalStorage, { key }))
  )
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}
const handleInitLocalStorageSuccess = (state, { key, value }) => ({
  ...state,
  localStorage: {
    ...state.localStorage,
    [key]: value
  }
})
const handleInitLocalStorageFailure = state => ({
  ...state
})

const handleSetLocalStorage = (state, { key, value }) =>
  loop(
    {
      ...state
    },
    Effects.promise(setItemLocalStorage, { key, value })
  )

const handleSetLocalStorageSuccess = (state, { key, value }) => ({
  ...state,
  localStorage: {
    ...state.localStorage,
    [key]: value
  }
})

const handleSetLocalStorageFailure = state => ({
  ...state
})

const handleInitNotification = (state, payload, { clientApi }) => {
  const effects = []
  effects.push(Effects.promise(reqNotification({ clientApi }), payload))
  effects.push(Effects.call(initLocalStorage, {}))

  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleChange = field => (state, payload) => {
  if (state[field] === payload) {
    return state
  }
  return {
    ...state,
    [field]: payload
  }
}
const handleShowOutdatedBrowser = state =>
  loop(
    {
      ...state,
      isOpenOutdatedBrowser: true
    },
    Effects.call(showModal, 'outDatedBrowser')
  )
const handleCloseOutdatedBrowser = state =>
  loop(
    {
      ...state,
      isOpenOutdatedBrowser: false
    },
    Effects.call(hideModal, 'outDatedBrowser')
  )

const handleInitNotificationSuccess = (state, payload) => {
  const data = pathOr(false, ['data', 'response'], payload)
  return {
    ...state,
    isInitSettings: true,
    notifications: prepareNotifications(data)
  }
}

const reqNotificationSet =
  ({ clientApi }) =>
    messages => {
      const params = {
        ...messages,
        contractor_id: clientApi.getContractorId()
      }
      return clientApi
        .post('/v3/sale/messages/status/', { params })
        .then(setNotificationSuccess)
        .catch(setNotificationFailure)
    }

const handleSetNotification = (state, { key, value }, { clientApi }) => {
  const newNotifications = {
    ...state.notifications,
    [key]: {
      ...state.notifications[key],
      ACTIVE: value
    }
  }
  const messages = compose(
    reduce(
      (acc, item) => ({ ...acc, [prop('CODE', item)]: prop('ACTIVE', item) }),
      {}
    ),
    values
  )(newNotifications)

  return loop(
    {
      ...state,
      notifications: newNotifications
    },
    Effects.promise(reqNotificationSet({ clientApi }), { messages })
  )
}

const handleSetNotificationSuccess = (state, payload) => {
  const data = pathOr({}, ['data', 'response', 'STATUSES'], payload)
  return {
    ...state,
    notifications: prepareNotifications(data)
  }
}

const handleSetTabActive = (state, payload) => ({
  ...state,
  tabActive: payload === state.tabActive ? '' : payload
})

const handleInitNotificationFailure = state => ({
  ...state
})

const handleSetNotificationFailure = state => ({
  ...state
})

const reducer = createReducer(on => {
  on(setMode, handleChange('mode'))
  on(setBookmark, handleChange('bookmark'))
  on(setScrollPosition, handleChange('scrollPosition'))
  on(setHeadersHeight, handleChange('headersHeight'))
  on(setCatalogFixTop, handleChange('catalogFixTop'))
  on(setBottomPanelVisible, handleChange('bottomPanelVisible'))
  on(setDisabledBodyScroll, handleChange('isDisabledBodyScroll'))
  on(showOutdatedBrowser, handleShowOutdatedBrowser)
  on(closeOutdatedBrowser, handleCloseOutdatedBrowser)
  on(initLocalStorage, handleInitLocalStorage)
  on(initLocalStorageSuccess, handleInitLocalStorageSuccess)
  on(initLocalStorageFailure, handleInitLocalStorageFailure)
  on(setLocalStorage, handleSetLocalStorage)
  on(setLocalStorageSuccess, handleSetLocalStorageSuccess)
  on(setLocalStorageFailure, handleSetLocalStorageFailure)
  on(initNotification, handleInitNotification)
  on(initNotificationSuccess, handleInitNotificationSuccess)
  on(initNotificationFailure, handleInitNotificationFailure)
  on(setTabActive, handleSetTabActive)
  on(setNotification, handleSetNotification)
  on(setNotificationSuccess, handleSetNotificationSuccess)
  on(setNotificationFailure, handleSetNotificationFailure)
}, initialState)

export default reducer
