import loadable from '@loadable/component'
import { mergeDeepRight, pathOr, prop, propOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

import parse from 'utils/querystring'

const Basket = loadable(() =>
  import(/* webpackChunkName: "Basket" */ 'pages/Basket/Basket')
)

export default asyncConnect([
  {
    key: 'pageBasket',
    promise: ({ helpers: { updateData, cookie }, match, location, store }) => {
      const state = store.getState()
      const catalog = pathOr('main', ['params', 'catalog'], match)
      const value = Number(pathOr(0, ['params', 'id'], match))
      const page = Number(propOr(1, 'p', parse(prop('search', location))))
      let basketParams = pathOr({}, ['productList', 'basketParams'], state)
      const cookieBasketParams = cookie.load('params_basket') || {}
      basketParams = mergeDeepRight(basketParams, cookieBasketParams)
      updateData.set({ view: 'basket', catalog, value, page, basketParams })
      return updateData.update()
    }
  }
])(Basket)
