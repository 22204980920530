/* eslint-disable complexity */
import { pathOr, prop, propOr, isEmpty, lensPath, set } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { showModal } from 'redux/modules/modal'
import { setNotice } from 'redux/modules/notice'

const initialState = {
  isLoaded: false,
  isLoading: false,
  error: '',
  employees: [],
  personal: {},
  form: {}
}

export {
  getIsLoaded,
  getIsLoading,
  getEmployees,
  getFormData,
  getStatusConfirm,
  getMessage
} from './selector'

export const setParams = createAction('employees/SET_PARAMS')

export const fetchEmployees = createAction('employees/FETCH_EMPLOYEES')
export const fetchEmployeesSuccess = createAction(
  'employees/FETCH_EMPLOYEES_SUCCESS'
)
export const fetchEmployeesFailure = createAction(
  'employees/FETCH_EMPLOYEES_FAILURE'
)

export const deleteEmployee = createAction('employees/DELETE_EMPLOYEE')
export const deleteEmployeeSuccess = createAction(
  'employees/DELETE_EMPLOYEE_SUCCESS'
)
export const deleteEmployeeFailure = createAction(
  'employees/DELETE_EMPLOYEE_FAILURE'
)
export const accessEmployee = createAction('employees/ACCESS_EMPLOYEE')
export const accessEmployeeSuccess = createAction(
  'employees/ACCESS_EMPLOYEE_SUCCESS'
)
export const accessEmployeeFailure = createAction(
  'employees/ACCESS_EMPLOYEE_FAILURE'
)
export const postEmployee = createAction('employees/POST_EMPLOYEE')
export const postEmployeeSuccess = createAction(
  'employees/POST_EMPLOYEE_SUCCESS'
)
export const postEmployeeFailure = createAction(
  'employees/POST_EMPLOYEE_FAILURE'
)
export const confirm = createAction('employees/CONFIRM')
export const confirmSuccess = createAction('employees/CONFIRM_SUCCESS')
export const confirmFailure = createAction('employees/CONFIRM_FAILURE')

const isLoading = {
  isLoading: true,
  isLoaded: false
}
const isLoaded = {
  isLoading: false,
  isLoaded: true
}
const isDefault = {
  isLoading: false,
  isLoaded: false
}

export const requestEmployees =
  ({ clientApi }) =>
    () => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v2/contractor/employee/list/', {
          params: { contractor_id: contractorId }
        })
        .then(fetchEmployeesSuccess)
        .catch(fetchEmployeesFailure)
    }

const handleFetchEmployees = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      ...isLoading
    },
    Effects.promise(requestEmployees({ clientApi }), payload)
  )

const handleFetchEmployeesSuccess = (state, payload) => {
  const employees = pathOr([], ['data', 'response', 'ITEMS'], payload)
  return {
    ...state,
    ...isLoaded,
    employees
  }
}

const handleFetchEmployeesFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  error: getErrorMessage(payload)
})

const handleSetParams = (state, { type, content, value }) => {
  const lens = lensPath([type, content])
  return set(lens, value, state)
}

export const requestDeleteEmployee =
  ({ clientApi }) =>
    (params = {}) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post(' /v2/contractor/employee/delete/', {
          params: {
            contractor_id: contractorId,
            ...params
          }
        })
        .then(deleteEmployeeSuccess)
        .catch(deleteEmployeeFailure)
    }

const handleDeleteEmployee = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      ...isLoading
    },
    Effects.promise(requestDeleteEmployee({ clientApi }), payload)
  )

const handleDeleteEmployeeSuccess = (state, payload) => {
  const effects = []
  const response = pathOr({}, ['data', 'response'], payload)
  const isSuccess = propOr(false, 'SUCCESS', response)
  const employees = propOr([], 'ITEMS', response)
  const messageAlert = pathOr('', ['data', 'response', 'MESSAGE'], payload)
  if (!isEmpty(messageAlert)) {
    effects.push(
      Effects.call(setNotice, {
        name: 'alert',
        page: 'employee',
        value: [{ TEXT: messageAlert }],
        header: isSuccess ? 'Удалить сотрудника' : 'Ошибка'
      })
    )
  }
  if (isSuccess) {
    effects.push(
      Effects.call(fetchEmployees, {})
    )
  }
  return loop(
    {
      ...state,
      ...isLoaded,
      employees: isEmpty(employees) ? prop('employees', state) : employees
    },
    Effects.batch(effects)
  )
}

const handleDeleteEmployeeFailure = state => ({
  ...state,
  ...isDefault
})

export const requestAccessEmployee = ({ clientApi }) => (params = {}) => {
  const contractorId = clientApi.getContractorId()
  return clientApi
    .post('/v2/contractor/employee/access/', {
      params: {
        contractor_id: contractorId,
        ...params
      }
    })
    .then(accessEmployeeSuccess)
    .catch(accessEmployeeFailure)
}

const handleAccessEmployee = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      ...isLoading
    },
    Effects.promise(requestAccessEmployee({ clientApi }), payload)
  )

const handleAccessEmployeeSuccess = (state, payload) => {
  const effects = []
  const response = pathOr({}, ['data', 'response'], payload)
  const employees = propOr([], 'ITEMS', response)

  return loop(
    {
      ...state,
      ...isLoaded,
      employees: isEmpty(employees) ? prop('employees', state) : employees
    },
    Effects.batch(effects)
  )
}

const handleAccessEmployeeFailure = (state, payload) => loop({
  ...state,
  ...isDefault,
  error: getErrorMessage(payload)
}, Effects.call(showModal, 'employeeAccessError'))

export const requestPostEmployee =
  ({ clientApi }) =>
    (params = {}) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post('/v2/contractor/employee/add/', {
          params: {
            ...params,
            contractor_id: contractorId
          }
        })
        .then(postEmployeeSuccess)
        .catch(postEmployeeFailure)
    }

const handlePostEmployee = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      ...isLoading
    },
    Effects.promise(requestPostEmployee({ clientApi }), payload)
  )

const handlePostEmployeeSuccess = (state, payload) => {
  const effects = []
  const response = pathOr({}, ['data', 'response'], payload)
  const employees = propOr([], 'ITEMS', response)
  const isSuccess = !!propOr(false, 'SUCCESS', response)
  const message = prop('MESSAGE', response)
  if (message) {
    effects.push(
      Effects.call(setParams, {
        type: 'formEmployee',
        content: 'message',
        value: message
      })
    )
  }
  if (isSuccess) {
    effects.push(
      Effects.call(setParams, {
        type: 'formEmployee',
        content: 'sendSuccess',
        value: true
      })
    )
  }
  return loop(
    {
      ...state,
      ...isLoaded,
      employees: isEmpty(employees) ? prop('employees', state) : employees
    },
    Effects.batch(effects)
  )
}

const handlePostEmployeeFailure = state => ({
  ...state,
  isDefault
})

export const requestConfirm =
  ({ clientApi }) =>
    params =>
      clientApi
        .post('/v2/contractor/employee/confirm/', { params })
        .then(confirmSuccess)
        .catch(confirmFailure)

const handleConfirm = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      ...isLoading
    },
    Effects.promise(requestConfirm({ clientApi }), payload)
  )

const handleConfirmSuccess = (state, payload) => ({
  ...state,
  ...isLoaded,
  message: pathOr('', ['data', 'response', 'MESSAGE'], payload),
  statusConfirm: pathOr(false, ['data', 'response', 'SUCCESS'], payload)
})

const handleConfirmFailure = state => ({
  ...state,
  isDefault
})

const reducer = createReducer(on => {
  on(setParams, handleSetParams)
  on(fetchEmployees, handleFetchEmployees)
  on(fetchEmployeesSuccess, handleFetchEmployeesSuccess)
  on(fetchEmployeesFailure, handleFetchEmployeesFailure)
  on(deleteEmployee, handleDeleteEmployee)
  on(deleteEmployeeSuccess, handleDeleteEmployeeSuccess)
  on(deleteEmployeeFailure, handleDeleteEmployeeFailure)
  on(accessEmployee, handleAccessEmployee)
  on(accessEmployeeSuccess, handleAccessEmployeeSuccess)
  on(accessEmployeeFailure, handleAccessEmployeeFailure)
  on(postEmployee, handlePostEmployee)
  on(postEmployeeSuccess, handlePostEmployeeSuccess)
  on(postEmployeeFailure, handlePostEmployeeFailure)
  on(confirm, handleConfirm)
  on(confirmSuccess, handleConfirmSuccess)
  on(confirmFailure, handleConfirmFailure)
}, initialState)

export default reducer
