// @ts-nocheck
import React, { FC } from 'react'

import Icon from 'components/Icon'
import Portal from 'components/Portal'
import Modal from 'containers/Modal'
import { modalActions } from 'decorators/modal'
import Button from 'UI/Button'
import Text from 'UI/Text'

import FormContainer from './FormContainer'
import s from './ReviewButton.scss'

interface IReviewButton {
  product: object
  showModal: (str: string) => void
}

const ReviewButton: FC<IReviewButton> = ({ product, showModal }) => (
  <>
    <Button
      stretched
      color='main'
      // eslint-disable-next-line react/jsx-no-bind
      onClick={() => showModal('leaveAFeedback')}
    >
      Оставить отзыв
    </Button>
    {/* @ts-ignore */}
    <Portal>
      <Modal id='leaveAFeedback'
        title='Отзыв на товар'>
        <FormContainer product={product} />
      </Modal>
      <Modal id='leaveAFeedbackSuccess'
        size='small'>
        <div className={s.block}>
          <Icon icon='status-luck'
            size='middle' />
          <Text>Отзыв успешно отправлен.</Text>
        </div>
      </Modal>
      <Modal id='leaveAFeedbackFailure'>
        <div className={s.block}>
          <Icon icon='status-error' />
          <Text>
            Приносим свои извинения!
            <br />
            Произошла ошибка. Повторите попытку позже.
          </Text>
        </div>
      </Modal>
    </Portal>
  </>
)

export default modalActions(ReviewButton)
