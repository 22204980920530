import React, { FC } from 'react'

import cx from 'classnames'

import styles from './Wrapper.scss'

interface IWrapperProps {
  children: React.ReactNode;
  width?: number | string;
  className?: string
}

const Wrapper: FC<IWrapperProps> = ({ children = [], width, className = '' }) => {
  return (
    <div
      style={{
        maxWidth: width
      }}
      className={cx(styles.wrapper, {
        [className]: className
      })}
    >
      {children}
    </div>
  )
}

export default Wrapper
