import React, { PureComponent } from 'react'

import { object, number } from 'prop-types'
import { propOr, isEmpty } from 'ramda'

import BannerWrapper from 'components/BannerWrapper'
import Picture from 'components/Picture'
import { setAnalyticsProps } from 'helpers/metaTags'

import styles from './BannerMenu.scss'

class BannerMenu extends PureComponent {
  static propTypes = {
    banner: object,
    sectionId: number
  }

  static defaultProps = {
    banner: {}
  }

  render() {
    const { banner, sectionId } = this.props
    if (isEmpty(banner)) {
      return null
    }

    return (
      <div
        className={styles.banner}
        itemScope
        itemType='http://schema.org/ImageObject'
      >
        <BannerWrapper banner={banner}>
          <Picture
            imgClassName={styles.bannerImg}
            src={propOr('', 'IMG', banner)}
            alt={propOr('', 'TITLE', banner)}
            backgroundSize='contain'
            {...setAnalyticsProps({
              type: 'banner',
              banner: 'menuBanner',
              pos: sectionId
            })}
          />
        </BannerWrapper>
      </div>
    )
  }
}

export default BannerMenu
