import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsSending = state => state.isSending
export const getIsReSending = state => state.isReSending
export const getIsSent = state => state.isSent
export const getOrderId = state => state.orderId
export const getError = state => state.error
export const isSend = ({ orders }) => orders && orders.isSend

export const isSendingSelector = createSelector(
  state => state,
  state => propOr(false, 'isSending', state)
)

export const isReSendingSelector = createSelector(
  state => state,
  state => propOr(false, 'isReSending', state)
)

export const orderInfoSelector = createSelector(
  state => prop('orders', state),
  (_, id) => id,
  (orders, id) => prop(id, orders)
)

export const orderToConfirmSelector = createSelector(
  state => prop('ordersToConfirm', state),
  (_, id) => id,
  (orders, id) => prop(id, orders)
)
