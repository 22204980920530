/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import hoistStatics from 'hoist-non-react-statics'
import { func, number, bool, string, oneOfType } from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'

import {
  fetch as fetchBookmarks,
  getIsLoaded as isBookmarkLoadedSelector,
  getIsLoading as isBookmarkLoadingSelector
} from 'redux/modules/bookmarks'
import {
  getAccess,
  getIsLoading as isPersonalLoading
} from 'redux/modules/personal'
import { setCartActive } from 'redux/modules/productList'
import {
  fetch as fetchSections,
  sectionsIsLoadedSelector,
  sectionsIsLoadingSelector
} from 'redux/modules/sections'
import { setMode, setBookmark } from 'redux/modules/settings'
import Preloader from 'UI/Preloader'

export default () => WrappedComponent => {
  @connect(
    ({ personal, sections, bookmarks, settings }, ownProps) => {
      const bookmark = R.pathOr(0, ['match', 'params', 'bookmark'], ownProps)
      return {
        isAccess: getAccess(personal, 'BOOKMARKS'),
        bookmark,
        isLoading: isPersonalLoading(personal),
        isBookmarkMode: R.prop('mode', settings) === 'bookmark',
        isSectionLoaded: sectionsIsLoadedSelector({
          catalogType: 'bookmark',
          sections,
          bookmark
        }),
        isSectionLoading: sectionsIsLoadingSelector({
          catalogType: 'bookmark',
          sections,
          bookmark
        }),
        isBookmarksLoaded: isBookmarkLoadedSelector(bookmarks),
        isBookmarksLoading: isBookmarkLoadingSelector(bookmarks)
      }
    },
    {
      setCartActive,
      setMode,
      setBookmark,
      fetchSections,
      fetchBookmarks
    }
  )
  class Bookmark extends PureComponent {
    static displayName = `Redirect(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      setCartActive: func,
      setMode: func,
      setBookmark: func,
      fetchSections: func,
      fetchBookmarks: func,
      isLoading: bool,
      isAccess: bool,
      isBookmarkMode: bool,
      isSectionLoaded: bool,
      isSectionLoading: bool,
      isBookmarkLoaded: bool,
      isBookmarkLoading: bool,
      bookmark: oneOfType([number, string])
    }

    static defaultProps = {
      setCartActive: () => {},
      setMode: () => {},
      setBookmark: () => {},
      fetchSections: () => {},
      fetchBookmarks: () => {},
      isLoading: false,
      isAccess: false,
      isBookmarkMode: false,
      isSectionLoaded: false,
      isSectionLoading: false,
      isBookmarkLoaded: false,
      isBookmarkLoading: false
    }

    componentDidMount() {
      if (this.props.isAccess && !this.props.isBookmarkMode) {
        this.onSetMode(this.props.bookmark)
      }
    }

    componentDidUpdate() {
      const {
        bookmark,
        isAccess,
        isBookmarkMode,
        isSectionLoaded,
        isSectionLoading,
        isBookmarkLoaded,
        isBookmarkLoading
      } = this.props
      if (isAccess && !(isSectionLoaded || isSectionLoading)) {
        this.onFetchSections(bookmark)
      }

      if (isAccess && !(isBookmarkLoaded || isBookmarkLoading)) {
        this.onFetchBookmarks(bookmark)
      }

      if (isAccess && !isBookmarkMode) {
        this.onSetMode(bookmark)
      }
    }

    componentWillUnmount() {
      this.props.setMode()
      this.props.setBookmark()
      this.props.setCartActive(0)
    }

    onFetchSections = R.once(bookmark =>
      this.props.fetchSections({ catalogType: 'bookmark', bookmark })
    )

    onFetchBookmarks = R.once(() => this.props.fetchBookmarks())

    onSetMode = R.once(bookmark => {
      this.props.setMode('bookmark')
      this.props.setBookmark(bookmark)
      this.props.setCartActive(Number(bookmark))
    })

    render() {
      const { isAccess, isLoading } = this.props

      if (isAccess) {
        return <WrappedComponent {...this.props} />
      }
      if (isLoading) {
        return <Preloader active={isLoading} />
      }
      return 'Доступ запрещен'
    }
  }

  return hoistStatics(Bookmark, WrappedComponent)
}
