/* eslint-disable complexity */
import React, { Component } from 'react'

import cx from 'classnames'
import { object, string, bool } from 'prop-types'

import InputError from 'components/InputError'
import { FieldInput } from 'UI/Input'

import styles from './InputWithErrors.scss'

class InputWithErrors extends Component {
  static propTypes = {
    meta: object,
    className: string,
    hideError: bool,
    errorSide: string,
    disabled: bool,
    errorClassName: string
  }

  static defaultProps = {
    hideError: false,
    disabled: false
  }

  render() {
    const {
      meta,
      className,
      hideError,
      disabled,
      errorSide,
      errorClassName,
      ...rest
    } = this.props
    const isError = meta.invalid && meta.touched && !disabled
    const isSuccess = meta.dirty && meta.valid && meta.touched

    return (
      <div className={cx(styles.inputWrapper, className)}>
        <FieldInput
          type='text'
          {...rest}
          error={isError}
          success={isSuccess}
          disabled={disabled}
        />
        {isError && !hideError && (
          <InputError
            side={errorSide}
            message={meta.error}
            className={errorClassName}
          />
        )}
      </div>
    )
  }
}

export default InputWithErrors
