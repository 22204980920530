import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const StorageSection = loadable(() =>
  import(/* webpackChunkName: "StorageSection" */ 'pages/StorageSection/StorageSection')
)

export default asyncConnect([
  {
    key: 'pageStorageSection',
    promise: ({ match, helpers: { updateData } }) => {
      const id = pathOr(0, ['params', 'id'], match)
      updateData.set({ id, view: 'storageSections' })
      return updateData.update()
    }
  }
])(StorageSection)
