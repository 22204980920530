import loadable from '@loadable/component'
import { path } from 'ramda'
import { asyncConnect } from 'redux-connect'

const LoyaltyOrderThanks = loadable(() =>
  import(
    /* webpackChunkName: "LoyaltyOrderThanks" */ 'pages/LoyaltyOrderThanks/LoyaltyOrderThanks'
  )
)

export default asyncConnect([
  {
    key: 'pageOrderThanks',
    promise: ({ helpers: { updateData }, match }) => {
      updateData.set({ view: 'orderThanks', id: path(['params', 'id'], match) })
      return updateData.update()
    }
  }
])(LoyaltyOrderThanks)
