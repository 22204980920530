import { compose, pathOr, pick, path } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

export { notificationsSelector, filtersSelector } from './selector'

const initialState = {
  isLoaded: false,
  isLoading: false,
  notifications: [],
  filters: {},
  query: {}
}

export const fetchNotifications = createAction(
  'notifications/FETCH_NOTIFICATIONS'
)
export const fetchNotificationsSuccess = createAction(
  'notifications/FETCH_NOTIFICATIONS_SUCCESS'
)
export const fetchNotificationsFailure = createAction(
  'notifications/FETCH_NOTIFICATIONS_FAILURE'
)

const requestNotifications =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/event/history/catalog/', { params })
        .then(fetchNotificationsSuccess)
        .catch(fetchNotificationsFailure)

const handleFetchNotifications = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoaded: true,
      isLoading: true
    },
    Effects.promise(requestNotifications({ clientApi }), payload)
  )

const handleFetchNotificationsSuccess = (state, payload) => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  notifications: pathOr([], ['data', 'response', 'ITEMS'], payload),
  filters: compose(
    pick(['AGGREGATIONS', 'ENTITIES', 'NAV']),
    path(['data', 'response'])
  )(payload)
})

const handleFetchNotificationsFailure = (state, payload) => ({
  ...state,
  isLoaded: false,
  isLoading: false,
  notifications: [],
  error: payload
})

const reducer = createReducer(on => {
  on(fetchNotifications, handleFetchNotifications)
  on(fetchNotificationsSuccess, handleFetchNotificationsSuccess)
  on(fetchNotificationsFailure, handleFetchNotificationsFailure)
}, initialState)

export default reducer
