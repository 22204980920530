import React, { PureComponent } from 'react'

import cx from 'classnames'
import { array, func, string } from 'prop-types'
import { prop } from 'ramda'

import Modal from 'containers/Modal'

import styles from './Notice.scss'

export default class NoticeAlert extends PureComponent {
  static propTypes = {
    items: array,
    setNoticeStatus: func,
    type: string,
    header: string
  }

  static defaultProps = {
    items: [],
    header: 'Ошибка'
  }

  handleCloseAlert = () => {
    const { type, setNoticeStatus } = this.props
    setNoticeStatus({ name: 'alert', page: type, status: false })
  }

  render() {
    const { items, header, type } = this.props
    return (
      <Modal
        id={`alert_${type}`}
        title={header}
        onClose={this.handleCloseAlert}
      >
        {items.map((item, key) => (
          <div
            key={key} // eslint-disable-line react/no-array-index-key
            className={cx({
              [styles.alertItem]: true,
              [styles[`alertItem${prop('TYPE', item)}`]]: true
            })}
            dangerouslySetInnerHTML={{ __html: prop('TEXT', item) }} // eslint-disable-line react/no-danger
          />
        ))}
      </Modal>
    )
  }
}
