import React, { PureComponent } from 'react'

import cx from 'classnames'
import { number, string, oneOfType, func, oneOf, bool } from 'prop-types'
import { clamp, compose, replace, pathOr, dec, inc } from 'ramda'

import styles from './OrderButton.scss'

const MAX_NUMBER = 9999999

export default class OrderButton extends PureComponent {
  static propTypes = {
    amount: oneOfType([string, number]),
    placeholder: oneOfType([string, number]),
    width: oneOfType([number, string]),
    onChange: func,
    onKeyUp: func,
    onFocus: func,
    onBlur: func,
    onClick: func,
    max: number,
    min: number,
    mode: oneOf(['Base', 'Basket', 'Code', 'LoyaltyTarget']),
    disabled: bool,
    emptyAmountChar: string
  }

  static defaultProps = {
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onClick: () => {},
    amount: 0,
    min: 1,
    max: MAX_NUMBER,
    width: 220,
    mode: 'Base',
    emptyAmountChar: ''
  }

  handleChange = value => {
    let amount = value
    if (amount === '') {
      amount = 0
    }
    this.props.onChange(parseFloat(amount), this.isCorrectValue(amount))
  }

  handleInput = event => {
    const amount = compose(
      parseInt,
      replace(/\D/g, ''),
      pathOr('0', ['target', 'value'])
    )(event)

    this.handleChange(amount || 0)
  }

  handleFocus = event => {
    event.target.select()
    this.props.onFocus(event)
  }

  handleClick = event => this.props.onClick(event)

  handleBlur = event => {
    event.persist()
    const { min } = this.props
    let value = this.getFixedValue(this.props.amount)
    if (Number.isNaN(Number(value))) {
      value = min
    }
    const isCorrect = this.isCorrectValue(this.props.amount)
    this.handleChange(value)
    this.props.onBlur(event, value, isCorrect)
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.props.onBlur(event, this.props.amount, true)
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
      const nextValue =
        event.key === 'ArrowUp' ? this.props.amount + 1 : this.props.amount - 1
      this.handleChange(nextValue)
    }
  }

  handleKeyUp = event => {
    if (typeof this.props.onKeyUp === 'function') {
      const value = this.getFixedValue(this.props.amount)
      this.props.onKeyUp(event, value, this.isCorrectValue(value))
    }
  }

  handleIncByStep = () => {
    const { amount } = this.props
    this.handleChange(inc(amount))
  }

  handleDecByStep = () => {
    const { amount } = this.props
    this.handleChange(dec(amount))
  }

  getFixedValue = amount => {
    const { min, max } = this.props

    return clamp(
      Math.min(...[min].concat(max)),
      Math.max(...[min].concat(max)),
      amount
    )
  }

  isCorrectValue = amount => {
    const { min, max } = this.props

    if (amount > max && amount > min) {
      return true
    }

    if (max === amount && max > 0 && max < min) {
      return true
    }
    if (
      (max && max <= min && amount !== max) ||
      (min && amount < min) ||
      (max && amount > max)
    ) {
      return false
    }
    if (max && max === amount) {
      return true
    }

    return true
  }

  render() {
    const {
      amount,
      width,
      mode,
      disabled,
      placeholder,
      emptyAmountChar,
      min,
      max
    } = this.props
    const value = parseInt(amount, 10) && amount >= 0 ? amount : emptyAmountChar
    const disabledLess = amount <= min || disabled
    const disabledMore = max <= 0 || (max > 0 && amount >= max) || disabled

    return (
      <div className={styles.orderButtonWrapper}>
        <div
          className={cx(styles.orderButton, {
            [styles.orderButtonLoyalty]: mode === 'LoyaltyTarget'
          })}
          style={{ width }}
        >
          {!!placeholder && (
            <div className={styles.placeholder}>{placeholder}</div>
          )}
          <input
            className={cx(styles[`orderInput${mode}`], {
              [styles.orderInputEmpty]: !value && value !== 0,
              [styles.orderInputLoyalty]: true
            })}
            onChange={this.handleInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onClick={this.handleClick}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            maxLength={7}
            value={value}
            disabled={disabled}
          />
          <button
            type='button'
            className={cx(styles.deltaBtn, styles[`buttonLeft${mode}`], {
              [styles.buttonLeftDisabled]: disabledLess,
              [styles[`buttonLeft${mode}Loyalty`]]: true
            })}
            disabled={disabledLess}
            onClick={this.handleDecByStep}
          >
            &ndash;
            <span
              className={cx(
                styles.buttonLeftHover,
                styles.buttonLeftHoverLoyalty
              )}
            />
          </button>
          <button
            type='button'
            className={cx(styles.deltaBtn, styles[`buttonRight${mode}`], {
              [styles.buttonRightDisabled]: disabledMore,
              [styles[`buttonRight${mode}Loyalty`]]: true
            })}
            disabled={disabledMore}
            onClick={this.handleIncByStep}
          >
            +
            <span
              className={cx(
                styles.buttonRightHover,
                styles.buttonRightHoverLoyalty
              )}
            />
          </button>
        </div>
      </div>
    )
  }
}
