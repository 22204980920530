/* eslint-disable complexity */
/* eslint-disable no-lonely-if, camelcase, import/extensions */
import { push } from 'connected-react-router'
import {
  path,
  prop,
  propOr,
  pathOr,
  isEmpty,
  pick,
  omit,
  compose,
  findLastIndex,
  propEq,
  set,
  lensPath,
  not,
  mergeDeepRight
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { setIsRefetch } from 'redux/modules/basket'
import { fetchStatus } from 'redux/modules/loyalty'
import { hideModal, showModal } from 'redux/modules/modal'
import {
  setNoticeAction,
  setNotice,
  setSendingConfirm
} from 'redux/modules/notice'
import { confirmOrder as confirmOrderSum } from 'redux/modules/order'
import { fetchBaskets } from 'redux/modules/personal'
import {
  fetch as fetchBasket,
  switchItemInGroupSuccess
} from 'redux/modules/productList'

const PREPARE_ACTION_PARAMS = [
  'delivery_id',
  'address_id',
  'delivery_date_possible',
  'comment',
  'id',
  'type',
  'items',
  'catalog',
  'bookmark',
  'email',
  'tk_id',
  'tk_title',
  'tk_method',
  'tk_contact_id',
  'tk_address_id',
  'tk_address_title',
  'tk_contact_name',
  'tk_contact_phone',
  'tk_delivery_id',
  'tk_consignee',
  'tk_transport_id',
  'tk_services'
]
const ERROR_TEXT = 'Ошибка'

const initialState = {
  orderId: undefined,
  isSent: false,
  isSending: false,
  isWarnings: false,
  error: '',
  orders: {},
  modalError: {}
}

export {
  getIsSending,
  getIsSent,
  getOrderId,
  getError,
  isSend,
  isReSendingSelector,
  isSendingSelector,
  orderInfoSelector,
  orderToConfirmSelector
} from './selector'

export const setStatus = createAction('orders/SET_STATUS')
export const fetchOrderThanks = createAction('orders/FETCH_ORDER_THANKS')
export const fetchOrderConfirm = createAction('orders/FETCH_ORDER_CONFIRM')
export const fetchOrderConfirmSuccess = createAction(
  'orders/FETCH_ORDER_CONFIRM_SUCCESS'
)
export const fetchOrderConfirmFailure = createAction(
  'orders/FETCH_ORDER_CONFIRM_FAILURE'
)
export const put = createAction('orders/PUT')
export const putSuccess = createAction('orders/PUT_SUCCESS')
export const putFailure = createAction('orders/PUT_FAILURE')
export const setIsWarnings = createAction('orders/SET_WARNINGS')
export const repeatOrder = createAction('orders/REPEAT_ORDER')
export const repeatSuccess = createAction('orders/REPEAT_ORDER_SUCCESS')
export const repeatFailure = createAction('orders/REPEAT_ORDER_FAILURE')
export const setValue = createAction('orders/SET_VALUE')
export const fetchOrderDetail = createAction('orders/FETCH_ORDER_DETAIL')
export const fetchOrderDetailSuccess = createAction(
  'orders/FETCH_ORDER_DETAIL_SUCCESS'
)
export const fetchOrderDetailFailure = createAction(
  'orders/FETCH_ORDER_DETAIL_FAILURE'
)
export const fetchDoc = createAction('orders/FETCH_DOC')
export const cancelOrder = createAction('orders/CANCEL_ORDER')
export const cancelOrderSuccess = createAction('orders/CANCEL_ORDER_SUCCESS')
export const cancelOrderFailure = createAction('orders/CANCEL_ORDER_FAILURE')
export const restoreOrder = createAction('orders/RESTORE_ORDER')
export const restoreOrderSuccess = createAction('orders/RESTORE_ORDER_SUCCESS')
export const restoreOrderFailure = createAction('orders/RESTORE_ORDER_FAILURE')
export const confirmOrder = createAction('orders/CONFIRM_ORDER')
export const confirmOrderSuccess = createAction('orders/CONFIRM_ORDER_SUCCESS')
export const confirmOrderFailure = createAction('orders/CONFIRM_ORDER_FAILURE')
export const downloadOrder = createAction('orders/DOWNLOAD_ORDER')
export const fetchDownloadOrder = createAction('orders/FETCH_DOWNLOAD_ORDER')
export const downloadOrderSuccess = createAction(
  'orders/DOWNLOAD_ORDER_SUCCESS'
)
export const downloadOrderFailure = createAction(
  'orders/DOWNLOAD_ORDER_FAILURE'
)

export const request =
  ({ clientApi }) =>
    ({
      delivery_id,
      delivery_date_possible,
      address_id,
      comment,
      id,
      type = 'basket',
      email = '',
      items,
      catalog,
      bookmark,
      operations,
      ...others
    }) => {
      const contractorId = clientApi.getContractorId()
      const params = {
        delivery_id,
        delivery_date_possible,
        address_id,
        comment,
        email,
        contractor_id: contractorId,
        operations,
        value: bookmark,
        ...others
      }
      return clientApi
        .post(`/v3/sale/basket/${catalog}/checkout/`, { params })
        .then(data =>
          putSuccess({
            ...data,
            delivery_id,
            delivery_date_possible,
            address_id,
            comment,
            id,
            type,
            items,
            catalog,
            email,
            bookmark,
            operations,
            ...others
          })
        )
        .catch(putFailure)
    }

const handleSetStatus = (
  state,
  { isSending = state.isSending, isSent = state.isSent } = {}
) => ({
  ...state,
  isSending,
  isSent
})

const handleSetWarnings = state => ({
  ...state,
  isWarnings: true
})

const handlePut = (state, payload, { clientApi }) => {
  const type = path(['type'], payload)
  const items = path(['items'], payload)
  return loop(
    {
      ...state,
      isSending: true,
      isSent: false,
      isWarnings: false
    },
    Effects.promise(request({ clientApi }), { ...payload, type, items })
  )
}

const handlePutSuccess = (state, payload = {}) => {
  const type = propOr('basket', 'type', payload)
  const catalog = propOr('main', ['catalog'], payload)
  let id = catalog
  const bookmark = prop('bookmark', payload)
  const email = prop('email', payload)
  const response = pathOr({}, ['data', 'response'], payload)
  const isSuccess = !!pathOr(false, ['SUCCESS'], response)
  const orderId = pathOr(0, ['ORDER', 'ID'], response)
  const isLoyalty = catalog === 'loyalty'
  const messages = propOr([], 'MESSAGES', response)
  const messageAlert = propOr({}, 'MESSAGE', response)
  const orders = pathOr([], ['ORDERS'], response)
  const params = { value: bookmark }
  if (catalog === 'bookmark') {
    id = `bookmark_${bookmark}`
  }
  if (isLoyalty) {
    params.mode = 'loyalty'
  }

  const effects = []
  effects.push(
    Effects.call(setSendingConfirm, { name: 'confirm', isSending: false })
  )
  effects.push(Effects.call(hideModal))
  // уведомление с подтверждением\отклонением действия
  if (isSuccess) {
    if (isLoyalty) {
      effects.push(Effects.call(push, `/loyalty/orders/thanks/${orderId}`))
    } else {
      effects.push(Effects.call(push, `/orders/checkout/${orderId}`))
    }
    effects.push(
      Effects.call(fetchBasket, {
        id,
        type,
        params,
        catalog
      })
    )
    effects.push(Effects.call(confirmOrderSum, { catalog, bookmark, sum: -1 }))
    effects.push(
      Effects.call(setNotice, { name: 'body', page: 'order', value: messages })
    )
    effects.push(Effects.call(fetchStatus, {}))
  } else {
    if (!isEmpty(messageAlert)) {
      const prepareActionParams = pick(PREPARE_ACTION_PARAMS, payload)
      effects.push(Effects.call(setNoticeAction, prepareActionParams))
      effects.push(
        Effects.call(setNotice, { name: 'confirm', value: [messageAlert] })
      )
    }
    effects.push(
      Effects.call(setNotice, { name: 'body', page: bookmark, value: messages })
    )
    effects.push(
      Effects.call(switchItemInGroupSuccess, {
        data: { data: { response: { ...omit(['MESSAGE'], response) } } },
        id: [catalog, bookmark],
        type,
        catalog,
        email,
        action: 'checkout'
      })
    )
  }

  return loop(
    {
      ...state,
      orders,
      isSending: false,
      isSent: true
    },
    Effects.batch(effects)
  )
}

const handlePutFailure = (state, payload) =>
  loop(
    {
      ...state,
      isSending: false,
      isSent: false,
      error: getErrorMessage(payload)
    },
    Effects.call(showModal, 'errorOrder')
  )

export const requestRepeat =
  ({ clientApi }) =>
    ({ orderId }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post('/v3/sale/basket/main/repeat/', {
          params: {
            check: 'N',
            order_id: orderId,
            contractor_id: contractorId
          }
        })
        .then(repeatSuccess)
        .catch(repeatFailure)
    }

const handleRepeat = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isReSending: true,
      isSent: false
    },
    Effects.batch([Effects.promise(requestRepeat({ clientApi }), payload)])
  )

const handleRepeatSuccess = (state, payload) =>
  loop(
    {
      ...state,
      orderId: path(['data', 'response', 'ORDERS'], payload),
      isReSending: false,
      isSent: true
    },
    Effects.batch([
      Effects.call(push, '/basket/main/0'),
      Effects.call(fetchBaskets, {})
    ])
  )

const handleRepeatFailure = (state, payload) =>
  loop(
    {
      ...state,
      isReSending: false,
      isSent: false,
      error: getErrorMessage(payload)
    },
    Effects.call(showModal, 'errorOrder')
  )

const handleFetchOrderThanks = (state, id) => ({
  ...state,
  orders: {
    ...state.orders,
    [id]: {
      id,
      status: true
    }
  }
})

const requestGetOrderConfirm =
  ({ clientApi }) =>
    ({ type = 'main', id }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/sale/order/${type}/${id}/`, {
          params: {
            contractor_id: contractorId
          }
        })
        .then(data => fetchOrderConfirmSuccess({ id, ...data }))
        .catch(() => fetchOrderConfirmFailure({ id }))
    }

const handleFetchOrderConfirm = (state, params, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestGetOrderConfirm({ clientApi }), params)
  )

const handleFetchOrderConfirmSuccess = (state, payload) => ({
  ...state,
  ordersToConfirm: {
    ...state.ordersToConfirm,
    [prop('id', payload)]: pathOr({}, ['data', 'response'], payload)
  }
})

const handleFetchOrderConfirmFailure = state => ({
  ...state
})

const requestOrderDetail =
  ({ clientApi }) =>
    ({ type, id }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/sale/bills/${type}/${id}/`, {
          params: {
            contractor_id: contractorId
          }
        })
        .then(data => fetchOrderDetailSuccess({ id, ...data }))
        .catch(() => fetchOrderDetailFailure({ id }))
    }

const handleFetchOrderDetail = (state, id, { clientApi }) =>
  loop(
    {
      ...state,
      orderDetailLoading: true,
      orderDetailLoaded: false
    },
    Effects.promise(requestOrderDetail({ clientApi }), id)
  )

const handleFetchOrderDetailSuccess = (state, payload) => ({
  ...state,
  orderDetailLoading: false,
  orderDetailLoaded: true,
  orderDetail: {
    ...state.orderDetail,
    [prop('id', payload)]: pathOr({}, ['data', 'response', 'ITEMS'], payload)
  }
})

const handleFetchOrderDetailFailure = state => ({
  ...state,
  orderDetailLoading: false,
  orderDetailLoaded: true
})

const handleSetValue = (state, { key, value }) => ({
  ...state,
  [key]: value
})

const requestCancelOrder =
  ({ clientApi }) =>
    ({ billId, id }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi.post(`/v3/invoice/bill/${billId}/cancel/`, {
        params: {
          contractor_id: contractorId,
          deactivate: true
        }
      })
        .then(data => cancelOrderSuccess({ id, billId, ...data }))
        .catch(error => cancelOrderFailure({ id, billId, error: error.response }))
    }

const handleCancelOrder = (state, params, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestCancelOrder({ clientApi }), params)
  )

const handleCancelOrderSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(false, ['data', 'response', 'SUCCESS'], payload)
  if (!isSuccess) {
    return {
      ...state,
      modalError: {
        title: ERROR_TEXT,
        text: pathOr(
          ERROR_TEXT,
          ['data', 'response', 'MESSAGE', 'TEXT'],
          payload
        ),
        error: path(['data', 'response', 'MESSAGE'], payload)
      }
    }
  }
  const id = prop('id', payload)
  const billId = prop('billId', payload)
  const bill = path(['data', 'response', 'BILL'], payload)
  const entities = mergeDeepRight(
    pathOr({}, ['ordersToConfirm', id, 'ENTITIES'], state),
    path(['data', 'response', 'ENTITIES'], payload)
  )
  const index = compose(
    findLastIndex(propEq('ID', billId)),
    path(['ordersToConfirm', id, 'ITEMS'])
  )(state)
  effects.push(Effects.call(hideModal))
  effects.push(Effects.call(setIsRefetch, true))
  return loop(
    {
      ...compose(
        set(lensPath(['ordersToConfirm', id, 'ENTITIES']), entities),
        set(lensPath(['ordersToConfirm', id, 'ITEMS', index]), bill)
      )(state)
    },
    Effects.batch(effects)
  )
}

const handleCancelOrderFailure = (state, payload) => ({
  ...state,
  modalError: {
    title: ERROR_TEXT,
    text: pathOr(
      ERROR_TEXT,
      ['error', 'data', 'response', 'MESSAGE', 'TEXT'],
      payload
    ),
    error: prop('error', payload)
  }
})

const requestRestoreOrder =
  ({ clientApi }) =>
    ({ type, billId, id }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post(`/v3/sale/bills/${type}/${billId}/action/restore/`, {
          params: {
            contractor_id: contractorId
          }
        })
        .then(data => restoreOrderSuccess({ id, billId, ...data }))
        .catch(error =>
          restoreOrderFailure({ id, billId, error: error.response })
        )
    }

const handleRestoreOrder = (state, params, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestRestoreOrder({ clientApi }), params)
  )

const handleRestoreOrderSuccess = (state, payload) => {
  const effects = []
  effects.push(Effects.call(cancelOrderSuccess, payload))
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleRestoreOrderFailure = (state, payload) => ({
  ...state,
  modalError: {
    title: ERROR_TEXT,
    text: pathOr(
      ERROR_TEXT,
      ['error', 'data', 'response', 'MESSAGE', 'TEXT'],
      payload
    ),
    error: prop('error', payload)
  }
})

const requestConfirmOrder =
  ({ clientApi }) =>
    ({ type, billIds, billIdsDelete, id, ...etc }) => {
      const contractorId = clientApi.getContractorId()
      const requests = billIds.map(item =>
        clientApi
          .post(`/v3/sale/bills/${type}/${item}/action/confirm/`, {
            params: {
              contractor_id: contractorId,
              ...etc
            }
          })
          .then(() => ({ id, billId: item }))
      )

      if (not(isEmpty(billIdsDelete))) {
        const requestDelete = billIdsDelete.map(item =>
          clientApi
            .post(`/v3/sale/bills/${type}/${item}/action/delete/`, {
              params: {
                contractor_id: contractorId,
                deactivate: true,
                ...etc
              }
            })
            .then(() => ({ id, billId: item }))
            .catch(error => ({ id, billId: item, error: error.response }))
        )
        // todo даже если придет ошибка - с этим ничего делать не надо
        Promise.all(requestDelete)
      }

      return Promise.all(requests).then(
        data => confirmOrderSuccess({ id, ...data }),
        error => confirmOrderFailure({ id, error: error.response })
      )
    }

const handleConfirmOrder = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestConfirmOrder({ clientApi }), payload)
  )

const handleConfirmOrderSuccess = (state, payload) =>
  loop(
    {
      ...state
    },
    Effects.call(push, `/orders/thanks/${prop('id', payload)}`)
  )

const handleConfirmOrderFailure = (state, payload) =>
  loop(
    {
      ...state,
      modalError: {
        title: ERROR_TEXT,
        text: pathOr(
          '',
          ['error', 'data', 'response', 'MESSAGE', 'TEXT'],
          payload
        ),
        error: prop('error', payload)
      }
    },
    Effects.call(showModal, 'confirmError')
  )

const requestDownload = ({ clientApi }) => ({ billId }) => {
  const contractorId = clientApi.getContractorId()
  return clientApi.get(`/v3/invoice/bill/${billId}/doc/bill/xls/`, {
    params: {
      contractor_id: contractorId
    }
  })
    .then(data => downloadOrderSuccess({ billId, ...data }))
    .catch(error => downloadOrderFailure({ billId, error: error.response }))
}

const handleFetchDownloadOrder = (state, payload, { clientApi }) => {
  return loop({
    ...state
  },
  Effects.promise(requestDownload({ clientApi }), payload))
}

const handleDownloadOrder = (state, payload) =>
  loop(
    {
      ...state,
      doc: {
        isLoaded: false,
        isLoading: true
      }
    },
    Effects.batch([
      Effects.call(showModal, 'downloadDoc'),
      Effects.call(fetchDownloadOrder, payload)
    ])
  )

const handleDownloadOrderSuccess = (state, payload) => ({
  ...state,
  doc: {
    isLoaded: true,
    isLoading: false,
    docPath: pathOr('', ['data', 'response', 'RESULT', 'PATH'], payload),
    docError: ''
  }
})

const handleDownloadOrderFailure = (state, payload) => ({
  ...state,
  doc: {
    isLoaded: true,
    isLoading: false,
    docPath: '',
    docError:
      pathOr('', ['error', 'data', 'response', 'MESSAGE', 'TEXT'], payload) ||
      pathOr('', ['error', 'data', 'meta', 'errorDetail'], payload)
  }
})

const reducer = createReducer(on => {
  on(setStatus, handleSetStatus)
  on(fetchOrderThanks, handleFetchOrderThanks)
  on(put, handlePut)
  on(putSuccess, handlePutSuccess)
  on(putFailure, handlePutFailure)
  on(setIsWarnings, handleSetWarnings)
  on(repeatOrder, handleRepeat)
  on(repeatSuccess, handleRepeatSuccess)
  on(repeatFailure, handleRepeatFailure)
  on(fetchOrderConfirm, handleFetchOrderConfirm)
  on(fetchOrderConfirmSuccess, handleFetchOrderConfirmSuccess)
  on(fetchOrderConfirmFailure, handleFetchOrderConfirmFailure)
  on(setValue, handleSetValue)
  on(fetchOrderDetail, handleFetchOrderDetail)
  on(fetchOrderDetailSuccess, handleFetchOrderDetailSuccess)
  on(fetchOrderDetailFailure, handleFetchOrderDetailFailure)
  on(cancelOrder, handleCancelOrder)
  on(cancelOrderSuccess, handleCancelOrderSuccess)
  on(cancelOrderFailure, handleCancelOrderFailure)
  on(restoreOrder, handleRestoreOrder)
  on(restoreOrderSuccess, handleRestoreOrderSuccess)
  on(restoreOrderFailure, handleRestoreOrderFailure)
  on(confirmOrder, handleConfirmOrder)
  on(confirmOrderSuccess, handleConfirmOrderSuccess)
  on(confirmOrderFailure, handleConfirmOrderFailure)
  on(downloadOrder, handleDownloadOrder)
  on(fetchDownloadOrder, handleFetchDownloadOrder)
  on(downloadOrderSuccess, handleDownloadOrderSuccess)
  on(downloadOrderFailure, handleDownloadOrderFailure)
}, initialState)

export default reducer
