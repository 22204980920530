import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Sitemap = loadable(() =>
  import(/* webpackChunkName: "Sitemap" */ 'pages/Sitemap/Sitemap')
)

export default asyncConnect([
  {
    key: 'pageSitemap',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'sitemap' })
      return updateData.update()
    }
  }
])(Sitemap)
