import { propOr, keys, map, compose } from 'ramda'
import { createSelector } from 'reselect'

import { flagsByCode, phoneCodes } from 'helpers/registration'

export const getDirectoriesIsLoaded = state => state.directoriesIsLoaded
export const getIsConfirmed = state => state.isConfirmed
export const getIsConfirming = state => state.isConfirming
export const getStatusConfirm = state => state.statusConfirm

export const getMessage = createSelector(
  state => state,
  state => propOr('', 'message', state)
)

export const getErrors = createSelector(
  state => state,
  state => propOr({}, 'errors', state)
)

export const suggestionsErrorSelector = createSelector(
  state => state,
  state => propOr(false, 'suggestionsError', state)
)

export const isSendedSelector = createSelector(
  state => state,
  state => propOr(false, 'isSended', state)
)

export const countriesSelector = createSelector(
  state => propOr({}, 'country', state),
  countries => compose(
    map(code => ({
      id: code,
      optionValue: propOr('', code, countries),
      phoneCode: propOr('', code, phoneCodes),
      icon: propOr('', code, flagsByCode)
    })),
    keys
  )(countries)
)

export const companiesSelector = createSelector(
  state => state,
  state => propOr([], 'companySuggestions', state)
)

export const recoveryMessagesSelector = createSelector(
  state => state,
  state => propOr([], 'recoveryMessages', state)
)

export const recoveryErrorsSelector = createSelector(
  state => state,
  state => propOr([], 'recoveryErrors', state)
)

export const isSendedRecoverySelector = createSelector(
  state => state,
  state => propOr(false, 'isSendedRecovery', state)
)
