import { pathOr } from 'ramda'

import { phoneNumberRegex } from 'utils/testRegexp'

const extractMatchGroups = (match: object) => ({
  country: pathOr('', ['groups', 'country'], match),
  operator: pathOr('', ['groups', 'operator'], match),
  userGroup3: pathOr('', ['groups', 'userGroup3'], match),
  userGroup2: pathOr('', ['groups', 'userGroup2'], match),
  userGroup1: pathOr('', ['groups', 'userGroup1'], match)
})

const cleanPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/\D/g, '')

const getMatchedGroups = (phoneNumber: string) => {
  const cleaned = cleanPhoneNumber(phoneNumber)
  return cleaned.match(phoneNumberRegex)
}

const formatMatchedPhoneNumber = (match: object) => {
  const { country, operator, userGroup3, userGroup2, userGroup1 } =
    extractMatchGroups(match)
  const code = country === '8' ? '+7' : `+${country}`
  return `${code} (${operator}) ${userGroup3}-${userGroup2}-${userGroup1}`
}

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return ''

  const match = getMatchedGroups(phoneNumber)
  return match ? formatMatchedPhoneNumber(match) : phoneNumber
}

export default formatPhoneNumber
