/* eslint-disable complexity */
/* eslint-disable react/prop-types */
import React, { useCallback, useEffect } from 'react'

import { pathOr, prop, values } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import CapPreloader from 'components/CapPreloader'
import CartItem from 'containers/Cart/CartChange/CartItem'
import {
  fetchCartsContractor,
  cartsContractorSelector,
  cartsContractorLoadingSelector
} from 'redux/modules/productList'

import s from './CartsToContractor.scss'
import CartTabs from './CartTabs'
import CartAdd from '../CartChange/CartAdd'

const CartsToContractor = ({
  contractorId,
  catalog,
  catalogInit,
  onMove,
  onSetCatalog,
  isChangedContractor
}) => {
  const dispatch = useDispatch()
  const carts = useSelector(({ productList }) =>
    cartsContractorSelector(productList)
  )
  const cartsLoading = useSelector(({ productList }) =>
    cartsContractorLoadingSelector(productList)
  )
  const onFetchCartsContractor = useCallback(
    params => dispatch(fetchCartsContractor(params)),
    [dispatch]
  )

  useEffect(() => {
    onFetchCartsContractor({ contractorId })
  }, [contractorId, onFetchCartsContractor])

  if (cartsLoading) {
    return (
      <>
        <CapPreloader
          width='40%'
          height='38px'
        />
        <CapPreloader
          height='135px'
        />
      </>
    )
  }

  return (
    <div className={s.container}>
      <CartTabs
        carts={carts}
        catalog={catalog}
        onMove={onMove}
        onSetCatalog={onSetCatalog}
        isChangedContractor={isChangedContractor}
      />
      {catalog === 'main' && (
        <div className={s.carts}>
          {values(pathOr({}, ['MAIN', 'VALUES'], carts)).map(item => (
            <CartItem
              className={s.cart}
              key={prop('ID', item)}
              item={item}
              onMove={onMove}
              basketType={catalog}
              catalogInit={catalogInit}
              isChangedContractor={isChangedContractor}
              isMove
            />
          ))}
          {!isChangedContractor && <CartAdd className={s.cart}
            isAdd />}
        </div>
      )}
    </div>
  )
}

export default CartsToContractor
