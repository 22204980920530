import { compose, propOr, pathOr, prop, map, defaultTo } from 'ramda'
import { createSelector } from 'reselect'

export const keysSelector = createSelector(
  state => prop('items', state),
  state => prop('entities', state),
  (items, entities) =>
    compose(
      map(item => ({
        key: prop('KEY', item),
        date: prop('DATE', item),
        comment: propOr('', 'TITLE', item),
        contractor: pathOr(
          '',
          ['CONTRACTORS', prop('CONTRACTOR', item), 'VALUE'],
          entities
        )
      })),
      defaultTo([])
    )(items)
)

export const errorsSelector = createSelector(
  state => state,
  state => propOr([], 'errors', state)
)
export const errorSelector = createSelector(
  state => state,
  state => propOr('', 'error', state)
)
