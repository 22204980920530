import React from 'react'

import cx from 'classnames'
import { array, func, string } from 'prop-types'
import { prop, propOr } from 'ramda'
import { connect } from 'react-redux'

import CopyPopup from 'components/CopyPopup'
import Icon from 'components/Icon'
import { download } from 'redux/modules/downloadModal'
import { productBarcodesSelector } from 'redux/modules/productList'

import s from './ProductBarcodes.scss'

const ProductBarcodes = ({ id, barcodes = [], onDownload }) => {
  const onGetBarcode = () => () => {
    onDownload(`/v3/catalog/main/product/${id}/barcodes/`, { method: 'get' })
  }
  return (
    <div className={s.container}>
      <div className={s.title}>
        <div className={s.titleText}>Характеристики и маркировка упаковок</div>
        <div
          role='presentation'
          className={s.titleButton}
          onClick={onGetBarcode()}
        >
          <Icon icon='download'
            size='small'
            className={s.icon} />
          Скачать
        </div>
      </div>
      <div className={s.table}>
        <div className={s.head}>
          <div className={s.row}>
            <div className={s.col}>Тип упаковки</div>
            <div className={s.col}>Кол-во</div>
            <div className={s.col}>Штрихкод</div>
            <div className={s.col}>Вес кг.</div>
            <div className={s.col}>Длина см.</div>
            <div className={s.col}>Ширина см.</div>
            <div className={s.col}>Высота см.</div>
            <div className={s.col}>Объем м³</div>
          </div>
        </div>
        <div className={s.body}>
          {barcodes.map(item => (
            <div className={cx(s.row, s.rowBody)}
              key={prop('BARCODE', item)}>
              <div className={s.col}>
                <span className={s.colName}>
                  Тип упаковки
                </span>
                <span className={s.colValue}>
                  {propOr('', 'UNIT', item)}
                </span>
              </div>
              <div className={s.col}>
                <span className={s.colName}>
                  Кол-во
                </span>
                <span className={s.colValue}>
                  {propOr('', 'RATE', item)}
                </span>
              </div>
              <div className={cx(s.col)}>
                <span className={s.colName}>
                  Штрихкод
                </span>
                <span className={s.colValue}>
                  <CopyPopup
                    text='Штрихкод скопирован'
                    value={propOr('', 'BARCODE', item)}
                  >
                    <div className={s.copy}>
                      <Icon icon='copy'
                        className={s.copyIcon} />
                      {propOr('', 'BARCODE', item)}
                    </div>
                  </CopyPopup>
                </span>
              </div>
              <div className={s.col}>
                <span className={s.colName}>
                  Вес кг.
                </span>
                <span className={s.colValue}>
                  {propOr('', 'WEIGHT', item)}
                </span>
              </div>
              <div className={s.col}>
                <span className={s.colName}>
                  Длина см.
                </span>
                <span className={s.colValue}>
                  {propOr('', 'LENGTH', item)}
                </span>
              </div>
              <div className={s.col}>
                <span className={s.colName}>
                  Ширина см.
                </span>
                <span className={s.colValue}>
                  {propOr('', 'HEIGHT', item)}
                </span>
              </div>
              <div className={s.col}>
                <span className={s.colName}>
                  Высота см.
                </span>
                <span className={s.colValue}>
                  {propOr('', 'WIDTH', item)}
                </span>
              </div>
              <div className={s.col}>
                <span className={s.colName}>
                  Объем м³
                </span>
                <span className={s.colValue}>
                  {propOr('', 'VOLUME', item)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

ProductBarcodes.propTypes = {
  id: string,
  barcodes: array,
  onDownload: func
}

ProductBarcodes.defaultProps = {
  onDownload: () => {},
  barcodes: []
}

export default connect(
  ({ productList }, { id }) => ({
    barcodes: productBarcodesSelector(id, productList)
  }),
  {
    onDownload: download
  }
)(ProductBarcodes)
