import React from 'react'

import cx from 'classnames'
import { array, number } from 'prop-types'
import { propOr, compose, join, over, lensIndex, toUpper } from 'ramda'

import Icon from 'components/Icon'
import Link from 'components/Link'
import { setAnalyticsProps } from 'helpers/metaTags'

import s from './LeftMenu.scss'

const BottomCabinetMenu = props => {
  const toUpperFirstLetter = name =>
    compose(join(''), over(lensIndex(0), toUpper))(name)

  const renderCabinetBottomItem = item => {
    const name = propOr('', 'name', item)
    const isAdditionalProps = propOr(false, 'showCounter', item)
    const analyticsProps = isAdditionalProps
      ? { ...setAnalyticsProps({
        type: 'action',
        group: 'cabinetMain',
        action: `go${toUpperFirstLetter(name)}`
      }) } : {}
    return (
      <div
        key={name}
        className={s.bottomMenuItem}
      >
        <Link
          to={propOr('', 'url', item)}
          {...analyticsProps}>
          <div className={s.bottomMenuLink}>
            <Icon
              icon={propOr('', 'icon', item)}
              size='small'
              className={cx(s.bottomMenuIcon, {
                [s.bottomMenuIconBlue]: propOr('', 'color', item) === 'blue',
                [s.bottomMenuIconRed]: propOr('', 'color', item) === 'red'
              })}
            />
            {propOr('', 'title', item)}
            {isAdditionalProps && (
              <span className={s.bottomMenuItemCounter}>({propOr(0, `${name}Count`, props)})</span>
            )}
          </div>
        </Link>
      </div>
    )
  }

  return (
    <div className={s.bottomMenu}>
      {props.menuCabinetBottom.map(item => renderCabinetBottomItem(item))}
    </div>
  )
}

BottomCabinetMenu.propTypes = {
  menuCabinetBottom: array,
  favoriteCount: number,
  compareCount: number
}

BottomCabinetMenu.defaultProps = {
  menuCabinetBottom: [],
  favoriteCount: 0,
  compareCount: 0
}

export default BottomCabinetMenu
