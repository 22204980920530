// @ts-nocheck
import React, { FC } from 'react'

import { SecurityModal } from 'containers/Forms/FormSecurity'
import { modalActions } from 'decorators/modal'
import Button from 'UI/Button'

interface ISecurityFormButton {
  showModal: (str: string) => void
}

const SecurityFormButton: FC<ISecurityFormButton> = ({
  showModal,
  ...rest
}) => {
  const showModalCallback = () => () => {
    showModal('securityForm')
  }

  return (
    <>
      <Button onClick={showModalCallback()} 
        {...rest} />
      <SecurityModal />
    </>
  )
}

export default modalActions(SecurityFormButton)
