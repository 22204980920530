import React from 'react'

import { bool } from 'prop-types'
import { connect } from 'react-redux'

import Link from 'components/Link'
import { isKdClientSelector } from 'redux/modules/apiAuth'
import Text from 'UI/Text'

const PersonalConfirm = ({ isKdClient = false }) => {
  return (
    <Text>
      Я{' '}
      <Link to='/documents/consent'
        target='_blank'
        color='blue'>
        подтверждаю согласие
      </Link>{' '}
      на обработку персональных данных в соответствии с{' '}
      <Link to='/documents/policy'
        target='_blank'
        color='blue'>
        политикой компании
      </Link>
      {isKdClient ? null : (
        <>
          {' '}
          и ознакомлен с{' '}
          <Link
            to='/documents/soglashenie_k_dogovoru_postavki'
            target='_blank'
            color='blue'>
            соглашением к договору поставки
          </Link>
        </>
      )}
      .
    </Text>
  )
}

PersonalConfirm.propTypes = {
  isKdClient: bool
}

export default connect(({ apiAuth, router }) => ({
  isKdClient: isKdClientSelector({ apiAuth, router })
}))(PersonalConfirm)
