import React from 'react'

import ChangeContractor from 'containers/ChangeContractor'
import Modal from 'containers/Modal'
import { forAuth } from 'decorators/user'

const PopupChangeContractor = () => (
  <Modal
    id='changeContractor'
    size='middle'
  >
    <ChangeContractor />
  </Modal>
)

export default forAuth(PopupChangeContractor)
