import { pathOr, propOr, prop, path, or } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal } from 'redux/modules/modal'

const initialState = {
  isPending: false,
  isSuccess: false,
  isFailure: false,
  isTimeout: false,
  isPendingPrinted: false,
  isSuccessPrinted: false,
  isFailurePrinted: false,
  url: null,
  id: null,
  type: null,
  button: null,
  file: null,
  message: null,
  docs: []
}
const TIMEOUT_20000 = 20000
export const download = createAction('download/DOWNLOAD', (url, options) => ({
  ...options,
  url
}))
const downloadRequest = createAction('download/START_DOWNLOAD')
const downloadSuccess = createAction('download/DOWNLOAD_SUCCESS')
const downloadFailure = createAction('download/DOWNLOAD_FAILURE')
export const showPrintedModal = createAction('download/SHOW_PRINTED_MODAL')
const fetchPrintedSettings = createAction('download/FETCH_PRINTED_SETTINGS')
const fetchPrintedSettingsSuccess = createAction(
  'download/FETCH_PRINTED_SETTINGS_SUCCESS'
)
const fetchPrintedSettingsFailure = createAction(
  'download/FETCH_PRINTED_SETTINGS_FAILURE'
)
const savePrintedSettings = createAction('download/SAVE_PRINTED_SETTINGS')
const savePrintedSettingsSuccess = createAction(
  'download/SAVE_PRINTED_SETTINGS_SUCCESS'
)
const savePrintedSettingsFailure = createAction(
  'download/SAVE_PRINTED_SETTINGS_FAILURE'
)
export const downloadPrinted = createAction('download/DOWNLOAD_PRINTED')
const downloadPrintedRequest = createAction('download/DOWNLOAD_PRINTED_REQUEST')

const request =
  clientApi =>
    ({ method, url, params, timeout }) =>
      Promise.race([
        new Promise(resolve => {
          setTimeout(() => {
            resolve({
              data: { response: { SUCCESS: false, FAILURE: false } },
              timeout: true
            })
          }, timeout)
        }),
        clientApi[method](url, {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
      ])
        .then(downloadSuccess)
        .catch(downloadFailure)

const fetchPrintedSettingsRequest = clientApi => () =>
  clientApi
    .get('/v2/contractor/settings/printed/', {
      params: {
        contractor_id: clientApi.getContractorId()
      }
    })
    .then(fetchPrintedSettingsSuccess)
    .catch(fetchPrintedSettingsFailure)

const savePrintedSettingsRequest =
  clientApi =>
    ({ params }) =>
      clientApi
        .post('/v2/contractor/settings/printed/', {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(savePrintedSettingsSuccess)
        .catch(savePrintedSettingsFailure)

const reducer = createReducer(
  {
    [download]: (
      state,
      {
        method = 'post',
        url,
        type,
        params,
        button,
        message,
        timeout = TIMEOUT_20000
      }
    ) =>
      loop(
        {
          ...state,
          isPending: true,
          isSuccess: false,
          isFailure: false,
          isTimeout: false,
          file: null,
          button,
          type,
          message
        },
        Effects.batch([
          Effects.call(showModal, 'downloadModal'),
          Effects.call(downloadRequest, {
            method,
            url,
            params,
            timeout
          })
        ])
      ),

    [downloadRequest]: (state, payload, { clientApi }) =>
      loop(
        {
          ...state
        },
        Effects.promise(request(clientApi), payload)
      ),

    [downloadSuccess]: (state, payload) => {
      const response = pathOr({}, ['data', 'response'], payload)
      return {
        ...state,
        isPending: false,
        isSuccess: response.SUCCESS,
        isFailure: propOr(false, 'FAILURE', response),
        isTimeout: propOr(false, 'timeout', payload),
        file: or(prop('PATH', response), path(['RESULT', 'PATH'], response)),
        message: pathOr(null, ['MESSAGE', 'TEXT'], response)
      }
    },

    [downloadFailure]: state => ({
      ...state,
      isPending: false,
      isFailure: true
    }),

    [showPrintedModal]: (state, { type = 'printed', id }) =>
      loop(
        {
          ...state,
          isPending: false,
          isSuccess: false,
          isFailure: false,
          isTimeout: false,
          isPendingPrinted: true,
          isSuccessPrinted: false,
          isFailurePrinted: false,
          file: null,
          message: null,
          type,
          id
        },
        Effects.batch([
          Effects.call(showModal, 'downloadModal'),
          Effects.call(fetchPrintedSettings)
        ])
      ),

    [fetchPrintedSettings]: (state, payload, { clientApi }) =>
      loop(
        {
          ...state,
          isPendingPrinted: true,
          isSuccessPrinted: false,
          isFailurePrinted: false,
          docs: []
        },
        Effects.promise(fetchPrintedSettingsRequest(clientApi), payload)
      ),

    [fetchPrintedSettingsSuccess]: (state, payload) => {
      const response = pathOr({}, ['data', 'response'], payload)
      const docs = pathOr([], ['ITEMS'], response).map(doc => ({
        ...doc,
        OPTIONS: Object.keys(doc.OPTIONS).map(value => ({
          title: doc.OPTIONS[value],
          value
        }))
      }))

      return {
        ...state,
        isPendingPrinted: false,
        isSuccessPrinted: response.SUCCESS,
        docs
      }
    },

    [fetchPrintedSettingsFailure]: state => ({
      ...state,
      isPendingPrinted: false,
      isFailurePrinted: true
    }),

    [savePrintedSettings]: (state, { params }, { clientApi }) =>
      loop(
        {
          ...state
        },
        Effects.promise(savePrintedSettingsRequest(clientApi), { params })
      ),

    [savePrintedSettingsSuccess]: state =>
      loop(
        {
          ...state
        },
        Effects.call(downloadPrintedRequest, {})
      ),

    [savePrintedSettingsFailure]: state =>
      loop({
        ...state,
        isPending: false,
        isFailure: true
      }),

    [downloadPrintedRequest]: (state, { timeout = TIMEOUT_20000 }, { clientApi }) => loop(
      {
        ...state
      },
      Effects.promise(request(clientApi), {
        method: 'get',
        url: `/v3/invoice/bill/${state.id}/doc/product/printed/`,
        timeout
      })
    ),

    [downloadPrinted]: (state, { params = {}, optionsMode }) => {
      const action = optionsMode ? savePrintedSettings : downloadPrintedRequest
      return loop(
        {
          ...state,
          isPending: true
        },
        Effects.call(action, { params })
      )
    }
  }, initialState)

export default reducer
