/* eslint-disable complexity */
import { equals, join, path, pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import config from 'config'
import { showModal } from 'redux/modules/modal'
import moment from 'utils/moment'

export const COOKIE_NAME_FEEDBACK_SEARCH = 'searchQuestion'
const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HALF_HOUR = 30

export {
  contractorsSelector,
  dateSelector,
  feedbackSelector,
  formsTypesSelector,
  getFeedbackProduct,
  getLocationSelector,
  periodTypesSelector,
  ratingTypesSelector,
  sortTypesSelector,
  statusSelector
} from './selector'

const initialState = {
  feedback: [],
  isError: false,
  isLoaded: false,
  isLoading: false,
  product: {
    name: '',
    image: ''
  }
}

export const fetchFeedback = createAction('feedback/FETCH_FEEDBACK')
const fetchFeedbackSuccess = createAction('feedback/FETCH_FEEDBACK_SUCCESS')
const fetchFeedbackFailure = createAction('feedback/FETCH_FEEDBACK_FAILURE')

export const fetchProduct = createAction('feedback/FETCH_PRODUCT')
const fetchProductSuccess = createAction('feedback/FETCH_PRODUCT_SUCCESS')
const fetchProductFailure = createAction('feedback/FETCH_PRODUCT_FAILURE')

export const feedbackSearch = createAction('feedback/FEEDBACK_SEARCH')
const feedbackSearchSuccess = createAction('feedback/FEEDBACK_SEARCH_SUCCESS')
const feedbackSearchFailure = createAction('feedback/FEEDBACK_SEARCH_FAILURE')
const setFeedbackSearchCookie = createAction('feedback/SET_FEEDBACK_SEARCH_COOKIE')

const fieldSelection = formType => {
  const baseFields = [
    '_id',
    'created',
    'data.BnzCaseId',
    'data.contractor',
    'data.employee_response',
    'data.reference_status',
    'modified'
  ]
  switch (formType) {
    case 'foundcheaper':
      return [
        ...baseFields,
        'data.cheaper_comment',
        'data.cheaper_images',
        'data.container',
        'data.product_id'
      ]
    case 'footerfeedback':
      return [
        ...baseFields,
        'data.feedback_message',
        'data.feedback_theme',
        'data.feedback_type',
        'data.list_documents'
      ]
    case 'productreview':
      return [
        ...baseFields,
        'data.product_advantages',
        'data.product_comment',
        'data.product_disadvantages',
        'data.product_id',
        'data.product_rating',
        'data.review_anonymity'
      ]
    case 'rangeexpansion':
      return [
        ...baseFields,
        'data.commodity_items',
        'data.planned_turnover',
        'data.range_brands',
        'data.range_comment'
      ]
    default:
      return baseFields
  }
}

const requestFeedback =
  ({ clientApi }) =>
    params => {
      const {
        contractorKey = '',
        form = 'foundcheaper',
        limit = 0,
        periodFrom = '',
        periodTo = '',
        productId = '',
        rating = '',
        sort = '-created',
        status = ''
      } = params

      const newParams = {
        [contractorKey.includes(',') ? 'data.contractor__in' : 'data.contractor']:
        contractorKey,
        [equals(status, 'Все') ? '' : 'data.reference_status']: status,
        [periodFrom ? 'created__gte' : '']: periodFrom
          ? moment(periodFrom, 'DD.MM.YYYY').startOf('day').toISOString()
          : '',
        [periodTo ? 'created__lte' : '']: periodTo
          ? moment(periodTo, 'DD.MM.YYYY').endOf('day').toISOString()
          : '',
        [productId ? 'data.product_id' : '']: productId,
        [rating ? 'data.product_rating' : '']: rating
      }

      return clientApi
        .get(`${config.formIo.host}/${form}/submission`, {
          params: {
            ...newParams,
            limit,
            select: join(',')(fieldSelection(form)),
            sort
          }
        })
        .then(fetchFeedbackSuccess)
        .catch(fetchFeedbackFailure)
    }

const handleFetchFeedback = (state, payload, { clientApi }) => {
  return loop(
    {
      ...state,
      isLoaded: false,
      isLoading: true,
      isError: false
    },
    Effects.promise(requestFeedback({ clientApi }), payload)
  )
}

const handleFetchFeedbackSuccess = (state, payload) => ({
  ...state,
  feedback: path(['data'], payload),
  isLoading: false,
  isLoaded: true,
  isError: false
})

const handleFetchFeedbackFailure = state =>
  loop(
    {
      ...state,
      feedback: [],
      isLoading: false,
      isLoaded: false,
      isError: true
    },
    Effects.call(showModal, 'pageFeedbackStatusError')
  )

const requestProduct =
  ({ clientApi }) =>
    ({ id }) =>
      clientApi
        .get(`/v3/catalog/main/product/${id}/`, {})
        .then(fetchProductSuccess)
        .catch(fetchProductFailure)

const handleFetchProduct = (state, payload, { clientApi }) => {
  return loop(
    {
      ...state,
      product: {},
      isLoaded: false,
      isLoading: true,
      isError: false
    },
    Effects.promise(requestProduct({ clientApi }), payload)
  )
}

const handleFetchProductSuccess = (state, payload) => ({
  ...state,
  product: {
    name: pathOr('', ['data', 'response', 'ITEM', 'NAME'], payload),
    image: pathOr(
      '',
      ['data', 'response', 'ITEM', 'PREVIEW_PICTURE_PATH'],
      payload
    )
  },
  isLoading: false,
  isLoaded: true,
  isError: false
})

const handleFetchProductFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  isError: true
})

const requestFeedbackSearch =
  ({ clientApi }) =>
    ({ query, like }) =>
      clientApi
        .post('/v3/search/grade/', { params: { query, like }})
        .then(feedbackSearchSuccess)
        .catch(feedbackSearchFailure)

const handleFeedbackSearch = (state, { query, like }, { clientApi }) => {
  return loop(
    {
      ...state
    },
    Effects.promise(requestFeedbackSearch({ clientApi }), { query, like })
  )
}

const handleFeedbackSearchSuccess = state => loop({
  ...state
}, Effects.call(setFeedbackSearchCookie, '1'))

const handleFeedbackSearchFailure = state => loop({
  ...state
}, Effects.call(setFeedbackSearchCookie, '1'))

const handleSetFeedbackSearchCookie = (state, payload, { cookie }) => {
  cookie.save(COOKIE_NAME_FEEDBACK_SEARCH, payload, {
    path: '/',
    expires: new Date(Date.now() + MINUTES_IN_HALF_HOUR * SECONDS_IN_MINUTE * MILLISECONDS_IN_SECOND)
  })
  return ({
    ...state
  })
}

export default createReducer(on => {
  on(fetchFeedback, handleFetchFeedback)
  on(fetchFeedbackSuccess, handleFetchFeedbackSuccess)
  on(fetchFeedbackFailure, handleFetchFeedbackFailure)
  on(fetchProduct, handleFetchProduct)
  on(fetchProductSuccess, handleFetchProductSuccess)
  on(fetchProductFailure, handleFetchProductFailure)
  on(feedbackSearch, handleFeedbackSearch)
  on(feedbackSearchSuccess, handleFeedbackSearchSuccess)
  on(feedbackSearchFailure, handleFeedbackSearchFailure)
  on(setFeedbackSearchCookie, handleSetFeedbackSearchCookie)
}, initialState)
