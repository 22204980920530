/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string, arrayOf, func, object } from 'prop-types'
import {  pathOr, find, propEq } from 'ramda'

import Icon from 'components/Icon'
import Button from 'UI/Button'
import Checkbox from 'UI/Checkbox'
import Select from 'UI/Select'

import s from './PrintedList.scss'

export default class PrintedList extends PureComponent {
  static propTypes = {
    isPending: bool,
    isSuccess: bool,
    isFailure: bool,
    isTimeout: bool,
    isPendingPrinted: bool,
    isFailurePrinted: bool,
    file: string,
    message: string,
    docs: arrayOf(object),
    email: string,
    hideModal: func,
    downloadPrinted: func
  }

  static defaultProps = {
    docs: []
  }

  state = {
    checkedDocs: {},
    selectedFormats: {},
    optionsMode: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.docs === prevProps.docs) return

    const checkedDocs = this.props.docs.reduce(
      (acc, doc) => ({ ...acc, [doc.ID]: doc.ACTIVE }),
      {}
    )
    const selectedFormats = this.props.docs.reduce(
      (acc, doc) => ({ ...acc, [doc.ID]: doc.VALUE }),
      {}
    )
    const optionsMode = Object.keys(checkedDocs).every(
      docId => !checkedDocs[docId]
    )

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({
      checkedDocs,
      selectedFormats,
      optionsMode
    })
  }

  handleDownloadClick = () => {
    const { docs, downloadPrinted } = this.props
    const { optionsMode, checkedDocs, selectedFormats } = this.state

    const options = { optionsMode }
    if (optionsMode) {
      options.params = {
        values: docs.map(doc => {
          const obj = {
            ID: doc.ID,
            ACTIVE: checkedDocs[doc.ID]
          }
          return checkedDocs[doc.ID]
            ? { ...obj, VALUE: selectedFormats[doc.ID] }
            : obj
        })
      }
    }
    downloadPrinted(options)
  }

  handleDownloadFileClick = () => {
    this.props.hideModal()
  }

  handleCheckboxChange = id => checked => {
    this.setState(prevState => ({
      checkedDocs: {
        ...prevState.checkedDocs,
        [id]: checked
      }
    }))
  }

  handleSelectChange = id => format => {
    const currentDoc = this.props.docs.find(doc => doc.ID === id)
    if (!currentDoc) return

    this.setState(prevState => ({
      selectedFormats: {
        ...prevState.selectedFormats,
        [id]: format
      }
    }))
  }

  handleOptionsClick = () =>
    this.setState(prevState => ({ optionsMode: !prevState.optionsMode }))

  renderSpinner = () => (
    <div className={s.spinner}>
      <img
        className={s.spinnerImage}
        src='/images/preloader/loader.gif'
        alt='loading'
      />
    </div>
  )

  renderDocumentsTable = docs => (
    <div className={s.table}>
      <div className={s.wrapper}>
        <div className={s.subTitle}>
          {this.state.optionsMode
            ? 'Документы'
            : `Отметьте документы которые хотите скачать, затем выберите
            формат файла и нажмите “Сохранить и скачать”`}
        </div>
        {docs
          .filter(doc => (this.state.optionsMode ? true : doc.ACTIVE))
          .map(doc => {
            const options = pathOr([], ['OPTIONS'], doc)
            const currentFormat = find(
              propEq(
                'value',
                this.state.optionsMode
                  ? this.state.selectedFormats[doc.ID]
                  : doc.VALUE
              )
            )(options)
            const title = pathOr('Формат не выбран', ['title'], currentFormat)

            return (
              <div className={s.tableItem}
                key={doc.ID}>
                {this.state.optionsMode ? (
                  <div className={s.tableEdit}>
                    <div className={s.tableTitle}>
                      <Checkbox
                        id={`document_${doc.ID}`}
                        checked={this.state.checkedDocs[doc.ID]}
                        onChange={this.handleCheckboxChange(doc.ID)}
                        title={doc.TITLE}
                      />
                    </div>
                    {this.state.checkedDocs[doc.ID] && (
                      <div className={s.tableFormat}>
                        <Select
                          color='white'
                          onSelect={this.handleSelectChange(doc.ID)}
                          options={doc.OPTIONS}
                          placeholder='Выберите формат'
                          selectedValue={this.state.selectedFormats[doc.ID]}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className={s.tableView}>
                    <div className={s.tableIcon}>
                      <Icon icon='document' />
                    </div>
                    <div className={s.tableDesc}>
                      <span className={s.tableDescTitle}>{doc.TITLE}</span>
                      <span className={s.tableDescFormat}>{title}</span>
                    </div>
                  </div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )

  renderButtons = ({
    downloadTitle,
    downloadDisabled = false,
    handleDownloadClick = () => {}
  } = {}) => (
    <Button
      onClick={handleDownloadClick}
      href={this.props.file}
      icon='download'
      disabled={downloadDisabled}
      stretched
    >
      {downloadTitle}
    </Button>
  )

  renderOptionsButton = () => (
    <div
      role='presentation'
      className={s.optionsButton}
      onClick={this.handleOptionsClick}
    >
      <Icon className={cx(s.icon, s.iconGear)}
        icon='settings' />
      <span className={s.optionsButtonTitle}>Настроить печатные формы</span>
    </div>
  )

  render() {
    const {
      isPending,
      isSuccess,
      isFailure,
      isTimeout,
      isPendingPrinted,
      isFailurePrinted,
      docs,
      message,
      email
    } = this.props
    const { checkedDocs, selectedFormats, optionsMode } = this.state

    if (isPending) {
      return (
        <div className={cx(s.root, s.root_loading)}>
          {this.renderSpinner()}
          <div className={s.message}>
            Через некоторое время мы подготовим документ и вы сможете его
            скачать.
          </div>
        </div>
      )
    }

    if ((isFailure || isFailurePrinted) && !isTimeout) {
      return (
        <div className={cx(s.root, s.root_error)}>
          <div className={s.message}>
            {message ||
              'Во время загрузки произошла ошибка, пожалуйста, повторите попытку.'}
          </div>
        </div>
      )
    }

    if (isSuccess || isTimeout) {
      return (
        <div className={cx(s.root, s.root_success)}>
          <div className={s.message}>
            {isTimeout &&
              `Документ будет подготовлен через некоторое время,
              мы отправим ссылку на скачивание Вам на почту ${email}`}
            {isSuccess &&
              (message || 'Мы подготовили документ, Вы можете его скачать.')}
          </div>
          {!isTimeout &&
            this.renderButtons({
              downloadTitle: 'Скачать',
              downloadDisabled: false,
              handleDownloadClick: this.handleDownloadFileClick
            })}
        </div>
      )
    }

    const isAtLeastOneChecked = Object.keys(checkedDocs).some(
      docId => checkedDocs[docId]
    )
    const isAllChekedHasFormat = Object.keys(checkedDocs).every(docId =>
      checkedDocs[docId] ? selectedFormats[docId] !== '' : true
    )

    return (
      <div
        className={cx(s.root, s.root_documents, {
          [s.root_optionsMode]: optionsMode
        })}
      >
        {!isPendingPrinted && (
          <div className={s.title}>
            {optionsMode && (
              <div className={s.titlePrev}>
                <div
                  role='presentation'
                  className={s.titleIcon}
                  onClick={this.handleOptionsClick}
                >
                  <Icon icon='prev'
                    size='small'
                    className={s.iconPrev} />
                </div>
                Настройки печатной формы
              </div>
            )}
          </div>
        )}
        {optionsMode && !isPendingPrinted && (
          <div className={s.desc}>
            Отметьте документы которые вы хотите скачать, затем выберите формат
            файла и нажмите «Сохранить и скачать»
          </div>
        )}
        {isPendingPrinted
          ? this.renderSpinner()
          : this.renderDocumentsTable(docs)}
        {!optionsMode && !isPendingPrinted && this.renderOptionsButton()}
        {this.renderButtons({
          downloadTitle: optionsMode
            ? 'Сохранить и скачать печатные формы'
            : 'Скачать печатные формы',
          downloadDisabled:
            optionsMode && (!isAtLeastOneChecked || !isAllChekedHasFormat),
          handleDownloadClick: this.handleDownloadClick
        })}
      </div>
    )
  }
}
