/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import {
  oneOfType,
  number,
  array,
  object,
  bool,
  func,
  string
} from 'prop-types'
import {
  prop,
  propOr,
  is,
  findLast,
  propEq,
  length,
  pathOr,
  path,
  compose,
  isEmpty,
  map,
  keys,
  filter,
  difference,
  lte
} from 'ramda'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import BasketTooltip from 'components/BasketTooltip'
import Icon from 'components/Icon'
import OrderButton from 'components/OrderButton'
import Portal from 'components/Portal'
import StockDelivery from 'components/ProductActions/StockDelivery'
import StoreCarts from 'components/ProductActions/StoreCarts'
import StoreCount from 'components/ProductActions/StoreCount'
import StoreList from 'components/ProductActions/StoreList'
import StoreSelect from 'components/ProductActions/StoreSelect'
import LayerOpacity from 'containers/LayerOpacity'
import { display } from 'decorators/device'
import { cartSelector, setMultiplicity } from 'redux/modules/productList'
import { currentStoreSelector } from 'redux/modules/products/selector'
import Button from 'UI/Button'

import styles from './ProductActions.scss'

const COUNT_PLACEHOLDER = '+9999'
const COUNT_MAX_LENGTH = 4
const HOME_SLIDER_PER_PAGE = 5
const TOP_POSITION = 4

@connect(
  ({ productList }, { item, activeStore }) => {
    return {
      cart: cartSelector(productList),
      currentStore: currentStoreSelector(item, activeStore)
    }
  },
  {
    setMultiplicity
  }
)
class ProductActions extends PureComponent {
  static propTypes = {
    serial: number,
    type: string,
    groupType: string,
    productId: string,
    activeStore: string,
    basketStore: string,
    cart: number,
    amount: number,
    basket: number,
    delay: number,
    min: number,
    step: number,
    remain: number,
    minInit: number,
    stepInit: number,
    goods: array,
    item: object,
    isUpdating: object,
    descriptionStores: object,
    currentStore: object,
    isShowMoreOptions: bool,
    inFavorite: bool,
    inCompare: bool,
    isBookmark: bool,
    isForPoints: bool,
    isForPointsBasket: bool,
    bookmark: number,
    renderInput: bool,
    renderButton: oneOfType([func, bool]),
    onUpdateGroup: func,
    removeFromCompare: func,
    removeFromFavorite: func,
    onSetStore: func,
    onChange: func,
    onOpenStore: func,
    isProductCard: bool,
    hasCommonCount: bool,
    isCarousel: bool,
    mod: string,
    originalProduct: string,
    analogsInfo: object,
    onShowAnalogs: func,
    multiple: object,
    isMultiple: bool,
    isNotMultiple: bool,
    isBuyNotMultiple: bool,
    isDesktop: bool,
    setMultiplicity: func
  }

  static defaultProps = {
    min: 1,
    remain: 1,
    goods: [],
    isUpdating: {},
    analogsInfo: {},
    multiple: {},
    currentStore: {},
    onUpdateGroup: () => {},
    onChange: () => {},
    onShowAnalogs: () => {},
    setMultiplicity: () => {},
    removeFromFavorite: () => {},
    removeFromCompare: () => {},
    onOpenStore: () => {},
    isCarousel: false,
    isForPoints: false,
    isForPointsBasket: false,
    isShowMoreOptions: true,
    renderInput: true,
    renderButton: true,
    isBookmark: false,
    isMultiple: true,
    isNotMultiple: false,
    isBuyNotMultiple: false
  }

  state = {
    inputValue: null,
    isOpenStore: false,
    carts: []
  }

  componentDidMount() {
    this.onSetCartsActive([this.props.cart])
  }

  componentDidUpdate(prevProps) {
    if (this.props.cart !== prevProps.cart) {
      this.onSetCartsActive([this.props.cart])
    }
  }

  handleFocusAmount = () => {
    this.handleOpen(true)()
    this.setState({ inputValue: this.props.amount })
  }

  handleClick = () => this.handleOpen(true)()

  handleBlurAmount = (event, value, isCorrect) => {
    const isEmptyInput =
      path(['target', 'nodeName'], event) === 'INPUT' &&
      !path(['target', 'value'], event)
    const amount = isEmptyInput ? this.props.amount : value
    this.props.onChange({ amount }, isCorrect, this.props.amount, { ...event })
    setTimeout(this.setState({ inputValue: null }))
  }

  handleChangeAmount = (value, isCorrect) => {
    this.setState({ inputValue: value })
    this.props.onChange({ amount: value }, isCorrect, this.props.amount)
  }

  handleKeyUpAmount = e => {
    if (e.key !== 'Enter') {
      return
    }

    const { remain, originalProduct, activeStore, isForPoints } = this.props
    const { carts } = this.state
    const amount = parseInt(e.target.value, 10)
    const group = remain < amount || remain === 0 ? ['delay', 0] : []
    let params = {}
    if (originalProduct) {
      params = {
        action: 'replace',
        originalProduct
      }
    }
    params = {
      full: 'items',
      values: carts,
      ...params
    }
    this.props.onUpdateGroup(
      { QUANTITY: amount, STORE: activeStore, FOR_POINTS: isForPoints },
      { type: 'basket', group, params }
    )
  }

  handleChangeBasket = _params => () => {
    const { amount, originalProduct, remain, activeStore, isForPoints } =
      this.props
    const { isOpenStore, carts } = this.state
    const group = remain < amount || remain === 0 ? ['delay', 0] : []
    let params = {
      ..._params
    }
    if (originalProduct) {
      params = {
        action: 'replace',
        originalProduct
      }
    }
    params = {
      full: 'items',
      values: carts,
      ...params
    }
    this.props.onUpdateGroup(
      { QUANTITY: amount, STORE: activeStore, FOR_POINTS: isForPoints },
      { type: 'basket', group, params }
    )
    if (isOpenStore) {
      this.setState({ isOpenStore: false })
    }
  }

  handleClose = () => {
    this.setState({ isOpenStore: false })
  }

  handleOpen = (isOpenStore = false) => () => {
    const { item } = this.props
    const countStores = compose(length, propOr([], 'STORES'))(item)
    if (countStores > 1) {
      this.setState({ isOpenStore })
    }
  }

  handleChangeStore = store => {
    this.props.onSetStore(store)
  }

  setActionsOffset() {
    const actions = this.actionsRef
    const coord = actions && actions.getBoundingClientRect()
    return {
      left: `${coord.left}px`,
      top: `${coord.top + TOP_POSITION}px`,
      width: `${coord.width}px`
    }
  }

  setActions = element => {
    this.actionsRef = element
  }

  getGoodsCount = () => {
    const { goods } = this.props

    const count = goods.reduce((acc, curr) => acc + parseInt(curr.count, 10), 0)

    return count.toString().length > COUNT_MAX_LENGTH
      ? COUNT_PLACEHOLDER
      : count
  }

  onSetCartsActive = carts => this.setState({ carts })

  renderActions() {
    const {
      activeStore,
      currentStore,
      item,
      groupType,
      serial,
      mod,
      descriptionStores,
      isDesktop,
      isCarousel
    } = this.props
    const { isOpenStore } = this.state
    const stores = propOr([], 'STORES', item)
    const stockSelected = findLast(propEq('CODE', activeStore))(stores)

    const titleStore = pathOr('', [activeStore, 'NAME'], descriptionStores)
    const titleStoreDelivery = pathOr('', ['DELIVERY', 'TITLE'], currentStore)
    const tooltipActiveStore = pathOr(
      '',
      [activeStore, 'TITLE'],
      descriptionStores
    )
    const availableStore = propOr({}, 'STORE', item)
    const storeColumnName = propOr('', 'COL', availableStore)
    const tooltipAvailableStore = propOr('', 'TITLE', availableStore)
    const availableStoreCount = propOr('', 'STOCKS', availableStore)
    const position = [0, 1].includes(serial)
    || serial % HOME_SLIDER_PER_PAGE === 0
    || (serial - 1) % HOME_SLIDER_PER_PAGE === 0 ? 'right' : 'left'
    const Tag = isOpenStore && isDesktop && isCarousel ? Portal : 'div'
    const TagStores = isOpenStore && !isDesktop ? Portal : 'div'
    const style = isOpenStore && isDesktop ? this.setActionsOffset() : {}

    return (
      <>
        <Tag className={styles.buyWrapper}>
          <div
            className={cx(styles.buy, {
              [styles.buyFixed]: isOpenStore && isDesktop
            })}
            style={style}
          >
            <div
              className={cx(styles.action, {
                [styles.actionActive]: isOpenStore,
                [styles.actionActiveRight]: position === 'right',
                [styles.actionActiveLeft]: position === 'left'
              })}
            >
              <div className={styles.header}>
                <StoreSelect
                  stores={stores}
                  storeTitle={titleStore}
                  isOpen={isOpenStore}
                  onOpen={this.handleOpen}
                />
                <StockDelivery title={titleStoreDelivery} />
              </div>
              <div className={styles.counts}>
                <StoreCount
                  count={prop('REMAIN', stockSelected)}
                  tooltip={tooltipActiveStore}
                  isHeader
                />
                {!isEmpty(availableStoreCount) && (
                  <StoreCount
                    count={availableStoreCount}
                    tooltip={tooltipAvailableStore}
                    isAvailable
                  />
                )}
              </div>
              {this.renderOrderPanel()}
            </div>
            {isOpenStore && (
              <TagStores>
                <StoreList
                  className={cx({
                    [styles[`storeList${groupType}`]]: !!groupType
                  })}
                  mod={mod}
                  position={position}
                  activeStore={activeStore}
                  storeColName={storeColumnName}
                  descriptionStores={descriptionStores}
                  stores={stores}
                  isOpen
                  onChange={this.handleChangeStore}
                  onClose={this.handleClose}
                />
              </TagStores>
            )}
          </div>
        </Tag>
        {isOpenStore && <LayerOpacity onClose={this.handleClose} />}
      </>
    )
  }

  renderButton() {
    const {
      item,
      cart,
      basket,
      activeStore,
      basketStore,
      remain,
      isBookmark,
      bookmark,
      analogsInfo,
      isMultiple,
      isForPoints,
      isForPointsBasket
    } = this.props
    const catalog = isBookmark ? 'BOOKMARK' : 'MAIN'
    const { carts } = this.state
    const inBaskets = compose(
      map(i => Number(i)),
      keys,
      map(i => propOr(0, 'QUANTITY', i)),
      filter(i => propOr(0, 'QUANTITY', i)),
      pathOr([], ['BASKETS', catalog])
    )(item)

    const amount = parseInt(this.state.inputValue, 10) || this.props.amount
    const isUpdating = this.props.isUpdating.basket
    const delay = pathOr(0, ['BASKETS', 'DELAY', 0, 'QUANTITY'], item)

    const inDelay = delay > 0
    const isWait = remain < amount || remain === 0
    const currentBasket = isWait ? delay : basket
    const isAddToBasket = compose(lte(1), length, difference(carts))(inBaskets)

    const icon = isMultiple ? null : (
      <Icon
        className={cx(styles.buttonIcon, {
          [styles.buttonIconBookmark]: isBookmark
        })}
        icon='not-multiple'
      />
    )

    const bookmarkTo =
      length(difference(carts, inBaskets)) > 1 ? 'ЗАКЛАДКИ' : 'ЗАКЛАДКУ'
    const bookmarkIn = length(inBaskets) > 1 ? 'ЗАКЛАДКАХ' : 'ЗАКЛАДКЕ'
    const basketTo =
      length(difference(carts, inBaskets)) > 1 ? 'КОРЗИНЫ' : 'КОРЗИНУ'
    const basketIn = length(inBaskets) > 1 ? 'КОРЗИНАХ' : 'КОРЗИНЕ'

    const addText = (
      <div className={styles.buttonText}>
        {icon}В {isBookmark ? bookmarkTo : basketTo}
      </div>
    )
    let text = isWait ? 'В ОЖИДАНИЕ' : addText
    let mod = isWait && 'wait'
    const isChanged =
      (currentBasket !== amount ||
        (!isWait && activeStore !== basketStore) ||
        isForPoints !== isForPointsBasket) &&
      !isAddToBasket
    if (isUpdating) {
      text = currentBasket > 0 ? 'ОБНОВЛЯЕТСЯ' : 'ДОБАВЛЯЕТСЯ'
      mod = 'success'
    } else if (currentBasket > 0 && (!isAddToBasket || isWait || isBookmark)) {
      if (isWait && inDelay) {
        text = isChanged ? 'ИЗМЕНИТЬ' : 'В ОЖИДАНИИ'
      } else {
        const alreadyText = (
          <div className={styles.buttonText}>
            {icon}В {isBookmark ? bookmarkIn : basketIn}
          </div>
        )
        text = isChanged ? 'ИЗМЕНИТЬ' : alreadyText
      }
      mod = isChanged ? 'change' : 'success'
    }

    const isLink = currentBasket > 0 && !isChanged && (!isAddToBasket || isWait)
    const Tag = isLink ? Link : 'button'
    const isDisabledButton = isUpdating || length(carts) === 0
    let prepareParams = {}
    if (isAddToBasket) {
      prepareParams = { values: difference(carts, inBaskets) }
    } else {
      prepareParams = { values: inBaskets }
    }

    if (isWait) {
      prepareParams = { values: [0] }
    }
    let props = isLink
      ? {
        to:
            isWait && inDelay
              ? '/basket/delay'
              : ['/basket']
                .concat(isBookmark ? ['bookmark', bookmark] : ['main', cart])
                .join('/')
      }
      : {
        type: 'button',
        onClick: this.handleChangeBasket(prepareParams),
        disabled: isDisabledButton
      }
    const inAssortment = propOr(true, 'ACTIVE', analogsInfo)
    if (!inAssortment) {
      const isDisabled =
        !propOr(true, 'ISSET_ANALOGS', analogsInfo) ||
        !propOr(true, 'selectable', analogsInfo)
      text = 'ЗАМЕНИТЬ АНАЛОГОМ'
      props = {
        type: 'button',
        onClick: this.props.onShowAnalogs(item.ID),
        disabled: isDisabled
      }
    }

    return (
      <div className={styles.cartWrapper}>
        {!isWait && !isBookmark && (
          <StoreCarts
            baskets={inBaskets}
            cartsActive={carts}
            setCartsActive={this.onSetCartsActive}
          />
        )}
        <Tag
          className={cx(styles.UpdateButton, {
            [styles.bookmark]: isBookmark,
            [styles.disabled]: isDisabledButton,
            [styles[`UpdateButton_${mod}`]]: mod,
            [styles.UpdateButton_showAnalogs]: !inAssortment
          })}
          data-testid='updateButton'
          {...props}
        >
          {text}
        </Tag>
      </div>
    )
  }

  renderOrderPanel() {
    const {
      productId,
      currentStore,
      amount,
      renderButton,
      renderInput,
      goods,
      hasCommonCount,
      isMultiple,
      isNotMultiple,
      isBuyNotMultiple,
      isBookmark,
      isProductCard,
      min,
      remain,
      step
    } = this.props
    const isUpdatingBasket = this.props.isUpdating.basket
    const hasGoods = goods.length !== 0
    const showButton = hasCommonCount && hasGoods
    // фиксируем панель заказа к низу экрана для mobile и tablet
    // только в карточке товара (isProductCard)
    return (
      <div className={cx(styles.Panel, {
        [styles.PanelProductCard]: isProductCard
      })}>
        <div
          className={cx(styles.PanelAction, {
            [styles.Panel_918ok]: hasCommonCount,
            [styles.PanelActionProduct]: isProductCard
          })}
        >
          <div className={cx(styles.PanelContainer, {
            [styles.PanelContainerProduct]: isProductCard
          })}>
            {showButton && (
              <div className={styles.buttonWrapper}>
                <Button
                  color='transparent'
                  size='small'
                >
                  {this.getGoodsCount()}
                </Button>
                <div className={styles.tooltipWrapper}>
                  <BasketTooltip items={goods} />
                </div>
              </div>
            )}
            {!!renderInput && (
              <OrderButton
                amount={amount}
                placeholder={amount}
                dropDownItems={3}
                fixAmount
                fixOutOfLimits={false}
                width='100%'
                min={min}
                max={Math.max(...[min].concat(remain))}
                step={step}
                onFocus={this.handleFocusAmount}
                onBlur={this.handleBlurAmount}
                onClick={this.handleClick}
                onChange={this.handleChangeAmount}
                onKeyUp={this.handleKeyUpAmount}
                disabled={isUpdatingBasket}
                productId={productId}
                currentStore={currentStore}
                isMultiple={isMultiple}
                isNotMultiple={isNotMultiple}
                isBuyNotMultiple={isBuyNotMultiple}
                isBookmark={isBookmark}
                setMultiplicity={this.props.setMultiplicity}
              />
            )}
            {!!renderButton && (
              <div className={cx(styles.ButtonArea, {
                [styles.ButtonAreaProduct]: isProductCard
              })}>
                {is(Function, renderButton)
                  ? renderButton(this.props)
                  : this.renderButton()}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { mod, analogsInfo, renderButton } = this.props

    const inAssortment = propOr(true, 'ACTIVE', analogsInfo)
    return (
      <div
        className={cx(styles.ProductActions, {
          [styles[`ProductActions_${mod}`]]: !!mod
        })}
      >
        <div
          className={styles.ProductOrder}
          ref={this.setActions}
        >
          {inAssortment
            ? this.renderActions()
            : (
              <div className={styles.showAnalogsButton}>
                {renderButton && is(Function, renderButton) ? renderButton(this.props) : this.renderButton()}
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

export default display(ProductActions)
