/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, oneOf, func, bool, number, oneOfType } from 'prop-types'
import { connect } from 'react-redux'
import { animateScroll as scroll } from 'react-scroll'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import { display } from 'decorators/device'
import { getRegistrationAccess } from 'redux/modules/personal'

import styles from './CapOne.scss'

@connect(({ personal }) => ({
  hasAccessRegistration: getRegistrationAccess(personal)
}))
@display
export default class CapOne extends PureComponent {
  static propTypes = {
    multiplicity: oneOfType([string, bool]),
    price: oneOfType([string, number]),
    mode: oneOf(['Base', 'ProductOne', 'ProductOneQuick']),
    hideModal: func,
    showModal: func,
    hasAccessRegistration: bool,
    isDesktop: bool,
    isStoreAvailable: bool
  }

  static defaultProps = {
    mode: 'Base',
    hasAccessRegistration: true,
    hideModal: () => {},
    showModal: () => {}
  }

  handleGoToAuth = () => {
    const { mode, hideModal } = this.props
    if (mode === 'ProductOneQuick') {
      hideModal()
    }
    if (!this.props.isDesktop) {
      this.props.showModal('auth')
    } else {
      scroll.scrollToTop({ duration: 500 })
    }
  }

  handleOpenRegistrationForm = () => this.props.showModal('registration')

  render() {
    const { mode, price, multiplicity, hasAccessRegistration, isStoreAvailable } = this.props
    return (
      <div className={styles.rightColumnOneBase}>
        <div
          className={cx(styles[`capOne${mode}`], {
            [styles.withOutPrice]: !price
          })}
        >
          {!!price && (
            <div className={styles.capPrice}>
              <div className={styles.price}>
                <CurrencyValue price={price}
                  className={styles.currencyValue} />
              </div>
              {multiplicity && (
                <div className={styles.partGroup}>
                  <i className={styles.partIcon}>
                    <span />
                    <span />
                    <span />
                  </i>
                  <span className={styles.batch}>Парт./уп. {multiplicity}</span>
                </div>
              )}
            </div>
          )}
          {isStoreAvailable && (
            <div className={styles.store}>
              <Icon
                icon='store'
                className={styles.store__icon}
              />
              <div className={styles.store__name}>
                Товар на складе: <span className={styles.store__name_bold}>Рязань</span>
              </div>
            </div>
          )}
          <span>
            Чтобы увидеть персональные цены и сделать заказ,
            <br />
            <a role='presentation'
              onClick={this.handleGoToAuth}>
              авторизуйтесь
            </a>
            {hasAccessRegistration && (
              <span className={styles.registrationText}>
                {' '}
                или пройдите{' '}
                <div
                  role='presentation'
                  className={styles.link}
                  onClick={this.handleOpenRegistrationForm}
                >
                  регистрацию
                </div>
              </span>
            )}
          </span>
        </div>
      </div>
    )
  }
}
