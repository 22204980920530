import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Employees = loadable(() => import(/* webpackChunkName: "Employees" */'pages/Employees/Employees'))

export default asyncConnect([
  {
    key: 'pageEmployee',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'employees' })
      return updateData.update()
    }
  }
])(Employees)
