/* eslint-disable complexity */
import { pick, isEmpty, propOr, is, prop } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

const MILLISECONDS_IN_SECOND = 1000
const MINUTES_IN_HOUR = 60
const SECONDS_IN_MINUTE = 60
const HOURS_IN_DAY = 24
const DAY_IN_MONTH = 30
const MONTH =
  DAY_IN_MONTH *
  HOURS_IN_DAY *
  SECONDS_IN_MINUTE *
  MINUTES_IN_HOUR *
  MILLISECONDS_IN_SECOND
const cookieParamsKeys = ['limit', 'sort', 'order']
export const cookieOpts = { path: '/', expires: new Date(Date.now() + MONTH) }
const cookieName = 'params_catalog'

export const initialSort = {
  sort: 'popularity',
  order: 'asc'
}

const initialState = {
  type: '',
  count: 0,
  page: 0,
  limit: 48,
  ...initialSort
}

export const setParams = createAction('productsParams/SET_PARAMS')

const handleSetParams = (state, { getEffects, ...payload }, others) => {
  const newState = { ...state, ...payload }
  const cookieParams = pick(cookieParamsKeys, newState)

  const cookie = prop('cookie', others)

  if (
    propOr('', 'type', payload) !== 'search' &&
    !isEmpty(pick(cookieParamsKeys, payload)) &&
    is(Object, cookie)
  ) {
    cookie.save(cookieName, cookieParams, cookieOpts)
  }

  const effects = is(Function, getEffects)
    ? getEffects(newState)
    : Effects.none()
  return loop(newState, effects)
}

export default createReducer(on => {
  on(setParams, handleSetParams)
}, initialState)
