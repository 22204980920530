// @ts-nocheck
import React, { FC, FormEventHandler } from 'react'

import { compose, has, path } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { Field, reduxForm } from 'redux-form'

import Picture from 'components/Picture'
import {
  currentContractorNameSelector,
  getUserEmail
} from 'redux/modules/personal'
import Button from 'UI/Button'
import Text from 'UI/Text'
import { FieldTextarea } from 'UI/Textarea'

import { names } from './constants'
import RadioGroup from './RadioGroup'
import s from './ReviewButton.scss'
import submit from './submit'

interface IFormComponent {
  contractorName: string
  clientApi: object
  email: string
  handleSubmit: FormEventHandler<HTMLFormElement>
  invalid: boolean
  product: object
  submitting?: boolean
}

const FormComponent: FC<IFormComponent> = ({
  contractorName,
  clientApi,
  email,
  handleSubmit,
  invalid,
  product,
  submitting
}) => (
  <form className={s.form}
    onSubmit={handleSubmit(submit({ product, contractorName, email, clientApi }))}
  >
    <div className={s.form__item}>
      <Text semibold>Товар</Text>
      <div className={s.form__itemProduct}>
        <Picture
          backgroundSize='contain'
          width={25}
          height={25}
          src={path(['PREVIEW_PICTURE_PATH'], product)}
          isLazy={false}
        />
        <Text>{path(['NAME'], product)}</Text>
      </div>
    </div>
    <Text>
      Делитесь с нами отзывом о товаре в форме, расположенной ниже. Ваш отзыв
      увидят только наши сотрудники.
      <br />
      Если у вас есть претензии по количеству или качеству, рекомендуем
      воспользоваться разделом “обращения” в вашем Личном кабинете.
    </Text>
    <div className={s.form__item}>
      <Text semibold>Оцените товар</Text>
      <Field name={names.rating}
        component={RadioGroup} />
    </div>
    <Text semibold>Расскажите подробнее</Text>
    <div className={s.form__item}>
      <Text semibold>Достоинства товара</Text>
      <Field name={names.advantages}
        placeholder='Что понравилось'
        component={FieldTextarea} />
    </div>
    <div className={s.form__item}>
      <Text semibold>Недостатки товара</Text>
      <Field name={names.disadvantages}
        placeholder='Что не понравилось'
        component={FieldTextarea} />
    </div>
    <div className={s.form__item}>
      <Text semibold>Комментарий</Text>
      <Field name={names.comment}
        placeholder='Впечатления'
        component={FieldTextarea} />
    </div>
    <Button type='submit'
      disabled={submitting || invalid}>
      Отправить
    </Button>
  </form>
)

export default compose(
  reduxForm({
    form: 'leaveAFeedbackForm',
    validate: values => {
      const errors = {
        [names.rating]: ''
      }
      if (!has(names.rating, values)) errors[names.rating] = 'Обязательное поле'
      return errors
    }
  }),
  connect(({ personal }) => ({
    contractorName: currentContractorNameSelector(personal),
    email: getUserEmail(personal)
  })),
  inject(({ helpers: { clientApi } }) => ({ clientApi }))
)(FormComponent)
