import React, { FC } from 'react'

import Modal from 'containers/Modal'

import FormShipment from './FormShipment'

interface ShipmentModalProps {
  [key: string]: React.ReactNode
}

const ShipmentModal: FC<ShipmentModalProps> = props => (
  /* @ts-ignore */
  <Modal id={`shipmentForm-${props.shipmentId}`}
    title='Оцените доставку'>
    <FormShipment
      {...props}
    />
  </Modal>
)

export default ShipmentModal
