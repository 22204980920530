import React from 'react'

import { number, bool } from 'prop-types'

import RubleSign from 'components/RubleSign'

import s from './NotMultiple.scss'

const NotMultiple = ({ price = 0, active = false, isPoints = false }) => {
  if (!active || price === 0) return null
  const text = `+${price} ${
    isPoints ? 'Б' : '₽'
  } к цене товара за  \nпокупку некратно упаковке`
  return (
    <div className={s.notMultiple}
      title={text}>
      +{price} {isPoints ? 'Б' : <RubleSign />}
    </div>
  )
}

NotMultiple.propTypes = {
  price: number,
  active: bool,
  isPoints: bool
}

export default NotMultiple
