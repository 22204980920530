import loadable from '@loadable/component'
import { path } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Document = loadable(() =>
  import(/* webpackChunkName: "Document" */ 'pages/Document/Document')
)

export default asyncConnect([
  {
    key: 'pageDocument',
    promise: ({ helpers: { updateData }, match }) => {
      const slug = path(['params', 'slug'], match)
      updateData.set({ view: 'document', url: `personal/${slug}` })

      return updateData.update()
    }
  }
])(Document)
