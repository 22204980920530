import { compose, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createSelector } from 'reselect'

import { NoticeAlertCatalog } from 'components/Notice'
import { forAuth } from 'decorators/user'
import { showModal, hideModal } from 'redux/modules/modal'
import {
  getNotices,
  getNoticeIsOpen,
  setNoticeStatus
} from 'redux/modules/notice'

const productsSelector = createSelector(
  state => state,
  state => pathOr({}, ['itemsById'], state)
)

export default compose(
  connect(
    ({ productList, notice }) => ({
      items: getNotices({ type: 'alert', page: 'catalog', notice }),
      products: productsSelector(productList),
      isOpen: getNoticeIsOpen({ type: 'alert', page: 'catalog', notice })
    }),
    dispatch =>
      bindActionCreators(
        {
          setNoticeStatus,
          showModal,
          hideModal
        },
        dispatch
      )
  ),
  forAuth
)(NoticeAlertCatalog)
