import React, { PureComponent } from 'react'

import cx from 'classnames'
import {
  number,
  string,
  oneOfType,
  func,
  oneOf,
  bool,
  object
} from 'prop-types'
import {
  clamp,
  compose,
  replace,
  pathOr,
  length,
  filter,
  not,
  equals,
  dec,
  inc,
  uniq
} from 'ramda'

import Portal from 'components/Portal'
import { display } from 'decorators/device'
import { isMultipleBuy } from 'helpers/products'

import styles from './OrderButton.scss'

const MAX_NUMBER = 9999999
const NUMBER_2 = 2

class OrderButton extends PureComponent {
  static propTypes = {
    amount: oneOfType([string, number]),
    placeholder: oneOfType([string, number]),
    width: oneOfType([number, string]),
    onChange: func,
    onKeyUp: func,
    onFocus: func,
    onBlur: func,
    onClick: func,
    setMultiplicity: func,
    step: number,
    productId: string,
    max: number,
    min: number,
    mode: oneOf(['Base', 'Basket', 'Code', 'LoyaltyTarget']),
    dropDownItems: number,
    currentStore: object,
    disabled: bool,
    fixOutOfLimits: bool,
    isBuyNotMultiple: bool,
    fixAmount: bool,
    isLoyalty: bool,
    isNotMultiple: bool,
    isDesktop: bool,
    emptyAmountChar: string
  }

  static defaultProps = {
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onClick: () => {},
    fixAmount: false,
    isNotMultiple: false,
    isBuyNotMultiple: false,
    step: 1,
    amount: 0,
    min: 1,
    max: MAX_NUMBER,
    width: 220,
    mode: 'Base',
    dropDownItems: 2,
    fixOutOfLimits: false,
    emptyAmountChar: ''
  }

  constructor(props) {
    super(props)
    this.dropdown = React.createRef()
    this.orderButtonWrapper = React.createRef()
  }

  state = {
    dropDownVisible: false,
    width: 0
  }

  componentDidMount() {
    window.addEventListener('mousedown', this.handleMousedownOutside)
    window.addEventListener('scroll', this.handleHideDropdown)
    window.addEventListener('resize', this.handleHideDropdown)
    this.setState({
      width: this.orderButtonWrapper.current.getBoundingClientRect().width
    })
  }

  componentDidUpdate(prevProps) {
    if (
      not(equals(this.props.disabled, prevProps.disabled)) &&
      this.props.disabled
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ dropDownVisible: false })
    }
    if (this.dropdown && this.dropdown.current) {
      const coords = this.orderButtonWrapper.current.getBoundingClientRect()

      this.dropdown.current.style.left = `${coords.left}px`
      this.dropdown.current.style.top = `${coords.bottom + NUMBER_2}px`
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.handleMousedownOutside)
    window.removeEventListener('scroll', this.handleHideDropdown)
    window.removeEventListener('resize', this.handleHideDropdown)
  }

  handleHideDropdown = () => {
    if (!this.state.dropDownVisible) return

    this.setState({ dropDownVisible: false })
  }

  handleMousedownOutside = event => {
    if (
      !this.state.dropDownVisible ||
      !this.orderButtonWrapper.current ||
      !this.dropdown.current
    )
      return

    if (
      !this.orderButtonWrapper.current.contains(event.target) &&
      !this.dropdown.current.contains(event.target)
    ) {
      this.setState({ dropDownVisible: false })
    }
  }

  handleChange = value => {
    let amount = value
    if (amount === '') {
      amount = 0
    }
    this.props.onChange(parseFloat(amount), this.isCorrectValue(amount))
  }

  handleChangeAmount = step => {
    const { amount, min, max } = this.props
    if (amount < 0) {
      return
    }

    let value
    if (amount === min && max > 0 && max < min && step < 0) {
      value = max
    } else {
      const correctValue = this.getCorrectValue(amount)
      value = clamp(
        Math.min(...[min].concat(max)),
        Math.max(...[min].concat(max)),
        correctValue === amount
          ? correctValue + step
          : correctValue + (amount > correctValue ? Math.max(step, 0) : 0)
      )
    }
    this.handleChange(value)
  }

  handleInput = event => {
    if (!this.state.dropDownVisible && this.props.dropDownItems) {
      this.setState({ dropDownVisible: true })
    }
    const amount = compose(
      parseInt,
      replace(/\D/g, ''),
      pathOr('0', ['target', 'value'])
    )(event)

    this.handleChange(amount || 0)
  }

  handleFocus = event => {
    this.setState({ dropDownVisible: true })
    event.target.select()
    this.props.onFocus(event)
  }

  handleClick = event => this.props.onClick(event)

  handleBlur = event => {
    event.persist()
    const { min } = this.props
    let value = this.getFixedValue(this.props.amount)
    if (Number.isNaN(Number(value))) {
      value = min
    }
    const isCorrect = this.isCorrectValue(this.props.amount)
    this.handleChange(value)
    this.props.onBlur(event, value, isCorrect)
  }

  handleKeyDown = event => {
    if (event.key === 'Enter') {
      event.preventDefault()
      this.props.onBlur(event, this.props.amount, true)
    }

    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
      event.preventDefault()
      const delta = event.key === 'ArrowUp' ? this.getStep() : -this.getStep()
      this.handleChangeAmount(delta)
    }
  }

  handleKeyUp = event => {
    if (typeof this.props.onKeyUp === 'function') {
      const value = this.getFixedValue(this.props.amount)
      this.props.onKeyUp(event, value, this.isCorrectValue(value))
    }
  }

  handleClickDropdown = value => event => {
    this.setState({ dropDownVisible: false })
    this.handleChange(value)
    this.props.onBlur(event, value, true)
  }

  handleIncByStep = () => {
    const { amount } = this.props
    const next = this.getNextMultiplyValue(inc(amount), inc)
    this.handleChange(next)
  }

  handleDecByStep = () => {
    const { amount } = this.props
    const next = this.getNextMultiplyValue(dec(amount), dec)
    this.handleChange(next)
  }

  getCorrectValue = amount => {
    const { min, max, currentStore } = this.props
    if (max && ((max <= min && amount !== max) || amount > max)) {
      return max
    }
    if (min && amount <= min) {
      return min
    }
    const countStatus = isMultipleBuy(amount, currentStore)

    if (countStatus.isBuy) {
      return amount
    }
    return this.getNextValue(amount, dec)
  }

  getFixedValue = amount => {
    const {
      fixOutOfLimits,
      fixAmount,
      step,
      isBuyNotMultiple,
      productId,
      min,
      max
    } = this.props
    /**
     * Если ввели некратное количество, то сбрасываем до ближайщего меньшего кратного количества
     */
    let value = amount
    if (value % step !== 0 && amount !== max) {
      if (!isBuyNotMultiple) {
        value -= value % step
      } else {
        this.props.setMultiplicity({ id: productId, value: false })
      }
    }

    if (!fixAmount && !fixOutOfLimits) {
      return value
    }

    if (amount > max) {
      return amount
    }

    return fixAmount
      ? this.getCorrectValue(value)
      : clamp(
        Math.min(...[min].concat(max)),
        Math.max(...[min].concat(max)),
        value
      )
  }

  getNextValue = (value, action = inc) => {
    const { max, currentStore } = this.props
    if (value === 0 || value < 0) {
      return 0
    }
    if (value >= max) {
      return max
    }
    const countStatus = isMultipleBuy(value, currentStore)
    if (countStatus.isBuy) {
      return value
    }
    return this.getNextValue(action(value), action)
  }

  getNextMultiplyValue = (value, action = inc) => {
    const { min, max, currentStore } = this.props

    if (value <= 0) {
      return 0
    }
    if (value <= min) {
      return min
    }
    if (value >= max) {
      return max
    }
    const countStatus = isMultipleBuy(value, currentStore)
    if (countStatus.isBuy && countStatus.isMultiple) {
      return value
    }
    return this.getNextMultiplyValue(action(value), action)
  }

  getDropdownItems = () => {
    const { amount, min, max } = this.props
    const prev = this.getNextMultiplyValue(dec(amount), dec)
    const current = this.getNextMultiplyValue(amount, inc)
    const next = this.getNextMultiplyValue(inc(current), inc)
    return compose(
      uniq,
      filter(item => !!item),
      filter(item =>
        clamp(
          Math.min(...[min].concat(max)),
          Math.max(...[min].concat(max)),
          item
        )
      )
    )([prev, current, next])
  }

  getStep = () => (this.props.isNotMultiple ? 1 : this.props.step)

  isCorrectValue = amount => {
    const { currentStore, min, max } = this.props

    if (amount > max && amount > min) {
      return true
    }

    if (max === amount && max > 0 && max < min) {
      return true
    }
    if (
      (max && max <= min && amount !== max) ||
      (min && amount < min) ||
      (max && amount > max)
    ) {
      return false
    }
    if (max && max === amount) {
      return true
    }
    const countStatus = isMultipleBuy(amount, currentStore)
    if (countStatus.isBuy) {
      return true
    }
    return true
  }

  renderDropDown() {
    const { isDesktop } = this.props
    const { width, dropDownVisible } = this.state
    if (!dropDownVisible || !isDesktop) return null
    const items = this.getDropdownItems()
    if (length(items) === 0) return null
    return (
      <Portal>
        <div
          className={styles.orderButtonDropDown}
          ref={this.dropdown}
          style={{ width }}
        >
          {items.map(item => (
            <div
              key={item}
              value={item}
              role='presentation'
              className={styles.orderButtonItem}
              onClick={this.handleClickDropdown(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </Portal>
    )
  }

  render() {
    const {
      amount,
      width,
      mode,
      disabled,
      placeholder,
      isLoyalty,
      emptyAmountChar,
      min,
      max
    } = this.props
    const value = parseInt(amount, 10) && amount >= 0 ? amount : emptyAmountChar
    const disabledLess = amount <= min || disabled
    const disabledMore = max <= 0 || (max > 0 && amount >= max) || disabled

    return (
      <div className={styles.orderButtonWrapper}
        ref={this.orderButtonWrapper}>
        <div
          className={cx(styles.orderButton, {
            [styles.orderButtonLoyalty]: isLoyalty || mode === 'LoyaltyTarget'
          })}
          style={{ width }}
        >
          {!!placeholder && (
            <div className={styles.placeholder}>{placeholder}</div>
          )}
          <input
            className={cx(styles[`orderInput${mode}`], {
              [styles.orderInputEmpty]: !value && value !== 0,
              [styles.orderInputLoyalty]: isLoyalty,
              [styles.orderInput_incorrect]: !this.isCorrectValue(amount)
            })}
            onChange={this.handleInput}
            onFocus={this.handleFocus}
            onBlur={this.handleBlur}
            onClick={this.handleClick}
            onKeyDown={this.handleKeyDown}
            onKeyUp={this.handleKeyUp}
            maxLength={7}
            value={value}
            disabled={disabled}
          />
          <button
            type='button'
            className={cx(styles.deltaBtn, styles[`buttonLeft${mode}`], {
              [styles.buttonLeftDisabled]: disabledLess,
              [styles[`buttonLeft${mode}Loyalty`]]: isLoyalty
            })}
            disabled={disabledLess}
            onClick={this.handleDecByStep}
          >
            &ndash;
            <span
              className={cx(styles.buttonLeftHover, {
                [styles.buttonLeftHoverLoyalty]: isLoyalty
              })}
            />
          </button>
          <button
            type='button'
            className={cx(styles.deltaBtn, styles[`buttonRight${mode}`], {
              [styles.buttonRightDisabled]: disabledMore,
              [styles[`buttonRight${mode}Loyalty`]]: isLoyalty
            })}
            disabled={disabledMore}
            onClick={this.handleIncByStep}
          >
            +
            <span
              className={cx(styles.buttonRightHover, {
                [styles.buttonRightHoverLoyalty]: isLoyalty
              })}
            />
          </button>
        </div>
        {!isLoyalty && this.renderDropDown()}
      </div>
    )
  }
}

export default display(OrderButton)
