import React, { PureComponent } from 'react'

import cx from 'classnames'
import { oneOfType, string, object, array } from 'prop-types'

import styles from './RubleSign.scss'

class RubleSign extends PureComponent {
  static propTypes = {
    className: oneOfType([string, object, array])
  }

  render() {
    const { className } = this.props
    return <span className={cx(styles.RubleSign, className)}>₽</span>
  }
}

export default RubleSign
