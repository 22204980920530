/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { number, string, object } from 'prop-types'
import { path, pathOr } from 'ramda'

import Icon from 'components/Icon'
import config from 'config'
import formatAmount from 'utils/formatAmount'
import noun from 'utils/noun'

import styles from './HeaderGift.scss'

const NUMBER_99 = 99

export default class HeaderGift extends PureComponent {
  static propTypes = {
    itemsCount: number,
    itemsSum: number,
    className: string,
    status: object,
    theme: string
  }

  render() {
    const { itemsCount, itemsSum, className, status, theme } = this.props
    const points = `${noun(itemsSum, ['балл', 'балла', 'баллов'])}`
    const classes = cx(styles.cart, className, {
      [styles.disabledCard]:
        !path(['ACCESSES', 'ACCESS'], status) || itemsCount < 1
    })
    const loyaltyIcon = pathOr(
      '',
      ['themes', theme, 'icons', 'loyaltyCart'],
      config
    )

    return (
      <div className={classes}>
        <div className={styles.wrapper}>
          <div className={styles.iconWrapper}>
            <Icon
              icon={loyaltyIcon}
              className={cx({
                [styles.icon]: true,
                [styles.iconGray]: itemsSum === 0
              })}
              isToggler
              isActive={!!itemsSum}
              size='auto'
            />
            {itemsSum !== 0 && (
              <div className={styles.basketCount}>
                {itemsCount > NUMBER_99 ? '+99' : itemsCount}
              </div>
            )}
          </div>
          <div className={styles.wrapPrice}>
            {itemsSum === 0 ? (
              'Корзина пуста'
            ) : (
              <span>
                {formatAmount(itemsSum)} {points}
              </span>
            )}
          </div>
        </div>
      </div>
    )
  }
}
