import React from 'react'

import cx from 'classnames'
import { node, string, bool } from 'prop-types'

import Wrapper from 'components/Wrapper'

import styles from './Content.scss'

const Content = props => {
  return (
    <Wrapper className={cx({ [styles.wrapperMainMenu]: props.isMainMenu })}>
      <div
        className={cx(styles.content, {
          [props.className]: props.className,
          [styles.content_bgWhite]: props.isWhite,
          [styles.content_isLoading]: props.isLoading,
          [styles.content_mainMenu]: props.isMainMenu
        })}
      >
        {props.children}
      </div>
    </Wrapper>
  )
}

Content.propTypes = {
  children: node,
  className: string,
  isMainMenu: bool,
  isWhite: bool,
  isLoading: bool
}

export default Content
