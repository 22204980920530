import { prop, map, pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = state => state.isLoaded

export const getIsLoading = state => state.isLoading

export const getBookmarks = state => state.bookmarks

export const getErrorsBookmarks = state => state.errors

export const getWarningsBookmarks = state => state.warnings

export const bookmarkNameSelector = createSelector(
  state => state,
  state => pathOr('', ['bookmarks', 0, 'NAME'], state)
)

export const bookmarksCatalogSelector = createSelector(
  bookmarks => prop('bookmarks', bookmarks),
  bookmarks => [
    {
      ID: 'bookmark',
      NAME: 'Закладки',
      PICTURE_PATH: '/images/catalog/bookmark.png',
      SORT: 999999,
      isBookmark: true,
      items: map(
        bookmark => ({
          ID: prop('ID', bookmark),
          NAME: prop('NAME', bookmark),
          PICTURE_PATH: '',
          SORT: 0,
          isBookmark: true,
          link: `/bookmark/${prop('ID', bookmark)}/catalog`
        }),
        bookmarks
      ),
      link: '/bookmark'
    }
  ]
)
