import { compose, map, prop } from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'
import { snakeToCamel } from 'helpers/toCamelCase'

export const getInvoice = createSelector(
  state => state,
  state => prop('invoice', state)
)

export const getProducts = createSelector(
  state => state,
  state => prop('products', state)
)

export const getIsLoading = createSelector(
  state => state,
  state => prop('isLoading', state)
)

export const getIsLoaded = createSelector(
  state => state,
  state => prop('isLoaded', state)
)

export const getSortList = createSelector(
  state => state,
  state =>
    compose(
      map(item =>
        renameKeys(snakeToCamel)({
          ...item,
          value: `${prop('SORT', item)}_${prop('ORDER', item)}`
        })
      ),
      prop('sortList')
    )(state)
)

export const getCount = createSelector(
  state => state,
  state => prop('count', state)
)

export const getWithoutInfo = createSelector(
  state => state,
  state => prop('withoutInfo', state)
)

export const getIsSubmitting = createSelector(
  state => state,
  state => prop('isSubmitting', state)
)

export const getIsError = createSelector(
  state => state,
  state => prop('isError', state)
)

export const getCreateErrors = createSelector(
  state => state,
  state => prop('createErrors', state)
)
