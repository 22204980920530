/* eslint-disable complexity */
import {
  path,
  pathOr,
  prop,
  propOr,
  curry,
  map,
  isEmpty,
  reduce,
  equals,
  compose,
  values,
  sortBy,
  toLower,
  filter,
  not,
  last,
  gt,
  and,
  addIndex,
  dec,
  inc,
  lte,
  length,
  gte,
  or,
  propEq
} from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'
import formatAmount from 'utils/formatAmount'
import moment from 'utils/moment'
import noun from 'utils/noun'

export const getIsLoaded = curry((pathName, state) =>
  pathName ? path([pathName, 'isLoaded'], state) : path(['isLoaded'], state)
)
const NUMBER_100 = 100
const MILLISECONDS_IN_SECOND = 1000

export const getDate = date =>
  moment(date * MILLISECONDS_IN_SECOND)
    .locale('ru')
    .format('DD MMMM YYYY')

export const getDateLocal = date =>
  moment(date * MILLISECONDS_IN_SECOND)
    .locale('ru')
    .format('L')

const getMonth = date =>
  moment(date * MILLISECONDS_IN_SECOND)
    .locale('ru')
    .format('MMMM YYYY')
    .replace('.', '')

export const pointsToText = points =>
  `${formatAmount(points)} ${noun(points, ['балл', 'балла', 'баллов'])}`

export const getBalance = createSelector(
  state => propOr(0, 'pointsLoyalty', state),
  points => pointsToText(points)
)

export const getForecastObj = createSelector(
  state => propOr({}, 0, state),
  expiration => {
    const dateExpiration = propOr(0, 'DATE_EXPIRATION', expiration)
    const balance = propOr(0, 'BALANCE_AFTER', expiration)
    const sum = propOr(0, 'SUM', expiration)
    return {
      dateExpiration: dateExpiration ? getDate(dateExpiration) : '',
      balanceAfter: pointsToText(balance),
      sum: pointsToText(sum)
    }
  }
)

export const getExpirationInfo = createSelector(
  state => state,
  state =>
    reduce(
      (acc, item) => {
        const date = propOr('', 'DATE_EXPIRATION', item)
        const month = date ? getMonth(date) : ''
        const dateExpiration = propOr(0, 'DATE_EXPIRATION', item)
        const dateLocal = dateExpiration ? getDateLocal(dateExpiration) : ''
        const balance = propOr(0, 'BALANCE_AFTER', item)
        const sum = propOr(0, 'SUM', item)

        if (acc.some(e => equals(prop('month', e), month))) {
          const monthObject = acc.find(e => equals(prop('month', e), month))
          const monthArray = prop('dateInfo', monthObject)
          monthArray.push({ dateLocal, balance, sum })
          return acc
        }
        return [...acc, { month, dateInfo: [{ dateLocal, balance, sum }] }]
      },
      [],
      state
    )
)

export const getDefaultExpirationInfo = createSelector(
  state => state,
  state =>
    map(
      item => ({
        month: item,
        balanceAfter: '0',
        sum: 0
      }),
      state
    )
)

export const getAvailableTargets = createSelector(
  state => state,
  state => pathOr([], ['availableTargets', 'items'], state)
)

const getDaysLoyaltyRemain = items => {
  const timeNow = moment()
  const timeEnd = moment(path([0, 'DATE_EXPIRATION'], items), 'X')
  const days =
    timeEnd && timeEnd - timeNow > 0 ? timeEnd.diff(timeNow, 'days') : ''

  return days
}

const personalSelector = createSelector(
  state => state,
  state => prop('personal', state)
)

const loyaltySelector = createSelector(
  state => state,
  state => prop('loyalty', state)
)

export const expirationSelector = createSelector(
  state => state,
  state => pathOr([], ['expiration', 'items'], state)
)

export const loyaltyHeaderSelector = createSelector(
  personalSelector,
  loyaltySelector,
  (personal, loyalty) => {
    const points =
      pathOr(0, ['status', 'BALANCE', 'CURRENT'], loyalty) ||
      propOr(0, 'pointsLoyalty', personal)
    // loyalty target info
    const loyaltyTarget = propOr({}, 'target', loyalty)
    const loyaltyTargetItem = propOr({}, 'item', loyaltyTarget)
    const isTargetLoaded = propOr(false, 'isLoaded', loyaltyTarget)
    const price = parseInt(prop('PRICE', loyaltyTargetItem), 10)
    const count = parseInt(prop('TARGET', loyaltyTargetItem), 10)
    const needPoints = price * count
    const delta = needPoints - points < 0 ? 0 : needPoints - points
    const needPointToText = pointsToText(delta)
    // loyalty flame info
    const loyaltyStatus = propOr({}, 'status', loyalty)
    const loyaltyStatusTitle = pathOr('', ['CARD', 'NAME'], loyaltyStatus)
    const loyaltyStatusCode = pathOr('', ['CARD', 'CODE'], loyaltyStatus)
    const isLoyaltyMember = path(['status', 'ACCESSES', 'MEMBER'], loyalty)
    const loyaltyExpiration = pathOr([], ['expiration', 'items'], loyalty)
    const daysLoyaltyRemain = getDaysLoyaltyRemain(loyaltyExpiration)
    const isLoyaltyRemainActive = isLoyaltyMember && daysLoyaltyRemain > 0

    // balance expiration info
    const dateExpiration = pathOr(
      '',
      ['expiration', 'items', 0, 'DATE_EXPIRATION'],
      loyalty
    )
    const sumExpiration = pathOr(0, ['expiration', 'items', 0, 'SUM'], loyalty)
    return {
      morePoints: `Нужно еще ${needPointToText}`,
      pointsDimension: `${noun(points, ['балл', 'балла', 'баллов'])}`,
      points: formatAmount(points),
      delta,
      imageSrc: propOr('', 'PREVIEW_PICTURE_PATH', loyaltyTargetItem),
      target: propOr('', 'NAME', loyaltyTargetItem),
      hasGift: !isEmpty(loyaltyTargetItem),
      isShowTarget: isTargetLoaded && !isEmpty(loyaltyTargetItem),
      isTargetLoaded,
      isExpirationLoaded: pathOr(false, ['expiration', 'isLoaded'], loyalty),
      daysLoyaltyRemain,
      isLoyaltyRemainActive,
      statusCode: loyaltyStatusCode && loyaltyStatusCode.toLowerCase(),
      statusText:
        isLoyaltyMember && !isEmpty(loyaltyStatusTitle)
          ? loyaltyStatusTitle
          : 'Мой статус',
      dateExpiration: getDate(dateExpiration),
      sumExpiration: formatAmount(sumExpiration)
    }
  }
)

export const getCurrentCard = createSelector(
  state => state,
  state => {
    const currentCard = pathOr({}, ['status', 'CARD'], state)
    const currentSku = propOr(0, 'SKU', currentCard)
    const currentSum = propOr(0, 'SUM', currentCard)
    const currentStatusName = propOr('', 'NAME', currentCard)
    const currentStatusDate = propOr(0, 'DATE_UPDATE', currentCard)
    const currentGuid = propOr(0, 'CURRENT', currentCard)
    return {
      currentSku,
      currentSum,
      currentStatusName,
      currentStatusDate: currentStatusDate
        ? getDateLocal(currentStatusDate)
        : 0,
      currentGuid
    }
  }
)

export const getLoyaltyCards = createSelector(
  state => state,
  getCurrentCard,
  (state, current) => {
    const currentSku = prop('currentSku', current)
    const currentSum = prop('currentSum', current)
    return compose(
      addIndex(map)((card, index, cards) => {
        const sku = prop('sku', card)
        const sum = prop('sum', card)
        const isLastCard = equals(index, dec(length(cards)))
        const maxSku = isLastCard ? sku : 0
        const maxSum = isLastCard ? sum : 0
        const progressSku = and(isLastCard, gte(currentSku, sku))
          ? NUMBER_100
          : Math.round((currentSku / sku) * NUMBER_100)
        const progressSum = and(isLastCard, gte(currentSum, sum))
          ? NUMBER_100
          : Math.round((currentSum / sum) * NUMBER_100)
        const isShowSku = or(
          and(
            lte(propOr(0, 'sku', cards[dec(index)]), currentSku),
            lte(currentSku, sku)
          ),
          and(isLastCard, equals(progressSku, NUMBER_100))
        )
        const isShowSum = or(
          and(
            lte(propOr(0, 'sum', cards[dec(index)]), currentSum),
            lte(currentSum, sum)
          ),
          and(isLastCard, equals(progressSum, NUMBER_100))
        )
        const firstCardSku = prop('sku', cards[inc(index)])
        const firstCardSum = prop('sum', cards[inc(index)])

        return {
          ...card,
          index,
          isShowSku,
          isShowSum,
          sku: equals(index, 0) ? firstCardSku : sku,
          sum: equals(index, 0) ? firstCardSum : sum,
          progressSku: isShowSku ? progressSku : 0,
          progressSum: isShowSum ? progressSum : 0,
          isLastCard,
          maxSku,
          maxSum
        }
      }),
      filter(card => not(propEq('code', 'Корпоративная')(card))),
      map(renameKeys(toLower)),
      sortBy(prop('SKU')),
      values,
      pathOr([], ['status', 'ENTITIES', 'CARDS'])
    )(state)
  }
)

export const getLastCompletedGuid = createSelector(
  getLoyaltyCards,
  getCurrentCard,
  (cards, current) =>
    compose(
      prop('guid'),
      last,
      filter(
        card =>
          gt(prop('sku', card), 0) &&
          gt(prop('sum', card), 0) &&
          lte(prop('sku', card), prop('currentSku', current)) &&
          lte(prop('sum', card), prop('currentSum', current)) &&
          not(equals(prop('guid', card), prop('currentGuid', current)))
      )
    )(cards)
)

export const getChangeStatusSending = createSelector(
  state => state,
  state => propOr(false, 'changeStatusSending', state)
)

export const changeStatusSuccessSelector = createSelector(
  state => state,
  state => propOr(false, 'changeStatus', state)
)

export const currentMonthItemsSelector = createSelector(
  state => pathOr([], ['cashback', 'currentMonth', 'items'], state),
  items => filter(item => propOr(false, 'SITE', item), items)
)

export const itemsFromManagerSelector = createSelector(
  state => pathOr([], ['cashback', 'currentMonth', 'items'], state),
  items => filter(item => !propOr(false, 'SITE', item), items)
)

export const feautureMonthItemsSelector = createSelector(
  state => state,
  state => pathOr([], ['cashback', 'feautureMonth', 'notChecked'], state)
)

export const selectedItemIdsSelector = createSelector(
  state => state,
  state => pathOr([], ['cashback', 'feautureMonth', 'selectedItems'], state)
)

export const checkedItemIdsSelector = createSelector(
  state => state,
  state => pathOr([], ['cashback', 'feautureMonth', 'checkedItems'], state)
)

export const availableItemsCountSelector = createSelector(
  state => state,
  state => pathOr(0, ['cashback', 'feautureMonth', 'cnt'], state)
)

export const isLoadingCashbackSelector = createSelector(
  state => state,
  state => pathOr(false, ['cashback', 'isLoading'], state)
)

export const isLoadingSetActionSelector = createSelector(
  state => state,
  state => pathOr(false, ['cashback', 'isLoadingSetAction'], state)
)

export const setCaskbackError = createSelector(
  state => state,
  state => pathOr('', ['cashback', 'error'], state)
)

export const cashbackDatesSelector = createSelector(
  (state, period) => pathOr('', ['cashback', period, 'dateFrom'], state),
  (state, period) => pathOr('', ['cashback', period, 'dateTo'], state),
  (dateFrom, dateTo) => `с ${dateFrom} до ${dateTo}`
)

export const loyaltyAccessSelector = createSelector(
  state => state,
  state => pathOr(false, ['status', 'ACCESSES', 'ACCESS'], state)
)

export const sectionsSelector = createSelector(
  state => path(['sections', 'items'], state),
  items => {
    const sections = sortBy(prop('SORT'), values(items))
    const productsCount = sections.reduce(
      (acc, item) => acc + compose(Number, propOr(0, 'COUNT'))(item),
      0
    )
    sections.unshift({
      ID: '',
      COUNT: productsCount,
      NAME: 'Все подарки'
    })
    return sections
  }
)