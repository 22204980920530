import React from 'react'

import { element } from 'prop-types'

const LayoutMobile = ({ children }) => (
  <main>{children}</main>
)

LayoutMobile.propTypes = {
  children: element.isRequired
}

export default LayoutMobile
