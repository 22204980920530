/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import pt from 'prop-types'
import LazyLoad from 'react-lazyload'

import ProductCard from './ProductCard/ProductCard'
import ProductList from './ProductList/ProductList'
import ProductTable from './ProductTable/ProductTable'

class Product extends PureComponent {
  static propTypes = {
    type: pt.string,
    itemType: pt.string,
    waitList: pt.bool,
    search: pt.bool,
    isLoyalty: pt.bool,
    isDesktop: pt.bool,
    isLoyaltyBasket: pt.bool,
    isLoyaltyProduct: pt.bool,
    isBookmark: pt.bool,
    bookmark: pt.number,
    autoHeight: pt.bool,
    isQuickView: pt.bool
  }

  static defaultProps = {
    type: 'card',
    itemType: 'section',
    isLoyalty: false,
    isLoyaltyProduct: false,
    isLoyaltyBasket: false,
    isBookmark: false,
    bookmark: 0,
    isQuickView: false
  }

  state = {
    TagLazy: typeof window === 'undefined' ? 'div' : LazyLoad
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }
  
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }
  
  handleKeyDown = event => {
    // для поиска CTRL+F на странице товаров убираем LazyLoad
    if (event.ctrlKey && event.key === 'f') {
      this.setState({
        TagLazy: 'div'
      })
    }
  }
  
  render() {
    const { type, itemType } = this.props
    const { TagLazy } = this.state 

    switch (type) {
      case 'list':
        return (
          <TagLazy height={200}
            offset={500}
            once>
            <ProductList {...this.props}
              type={itemType} />
          </TagLazy>
        )

      case 'table':
        return (
          <TagLazy height={220}
            offset={300}
            once>
            <ProductTable {...this.props}
              type={itemType} />
          </TagLazy>
        )

      case 'card':
        return (
          <TagLazy height={200}
            offset={500}
            once>
            <ProductCard {...this.props}
              type={itemType} />
          </TagLazy>
        )

      default:
        return null
    }
  }
}

export default Product
