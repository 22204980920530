/* eslint-disable complexity */
import React, { FC, useEffect } from 'react'

import styles from './ContentWrapper.scss'

const TIMEOUT_1000 = 1000

interface IContentWrapper {
  html: string
}

const ContentWrapper: FC<IContentWrapper> = ({ html = '' }) => {
  useEffect(() => {
    const timeoutEl = setTimeout(() => {
      if (typeof document !== 'undefined') {
        const script =
          document.getElementById('content_script') &&
          document.getElementById('content_script')!.getAttribute('src')
            ? document.getElementById('content_script')!.getAttribute('src')
            : false
        if (script) {
          const scriptEl = document.createElement('script')
          scriptEl.setAttribute('type', 'text/javascript')
          scriptEl.setAttribute('src', script)
          document.head.appendChild(scriptEl)
        }
      }
    }, TIMEOUT_1000)
    return () => clearTimeout(timeoutEl)
  }, [])
  return (
    <div
      className={styles.content}
      dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
    />
  )
}

export default ContentWrapper
