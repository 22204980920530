import R, { prop, lensPath, compose, set, pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

export {
  getIsLoaded,
  getIsLoading,
  getNewsContent,
  itemsSelector,
  navSelector,
  aggregationsSelector,
  itemSelector,
  metaItemSelector,
  loadingSelector,
  loadedSelector,
  homeContent,
  documentSectionsSelector,
  contentMenuItemsSelector,
  contentMenuIsLoadingSelector,
  contentMenuIsLoadedSelector
} from './selector'

export const fetch = createAction('content/GET')
export const fetchSuccess = createAction('content/GET_SUCCESS')
export const fetchFailure = createAction('content/GET_FAILURE')
export const fetchContent = createAction('content/FETCH_CONTENT')
export const fetchContentSuccess = createAction('content/FETCH_CONTENT_SUCCESS')
export const fetchContentFailure = createAction('content/FETCH_CONTENT_FAILURE')
export const fetchContents = createAction('content/FETCH_CONTENTS')
export const fetchContentsSuccess = createAction(
  'content/FETCH_CONTENTS_SUCCESS'
)
export const fetchContentsFailure = createAction(
  'content/FETCH_CONTENTS_FAILURE'
)
export const fetchContentMenu = createAction('content/FETCH_CONTENT_MENU')
export const fetchContentMenuSuccess = createAction(
  'content/FETCH_CONTENTS_SUCCESS_MENU'
)
export const fetchContentMenuFailure = createAction(
  'content/FETCH_CONTENTS_FAILURE_MENU'
)

const flattenObj = obj => {
  const go = obj_ =>
    R.chain(([k, v]) => {
      if (R.type(v) === 'Object' || R.type(v) === 'Array') {
        return R.map(([k_, v_]) => [`filter[${k}][${k_}]`, v_], go(v))
      }
      return [[k, v]]
    }, R.toPairs(obj_))

  return R.fromPairs(go(obj))
}

const initialState = {
  isLoaded: false,
  isLoading: false,
  data: {},
  content: {},
  contentMenu: {},
  documentSections: [
    {
      title: 'Персональные данные',
      linkTo: '/documents/consent'
    },
    {
      title: 'Политика конфиденциальности',
      linkTo: '/documents/policy'
    },
    {
      title: 'Соглашение к договору поставки',
      linkTo: '/documents/soglashenie_k_dogovoru_postavki'
    }
  ]
}

export const request =
  ({ clientApi }) =>
    ({ url, lens }) =>
      clientApi
        .get(`/v1/content/${url}/`)
        .then(data => fetchSuccess({ data, lens }))
        .catch(fetchFailure)

const handleFetch = (state, url, { clientApi }) => {
  const lens = lensPath(url.split('/'))
  return loop(
    compose(
      set(lens, {
        isLoading: true,
        isLoaded: false
      })
    )(state),
    Effects.promise(request({ clientApi }), { url, lens })
  )
}

const handleFetchSuccess = (state, { data, lens }) =>
  compose(
    set(lens, {
      isLoading: false,
      isLoaded: true,
      data: pathOr({}, ['data', 'response', 'ITEMS'], data)
    })
  )(state)

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errorMessage: getErrorMessage(payload)
})

const requestContent =
  ({ clientApi }) =>
    ({ type, id }) =>
      clientApi
        .get(`/v3/content/${type}/${id}/`)
        .then(data => fetchContentSuccess({ type, id, ...data }))
        .catch(fetchContentFailure)

const handleFetchContent = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: true
    },
    Effects.promise(requestContent({ clientApi }), payload)
  )

const handleFetchContentSuccess = (state, payload) => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  content: {
    ...prop('content', state),
    [prop('type', payload)]: {
      ...pathOr({}, ['content', prop('type', payload)], state),
      [prop('id', payload)]: pathOr({}, ['data', 'response'], payload)
    }
  }
})

const handleFetchContentFailure = (state, payload) => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  error: payload
})

const requestContents =
  ({ clientApi }) =>
    ({ type, filter, ...params }) =>
      clientApi
        .get(`/v3/content/${type}/`, {
          params: {
            ...params,
            ...flattenObj(filter)
          }
        })
        .then(data => fetchContentsSuccess({ type, ...data }))
        .catch(fetchFailure)

const handleFetchContents = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: true
    },
    Effects.promise(requestContents({ clientApi }), payload)
  )

const handleFetchContentsSuccess = (state, payload) => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  contents: {
    ...prop('contents', state),
    [prop('type', payload)]: {
      items: pathOr([], ['data', 'response', 'ITEMS'], payload),
      nav: pathOr({}, ['data', 'response', 'NAV'], payload),
      aggregations: pathOr([], ['data', 'response', 'AGGREGATIONS'], payload)
    }
  }
})

const handleFetchContentsFailure = (state, payload) => ({
  ...state,
  isLoaded: false,
  isLoading: false,
  error: payload
})

const requestContentMenu =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/contents/menu/main/', { params })
        .then(fetchContentMenuSuccess)
        .catch(fetchContentMenuFailure)

const handleFetchContentMenu = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      contentMenu: {
        isLoading: true,
        isLoaded: false,
        items: []
      }
    },
    Effects.promise(requestContentMenu({ clientApi }), payload)
  )

const handleFetchContentMenuSuccess = (state, payload) => ({
  ...state,
  contentMenu: {
    isLoading: false,
    isLoaded: true,
    items: pathOr([], ['data', 'response', 'ITEMS'], payload)
  }
})

const handleFetchContentMenuFailure = (state, payload) => ({
  ...state,
  contentMenu: {
    isLoading: false,
    isLoaded: true,
    items: [],
    error: payload
  }
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchContent, handleFetchContent)
  on(fetchContentSuccess, handleFetchContentSuccess)
  on(fetchContentFailure, handleFetchContentFailure)
  on(fetchContents, handleFetchContents)
  on(fetchContentsSuccess, handleFetchContentsSuccess)
  on(fetchContentsFailure, handleFetchContentsFailure)
  on(fetchContentMenu, handleFetchContentMenu)
  on(fetchContentMenuSuccess, handleFetchContentMenuSuccess)
  on(fetchContentMenuFailure, handleFetchContentMenuFailure)
}, initialState)

export default reducer
