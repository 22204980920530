import { pathOr, values } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { hideModal } from 'redux/modules/modal'

const initialState = {
  isLoading: false,
  isLoaded: false,
  items: [],
  entities: {},
  errors: [],
  error: ''
}

export { keysSelector, errorsSelector, errorSelector } from './selector'

export const fetchKeys = createAction('api/FETCH_KEYS')
export const fetchKeysSuccess = createAction('api/FETCH_KEYS_SUCCESS')
export const fetchKeysFailure = createAction('api/FETCH_KEYS_FAILURE')
export const createKey = createAction('api/CREATE_KEY')
export const createKeySuccess = createAction('api/CREATE_KEY_SUCCESS')
export const createKeyFailure = createAction('api/CREATE_KEY_FAILURE')
export const deleteKey = createAction('api/DELETE_KEY')
export const deleteKeySuccess = createAction('api/DELETE_KEY_SUCCESS')
export const deleteKeyFailure = createAction('api/DELETE_KEY_FAILURE')
export const hideModalKey = createAction('api/HIDE_MODAL_KEY')

const requestFetchKeys =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/exchange/apikey/', {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(fetchKeysSuccess)
        .catch(fetchKeysFailure)

const handleFetchKeys = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: true,
      errors: [],
      error: ''
    },
    Effects.promise(requestFetchKeys({ clientApi }), payload)
  )

const handleFetchKeysSuceess = (state, payload) => ({
  ...state,
  isLoaded: true,
  isLoading: false,
  items: pathOr([], ['data', 'response', 'ITEMS'], payload),
  entities: pathOr({}, ['data', 'response', 'ENTITIES'], payload)
})

const handleFetchKeysFailure = (state, payload) => ({
  ...state,
  isLoaded: false,
  isLoading: false,
  errors: values(pathOr({}, ['data', 'notices'], payload))
})

const requestCreateKey =
  ({ clientApi }) =>
    ({ title = '' }) =>
      clientApi
        .post('/v3/exchange/apikey/create/', {
          params: {
            title,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(createKeySuccess)
        .catch(createKeyFailure)

const handleCreateKey = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: true,
      errors: [],
      error: ''
    },
    Effects.promise(requestCreateKey({ clientApi }), payload)
  )

const handleCreateKeySuccess = (state, payload) =>
  loop(
    {
      ...state,
      isLoaded: true,
      isLoading: false,
      error: getErrorMessage(payload)
    },
    Effects.batch([Effects.call(hideModalKey), Effects.call(fetchKeys)])
  )

const handleCreateKeyFailure = (state, payload) => ({
  ...state,
  isLoaded: false,
  isLoading: false,
  error: getErrorMessage(payload)
})

const requestDeleteKey =
  ({ clientApi }) =>
    ({ key }) =>
      clientApi
        .post('/v3/exchange/apikey/delete/', {
          params: {
            key,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(deleteKeySuccess)
        .catch(deleteKeyFailure)

const handleDeleteKey = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: true,
      errors: [],
      error: ''
    },
    Effects.promise(requestDeleteKey({ clientApi }), payload)
  )

const handleDeleteKeySuccess = state =>
  loop(
    {
      ...state,
      isLoaded: true,
      isLoading: false
    },
    Effects.batch([Effects.call(hideModalKey), Effects.call(fetchKeys)])
  )

const handleDeleteKeyFailure = (state, payload) => ({
  ...state,
  isLoaded: false,
  isLoading: false,
  error: getErrorMessage(payload)
})

const handleHideModalKey = state =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: false,
      errors: [],
      error: ''
    },
    Effects.call(hideModal)
  )

const reducer = createReducer(on => {
  on(fetchKeys, handleFetchKeys)
  on(fetchKeysSuccess, handleFetchKeysSuceess)
  on(fetchKeysFailure, handleFetchKeysFailure)
  on(fetchKeys, handleFetchKeys)
  on(createKey, handleCreateKey)
  on(createKeySuccess, handleCreateKeySuccess)
  on(createKeyFailure, handleCreateKeyFailure)
  on(deleteKey, handleDeleteKey)
  on(deleteKeySuccess, handleDeleteKeySuccess)
  on(deleteKeyFailure, handleDeleteKeyFailure)
  on(hideModalKey, handleHideModalKey)
}, initialState)

export default reducer
