import React from 'react'

import cx from 'classnames'
import pt from 'prop-types'
import { connect } from 'react-redux'

import { getActive } from 'redux/modules/tabs'

import styles from './TabsContent.scss'

const TabsContent = ({ isActive = false, children }) => {
  return (
    <div
      className={cx({
        [styles.tabsContent]: true,
        [styles.tabsContent_active]: isActive
      })}
    >
      {/* Некоторым дочерним компонентам нужно инициализация, только когда они видны */}
      {isActive && children}
    </div>
  )
}

TabsContent.propTypes = {
  isActive: pt.bool,
  children: pt.node
}

export default connect(({ tabs }, ownProps) => ({
  isActive: getActive(ownProps.name, tabs) === ownProps.id
}))(TabsContent)
