import React, {
  DetailedHTMLProps,
  FC,
  ChangeEvent,
  HTMLAttributes,
  useState
} from 'react'

import cx from 'classnames'
import { pathOr } from 'ramda'

import flattenInput from 'utils/flattenInput'

import styles from './Textarea.scss'

interface ITextarea
  extends DetailedHTMLProps<
    HTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  > {
  isError?: boolean
  name?: string
  value: string | number
}

const Textarea: FC<ITextarea> = ({
  value,
  onChange,
  name,
  placeholder,
  isError,
  className,
  children,
  ...rest
}) => {
  const [textAreaValue, setTextAreaValue] = useState(value)

  const handleChange = () => (e: ChangeEvent<HTMLTextAreaElement>) => {
    const newTextAreaValue: string = pathOr('', ['target', 'value'], e)
    setTextAreaValue(newTextAreaValue)
    if (onChange) onChange(e)
  }

  return (
    <div className={styles.formTextarea}>
      <textarea
        className={cx({
          [styles.formTextarea__field]: true,
          [styles.formTextarea__field_error]: isError,
          [className || '']: className
        })}
        name={name}
        value={textAreaValue}
        onChange={handleChange()}
        placeholder={placeholder}
        id='textarea'
        {...rest}
      />
      {children}
    </div>
  )
}

export default Textarea
export const FieldTextarea = flattenInput(Textarea)
