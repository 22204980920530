import React, { FC } from 'react'

import cx from 'classnames'

import s from './Spacing.scss'
import { ISpacing } from './types'

const Spacing: FC<ISpacing> = ({
  children,
  margin,
  padding,
  size = 'default',
  stretched = false
}) => (
  <div
    className={cx(s.spacing, {
      [s.spacing_stretched]: stretched,
      [s[`spacing__margin_${margin}`]]: !!margin,
      [s[`spacing__padding_${padding}`]]: !!padding,
      [s[`spacing__size_${size}`]]: !!size
    })}
  >
    {children}
  </div>
)

export default Spacing
