import { path, lensPath, compose, set, filter } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { settings } from 'helpers/registration'

import { getCitiesByQuery } from './selector'

const MIN_QUERY_LENGTH = settings.citiesQueryMinLength

const initialState = {
  isLoading: false,
  list: [],
  byQuery: {},
  error: undefined
}

export const fetchCities = createAction('cities/FETCH_CITIES')
const fetchCitiesSuccess = createAction('cities/FETCH_CITIES_SUCCESS')
const fetchCitiesList = createAction('cities/FETCH_CITIES_LIST')
const fetchCitiesFailure = createAction('cities/FETCH_CITIES_FAILURE')

const requestCities =
  ({ clientApi }) =>
    ({ query = '' }) =>
      clientApi
        .get(
          `/v3/exchange/proxy/kladr?contentType=city&typeCode=1&query=${query.substr(
            0,
            MIN_QUERY_LENGTH
          )}`
        )
        .then(data => fetchCitiesSuccess({ data, query }))
        .catch(fetchCitiesFailure)

const handleFetchCities = (state, { query = '' }, { clientApi }) => {
  const q = query.toLowerCase()
  const byQuery = getCitiesByQuery(state)
  const validQuery = q.length >= MIN_QUERY_LENGTH
  const items = byQuery[q.substr(0, MIN_QUERY_LENGTH)]
  const isRequest = validQuery && !items

  return loop(
    {
      ...state,
      isLoading: true
    },
    isRequest
      ? Effects.promise(requestCities({ clientApi }), { query: q })
      : Effects.call(fetchCitiesList, { query: q })
  )
}

const handleFetchCitiesSuccess = (state, { data, query = '' }) => {
  const response = path(['data', 'response', 'result'], data)
  const cityLens = lensPath(['byQuery', query.substr(0, MIN_QUERY_LENGTH)])
  return loop(
    compose(set(cityLens, response))(state),
    Effects.call(fetchCitiesList, { query })
  )
}

const handleFetchCitiesList = (state, { query = '' }) => {
  let list = []
  if (query.length >= MIN_QUERY_LENGTH) {
    const byQuery = getCitiesByQuery(state)
    const items = byQuery[query.substr(0, MIN_QUERY_LENGTH)]
    list = filter(item => item.name.toLowerCase().indexOf(query) !== -1, items)
  }
  return {
    ...state,
    list
  }
}

const handleFetchCitiesFailure = (state, { data }) => ({
  ...state,
  isLoading: false,
  error: data
})

const reducer = createReducer(on => {
  on(fetchCities, handleFetchCities)
  on(fetchCitiesSuccess, handleFetchCitiesSuccess)
  on(fetchCitiesList, handleFetchCitiesList)
  on(fetchCitiesFailure, handleFetchCitiesFailure)
}, initialState)

export default reducer
