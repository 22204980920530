/* eslint-disable complexity */
import debounceEvent from '@redux-beacon/debounce-event'
import {
  pathOr,
  inc,
  values,
  pick,
  map,
  addIndex,
  filter,
  propOr,
  splitEvery,
  keys
} from 'ramda'

import config from 'config'
import {
  addPurchase as sentDelivery,
  removeProducts as clearProducts
} from 'redux/modules/gtm'
import {
  putProducts,
  fetchSuccess as showCard
} from 'redux/modules/productList'
import { switchItemInGroupSuccess } from 'redux/modules/productList/switchItemsInGroup'
import { setParams } from 'redux/modules/updateData'

const obj = {}
const timeout = pathOr(0, ['google', 'timeout'], config)

const showProducts = (action, prevState, nextState) => {
  const view = pathOr([], ['updateData', 'view'], prevState)
  const type = pathOr([], ['payload', 'type'], action)
  if ((type === 'section' || type === 'search') && view !== 'productPage') {
    const itemsIdList = pathOr([], ['products', 'itemsIdList'], nextState)
    const itemsAll = pathOr([], ['products', 'itemsById'], nextState)
    const items = values(pick(itemsIdList, itemsAll))
    const impressions = addIndex(map)(
      (item, position) => ({
        id: pathOr('', ['ID'], item),
        name: pathOr('', ['CODE_1C'], item),
        list: type === 'search' ? 'Search' : 'Category',
        brand: pathOr('', ['TM', 'VALUE'], item),
        category: pathOr('', ['products', 'title'], nextState),
        position: inc(position),
        price: pathOr('', ['PRICE'], item)
      }),
      items
    )
    const dataLayer = [
      {
        ecommerce: {
          currencyCode: 'RUB',
          impressions
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Impressions',
        'gtm-ee-event-non-interaction': 'True'
      }
    ]
    return dataLayer
  }
  return null
}

const showProductCard = (action, prevState, nextState) => {
  const view = pathOr([], ['updateData', 'view'], nextState)
  const type = pathOr([], ['payload', 'type'], action)
  if (view === 'productPage' && type === 'product') {
    const item = pathOr(
      [],
      ['payload', 'data', 'data', 'response', 'ITEM'],
      action
    )
    const products = {
      id: pathOr('', ['ID'], item),
      name: pathOr('', ['CODE_1C'], item),
      price: pathOr('', ['PRICE'], item),
      brand: pathOr('', ['TM', 'VALUE'], item),
      category: pathOr(
        '',
        ['payload', 'data', 'data', 'response', 'META', 'L'],
        action
      )
    }
    const dataLayer = [
      {
        ecommerce: {
          currencyCode: 'RUB',
          detail: {
            actionField: { list: 'Карточка товара' },
            products: [products]
          }
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Details',
        'gtm-ee-event-non-interaction': 'True'
      }
    ]
    return dataLayer
  }
  return null
}

const productClick = (action, prevState, nextState) => {
  const typeAction = pathOr([], ['router', 'location', 'action'], nextState)
  const view = pathOr([], ['payload', 'view'], action)
  const type = pathOr([], ['productsParams', 'type'], nextState)
  if (view === 'app') {
    obj.type = pathOr([], ['updateData', 'view'], prevState)
  }
  if (
    view === 'productPage' &&
    typeAction === 'PUSH' &&
    obj.type === 'products' &&
    (type === 'search' || type === 'section')
  ) {
    const itemId = pathOr([], ['payload', 'productId'], action)
    const itemsAll = pathOr([], ['productList', 'itemsById'], nextState)
    const product = values(filter(item => item.ID === itemId, itemsAll))[0]
    const products = {
      id: pathOr('', ['ID'], product),
      name: pathOr('', ['CODE_1C'], product),
      brand: pathOr('', ['TM', 'VALUE'], product),
      category: pathOr(
        '',
        ['products', 'metadata', 'META', 'TITLE'],
        nextState
      ),
      position: 1,
      price: pathOr('', ['PRICE'], product)
    }
    const dataLayer = [
      {
        ecommerce: {
          currencyCode: 'RUB',
          click: {
            actionField: {
              list: type === 'section' ? 'Категория товара' : 'Search'
            },
            products: [products]
          }
        },
        event: 'gtm-ee-event',
        'gtm-ee-event-category': 'Enhanced Ecommerce',
        'gtm-ee-event-action': 'Product Clicks',
        'gtm-ee-event-non-interaction': 'False'
      }
    ]
    return dataLayer
  }
  return null
}

const addToCart = (action, prevState, nextState) => {
  const type = pathOr([], ['payload', 'type'], action)
  const typeAction = pathOr([], ['payload', 'action'], action)
  if (type === 'basket' && typeAction === 'add') {
    const itemsIdList = keys(pathOr([], ['payload', 'items'], action))
    const itemsAll = pathOr([], ['productList', 'itemsById'], nextState)
    const items = values(pick(itemsIdList, itemsAll))
    const products = addIndex(map)(
      (item, position) => ({
        id: pathOr('', ['ID'], item),
        name: pathOr('', ['CODE_1C'], item),
        price: pathOr('', ['PRICE'], item),
        brand: pathOr('', ['TM', 'VALUE'], item),
        category: pathOr('', ['products', 'title'], nextState),
        position: inc(position),
        quantity: pathOr('', ['BASKETS', 'MAIN', 0, 'QUANTITY'], item)
      }),
      items
    )
    const dataLayer = [
      {
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'RUB',
          add: {
            products
          },
          event: 'gtm-ee-event',
          'gtm-ee-event-category': 'Enhanced Ecommerce',
          'gtm-ee-event-action': 'Adding a Product to a Shopping Cart',
          'gtm-ee-event-non-interaction': 'False'
        }
      }
    ]
    return dataLayer
  }
  return null
}

const removeProducts = action => {
  const items = propOr([], 'payload', action)
  const products = items.map(item => ({
    id: pathOr('', ['ID'], item),
    name: pathOr('', ['CODE_1C'], item),
    price: pathOr('', ['PRICE'], item),
    brand: pathOr('', ['TM', 'VALUE'], item),
    category: 'Basket',
    quantity: pathOr('', ['BASKETS', 'MAIN', 0, 'QUANTITY'], item)
  }))
  const dataLayer = [
    {
      event: 'removeFromCart',
      ecommerce: {
        currencyCode: 'RUB',
        remove: {
          products
        }
      }
    }
  ]
  return dataLayer
}

const addPurchase = (action, prevState, nextState) => {
  const items = propOr([], 'payload', action)
  const products = items.map(item => ({
    id: pathOr('', ['ID'], item),
    name: pathOr('', ['CODE_1C'], item),
    price: pathOr('', ['PRICE'], item),
    brand: pathOr('', ['TM', 'VALUE'], item),
    category: 'Basket',
    quantity: pathOr('', ['BASKETS', 'MAIN', 0, 'QUANTITY'], item)
  }))
  const dataLayer = [
    {
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            id: pathOr([], ['orders', 'orders'], nextState)[0].ID,
            affiliation: 'Online Store',
            revenue: pathOr([], ['orders', 'orders'], nextState)[0].SUM,
            tax: 0.0,
            shipping: 0.0
          },
          products
        }
      },
      event: 'gtm-ee-event',
      'gtm-ee-event-category': 'Enhanced Ecommerce',
      'gtm-ee-event-action': 'Purchase',
      'gtm-ee-event-non-interaction': 'False'
    }
  ]
  return dataLayer
}

export const dispatchInParts = (action, items, limit) => {
  const subOrders = splitEvery(limit, items)
  subOrders.map(item => {
    action(item)
    return item
  })
}

export const eventsTagManager = {
  [putProducts]: debounceEvent(timeout, showProducts),
  [setParams]: debounceEvent(timeout, productClick),
  [showCard]: debounceEvent(timeout, showProductCard),
  [switchItemInGroupSuccess]: debounceEvent(timeout, addToCart),
  [sentDelivery]: debounceEvent(timeout, addPurchase),
  [clearProducts]: debounceEvent(timeout, removeProducts)
}
