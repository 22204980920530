/* eslint-disable complexity */
import { length, ascend, prop, sortWith, descend } from 'ramda'

export const sortSection = (
  items = [],
  params = [{ level: 'DEPTH', key: 'SORT', desc: true }, 'NAME']
) => {
  if (params && length(params) > 0) {
    const sortParams = params.map(item => {
      let funcSort = () => {}
      switch (typeof item) {
        case 'string':
          funcSort = ascend(prop(item))
          break
        case 'object':
          if (item.desc && item.key) {
            funcSort = descend(prop(item.key)) // из 1с приходит прямая сортировка
          } else if (item.key) {
            funcSort = descend(prop(item.key))
          }
          break
        default:
          return false
      }
      return funcSort
    })
    return sortWith(sortParams)(items)
  }
  return items
}
