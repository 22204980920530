import React, { PureComponent } from 'react'

import { string, number, array, bool } from 'prop-types'
import ReactImageZoom from 'react-image-zoom'

import Image from 'components/Image'
import getImagePath from 'helpers/getImagePath'

const defaultZoomStyle = [
  'width: 650px',
  'height: 496px',
  'z-index: 370',
  'background-color: white',
  'margin-left: 20px',
  'top: -12px'
]

const defaultZoomLensStyle = [
  'background-color: rgba(72, 101, 255, 0.42)',
  'opacity: 0.4',
  'border: 2px solid #4865FF'
]

export default class ImageLens extends PureComponent {
  static propTypes = {
    src: string,
    zoomPosition: string,
    width: number,
    zoomWidth: number,
    height: number,
    zoomStyle: array,
    zoomLensStyle: array,
    isLazy: bool
  }

  static defaultProps = {
    isLazy: true,
    src: '',
    zoomPosition: 'right',
    width: 350,
    height: 350,
    zoomWidth: 600,
    zoomStyle: defaultZoomStyle,
    zoomLensStyle: defaultZoomLensStyle
  }

  state = {
    isLens: false
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      window.addEventListener('mousemove', this.handleMouseMove)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove)
  }

  handleMouseMove = () => {
    this.setState({ isLens: true }, () => {
      window.removeEventListener('mousemove', this.handleMouseMove)
    })
  }

  render() {
    const {
      width,
      height,
      zoomWidth,
      src,
      zoomPosition,
      zoomStyle,
      zoomLensStyle,
      isLazy
    } = this.props
    const { isLens } = this.state
    if (isLens) {
      const srcImg = getImagePath(src)
      return (
        <ReactImageZoom
          width={width}
          height={height}
          img={srcImg}
          zoomWidth={zoomWidth}
          zoomPosition={zoomPosition}
          zoomStyle={zoomStyle.join(';')}
          zoomLensStyle={zoomLensStyle.join(';')}
          isLazy={isLazy}
        />
      )
    }
    return (
      <Image
        width={width}
        height={height}
        backgroundSize='fullWidth'
        src={src}
        isLazy={isLazy}
      />
    )
  }
}
