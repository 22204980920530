/* eslint-disable complexity */
import R from 'ramda'
import { loop, Effects } from 'redux-loop'

import getFilters from 'helpers/getFilters'
import getPropertiesMap from 'helpers/getPropertiesMap'
import { getOrderData, getBasket } from 'helpers/products'
import { setCount, setNav, setSendStatus } from 'redux/modules/basket'
import { fetchSuccess as basketParamsFetchSuccess } from 'redux/modules/basketParams'
import { fetchCarouselSuccess } from 'redux/modules/carousel'
import { setNotice } from 'redux/modules/notice'
import { setOrderData, remainSelector, setMarks } from 'redux/modules/products'

import { cartSelector } from './selector'

import {
  getLoadByGroupAndId,
  getItemCountByGroupAndId,
  setMetaData,
  setCarts
} from './index'

const emptyObject = {}
const removeEmptyObjects = items => R.filter(R.identity)(items)

const getItemsIdByGroup = (state, { id, type, itemsId }) => {
  const itemsGroupLens = R.lensPath(['itemsIdByGroup', type, id])
  return R.set(itemsGroupLens, itemsId)(state)
}

const setAnalogsTitles = (state, { key, title }) =>
  R.over(
    R.lensProp('analogTitles'),
    R.flip(R.mergeDeepRight)({ [key]: title })
  )(state)

const handleFetchSection = (state, { data, id, type }) => {
  const response = R.prop('response', data)
  const requestItems = removeEmptyObjects(R.propOr([], 'ITEMS', response))
  const itemsId = R.keys(requestItems)
  const propertiesMap = getPropertiesMap(R.values(requestItems))
  const filtersGroupLens = R.lensPath(['filtersBySectionId', type, id])
  const newState = getItemsIdByGroup(state, { id, type, itemsId })

  return R.set(filtersGroupLens, getFilters(response, propertiesMap))(newState)
}

const handleFetchSectionV2 = (state, { response, id, type }) => {
  const requestItems = removeEmptyObjects(R.propOr([], 'ITEMS', response))
  const itemsId = R.map(R.prop('ID'), requestItems)
  const propertiesMap = getPropertiesMap(requestItems)
  const itemsGroupLens = R.lensPath(['itemsIdByGroup', type, id])
  const filtersGroupLens = R.lensPath(['filtersBySectionId', type, id])

  return R.compose(
    R.set(itemsGroupLens, itemsId),
    R.set(filtersGroupLens, getFilters(response, propertiesMap))
  )(state)
}

const getItemCount = (type, state) => item => {
  const inBasket = getBasket(item, [
    type === 'loyaltyBasket' ? 'loyalty' : 'main',
    0
  ])
  if (inBasket) {
    return inBasket
  }

  const productCount = getItemCountByGroupAndId('section', item.ID, state)
  if (type === 'product' && productCount) {
    return productCount
  }

  const remain = remainSelector(item)
  const min = R.path(['MULTIPLICITY', 'MIN'], item)

  return Math.min(remain, min)
}

const handleFetchItems = (state, { response, type, bookmark = 0 }) => {
  const items = R.propOr([], 'ITEMS', response)
  const products = R.is(Array, items) ? R.indexBy(R.prop('ID'), items) : []
  const formattedItems = R.map(item => item, products)
  const itemsCount = R.map(getItemCount(type, state), formattedItems)
  const inBasket = R.map(
    R.pathOr(0, ['BASKETS', 'MAIN', 0, 'QUANTITY']),
    formattedItems
  )
  const inWaitList = R.map(
    R.pathOr(0, ['BASKETS', 'DELAY', 0, 'QUANTITY']),
    formattedItems
  )
  const inBookmark = R.map(
    R.pathOr(0, ['BASKETS', 'BOOKMARK', bookmark, 'QUANTITY']),
    formattedItems
  )
  const itemsLens = R.lensPath(['itemsById'])
  const itemCountsSectionLens = R.lensPath([
    'itemsCountsMap',
    type === 'loyaltyBasket' ? type : 'section'
  ])
  const itemCountsBasketLens = R.lensPath(['itemsCountsMap', 'basket', 'main'])
  const itemCountsWaitList = R.lensPath(['itemsCountsMap', 'basket', 'delay'])
  const itemCountsBookmarkLens = R.lensPath([
    'itemsCountsMap',
    'basket',
    `bookmark_${bookmark}`
  ])
  return R.compose(
    R.over(itemsLens, R.flip(R.merge)(formattedItems)),
    R.over(itemCountsSectionLens, R.flip(R.merge)(itemsCount)),
    R.over(itemCountsBasketLens, R.flip(R.merge)(inBasket)),
    R.over(itemCountsWaitList, R.flip(R.merge)(inWaitList)),
    R.over(itemCountsBookmarkLens, R.flip(R.merge)(inBookmark))
  )(state)
}

export const handlePutProduct = (state, { id, data }) => {
  if ((getLoadByGroupAndId('product', id, state) || {}).isLoaded) {
    return state
  }
  const itemLens = R.lensPath(['itemsById', id])
  const loadLens = R.lensPath(['loadById', 'product', id])
  return R.compose(
    R.set(itemLens, { ...data }),
    R.set(loadLens, { isLoading: false, isLoaded: true })
  )(state)
}

export const handlePutProducts = (
  state,
  {
    itemsById: itemsMap,
    catalog = 'main',
    updateOrderData,
    type = 'section',
    replacing = false
  }
) => {
  const effects = []
  const itemsById = {
    ...R.prop('itemsById', state),
    ...R.map(
      item =>
        replacing
          ? item
          : {
            ...R.path(['itemsById', R.prop('ID', item)], state),
            ...item
          },
      itemsMap
    )
  }
  if (updateOrderData) {
    const items = R.mapObjIndexed(
      (val, id) => getOrderData(R.prop(id, itemsById), [catalog, 0]),
      itemsMap
    )
    effects.push(Effects.call(setOrderData, { items }))
  }
  return loop(
    {
      ...state,
      itemsById,
      loadById: {
        ...R.prop('loadById', state),
        product: R.map(() => ({ isLoading: false, isLoaded: true }), itemsMap)
      },
      itemsCountsMap: {
        ...R.prop('itemsCountsMap', state),
        [type]: {
          ...R.pathOr({}, ['itemsCountsMap', type], state),
          ...R.map(item => {
            if (type === 'loyaltyBasket') {
              return R.pathOr(null, ['amount', 'loyalty'], item)
            }
            return R.prop('amount', item)
          }, itemsMap)
        }
      }
    },
    Effects.batch(effects)
  )
}

const indexedOrderData = (items = [], catalog = '') =>
  R.compose(
    R.map(item => getOrderData(item, catalog)),
    R.indexBy(R.prop('ID'))
  )(items)

const handleFetchProductAnalogs = (
  state,
  { data: actionData, catalog = 'main', id, type }
) => {
  const response = R.path(['data', 'response'], actionData)
  const effects = []
  let newState = { ...state }
  const loadLens = R.lensPath(['loadById', type, id])
  const entities = R.compose(
    R.reduce(
      (acc, item) => ({
        ...R.mergeDeepRight(acc, R.propOr({}, 'ENTITIES', item))
      }),
      {}
    ),
    R.values
  )(response)
  effects.push(Effects.call(setMarks, entities))

  R.keys(response).forEach(key => {
    const itemsId = R.pathOr([], [key, 'ITEMS'], response).map(item => item.ID)
    const items = R.pathOr([], [key, 'ITEMS'], response)
    const storeKey = key.toLowerCase()

    const itemsOrder = indexedOrderData(items, catalog)
    effects.push(Effects.call(setOrderData, { items: itemsOrder }))

    newState = setAnalogsTitles(newState, {
      key: storeKey,
      title: R.pathOr('', [key, 'TITLE'], response)
    })
    newState = getItemsIdByGroup(newState, { id, type: storeKey, itemsId })

    newState = handleFetchItems(newState, {
      response: {
        ...response,
        ITEMS: items
      },
      type: storeKey
    })

    newState = R.set(loadLens, {
      isLoading: false,
      isLoaded: true
    })(newState)
  })
  return loop(newState, Effects.batch(effects))
}

const handleFetchSuccess = (
  state,
  { data: actionData, id: paramId, catalog, type, params }
) => {
  const cartId = R.propOr(0, 'value', params) || cartSelector(state)
  let id = paramId
  const isArrayId = Array.isArray(paramId)
  let bookmark = 0
  if (isArrayId) {
    id = R.join('_', paramId)
    bookmark = R.last(paramId)
  }
  if (R.prop('bookmark_id', params)) {
    bookmark = R.prop('bookmark_id', params)
  }
  if (type === 'similar') {
    return handleFetchProductAnalogs(state, {
      data: actionData,
      catalog,
      id,
      type
    })
  }
  const isLoyalty = ['loyaltyBasket', 'loyalty'].indexOf(type) !== -1

  const effects = []
  const data = R.prop('data', actionData)
  const response = R.propOr(emptyObject, 'response', data)

  if (type === 'carousel' && !id) {
    const arrCarousel = R.values(response)
    const [newStateCarousel, allEffects] = arrCarousel.reduce(
      ([finalState, effectsReduce], r) => {
        const [localState, localEffects] = handleFetchSuccess(finalState, {
          type,
          id: r.CODE,
          data: {
            data: { response: r }
          }
        })
        return [localState, effectsReduce.concat(localEffects)]
      },
      [state, []]
    )

    allEffects.push(
      Effects.call(fetchCarouselSuccess, { carousel: arrCarousel, catalog })
    )

    const allItems = Array.isArray(response)
      ? response.reduce((acc, { ITEMS = [] }) => [...acc, ...ITEMS], [])
      : []
    const orderData = indexedOrderData(allItems, [catalog, cartId])
    allEffects.push(
      Effects.call(setOrderData, {
        items: orderData,
        basket: [catalog, cartId]
      })
    )

    return loop(newStateCarousel, Effects.batch(allEffects))
  }

  const requestItemsV2 = R.propOr(emptyObject, 'ITEMS', response)
  const requestItems = removeEmptyObjects(
    R.propOr(requestItemsV2, 'ITEMS', response)
  )
  const entities = R.propOr({}, 'ENTITIES', response)
  const requestItemV2 = R.prop('ITEM', response)
  const requestItem = R.propOr(requestItemV2, 'ITEM', response)
  let newState = { ...state }
  const nav = R.pathOr({}, ['NAV'], response)
  const statusSendActive = R.pathOr(
    false,
    ['CHECKOUT', 'AVAILABILITY'],
    response
  )
  let isMainBasket
  if (type === 'basket' || isLoyalty) {
    isMainBasket = R.path(['BASKETS', 'MAIN', 'VALUES', 0], nav)
    const message = R.propOr({}, 'MESSAGE', response)
    const messageCheckout = R.pathOr({}, ['CHECKOUT', 'MESSAGE'], response)
    const messages = R.compose(R.values, R.pathOr([], ['MESSAGES']))(response)
    effects.push(
      Effects.call(setNotice, {
        name: 'body',
        page: isLoyalty ? 'loyalty' : cartId,
        value: messages
      })
    )
    if (type === 'loyaltyBasket') {
      isMainBasket = true
    }
    if (!R.isEmpty(message)) {
      effects.push(
        Effects.call(setNotice, {
          name: 'alert',
          page: 'basket',
          value: [message]
        })
      )
    }
    effects.push(
      Effects.call(setNotice, {
        name: 'checkout',
        page: type,
        value: [messageCheckout]
      })
    )
    if (isMainBasket) {
      effects.push(Effects.call(setCount, { data }))
    }
    effects.push(Effects.call(basketParamsFetchSuccess, { data }))
    effects.push(Effects.call(setNav, { nav }))
    effects.push(Effects.call(setSendStatus, statusSendActive))
  }

  effects.push(Effects.call(setMarks, entities))
  if (!R.isEmpty(R.pathOr({}, ['BASKETS', 'MAIN', 'VALUES'], nav))) {
    effects.push(
      Effects.call(setCarts, { carts: R.pathOr({}, ['BASKETS'], nav) })
    )
  }

  const lastSum = isMainBasket
    ? {
      lastSum: {
        itemsCount:
            R.path(['NAV', 'BASKETS', 'MAIN', 'VALUES', 0, 'CNT'], response) ||
            R.path(
              ['NAV', 'BASKETS', 'LOYALTY', 'VALUES', 0, 'CNT'],
              response
            ) ||
            R.pathOr(0, ['CNT'], response),
        itemsSum:
            R.path(['NAV', 'BASKETS', 'MAIN', 'VALUES', 0, 'SUM'], response) ||
            R.path(
              ['NAV', 'BASKETS', 'LOYALTY', 'VALUES', 0, 'SUM'],
              response
            ) ||
            R.pathOr(0, ['SUM'], response)
      }
    }
    : {}

  if (requestItem) {
    const META = R.pathOr({}, ['META'], response)
    const itemId = requestItem.ID
    requestItems[itemId] = {
      ...requestItem,
      META
    }
  }

  if (type === 'product' && !requestItem) {
    requestItems[id] = data
  }

  if (
    !R.prop('ITEMS', response) &&
    ['basket', 'loyaltyBasket'].includes(type) &&
    id === '0'
  ) {
    response.ITEMS = {}
  }
  if (R.prop('ITEMS', response)) {
    newState = handleFetchItems(newState, {
      response: {
        ...response,
        ITEMS: !R.isEmpty(R.prop('ITEMS', response)) ? requestItems : []
      },
      type,
      bookmark
    })
    newState = handleFetchSection(newState, {
      data: { ...data, response },
      id,
      type
    })
  }
  if (R.prop('ITEMS', response) && !R.isEmpty(R.prop('ITEMS', response))) {
    newState = handleFetchItems(newState, {
      response,
      type,
      bookmark
    })
    newState = handleFetchSectionV2(newState, {
      response,
      id,
      type
    })

    const orderData = indexedOrderData(R.propOr([], 'ITEMS', response), [
      catalog,
      cartId
    ])
    effects.push(
      Effects.call(setOrderData, {
        items: orderData,
        basket: [catalog, cartId]
      })
    )
  }

  if (
    R.isEmpty(R.propOr([], 'ITEMS', response)) &&
    (type === 'basket' || type === 'loyaltySearch')
  ) {
    newState = handleFetchItems(newState, {
      response: {
        ...response,
        ITEMS: []
      },
      type,
      bookmark
    })

    newState = handleFetchSectionV2(newState, {
      response,
      id,
      type
    })
  }
  if (R.prop('ITEM', response)) {
    newState = handleFetchItems(newState, {
      response: {
        response,
        ITEMS: [requestItem]
      },
      type,
      bookmark
    })
    const orderData = indexedOrderData(
      [R.propOr({}, 'ITEM', response)],
      [catalog, cartId]
    )
    effects.push(
      Effects.call(setOrderData, {
        items: orderData,
        basket: [catalog, cartId]
      })
    )
  }
  const loadLens = R.lensPath(['loadById', type, id])
  if (['product', 'loyaltyProduct'].indexOf(type) !== -1) {
    const meta = R.propOr({}, 'META', response)
    const pageTitle = R.pathOr('', ['NAV', 'TITLE'], response)
    effects.push(Effects.call(setMetaData, { ...meta, pageTitle }))
  }

  if (type === 'loyaltySearch') {
    const query = R.prop('QUERY', response)
    const suggest = R.prop('SUGGEST', response)
    const count = R.path(['NAV', 'CNT'], response)
    effects.push(
      Effects.call(setMetaData, {
        QUERY: query,
        SUGGEST: suggest,
        COUNT: count
      })
    )
  }

  return loop(
    R.set(
      loadLens,
      {
        isLoading: false,
        isLoaded: true,
        ...lastSum
      },
      newState
    ),
    Effects.batch(effects)
  )
}

export default handleFetchSuccess
