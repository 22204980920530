import {
  path,
  compose,
  prop,
  values,
  mapObjIndexed,
  sortBy,
  pickBy,
  keys,
  or
} from 'ramda'

const QUERY_LENGTH = 3

export default (data, propertiesMap) =>
  compose(
    sortBy(prop('sort')),
    values,
    mapObjIndexed((property, key) => {
      const title = path(['TITLE'], property)
      const value = path(['VALUE'], property)
      const unit = path(['UNIT'], property)
      const sort = parseInt(path(['SORT'], property), 10)

      const items = compose(
        values,

        mapObjIndexed((itemTitle = '', itemKey = '') => ({
          title: itemTitle,
          id: itemKey
        })),

        pickBy((itemTitle, itemKey) => path([key, itemKey], propertiesMap))
      )(value)

      return {
        title,
        primary: path(['PRIMARY'], property) === 'Y',
        id: key,
        items,
        logic: 'OR',
        sort,
        unit,
        query: key.substr(QUERY_LENGTH).toLowerCase()
      }
    }),

    pickBy((property, key) =>
      or(keys(propertiesMap[key]).length, path(['TITLE'], property))
    ),

    path(['ENTITIES', 'PROPERTY'])
  )(data)
