/* eslint-disable complexity */
const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAYS_IN_MONTH = 31
const MONTH =
  DAYS_IN_MONTH *
  HOURS_IN_DAY *
  MINUTES_IN_HOUR *
  SECONDS_IN_MINUTE *
  MILLISECONDS_IN_SECOND

export const initContractor = ({ cookie }) => {
  const toolbox = {
    cookie
  }
  const getContractorId = (userId = 0) => {
    // const contractorId = sessionStorage.getItem('contractor_id')
    const contractorId = toolbox.cookie.load('contractor', { path: '/' })
    const contractorUserId = toolbox.cookie.load(`contractor_${userId}`, {
      path: '/'
    })
    return contractorId === '-1' ? undefined : contractorUserId || contractorId
  }

  const setContractorId = (id, userId = 0) => {
    // 1 месяц
    const expires = new Date(Date.now() + MONTH)
    if (id && Number(id) !== -1) {
      // sessionStorage.setItem('contractor_id', id)
      toolbox.cookie.save('contractor', id, { path: '/', expires })
      toolbox.cookie.save(`contractor_${userId}`, id, { path: '/', expires })
    } else if (id && Number(id) === -1) {
      toolbox.cookie.remove('contractor', { path: '/', expires })
      toolbox.cookie.remove(`contractor_${userId}`, { path: '/', expires })
    }
    return getContractorId(userId)
  }

  const getGeneralContractorId = () => {
    const contractorId = toolbox.cookie.load('general_contractor', {
      path: '/'
    })
    return contractorId === '-1' ? undefined : contractorId
  }
  const setGeneralContractorId = id => {
    const expires = new Date(Date.now() + MONTH)
    if (id && Number(id) !== -1) {
      toolbox.cookie.save('general_contractor', id, { path: '/', expires })
    } else {
      toolbox.cookie.remove('general_contractor', { path: '/', expires })
    }
    return getContractorId()
  }

  const clearContractorId = (userId = 0) => {
    // sessionStorage.clear()
    toolbox.cookie.remove('contractor', { path: '/' })
    if (userId) {
      toolbox.cookie.remove(`contractor_${userId}`, { path: '/' })
    }
  }

  return {
    getContractorId,
    setContractorId,
    getGeneralContractorId,
    setGeneralContractorId,
    clearContractorId
  }
}
