/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { object, func, string, bool } from 'prop-types'
import { path, prop, not, equals } from 'ramda'
import { connect } from 'react-redux'

import BannerWrapper from 'components/BannerWrapper'
import Picture from 'components/Picture'
import { forClientRendering } from 'decorators/window'
import getBannerParams from 'helpers/getBannerParams'
import { setAnalyticsProps } from 'helpers/metaTags'
import {
  getBannerType,
  fetchBanners as fetchBannersStore
} from 'redux/modules/banners'
import { pageLoadedSelector } from 'redux/modules/settings'

import styles from './Banners.scss'

const getAlignedBanner = (side, banners) =>
  getBannerType('BACKGROUND')(banners) &&
  getBannerType('BACKGROUND')(banners).find(
    banner => prop('ALIGN', banner) === side
  )

@connect(
  ({ router, banners, reduxAsyncConnect }) => ({
    pathname: path(['location', 'pathname'], router),
    centerBanner: getAlignedBanner('center', banners),
    leftBanner: getAlignedBanner('left', banners),
    rightBanner: getAlignedBanner('right', banners),
    isPageLoaded: pageLoadedSelector(reduxAsyncConnect)
  }),
  {
    fetchBanners: fetchBannersStore
  }
)
class Banners extends PureComponent {
  static propTypes = {
    pathname: string,
    centerBanner: object,
    leftBanner: object,
    rightBanner: object,
    fetchBanners: func,
    isPageLoaded: bool
  }

  static defaultProps = {
    fetchBanners: () => {},
    isPageLoaded: false
  }

  componentDidMount() {
    const { isPageLoaded } = this.props
    if (isPageLoaded) {
      this.onFetchBanners()
    }
  }

  componentDidUpdate(prevProps) {
    if (
      not(equals(prevProps.isPageLoaded, this.props.isPageLoaded)) &&
      this.props.isPageLoaded
    ) {
      this.onFetchBanners()
    }
  }

  @forClientRendering
  onFetchBanners() {
    const { fetchBanners, pathname } = this.props
    const params = getBannerParams({ pathname })
    fetchBanners({
      params
    })
  }

  renderBanner = (side, banner) => {
    const { centerBanner } = this.props
    return (
      <div
        className={cx([
          styles.bannerImageWrapper,
          styles[`bannerImageWrapper_${side}`]
        ])}
        style={{
          position: prop('FIX', banner) ? 'fixed' : undefined
        }}
      >
        <BannerWrapper
          className={cx({
            [styles.centerBannerAncestor]: centerBanner,
            [styles[`centerBannerAncestor_${side}`]]: centerBanner
          })}
          banner={banner}
        >
          <div
            className={cx({
              [styles.centerBanner]: centerBanner,
              [styles[`centerBanner_${side}`]]: centerBanner
            })}
            style={{
              backgroundImage: centerBanner
                ? `url(${prop('IMG', banner)})`
                : undefined
            }}
            itemProp='image'
          >
            <Picture
              src={prop('IMG', banner)}
              alt={prop('TITLE', banner)}
              backgroundSize=''
              isLazy={false}
              {...setAnalyticsProps({
                type: 'banner',
                banner: 'background',
                pos: side
              })}
            />
          </div>
        </BannerWrapper>
      </div>
    )
  }

  render() {
    const { centerBanner } = this.props
    const leftBanner = centerBanner || this.props.leftBanner
    const rightBanner = centerBanner || this.props.rightBanner
    return (
      <div className={styles.banners}>
        {leftBanner && this.renderBanner('left', leftBanner)}
        {rightBanner && this.renderBanner('right', rightBanner)}
      </div>
    )
  }
}

export default Banners
