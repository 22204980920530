import { connect } from 'react-redux'

import { NoticeConfirm } from 'components/Notice'
import {
  confirm,
  getNotice,
  getNoticeHeader,
  getIsSendingConfirm
} from 'redux/modules/notice'

export default connect(
  state => ({
    item: getNotice({ type: 'confirm', page: 'site', notice: state.notice }),
    header: getNoticeHeader({
      type: 'confirm',
      page: 'site',
      notice: state.notice
    }),
    isSending: getIsSendingConfirm(state.notice)
  }),
  {
    confirm
  }
)(NoticeConfirm)
