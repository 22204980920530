import { push } from 'connected-react-router'
import { pathOr, isEmpty, has, prop } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

export {
  getProducts,
  getIsLoading,
  getInvoices,
  getProduct,
  getIsError,
  getCount,
  getHeaders
} from './selector'

const initialState = {
  products: [],
  product: {},
  invoices: [],
  count: 0,
  entities: [],
  isError: false,
  isLoading: false,
  isLoaded: true,
  headers: {
    contractorTitle: 'Контрагент',
    addressTitle: 'Адрес доставки',
    deliveryDateTitle: 'Дата доставки',
    sumTitle: 'Сумма, ₽'
  }
}

export const reset = createAction('claim/RESET')
export const fetchProducts = createAction('claim/FETCH_PRODUCT')
export const fetchProductsSuccess = createAction('claim/FETCH_PRODUCT_SUCCESS')
export const fetchProductsFailure = createAction('claim/FETCH_PRODUCT_FAILURE')
export const fetchInvoices = createAction('claim/FETCH_INVOICE')
export const fetchInvoicesSuccess = createAction('claim/FETCH_INVOICE_SUCCESS')
export const fetchFailure = createAction('claim/FETCH_FAILURE')

const handleReset = () => ({ ...initialState })

const requestProductsSearch =
  ({ clientApi }) =>
    q =>
      clientApi
        .get('/v3/sale/product/search/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            limit: 5,
            q
          }
        })
        .then(fetchProductsSuccess)
        .catch(fetchProductsFailure)

const requestInvoices =
  ({ clientApi }) =>
    params => {
      return clientApi
        .get('/v3/sale/orders/shipment/', {
          params: {
            ...params,
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(res =>
          fetchInvoicesSuccess({
            ...res,
            redirect: has('num', params)
          })
        )
        .catch(fetchFailure)
    }

const handleFetchProducts = (state, params, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestProductsSearch({ clientApi }), params)
  )

const handleFetchProductsSuccess = (state, payload) => ({
  ...state,
  products: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchProductsFailure = state => ({
  ...state,
  products: []
})

const handleFetchInvoices = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      products: [],
      invoices: [],
      isLoading: true,
      isLoaded: false,
      isError: false
    },
    Effects.promise(requestInvoices({ clientApi }), params)
  )

const handleFetchInvoicesSuccess = (state, payload) => {
  const invoices = pathOr([], ['data', 'response', 'ITEMS'], payload)

  if (isEmpty(invoices))
    return {
      ...initialState,
      isError: true
    }

  if (prop('redirect', payload)) {
    const id = pathOr([], ['data', 'response', 'ITEMS', 0, 'ID'], payload)
    return loop(
      {
        ...state
      },
      Effects.call(push, `/cabinet/claim/invoice-products/${id}`)
    )
  }

  return {
    ...state,
    invoices,
    isLoading: false,
    isLoaded: true,
    entities: pathOr([], ['data', 'response', 'ENTITIES'], payload),
    count: pathOr([], ['data', 'response', 'NAV', 'CNT'], payload),
    product: pathOr({}, ['data', 'response', 'NAV', 'PRODUCT'], payload)
  }
}

const handleFetchFailure = () => ({
  ...initialState,
  isError: true
})

export default createReducer(on => {
  on(reset, handleReset)
  on(fetchProducts, handleFetchProducts)
  on(fetchProductsSuccess, handleFetchProductsSuccess)
  on(fetchProductsFailure, handleFetchProductsFailure)
  on(fetchInvoices, handleFetchInvoices)
  on(fetchInvoicesSuccess, handleFetchInvoicesSuccess)
  on(fetchFailure, handleFetchFailure)
}, initialState)
