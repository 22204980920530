import { connect } from 'react-redux'

import MenuCatalog from 'components/MenuCatalog'
import { getBookmarks } from 'redux/modules/bookmarks'
import {
  setCatalogVisible,
  setCatalogHeight,
  selectCatalogItem,
  setListening
} from 'redux/modules/catalog'
import { getAccess } from 'redux/modules/personal'
import { sectionsMainSelector } from 'redux/modules/sections'
import {
  bookmarkSelector,
  bottomPanelVisibleSelector,
  catalogTypeSelector,
  isBookmarkSelector
} from 'redux/modules/settings'

export default connect(
  (state, ownProps) => {
    const {
      bookmarks,
      settings,
      token: { isUserWithoutPassword },
      personal,
      sections,
      catalog: { isListening, selectedItemId, catalogHeight }
    } = state
    const isBookmark = isBookmarkSelector(settings)
    const bookmark = bookmarkSelector(settings)
    const catalogType = catalogTypeSelector(settings)
    return {
      buttonCatalog: ownProps.buttonCatalog,
      catalog: sectionsMainSelector({ catalogType, sections, bookmark }),
      bookmarkData: getBookmarks(bookmarks),
      isBookmark,
      bookmark,
      bookmarkAccess: getAccess(personal, 'BOOKMARKS'),
      selectedItemId,
      bottomPanelVisible: bottomPanelVisibleSelector(settings),
      isUserWithoutPassword,
      isListening,
      catalogHeight
    }
  },
  {
    setCatalogVisible,
    setCatalogHeight,
    selectCatalogItem,
    setListening
    
  }
)(MenuCatalog)
