/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, func, object, string } from 'prop-types'
import { equals, isEmpty, prop, propOr } from 'ramda'
import SliderSlick from 'react-slick'

import Icon from 'components/Icon'
import Image from 'components/Image'
import { display } from 'decorators/device'
import { sliderImages } from 'utils/sliderConfig'

import styles from './ProductPhoto.scss'

const DEFAULT_SLIDES_CNT = 3
const DEFAULT_IMG_WIDTH = 49
const PAGE_IMG_SIZE = 35
const MODAL_IMG_SIZE = 43
const SPRITE_PICTURE = '/images/sprite/sprite.png'

const SliderArrow = ({ next, isQuickView, className, style, ...props }) => {
  return (
    <Icon
      icon={next ? 'down' : 'up'}
      className={cx(styles.sliderArrow, {
        [className]: !!className,
        [styles.sliderArrow_next]: next,
        [styles.sliderArrow_quickView]: isQuickView,
        [styles.sliderArrow_quickView_next]: isQuickView && next
      })}
      style={{ ...style }}
      {...props}
    />
  )
}

SliderArrow.propTypes = {
  style: object,
  next: bool,
  isQuickView: bool,
  className: string
}

class ProductPhoto extends PureComponent {
  static propTypes = {
    items: object,
    settings: object,
    selectedPath: string,
    spriteIcon: string,
    isQuickView: bool,
    isGalleryModal: bool,
    isDesktop: bool,
    isLoyalty: bool,
    onChange: func,
    onChangeSpriteIcon: func,
    handleShowGallery: func,
    handleFetchSpritePhoto: func
  }

  static defaultProps = {
    items: {},
    settings: {},
    onChange: () => {},
    onChangeSpriteIcon: () => {},
    spriteIcon: 'rotate',
    isQuickView: false,
    isGalleryModal: false,
    isLoyalty: false
  }

  handleShowGallery =
    ({ path }) =>
      () =>
        this.props.handleShowGallery({ path })

  handleShowSprite =
    ({ path }) =>
      () => {
        const {
          isGalleryModal,
          handleFetchSpritePhoto,
          spriteIcon,
          onChangeSpriteIcon
        } = this.props
        handleFetchSpritePhoto()
        if (!isGalleryModal) {
          this.handleShowGallery({ path })()
        } else if (spriteIcon === 'rotate-paused') {
          onChangeSpriteIcon('rotate')
        } else {
          this.onChange({ path })()
        }
      }

  onHandleSlideBefore = (current, next) => {
    const { items } = this.props
    const photos = propOr([], 'photos', items)
    if (!equals(current, next)) {
      this.onChange({ path: prop(next, photos) })()
    }
  }

  onChange =
    ({ path }) =>
      () =>
        this.props.onChange({ path })

  renderProductSlide = (path, key) => {
    const { isQuickView, selectedPath, isGalleryModal, isLoyalty } = this.props
    const isSelected = equals(path, selectedPath)
    const slideWidth = !isQuickView ? '49px' : '67px'
    const imageSize = isQuickView ? MODAL_IMG_SIZE : PAGE_IMG_SIZE
    const isInlineView = isGalleryModal || (isLoyalty && !isQuickView)
    return (
      <div
        role='presentation'
        key={`img${key}`}
        style={{ width: slideWidth }}
        className={cx(styles.productPhoto, {
          [styles.productPhoto_inline]: isInlineView,
          [styles.productPhoto_selected]: isSelected,
          [styles.productPhoto_quickView]: isQuickView,
          [styles.productPhoto_quickView_selected]: isQuickView && isSelected
        })}
        onClick={this.onChange({ path })}
        itemProp='image'
      >
        <Image
          backgroundSize='contain'
          width={imageSize}
          height={imageSize}
          src={path} />
      </div>
    )
  }

  renderVideo = (path, key) => {
    const { isQuickView, isGalleryModal, selectedPath } = this.props
    const isSelected = equals(selectedPath, path)
    return (
      <div
        role='presentation'
        key={`video${key}`}
        className={cx(styles.productVideo, {
          [styles.productVideo_quickView]: isQuickView,
          [styles.productVideo_active]: isSelected
        })}
        onClick={
          !isGalleryModal
            ? this.handleShowGallery({ path })
            : this.onChange({ path })
        }
      >
        <div className={styles.productVideo_item} />
        <Icon
          className={cx(styles.iconInfo, {
            [styles.iconInfo_quickView]: isQuickView
          })}
          icon='video'
        />
      </div>
    )
  }

  renderRotateItem = () => {
    const { isQuickView, selectedPath, spriteIcon } = this.props
    const isSelected = equals(selectedPath, SPRITE_PICTURE)
    return (
      <div
        role='presentation'
        className={cx(styles.productVideo, {
          [styles.productVideo_quickView]: isQuickView,
          [styles.productVideo_active]: isSelected
        })}
        onClick={this.handleShowSprite({ path: SPRITE_PICTURE })}
      >
        <div className={styles.productVideo_item} />
        <Icon
          className={cx(styles.iconRotate, {
            [styles.iconRotate_quickView]: isQuickView
          })}
          icon={spriteIcon}
        />
      </div>
    )
  }

  render() {
    const { isDesktop, items, settings, isQuickView, isGalleryModal, isLoyalty } =
      this.props
    const isShowGallery = propOr(false, 'isShowGallery', settings)
    if (!isShowGallery) {
      return null
    }
    const photos = propOr([], 'photos', items)
    const video = propOr([], 'video', items)
    const sprite = propOr('', 'sprite', items)
    const showControls = propOr(false, 'isShowControls', settings)
    const slidesToShow = propOr(DEFAULT_SLIDES_CNT, 'slidesToShow', settings)
    const slidesToScroll = propOr(1, 'slidesToScroll', settings)
    const sliderWidth = propOr(DEFAULT_IMG_WIDTH, 'sliderWidth', settings)
    const isInlineView = !isDesktop || isGalleryModal || (isLoyalty && !isQuickView)
    const styleName = isInlineView ? 'width' : 'height'

    return (
      <div
        className={cx(styles.galleryWrapper, {
          [styles.galleryWrapper_quickView]: isQuickView,
          [styles.galleryWrapper_inline]: isInlineView
        })}
      >
        <div style={showControls ? {} : { [styleName]: `${sliderWidth}px` }}>
          <SliderSlick
            style={{ [styleName]: `${sliderWidth}px` }}
            dots={false}
            draggable={false}
            infinite={isInlineView}
            speed={500}
            vertical={!isInlineView}
            verticalSwiping={!isInlineView}
            arrows={showControls}
            slidesToShow={slidesToShow}
            slidesToScroll={slidesToScroll}
            className={cx(styles.photoSlider, {
              [styles.photoSlider_center]: !showControls,
              [styles.photoSlider_quickView]: isQuickView,
              [styles.photoSlider_center_quickView]:
                !showControls && isQuickView,
              [styles.photoSlider_inline]: isInlineView
            })}
            nextArrow={<SliderArrow isQuickView={isQuickView}
              next />}
            prevArrow={<SliderArrow isQuickView={isQuickView} />}
            beforeChange={this.onHandleSlideBefore}
            afterChange={this.onAfterChange}
            focusOnSelect={showControls}
            variableWidth={isInlineView}
            responsive={sliderImages}
          >
            {photos.map(this.renderProductSlide)}
          </SliderSlick>
        </div>
        {!isEmpty(sprite) && this.renderRotateItem()}
        {!isEmpty(video) && video.map(this.renderVideo)}
      </div>
    )
  }
}

export default display(ProductPhoto)
