import {
  or,
  pathOr,
  prop,
  propOr,
  map,
  length,
  compose,
  slice,
  filter
} from 'ramda'
import { createSelector } from 'reselect'

const COUNT_LAST = 4

export const getIsLoaded = (name, state) =>
  pathOr(false, name.split('/').concat(['isLoaded']), state)
export const getIsLoading = state => state.isLoading
export const getNewsContent = state => state.data

const contentsSelector = createSelector(
  state => state,
  state => prop('contents', state)
)

const typeSelector = createSelector(
  (_, type) => type,
  type => type
)

const contentsTypeSelector = createSelector(
  contentsSelector,
  typeSelector,
  (content, type) => propOr({}, type, content)
)

export const itemsSelector = createSelector(contentsTypeSelector, content =>
  propOr([], 'items', content)
)

export const navSelector = createSelector(contentsTypeSelector, content =>
  propOr({}, 'nav', content)
)

const aggregationsTypeSelector = createSelector(contentsTypeSelector, content =>
  propOr([], 'aggregations', content)
)

const aggregationsCheckedSelector = createSelector(
  (_, __, filters) => filters,
  filters => filters
)

export const aggregationsSelector = createSelector(
  aggregationsTypeSelector,
  aggregationsCheckedSelector,
  (items, checkeds) =>
    compose(
      map(item => ({
        title: propOr('', 'TITLE', item),
        id: prop('CODE', item),
        type: prop('TYPE', item),
        isExpand: prop('EXPAND', item),
        totalLength: length(propOr([], 'VALUES', item)),
        values: compose(
          map(value => {
            const id = or(prop('ID', value), prop('VALUE', value))
            return {
              id,
              value: String(propOr('', 'VALUE', value)),
              checked: pathOr(false, [prop('CODE', item), id], checkeds),
              disabled: false
            }
          }),
          propOr([], 'VALUES')
        )(item)
      })),
      filter(item => prop('TYPE', item) !== 'DATE') // todo БЭК неверно фильтр по дате строит
    )(items)
)

export const loadingSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const loadedSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

const itemDataSelector = createSelector(
  state => state,
  (_, type) => type,
  (_, __, id) => id,
  (state, type, id) => pathOr({}, ['content', type, id], state)
)

export const itemSelector = createSelector(itemDataSelector, state =>
  propOr({}, 'ITEM', state)
)

export const metaItemSelector = createSelector(itemDataSelector, state =>
  pathOr({}, ['META'], state)
)

export const itemsNewsSelector = createSelector(contentsSelector, contents =>
  pathOr([], ['news', 'items'], contents)
)
export const itemsActualSelector = createSelector(contentsSelector, contents =>
  pathOr([], ['actual', 'items'], contents)
)

export const homeContent = createSelector(itemsNewsSelector, news =>
  compose(
    slice(0, COUNT_LAST),
    map(item => ({
      ...item,
      contentType: 'news'
    }))
  )(news)
)

export const documentSectionsSelector = createSelector(
  state => state,
  state => propOr([], 'documentSections', state)
)

const contentMenuSelector = createSelector(
  state => state,
  state => pathOr({}, ['contentMenu'], state)
)

export const contentMenuItemsSelector = createSelector(
  contentMenuSelector,
  state => pathOr([], ['items'], state)
)

export const contentMenuIsLoadingSelector = createSelector(
  contentMenuSelector,
  state => pathOr(false, ['isLoading'], state)
)

export const contentMenuIsLoadedSelector = createSelector(
  contentMenuSelector,
  state => pathOr(false, ['isLoaded'], state)
)
