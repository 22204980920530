import React from 'react'

import { array } from 'prop-types'
import { propOr } from 'ramda'

import Image from 'components/Image'

import styles from './UserManagers.scss'

const UserManagers = ({ managers = [] }) => (
  <div className={styles.userManagers}>
    <div className={styles.userManagersTitle}>Ваши менеджеры</div>
    {managers.map(m => (
      <div className={styles.manager}
        key={propOr('', 'ID', m)}>
        <div className={styles.managerPhoto}>
          <Image
            backgroundSize='contain'
            src={propOr('', 'PHOTO', m)}
            width={196}
            height={196} />
        </div>
        <div className={styles.managerInfo}>
          <div className={styles.managerName}>{propOr('', 'TITLE', m)}</div>
          <div className={styles.managerPosition}>
            {propOr('', 'POSITION', m)}
          </div>
          <div className={styles.managerContacts}>
            <div className={styles.managerPhone}>
              {propOr('', 'PHONE', m)}
            </div>
            <div className={styles.managerPhone}>
              {propOr('', 'MOBILE_PHONE', m)}
            </div>
            <div className={styles.managerEmail}>
              <a href={`mailto:${propOr('', 'EMAIL', m)}`}>
                {propOr('', 'EMAIL', m)}
              </a>
            </div>
          </div>
        </div>
      </div>
    ))}
  </div>
)

UserManagers.propTypes = {
  managers: array
}

UserManagers.defaultProps = {
  managers: []
}

export default UserManagers
