/* eslint-disable react/jsx-no-bind, react-hooks/exhaustive-deps */
import React, { useEffect, FC } from 'react'

import { find, propEq, propOr } from 'ramda'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import { display } from 'decorators/device'
import { activate, getActive } from 'redux/modules/tabs'
import TabsComponent from 'UI/Tabs'

interface IItem {
  id: string,
  title: string
}

interface ITabsProps {
  onClick?: (tab: IItem) => void,
  items: IItem[],
  active: string,
  name: string,
  defaultActive: string
}

const Tabs: FC<ITabsProps> = ({
  name = '',
  defaultActive,
  active,
  items,
  onClick,
  ...props
}) => {
  const activeStore: string = useSelector(({ tabs } : RootStateOrAny) => getActive(name, tabs)) as string
  const isExists: boolean = Boolean(find(propEq('id', active), items))
  const activeTab: string = isExists ? active : ''
  const activeTabName: string = activeTab || activeStore

  const dispatch = useDispatch()

  useEffect(() => {
    // @ts-ignore
    dispatch(activate({
      name,
      tab: activeTabName || defaultActive
    }))
  }, [activeTabName, defaultActive, name])

  const onHandleClick = (tab: IItem) => () => {
    onClick?.(tab)
    // @ts-ignore
    dispatch(activate({
      name,
      tab: propOr('', 'id', tab)
    }))
  }

  return (
    <TabsComponent
      handleClick={onHandleClick}
      active={activeTabName}
      items={items}
      {...props}
    />
  )
}

export default display(Tabs)
