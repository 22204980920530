/* eslint-disable complexity */
/* eslint-disable no-return-assign, react/jsx-no-bind */
import React, { PureComponent } from 'react'

import { object, bool } from 'prop-types'
import { prop, propOr } from 'ramda'
import { connect } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { asyncConnect } from 'redux-connect'

import { LayoutDesktop, LayoutMobile } from 'components/Layout'
import { Gtm } from 'components/Metrika'
import Redhelper from 'components/Redhelper'
import СookiePopup from 'components/СookiePopup'
import Analogs from 'containers/Analogs'
import Banners from 'containers/Banners'
import Device from 'containers/Device'
import DownloadModal from 'containers/DownloadModal'
import Footer from 'containers/Footer'
import Header from 'containers/Header'
import HeaderMobile from 'containers/HeaderMobile'
import MenuCatalog from 'containers/MenuCatalog'
import MetaInfo from 'containers/MetaInfo'
import Monitoring from 'containers/Monitoring'
import { NoticeAlertCatalog } from 'containers/Notice'
import {
  Hello as NotificationHello,
  Popup as NotificationPopup
} from 'containers/Notification'
import OutDatedBrowser from 'containers/OutDatedBrowser'
import PopupAuth from 'containers/PopupAuth'
import PopupChangeContractor from 'containers/PopupChangeContractor'
import PopupDownloadOrder from 'containers/PopupDownloadOrder'
import PopupProduct from 'containers/PopupProduct'
import PopupProductBarcode from 'containers/PopupProductBarcode'
import PopupRecoveryPassword from 'containers/PopupRecoveryPassword'
import PopupRegistration from 'containers/PopupRegistration'
import PopupSettingsCart from 'containers/PopupSettingsCart'
import ProgressInvoice from 'containers/ProgressInvoice'
import Socket from 'containers/Socket'
import StorageModal from 'containers/StorageModal'
import TabBar from 'containers/TabBar'
import authFromUrl from 'decorators/authFromUrl'
import customScroll from 'decorators/customScroll'
import { isDesktopSelector } from 'redux/modules/device'

import 'theme/reset.css'
import 'theme/fonts.css'
import 'theme/slick.css'
import 'theme/print.css'
import 'theme/customScroll.css'
import 'theme/react-datepicker.css'
import 'theme/formio.full.css'
import 'theme/quill.snow.css'
import 'theme/bootstrap.css'
import styles from './App.scss'

@asyncConnect([
  {
    key: 'app',
    promise: ({ helpers: { cookie, clientApi, updateData } }) => {
      updateData.set({ view: 'app', cookie, clientApi })
      return updateData.update()
    }
  }
])
@connect(
  (
    { catalog, reduxAsyncConnect, device }
  ) => {
    const isVisible = prop('isVisible', catalog)
    const loaded = propOr(false, 'loaded', reduxAsyncConnect)

    return {
      isVisible,
      loaded,
      isDesktop: isDesktopSelector(device)
    }
  },
  {}
)
@authFromUrl()
@customScroll()
class App extends PureComponent {
  static propTypes = {
    route: object,
    isVisible: bool,
    loaded: bool,
    isDesktop: bool
  }

  static defaultProps = {
    loaded: false,
    isVisible: false
  }

  render() {
    const {
      isVisible,
      loaded,
      route,
      isDesktop
    } = this.props

    return (
      <div
        className={styles.app}
        itemScope
        itemType='http://schema.org/WebPage'
      >
        <MetaInfo />
        {isDesktop ? (
          <>
            <NotificationHello />
            <Monitoring />
            <Banners />
            <Header buttonCatalogRef={el => (this.buttonCatalog = el)}/>
            <LayoutDesktop after={isVisible && <MenuCatalog buttonCatalog={this.buttonCatalog} />}>
              {renderRoutes(route.routes)}
            </LayoutDesktop>
            <Footer />
            <Redhelper />
          </>
        ) : (
          <>
            <NotificationHello />
            <HeaderMobile />
            <LayoutMobile>{renderRoutes(route.routes)}</LayoutMobile>
            <TabBar />
          </>
        )}
        <Gtm />
        <Socket />
        <Device />
        {loaded && (
          <>
            <StorageModal />
            <OutDatedBrowser />
            <NotificationPopup />
            <PopupProduct />
            <PopupProductBarcode />
            <СookiePopup />
            <NoticeAlertCatalog type='catalog' />
            <DownloadModal />
            <PopupSettingsCart />
            <ProgressInvoice />
            <PopupDownloadOrder />
            <PopupChangeContractor />
            <PopupRecoveryPassword />
            <PopupAuth />
            <PopupRegistration />
            <Analogs />
          </>
        )}
      </div>
    )
  }
}

export default App
