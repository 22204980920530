import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

import getProducts from 'helpers/getProducts'

const LoyaltyCounter = loadable(() =>
  import(
    /* webpackChunkName: "LoyaltyCounter" */ 'pages/LoyaltyCounter/LoyaltyCounter'
  )
)

export default asyncConnect([
  {
    key: 'pageLoyaltyCounter',
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    promise: ({ routes, ...props }) => {
      const state = props.store.getState()
      const points = pathOr(
        0,
        ['loyalty', 'status', 'BALANCE', 'CURRENT'],
        state
      )
      const urlParams = {
        section_id: 'all',
        sort: 'price',
        order: 'desc',
        limit: 15,
        'filter[PRICE][MAX]': points
      }
      return getProducts({
        url: 'catalog/loyalty/section/',
        urlParams,
        type: 'loyalty'
      })(props)
    }
  }
])(LoyaltyCounter)
