/* eslint-disable complexity */
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { setChecked } from 'redux/modules/products'

const initialState = {
  section: '',
  limit: 12,
  count: 1000,
  page: 1,
  selectedIndex: 0,
  type: 'list'
}

export { getLimit, getPage, getSelectedIndex, getType } from './selector'

export const setLimit = createAction('productListParams/SET_LIMIT')
export const setPage = createAction('productListParams/SET_PAGE')
export const setSelectedIndex = createAction(
  'productListParams/SET_SELECTED_INDEX'
)
export const setType = createAction('productListParams/SET_TYPE')
export const setSection = createAction('productListParams/SET_SECTION')

const handleChange = field => (state, payload) => {
  if (state[field] === payload) {
    return state
  }

  const needSetFirstPage = field === 'limit' || field === 'selectedIndex'

  if (needSetFirstPage) {
    return {
      ...state,
      page: 1,
      [field]: payload
    }
  }

  return {
    ...state,
    [field]: payload
  }
}

const handleSetPage = (state, page) =>
  loop(handleChange('page')(state, page), Effects.call(setChecked, false))

const handleSetSection = (state, section) => ({
  ...state,
  section
})

export default createReducer(on => {
  on(setLimit, handleChange('limit'))
  on(setPage, handleSetPage)
  on(setSelectedIndex, handleChange('selectedIndex'))
  on(setType, handleChange('type'))
  on(setSection, handleSetSection)
}, initialState)
