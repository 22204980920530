import React from 'react'

import cx from 'classnames'
import { string, oneOf, node, arrayOf } from 'prop-types'
import { isEmpty, not } from 'ramda'

import ContentWrapper from 'components/ContentWrapper'
import Icon from 'components/Icon'

import { getIcon } from './getIcon'
import s from './NoticeBody.scss'

const NoticeBody = ({
  text,
  type,
  buttons = []
}) => (
  <div className={s.noticeBody}>
    <div className={cx(s.noticeBody__description, {
      [s[`noticeBody__type_${type}`]]: true
    })}>
      <div
        className={cx(s.noticeBody__logo, {
          [s[`noticeBody__logo_${type}`]]: !!type
        })}
      >
        <Icon
          className={cx(s.noticeBody__icon, {
            [s[`noticeBody__icon_${type}`]]: !!type
          })}
          size='default'
          icon={getIcon(type)}
        />
      </div>
      <div className={s.noticeBody__text}>
        <ContentWrapper html={text} />
      </div>
    </div>
    {not(isEmpty(buttons)) ? (
      <div className={s.noticeBody__buttons}>
        {buttons}
      </div>
    ) : null}
  </div>
)

NoticeBody.propTypes = {
  text: string,
  type: oneOf([
    'error',
    'warning',
    'success',
    'notice',
    'info',
    'system',
    'loyalty'
  ]),
  buttons: arrayOf(node)
}

export default NoticeBody
