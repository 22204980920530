const getRangeSliderProps = prop => state => state.rangeSlider[prop]

export const getRangeSlider = state => state.rangeSlider
export const getRangeSliderMin = getRangeSliderProps('min')
export const getRangeSliderMax = getRangeSliderProps('max')
export const getRangeSliderFrom = getRangeSliderProps('from')
export const getRangeSliderTo = getRangeSliderProps('to')
export const getRangeSliderIsDrag = getRangeSliderProps('isDrag')

export const getIsShowAll = (id, state) => state.isShow[id]
export const getCheckedItems = state => state.checkedItems

export const getCheckedItemsById = (id, state) => getCheckedItems(state)[id]
