import React, { PureComponent } from 'react'

import { object, bool, number, func } from 'prop-types'
import { propOr } from 'ramda'
import { Link } from 'react-router-dom'

import ProductOne from 'components/ProductPage/ProductOne'
import { setAnalyticsProps } from 'helpers/metaTags'

import styles from './ProductQuickView.scss'

export default class ProductQuickView extends PureComponent {
  static propTypes = {
    product: object,
    location: object,
    galleryItems: object,
    gallerySettings: object,
    galleryModalSettings: object,
    gallerySpriteData: object,
    isUserWithoutPassword: bool,
    isLoyalty: bool,
    isBookmark: bool,
    isLoaded: bool,
    bookmark: number,
    hasLoyaltyAccess: bool,
    hideModal: func,
    fetchSpritePhoto: func,
    onNavigateTo: func
  }

  static defaultProps = {
    galleryItems: {},
    gallerySettings: {},
    galleryModalSettings: {}
  }

  render() {
    const {
      product,
      isUserWithoutPassword,
      isLoyalty,
      isBookmark,
      bookmark,
      hasLoyaltyAccess,
      hideModal,
      onNavigateTo,
      isLoaded,
      galleryItems,
      gallerySettings,
      galleryModalSettings,
      gallerySpriteData,
      location,
      fetchSpritePhoto
    } = this.props
    return (
      <div
        className={styles.container}
        {...setAnalyticsProps({
          type: 'action',
          group: 'extra',
          action: 'productQuickView',
          product: propOr('', 'CODE_1C', product)
        })}
      >
        <Link to={`${
          isBookmark ? `/bookmark/${bookmark}` : ''
        }/catalog/product/${propOr('', 'ID', product)}`}
        >
          <span className={styles.title}>{propOr('', 'NAME', product)}</span>
        </Link>
        <ProductOne
          location={location}
          id={propOr('', 'ID', product)}
          item={product}
          isUserWithoutPassword={isUserWithoutPassword}
          isLoyalty={isLoyalty}
          isBookmark={isBookmark}
          bookmark={bookmark}
          hasLoyaltyAccess={hasLoyaltyAccess}
          galleryItems={galleryItems}
          gallerySettings={gallerySettings}
          galleryModalSettings={galleryModalSettings}
          gallerySpriteData={gallerySpriteData}
          isQuickView
          hideModal={hideModal}
          onNavigateTo={onNavigateTo}
          tabsName='ProductQuckViewTabs'
          isProductLoaded={isLoaded}
          fetchSpritePhoto={fetchSpritePhoto}
        />
      </div>
    )
  }
}
