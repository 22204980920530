import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, array, node, element, oneOfType, func } from 'prop-types'
import { isEmpty, prop } from 'ramda'

import { setAnalyticsProps } from 'helpers/metaTags'
import Button from 'UI/Button'

import styles from './OrderStatus.scss'

const actionNames = {
  confirm: 'Подтвердить',
  restore: 'Восстановить',
  cancel: 'Вернуть',
  delete: 'Отменить'
}

class OrderStatus extends PureComponent {
  static propTypes = {
    num: string,
    code: string,
    actions: array,
    children: oneOfType([element, node, string]),
    onOrderAction: func
  }

  static defaultProps = {
    num: '',
    code: '',
    actions: [],
    onOrderAction: () => {}
  }

  handleOrderAction = actionType => () => this.props.onOrderAction(actionType)

  renderButton = actionType => (
    <Button
      key={actionType}
      type='button'
      className={cx(styles.button, styles[`button_${actionType}`])}
      title={prop(actionType, actionNames)}
      onClick={this.handleOrderAction(actionType)}
      {...setAnalyticsProps({
        type: 'action',
        group: 'orderButton',
        action: actionType,
        product: this.props.num
      })}
    />
  )

  render() {
    const { code, actions, children } = this.props
    return (
      <div
        className={cx(styles.OrderStatus, {
          [styles[`OrderStatus_${code}`]]: !!code
        })}
      >
        {isEmpty(actions) ? (
          children
        ) : (
          <div className={styles.actionButtons}>
            {actions.map(this.renderButton)}
          </div>
        )}
      </div>
    )
  }
}

export default OrderStatus
