import React, { FC, memo, useMemo } from 'react'

import cx from 'classnames'
import { map, propOr } from 'ramda'

import Portal from 'components/Portal'
import DisableBodyScroll from 'containers/DisableBodyScroll/DisableBodyScroll'
import { ClickedValueType, IMenu, IMenuOption } from 'UI/Select/types'

import s from './Select.scss'

type ItemType = 'title' | 'value' | 'handleSelect' | 'selectedValue'

const Item: FC<Pick<IMenuOption, ItemType>> = ({
  title,
  value,
  handleSelect,
  selectedValue
}) => {
  const isSelected = useMemo(
    () => String(selectedValue) === String(value),
    [selectedValue, value]
  )
  const handleClick = (clickedValue: ClickedValueType) => () =>
    handleSelect({ clickedValue, isSelected })

  return (
    <li
      className={cx(s.mobileList__item, {
        [s.mobileList__item_selected]: isSelected
      })}
      role='presentation'
      value={value}
      onClick={handleClick(value)}
    >
      {title}
    </li>
  )
}

const MenuMobile: FC<IMenu> = ({ options, ...rest }) => (
  <DisableBodyScroll>
    <Portal>
      <div className={s.wrapper}>
        <ul className={s.mobileList}>
          {map(option => (
            <Item
              title={propOr('', 'title', option)}
              value={propOr('', 'value', option)}
              {...rest}
            />
          ))(options)}
        </ul>
      </div>
    </Portal>
  </DisableBodyScroll>
)

export default memo(MenuMobile)
