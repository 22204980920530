import React, { PureComponent } from 'react'

import cx from 'classnames'
import { object, func } from 'prop-types'
import { propOr, compose, prop, map, toLower, head, isEmpty } from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import ContentWrapper from 'components/ContentWrapper'
import Icon from 'components/Icon'
import Wrapper from 'components/Wrapper'
import { forAuth } from 'decorators/user'
import { socketSend } from 'redux/modules/socket'

import s from './Notification.scss'

const notificationHello = createSelector(
  state => state,
  state => propOr([], 'notificationHello', state)
)

const notificationSelector = createSelector(notificationHello, hello =>
  compose(
    head,
    map(item => ({
      text: propOr('', 'text', item),
      link: propOr('', 'link', item),
      status: toLower(propOr('info', 'status', item)),
      isClose: Boolean(propOr(0, 'viewable', item)),
      id: prop('id', item)
    }))
  )(hello)
)

@connect(
  ({ socket }) => ({
    notification: notificationSelector(socket)
  }),
  {
    socketSend
  }
)
class Hello extends PureComponent {
  static propTypes = {
    notification: object,
    socketSend: func
  }

  static defaultProps = {
    notification: {},
    socketSend: () => {}
  }

  handleCloseHello = () => {
    const { notification } = this.props
    const id = prop('id', notification)
    this.props.socketSend({ type: 'read', id })
  }

  render() {
    const { notification } = this.props
    if (isEmpty(notification)) return null
    return (
      <div
        key={prop('id', notification)}
        className={cx(s.helloItem, {
          [s[`helloItem_${prop('status', notification)}`]]: !!prop(
            'status',
            notification
          )
        })}
      >
        <Wrapper>
          <div className={s.helloDescription}>
            <div className={s.message}>
              <ContentWrapper html={prop('text', notification)} />
            </div>
            {prop('isClose', notification) && (
              <div
                role='presentation'
                className={s.helloClose}
                onClick={this.handleCloseHello}
              >
                <Icon icon='close'
                  size='small'
                  className={s.icon} />
              </div>
            )}
          </div>
        </Wrapper>
      </div>
    )
  }
}
export default forAuth(Hello)
