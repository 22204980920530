/* eslint-disable complexity */
import axios from 'axios'
import { push } from 'connected-react-router'
import { has, path, pathOr, prop, propOr, isEmpty } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage, { getErrorMessages } from 'helpers/getErrorMessage'
import { hideModal, showModal } from 'redux/modules/modal'

export {
  getInvoice,
  getProducts,
  getIsLoading,
  getIsLoaded,
  getSortList,
  getCount,
  getWithoutInfo,
  getIsError,
  getSubmitErrors,
  getLoadingProductsIds,
  getIsLoadingCategory,
  getIsSubmitError,
  getIsSubmitErrorText,
  getTabs,
  getCheckedTabs,
  getStatus,
  getIsLoadingComments,
  getOperations,
  getIsFilled,
  getCategories,
  getAddedProductsIds,
  getIsLoadingPreview,
  getPreviewProducts,
  getUpdatedCategories,
  getMainImages,
  getMainComment
} from './selector'

const initialState = {
  invoice: {
    name: '',
    date: -1,
    id: ''
  },
  status: '',
  operations: {},
  categories: {},
  updatedCategories: [],
  sortList: [],
  products: [],
  previewProducts: [],
  submitErrors: [],
  loadingProductsIds: [],
  addedProductsIds: [],
  count: 0,
  isLoading: false,
  isLoaded: false,
  isError: false,
  withoutInfo: false,
  isLoadingCategory: false,
  isLoadingComments: false,
  isLoadingPreview: false
}

const updateProductData = (productData, newData) => {
  const data = { ...productData }
  Object.keys(newData).forEach(key => {
    if (key === 'description') {
      data.DESCRIPTIONS = newData[key]
      return
    }

    if (key === 'keepQuantity') {
      data.KEEP_QUANTITY = newData[key]
      return
    }

    const property = key.toUpperCase()
    data[property] = newData[key]
  })

  return data
}

export const fetchProducts = createAction('claimEdit/FETCH_PRODUCTS')
const fetchProductsSuccess = createAction('claimEdit/FETCH_PRODUCTS_SUCCESS')
const fetchProductsFailure = createAction('claimEdit/FETCH_PRODUCTS_FAILURE')

export const fetchAggregation = createAction('claimEdit/FETCH_AGGREGATION')
const fetchAggregationSuccess = createAction(
  'claimEdit/FETCH_AGGREGATION_SUCCESS'
)
const fetchAggregationFailure = createAction(
  'claimEdit/FETCH_AGGREGATION_FAILURE'
)

export const fetchProductUpdate = createAction('claimEdit/FETCH_PRODUCT_UPDATE')
export const fetchProductUpdateSuccess = createAction(
  'claimEdit/FETCH_PRODUCT_SELECT_SUCCESS'
)
export const fetchFailure = createAction(
  'claimEdit/FETCH_PRODUCT_SELECT_FAILURE'
)

export const fetchCategoryAdd = createAction('claimEdit/FETCH_CATEGORY_ADD')
const fetchCategoryAddSuccess = createAction(
  'claimEdit/FETCH_CATEGORY_ADD_SUCCESS'
)

export const fetchSend = createAction('claimEdit/FETCH_SEND')
const fetchSendSuccess = createAction('claimEdit/FETCH_SEND_SUCCESS')

export const fetchUpdate = createAction('claimEdit/FETCH_UPDATE')
const fetchUpdateSuccess = createAction('claimEdit/FETCH_UPDATE_SUCCESS')

let source = null
let aggregationSource = null

const requestAggregation =
  ({ clientApi, cancelToken }) =>
    ({ id }) =>
      clientApi
        .get(`/v3/contractor/pretension/${id}/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          },
          cancelToken
        })
        .then(fetchAggregationSuccess)
        .catch(fetchAggregationFailure)

const requestProducts =
  ({ clientApi, cancelToken }) =>
    params => {
      const onlyOperations = prop('type', params) === 'operations'
      const category = prop('category', params)
      const id = prop('id', params)
      const url = `/v3/contractor/pretension/${id}/category/${category}/${
        onlyOperations ? 'group_operation/' : ''
      }`
      return clientApi[onlyOperations ? 'get' : 'post'](url, {
        params: {
          ...params,
          contractor_id: clientApi.getContractorId()
        },
        cancelToken
      })
        .then(fetchProductsSuccess)
        .catch(fetchProductsFailure)
    }

const requestCategoryAdd =
  ({ clientApi }) =>
    ({ onError = fetchFailure, ...params }) => {
      const category = propOr('', 'category', params)
      const id = propOr('', 'id', params)
      const comment = propOr('', 'comment', params)
      const isUpdate = prop('isUpdate', params)

      return clientApi
        .post(
          `/v3/contractor/pretension/${id}/category/${category}/${
            isUpdate ? 'update' : 'add'
          }/`,
          {
            params: {
              contractor_id: clientApi.getContractorId(),
              comment
            }
          }
        )
        .then(res => fetchCategoryAddSuccess({ res, params }))
        .catch(onError)
    }

const requestProductSelect =
  ({ clientApi }) =>
    params => {
      const productId = propOr('', 'productId', params)
      const category = propOr('', 'category', params)
      const id = propOr('', 'id', params)
      const data = propOr({}, 'newData', params)
      const isSelected = propOr(false, 'select', data)
      const action = !isSelected ? 'delete' : 'add'

      return clientApi
        .post(
          `/v3/contractor/pretension/${id}/category/${category}/product/${action}/${productId}/`,
          {
            params: {
              contractor_id: clientApi.getContractorId()
            }
          }
        )
        .then(res => fetchProductUpdateSuccess({ ...params, ...res }))
        .catch(err => fetchFailure({ productId, ...err }))
    }

const requestProductChange =
  ({ clientApi }) =>
    params => {
      const productId = propOr('', 'productId', params)
      const category = propOr('', 'category', params)
      const id = propOr('', 'id', params)
      const data = propOr({}, 'newData', params)

      return clientApi
        .post(
          `/v3/contractor/pretension/${id}/category/${category}/product/update/${productId}/`,
          {
            params: {
              ...data,
              contractor_id: clientApi.getContractorId()
            }
          }
        )
        .then(res => fetchProductUpdateSuccess({ ...params, ...res }))
        .catch(err => fetchFailure({ productId, ...err }))
    }

const requestProductUpdateAll =
  ({ clientApi }) =>
    params => {
      const category = propOr('', 'category', params)
      const id = propOr('', 'id', params)
      const operation = propOr('', 'operation', params)

      return clientApi
        .post(
          `/v3/contractor/pretension/${id}/category/${category}/group_operation/${operation}/`,
          {
            params: {
              contractor_id: clientApi.getContractorId()
            }
          }
        )
        .then(res => fetchProductUpdateSuccess({ ...params, ...res }))
        .catch(fetchFailure)
    }

const requestSend =
  ({ clientApi }) =>
    id =>
      clientApi
        .post(`/v3/contractor/pretension/${id}/send/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(res => fetchSendSuccess({ id, ...res }))
        .catch(fetchFailure)

const requestUpdate =
  ({ clientApi }) =>
    ({ onError = fetchFailure, ...params }) => {
      const id = propOr('', 'id', params)
      const comment = propOr('', 'comment', params)
      return clientApi
        .post(`/v3/contractor/pretension/${id}/update/`, {
          params: {
            contractor_id: clientApi.getContractorId(),
            comment
          }
        })
        .then(res => fetchUpdateSuccess({ id, ...res }))
        .catch(onError)
    }

const handleFetchProducts = (state, params, { clientApi }) => {
  const type = propOr('products', 'type', params)
  const newState = { ...state }

  if (type === 'products') {
    newState.isLoading = true
    newState.isLoaded = false
    newState.isError = false
  }

  if (type === 'preview') {
    newState.isLoadingPreview = true
  }

  if (source && source.cancel) {
    source.cancel()
  }

  source = axios.CancelToken.source()

  return loop(
    newState,
    Effects.promise(
      requestProducts({ clientApi, cancelToken: source.token }),
      params
    )
  )
}

const handleFetchProductsSuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'response'], payload)
  const nav = propOr({}, 'NAV', response)
  const isLoading = prop('isLoading', state)
  const isLoadingPreview = prop('isLoadingPreview', state)
  const operations = propOr([], 'OPERATIONS', response)
  const items = propOr([], 'ITEMS', response)

  const newState = {
    ...state,
    operations: operations.reduce(
      (acc, item) => ({
        ...acc,
        [prop('CODE', item)]: prop('VALUE', item)
      }),
      {}
    )
  }

  if (isLoading) {
    newState.isLoading = false
    newState.isLoaded = true
    newState.sortList = propOr([], 'SORT', nav)
    newState.count = propOr(0, 'CNT', nav)
    newState.withoutInfo = propOr(false, 'WITHOUT_INFO', nav)
    newState.products = items
  }

  if (isLoadingPreview) {
    newState.previewProducts = items
    newState.isLoadingPreview = false
  }

  return newState
}

const handleFetchProductsFailure = (state, payload) => {
  if (axios.isCancel(payload)) return { ...state }

  if (!prop('isLoading', state))
    return loop(
      {
        ...state,
        submitErrors: [getErrorMessage(payload)]
      },
      Effects.call(showModal, 'claimEditError')
    )

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    isError: true,
    products: [],
    withoutInfo: false,
    count: 0,
    sortList: [],
    operations: [],
    previewProducts: [],
    isLoadingPreview: false
  }
}

const handleFetchAggregation = (state, params, { clientApi }) => {
  const isLoadingCategory = propOr(true, 'isLoading', params)
  const category = prop('category', params)

  const newState = {
    ...state,
    isLoadingCategory
  }

  if (prop('reset', params)) {
    newState.invoice = {
      name: '',
      date: -1,
      id: ''
    }

    newState.isError = false
    newState.categories = {}
    newState.updatedCategories = []
  }

  if (category) {
    const updatedCategories = propOr([], 'updatedCategories', state)
    newState.updatedCategories = updatedCategories.includes(category)
      ? updatedCategories
      : [...updatedCategories, category]
  }

  if (aggregationSource && aggregationSource.cancel) aggregationSource.cancel()

  aggregationSource = axios.CancelToken.source()

  return loop(
    newState,
    Effects.promise(
      requestAggregation({ clientApi, cancelToken: aggregationSource.token }),
      params
    )
  )
}

const handleFetchAggregationSuccess = (state, payload) => {
  const item = pathOr('', ['data', 'response', 'ITEM'], payload)
  const invoice = propOr({}, 'SHIPMENT', item)

  return {
    ...state,
    invoice: {
      name: propOr('', 'NUM', invoice),
      date: propOr(-1, 'DATE', invoice),
      id: propOr('', 'ID', invoice)
    },
    status: propOr('', 'STATUS', item),
    mainImages: propOr([], 'IMAGES', item),
    mainComment: propOr('', 'COMMENT', item),
    categories: pathOr('', ['data', 'response', 'CATEGORIES'], payload),
    isLoadingCategory: false,
    isLoadingComments: false,
    isError: propOr(0, 'CNT', invoice) === 0
  }
}

const handleFetchAggregationFailure = (state, payload) => {
  if (axios.isCancel(payload)) return { ...state }
  return {
    ...state,
    invoice: {
      name: '',
      date: -1,
      id: ''
    },
    status: '',
    categories: {},
    isError: true,
    isLoadingCategory: false,
    isLoadingComments: false
  }
}

const handleFetchProductUpdate = (state, params, { clientApi }) => {
  const effects = []
  const newState = {
    ...state,
    submitErrors: []
  }
  const action = prop('action', params)
  const productId = prop('productId', params)
  const categories = propOr([], 'categories', state)
  const category = prop('category', params)

  if (productId)
    newState.loadingProductsIds = [
      ...propOr([], 'loadingProductsIds', state),
      productId
    ]

  switch (action) {
    case 'add':
    case 'select':
      const request = requestProductSelect({ clientApi })
      effects.push(
        has(category, categories)
          ? Effects.promise(request, { newCategory: false, ...params })
          : Effects.call(fetchCategoryAdd, {
            ...params,
            callback: Effects.promise(request, params)
          })
      )
      break

    case 'updateAll':
      newState.isLoadingCategory = true
      effects.push(
        has(category, categories)
          ? Effects.promise(requestProductUpdateAll({ clientApi }), params)
          : Effects.call(fetchCategoryAdd, {
            ...params,
            callback: Effects.promise(
              requestProductUpdateAll({ clientApi }),
              params
            )
          })
      )
      break

    default:
      effects.push(Effects.promise(requestProductChange({ clientApi }), params))
      break
  }

  return loop(newState, Effects.batch(effects))
}

const handleFetchProductUpdateSuccess = (state, payload) => {
  const updatedProductId = propOr('', 'productId', payload)
  const newCategory = propOr(false, 'newCategory', payload)
  const response = pathOr({}, ['data', 'response'], payload)
  const isSuccess = prop('SUCCESS', response) === 'Y'
  const newState = { ...state }

  if (updatedProductId !== '') {
    const loadingIds = propOr([], 'loadingProductsIds', state)
    newState.loadingProductsIds = loadingIds.filter(
      loadingId => loadingId !== updatedProductId
    )
  }

  if (!isSuccess) {
    const errors = pathOr({}, ['data', 'errors'], payload)
    newState.submitErrors = getErrorMessages(errors)
    newState.isLoadingCategory = false

    return loop(newState, Effects.call(showModal, 'claimEditError'))
  }

  const id = prop('id', payload)
  const effects = [
    Effects.call(fetchAggregation, { id, isLoading: newCategory })
  ]
  const products = propOr([], 'products', state)
  const previewProducts = propOr([], 'previewProducts', state)
  const validStates = propOr([], 'ITEMS', response)
  const newData = propOr({}, 'newData', payload)
  const action = prop('action', payload)
  const operation = prop('operation', payload)
  const operationValue = path(['operations', operation], state)
  const isAdd = action === 'add'
  const isPreview = prop('isPreview', payload)
  const category = prop('category', payload)
  const isLoading = prop('isLoading', state) || prop('isLoadingPreview', state)

  if (!isLoading && isAdd) {
    const addedProductsIds = propOr([], 'addedProductsIds', state)
    newState.addedProductsIds = addedProductsIds.includes(updatedProductId)
      ? addedProductsIds.filter(item => item !== updatedProductId)
      : [updatedProductId, ...addedProductsIds]
  }

  if (!isLoading && !isAdd)
    newState.products = products.map(product => {
      const productId = prop('ID', product)
      if (productId !== updatedProductId && action !== 'updateAll')
        return product

      const data = propOr({}, 'DATA', product)
      const isSelect = prop('SELECT', data)

      if (!isEmpty(validStates)) {
        const validState = validStates.find(
          item => prop('ID', item) === productId
        )
        data.VALID = prop('VALID', validState)
      }

      if (operation === 'SET_MAX_QUANTITY' && isSelect)
        data.QUANTITY = operationValue ? 1 : prop('QUANTITY', product)

      if (operation === 'TRACES_OPENING' && isSelect)
        data.OPENED = !operationValue

      if (operation === 'KEEP' && isSelect) data.KEEP = !operationValue

      if (operation === 'SEPARATELY' && isSelect)
        data.SEPARATELY = !operationValue

      if (operation === 'TOGETHER_SHIPMENT' && isSelect)
        data.TOGETHER_SHIPMENT = !operationValue

      if (operation === 'SELECT_ALL') {
        data.SELECT = !operationValue

        if (!isSelect) {
          data.QUANTITY = 1
          data.KEEP = false
          data.OPENED = false
          data.KEEP_QUANTITY = 0
        }
      }

      return {
        ...product,
        DATA: updateProductData(data, newData)
      }
    })

  if (isPreview) {
    const updatedCategories = propOr([], 'updatedCategories', state)
    newState.updatedCategories = updatedCategories.includes(category)
      ? updatedCategories
      : [...updatedCategories, category]

    newState.previewProducts = previewProducts.map(product => {
      if (prop('ID', product) !== updatedProductId) return product

      const data = propOr({}, 'DATA', product)

      return {
        ...product,
        DATA: updateProductData(data, newData)
      }
    })
  }

  if (!isLoading)
    effects.push(
      Effects.call(fetchProducts, {
        id,
        type: isAdd ? 'products' : 'operations',
        category: propOr('', 'category', payload)
      })
    )

  return loop(newState, Effects.batch(effects))
}

const handleFetchFailure = (state, payload) => {
  const productId = prop('productId', payload)
  const loadingProductsIds = propOr([], 'loadingProductsIds', state)
  const isErrors = path(['data', 'response', 'SUCCESS'], payload) === 'N'

  const newState = {
    ...state,
    isLoadingCategory: false,
    isLoadingComments: false,
    loadingProductsIds: productId
      ? loadingProductsIds.filter(id => id !== productId)
      : []
  }

  if (isErrors) {
    const errors = pathOr({}, ['data', 'errors'], payload)
    newState.submitErrors = getErrorMessages(errors)
  } else newState.submitErrors = [getErrorMessage(payload)]

  return loop(newState, Effects.call(showModal, 'claimEditError'))
}

const handleFetchCategoryAdd = (state, params, { clientApi }) => {
  const categories = prop('categories', state)
  const category = propOr('', 'category', params)
  const isUpdate = has(category, categories)

  return loop(
    {
      ...state,
      isLoadingComments: true,
      isLoadingCategory: !isUpdate
    },
    Effects.promise(requestCategoryAdd({ clientApi }), { ...params, isUpdate })
  )
}

const handleFetchCategoryAddSuccess = (state, payload) => {
  const effects = propOr([], 'effects', payload)
  const response = propOr({}, 'res', payload)
  const params = propOr({}, 'params', payload)
  const callback = prop('callback', params)
  const isSuccess = path(['data', 'response', 'SUCCESS'], response) === 'Y'
  const newState = { ...state }

  if (!isSuccess) {
    const errors = pathOr({}, ['data', 'errors'], response)
    newState.submitErrors = getErrorMessages(errors)
    newState.loadingProductsIds = []
    newState.isLoadingCategory = false
    newState.isLoadingComments = false
    effects.push(Effects.call(showModal, 'claimEditError'))
  }

  if (isSuccess)
    effects.push(
      callback ||
        Effects.call(fetchAggregation, {
          id: propOr('', 'id', params),
          isLoading: prop('isLoadingCategory', state)
        })
    )

  return loop(newState, Effects.batch(effects))
}

const handleFetchSend = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoadingCategory: true,
      isLoadingComments: true
    },
    Effects.promise(requestSend({ clientApi }), params)
  )

const handleFetchSendSuccess = (state, payload) => {
  const effects = []
  const errors = pathOr({}, ['data', 'errors'], payload)
  const isSuccess = path(['data', 'response', 'SUCCESS'], payload) === 'Y'
  const id = prop('id', payload)

  const newState = {
    ...state,
    isLoadingCategory: false,
    isLoadingComments: false
  }

  if (isSuccess) {
    newState.submitErrors = getErrorMessages(errors)
    effects.push(Effects.call(hideModal))
    effects.push(Effects.call(push, `/cabinet/claim/${id}/thanks`))
  } else effects.push(Effects.call(showModal, 'claimEditError'))

  return loop(newState, Effects.batch(effects))
}

const handleFetchUpdate = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoadingCategory: true,
      isLoadingComments: true
    },
    Effects.promise(requestUpdate({ clientApi }), params)
  )

const handleFetchUpdateSuccess = (state, payload) => {
  const effects = []
  const isSuccess = path(['data', 'response', 'SUCCESS'], payload) === 'Y'

  const newState = {
    ...state,
    isLoadingCategory: false,
    isLoadingComments: false
  }

  if (!isSuccess) effects.push(Effects.call(showModal, 'claimEditError'))

  return loop(newState, Effects.batch(effects))
}

export default createReducer(on => {
  on(fetchProducts, handleFetchProducts)
  on(fetchProductsSuccess, handleFetchProductsSuccess)
  on(fetchProductsFailure, handleFetchProductsFailure)
  on(fetchAggregationSuccess, handleFetchAggregationSuccess)
  on(fetchAggregationFailure, handleFetchAggregationFailure)
  on(fetchAggregation, handleFetchAggregation)
  on(fetchProductUpdate, handleFetchProductUpdate)
  on(fetchProductUpdateSuccess, handleFetchProductUpdateSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchCategoryAdd, handleFetchCategoryAdd)
  on(fetchCategoryAddSuccess, handleFetchCategoryAddSuccess)
  on(fetchSend, handleFetchSend)
  on(fetchSendSuccess, handleFetchSendSuccess)
  on(fetchUpdate, handleFetchUpdate)
  on(fetchUpdateSuccess, handleFetchUpdateSuccess)
}, initialState)
