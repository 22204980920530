/* eslint-disable react/no-unused-class-component-methods */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'

import Icon from 'components/Icon'
import Image from 'components/Image'

import styles from './PopupImage.scss'

class PopupImage extends PureComponent {
  static propTypes = {
    className: pt.string,
    src: pt.string,
    onClose: pt.func,
    withoutOverlay: pt.bool,
    width: pt.number,
    height: pt.number
  }

  static defaultProps = {
    width: 600,
    height: 600
  }

  setPopupPosition = (left, top) => {
    const popup = this.imageWrapper
    popup.style.left = `${left}px`
    popup.style.top = `${top}px`
    popup.style.transform = 'none'
  }

  render() {
    const { src, onClose, width, height, className } = this.props
    const classes = cx(styles.popup, {
      [className]: true,
      [styles.withoutOverlay]: this.props.withoutOverlay
    })

    return (
      <div role='presentation'
        className={classes}
        onClick={onClose}>
        <div
          className={styles.imageWrapper}
          ref={el => {
            this.imageWrapper = el
          }}
        >
          <Image
            backgroundSize='contain'
            imgClassName={cx(styles.previewImage, styles.previewWrapper)}
            width={width}
            height={height}
            src={src}
            onClick={onClose}
          />
          <Icon icon='close'
            className={styles.popupCloseIcon} />
        </div>
      </div>
    )
  }
}

export default PopupImage
