/* eslint-disable complexity */
import { prop, has, isEmpty, propOr } from 'ramda'
import { createSelector } from 'reselect'

import { parseTitle } from 'helpers/addressDelivery'

export const getIsLoaded = createSelector(
  state => state,
  state => prop('isLoaded', state)
)

export const getIsLoadedManager = createSelector(
  state => state,
  state => prop('isLoadedManager', state)
)

export const getIsLoadedFullAddress = createSelector(
  state => state,
  state => prop('isLoadedFullAddress', state)
)

export const getIsLoading = createSelector(
  state => state,
  state => prop('isLoading', state)
)

export const getAddress = createSelector(
  state => state,
  state => prop('address', state)
)

export const getDeliveries = createSelector(
  state => state,
  state => prop('deliveries', state)
)

export const getAddressLaw = createSelector(
  state => state,
  state => prop('address_law', state)
)

export const getWorkPhone = createSelector(
  state => state,
  state => prop('work_phone', state)
)

export const getPersonalPhone = createSelector(
  state => state,
  state => prop('personal_phone', state)
)

export const getTitle = createSelector(
  state => state,
  state => prop('title', state)
)

export const getEmail = createSelector(
  state => state,
  state => prop('email', state)
)

export const getHoldings = createSelector(
  state => state,
  state => prop('holdings', state)
)

export const getErrorAddDelivery = createSelector(
  state => state,
  state => prop('errorAddDelivery', state)
)

export const getCountries = createSelector(
  state => state,
  state => prop('countries', state)
)

export const getIsAddingDelivery = createSelector(
  state => state,
  state => prop('isAddingDelivery', state)
)

export const getIsExists = createSelector(
  state => state,
  state => prop('isExists', state)
)

export const getIsCheckingDelivery = createSelector(
  state => state,
  state => prop('isCheckingDelivery', state)
)

export const getIsFetchingDelivery = createSelector(
  state => state,
  state => prop('isFetchingDelivery', state)
)

export const getIsDeletingDelivery = createSelector(
  state => state,
  state => prop('isDeletingDelivery', state)
)

export const getDelivery = createSelector(
  state => state,
  state => {
    const delivery = prop('delivery', state)

    if (isEmpty(delivery)) return {}

    const fields = {}

    Object.keys(delivery).forEach(key => {
      const value = prop(key, delivery)
      const name = key.toLowerCase()

      fields[name] = name === 'personal' ? Boolean(+value) : value
    })

    const title = prop('title', fields)

    return has('house')(fields) && !title
      ? fields
      : {
        ...fields,
        ...parseTitle(prop('title', fields))
      }
  }
)

export const getIsScheduleForWeek = createSelector(getDelivery, delivery =>
  propOr(true, 'is_schedule_for_week', delivery)
)
