import React from 'react'

import cx from 'classnames'
import { object, node, arrayOf, oneOfType, string } from 'prop-types'
import { isEmpty } from 'ramda'

import s from './BannerWrapper.scss'

const BannerWrapper = ({
  banner,
  className,
  children,
  ...rest
}) => {

  if (isEmpty(banner)) return null

  const Tag = banner.LINK ? 'a' : 'div'

  const setProps = () => banner.LINK
    ? {
      href: banner.LINK,
      target: `_${banner.TARGET}`,
      itemProp: 'image'
    }
    : {}

  return (
    <Tag {...setProps()}
      {...rest}
      className={cx(s.bannerWrapper, className)}>
      {children}
    </Tag>
  )
}

BannerWrapper.propTypes = {
  banner: object,
  className: string,
  children: oneOfType([node, arrayOf(node)])
}

BannerWrapper.defaultProps = {
  banner: {}
}

export default BannerWrapper
