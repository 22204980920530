/* eslint-disable complexity */
import React, { memo, useCallback } from 'react'

import { func } from 'prop-types'
import { prop, propOr, path, pathOr, slice } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

import HeaderComponent from 'components/Header'
import {
  isKdClientSelector,
  isOptClientSelector
} from 'redux/modules/apiAuth'
import { setCatalogVisible, setListening } from 'redux/modules/catalog'
import { compareCount as compareCountSelector } from 'redux/modules/compare'
import { favoriteCount as favoriteCountSelector } from 'redux/modules/favorite'
import {
  fetch as fetchInvoice,
  invoiceItemsSelector as prepareInvoiceItems
} from 'redux/modules/invoice'
import {
  loyaltyHeaderSelector,
  getLoyaltyCards,
  getCurrentCard,
  getLastCompletedGuid
} from 'redux/modules/loyalty'
import { showModal, hideModal } from 'redux/modules/modal'
import {
  getContractors,
  setContractor,
  userIdSelector,
  getActiveContractor,
  getIsSuperuser,
  getMessage,
  getSupports,
  getIsExistsSuperuser,
  adminTokenSelector,
  gotoSuperuser,
  discountsAccessSelector,
  getIsLoaded,
  tempUserSelector,
  menuAccessSelector
} from 'redux/modules/personal'
import { menuSelector, pathnameSelector } from 'redux/modules/settings'
import { getTheme } from 'redux/modules/theme'
import { resetError, clearToken } from 'redux/modules/token'

const EMPTY_ARRAY = []
const INVOICES_LAST_4 = 4

const modeSelector = createSelector(
  state => state,
  settings => prop('mode', settings)
)

const isUserWithoutPasswordSelector = createSelector(
  state => prop('token', state),
  state => propOr(true, 'isUserWithoutPassword', state)
)

const isVisibleSelector = createSelector(
  state => state,
  catalog => propOr(false, 'isVisible', catalog)
)

const getHeaderAccess = createSelector(
  state => modeSelector(prop('settings', state)),
  isKdClientSelector,
  isUserWithoutPasswordSelector,
  path(['personal', 'ok918']),
  path(['personal', 'accesses', 'OFFERS', 'OFFLINE']),
  path(['personal', 'accesses', 'OFFERS', 'MARKDOWN']),
  path(['personal', 'accesses', 'LOYALTY']),
  path(['personal', 'accesses', 'REGISTRATION']),
  path(['loyalty', 'status', 'ACCESSES', 'ACCESS']),
  path(['personal', 'status', 'MEMBER']),
  path(['loyalty', 'status', 'ACCESSES', 'FORECAST']),
  path(['loyalty', 'status', 'ACCESSES', 'HISTORY']),
  path(['loyalty', 'status', 'ACCESSES', 'STATUS']),
  (
    mode,
    isKd,
    isUserWithoutPassword,
    ok918,
    offers,
    markdown,
    loyalty,
    registration,
    access,
    member,
    forecast,
    history,
    status
  ) => {
    const isLoyalty = mode === 'loyalty'
    const canJoin =
      access && !member && forecast === undefined && history === undefined

    return {
      canJoin,
      isUserInfoVisible: !isUserWithoutPassword,
      isPhoneVisible: !isUserWithoutPassword,
      isManagerVisible: !isLoyalty,
      isCabinetVisible: !isUserWithoutPassword || isLoyalty,
      isHelpVisible: !isUserWithoutPassword && !isLoyalty,
      isPartnerVisible: !isUserWithoutPassword && !isLoyalty && ok918,
      isWhyWeVisible: isUserWithoutPassword,
      isLoginFormVisible: isUserWithoutPassword && !isLoyalty,
      isRegistrationVisible: registration && isUserWithoutPassword,
      isLoyaltyVisible: !isUserWithoutPassword && loyalty,
      isLoyaltyMember: !isUserWithoutPassword && member,
      isCashbackVisible: isLoyalty,
      isHistoryVisible: isLoyalty && (history || canJoin) && !isKd,
      isStatusVisible: isLoyalty && (forecast || canJoin) && status,
      isMiniBasketVisible: !isUserWithoutPassword,
      isRulesVisible: isLoyalty && access && !member,
      isPromotionalAccount: isLoyalty && access && member && !forecast,
      isOffersVisible: offers || markdown
    }
  }
)

const invoiceStateSelector = createSelector(
  state => state,
  state => pathOr({}, ['invoice', 'headerItems'], state)
)

const invoiceItemsSelector = createSelector(invoiceStateSelector, invoice =>
  prepareInvoiceItems(invoice)
)

const invoiceLastItems = createSelector(invoiceItemsSelector, state =>
  slice(0, INVOICES_LAST_4, state)
)

const invoiceIsLoadedSelector = createSelector(invoiceStateSelector, state =>
  propOr(false, 'isLoaded', state)
)

const invoiceIsLoadingSelector = createSelector(invoiceStateSelector, state =>
  propOr(false, 'isLoading', state)
)

const invoiceSelector = createSelector(
  invoiceLastItems,
  invoiceIsLoadedSelector,
  invoiceIsLoadingSelector,
  (list, isLoaded, isLoading) => ({ list, isLoaded, isLoading })
)

const managerSelector = createSelector(
  state => state,
  state => propOr({}, 'manager', state)
)

const basketSelector = createSelector(
  state => state,
  state => prop('basket', state)
)

const catalogIconSelector = createSelector(
  state => state,
  ({ isLoyalty, isBookmark, isVisible }) => {
    let iconName = isVisible ? 'close' : 'bars'
    if (isLoyalty) iconName = 'catalog-icon'
    if (isBookmark) iconName = 'bookmark'
    return iconName
  }
)

const catalogTitleSelector = createSelector(
  state => state,
  state => {
    switch (state) {
      case 'loyalty':
        return 'Каталог подарков'
      case 'bookmark':
        return 'Закладка'
      default:
        return 'Каталог товаров'
    }
  }
)

const linksSelector = createSelector(
  state => state,
  state => pathOr(EMPTY_ARRAY, ['content', 'items'], state)
)

const has918okAccessSelector = createSelector(
  state => state,
  personal => propOr(false, 'ok918')(personal)
)

const personalTitleSelector = createSelector(
  state => state,
  personal => propOr('', 'title')(personal)
)

const tokenLoadingSelector = createSelector(
  state => state,
  token => propOr(false, 'isLoading')(token)
)

const userWithoutPasswordSelector = createSelector(
  state => state,
  token => propOr(false, 'isUserWithoutPassword')(token)
)

const HeaderContainer = ({ buttonCatalogRef = () => {} }) => {
  const mode = useSelector(({ settings }) => modeSelector(settings))
  const headerVisibility = useSelector(state => getHeaderAccess(state))
  const isBookmark = mode === 'bookmark'
  const isLoyalty = mode === 'loyalty'
  const links = useSelector(({ loyalty }) => linksSelector(loyalty))

  const isOptClient = useSelector(state => isOptClientSelector(state))
  const isKdClient = useSelector(state => isKdClientSelector(state))

  const isVisible = useSelector(({ catalog }) => isVisibleSelector(catalog))
  const isDiscountsVisible = useSelector(({ personal }) =>
    discountsAccessSelector(personal)
  )
  const isTempUser = useSelector(({ personal }) => tempUserSelector(personal))
  const access = useSelector(({ personal }) => menuAccessSelector(personal, isOptClient))

  const activeContractor = useSelector(({ personal }) =>
    getActiveContractor(personal)
  )
  const adminToken = useSelector(({ personal }) => adminTokenSelector(personal))
  const basket = useSelector(state => basketSelector(state))
  const cards = useSelector(({ loyalty }) => getLoyaltyCards(loyalty))
  const catalogIcon = useSelector(() =>
    catalogIconSelector({ isLoyalty, isBookmark, isVisible })
  )
  const catalogTitle = useSelector(() => catalogTitleSelector(mode))
  const compareCount = useSelector(({ compare }) =>
    compareCountSelector(compare)
  )
  const contractors = useSelector(({ personal }) => getContractors(personal))
  const currentCard = useSelector(({ loyalty }) => getCurrentCard(loyalty))
  const favoriteCount = useSelector(({ favorite }) =>
    favoriteCountSelector(favorite)
  )
  const has918okAccess = useSelector(({ personal }) =>
    has918okAccessSelector(personal)
  )
  const invoice = useSelector(state => invoiceSelector(state))
  const isExistsSuperuser = useSelector(({ personal }) =>
    getIsExistsSuperuser(personal)
  )
  const isPersonalLoaded = useSelector(({ personal }) => getIsLoaded(personal))
  const isSuperuser = useSelector(({ personal }) => getIsSuperuser(personal))
  const isUserWithoutPassword = useSelector(({ token }) =>
    userWithoutPasswordSelector(token)
  )
  const lastGuid = useSelector(({ loyalty }) => getLastCompletedGuid(loyalty))
  const loyaltyBlock = useSelector(state => loyaltyHeaderSelector(state))
  const manager = useSelector(({ personal }) => managerSelector(personal))
  const menuItems = useSelector(({ settings }) =>
    menuSelector(settings, access)
  )
  const message = useSelector(({ personal }) => getMessage(personal))
  const pathname = useSelector(({ router }) => pathnameSelector(router))
  const personalTitle = useSelector(({ personal }) =>
    personalTitleSelector(personal)
  )
  const support = useSelector(({ personal }) => getSupports(personal))
  const { theme } = useSelector(state => getTheme(state))
  const tokenIsLoading = useSelector(({ token }) => tokenLoadingSelector(token))
  const userId = useSelector(({ personal }) => userIdSelector(personal))

  const dispatch = useDispatch()

  const handleFetchInvoice = useCallback(
    data => dispatch(fetchInvoice(data)),
    [dispatch]
  )

  const handleGotoSuperuser = useCallback(
    data => dispatch(gotoSuperuser(data)),
    [dispatch]
  )
  const handleHideModal = useCallback(
    data => dispatch(hideModal(data)),
    [dispatch]
  )
  const handleResetError = useCallback(
    data => dispatch(resetError(data)),
    [dispatch]
  )
  const handleSetCatalogVisible = useCallback(
    data => dispatch(setCatalogVisible(data)),
    [dispatch]
  )
  const handleSetContractor = useCallback(
    data => dispatch(setContractor(data)),
    [dispatch]
  )
  const handleSetListening = useCallback(
    data => dispatch(setListening(data)),
    [dispatch]
  )
  const handleShowModal = useCallback(
    data => dispatch(showModal(data)),
    [dispatch]
  )
  const onSignOut = useCallback(data => dispatch(clearToken(data)), [dispatch])

  return (
    <HeaderComponent
      activeContractor={activeContractor}
      adminToken={adminToken}
      basket={basket}
      buttonCatalogRef={buttonCatalogRef}
      cards={cards}
      catalogIcon={catalogIcon}
      catalogTitle={catalogTitle}
      compareCount={compareCount}
      contractors={contractors}
      currentCard={currentCard}
      favoriteCount={favoriteCount}
      fetchInvoice={handleFetchInvoice}
      gotoSuperuser={handleGotoSuperuser}
      has918okAccess={has918okAccess}
      headerVisibility={headerVisibility}
      hideModal={handleHideModal}
      invoice={invoice}
      isBookmark={isBookmark}
      isDiscountsVisible={isDiscountsVisible}
      isExistsSuperuser={isExistsSuperuser}
      isKdClient={isKdClient}
      isLoyalty={isLoyalty}
      isPersonalLoaded={isPersonalLoaded}
      isSuperuser={isSuperuser}
      isTempUser={isTempUser}
      isUserLoading={tokenIsLoading}
      isUserWithoutPassword={isUserWithoutPassword}
      isVisible={isVisible}
      lastGuid={lastGuid}
      links={links}
      loyaltyBlock={loyaltyBlock}
      manager={manager}
      menuItems={menuItems}
      message={message}
      onSignOut={onSignOut}
      pathname={pathname}
      personalTitle={personalTitle}
      resetError={handleResetError}
      setCatalogVisible={handleSetCatalogVisible}
      setContractor={handleSetContractor}
      setListening={handleSetListening}
      showModal={handleShowModal}
      support={support}
      theme={theme}
      userId={userId}
    />
  )
}

HeaderContainer.propTypes = {
  buttonCatalogRef: func
}

export default memo(HeaderContainer)
