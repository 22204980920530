/* eslint-disable complexity */
import {
  pathOr,
  path,
  propOr,
  prop,
  propEq,
  compose,
  map,
  isEmpty,
  equals,
  sum,
  toLower,
  prepend,
  reduce,
  insert,
  keys,
  values,
  includes,
  mapObjIndexed,
  findLastIndex,
  filter,
  last,
  length,
  mergeAll,
  assoc,
  dissoc,
  concat,
  not
} from 'ramda'
import { createSelector } from 'reselect'

import moment from 'utils/moment'
import noun from 'utils/noun'

const FIRST_PAGE = 1
const LIMIT = 10

const dateFromState = moment().subtract(1, 'month').format('DD.MM.YYYY')
const dateToState = moment().format('DD.MM.YYYY')

const dateSelector = createSelector(
  date => date,
  date => (date ? moment(date, 'X').format('DD.MM.YYYY') : '')
)

export const isFiltersCheckedSelector = createSelector(
  (state, offerType) => pathOr('active', [offerType, 'type'], state),
  (state, offerType) => pathOr({}, [offerType, 'checkedItems'], state),
  (type, checkedItems) => !equals(type, 'active') || !isEmpty(checkedItems)
)

// селекторы для акций по типу (markdowns/offline)
const offersByTypeSelector = createSelector(
  (state, type) => propOr({}, type, state),
  state => state
)

export const getType = createSelector(offersByTypeSelector, state =>
  propOr('active', 'type', state)
)

export const getDateFrom = createSelector(offersByTypeSelector, state =>
  propOr(dateFromState, 'date_from', state)
)

export const getDateTo = createSelector(offersByTypeSelector, state =>
  propOr(dateToState, 'date_to', state)
)

export const getNotices = createSelector(offersByTypeSelector, state =>
  propOr('', 'notices', state)
)

export const getErrors = createSelector(
  state => state,
  state => propOr('', 'error', state)
)

export const getLoyaltyPoints = createSelector(
  state => state,
  state => propOr('', 'loyaltyPoints', state)
)

export const getPickupId = createSelector(
  state => state,
  state => propOr(0, 'pickupId', state)
)

// подарки типа PRODUCT для просмотра в модалке
export const prizesToShowSelector = createSelector(
  state => state,
  state => propOr([], 'prizesToShow', state)
)

export const pickupPrizeIdSelector = createSelector(
  state => state,
  state => propOr('', 'pickupPrizeId', state)
)

export const errorSelector = createSelector(
  state => state,
  state => propOr('', 'error', state)
)

export const countSelector = createSelector(offersByTypeSelector, offers =>
  pathOr(0, ['count', 'CNT'], offers)
)

export const isLoadedSelector = createSelector(offersByTypeSelector, offers =>
  propOr(false, 'isLoaded', offers)
)

export const limitSelector = createSelector(offersByTypeSelector, markdown =>
  propOr(LIMIT, 'limit', markdown)
)

const prepareSortItems = createSelector(
  item => item,
  item => ({
    id: prop('CODE', item),
    selectItems: compose(
      prepend({
        title: 'Все предложения',
        value: ''
      }),
      map(value => ({
        title: propOr('', 'VALUE', value),
        value: prop('ID', value)
      })),
      propOr([], 'VALUES')
    )(item)
  })
)

export const sortSelector = createSelector(offersByTypeSelector, offers =>
  compose(map(prepareSortItems), propOr([], 'sort'))(offers)
)

export const pageSelector = createSelector(offersByTypeSelector, offers =>
  propOr(FIRST_PAGE, 'page', offers)
)

export const checkedItemsSelector = createSelector(
  offersByTypeSelector,
  markdown => propOr({}, 'checkedItems', markdown)
)

// селекторы для акций оффлайн
export const getIsLoaded = createSelector(
  (state, offerType) => pathOr(false, [offerType, 'isLoaded'], state),
  isLoaded => isLoaded
)
export const getIsLoading = createSelector(
  (state, offerType) => pathOr(false, [offerType, 'isLoading'], state),
  isLoading => isLoading
)

export const getOfferIsLoaded = createSelector(
  (state, offerId) => pathOr({}, ['offerDetail', offerId], state),
  offer => offer
)

const TIME_INTERVAL = 60

// счетчик этапов - для принявших условие,
// уведомление об окончании, если период проведения прошел
// счетчик времени до завершения акции, если пользователь не принял участие.
const getEstimate = (dateTo, statusNew, statusActive, offer) => {
  if (statusActive && !statusNew) {
    return `${propOr(0, 'STAGE_NUM', offer)} из ${propOr(
      0,
      'STAGES_CNT',
      offer
    )}`
  }
  if (!statusActive) {
    return 'Завершена'
  }
  let estimate = ''
  const estimateDays = dateTo.diff(moment(), 'day')
  const estimateMinutes = dateTo.diff(moment(), 'minute')
  if (estimateDays <= 1) {
    estimate =
      estimateMinutes / TIME_INTERVAL > 1
        ? `${Math.floor(estimateMinutes / TIME_INTERVAL)} часов ${
          estimateMinutes % TIME_INTERVAL
        }
        ${noun(estimateMinutes, ['минута', 'минуты', 'минут'])}`
        : `${estimateMinutes} ${noun(estimateMinutes, [
          'минута',
          'минуты',
          'минут'
        ])}`
  } else {
    estimate = `${estimateDays} ${noun(estimateDays, ['день', 'дня', 'дней'])}`
  }
  return `Осталось ${estimate}`
}

export const statusesSelector = createSelector(
  state => state,
  state => {
    const dateFrom = dateSelector(propOr('', 'ACTIVE_FROM', state))
    const activeTo = moment(prop('ACTIVE_TO', state), 'X')
    const dateTo = activeTo.format('DD.MM.YYYY')
    const statusActive = propEq('STATUS', 'ACTIVE', state)
    const confirm = propOr('0', 'IS_CONFIRM', state) === '1'
    const offerInactive = propEq('STATUS', 'ARCHIVE', state)
    const statusNew = statusActive && !confirm
    const isFavorite = compose(equals('1'), propOr(false, 'FAVORITE'))(state)
    return {
      dateFrom,
      dateTo,
      statusNew,
      statusActive,
      offerInactive,
      isConfirm: statusActive && confirm,
      offerWinner: propEq('STATUS', 'COMPLETE', state),
      stagesCounterText: getEstimate(activeTo, statusNew, statusActive, state),
      isFavorite
    }
  }
)

export const offerErrorSelector = createSelector(
  offer => propOr({}, 'ERROR', offer),
  error => ({
    availability: propOr(true, 'AVAILABILITY', error),
    message: propOr('', 'MESSAGE', error)
  })
)

const preparePrizesByType = createSelector(
  prizes => prizes,
  prizes => {
    const stagePrizes = reduce((acc, item) => {
      const lastIndex = compose(length, propOr([], prop('TYPE', item)))(acc)

      const objByType =
        prop('TYPE', item) === 'LOYALTY'
          ? {
            LOYALTY: insert(lastIndex, item, propOr([], 'LOYALTY', acc))
          }
          : {
            PRODUCT: insert(
              lastIndex,
              item,
              propOr([], prop('TYPE', item), acc)
            )
          }

      return {
        ...acc,
        ...objByType
      }
    }, {})(prizes)
    const prizeTypes = keys(stagePrizes)
    const isPrizeLoyalty =
      !isEmpty(stagePrizes) && includes('LOYALTY', prizeTypes)
    const isPrizeProduct =
      !isEmpty(stagePrizes) && includes('PRODUCT', prizeTypes)
    return {
      prizeGrand: propOr({}, 0, prizes), // в качестве примера всегда выводим первый подарок из списка
      prizesList: stagePrizes, // для определения типов подарков и вывода кнопок просмотра/получения
      isPrizeLoyalty: isPrizeLoyalty && !isPrizeProduct,
      isPrizeProduct: !isPrizeLoyalty && isPrizeProduct,
      isPrizeDifferent: isPrizeLoyalty && isPrizeProduct
    }
  }
)

const prepareOffers = createSelector(
  offer => offer,
  offer => {
    const offerStatuses = statusesSelector(offer)
    const { isConfirm, offerWinner } = offerStatuses
    const prizeInfo = preparePrizesByType(propOr([], 'PRIZE', offer))
    const isShowPrize = !isEmpty(propOr({}, 'prizeGrand', prizeInfo))
    const conditions = propOr(0, 'STAGES_CNT', offer)
    const successConditions = propOr(0, 'STAGE_NUM', offer)
    const isHideCounter =
      isConfirm && (equals(0, conditions) || equals(0, successConditions))
    return {
      ...offer,
      ...offerStatuses,
      prizeInfo,
      isShowPrize,
      isHideCounter,
      isShowGiftButton: offerWinner && isShowPrize,
      error: offerErrorSelector(offer)
    }
  }
)

export const getOffers = createSelector(
  (state, offerType) =>
    compose(map(prepareOffers), pathOr([], [offerType, 'data']))(state),
  offers => offers
)

export const offersToConfirmSelector = createSelector(
  (state, offerType) => pathOr([], [offerType, 'data'], state),
  offers =>
    reduce((acc, offer) => {
      if (
        propOr('0', 'IS_CONFIRM', offer) === '0' &&
        propOr('', 'STATUS', offer) === 'ACTIVE'
      ) {
        const offerId = propOr(0, 'ID', offer)
        return [...acc, offerId]
      }
      return acc
    }, [])(offers)
)

export const offersToGetPointsSelector = createSelector(
  (state, offerType) => pathOr([], [offerType, 'data'], state),
  offers => {
    // выигрышные акции с подарком - тип LOYALTY
    const offersPrizeInfo = compose(
      filter(offer => pathOr(false, ['prizeInfo', 'isPrizeLoyalty'], offer)),
      map(offer => ({
        id: propOr(0, 'ID', offer),
        prizeInfo: preparePrizesByType(propOr([], 'PRIZE', offer)),
        prizeId: pathOr('', ['PRIZE', 0, 'ID'], offer),
        prizeValue: pathOr('0', ['PRIZE', 0, 'VALUE'], offer)
      })),
      filter(offer => propEq('STATUS', 'COMPLETE', offer))
    )(offers)
    return map(({ id, prizeId, prizeValue }) => ({
      id,
      prizeId,
      prizeValue: parseInt(prizeValue, 10)
    }))(offersPrizeInfo)
  }
)

export const isPrizeAvailableSelector = createSelector(
  (state, offerType) => pathOr([], [offerType, 'data'], state),
  offers =>
    compose(
      isEmpty,
      filter(offer => !pathOr(true, ['ERROR', 'AVAILABILITY'], offer))
    )(offers)
)

// подробная информация об акции
export const offerSelector = createSelector(
  state => pathOr([], ['offerDetail'], state),
  (_, match) => path(['params', 'id'], match),
  (offers, id) => {
    const offer = propOr({}, id, offers)
    const offerStatuses = statusesSelector(offer)
    const { statusNew, offerWinner, offerInactive } = offerStatuses
    const stagesFromResponse = propOr([], 'STAGES', offer)
    const currentStageIndex = findLastIndex(propEq('IS_CURRENT', '1'))(
      stagesFromResponse
    )
    const completedStageIndex = findLastIndex(propOr(false, 'IS_COMPLETE'))(
      stagesFromResponse
    )
    const stages = compose(
      values,
      mapObjIndexed((stage, key) => ({
        ...stage,
        isCurrent: propOr('', 'IS_CURRENT', stage) === '1',
        isInactive: currentStageIndex < parseInt(key, 10),
        isCompleted: currentStageIndex > parseInt(key, 10),
        PRIZE: preparePrizesByType(propOr([], 'PRIZE', stage))
      }))
    )(stagesFromResponse)
    // главный подарок выводим в зависимости от статуса акции
    let stageForPrizeGrand = []
    if (statusNew) {
      stageForPrizeGrand = last(stages)
    }
    if (offerWinner) {
      stageForPrizeGrand = compose(
        propOr({}, 0),
        filter(stage => propOr(false, 'IS_COMPLETE', stage))
      )(stages)
    }
    // в архивной акции подарок, который забрали, содержит признак PICKUP: true
    const prizeGrand = offerInactive
      ? compose(
        preparePrizesByType,
        values,
        mergeAll,
        map(stage =>
          filter(prize => !!propOr(false, 'PICKUP', prize))(
            prop('PRIZE', stage)
          )
        )
      )(stagesFromResponse)
      : propOr({}, 'PRIZE', stageForPrizeGrand)
    const isPrizeEmpty = compose(isEmpty, propOr({}, 'prizeGrand'))(prizeGrand)
    // prizeNotPickup - подарок не забрали в течение 30 дней
    // если в архивной акции есть выполненные этапы, а подарка нет
    return {
      ...offer,
      ...offerStatuses,
      STAGES: statusNew ? [] : stages, // в непринятой акции не выводятся этапы, но выводится главный приз
      prizeGrand,
      completedStageNum: pathOr(
        0,
        [completedStageIndex, 'NUM'],
        stagesFromResponse
      ),
      isShowPrize: !isPrizeEmpty,
      prizeNotPickup:
        offerInactive && completedStageIndex !== -1 && isPrizeEmpty
    }
  }
)

// селекторы для спецпредложений (markdown)
export const offersMarkdowns = createSelector(
  state => state,
  state => propOr({}, 'markdown', state)
)

export const isMarkdownsLoaded = createSelector(offersMarkdowns, markdown =>
  propOr(false, 'isLoaded', markdown)
)

export const markdownsSelector = createSelector(offersMarkdowns, markdown =>
  compose(
    map(item => {
      const itemCode = compose(toLower, propOr('', 'CODE'))(item)
      return {
        code: itemCode,
        linkTo: `/catalog/offers/markdown/${itemCode}`,
        dateFrom: dateSelector(propOr('', 'ACTIVE_FROM', item)),
        dateTo: dateSelector(propOr('', 'ACTIVE_TO', item)),
        view: propOr(true, 'VIEW', item),
        title: propOr('', 'TITLE', item),
        picture: propOr('', 'PREVIEW_PICTURE', item)
      }
    }),
    propOr([], 'data')
  )(markdown)
)

// страница спецпредложение (markdown) детально
const markdownDetailSelector = createSelector(
  state => state,
  state => propOr({}, 'markdownDetail', state)
)

export const markdownPageDateSelector = createSelector(
  markdownDetailSelector,
  offer => ({
    dateFrom: dateSelector(propOr('', 'ACTIVE_FROM', offer)),
    dateTo: dateSelector(propOr('', 'ACTIVE_TO', offer))
  })
)

export const markdownPageDescriptSelector = createSelector(
  markdownDetailSelector,
  offer => propOr('', 'DESCRIPTION', offer)
)

export const markdownPageTitleSelector = createSelector(
  markdownDetailSelector,
  offer => propOr('', 'TITLE', offer)
)

export const markdownPagePictureSelector = createSelector(
  markdownDetailSelector,
  offer => propOr('', 'PREVIEW_PICTURE', offer)
)

// селекторы для акций в шапке
const prepareHeaderMarkdown = createSelector(
  offer => offer,
  offer => {
    const offerCode = compose(toLower, propOr('', 'CODE'))(offer)
    return {
      offerId: offerCode,
      offerName: propOr('', 'TITLE', offer),
      link: `/catalog/offers/markdown/${offerCode}`,
      dateFrom: dateSelector(propOr('', 'ACTIVE_FROM', offer)),
      dateTo: dateSelector(propOr('', 'ACTIVE_TO', offer)),
      isHideCounter: propOr(true, 'VIEW', offer),
      labelText: 'Новая'
    }
  }
)

export const prepareHeaderOffer = createSelector(
  (offers, type) =>
    equals(type, 'MARKDOWN')
      ? map(prepareHeaderMarkdown, offers)
      : map(offer => {
        const offerId = propOr('', 'ID', offer)
        const offerActive = propEq('STATUS', 'ACTIVE', offer)
        const offerNew = offerActive && propEq('IS_CONFIRM', '0', offer)
        const offerWinner =
            !offerActive && propEq('STATUS', 'COMPLETE', offer)
        const stagesCount = propOr(0, 'STAGES_CNT', offer)
        const currentStage = propOr(0, 'STAGE_NUM', offer)
        return {
          offerId,
          offerName: propOr('', 'NAME', offer),
          link: `/offer/${offerId}`,
          dateFrom: dateSelector(propOr('', 'ACTIVE_FROM', offer)),
          dateTo: dateSelector(propOr('', 'ACTIVE_TO', offer)),
          offerActive,
          offerNew,
          offerWinner,
          isPrizeAvailable: pathOr(true, ['ERROR', 'AVAILABILITY'], offer),
          labelText: offerNew
            ? 'Принять участие'
            : `${currentStage} из ${stagesCount}`,
          isHideCounter:
              !offerNew &&
              !offerWinner &&
              (equals(0, currentStage) || equals(0, stagesCount))
        }
      })(offers),
  state => state
)

export const offersHeader = createSelector(
  state => state,
  state => propOr({}, 'headerOffers', state)
)

export const offersHeaderSelector = createSelector(
  offers =>
    compose(
      map(type => {
        const obj = pathOr([], ['headerOffers', 'data', type, 'ITEMS'], offers)
        const result = !isEmpty(obj) ? prepareHeaderOffer(obj, type) : []
        return isEmpty(result)
          ? {}
          : {
            offers: result,
            offerType: toLower(type),
            title: pathOr(
              '',
              ['headerOffers', 'data', type, 'NAV', 'NAME'],
              offers
            )
          }
      }),
      keys,
      pathOr({}, ['headerOffers', 'data'])
    )(offers),
  state => state
)

export const newOffersSelector = createSelector(
  state =>
    compose(
      assoc('linkTo', '/offers'),
      reduce((acc, type) => {
        const newCnt = pathOr(
          0,
          ['headerOffers', 'data', type, 'NAV', 'NOVELTY'],
          state
        )
        const accCount = propOr(0, 'count', acc)
        return {
          count: sum([accCount, newCnt])
        }
      }, {}),
      keys,
      pathOr({}, ['headerOffers', 'data'])
    )(state),
  count => count
)

export const isLoadedOffersHeader = createSelector(offersHeader, offers =>
  propOr(false, 'isLoaded', offers)
)

export const isLoadingOffersHeader = createSelector(offersHeader, offers =>
  propOr(false, 'isLoading', offers)
)

export const isDataOffersHeader = createSelector(offersHeader, offers =>
  not(isEmpty(propOr({}, 'data', offers)))
)

export const offersMainPageSelector = createSelector(
  (offersAccess, offers) => {
    const isOffline = compose(not, isEmpty, dissoc('MARKDOWN'))(offersAccess)
    const offlineKeys = isOffline
      ? compose(
        keys,
        dissoc('MARKDOWN'),
        pathOr({}, ['headerOffers', 'data'])
      )(offers)
      : []
    const accessKeys = compose(keys, dissoc('OFFLINE'))(offersAccess)
    const offersKeys = concat(accessKeys, offlineKeys)
    return compose(
      map(type => ({
        type,
        title: pathOr(
          '',
          ['headerOffers', 'data', type, 'NAV', 'NAME'],
          offers
        ),
        linkTo: `/offers/${toLower(type)}`,
        count: pathOr(0, ['headerOffers', 'data', type, 'NAV', 'CNT'], offers),
        new: pathOr(0, ['headerOffers', 'data', type, 'NAV', 'NOVELTY'], offers)
      }))
    )(offersKeys)
  },
  offersInfo => offersInfo
)

export const offersTabsSelector = createSelector(
  offersMainPageSelector,
  offers =>
    map(offer => ({
      id: toLower(propOr('', 'type', offer)),
      title: propOr('', 'title', offer),
      url: propOr('', 'linkTo', offer),
      count: propOr(0, 'new', offer),
      type: 'offers'
    }))(offers)
)

export const isGroupOperationLoading = createSelector(
  state => state,
  state => pathOr(false, ['groupOperation', 'isLoading'], state)
)

export const isGroupOperationLoaded = createSelector(
  state => state,
  state => pathOr(false, ['groupOperation', 'isLoaded'], state)
)

export const errorGroupOffersSelector = createSelector(
  state => state,
  state => pathOr({}, ['groupOperation', 'errorOffers'], state)
)

export const errorOffersIdsSelector = createSelector(
  errorGroupOffersSelector,
  errors => keys(errors)
)

export const pointsGroupOffersSelector = createSelector(
  state => state,
  state => pathOr(0, ['groupOperation', 'points'], state)
)

export const groupOperationNameSelector = createSelector(
  state => state,
  state => pathOr('', ['groupOperation', 'action'], state)
)
