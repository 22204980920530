/* eslint-disable complexity */
import React from 'react'

import cx from 'classnames'
import { bool, number } from 'prop-types'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'

import s from './ProgressLine.scss'

const PERCENT = 100

const Status = ({ isPay = false, isCompleted = false, sum = 0 }) => {

  return (
    <span className={cx(s.hint, {
      [s.hintCompleted]: isCompleted
    })}>
      {isPay ? 'Вы выбрали платную доставку' : (
        <>
          {sum ?
            'До бесплатной доставки:' :
            'Вам доступна бесплатная доставка'
          }
          {sum > 0 ? <CurrencyValue price={sum} /> : null}
        </>
      )}
    </span>
  )
}

Status.propTypes = {
  isPay: bool,
  isCompleted: bool,
  sum: number
}

const ProgressLine = ({ isPay, sum, sumMax }) => {
  const progress = (sum * PERCENT) / sumMax
  const isCompleted = progress >= PERCENT

  return (
    <div
      className={s.progressLineContainer}
      title='Сумма всех заказов за определенный период'
    >
      <div
        className={cx(s.progressLine, {
          [s.progressLineCompleted]: isCompleted,
          [s.progressLinePay]: isPay
        })}
        style={{ width: isPay ? '100%': `${progress}%` }}
      />
      <div className={s.progressLineInfo}>
        <div
          className={cx(s.progressLineSum, {
            [s.progressLineSumCompleted]: isCompleted
          })}
        >
          <Icon
            icon='cart-middle'
            className={cx(s.progressLineBasket, {
              [s.progressLineBasketCompleted]: isCompleted
            })}
          />
          <CurrencyValue price={sum} />
          {sumMax && sumMax > 0 ? (
            <>
              <span className={s.progressLineSumText}>
                из
              </span>
              <CurrencyValue price={sumMax} />
            </>
          ) : null}
        </div>
        <Status
          isPay={isPay}
          isCompleted={isCompleted}
          sum={sum >= sumMax ? 0 : sumMax - sum}
        />
      </div>
    </div>
  )
}

ProgressLine.propTypes = {
  isPay: bool,
  sum: number,
  sumMax: number
}

export default ProgressLine
