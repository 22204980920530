import React, {
  FC,
  memo,
  useEffect,
  useMemo,
  useState
} from 'react'

import cx from 'classnames'
import { pathOr } from 'ramda'

import { ClickedValueType, IMenu, IMenuOption } from 'UI/Select/types'
import { ENTER_CODE, KEY_CODE_UP, KEY_CODE_DOWN } from 'utils/keycodes'

import s from './Select.scss'

const Option: FC<IMenuOption> = ({
  focusedIndex,
  handleSelect,
  index,
  selectedValue,
  title,
  value
}) => {
  const isSelected = useMemo(() => String(selectedValue) === String(value), [selectedValue, value])
  const isFocused = useMemo(() => index === focusedIndex, [focusedIndex, index])
  const handleClick = (clickedValue: ClickedValueType) => () => handleSelect({ clickedValue, isSelected })

  return (
    <li
      className={cx(s.list__item, {
        [s.list__item_selected]: isSelected,
        [s.list__item_focused]: isFocused
      })}
      role='presentation'
      value={value}
      onClick={handleClick(value)}
    >
      {title}
    </li>
  )
}

const Menu: FC<IMenu> = (props: IMenu) => {
  const { options = [], handleSelect } = props
  const [focusedIndex, setIndex] = useState(-1)

  useEffect(() => {
    if (document.activeElement && document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }

    const maxIndex = options.length - 1
    const handlePressKey = (e: KeyboardEvent) => {
      const arrowSet = new Set([KEY_CODE_UP, KEY_CODE_DOWN])
      if (arrowSet.has(e.keyCode)) {
        e.preventDefault()
        const arrowIndex = e.keyCode === KEY_CODE_UP ? -1 : 1
        const callback = (prevIndex: number) => {
          const newIndex = prevIndex + arrowIndex
          if (newIndex < 0) return 0
          if (newIndex > maxIndex) return maxIndex
          return newIndex
        }
        setIndex(callback)
      }

      if (e.keyCode === ENTER_CODE) {        
        const value = pathOr('', [focusedIndex, 'value'])(options)
        handleSelect({ clickedValue: value })
      }
    }

    document.addEventListener('keydown', handlePressKey)
    return () => {
      document.removeEventListener('keydown', handlePressKey)
    }
  }, [focusedIndex, handleSelect, options, options.length])

  return (
    <ul className={s.root__list}>
      {options.map((option, index) => (
        <Option
          key={pathOr(index, ['value'], options)}
          focusedIndex={focusedIndex}
          index={index}
          {...props}
          {...option}
        />
      ))}
    </ul>
  )
}

export default memo(Menu)