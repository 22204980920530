/* eslint-disable react/button-has-type */
import React, { FC } from 'react'

import cx from 'classnames'
import { propOr } from 'ramda'

import Icon from 'components/Icon'
import Link from 'components/Link'

import styles from './Button.scss'
import { IButtonProps } from './types'

const Button: FC<IButtonProps> = ({
  shape = 'default',
  size = 'default',
  iconSize = 'default',
  color = 'main',
  type,
  target,
  title,
  children,
  icon,
  stretched = false,
  disabled,
  onClick,
  to,
  href,
  ...rest
}) => {
  let Tag: React.ElementType
  if (to) Tag = Link
  else if (href) Tag = 'a'
  else Tag = 'button'
  let props = {
    onClick,
    className: cx(styles.button, {
      [styles[`button__shape_${shape}`]]: !!shape,
      [styles[`button__color_${color}`]]: !!color,
      [styles[`button__size_${size}`]]: !!size,
      [styles.button__autowidth]: stretched
    }),
    type,
    to,
    href,
    disabled,
    target
  }
  if (Tag === 'button') {
    const dataAnalytics = propOr('', 'data-analytics', rest)
    if (dataAnalytics) {
      props = { ...props, ...rest }
    }
  }

  if (to || href) {
    return (
      <Tag
        to={to ?? ''}
        href={href ?? ''}
        target={target}
      >
        <Button
          shape={shape}
          color={color}
          size={size}
          icon={icon}
          iconSize={iconSize}
          type={type}
          disabled={disabled}
          onClick={onClick}
          stretched={stretched}
        >
          {children || title}
        </Button>
      </Tag>
    )
  }

  return (
    <button {...props}>
      {icon && (
        <Icon
          className={cx(styles.icon, {
            [styles[`icon__color_${color}`]]: !!color,
            [styles[`icon__shape_${shape}`]]: !!shape
          })}
          icon={icon}
          size={iconSize}
        />
      )}
      {children || title}
    </button>
  )
}

export default Button
