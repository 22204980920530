/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { object, bool, string, func, oneOfType, number, array } from 'prop-types'
import {
  path,
  pathOr,
  propOr,
  findLast,
  propEq,
  prop,
  isEmpty,
  compose,
  filter,
  sortBy,
  addIndex,
  map,
  length
} from 'ramda'

import Buy from 'components/Buy'
import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import LoyaltyCoefficient from 'components/LoyaltyCoefficient'
import NotMultiple from 'components/NotMultiple'
import PointsValue from 'components/PointsValue'
import Tooltip from 'components/Tooltip'
import { setAnalyticsProps } from 'helpers/metaTags'
import moment from 'utils/moment'

import s from './ProductPrices.scss'

// Преобразовывает из [{FROM: 1}, ...] в диапазон [{FROM: 1, TO: 99}, ...]
const prepareArrayRange = arr => addIndex(map)((item, index, arrOut) => ({
  ...item,
  TO: index < length(arrOut) - 1 ? path([index + 1, 'FROM'], arrOut) - 1 : Infinity
}), arr)

// Вычисляем цену товара в зависимости от кол-ва (isActive признак)
const preparePriceRanges = (product, amount) => compose(
  map(priceRange => ({
    ...priceRange, isActive: amount >= prop('FROM', priceRange) && amount <= prop('TO', priceRange)
  })),
  prepareArrayRange,
  pathOr([], ['PRICE_RANGES'])
)(product)

// Вычисляем цену товара в зависимости от кол-ва + SHOW = 1 (для отображения таблицы с ценами)
const prepareActivePriceRanges = (product, amount) =>
  filter(propEq('SHOW', 1))(preparePriceRanges (product, amount))

// Получаем прайс на товар в зависимости от введенной цены (amount)
export const getActivePriceFromPriceRanges = (product, amount) => compose(
  prop('PRICE'),
  findLast(propEq('isActive', true))
)(preparePriceRanges(product, amount))

const getPricesFromPriceRanges = item => compose(
  sortBy(prop('PRICE')),
  pathOr([], ['PRICE_RANGES'])
)(item)

// Компонент отображения списка цен в зависимости от кол-ва (priceRanges - предобработан)
const RawPriceRanges = ({ priceRanges = []}) => {

  if (isEmpty(priceRanges)) {
    return null
  }

  return (
    <div className={s.priceRanges}>
      {priceRanges.map(item => (
        <div
          key={prop('PRICE', item)}
          className={cx(s.priceRanges__row, {
            [s.priceRanges__row_active]: prop('isActive', item)
          })}
        >
          <div className={cx(s.priceRanges__count, {
            [s.priceRanges__count_active]: prop('isActive', item)
          })}>
            от {propOr('', 'FROM', item)} шт.
          </div>
          <div className={s.priceRanges__price}>
            <CurrencyValue
              price={propOr('', 'PRICE', item)}
            />
          </div>
        </div>
      ))}

    </div>
  )
}

RawPriceRanges.propTypes = {
  priceRanges: array
}

// Компонент отображения списка цен в зависимости от кол-ва (product, amount)
const PriceRanges = ({ product, amount }) => {

  const priceRanges = prepareActivePriceRanges(product, amount)

  return <RawPriceRanges priceRanges={priceRanges} />
}

PriceRanges.propTypes = {
  product: object,
  amount: oneOfType([number, string])
}

class ProductPrices extends PureComponent {
  static propTypes = {
    item: object,
    amount: oneOfType([number, string]),
    multipleBuy: object,
    isLoyalty: bool,
    isSlider: bool,
    forPoints: bool,
    isForPoints: bool,
    isBasket: bool,
    isUserWithoutPassword: bool,
    className: string,
    isMultiple: bool,
    onShowAnalogs: func,
    setForPoints: func,
    activeStore: string
  }

  static defaultProps = {
    item: {},
    isMultiple: true,
    isBasket: false,
    forPoints: false,
    isForPoints: false,
    onShowAnalogs: () => {}
  }

  renderInfoPrice = item => {
    const date = pathOr(false, ['PRICES_PROMO', 'RISE', 'DATE'], item)
    const dateString = date
      ? moment(date, 'X').locale('ru').format('D MMM YYYY')
      : false
    return (
      <div
        className={s.infoPrice}
        itemScope
        itemType='http://schema.org/PriceSpecification'
      >
        <div className={s.textDescription}
          itemProp='description'>
          <CurrencyValue
            price={pathOr(0, ['PRICES_PROMO', 'RISE', 'VALUE'], item)}
            className={s.infoPriceValue}
          />
          {dateString && (
            <>
              {' '}
              - дороже с{' '}
              <span
                className={s.infoPriceData}
                itemProp='priceValidUntil'
                title={`С ${dateString} возможно повышение цены. Успейте купить по старой цене`}
              >
                {dateString}
              </span>
            </>
          )}
        </div>
      </div>
    )
  }

  renderOldPrice = item => {
    const { isSlider, isForPoints } = this.props
    const oldPrice = path(['PRICES_PROMO', 'REDUCE', 'VALUE'], item)
    const date = pathOr(false, ['PRICES_PROMO', 'REDUCE', 'DATE'], item)
    const dateString = date
      ? moment(date, 'X').locale('ru').format('D MMM YYYY')
      : false
    return (
      <div className={s.oldPrice}>
        <CurrencyValue
          price={oldPrice}
          className={cx(s.oldPriceValue, {
            [s.pricePurple]: isForPoints
          })}
          isPoints={isForPoints}
        />
        {dateString && !isSlider && (
          <span className={s.oldPriceText}
            itemProp='priceValidUntil'>
            Дешевле до&nbsp;{dateString}
          </span>
        )}
      </div>
    )
  }

  renderMultiplicity = () => {
    const { activeStore, item } = this.props
    const stores = propOr([], 'STORES', item)
    const stockSelected = findLast(propEq('CODE', activeStore))(stores)

    if (isEmpty(stores)) {
      return null
    }

    return (
      <Tooltip tooltip={path(['MULTIPLICITY_TITLE'], stockSelected)}>
        <div className={s.multiplicity}>
          <div className={s.multiplicityIconContainer}>
            <Icon icon='multiplicity'
              size='small' />
          </div>
          <span className={s.multiplicityValue}
            itemProp='offerCount'>
            {path(['MULTIPLICITY_HTML'], stockSelected)}
          </span>
        </div>
      </Tooltip>
    )
  }

  renderDelivery = () => {
    const delivery = pathOr(
      null,
      ['REMAINS', 'DELIVERY', 'TITLE'],
      this.props.item
    )
    if (delivery) {
      return (
        <div className={s.partGroup}>
          <span className={s.delivery}>{delivery}</span>
        </div>
      )
    }
    return false
  }

  renderNotInAssortment = () => {
    const { item, onShowAnalogs } = this.props
    const selectable = propOr(true, 'selectable', this.props)
    return (
      <div
        className={s.analogsInfo}
        itemProp='Discontinued'
        itemScope
        itemType='https://schema.org/ItemAvailability'
      >
        <p>
          Товар выведен <br /> из ассортимента.
        </p>
        {propOr(false, 'ISSET_ANALOGS', item) && (
          <p>
            <span
              role='presentation'
              className={cx({ [s.linkShowAnalogs]: selectable })}
              onClick={onShowAnalogs(item.ID)}
            >
              Выберите
            </span>{' '}
            товар-аналог
          </p>
        )}
      </div>
    )
  }

  render() {
    const {
      item,
      amount,
      multipleBuy,
      isLoyalty,
      isSlider,
      isForPoints,
      forPoints,
      isUserWithoutPassword,
      className,
      isMultiple,
      setForPoints,
      isBasket
    } = this.props

    const price = parseFloat(propOr(0, 'PRICE', item))
    const oldPrice = path(['PRICES_PROMO', 'REDUCE', 'VALUE'], item)
    const newPrice = path(['PRICES_PROMO', 'RISE', 'VALUE'], item)
    const isOldPrice = !!oldPrice && !isLoyalty
    const isNewPrice = !!newPrice && !isLoyalty
    const inAssortment = propOr(true, 'ACTIVE', item)
    const coefficient = Number(propOr(0, 'LOYALTY_COEFF', item))
    const coefficientTotal =
      Number(propOr(0, 'TOTAL_LOYALTY_COEFF', item)) || coefficient

    // минимальная цена (объект) из диапазона PRICE_RANGES
    const pricesFromPriceRanges = getPricesFromPriceRanges(item)

    // минимальная цена из диапазона PRICE_RANGES
    const minPriceFromPriceRanges = path([0, 'PRICE'])(pricesFromPriceRanges)

    // минимально ШТ. из диапазона PRICE_RANGES
    const minCountFromPriceRanges = path([0, 'FROM'])(pricesFromPriceRanges)

    // Актуальная цена для корзины
    const priceFromPriceRange = getActivePriceFromPriceRanges(item, amount)

    // для корзины указываем конечную цену товара в зависимости от кол-ва
    const pricePrepare = (isBasket ? priceFromPriceRange : minPriceFromPriceRanges) || price

    return (
      <div
        className={cx({
          [className]: className
        })}
      >
        {!!newPrice && !isSlider && this.renderInfoPrice(item)}
        {(price ||
          isOldPrice ||
          (!!newPrice && isOldPrice) ||
          (!isUserWithoutPassword && !isLoyalty && (price > 0 || oldPrice))) &&
          inAssortment && (
          <div
            className={cx(s.price, {
              [s.priceBlock]: isLoyalty,
              [s.priceInfoPrice]: !!newPrice
            })}
          >
            {forPoints && (
              <div
                {...setAnalyticsProps({
                  type: 'action',
                  group: 'buyForPoints',
                  action: `${isForPoints ? 'checked' : 'notchecked'}`,
                  product: propOr('', 'CODE_1C', item)
                })}
              >
                <Buy isPoints={isForPoints}
                  setForPoints={setForPoints} />
              </div>
            )}
            {isOldPrice && this.renderOldPrice(item)}
            <div className={s.priceReal}
              data-testid='priceReal'>
              {isLoyalty ? (
                <PointsValue points={Number(price)}
                  capitalize={false} />
              ) : (
                <div className={s.priceRealMain}>
                  <CurrencyValue
                    price={pricePrepare}
                    className={cx({
                      [s.priceRed]: isNewPrice || isOldPrice,
                      [s.pricePurple]: isForPoints
                    })}
                    isPoints={isForPoints}
                  />
                </div>
              )}
              {!isUserWithoutPassword && !isLoyalty && (
                <div className={s.marketingBlock}>
                  {(price > 0 || oldPrice) && !isForPoints && (
                    <div className={s.loyaltyCoeff}>
                      <LoyaltyCoefficient
                        coefficientTotal={coefficientTotal}
                      />
                    </div>
                  )}
                  <NotMultiple
                    price={prop('price', multipleBuy)}
                    multipleBuy={multipleBuy}
                    active={!isMultiple}
                    isPoints={isForPoints}
                  />
                </div>
              )}
            </div>
            {!isBasket && minPriceFromPriceRanges && (
              <div className={s.priceRealCount}>
                от {minCountFromPriceRanges} шт.
              </div>
            )}
          </div>
        )}
        {!isLoyalty && this.renderDelivery()}
        {!isLoyalty && inAssortment && this.renderMultiplicity()}
        {!isLoyalty && !this.props.isBasket && (
          <PriceRanges
            product={item}
            amount={amount}
          />
        )}
        {!inAssortment && this.renderNotInAssortment()}
      </div>
    )
  }
}

export { RawPriceRanges, PriceRanges, prepareActivePriceRanges }
export default ProductPrices
