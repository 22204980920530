/* eslint-disable complexity */
import axios from 'axios'
import { path, pathOr, prop, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

import getErrorMessage, { getErrorMessages } from 'helpers/getErrorMessage'
import { fetchFailure } from 'redux/modules/claimEdit'
import { hideModal, showModal } from 'redux/modules/modal'

export {
  getClaims,
  getIsLoading,
  getIsError,
  getTabs,
  getErrorMessages,
  getIsDeleting,
  getListIsEmpty,
  getIsLoadingReport
} from './selector'

const initialState = {
  claims: [],
  isError: false,
  isLoading: false,
  isDeleting: false,
  isLoadingReport: false,
  errorMessages: [],
  tabs: {
    Draft: 0,
    InProgress: 0,
    Completed: 0
  }
}

export const fetchClaims = createAction('claim/FETCH_CLAIMS')
const fetchClaimsSuccess = createAction('claim/FETCH_CLAIMS_SUCCESS')
const fetchClaimsFailure = createAction('claim/FETCH_CLAIMS_FAILURE')

export const fetchAggregation = createAction('claim/FETCH_AGGREGATION')
const fetchAggregationSuccess = createAction('claim/FETCH_AGGREGATION_SUCCESS')
const fetchAggregationFailure = createAction('claim/FETCH_AGGREGATION_FAILURE')

export const fetchClaimDelete = createAction('claim/FETCH_CLAIM_DELETE')
const fetchClaimDeleteSuccess = createAction('claim/FETCH_CLAIM_DELETE_SUCCESS')
const fetchClaimDeleteFailure = createAction('claim/FETCH_CLAIM_DELETE_FAILURE')

export const fetchReport = createAction('claim/FETCH_REPORT')
const fetchReportSuccess = createAction('claim/FETCH_REPORT_SUCCESS')
const fetchReportFailure = createAction('claim/FETCH_REPORT_FAILURE')

let source = null

const requestClaims =
  ({ clientApi }) =>
    params => {
      source = axios.CancelToken.source()
      return clientApi
        .post('/v3/contractor/pretensions/', {
          params: {
            limit: prop('limit', params),
            offset: prop('offset', params),
            filter: prop('filter', params),
            sort: prop('sort', params),
            order: 'DESC',
            contractor_id: clientApi.getContractorId()
          },
          cancelToken: source.token
        })
        .then(fetchClaimsSuccess)
        .catch(fetchClaimsFailure)
    }

const requestAggregation =
  ({ clientApi }) =>
    () =>
      clientApi
        .post('/v3/contractor/pretensions/aggregation/', {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(fetchAggregationSuccess)
        .catch(fetchAggregationFailure)

const requestClaimDelete =
  ({ clientApi }) =>
    ({ id, ...params }) =>
      clientApi
        .post(`/v3/contractor/pretension/${id}/delete/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(res => fetchClaimDeleteSuccess({ ...params, ...res }))
        .catch(fetchClaimDeleteFailure)

const requestReport =
  ({ clientApi }) =>
    params => {
      const isEdit = prop('isEdit', params)
      return clientApi
        .get(`/v3/contractor/pretension/${prop('id', params)}/report/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(res => fetchReportSuccess({ ...res, isEdit }))
        .catch(err => fetchReportFailure({ ...err, isEdit }))
    }

const handleFetchClaims = (state, params, { clientApi }) => {
  const isLoading = prop('isLoading', state)
  if (isLoading && source && source.cancel) source.cancel()

  return loop(
    {
      ...state,
      isLoading: true,
      isError: false
    },
    Effects.promise(requestClaims({ clientApi }), params)
  )
}

const handleFetchClaimsSuccess = (state, payload) => {
  const response = path(['data', 'response'], payload)
  const newState = {
    ...state,
    isLoading: false,
    isError: false
  }

  if (!response) newState.isError = true
  else newState.claims = propOr([], 'ITEMS', response)

  return newState
}

const handleFetchClaimsFailure = (state, payload) =>
  axios.isCancel(payload)
    ? { ...state }
    : {
      ...state,
      isLoading: false,
      isError: true
    }

const handleFetchAggregation = (state, _, { clientApi }) =>
  loop(
    {
      ...state,
      tabs: {
        new: 0,
        send: 0,
        completed: 0
      }
    },
    Effects.promise(requestAggregation({ clientApi }))
  )

const handleFetchAggregationSuccess = (state, payload) => {
  const aggregation = pathOr({}, ['data', 'response', 'AGGREGATION'], payload)
  return {
    ...state,
    tabs: {
      Draft: propOr(0, 'CNT_STATUS_NEW', aggregation),
      InProgress: propOr(0, 'CNT_STATUS_SEND', aggregation),
      Completed: propOr(0, 'CNT_STATUS_COMPLETED', aggregation)
    }
  }
}

const handleFetchAggregationFailure = state => ({
  ...state,
  tabs: {
    new: 0,
    send: 0,
    completed: 0
  }
})

const handleFetchClaimDelete = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isDeleting: true
    },
    Effects.promise(requestClaimDelete({ clientApi }), params)
  )

const handleFetchClaimDeleteSuccess = (state, payload) => {
  const isSuccess = pathOr({}, ['data', 'response', 'SUCCESS'], payload) === 'Y'
  const errors = pathOr({}, ['data', 'errors'], payload)

  if (!isSuccess)
    return loop(
      {
        ...state,
        isDeleting: false,
        errorMessages: Object.keys(errors).map(
          key => `${key} - ${propOr('', key, errors)}`
        )
      },
      Effects.call(showModal, 'claimError')
    )

  return loop(
    {
      ...state,
      isDeleting: false
    },
    Effects.batch([
      Effects.call(hideModal),
      Effects.call(fetchAggregation),
      Effects.call(fetchClaims, payload)
    ])
  )
}

const handleFetchClaimDeleteFailure = (state, payload) =>
  loop(
    {
      ...state,
      isDeleting: false,
      errorMessages: [getErrorMessage(payload)]
    },
    Effects.call(showModal, 'claimError')
  )

const handleFetchReport = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoadingReport: true
    },
    Effects.promise(requestReport({ clientApi }), params)
  )

const handleFetchReportSuccess = (state, payload) => {
  const effects = []
  const link = pathOr('', ['data', 'response', 'ITEM', 'PATH'], payload)
  const isEdit = prop('isEdit', payload)
  const newState = {
    ...state,
    isLoadingReport: false
  }

  if (link) window.open(link, '_blank')

  if (!link && isEdit) effects.push(Effects.call(fetchFailure, payload))

  if (!link && !isEdit) {
    const errors = pathOr({}, ['data', 'errors'], payload)
    newState.errorMessages = getErrorMessages(errors)
    effects.push(Effects.call(showModal, 'claimError'))
  }

  return loop(newState, Effects.batch(effects))
}

const handleFetchReportFailure = (state, payload) =>
  loop(
    {
      ...state,
      isLoadingReport: false,
      errorMessages: [getErrorMessage(payload)]
    },
    prop('isEdit', payload)
      ? Effects.call(fetchFailure, payload)
      : Effects.call(showModal, 'claimError')
  )

export default createReducer(on => {
  on(fetchClaims, handleFetchClaims)
  on(fetchClaimsSuccess, handleFetchClaimsSuccess)
  on(fetchClaimsFailure, handleFetchClaimsFailure)
  on(fetchAggregation, handleFetchAggregation)
  on(fetchAggregationSuccess, handleFetchAggregationSuccess)
  on(fetchAggregationFailure, handleFetchAggregationFailure)
  on(fetchClaimDelete, handleFetchClaimDelete)
  on(fetchClaimDeleteSuccess, handleFetchClaimDeleteSuccess)
  on(fetchClaimDeleteFailure, handleFetchClaimDeleteFailure)
  on(fetchReport, handleFetchReport)
  on(fetchReportSuccess, handleFetchReportSuccess)
  on(fetchReportFailure, handleFetchReportFailure)
}, initialState)
