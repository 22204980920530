import loadable from '@loadable/component'
import { pathOr, prop } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Product = loadable(() =>
  import(/* webpackChunkName: "Product" */ 'pages/Product/Product')
)

export default asyncConnect([
  {
    key: 'pageProduct',
    promise: ({
      match,
      route,
      helpers: { updateData },
      location: { pathname = '' }
    }) => {
      const id = pathOr(0, ['params', 'id'], match)
      const mode = prop('mode', route)
      updateData.set({
        productId: id,
        productError: !id,
        mode,
        view: 'productPage',
        url: pathname
      })
      return updateData.update()
    }
  }
])(Product)
