/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { push } from 'connected-react-router'
import hoistStatics from 'hoist-non-react-statics'
import { bool, func, number, object, string } from 'prop-types'
import {
  propOr,
  path,
  pathOr,
  head,
  compose,
  defaultTo,
  last,
  keys,
  clamp,
  is,
  isEmpty,
  prop
} from 'ramda'
import { connect } from 'react-redux'

import config from 'config'
import { getBasketInfo, isMultipleBuy } from 'helpers/products'
import { showModal, hideModal } from 'redux/modules/modal'
import { onlyRubSelector } from 'redux/modules/personal'
import {
  setItemCounts,
  getItemCountByGroupAndId,
  getItemCountByBasketGroupAndId,
  setMultiplicity,
  cartSelector
} from 'redux/modules/productList'
import {
  setProductModal,
  setAnalogsModal,
  setChecked,
  updateGroup,
  setOrderData,
  setActiveStore,
  activeStoreSelector,
  storeSelector,
  storesSelector,
  buySelector,
  updateItemsOrder,
  itemSelector,
  productCertsSelector,
  setForPoints,
  setProductBarcodeModal
} from 'redux/modules/products'
import { currentStoreSelector } from 'redux/modules/products/selector'

const LOYALTY_MAX = config.loyaltyOrderMaxLimit

const makeMapStateToProps = () => {
  const mapStateToProps = (
    { productList, token, loyalty, loyaltyBasket, personal, products },
    ownProps
  ) => {
    const listType = ownProps.type === 'orders' ? 'section' : ownProps.type
    let itemsCount = getItemCountByGroupAndId(
      ownProps.groupType || listType,
      ownProps.id,
      productList
    )
    if (ownProps.type === 'basket') {
      itemsCount = getItemCountByBasketGroupAndId(
        ownProps.groupType || listType,
        ownProps.catalog,
        ownProps.id,
        productList
      )
    }
    const cartId = cartSelector(productList)
    const item = itemSelector(productList, ownProps)
    const activeStore = activeStoreSelector({ products, productList }, ownProps)
    const catalog = propOr('main', 'catalog', ownProps)
    const basketInfo = getBasketInfo(item, [catalog, cartId])
    const basket = Number(propOr(0, 'QUANTITY', basketInfo))
    const basketStore = propOr('', 'STORE', basketInfo)
    const isForPointsBasket = propOr(false, 'FOR_POINTS', basketInfo)
    const { isNotMultiple, amount, isForPoints } = buySelector(
      products,
      ownProps
    )
    const amountPrepare = amount || basket
    const { isMain, remain, min, step, onlyMyltiplicity, forPoints } =
      storeSelector(item, activeStore)
    const descriptionStores = storesSelector(products)
    const checked = pathOr(false, ['itemsCheckedMap', ownProps.id], products)
    const cert = productCertsSelector(item, productList)
    const onlyRub = onlyRubSelector(personal)
    return {
      item,
      checked,
      itemsCount: Number(itemsCount),
      isBuyNotMultiple: !onlyMyltiplicity,
      forPoints: forPoints && !onlyRub,
      isForPoints,
      isForPointsBasket,
      basket,
      isMain,
      remain,
      min,
      step,
      amount: amountPrepare,
      isNotMultiple,
      activeStore,
      basketStore,
      descriptionStores,
      isUserWithoutPassword: token.isUserWithoutPassword,
      balance: parseInt(pathOr(0, ['status', 'BALANCE'], personal), 10),
      loyaltyBasketSum: pathOr(0, ['itemsSum'], loyaltyBasket),
      hasLoyaltyAccess: path(['status', 'ACCESS'], loyalty) === '1',
      cert
    }
  }
  return mapStateToProps
}

export default WrappedComponent => {
  @connect(makeMapStateToProps, {
    navigateTo: push,
    updateGroup,
    setProductModal,
    setAnalogsModal,
    setOrderData,
    setChecked,
    setItemCounts,
    setActiveStore,
    showModal,
    hideModal,
    setMultiplicity,
    updateItemsOrder,
    setForPoints,
    setProductBarcodeModal
  })
  class Product extends PureComponent {
    static displayName = `Product(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      switchItemInGroup: func,
      pushToBookmark: func,
      pushToBasket: func,
      onItemSelect: func,
      updateGroup: func,
      setProductModal: func,
      setAnalogsModal: func,
      setOrderData: func,
      setItemCounts: func,
      setActiveStore: func,
      showModal: func,
      hideModal: func,
      navigateTo: func,
      setChecked: func,
      updateItemsOrder: func,
      setForPoints: func,
      isUserWithoutPassword: bool,
      checked: bool,
      isLoyalty: bool,
      isBookmark: bool,
      isBasket: bool,
      withError: bool,
      isNewApi: bool,
      hasLoyaltyAccess: bool,
      isBuyNotMultiple: bool,
      isNotMultiple: bool,
      id: string.isRequired,
      type: string,
      catalog: string,
      activeStore: string,
      basketStore: string,
      min: number,
      remain: number,
      step: number,
      amount: number,
      bookmark: number,
      itemsCount: number,
      balance: number,
      loyaltyBasketSum: number,
      item: object.isRequired,
      productList: object,
      itemOrder: object
    }

    static defaultProps = {
      switchItemInGroup: () => {},
      pushToBookmark: () => {},
      pushToBasket: () => {},
      onItemSelect: () => {},
      setItemCounts: () => {},
      setActiveStore: () => {},
      updateGroup: () => {},
      setOrderData: () => {},
      showModal: () => {},
      hideModal: () => {},
      navigateTo: () => {},
      setChecked: () => {},
      updateItemsOrder: () => {},
      setForPoints: () => {},
      activeStore: '',
      isUserWithoutPassword: true,
      checked: false,
      isLoyalty: false,
      isBookmark: false,
      isBasket: false,
      withError: false,
      isNewApi: false,
      hasLoyaltyAccess: false,
      isBuyNotMultiple: false,
      type: 'section'
    }

    componentDidMount() {
      const { isBasket, withError, checked } = this.props
      if (!isBasket) {
        return
      }
      if (withError && !checked) {
        this.handleSelectItem(true)
      }
    }

    componentDidUpdate() {
      const { isBasket, withError, checked } = this.props
      if (!isBasket) {
        return
      }
      if (!this.props.withError && withError && !checked) {
        this.handleSelectItem(true)
      }
    }

    handleItemCountChange = count => {
      const {
        isLoyalty,
        id,
        type,
        catalog: catalogBasket,
        isBookmark,
        bookmark
      } = this.props
      const value = isLoyalty && count > LOYALTY_MAX ? LOYALTY_MAX : count
      let catalog = catalogBasket
      if (isBookmark) {
        catalog = `bookmark_${bookmark}`
      }
      const product = {
        [id]: value
      }
      return this.props.setItemCounts({
        type,
        catalog,
        items: product
      })
    }

    handleChangeOrder = data => {
      const { id } = this.props
      return this.props.setOrderData({ id, data })
    }

    handleSelectItem = checked => {
      const { id } = this.props
      this.props.setChecked({ [id]: checked })
    }

    handleChangeInBasket = count => {
      const { item, type, switchItemInGroup, catalog } = this.props
      switchItemInGroup({
        id: catalog,
        type,
        items: {
          [item.ID]: { QUANTITY: count }
        },
        params: {
          full: 'full'
        },
        settings: {
          withError: this.props.withError,
          catalog
        }
      })
    }

    handleUpdateBasket = (
      count,
      {
        type,
        group = [],
        notResetStatusOnSuccess,
        params = {},
        settings = {},
        afterAdd = () => {}
      }
    ) => {
      const {
        item,
        withError,
        catalog: catalogProp,
        bookmark: valueProp
      } = this.props
      const catalog =
        is(Array, group) && !isEmpty(group) ? head(group) : catalogProp
      const value =
        is(Array, group) && !isEmpty(group) ? last(group) : valueProp
      return this.props
        .updateGroup({
          notResetStatusOnSuccess,
          type,
          items: { [item.ID]: count },
          params: {
            withError,
            value,
            ...params
          },
          settings: {
            ...settings,
            withError,
            catalog
          }
        })
        .then(() => afterAdd())
    }

    handleNavigateTo = url => this.props.navigateTo(url)

    handleKeyUpAmount = (event, amount) => {
      if (event.key !== 'Enter') {
        return
      }
      const { isBookmark, pushToBookmark, pushToBasket, itemsCount } =
        this.props
      const promise =
        amount === parseInt(itemsCount, 10)
          ? Promise.resolve(amount)
          : this.handleItemCountChange(amount)
      promise.then(() => {
        if (isBookmark) {
          pushToBookmark()
        } else {
          pushToBasket()
        }
      })
    }

    setActiveStore = ({ store }) => {
      const { id } = this.props
      this.props.setActiveStore({ store, id }).then(() => {
        const { min, remain, amount } = this.props
        const newAmount = clamp(
          Math.min(min, remain),
          Math.max(min, remain),
          amount
        )
        this.props.updateItemsOrder({
          items: {
            [id]: {
              activeStore: store,
              amount: newAmount,
              isNotMultiple: false
            }
          }
        })
      })
    }

    setForPoints = ({ status }) => {
      const { id } = this.props
      this.props.setForPoints({ status, id })
    }

    showModal = name => {
      this.props.showModal(name)
    }

    hideModal = name => {
      this.props.hideModal(name)
    }

    render() {
      const {
        itemsCount,
        isUserWithoutPassword,
        item,
        checked,
        balance,
        loyaltyBasketSum,
        type,
        activeStore,
        basketStore,
        isBuyNotMultiple,
        min,
        step,
        remain,
        amount,
        isNotMultiple
      } = this.props

      const withActions =
        !isUserWithoutPassword && item && item.PRICE !== undefined
      const price = parseFloat(pathOr(0, ['PRICE'], item))
      const amountValue = Number(this.props.amount) || Number(itemsCount)
      const disableToLoyalty = balance - loyaltyBasketSum < price * amountValue
      const stockDeliveryDate = compose(
        defaultTo(''),
        head,
        keys,
        path(['REMAINS', 'DELIVERY'])
      )(item)

      // кратно если кол-во в инпуте:
      // - равно минимальному остатку
      // - кратно шагу
      // - равно остатку на основном складе и склад - центральный
      const currentStore = currentStoreSelector(item, activeStore)
      const multipleBuy = isMultipleBuy(amountValue, currentStore)
      const isNotMultipleProduct =
        isBuyNotMultiple && (isNotMultiple || !prop('isMultiple', multipleBuy))

      return (
        <WrappedComponent
          {...this.props}
          stockDeliveryDate={stockDeliveryDate}
          remain={remain}
          min={isNotMultiple ? 1 : min}
          step={isNotMultiple ? 1 : step}
          minInit={min}
          stepInit={step}
          amount={amount}
          currentStore={currentStore}
          onItemCountChange={this.handleItemCountChange}
          onChangeInBasket={
            type === 'loyaltyBasket'
              ? this.handleChangeInBasket
              : this.handleUpdateBasket
          }
          onItemSelect={this.handleSelectItem}
          disableToLoyalty={disableToLoyalty}
          withActions={withActions}
          onNavigateTo={this.handleNavigateTo}
          onKeyUpAmount={this.handleKeyUpAmount}
          onChangeOrder={this.handleChangeOrder}
          checked={checked}
          multipleBuy={multipleBuy}
          isMultiple={prop('isMultiple', multipleBuy)}
          isNotMultiple={isNotMultipleProduct}
          isBuyNotMultiple={isBuyNotMultiple}
          activeStore={activeStore}
          basketStore={basketStore}
          setActiveStore={this.setActiveStore}
          setForPoints={this.setForPoints}
          showModal={this.showModal}
          hideModal={this.hideModal}
        />
      )
    }
  }

  return hoistStatics(Product, WrappedComponent)
}
