// @ts-nocheck
import React, { FC, FormEventHandler } from 'react'

import { compose, has } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { Field, reduxForm } from 'redux-form'

import InputWithErrors from 'components/InputWithErrors'
import Link from 'components/Link'
import {
  currentContractorGuidSelector,
  currentContractorNameSelector
} from 'redux/modules/personal'
import Button from 'UI/Button'
import Text from 'UI/Text'
import { FieldTextarea } from 'UI/Textarea'
import { errorTestPhone, testPhone } from 'utils/testRegexp'

import s from './FormCallBack.scss'
import submit from '../submit'

const fieldset = [
  {
    name: 'callback_name',
    placeholder: 'Как к вам обращаться',
    component: InputWithErrors,
    required: true
  },
  {
    name: 'callback_phone',
    placeholder: 'Введите телефон',
    component: InputWithErrors,
    required: true
  }
]

interface IFormCallBack {
  contractorName: string
  contractorGUID: string
  clientApi: object
  handleSubmit: FormEventHandler<HTMLFormElement>

  invalid: boolean
  submitting?: boolean
}

const FormCallBack: FC<IFormCallBack> = ({
  contractorName,
  contractorGUID,
  clientApi,
  handleSubmit,
  invalid,

  submitting
}) => (
  <form
    className={s.form}
    onSubmit={handleSubmit(
      submit({
        formName: 'callback',
        contractor_name: contractorName,
        contractor_code: contractorGUID,
        clientApi
      })
    )}
  >
    <Text>Внесите информацию</Text>

    <fieldset className={s.fieldset}>
      {fieldset.map(field => (
        <Field key={field.name}
          {...field} />
      ))}
    </fieldset>
    <fieldset className={s.fieldset}>
      <Field
        component={FieldTextarea}
        name='callback_comment'
        placeholder='Введите сообщение'
      />
    </fieldset>
    <Text>
      Отправляя форму я даю
      <Link to='/documents/consent'
        color='blue'>
        &nbsp;согласие на обработку персональных данных&nbsp;
      </Link>
      в соответствии с
      <Link to='/documents/policy'
        color='blue'>
        &nbsp;политикой конфиденциальности&nbsp;
      </Link>
      .
    </Text>
    <Button type='submit'
      disabled={submitting || invalid}>
      Отправить
    </Button>
  </form>
)

export default compose(
  reduxForm({
    form: 'callbackForm',
    // eslint-disable-next-line complexity
    validate: values => {
      const errors = {
        callback_name: !has('callback_name', values) ? 'Обязательное поле' : '',
        callback_comment: !has('callback_comment', values)
          ? 'Обязательное поле'
          : '',
        callback_phone:
          values.callback_phone && !testPhone(values.callback_phone)
            ? errorTestPhone
            : ''
      }
      return errors
    }
  }),
  connect(({ personal }) => ({
    contractorName: currentContractorNameSelector(personal),
    contractorGUID: currentContractorGuidSelector(personal)
  })),
  inject(({ helpers: { clientApi } }) => ({ clientApi }))
)(FormCallBack)
