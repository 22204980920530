/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string, number, func, object } from 'prop-types'
import R, { path, prop } from 'ramda'

import HonestSign from 'components/HonestSign'
import Icon from 'components/Icon'
import Link from 'components/Link'
import Picture from 'components/Picture'
import ProductActions from 'components/ProductActions'
import ProductCode from 'components/ProductCode'
import ProductGalleryModal from 'components/ProductGalleryModal'
import ProductOneActions from 'components/ProductPage/ProductOneActions'
import ProductPrices from 'components/ProductPrices'
import TextTruncate from 'components/TextTruncate'
import ButtonMoreOptions from 'containers/ButtonMoreOptions'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import Labels from 'containers/Labels'
import basket from 'decorators/basket'
import { display } from 'decorators/device'
import product from 'decorators/product'
import capitalizeFirstLetter from 'utils/capitalizeFirstLetter'

import styles from './ProductCard.scss'
import Cap from '../Cap'

const LOYALTY_DIM = 206
const CATALOG_DIM = '100%'

@basket
@product
@display
export default class ProductCard extends PureComponent {
  static propTypes = {
    item: object,
    isUpdating: object,
    descriptionStores: object,
    multipleBuy: object,
    removeFromFavorite: func,
    removeFromCompare: func,
    onChangeOrder: func,
    onChangeInBasket: func,
    showModal: func,
    setProductModal: func,
    setAnalogsModal: func,
    setActiveStore: func,
    setForPoints: func,
    isUserWithoutPassword: bool,
    withActions: bool,
    inCompare: bool,
    inFavorite: bool,
    isRemoveFromCompare: bool,
    isBookmark: bool,
    isSlider: bool,
    isLoyalty: bool,
    isLoyaltyProduct: bool,
    autoHeight: bool,
    isQuickView: bool,
    inline: bool,
    isMultiple: bool,
    isNotMultiple: bool,
    isBuyNotMultiple: bool,
    forPoints: bool,
    isForPoints: bool,
    isForPointsBasket: bool,
    isDesktop: bool,
    isMobile: bool,
    serial: number,
    min: number,
    delay: number,
    bookmark: number,
    amount: number,
    remain: number,
    step: number,
    minInit: number,
    stepInit: number,
    basket: number,
    id: string,
    groupType: string,
    activeStore: string,
    basketStore: string,
    onNavigateTo: func
  }

  static defaultProps = {
    removeFromFavorite: () => {},
    removeFromCompare: () => {},
    onChangeOrder: () => {},
    onChangeInBasket: () => {},
    showModal: () => {},
    setProductModal: () => {},
    setAnalogsModal: () => {},
    setActiveStore: () => {},
    setForPoints: () => {},
    isMultiple: true,
    isNotMultiple: false,
    isBuyNotMultiple: false,
    isQuickView: false,
    isUserWithoutPassword: false,
    withActions: false,
    inCompare: false,
    inFavorite: false,
    isRemoveFromCompare: false,
    isBookmark: false,
    isSlider: false,
    isLoyalty: false,
    isLoyaltyProduct: false,
    autoHeight: false,
    inline: false,
    forPoints: false,
    isForPoints: false,
    isForPointsBasket: false,
    serial: 0
  }

  state = {
    isImagePopup: false
  }

  handleShowPopup = popup => e => {
    const MIDDLE_BUTTON = 1
    if (e.button === MIDDLE_BUTTON) return
    this.setState({
      [popup]: true
    })
  }

  handleHidePopup = popup => () => {
    this.setState({
      [popup]: false
    })
  }

  handleShowAnalogs = id => e => {
    const { setAnalogsModal } = this.props
    const MIDDLE_BUTTON = 1
    if (e.button === MIDDLE_BUTTON) return
    setAnalogsModal(id)
  }

  handleSetStore = store => this.props.setActiveStore({ store })

  handleShowQuickView = e => {
    e.preventDefault()
    e.stopPropagation()
    const { setProductModal, id, isQuickView, isDesktop, onNavigateTo } = this.props
    if (isQuickView) {
      setProductModal(id)
    }

    if (!isDesktop) {
      onNavigateTo(`/catalog/product/${id}`)
    }
  }

  onRemoveFromCompare = () => {
    const { removeFromCompare, item } = this.props
    removeFromCompare(prop('ID', item))
  }

  renderRightColumn = () => {
    const {
      item,
      multipleBuy,
      isLoyalty,
      isSlider,
      isUserWithoutPassword,
      isMultiple,
      isNotMultiple,
      isBookmark,
      isBuyNotMultiple,
      bookmark,
      serial,
      activeStore,
      forPoints,
      isForPoints,
      isForPointsBasket,
      isRemoveFromCompare,
      setForPoints
    } = this.props
    const analogsInfo = R.pick(['ACTIVE', 'ISSET_ANALOGS'], item)

    if (isLoyalty) {
      return (
        <ProductOneActions
          id={this.props.id}
          mode='card'
          isRightBlock={false}
        />
      )
    }

    return (
      <div itemProp='orderedItem'>
        <ProductPrices
          className={styles.prices}
          item={item}
          multipleBuy={multipleBuy}
          isLoyalty={isLoyalty}
          isSlider={isSlider}
          isUserWithoutPassword={isUserWithoutPassword}
          isMultiple={isMultiple}
          isNotMultiple={isNotMultiple}
          isBuyNotMultiple={isBuyNotMultiple}
          onShowAnalogs={this.handleShowAnalogs}
          activeStore={activeStore}
          forPoints={forPoints}
          isForPoints={isForPoints}
          setForPoints={setForPoints}
          amount={this.props.basket || this.props.amount}
          noPointsPostfix
        />
        {!isUserWithoutPassword && (
          <ButtonMoreOptions
            inFavorite={this.props.inFavorite}
            inCompare={this.props.inCompare}
            isBookmark={isBookmark}
            bookmark={bookmark}
            activeStore={activeStore}
            onUpdateGroup={this.props.onChangeInBasket}
            removeFromCompare={this.onRemoveFromCompare}
            isRemoveFromCompare={isRemoveFromCompare}
            basket={this.props.basket}
          />
        )}
        <div className={styles.actions}>
          <ProductActions
            serial={serial}
            productId={item.ID}
            analogsInfo={analogsInfo}
            onShowAnalogs={this.handleShowAnalogs}
            inCompare={this.props.inCompare}
            inFavorite={this.props.inFavorite}
            isBookmark={isBookmark}
            bookmark={bookmark}
            delay={this.props.delay}
            mod='ProductCard'
            onUpdateGroup={this.props.onChangeInBasket}
            onChange={this.props.onChangeOrder}
            isMultiple={isMultiple}
            isNotMultiple={isNotMultiple}
            isBuyNotMultiple={isBuyNotMultiple}
            isForPoints={isForPoints}
            isForPointsBasket={isForPointsBasket}
            groupType='catalog'
            type={this.props.groupType}
            removeFromCompare={this.props.removeFromCompare}
            removeFromFavorite={this.props.removeFromFavorite}
            activeStore={activeStore}
            basketStore={this.props.basketStore}
            onSetStore={this.handleSetStore}
            item={this.props.item}
            min={this.props.min}
            remain={this.props.remain}
            step={this.props.step}
            minInit={this.props.minInit}
            stepInit={this.props.stepInit}
            amount={this.props.amount}
            basket={this.props.basket}
            isUpdating={this.props.isUpdating}
            descriptionStores={this.props.descriptionStores}
            isCarousel
          />
        </div>
      </div>
    )
  }

  render() {
    const {
      item,
      id,
      isSlider,
      isMobile,
      isRemoveFromCompare,
      isBookmark,
      isLoyalty,
      isLoyaltyProduct,
      autoHeight,
      bookmark,
      inline,
      isUserWithoutPassword,
      isQuickView,
      isDesktop
    } = this.props
    const outOfAssortment =
      !isUserWithoutPassword && !R.propOr(true, 'ACTIVE', item)
    const isHonestSign = R.propOr(false, 'IS_HONEST_SIGN', item)
    const isGrayPreview = isUserWithoutPassword ? false : outOfAssortment
    return (
      <div
        className={cx(styles.productCard, {
          [styles.productCard_inline]: inline,
          [styles.productCard_loyalty]: isLoyaltyProduct,
          [styles.productCard_autoheight]: autoHeight,
          [styles.productCardRelative]: isSlider,
          [styles.noAuth]: this.props.withActions,
          [styles.productCard_loyaltySlider]: isLoyaltyProduct && isSlider,
          [styles.productCardCompare]: isRemoveFromCompare
        })}
        itemScope
        itemType='http://schema.org/Product'
        data-testid='productCard'
      >
        <meta
          itemProp='itemCondition'
          itemType='http://schema.org/OfferItemCondition'
          content='http://schema.org/NewCondition'
        />
        <meta itemProp='name'
          content={prop('NAME', item)} />
        {!isLoyalty && (
          <div className={cx(styles.labels, {
            [styles.labels_auth]: !isUserWithoutPassword
          })}>
            <Labels type='card'
              item={item} />
          </div>
        )}
        <div
          className={cx(styles.imageWrap, {
            [styles.imageWrap_clickable]: isQuickView
          })}
          itemProp='image'
        >
          <Picture
            backgroundSize='contain'
            className={cx(styles.previewPicture, {
              [styles.previewPicture_loyalty]: isLoyalty,
              [styles.previewPicture_grayscale]: isGrayPreview
            })}
            imgClassName={styles.productCardImg}
            width={isLoyalty ? LOYALTY_DIM : CATALOG_DIM}
            height={isLoyalty ? LOYALTY_DIM : CATALOG_DIM}
            src={R.prop('PREVIEW_PICTURE_PATH', item)}
            onClick={this.handleShowPopup('isImagePopup')}
            isLazy={false}
          />
          {isQuickView && isDesktop && (
            <div
              role='presentation'
              className={styles.imageHover}
              onClick={this.handleShowQuickView}
              itemProp='potentialAction'
            >
              <Icon className={styles.imageHoverIcon}
                icon='eye' />
              Быстрый просмотр
            </div>
          )}
          {this.state.isImagePopup && (
            <DisableBodyScroll>
              <ProductGalleryModal
                id={prop('ID', item)}
                mainPicture={path(['DETAIL_PICTURE_PATH'], item)}
                onClose={this.handleHidePopup('isImagePopup')}
              />
            </DisableBodyScroll>
          )}
          {isHonestSign && <HonestSign 
            className={styles.honestSign} 
            height={24} 
            width={73}/>}
        </div>
        <div className={cx(styles.padding, styles.productInfoTop)}>
          <ProductCode
            name='Код'
            type='code'
            className={styles.code}
            code={R.prop('CODE_1C', item)}
            isLoyalty={isLoyalty}
          />
          {!isMobile && (
            <div
              className={styles.brand}
              itemProp='brand'
              itemScope
              itemType='http://schema.org/Brand'
            >
              <div className={styles.brandLabel}>Бренд</div>
              {isLoyalty ? (
                <div className={styles.brandTitle}
                  itemProp='name'>
                  {R.path(['TM', 'VALUE'], item)}
                </div>
              ) : (
                <Link to={`/catalog/brand/${R.path(['TM', 'ID'], item)}`}
                  color='blue'>
                  {R.path(['TM', 'VALUE'], item)}
                </Link>
              )}
            </div>
          )}

          <TextTruncate
            component={Link}
            className={cx(styles.productName, {
              [styles.productNameLoyalty]: isLoyaltyProduct
            })}
            to={`${isBookmark ? `/bookmark/${bookmark}` : ''}${
              isLoyalty ? '/loyalty' : ''
            }/catalog/product/${id}`}
            itemProp='url'
            data-testid='productCardName'
          >
            {capitalizeFirstLetter(R.propOr('', 'NAME', item))}
          </TextTruncate>
        </div>
        <div
          className={cx(styles.productInfoBottom, styles.paddingLight, {
            [styles.productInfoBottom_withOutPrice]: !this.props.withActions,
            [styles.isLoyaltyBottom]: isLoyalty
          })}
          itemScope
          itemProp='http://schema.org/Offer'
        >
          {this.props.withActions || outOfAssortment ? (
            this.renderRightColumn()
          ) : (
            <Cap
              showModal={this.props.showModal}
              mode='ProductCard'
              price={parseFloat(R.pathOr(0, ['PRICE'], item))}
              multiplicity={R.pathOr(false, ['MULTIPLICITY_HTML'], item)}
              isStoreAvailable={R.pathOr(false, ['STORE', 'AVAILABLE'], item)}
            />
          )}
        </div>
      </div>
    )
  }
}
