/* eslint-disable complexity */
import React, { useMemo } from 'react'

import cx from 'classnames'
import { bool, array, object, string } from 'prop-types'
import { pathOr, length, findIndex, propEq } from 'ramda'
import { formValues } from 'redux-form'

import InputWithErrors from 'components/InputWithErrors'
import s from 'components/Registration/Registration.scss'
import RegistrationSelect from 'components/Registration/RegistrationSelect'
import Input from 'UI/Input'

const PHONE_CODE = 3

const RegistrationPhone = formValues({ countryCode: 'country_code' })(({
  isFieldSelect,
  phoneCodeList,
  countryCode,
  phoneCode,
  countries,
  phone
}) => {
  const selectedIndex = useMemo(() => 
    findIndex(propEq('id', countryCode))(countries),
  [countryCode, countries])

  const phoneCodeValue = useMemo(() => pathOr('', ['input', 'value'], phoneCode), [phoneCode])

  const useShortAreaCode = useMemo(() => length(phoneCodeValue) > PHONE_CODE, [phoneCodeValue])

  return (
    <div className={s.registration__phone}>
      <div className={cx(s.registration__phone_code, {
        [s.registration__phone_code_input]: !isFieldSelect
      })}>
        {isFieldSelect ? (
          <RegistrationSelect
            {...phoneCode}
            items={phoneCodeList}
            withoutFogging
          />
        ) : (
          <>
            <div className={s.registration__phone_code_field}>
              <div className={s.select__block_image}>
                <img
                  src={`/images/registration/${pathOr('', [selectedIndex, 'icon'], countries)}.png`}
                  alt={pathOr('', [selectedIndex, 'icon'], countries)}
                  className={s.iconFlag}
                />
              </div>
              {phoneCodeValue}
            </div>
            <Input
              type='hidden'
              name={pathOr('', ['input', 'name'], phoneCode)}
              value={phoneCodeValue}
            />
          </>
        )}
      </div>
      <div className={cx(s.registration__phone_number, {
        [s.registration__phone_number_input]: !isFieldSelect
      })}>
        <InputWithErrors
          {...phone}
          placeholder={useShortAreaCode ? '__ ___ __ __' : '___ ___ __ __'}
          mask={useShortAreaCode ? '99 999 99 99' : '999 999 99 99'}
        />
      </div>
    </div>
  )
})

RegistrationPhone.propTypes = {
  isFieldSelect: bool,
  countryCode: string,
  phoneCodeList: array,
  countries: array,
  phoneCode: object,
  phone: object
}

RegistrationPhone.defaultProps = {
  phoneCodeList: [],
  countries: [],
  isFieldSelect: true
}

export default RegistrationPhone
