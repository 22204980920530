import React, { Component } from 'react'

import hoistStatics from 'hoist-non-react-statics'
import { bool } from 'prop-types'
import { connect } from 'react-redux'

export default () => WrappedComponent => {
  @connect(
    ({
      reduxAsyncConnect: { loaded: reduxAsyncConnectLoaded },
      updateData: { loaded: updateDataLoaded }
    }) => ({
      reduxAsyncConnectLoaded,
      updateDataLoaded
    })
  )
  class PreventRenderWhileLoading extends Component {
    static displayName = `PreventRenderWhileLoading(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      loaded: bool,
      reduxAsyncConnectLoaded: bool,
      updateDataLoaded: bool
    }

    shouldComponentUpdate({ reduxAsyncConnectLoaded, updateDataLoaded }) {
      return reduxAsyncConnectLoaded && updateDataLoaded
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistStatics(PreventRenderWhileLoading, WrappedComponent)
}
