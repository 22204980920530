import React, { useState, useRef, useCallback, useMemo } from 'react'

import cx from 'classnames'
import { object, array, func } from 'prop-types'
import { findIndex, propEq, propOr, pathOr, equals } from 'ramda'
import { useClickAway } from 'react-use'

import Icon from 'components/Icon'
import s from 'components/Registration/Registration.scss'
import Input from 'UI/Input'

const RegistrationCountry = ({ input, meta, items, handleChangeCode }) => {
  const ref = useRef(null)

  const [ isActiveTooltip, setActiveTooltip] = useState(false)

  const selectedIndex = useMemo(() =>
    findIndex(propEq('id', input.value || meta.initial))(items),
  [input, items, meta])

  const handleClickOutside = useCallback(() => {
    setActiveTooltip(false)
  }, [])

  const handleOpenTooltip = useCallback(() => {
    if (!isActiveTooltip) {
      setActiveTooltip(true)
    }
  }, [isActiveTooltip])

  useClickAway(ref, handleClickOutside)

  const handleChange = useCallback(id => () => {
    input.onChange(id)
    setActiveTooltip(false)
    handleChangeCode(id)
  }, [input, handleChangeCode])

  const renderItem = (item, index) => (
    <div
      role='presentation'
      key={propOr('', 'id', item)}
      className={cx(s.select__option_item, {
        [s.select__option_item_selected]: equals(selectedIndex, index)
      })}
      onClick={handleChange(propOr('', 'id', item))}
    >
      <img
        src={`/images/registration/${propOr('', 'icon', item)}.png`}
        alt={propOr('', 'icon', item)}
        className={s.icon__flag_option}
      />
      {propOr('', 'optionValue', item)}
    </div>
  )

  return (
    <div className={s.inputWrapper}>
      <div
        className={s.select}
        ref={ref}
      >
        <div
          role='presentation'
          className={s.select__block}
          onClick={handleOpenTooltip}
        >
          <div className={s.select__block_title}>
            <div className={s.select__block_image}>
              <img
                src={`/images/registration/${pathOr('', [selectedIndex, 'icon'], items)}.png`}
                alt={pathOr('', [selectedIndex, 'icon'], items)}
                className={s.icon__flag}
              />
            </div>
            {pathOr('', [selectedIndex, 'optionValue'], items)}
          </div>
          <Icon
            icon='down'
            size='small'
            className={cx(s.icon__select, {
              [s.icon__select_up]: isActiveTooltip
            })}
          />
        </div>
        <div className={cx(s.select__option, {
          [s.select__option_active]: isActiveTooltip
        })}>
          {items.map(renderItem)}
        </div>
      </div>
      <Input
        type='hidden'
        name={input.name}
        value={input.value}
      />
    </div>
  )
}

RegistrationCountry.propTypes = {
  input: object,
  meta: object,
  items: array,
  handleChangeCode: func
}

export default RegistrationCountry
