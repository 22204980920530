import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoading = state => state.compare.isLoading
export const getItems = state => state.compare.items
export const getTotalCount = state => state.compare.totalCount
export const getSections = state => state.compare.sections
export const getProperties = state => state.compare.properties
export const getSectionId = state => state.compare.sectionId
export const getCheckedProperties = state => state.compare.checkedProperties
export const getDirection = state => state.compare.direction
export const getOffset = state => state.compare.offset
export const getPosition = state => state.compare.position
export const getLimit = state => state.compare.limit
export const getOnlyDifference = state => state.compare.onlyDifference
export const getIsLoaded = state => state.compare.isLoaded
export const compareCount = createSelector(
  state => state,
  state => propOr(0, 'totalCount', state)
)
