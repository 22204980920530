import React, { FC, ForwardedRef, InputHTMLAttributes } from 'react'

import cx from 'classnames'
import ReactInputMask from 'react-input-mask'

import flattenInput from 'utils/flattenInput'

import s from './Input.scss'

interface IInput<T> extends InputHTMLAttributes<T> {
  error?: boolean | string
  mask?: string | (string | RegExp)[]
  ref?: ForwardedRef<T>
  success?: boolean
}

const Input: FC<IInput<HTMLInputElement>> = ({ error, mask, success, ...rest }) => {
  const Tag = mask ? ReactInputMask : 'input'

  return (
    <Tag
      {...rest}
      mask={mask || ''}
      className={cx(s.input, {
        [s.input_error]: error,
        [s.input_success]: success
      })}
    />
  )
}

export default Input

export const FieldInput = flattenInput(Input)
