import React, { PureComponent } from 'react'

import cx from 'classnames'
import { func, bool, object, array } from 'prop-types'
import { propOr, is } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { reduxForm } from 'redux-form'

import Icon from 'components/Icon'
import Registration from 'components/Registration'
import preventRenderWhileLoading from 'decorators/preventRenderWhileLoading'
import { getThemeName } from 'helpers/getTheme'
import { conditions as conditionsList, registrationForm, onSubmit as onFormSubmit } from 'helpers/registration'
import { isKdClientSelector } from 'redux/modules/apiAuth'
import {
  showModal as onShowModal,
  hideModal as onHideModal
} from 'redux/modules/modal'
import {
  fetchCompanyData,
  setValues as onSetValues,
  countriesSelector,
  companiesSelector,
  getMessage,
  getErrors,
  isSendedSelector,
  suggestionsErrorSelector
} from 'redux/modules/registration'

import s from './Registration.scss'

@preventRenderWhileLoading()
@connect(
  ({
    registration,
    apiAuth,
    router
  }) => ({
    isKdClient: isKdClientSelector({ apiAuth, router }),
    theme: getThemeName({ apiAuth, router }),
    countries: countriesSelector(registration),
    suggestionItems: companiesSelector(registration),
    conditions: conditionsList,
    isSended: isSendedSelector(registration),
    message: getMessage(registration),
    errors: getErrors(registration),
    isSuggestionsError: suggestionsErrorSelector(registration)
  }), {
    setValues: onSetValues,
    fetchCompanyData,
    showModal: onShowModal,
    hideModal: onHideModal
  }
)
@inject(({ helpers: { updateData, clientApi } }) => ({ updateData, clientApi }))
@reduxForm({
  ...registrationForm
})
export default class PopupRegistration extends PureComponent {
  static propTypes = {
    handleSubmit: func,
    invalid: bool,
    pristine: bool,
    submitting: bool,
    clientApi: object,
    conditions: array,
    reset: func,
    showModal: func,
    hideModal: func,
    updateData: object,
    setValues: func
  }

  componentDidMount() {
    const { updateData } = this.props
    updateData.set({ view: 'directories' })
    return updateData.update()
  }

  componentWillUnmount() {
    this.props.setValues({ isSended: false, message: '' })
    this.props.reset()
  }

  handleCloseModal = () => {
    this.props.setValues({ isSended: false, message: '' })
    this.props.reset()
  }

  handleSubmitError = isCommonError  => () => {
    const { setValues, showModal, hideModal } = this.props
    if (isCommonError) {
      setValues({ errors: {}, message: '' })
    } else {
      hideModal('registration')
      showModal('recoveryPassword')
      this.handleCloseModal()
    }
  }

  renderTable = item => {
    const steps = propOr([], 'steps', item)
    return (
      <div
        key={propOr('', 'title', item)}
        className={s.rulesTable}
      >
        <div className={s.rulesHeader}>
          {propOr('', 'title', item)}
        </div>
        {steps.map(condition => {
          const icon = propOr('', 'icon', condition)
          return (
            <div
              key={icon}
              className={s.rulesRow}
            >
              <div className={cx(s.rulesColumn, s.rulesColumnIcon)}>
                <div className={s.rulesStep}>
                  {is(Number, icon)
                    ? icon
                    : (
                      <Icon
                        icon={icon}
                        className={s.rulesIcon}
                      />
                    )
                  }
                </div>
              </div>
              <div className={cx(s.rulesColumn)}>
                {propOr('', 'text', condition)}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const {
      clientApi,
      handleSubmit,
      invalid,
      pristine,
      submitting,
      conditions,
      ...rest
    } = this.props
    return (
      <div className={s.modalContainer}>
        <Registration
          disabled={invalid || pristine || submitting}
          onSubmit={handleSubmit(onFormSubmit({ clientApi }))}
          onSubmitError={this.handleSubmitError}
          {...rest}
        />
        <div className={s.rules}>
          <div className={s.rulesTop}>
            {conditions.map(this.renderTable)}
          </div>
          <div className={s.rulesBottom}>
            <p className={s.rulesBottomTitle}>
              Остались вопросы?
            </p>
            <p>Свяжитесь с нами по телефону</p>
            <p className={s.rulesBottomPhone}>
              8-800-110-00-00
            </p>
          </div>
        </div>
      </div>
    )
  }
}
