import { pathOr, prop } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { clearFavorite as clearFavoriteProducts } from 'redux/modules/products'

const initialState = {
  itemsCount: 0
}

export { getItemsCount, favoriteCount } from './selector'

export const fetchCountSuccess = createAction('favorite/FETCH_COUNT_SUCCESS')
export const fetchSuccess = createAction('favorite/FETCH_SUCCESS')
export const clearFavorite = createAction('favorite/CLEAR_FAVORITE')
export const clearFavoriteSuccess = createAction(
  'favorite/CLEAR_FAVORITE_SUCCESS'
)
const clearFavoriteFailure = createAction('favorite/CLEAR_FAVORITE_FAILURE')

const handleFetchCountSuccess = (state, payload) => ({
  ...state,
  itemsCount: pathOr(
    state.itemsCount,
    ['data', 'response', 'NAV', 'FAVORITE', 'CNT'],
    payload
  )
})

const handleFetchSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  itemsCount: pathOr(state.itemsCount, ['data', 'response', 'CNT'], payload)
})

const requestClear =
  ({ clientApi }) =>
    () =>
      clientApi
        .post('/v3/personal/favorite/delete/')
        .then(clearFavoriteSuccess)
        .catch(clearFavoriteFailure)

const handleClearFavorite = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true
    },
    Effects.promise(requestClear({ clientApi }))
  )

const handleClearFavoriteSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(false, ['data', 'response', 'SUCCESS'], payload)
  const itemsCount = isSuccess
    ? pathOr(0, ['data', 'response', 'CNT'], payload)
    : prop('itemsCount', state)
  if (isSuccess) {
    effects.push(Effects.call(clearFavoriteProducts))
  }
  return loop(
    {
      ...state,
      isLoading: false,
      isLoaded: isSuccess,
      itemsCount
    },
    Effects.batch(effects)
  )
}

const handleClearFavoriteFailure = (state, { data }) => ({
  ...state,
  isLoading: false,
  error: data
})

const reducer = createReducer(on => {
  on(fetchCountSuccess, handleFetchCountSuccess)
  on(fetchSuccess, handleFetchSuccess)
  on(clearFavorite, handleClearFavorite)
  on(clearFavoriteSuccess, handleClearFavoriteSuccess)
  on(clearFavoriteFailure, handleClearFavoriteFailure)
}, initialState)

export default reducer
