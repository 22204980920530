/* eslint-disable react/no-unstable-nested-components */
import React from 'react'

import { RootStateOrAny, useSelector } from 'react-redux'

import ResultPopup from 'components/ResultPopup'
import { isAuthSelector } from 'redux/modules/token'
import Text from 'UI/Text'

import s from './FormHOC.scss'

const ShipmentSuccess = () => (
  <span className={s.success}>
    <Text semibold>Спасибо за Ваше мнение!</Text>
    <Text>Отзыв направлен в отдел контроля качества доставки</Text>
  </span>
)

const msg: { [key: string]: React.ReactNode } = {
  shipmentSuccess: <ShipmentSuccess />
}

const FormHOC = (Component: React.ComponentType) => {
  const WrappedComponent = (props: any) => {
    const isAuth = useSelector(({ token }: RootStateOrAny) =>
      isAuthSelector(token)
    )
    if (!isAuth) return null
    const { popupSuccess, popupError } = props

    return (
      <>
        <Component {...props} />
        <ResultPopup
          msgSuccess={msg[popupSuccess]}
          popupSuccess={popupSuccess}
          popupError={popupError}
        />
      </>
    )
  }
  return WrappedComponent
}

export default FormHOC
