import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

const initialState = {
  name: null,
  isActive: false
}

export { getIsActive, getName } from './selector'

export const delayHideModal = createAction('modal/DELAY_HIDE_MODAL')
export const hideModal = createAction('modal/HIDE_MODAL')
export const showModal = createAction('modal/SHOW_MODAL')

const delay = milliseconds =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, milliseconds)
  })

const handleHideModal = () => initialState

const handleShowModal = (state, payload) => ({
  ...state,
  name: payload,
  isActive: true
})

const promiseDelay = ms => delay(ms).then(hideModal)

const handleDelayHideModal = (state, payload) =>
  loop(
    {
      ...state
    },
    Effects.promise(promiseDelay, payload)
  )

const reducer = createReducer(on => {
  on(delayHideModal, handleDelayHideModal)
  on(hideModal, handleHideModal)
  on(showModal, handleShowModal)
}, initialState)

export default reducer
