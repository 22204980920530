import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, func, bool } from 'prop-types'

import Icon from 'components/Icon'
import Link from 'components/Link'
import Sticky from 'components/Sticky'
import HeaderLogo from 'containers/HeaderLogo'

import styles from './HeaderContacts.scss'

export default class HeaderContacts extends PureComponent {
  static propTypes = {
    className: string,
    catalogIcon: string,
    catalogTitle: string,
    handleBreakpointPassed: func,
    setCatalogVisible: func,
    setListening: func,
    buttonCatalogRef: func,
    isVisible: bool,
    isBookmark: bool,
    isLoyalty: bool
  }

  static defaultProps = {
    className: ''
  }

  handleVisibleMenu = () => {
    const { isLoyalty, isVisible } = this.props
    if (!isLoyalty) {
      this.props.setCatalogVisible(!isVisible)
      this.props.setListening(!isVisible)
    }
  }

  render() {
    const {
      className,
      handleBreakpointPassed,
      buttonCatalogRef,
      catalogIcon,
      catalogTitle,
      isLoyalty,
      isBookmark
    } = this.props
    const Tag = isLoyalty ? Link : 'div'
    return (
      <div
        className={cx(styles.contacts, {
          [className]: true
        })}
      >
        <HeaderLogo />
        <Sticky
          className={styles.catalogLinkWrapper}
          absoluteClassName={styles.catalogLinkWrapperAbsolute}
          fixedClassName={styles.catalogLinkWrapperFixed}
          onBreakpointPassed={handleBreakpointPassed}
        >
          <Tag
            role='presentation'
            ref={buttonCatalogRef}
            to='/loyalty/catalog'
            className={cx(styles.linkToCatalog, {
              [styles.linkToCatalogLoyalty]: isLoyalty,
              [styles.linkToCatalogBookmark]: isBookmark
            })}
            onClick={this.handleVisibleMenu}
          >
            <Icon
              className={cx(styles.catalogIcon, {
                [styles.catalogIcon_bookmark]: isBookmark,
                [styles.catalogIcon_loyalty]: isLoyalty
              })}
              icon={catalogIcon}
            />

            <span className={styles.catalogLinkTitle}>{catalogTitle}</span>
          </Tag>
        </Sticky>
      </div>
    )
  }
}
