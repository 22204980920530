import React from 'react'

import { string } from 'prop-types'

import Icon from 'components/Icon/Icon'

import s from './ProductActions.scss'

const StockDelivery = ({ title }) => {
  if (!title) return null
  return (
    <div className={s.delivery}>
      <Icon icon='store-delivery'
        className={s.deliveryIcon} />
      {title}
    </div>
  )
}

StockDelivery.propTypes = {
  title: string
}

export default StockDelivery
