import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const OffersMain = loadable(() =>
  import(/* webpackChunkName: "OffersMain" */ 'pages/OffersMain/OffersMain')
)

export default asyncConnect([
  {
    key: 'pageOffers',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'offersMain' })
      return updateData.update()
    }
  }
])(OffersMain)
