/* eslint-disable complexity */
import React, { useCallback } from 'react'

import cx from 'classnames'
import { pathOr, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import Icon from 'components/Icon'
import Image from 'components/Image'
import Interactions from 'components/Interactions'
import Link from 'components/Link'
import config from 'config'
import { isDesktopSelector } from 'redux/modules/device'
import {
  fetchExpiration,
  fetchTarget,
  loyaltyHeaderSelector
} from 'redux/modules/loyalty'
import {
  getAccess,
  isLoyaltyMember as isLoyaltyMemberSelector,
  tempUserSelector
} from 'redux/modules/personal'
import { modeSelector } from 'redux/modules/settings/selector'
import { getTheme } from 'redux/modules/theme'
import { isAuthSelector } from 'redux/modules/token'

import styles from './HeaderLoyalty.scss'

const HeaderLoyalty = () => {
  const dispatch = useDispatch()

  const isDesktop = useSelector(({device}) => isDesktopSelector(device))

  const isAuth = useSelector(({ token }) => isAuthSelector(token))

  const member = useSelector(({ personal }) => isLoyaltyMemberSelector(personal))

  const loyalty = useSelector(({ personal }) => getAccess(personal, 'LOYALTY'))

  const isLoyaltyMember = isAuth && member
  const isLoyaltyVisible = isAuth && loyalty

  const {
    morePoints,
    points,
    pointsDimension,
    delta,
    imageSrc,
    target,
    isShowTarget,
    isLoyaltyRemainActive,
    isTargetLoaded,
    isExpirationLoaded,
    sumExpiration,
    dateExpiration
  } = useSelector(state => loyaltyHeaderSelector(state))

  const isTempUser = useSelector(({ personal }) => tempUserSelector(personal))

  const mode = useSelector(state => modeSelector(prop('settings', state)))

  const isLoyalty = mode === 'loyalty'

  const { theme } = useSelector(state => getTheme(state))

  const handleFetchExpiration = useCallback(() => dispatch(fetchExpiration()), [dispatch])

  const handleFetchTarget = useCallback(() => dispatch(fetchTarget()), [dispatch])

  const loyaltyPointsText = !isLoyaltyMember
    ? 'Твои подарки'
    : `${points} ${pointsDimension}`
  const isRenderPopup = isLoyaltyVisible && isLoyaltyMember

  let link
  if (isLoyaltyVisible && isLoyaltyMember) {
    link = '/loyalty'
  } else if (isLoyaltyVisible && !isTempUser) {
    link = '/loyalty/how_it_works'
  }

  const handleFetchLoyaltyTarget = useCallback(() => {
    if (!isExpirationLoaded) {
      handleFetchExpiration()
    }
    if (!isLoyalty && !isTargetLoaded) {
      handleFetchTarget()
    }
  }, [handleFetchExpiration, handleFetchTarget, isExpirationLoaded, isLoyalty, isTargetLoaded])

  const handleGetThemeIcon = icon => pathOr('',
    ['themes', theme, 'icons', icon], config
  )

  const renderContent = () => (
    <div
      className={cx(styles.content, {
        [styles.content__main]: !isLoyalty
      })}
    >
      <div className={styles.content__info}>
        <Icon className={styles.content__icon}
          icon='flame' />
        <div className={styles.content__text}>
          <div>
              Сгорит
            <span className={styles.content__pointsFired}>{sumExpiration}</span>из
            <span className={styles.content__pointsCurrent}>{points}</span>
            {pointsDimension}
          </div>
          <div className={styles.content__pointsExpirationDate}>{dateExpiration}</div>
        </div>
      </div>
      <Link to='/loyalty/statuses'
        color='blue'>
          Больше информации &rarr;
      </Link>
    </div>
  )

  const renderTargetContent = () => (
    <div className={styles.target}>
      <Link to='/loyalty'>
        <div className={styles.target__item}>
          <div className={styles.target__name}>
            <span className={styles.target__title}>Моя цель:</span>
            {target}
          </div>

          <div className={styles.target__image}>
            <Image
              backgroundSize='fullWidth'
              width={64}
              height={64}
              src={imageSrc}
            />
          </div>
        </div>

        {delta > 0 ? (
          <div className={styles.points}>{morePoints}</div>
        ) : (
          <button className={styles.getGiftBtn}
            type='button'>
            Забрать подарок
          </button>
        )}
      </Link>
    </div>
  )

  const Tag = isDesktop ? Interactions : 'div'
  const tagProps = isDesktop
    ? {
      onDelayedMouseEnter: handleFetchLoyaltyTarget
    } : {}

  return (
    <Tag {...tagProps}>
      <HeaderMenuItem
        iconPath={handleGetThemeIcon('loyaltyLogo')}
        iconWrapper='loyalty'
        isMenuColor
        linkTo={link}
        renderPopup={isRenderPopup}
        text={loyaltyPointsText}
      >
        {isLoyaltyRemainActive && isDesktop && renderContent()}
        {isShowTarget && isDesktop && renderTargetContent()}
      </HeaderMenuItem>
    </Tag>
  )
}

export default HeaderLoyalty
