import React from 'react'

import cx from 'classnames'
import { range } from 'ramda'

import s from './Placeholder.scss'

const COLUMNS = 3
const ROWS = 6

const ContentMenu = () => {
  return (
    <>
      <div className={s.item}>
        <div className={cx(s.col, s.col12)}>
          <div className={s.row}>
            <div className={cx(s.col, s.col4, s.big)} />
          </div>
        </div>
      </div>
      <div className={s.item}>
        {range(0, ROWS).map(row => (
          <div className={cx(s.col, s.col10)}
            key={row}>
            <div className={s.row}>
              {range(0, COLUMNS).map(column => (
                <React.Fragment key={column}>
                  <div className={cx(s.col, s.picture, s.big, s.col3)} />
                  <div className={cx(s.col, s.col1, s.empty, s.big)} />
                </React.Fragment>
              ))}
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

ContentMenu.propTypes = {}

export default ContentMenu
