import { pathOr, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getActive = (name, state) => pathOr('unknown', [name], state)
export const getTheme = state => propOr('default', 'theme', state)
export const isLoadedTheme = createSelector(
  state => state.template,
  state => state.section,
  state => state.theme,
  (template, section, theme) =>
    pathOr(false, [template, section, 'isLoaded'], theme)
)
