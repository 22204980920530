import React, { PureComponent } from 'react'

import cx from 'classnames'
import { oneOfType, bool, func, number, string, oneOf } from 'prop-types'
import { connect } from 'react-redux'

import DimmerWrapper from 'components/DimmerWrapper'
import Icon from 'components/Icon'
import Interactions from 'components/Interactions'
import { download, showPrintedModal } from 'redux/modules/downloadModal'
import { downloadOrder } from 'redux/modules/orders'
import Button from 'UI/Button'
import Text from 'UI/Text'

import s from './OrderDocumentsDropdown.scss'

@connect(null, {
  download,
  showPrintedModal,
  downloadOrder
})
export default class OrderDocumentsDropdown extends PureComponent {
  static propTypes = {
    id: oneOfType([string, number]),
    mod: oneOf(['OrderOne', 'OrderTable']),
    docPrint: bool,
    isShowText: bool,
    certPrint: bool,
    isBillActive: bool,
    download: func,
    downloadOrder: func,
    showPrintedModal: func
  }

  static defaultProps = {
    docPrint: false,
    isBillActive: false,
    isShowText: true,
    certPrint: false
  }

  state = {
    isDropdownOpened: false
  }

  handleClickOutside = () => this.setState({ isDropdownOpened: false })

  handleClickDownload = () =>
    this.setState(prevState => ({
      isDropdownOpened: !prevState.isDropdownOpened
    }))

  handleClickCetrificate = () => {
    const { id } = this.props
    this.props.download(`/v3/invoice/bill/${id}/doc/product/certification/`, { method: 'get' })
    this.setState({ isDropdownOpened: false })
  }

  handleClickViewOrder = () => {
    const { id } = this.props
    this.props.downloadOrder({ billId: id, type: 'main' })
    this.setState({ isDropdownOpened: false })
  }

  handleClickDocPrint = () => {
    const { id } = this.props
    this.props.showPrintedModal({ id })
    this.setState({ isDropdownOpened: false })
  }

  render() {
    const { isDropdownOpened } = this.state
    const { docPrint, certPrint, mod, isShowText, isBillActive } = this.props

    const dropdownItems = [
      {
        title: 'Посмотреть счет',
        onClick: this.handleClickViewOrder,
        disabled: !isBillActive
      },
      {
        title: 'Сертификаты',
        onClick: this.handleClickCetrificate,
        disabled: !certPrint
      },
      {
        title: 'Сопроводительные документы',
        onClick: this.handleClickDocPrint,
        disabled: !docPrint
      }
    ]

    return (
      <div className={s.wrapper}>
        <Button
          type='button'
          icon='download'
          shape={isShowText ? 'default' : 'circle'}
          color='lightBlue'
          onClick={this.handleClickDownload}
        >
          {isShowText && (
            <Text color='blue'>Скачать счет</Text>
          )}
        </Button>
        {isDropdownOpened && (
          <DimmerWrapper>
            <Interactions onClickOutside={isDropdownOpened ? this.handleClickOutside : undefined}>
              <div className={cx(s.download, s[`download_mod_${mod}`])}>
                <div className={s.dropdown}>
                  {dropdownItems.map(item => (
                    <div
                      role='presentation'
                      key={item.title}
                      className={cx(s.dropdownItem, {
                        [s.dropdownItem_disabled]: item.disabled
                      })}
                      onClick={item.disabled ? undefined : item.onClick}
                    >
                      <Icon className={s.dropdownIcon}
                        icon='download' />
                      {item.title}
                    </div>
                  ))}
                </div>
              </div>
            </Interactions>
          </DimmerWrapper>
        )}
      </div>
    )
  }
}
