import R from 'ramda'
import { createAction } from 'redux-act'
import { createSelector } from 'reselect'

import { cookieOpts } from 'redux/modules/productsParams'

const cookieParamsKeys = ['limit', 'sort', 'order']

export const initialState = {
  limit: 48,
  sort: 'POPULARITY',
  order: 'DESC'
}
export const setBasketParams = createAction('productList/SET_BASKET_PARAMS')

export const getBasketParams = createSelector(
  state => state,
  state => R.compose(R.pick(cookieParamsKeys), R.prop('basketParams'))(state)
)

export const handleSetBasketParams = (state, payload, { cookie }) => {
  const basketParams = R.propOr({}, 'basketParams', state)
  const newState = R.mergeDeepRight(
    basketParams,
    R.pick(cookieParamsKeys, payload)
  )
  const cookieName = 'params_basket'
  const cookieParams = R.pick(cookieParamsKeys, newState)

  if (!R.isEmpty(R.pick(cookieParamsKeys, payload))) {
    cookie.save(cookieName, cookieParams, cookieOpts)
  }

  return R.set(R.lensProp('basketParams'), newState, state)
}
