import { pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

const initialState = {
  meta: {}
}

export { metaSelector, metaBreadcrumbsSelector } from './selector'

export const fetchMeta = createAction('meta/FETCH_META')
const fetchMetaSuccess = createAction('meta/FETCH_META_SUCCESS')
const fetchMetaFailure = createAction('meta/FETCH_META_FAILURE')

const requestMeta =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/meta/information/', { params })
        .then(fetchMetaSuccess)
        .catch(fetchMetaFailure)

const handleFetchMeta = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestMeta({ clientApi }), payload)
  )

const handleFetchMetaSuccess = (state, payload) => ({
  ...state,
  meta: pathOr({}, ['data', 'response'], payload)
})
const handleFetchMetaFailure = state => ({
  ...state,
  meta: {}
})

const reducer = createReducer(on => {
  on(fetchMeta, handleFetchMeta)
  on(fetchMetaSuccess, handleFetchMetaSuccess)
  on(fetchMetaFailure, handleFetchMetaFailure)
}, initialState)

export default reducer
