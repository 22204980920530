/* eslint-disable complexity */
import { compose, filter, pathOr, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal, hideModal } from 'redux/modules/modal'
import { fetch as fetchPayments } from 'redux/modules/payments'

export { cabinetShipmentSelector, basketShipmentSelector, dateUpdateSelector } from './selectors'

const initialState = {
  items: [],
  dateUpdate: 0
}

export const fetchShipment = createAction('shipment/REQUEST_SHIPMENT')
export const fetchShipmentSuccess = createAction('shipment/REQUEST_SHIPMENT_SUCCESS')
export const fetchShipmentFailure = createAction('shipment/REQUEST_SHIPMENT_FAILURE')

export const fetchRefresh = createAction('shipment/FETCH_REFRESH')
export const runRefresh = createAction('shipment/RUN_REFRESH')
export const fetchRefreshSuccess = createAction('shipment/FETCH_REFRESH_SUCCESS')
export const fetchRefreshFailure = createAction('shipment/FETCH_REFRESH_FAILURE')

export const fetchDateUpdate = createAction('shipment/FETCH_DATE_UPDATE')
export const fetchDateUpdateSuccess = createAction('shipment/FETCH_DATE_UPDATE_SUCCESS')
export const fetchDateUpdateFailure = createAction('shipment/FETCH_DATE_UPDATE_FAILURE')

const shipmentRequest = ({ clientApi }) =>
  clientApi
    .get('/v3/contractor/finance/afc/', {
      params: {
        contractor_id: clientApi.getContractorId()
      }
    })
    .then(fetchShipmentSuccess)
    .catch(fetchShipmentFailure)

const handleFetchShipment = (state, _, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(shipmentRequest, { clientApi })
  )

const handleFetchShipmentSuccess = (state, payload) =>
  loop(
    {
      ...state,
      items: compose(
        filter(item => !propOr(true, 'success', item)),
        pathOr([], ['data', 'response'])
      )(payload)
    },
    Effects.call(hideModal)
  )
const handleFetchShipmentFailure = state =>
  loop(
    {
      ...state
    },
    Effects.call(showModal, 'refresh_failure')
  )

const requestRefresh =
  ({ clientApi }) =>
    ({ page, type = 'shipment' }) => clientApi
      .post(`/v3/update/${type}/`, {
        params: {
          contractor_id: clientApi.getContractorId()
        }
      })
      .then(data => fetchRefreshSuccess({ ...data, page, type }))
      .catch(fetchRefreshFailure)

const handleRunRefresh = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestRefresh({ clientApi }), payload)
  )

const handleFetchRefresh = (state, payload) => {
  const effects = []
  effects.push(Effects.call(showModal, 'refresh_processing'))
  effects.push(Effects.call(runRefresh, payload))
  return loop(
    {
      ...state,
      isRefreshSuccess: false
    },
    Effects.batch(effects)
  )
}

const handleFetchRefreshSuccess = (state, payload) => {
  const isRefreshSuccess = pathOr(
    false,
    ['data', 'response', 'success'],
    payload
  )
  const effects = []
  if (isRefreshSuccess) {
    effects.push(Effects.call(hideModal))

    const page = propOr('', 'page', payload)
    const type = propOr('', 'type', payload)
    switch (page) {
      case 'schedule':
        effects.push(Effects.call(fetchPayments))
        break
      case 'discounts':
        effects.push(Effects.call(fetchDateUpdate, { type }))
        break
      case 'basket':
        effects.push(Effects.call(fetchDateUpdate, { type }))
        break
      default:
        break
    }
  } else effects.push(Effects.call(showModal, 'refresh_failure'))

  return loop(
    {
      ...state,
      isRefreshSuccess
    },
    Effects.batch(effects)
  )
}

const handleFetchRefreshFailure = state =>
  loop(
    {
      ...state
    },
    Effects.call(showModal, 'refresh_failure')
  )

const requestDateUpdate =
  ({ clientApi }) =>
    ({ type }) => clientApi
      .post(`/v3/dateupdate/${type}/`, {
        params: { contractor_id: clientApi.getContractorId() }
      })
      .then(fetchDateUpdateSuccess)
      .catch(fetchDateUpdateFailure)
    
const handleFetchDateUpdate = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestDateUpdate({ clientApi }), payload)
  )

export const handleFetchDateUpdateSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  dateUpdate: pathOr(0, ['data', 'response', 'DATE_UPDATE'], payload)
})

export const handleFetchDateUpdateFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  dateUpdate: 0
})

const reducer = createReducer(on => {
  on(fetchShipment, handleFetchShipment)
  on(fetchShipmentSuccess, handleFetchShipmentSuccess)
  on(fetchShipmentFailure, handleFetchShipmentFailure)
  on(fetchRefresh, handleFetchRefresh)
  on(runRefresh, handleRunRefresh)
  on(fetchRefreshSuccess, handleFetchRefreshSuccess)
  on(fetchRefreshFailure, handleFetchRefreshFailure)
  on(fetchDateUpdate, handleFetchDateUpdate)
  on(fetchDateUpdateSuccess, handleFetchDateUpdateSuccess)
  on(fetchDateUpdateFailure, handleFetchDateUpdateFailure)
}, initialState)

export default reducer
