import React, { useCallback, useState, useMemo } from 'react'

import { pathOr, length } from 'ramda'
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux'

import config from 'config'
import HeaderMobileComponent from 'containers/HeaderMobile/HeaderMobileComponent'
import { display } from 'decorators/device'
import { showModal } from 'redux/modules/modal'
import { tempUserSelector, userIdSelector } from 'redux/modules/personal'
import { cartsSelector } from 'redux/modules/productList'
import { isLoyaltySelector } from 'redux/modules/settings'
import { getTheme } from 'redux/modules/theme'
import { isAuthSelector } from 'redux/modules/token'

interface IHeaderMobileProps {
  isMobile: boolean
}

const HeaderMobile = ({ isMobile } : IHeaderMobileProps) => {
  const isLoyalty = useSelector(({ settings } : RootStateOrAny) => isLoyaltySelector(settings))
  const isTempUser = useSelector(({ personal } : RootStateOrAny) => tempUserSelector(personal))
  const themeComponent = useSelector(({ theme } : RootStateOrAny) => getTheme(theme))
  const isAuth = useSelector(({ token } : RootStateOrAny) => isAuthSelector(token))
  const userId = useSelector(({ personal } : RootStateOrAny) => userIdSelector(personal))
  const carts = useSelector(({ productList, settings } : RootStateOrAny) => cartsSelector(productList, settings))

  const dispatch = useDispatch()
  const [isHeaderFixed, setHeaderFixed] = useState<boolean>(false)

  const handleBreakpointPassed = useCallback((isPassed?: boolean) => {
    return setHeaderFixed(isPassed || false)
  }, [])

  const handleChangeCarts = useCallback(() => {
    // @ts-ignore
    return dispatch(showModal('cart-change'))
  }, [dispatch])

  const handleGetThemeIcon = useCallback((icon?: string) => {
    return pathOr(
      '',
      ['themes', themeComponent, 'icons', icon],
      config
    )
  }, [themeComponent])

  const isManyCarts = useMemo(() => length(carts) > 1, [carts])

  return (
    <HeaderMobileComponent
      isHeaderFixed={isHeaderFixed}
      isManyCarts={isManyCarts}
      isLoyalty={isLoyalty}
      isTempUser={isTempUser}
      isMobile={isMobile}
      isAuth={isAuth}
      userId={userId}
      onChangeCarts={handleChangeCarts}
      onGetThemeIcon={handleGetThemeIcon}
      onBreakpointPassed={handleBreakpointPassed}
    />
  )
}

export default display(HeaderMobile)
