import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getItemsCount = state => state.itemsCount
export const getItemsSum = state => state.itemsSum
export const favoriteCount = createSelector(
  state => state,
  state => propOr(0, 'itemsCount', state)
)
