import React from 'react'

import { bool, string } from 'prop-types'

import Icon from 'components/Icon'

const IconRadio = ({ checked, className }) => {
  return (
    <Icon icon={checked ? 'radio-checked' : 'radio'}
      className={className} />
  )
}

IconRadio.propTypes = {
  checked: bool,
  className: string
}

export default IconRadio
