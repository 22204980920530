import { prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const connectionSelector = createSelector(
  state => state,
  state => prop('isConnect', state)
)

export const notificationPopup = createSelector(
  state => state,
  state => propOr([], 'notificationPopup', state)
)
