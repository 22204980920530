import React, { useMemo } from 'react'

import { bool, number, array, func } from 'prop-types'
import { propOr, propEq, filter, head, compose } from 'ramda'

import Icon from 'components/Icon'
import Link from 'components/Link'
import Spacing from 'UI/Spacing'
import Title from 'UI/Title'

import s from './MenuCatalogMobile.scss'

const SecondLevelHeader = ({
  selectedItemId,
  catalogItems,
  isBookmark,
  bookmark,
  onHideCatalog
}) => {

  const item = useMemo(() => compose(
    head,
    filter(catalogItem => propEq('ID', selectedItemId, catalogItem))
  )(catalogItems),
  [catalogItems, selectedItemId])

  const linkToCatalogPage = isBookmark
    ? `/bookmark/${bookmark}/catalog/products/${propOr('', 'ID', item)}/${propOr('', 'CODE', item)}`
    : `/catalog/products/${propOr('', 'ID', item)}/${propOr('', 'CODE', item)}`

  return (
    <div className={s.menuCatalog__second_header}>
      <div
        role='presentation'
        className={s.menuCatalog__second_title}
        onClick={onHideCatalog}
      >
        <Spacing
          margin='right'
          size='small'
        >
          <Icon icon='prev' />
        </Spacing>
        <Title
          level={3}
          size={16}
          spacing='none'
        >
          {propOr('', 'NAME', item)}
        </Title>
      </div>
      <Link
        color='blue'
        to={linkToCatalogPage}
        onClick={onHideCatalog}
      >
        Все товары
      </Link>
    </div>
  )
}

SecondLevelHeader.propTypes = {
  selectedItemId: number,
  catalogItems: array,
  isBookmark: bool,
  bookmark: number,
  onHideCatalog: func
}

export default SecondLevelHeader
