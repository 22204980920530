import React from 'react'

import cx from 'classnames'
import { range } from 'ramda'

import s from './Placeholder.scss'

const ROWS = 3

const CartLoading = () => {
  return (
    <div className={s.item}>
      <div className={cx(s.col, s.col12)}>
        <div className={s.row}>
          {range(0, ROWS).map(item => (
            <React.Fragment key={item}>
              <div className={cx(s.col, s.col6, s.big)} />
              <div className={cx(s.col, s.col6, s.empty)} />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

CartLoading.propTypes = {}

export default CartLoading
