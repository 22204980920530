import {
  pick,
  prop,
  pathOr,
  propOr,
  findIndex,
  lensPath,
  set,
  mergeDeepRight,
  append,
  equals
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

export {
  getIsLoaded,
  getIsLoading,
  getInvoice,
  invoiceItemsSelector,
  statusesSelector,
  filtersSelector,
  itemsCountSelector,
  limitSelector,
  changedItemIdSelector,
  startDateSelector,
  endDateSelector
} from './selector'

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAY_IN_MONTH = 31
const MIN_LIMIT = 48
const cookieOpts = {
  path: '/',
  expires: new Date(
    Date.now() +
      DAY_IN_MONTH *
        HOURS_IN_DAY *
        MINUTES_IN_HOUR *
        SECONDS_IN_MINUTE *
        MILLISECONDS_IN_SECOND
  )
}
const cookieParamsKeys = [
  'limit',
  'p',
  'order',
  'sortBy',
  'contractorIndex',
  'userIndex',
  'typeIndex',
  'valueIndex',
  'startDate',
  'endDate'
]
const updateCookies = (cookie, newState) => {
  const cookieParams = pick(cookieParamsKeys, newState)
  cookie.save('params_invoice', cookieParams, cookieOpts)
}

export const fetch = createAction('invoice/GET')
export const fetchSuccess = createAction('invoice/GET_SUCCESS')
export const fetchFailure = createAction('invoice/GET_FAILURE')

export const fetchCount = createAction('invoice/GET_COUNT')
export const fetchCountSuccess = createAction('invoice/GET_COUNT_SUCCESS')
export const fetchCountFailure = createAction('invoice/GET_COUNT_FAILURE')

export const setParams = createAction('invoice/SET_PARAMS')
export const resetParams = createAction('invoice/RESET_PARAMS')
export const updateItem = createAction('invoice/UPDATE_ITEM')

const initialState = {
  limit: MIN_LIMIT,
  entities: [],
  statuses: [],
  filters: [],
  successChangedId: [],
  startDate: '',
  endDate: '',
  isLoaded: false,
  isLoading: false,
  tabItemsCount: 0,
  headerItems: {
    items: [],
    isLoaded: false,
    isLoading: false
  },
  certificate: {
    id: null
  }
}

export const request =
  ({ clientApi }) =>
    ({
      mode = 'main',
      onSuccess = fetchSuccess,
      onFailure = fetchFailure,
      isHeader = false,
      ...params
    } = {}) =>
      clientApi
        .post(`/v3/invoice/bill/${mode}/list/`, {
          params: {
            contractor_id: clientApi.getContractorId(),
            ...params
          }
        })
        .then(data =>
          onSuccess({ data, listType: propOr('', 'list_type', params), isHeader })
        )
        .catch(onFailure)

const handleFetch = (state, payload, { clientApi, cookie }) => {
  const paramsFromCookie = cookie.load('params_invoice')
  const limit = propOr(MIN_LIMIT, 'limit', paramsFromCookie)
  const params =
    prop('mode', payload) === 'loyalty'
      ? payload
      : {
        sort: 'DATE',
        order: 'DESC',
        limit,
        ...payload
      }
  const isHeader = propOr(false, 'isHeader', payload)
  const newState = isHeader
    ? {
      headerItems: {
        ...state.headerItems,
        isLoading: true,
        isLoaded: false
      }
    }
    : {
      limit,
      isLoading: true,
      isLoaded: false
    }
  return loop(
    {
      ...state,
      ...newState
    },
    Effects.promise(request({ clientApi }), params)
  )
}

const handleFetchSuccess = (state, { data, listType, isHeader }) => {
  const response = pathOr({}, ['data', 'response'], data)
  const count = Number(pathOr(0, ['NAV', 'CNT'], response))

  const filters = !equals(listType, 'items')
    ? propOr([], 'AGGREGATIONS', response)
    : propOr([], 'filters', state)

  const items = propOr([], 'ITEMS', response)
  const statuses = propOr([], 'STATE', response)
  const entities = propOr([], 'ENTITIES', response)
  if (isHeader) {
    return {
      ...state,
      headerItems: {
        ...state.headerItems,
        isLoading: false,
        isLoaded: true,
        items,
        statuses,
        entities
      }
    }
  }
  return {
    ...state,
    successChangedId: [],
    isLoading: false,
    isLoaded: true,
    allItemsCount: count,
    items,
    filters,
    statuses,
    entities
  }
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errorMessage: getErrorMessage(payload)
})

const handleFetchCount = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(request({ clientApi }), {
      ...payload,
      onSuccess: fetchCountSuccess,
      onFailure: fetchCountFailure,
      list_type: 'aggregations',
      'filter[STATE]': 'wait_confirm'
    })
  )

const handleFetchCountSuccess = (state, { data }) => {
  const count = pathOr(0, ['data', 'response', 'NAV', 'CNT'], data)

  return {
    ...state,
    tabItemsCount: parseInt(count, 10)
  }
}

const handleFetchCountFailure = (state, payload) => ({
  ...state,
  errorMessage: getErrorMessage(payload)
})

const handleUpdateItem = (
  state,
  { orderId, statusCode, itemActions, statusesList }
) => {
  const itemsFromState = propOr([], 'items', state)
  const itemIndex = findIndex(item => propOr('', 'ID', item) === orderId)(
    itemsFromState
  )
  const newItemStatus = set(
    lensPath([itemIndex, 'STATUS']),
    statusCode,
    itemsFromState
  )
  const newItems = set(
    lensPath([itemIndex, 'ACTIONS']),
    itemActions,
    newItemStatus
  )
  const entitiesFromState = propOr({}, 'entities', state)
  const changedItems = propOr([], 'successChangedId', state)
  return {
    ...state,
    items: newItems,
    successChangedId: append(orderId, changedItems),
    entities: mergeDeepRight(entitiesFromState, { STATUS: statusesList })
  }
}

const handleSetParams = (state, { fromCookie, ...payload }, { cookie }) => {
  const newState = { ...state, ...payload }
  if (!fromCookie) {
    updateCookies(cookie, newState)
  }

  return newState
}

const handleResetParams = (state, _, { cookie }) => {
  const cookieParams = pick(cookieParamsKeys, initialState)
  cookie.save('params_invoice', cookieParams, cookieOpts)

  return {
    ...state,
    ...cookieParams
  }
}

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchCount, handleFetchCount)
  on(fetchCountSuccess, handleFetchCountSuccess)
  on(fetchCountFailure, handleFetchCountFailure)
  on(setParams, handleSetParams)
  on(resetParams, handleResetParams)
  on(updateItem, handleUpdateItem)
}, initialState)

export default reducer
