import React, { useEffect } from 'react'

import cx from 'classnames'
import { string, func, number, array, object, oneOf, bool } from 'prop-types'
import { pathOr, propOr, isEmpty } from 'ramda'

import Product from 'components/Product/Product'
import { display } from 'decorators/device'
import { setAnalyticsProps } from 'helpers/metaTags'
import Title from 'UI/Title'

import styles from './AnalogsPopup.scss'

const COUNT_ANALOGS = 10

const AnalogsPopup = ({
  catalog,
  page,
  onChangeByAnalog,
  fetch,
  bookmark,
  analogs,
  isBookmark,
  isDesktop,
  partialAnalogs,
  item
}) => {

  useEffect(() => {
    fetch({
      id: item.ID,
      type: 'similar'
    })
  }, [fetch, item])

  const classes = cx(styles.popup)
  const analogsList = pathOr(
    analogs,
    ['similar', 'PRODUCTS', 'ABSOLUTE_ANALOG'],
    item
  )

  const itemPartialAnalogs = pathOr(
    partialAnalogs,
    ['similar', 'PRODUCTS', 'ANALOG'],
    item
  )

  const analogsCount = analogsList.length

  const partialAnalogsSliceLength =
    itemPartialAnalogs.length >= COUNT_ANALOGS - analogsCount
      ? COUNT_ANALOGS - analogsCount
      : itemPartialAnalogs.length

  const partialAnalogsList =
    partialAnalogsSliceLength > 0
      ? itemPartialAnalogs.slice(0, partialAnalogsSliceLength)
      : []

  const isAnalog = page === 'basket'

  const renderAnalogsBlock = ({ items, title, analytics }) => !!items.length && (
    <div
      className={styles.partialAnalogsList}
      {...analytics}
      itemProp='offers'
    >
      <Title
        level={2}
        size={13}
      >
        {title}
      </Title>
      <div className={styles.analogsList}>
        {items.map(analog => (
          <Product
            isAnalog={isAnalog}
            id={analog.ID}
            key={analog.ID}
            type={isDesktop ? 'list' : 'card'}
            selectable={false}
            isBookmark={isBookmark}
            bookmark={bookmark}
            catalog={catalog}
            groupType={catalog}
            originalProduct={isAnalog ? item.ID : ''}
            onChangeByAnalog={onChangeByAnalog}
            isQuickView={false}
          />
        ))}
      </div>
    </div>
  )

  const renderEmptyMessage = () => isEmpty(analogsList) && isEmpty(itemPartialAnalogs) && (
    <Title
      level={2}
      size={18}
      spacing='big'
    >
      Товар не имеет аналогов
    </Title>
  )

  return (
    <div className={classes}
      itemScope
      itemType='http://schema.org/Product'>
      <div
        className={styles.popupContainer}
        itemProp='offers'
        itemScope
        itemType='http://schema.org/Offer'
      >
        <div className={styles.analogsWrapper}>
          <div
            className={styles.productList}
            itemScope
            itemType='http://schema.org/AggregateOffer'
          >
            {renderAnalogsBlock({
              items: analogsList,
              title: 'Полные аналоги',
              analytics: setAnalyticsProps({
                type: 'action',
                group: 'productCard',
                action: 'replace_absolute_analog',
                product: propOr('', 'CODE_1C', item)
              })
            })}
            {renderAnalogsBlock({
              items: partialAnalogsList,
              title: 'Частичные аналоги',
              analytics: setAnalyticsProps({
                type: 'action',
                group: 'productCard',
                action: 'replace_analog',
                product: propOr('', 'CODE_1C', item)
              })
            })}
            {renderEmptyMessage()}
          </div>
        </div>
      </div>
    </div>
  )
}

AnalogsPopup.propTypes = {
  catalog: string,
  page: oneOf(['basket', 'catalog']),
  onChangeByAnalog: func,
  fetch: func,
  bookmark: number,
  analogs: array,
  isBookmark: bool,
  isDesktop: bool,
  partialAnalogs: array,
  item: object
}

AnalogsPopup.defaultProps = {
  analogs: [],
  partialAnalogs: [],
  onChangeByAnalog: () => {},
  item: {},
  isBookmark: false,
  catalog: 'catalog',
  bookmark: 0
}

export default display(AnalogsPopup)
