/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, number, oneOfType, func, bool, oneOf } from 'prop-types'
import LazyLoad from 'react-lazyload'
import ProgressiveImage from 'react-progressive-image'

import config from 'config'
import getImagePath from 'helpers/getImagePath'

import styles from './Image.scss'

export default class Image extends PureComponent {
  static propTypes = {
    imgClassName: string,
    width: oneOfType([number, string]),
    height: oneOfType([number, string]),
    src: string,
    alt: string,
    title: string,
    backgroundSize: oneOf(['', 'contain', 'cover', 'fullWidth']),
    imageRef: func,
    onClick: func,
    isLazy: bool
  }

  static defaultProps = {
    onClick: () => {},
    imageRef: () => {},
    isLazy: true
  }

  state = {
    isError: false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.src !== this.props.src && this.state.isError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isError: false })
    }
  }

  handleError = () => this.setState({ isError: true })

  render() {
    const {
      src,
      imgClassName,
      width,
      height,
      backgroundSize,
      onClick,
      alt,
      title,
      isLazy,
      imageRef,
      ...rest
    } = this.props
    const { isError } = this.state
    const srcImg = getImagePath(src)

    if (isError) {
      return (
        <div
          {...rest}
          className={cx(styles.imgNoImage, {
            [styles.imgFullWidth]: backgroundSize === 'fullWidth',
            [styles.imgContain]: backgroundSize === 'contain',
            [styles.imgCover]: backgroundSize === 'cover',
            [imgClassName]: true
          })}
          alt={alt}
          title={title}
          style={{ width, height }}
        />
      )
    }

    if (!config.lazy.isActive || !isLazy) {
      return (
        <img
          {...rest}
          role='presentation'
          src={srcImg}
          alt={alt}
          title={title}
          onClick={onClick}
          style={{
            width,
            height
          }}
          className={cx({
            [styles.imgFullWidth]: backgroundSize === 'fullWidth',
            [styles.imgContain]: backgroundSize === 'contain',
            [styles.imgCover]: backgroundSize === 'cover',
            [imgClassName]: true
          })}
          itemProp='image'
        />
      )
    }

    return (
      <LazyLoad
        style={{
          width,
          height
        }}
        offset={500}
        once
      >
        <ProgressiveImage src={srcImg}
          onError={this.handleError}>
          {(srcImage, loading) =>
            loading ? (
              <div
                style={{ backgroundColor: '#f7f7f7', width, height }}
                className={imgClassName}
              />
            ) : (
              <img
                {...rest}
                ref={imageRef}
                role='presentation'
                src={srcImage}
                alt={alt}
                onClick={onClick}
                title={title}
                className={cx({
                  [styles.imgContain]: backgroundSize === 'contain',
                  [styles.imgCover]: backgroundSize === 'cover',
                  [imgClassName]: true
                })}
                itemProp='image'
              />
            )
          }
        </ProgressiveImage>
      </LazyLoad>
    )
  }
}
