import R from 'ramda'
import { createAction, createReducer } from 'redux-act'

export const setCount = createAction('basket/SET_COUNT')
export const setBasketCount = createAction('basket/SET_BASKET_COUNT')
export const setNav = createAction('basket/SET_NAV')
export const setSendStatus = createAction('basket/SET_SEND_STATUS')
export const setIsRefetch = createAction('basket/SET_IS_REFETCH')

export {
  getIsLoaded,
  getIsLoading,
  getBasketByType,
  getSendActive,
  basketSelector,
  isRefetchSelector,
  bookmarkAccessSelector,
  choiseDeliveryDateSelector
} from './selector'

const initialState = {
  sendActive: false,
  isRefetch: false,
  itemsCount: 0,
  itemsSum: 0,
  itemsVolume: 0,
  itemsWeight: 0,
  loyaltyPoints: 0,
  removedItems: {},
  types: {}
}

const getBasketProp = (prop, payload) => {
  const pathPayload = R.flatten([
    'data',
    'response',
    'NAV',
    'BASKETS',
    'MAIN',
    'VALUES',
    0,
    prop
  ])
  return R.pathOr(0, pathPayload, payload)
}

const handleSetCount = (state, payload) => {
  const itemsCount = parseInt(
    R.path(['data', 'response', 'CNT'], payload) ||
      getBasketProp('CNT', payload),
    10
  )
  const itemsSum =
    R.path(['data', 'response', 'SUM'], payload) ||
    getBasketProp('SUM', payload)
  return {
    ...state,
    isLoaded: true,
    itemsCount,
    itemsSum,
    itemsVolume: getBasketProp('VOL', payload),
    itemsWeight: getBasketProp('WG', payload),
    loyaltyPoints: getBasketProp('LOYALTY_POINTS', payload),
    additionally: getBasketProp(['ADDITIONALLY', 'MULTIPLICITY'], payload)
  }
}

const handleSetBasketCount = (state, payload) => ({
  ...state,
  isLoaded: true,
  itemsCount: R.propOr(0, 'CNT', payload),
  itemsSum: R.propOr(0, 'SUM', payload),
  itemsVolume: R.propOr(0, 'VOL', payload),
  itemsWeight: R.propOr(0, 'WG', payload),
  loyaltyPoints: R.propOr(0, 'LOYALTY_POINTS', payload),
  additionally: R.pathOr(0, ['ADDITIONALLY', 'MULTIPLICITY'], payload)
})

const handleSetNav = (state, payload) => ({
  ...state,
  types: R.mergeRight(state.types, R.pathOr({}, ['nav'], payload))
})

const handleSendStatus = (state, payload) => ({
  ...state,
  sendActive: payload
})

const handleSetIsRefetch = (state, payload) => ({
  ...state,
  isRefetch: !!payload
})

const reducer = createReducer(on => {
  on(setCount, handleSetCount)
  on(setBasketCount, handleSetBasketCount)
  on(setNav, handleSetNav)
  on(setSendStatus, handleSendStatus)
  on(setIsRefetch, handleSetIsRefetch)
}, initialState)

export default reducer
