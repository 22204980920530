import { equals } from 'ramda'
import { createAction, createReducer } from 'redux-act'

const initialState = {
  isDesktop: true,
  isMobile: false,
  isTablet: false
}

export {
  isDesktopSelector,
  isTabletSelector,
  isMobileSelector
} from './selector'

const desktopQuery = '(min-width: 1200px)'
const tabletQuery = '(max-width: 1199px) and (min-width: 768px)'
const mobileQuery = '(max-width: 768px)'
export const mediaQuery = device => typeof window !== 'undefined' ? window.matchMedia(device) : undefined
export const desktopMediaQuery = mediaQuery(desktopQuery)
export const tabletMediaQuery = mediaQuery(tabletQuery)
export const mobileMediaQuery = mediaQuery(mobileQuery)

export const setDevice = createAction('device/SET_DEVICE')

const handleSetDevice = (state, payload) => ({
  isDesktop: equals(payload, desktopQuery) ,
  isTablet: equals(payload, tabletQuery),
  isMobile: equals(payload, mobileQuery)
})

const reducer = createReducer(on => {
  on(setDevice, handleSetDevice)
}, initialState)

export default reducer
