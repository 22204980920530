import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const CabinetCompanies = loadable(() =>
  import(
    /* webpackChunkName: "CabinetCompanies" */ 'pages/CabinetCompanies/CabinetCompanies'
  )
)

export default asyncConnect([
  {
    key: 'pageCabinetCompanies',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({
        view: 'cabinetCompany'
      })

      return updateData.update()
    }
  }
])(CabinetCompanies)
