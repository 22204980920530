import React from 'react'

import { Redirect } from 'react-router'

const CatalogRc = () => {
  return <Redirect to='/catalog' />
}

export default CatalogRc

