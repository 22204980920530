import React, { memo, useCallback, useEffect } from 'react'

import cx from 'classnames'
import { bool, string } from 'prop-types'
import { prop, equals, map, path } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import Icon from 'components/Icon'
import Portal from 'components/Portal'
import Wrapper from 'components/Wrapper'
import DisableBodyScroll from 'containers/DisableBodyScroll/DisableBodyScroll'
import { isOffersVisibleSelector } from 'redux/modules/personal'
import { tabActiveSelector, setTabActive, isLoyaltySelector, tabBarSelector } from 'redux/modules/settings'
import { isAuthSelector } from 'redux/modules/token'

import { tabContent } from './constants'
import s from './TabBar.scss'

const TabItem = memo(({ isActive, icon, title, id }) => {
  const dispatch = useDispatch()
  const onSetTab = useCallback(tabId => () => {
    dispatch(setTabActive(tabId))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const Component = path([id], tabContent)
  return (
    <li className={s.tabBar__element}>
      {isActive ? (
        <DisableBodyScroll>
          <Portal>
            <div className={s.content}>
              <Component />
            </div>
          </Portal>
        </DisableBodyScroll>
      ) : null}
      <button
        type='button'
        className={cx(s.tabBar__item, {
          [s.tabBar__item_active]: isActive,
          [s.tabBar__item_catalogLoyalty]: id === 'catalogLoyalty'
        })}
        onClick={onSetTab(id)}
      >
        <Icon
          icon={isActive ? 'close' : icon}
          className={cx(s.tabBar__itemIcon, {
            [s.tabBar__itemIcon_active]: isActive,
            [s.tabBar__itemIcon_catalogRc]: id === 'catalogRc',
            [s.tabBar__itemIcon_catalogLoyalty]: id === 'catalogLoyalty'
          })}
        />
        {title}
      </button>
    </li>
  )
})

TabItem.propTypes = {
  icon: string,
  id: string,
  isActive: bool,
  title: string
}

const TabBar = () => {
  const isAuth = useSelector(({ token }) => isAuthSelector(token))
  const isLoyalty = useSelector(({ settings }) => isLoyaltySelector(settings))
  const isOffers = useSelector(({ personal }) => isOffersVisibleSelector(personal))
  const tabBarList = useSelector(({ settings }) => tabBarSelector(settings, {
    auth: isAuth,
    unauth: !isAuth,
    loyalty: isLoyalty,
    main: !isLoyalty,
    offers: isOffers
  }))
  const tabActive = useSelector(({ settings }) => tabActiveSelector(settings))
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setTabActive(''))
    return undefined
  }, [dispatch, location])

  return (
    <>
      <nav className={s.container}>
        <Wrapper>
          <ul className={s.tabBar}>
            {map(item => {
              const title = prop('title', item)
              const id = prop('id', item)
              const icon = prop('icon', item)
              return (
                <TabItem
                  icon={icon}
                  id={id}
                  key={id}
                  title={title}
                  isActive={equals(id, tabActive)}
                  isLoyalty={isLoyalty}
                />
              )
            })(tabBarList)}
          </ul>
        </Wrapper>
      </nav>
      <div className={s.space} />
    </>
  )
}

export default memo(TabBar)
