import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Compare = loadable(() =>
  import(/* webpackChunkName: "Compare" */ 'pages/Compare/Compare')
)

export default asyncConnect([
  {
    key: 'pageCompare',
    promise: ({ helpers: { updateData }, ...props }) => {
      const id = pathOr(0, ['match', 'params', 'id'], props)
      updateData.set({ id, view: 'compare' })

      return updateData.update()
    }
  }
])(Compare)
