import React, { useCallback } from 'react'

import { func, number, oneOfType, string } from 'prop-types'
import { pathOr, startsWith, equals } from 'ramda'
import { connect } from 'react-redux'
import { compose } from 'redux'

import AnalogsPopup from 'components/AnalogsPopup'
import Portal from 'components/Portal'
import Modal from 'containers/Modal'
import { catalogSelector } from 'decorators/basket'
import preventRenderWhileLoading from 'decorators/preventRenderWhileLoading'
import {
  fetch as fetchStore,
  getItemAnalogsById,
  getItemAbsoluteAnalogsById
} from 'redux/modules/productList'
import { setAnalogsModal, analogsModalSelector, itemSelector } from 'redux/modules/products'
import { isBookmarkSelector, bookmarkSelector } from 'redux/modules/settings'

const Analogs = ({ onSetAnalogsModal, id, ...rest }) => {

  const handleHideModal = useCallback(() => onSetAnalogsModal(''), [onSetAnalogsModal])

  if (!id) return null

  return (
    <Portal>
      <Modal
        id='analogsModal'
        title='Замена отсутствующих товаров'
        size='big'
        onClose={handleHideModal}
      >
        <AnalogsPopup {...rest} />
      </Modal>
    </Portal>
  )
}

Analogs.propTypes = {
  id: oneOfType([number, string]),
  onSetAnalogsModal: func
}

export default compose(
  preventRenderWhileLoading(),
  connect(
    ({ productList, settings, products, router }) => {
      const id = analogsModalSelector(products)
      const location = pathOr({}, ['location', 'pathname'], router)
      const page = startsWith('/basket', location) ? 'basket' : 'catalog'
      return {
        id,
        location,
        page,
        catalog: equals(page, 'catalog') ? 'catalog' : catalogSelector({ settings, productList, type: page }),
        item: itemSelector(productList, { id }),
        analogs: getItemAbsoluteAnalogsById(id, productList),
        partialAnalogs: getItemAnalogsById(id, productList),
        bookmark: bookmarkSelector(settings),
        isBookmark: isBookmarkSelector(settings)
      }
    },
    {
      fetch: fetchStore,
      onSetAnalogsModal: setAnalogsModal
    }
  )
)(Analogs)
