/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { object, func, string, bool } from 'prop-types'
import {
  propOr,
  pathOr,
  min,
  equals,
  indexOf,
  inc,
  dec,
  length,
  prop,
  compose
} from 'ramda'

import Icon from 'components/Icon'
import Image from 'components/Image'
import ImageLens from 'components/ImageLens'
import ImageRotate from 'components/ImageRotate'
import Portal from 'components/Portal'
import ProductPhoto from 'components/ProductPage/ProductPhoto'
import { display } from 'decorators/device'

import styles from './ProductGalleryModal.scss'

const HEIGHT_MARGIN = 190
const VIDEO_PICTURE = '/images/video/video.png'
const SPRITE_PICTURE = '/images/sprite/sprite.png'
const MIN_IMAGE_SIZE = 800
const zoomStyle = [
  'z-index: 370',
  'background-color: white',
  'margin-left: 0'
]
const zoomLensStyle = ['background-color: transparent']

@display
export default class ProductGalleryModal extends PureComponent {
  static propTypes = {
    mainPicture: string,
    videoItem: string,
    galleryItems: object,
    gallerySettings: object,
    spriteData: object,
    handleFetchSpritePhoto: func,
    onChange: func,
    onClose: func,
    isDesktop: bool
  }

  static defaultProps = {
    mainPicture: '',
    galleryItems: {},
    spriteData: {}
  }

  constructor(props) {
    super(props)
    this.modalContainer = React.createRef()
  }

  state = {
    isZoomUp: false,
    outerHeight: 0,
    outerWidth: 0,
    spriteIcon: 'rotate',
    realImageWidth: MIN_IMAGE_SIZE,
    realImageHeight: MIN_IMAGE_SIZE
  }

  componentDidMount() {
    this.setRealImageSize()
    this.handleSetModalSize()
    if (this.props.onChange) this.onChange({ path: this.props.mainPicture })
    window.addEventListener('resize', this.handleSetModalSize)
  }

  componentDidUpdate() {
    if (this.galleryImage) {
      this.setRealImageSize()
    }
  }

  componentWillUnmount() {
    if (typeof window === 'undefined') {
      return
    }
    window.removeEventListener('resize', this.handleSetModalSize)
  }

  handleSetModalSize = () => {
    const element = this.modalContainer.current
    const outerHeight = propOr(0, 'offsetHeight', element)
    const outerWidth = propOr(0, 'offsetWidth', element)
    this.setState({ outerHeight, outerWidth })
  }

  handleShowZoom = isShow => () => {
    this.setState({
      isZoomUp: isShow
    })
  }

  handleSlideChange = action => () => {
    const { mainPicture, galleryItems } = this.props
    const photos = propOr([], 'photos', galleryItems)
    const activeIndex = indexOf(mainPicture, photos)
    const lastIndex = compose(dec, length)(photos)
    let index = 0
    if (equals(action, 'next')) {
      index = equals(activeIndex, lastIndex) ? 0 : inc(activeIndex)
    } else {
      index = equals(activeIndex, 0) ? lastIndex : dec(activeIndex)
    }
    this.onChange({ path: prop(index, photos) })
  }

  handleClose = () => {
    const { onClose, mainPicture, galleryItems } = this.props
    const isVideo = equals(mainPicture, VIDEO_PICTURE)
    const isSprite = equals(mainPicture, SPRITE_PICTURE)
    if (isVideo || isSprite) {
      const firstPicture = pathOr('', ['photos', 0], galleryItems)
      this.onChange({ path: firstPicture })
    }
    onClose()
  }

  handleChangeIconName = name => this.setState({ spriteIcon: name })

  setRealImageSize = () => {
    this.setState({
      realImageWidth: propOr(
        MIN_IMAGE_SIZE,
        'naturalWidth ',
        this.galleryImage
      ),
      realImageHeight: propOr(
        MIN_IMAGE_SIZE,
        'naturalHeight ',
        this.galleryImage
      )
    })
  }

  setImageRef = el => {
    this.galleryImage = el
  }

  onChange = ({ path }) => this.props.onChange({ path })

  renderArrows = type => (
    <div
      role='presentation'
      className={cx(styles.buttonArrow, {
        [styles[`buttonArrow_${type}`]]: type
      })}
      onClick={this.handleSlideChange(type)}
    >
      <Icon
        className={styles.iconArrow}
        icon={type}
      />
    </div>
  )

  renderMainImage = () => {
    const { mainPicture, spriteData, galleryItems, isDesktop } = this.props
    const {
      isZoomUp,
      outerHeight,
      outerWidth,
      realImageHeight,
      realImageWidth
    } = this.state

    // определяем максимальные размеры картинки в соответствии с размерами экрана
    const maxHeight = outerHeight - HEIGHT_MARGIN
    const size = min(maxHeight, outerWidth)
    zoomStyle.push(`width: ${size}px`)

    // устанавливаем размеры картинки, не выше оригинального размера
    const isResizePhoto = realImageHeight > size || realImageWidth > size
    const imageHeight = realImageHeight > size ? size : realImageHeight
    const imageWidth = realImageWidth > size ? size : realImageWidth

    // воспроизводим видео или спрайт, если они активны
    const isShowVideo = equals(mainPicture, VIDEO_PICTURE)
    const isShowSprite = equals(mainPicture, SPRITE_PICTURE)

    // стрелки только в фото и если фотографий больше одной
    const photos = propOr([], 'photos', galleryItems)
    const isShowArrows = !isShowSprite && !isShowVideo && length(photos) > 1

    return (
      <div style={{ height: `${size}px` }}
        className={styles.mediaWrapper}>
        {isShowArrows && isDesktop && this.renderArrows('prev')}
        {!isShowSprite && !isShowVideo && (
          <div
            style={{ width: `${imageWidth}px` }}
            role='presentation'
            className={cx(styles.imageWrapper, {
              [styles.imageWrapperZoomOut]: isZoomUp,
              [styles.imageWrapperWithoutZoom]: !isResizePhoto
            })}
            onClick={isResizePhoto ? this.handleShowZoom(!isZoomUp) : undefined}
          >
            {isResizePhoto && isZoomUp ? (
              <ImageLens
                width={size}
                height={size}
                zoomWidth={size}
                zoomPosition='original'
                zoomStyle={zoomStyle}
                zoomLensStyle={zoomLensStyle}
                src={mainPicture}
              />
            ) : (
              <div style={{ width: `${imageWidth}px` }}>
                <Image
                  imageRef={this.setImageRef}
                  backgroundSize='contain'
                  width={imageWidth}
                  height={imageHeight}
                  src={mainPicture}
                />
              </div>
            )}
          </div>
        )}
        {isShowArrows && isDesktop && this.renderArrows('next')}
        {isShowSprite && (
          <ImageRotate
            spriteData={spriteData}
            photos={photos}
            maxImageSize={maxHeight}
            maxWidth={outerWidth}
            previewSize={size}
            spriteIcon={this.state.spriteIcon}
            onChangeSpriteIcon={this.handleChangeIconName}
          />
        )}
        {isShowVideo && this.renderVideo({ outerWidth, maxHeight })}
      </div>
    )
  }

  renderVideo = ({ outerWidth, maxHeight }) => {
    const { videoItem } = this.props
    return (
      <div
        key='galleryVideo'
        className={styles.videoWrapper}
        style={{
          width: `${outerWidth}px`,
          height: `${maxHeight}px`
        }}
      >
        <div
          className={styles.video}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: videoItem }}
        />
      </div>
    )
  }

  render() {
    const {
      galleryItems,
      mainPicture,
      gallerySettings,
      onChange,
      handleFetchSpritePhoto
    } = this.props

    return (
      <Portal>
        <div className={styles.portal}>
          <div className={styles.modalContainer}
            ref={this.modalContainer}>
            {this.renderMainImage()}
            <ProductPhoto
              selectedPath={mainPicture}
              items={galleryItems}
              settings={gallerySettings}
              onChange={onChange}
              handleFetchSpritePhoto={handleFetchSpritePhoto}
              onChangeSpriteIcon={this.handleChangeIconName}
              spriteIcon={this.state.spriteIcon}
              isGalleryModal
            />
            <div
              role='presentation'
              className={styles.buttonClose}
              onClick={this.handleClose}
            >
              <Icon
                className={styles.iconClose}
                icon='close'
              />
            </div>
          </div>
        </div>
      </Portal>
    )
  }
}
