import React, { PureComponent } from 'react'

import { array } from 'prop-types'
import { prop, compose, map, sortWith, descend } from 'ramda'

import Link from 'components/Link'
import { setAnalyticsProps } from 'helpers/metaTags'
import Spacing from 'UI/Spacing'

import styles from './ContentMenu.scss'

const FIRST_ITEM = '/loyalty/how_it_works'

class ContentMenu extends PureComponent {
  static propTypes = {
    items: array
  }

  static defaultProps = {
    items: []
  }

  renderItem = item => (
    <Spacing margin='right'
      size='big'>
      <Link
        key={prop('id', item)}
        to={prop('to', item)}
        color={prop('isImportant', item) ? 'pink' : 'default'}
        {...setAnalyticsProps({
          type: 'action',
          group: 'navigation',
          pos: 'top'
        })}
      >
        {prop('content', item)}
      </Link>
    </Spacing>
  )

  render() {
    const { items } = this.props
    const sortedItems = compose(
      sortWith([descend(prop('isImportant'))]),
      map(item => ({
        ...item,
        isImportant: item.to === FIRST_ITEM
      }))
    )(items)

    return <div className={styles.menu}>{sortedItems.map(this.renderItem)}</div>
  }
}

export default ContentMenu
