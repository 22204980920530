import React, { useEffect, useRef } from 'react'

import cx from 'classnames'
import { array, bool, func, number, string } from 'prop-types'
import { findLastIndex, prop, propEq } from 'ramda'
import { connect } from 'react-redux'
import SliderSlick from 'react-slick'

import Icon from 'components/Icon'
import { cartsSelector, setCartActive } from 'redux/modules/productList'
import { sliderCarts } from 'utils/sliderConfig'

import CartAdd from './CartAdd'
import s from './CartChange.scss'
import CartItem from './CartItem'

const SLIDES = 4

const CartChange = ({
  onSetCartActive = () => {},
  cart = 0,
  carts = [],
  catalog = 'main',
  isRoute = false,
  isWide = false
}) => {
  const refSlider = useRef(null)

  useEffect(() => {
    const indexSlider = findLastIndex(propEq('ID', cart), carts)
    onSetCartActive(Number(cart))
    if (refSlider && refSlider.current) {
      refSlider.current.slickGoTo(indexSlider)
    }
  }, [cart, carts, onSetCartActive])

  if (catalog !== 'main') {
    return null
  }

  return (
    <div className={s.cartChange}>
      <div className={s.wrapper}>
        <div className={cx(s.slider, {
          [s.slider__fadeEffect]: carts.length > 1
        })}>
          <SliderSlick
            ref={refSlider}
            dots={false}
            infinite={false}
            draggable={false}
            speed={500}
            slidesToShow={SLIDES}
            slidesToScroll={SLIDES}
            nextArrow={<Icon icon='next' />}
            prevArrow={<Icon icon='prev' />}
            className={s.slider}
            arrows
            responsive={sliderCarts}
          >
            {carts.map((item, index) => (
              <CartItem
                item={item}
                index={index}
                key={prop('ID', item)}
                catalogInit={catalog}
                isRoute={isRoute}
                isWide={isWide}
              />
            ))}
            <CartAdd isAdd />
          </SliderSlick>
        </div>
      </div>
    </div>
  )
}

CartChange.propTypes = {
  onSetCartActive: func,
  cart: number,
  isRoute: bool,
  isWide: bool,
  catalog: string,
  carts: array
}

CartChange.defaultProps = {
  carts: [],
  isRoute: false,
  isWide: false
}

export default connect(
  ({ productList, settings }) => {
    return {
      carts: cartsSelector(productList, settings)
    }
  },
  {
    onSetCartActive: setCartActive
  }
)(CartChange)
