/* eslint-disable complexity */
/* eslint-disable react-hooks/exhaustive-deps, react/jsx-no-bind, jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { push } from 'connected-react-router'
import { bool, func, number, object, string } from 'prop-types'
import { pathOr, prop, propOr } from 'ramda'
import { connect } from 'react-redux'
import { useClickAway } from 'react-use'
import { compose } from 'redux'

import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import { display } from 'decorators/device'
import { delayHideModal } from 'redux/modules/modal'
import { cartSelector, setCartActive } from 'redux/modules/productList'
import { catalogTypeSelector } from 'redux/modules/settings'
import Button from 'UI/Button'
import IconRadio from 'UI/Icons/IconRadio'
import { ESC_CODE } from 'utils/keycodes'
import noun from 'utils/noun'

import CartAdd from './CartAdd'
import s from './CartChange.scss'
import CartDelete from './CartDelete'

const DELAY_HIDE_MODAL = 300

const CartItem = ({
  item,
  index = 0,
  cart,
  isChangedContractor = false,
  isRoute = false,
  isWide = false,
  isMove = false,
  isDesktop,
  className,
  catalog,
  catalogInit,
  basketType = 'main',
  onSetCartActive,
  onHideNodal,
  onMove,
  onPush
}) => {
  const ref = useRef(null)

  const [isAdd, setIsAdd] = useState(false)

  const [isDelete, setIsDelete] = useState(false)

  const [isHover, setIsHover] = useState(false)

  const id = prop('ID', item)

  const isActive =
    basketType === catalogInit && !isChangedContractor ? id === cart : false

  const onClickOutside = () => {
    setIsAdd(false)
    setIsDelete(false)
    setIsHover(false)
  }

  const handleUserKeyPress = event => {
    const { keyCode } = event

    if (keyCode === ESC_CODE) {
      onClickOutside()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress)

    return () => {
      window.removeEventListener('keydown', handleUserKeyPress)
    }
  }, [handleUserKeyPress])

  useClickAway(ref, onClickOutside)

  const onSetActive = () => {
    if (isActive) return
    if (isMove) {
      onMove({ id, toCatalog: 'main' })
    } else {
      onSetCartActive(Number(id))
      onHideNodal(DELAY_HIDE_MODAL)
      if (isRoute) {
        onPush(`/basket/${catalog}/${id}`)
      }
    }
  }

  const onOver = () => () => {
    if (!isMove) {
      setIsHover(true)
    }
  }

  const onLeave = () => () => {
    if (!isMove) {
      setIsHover(false)
    }
  }

  const onAdd = () => event => {
    event.preventDefault()
    event.stopPropagation()
    setIsAdd(true)
  }

  const onDelete = () => event => {
    event.preventDefault()
    event.stopPropagation()
    setIsDelete(true)
  }

  const onDeleteConfirm = _id => {
    if (isRoute) {
      onPush(`/basket/${catalog}/${_id}`)
    }
  }

  if (isAdd) {
    return (
      <CartAdd
        ref={ref}
        id={id}
        name={propOr('', 'TITLE', item)}
        onClose={onClickOutside}
        isWide={isWide}
      />
    )
  }

  if (isDelete) {
    return (
      <CartDelete
        ref={ref}
        id={id}
        name={propOr('', 'TITLE', item)}
        onClose={onClickOutside}
        onDelete={onDeleteConfirm}
        isWide={isWide}
      />
    )
  }

  return (
    <div
      role='presentation'
      className={cx(s.cart, {
        [s.active]: isActive || isHover,
        [s.cartWide]: isWide,
        [className]: className
      })}
      onClick={onSetActive}
      onMouseOver={onOver()}
      onMouseLeave={onLeave()}
      data-testid='cartItem'
    >
      {isHover && !(id === 0 || (catalog === 'bookmark' && index === 0)) && (
        <div className={s.settings}>
          <div
            role='presentation'
            className={cx(s.setting, s.settingFirst)}
            onClick={onAdd()}
          >
            <Icon className={s.settingIcon}
              icon='settings' />
          </div>
          <div role='presentation'
            className={s.setting}
            onClick={onDelete()}>
            <Icon className={s.settingIcon}
              icon='delete' />
          </div>
        </div>
      )}

      {!isDesktop && !(id === 0 || (catalog === 'bookmark' && index === 0)) && (
        <div className={s.settings}>
          <Button
            onClick={onAdd()}
            icon='settings'
            color='whiteBlue'
            shape='circle'
          />
          <Button
            onClick={onDelete()}
            icon='delete'
            color='whiteBlue'
            shape='circle'
          />
        </div>
      )}
      <div className={s.cartTitle}>
        <IconRadio checked={isActive}
          className={s.cartIcon} />
        <span className={s.cartName}>{propOr('', 'TITLE', item)}</span>
      </div>
      <div className={s.cartCount}>
        {`Всего ${propOr(0, 'CNT', item)} ${noun(prop('CNT', item), [
          'наименование',
          'наименования',
          'наименований'
        ])}`}
      </div>
      <div className={s.sum}>
        {pathOr(0, ['PAYMENTS', 'CASHLESS', 'SUM'], item) ? (
          <div className={s.cartSum}>
            <span className={s.key}>ИТОГО:</span>
            <CurrencyValue
              price={pathOr(0, ['PAYMENTS', 'CASHLESS', 'SUM'], item)}
            />
          </div>
        ) : null}
        {pathOr(0, ['PAYMENTS', 'LOYALTY', 'SUM'], item) ? (
          <div className={s.cartPoints}>
            <span className={s.key}>БАЛЛАМИ:</span>
            <CurrencyValue
              price={pathOr(0, ['PAYMENTS', 'LOYALTY', 'SUM'], item)}
              isPoints
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

CartItem.propTypes = {
  onSetCartActive: func,
  onHideNodal: func,
  onMove: func,
  onPush: func,
  className: string,
  catalog: string,
  catalogInit: string,
  basketType: string,
  isDesktop: bool,
  isChangedContractor: bool,
  isRoute: bool,
  isWide: bool,
  isMove: bool,
  item: object,
  cart: number,
  index: number
}

CartItem.defaultProps = {
  onSetCartActive: () => {},
  onHideNodal: () => {},
  onMove: () => {},
  onPush: () => {},
  item: {},
  isChangedContractor: false,
  isRoute: false,
  isWide: false,
  isMove: false,
  index: 0
}

export default compose(
  connect(
    ({ productList, settings }) => ({
      cart: cartSelector(productList),
      catalog: catalogTypeSelector(settings)
    }),
    {
      onSetCartActive: setCartActive,
      onHideNodal: delayHideModal,
      onPush: push
    }
  ),
  display
)(CartItem)
