/* eslint-disable complexity */
import {
  addIndex,
  any,
  compose,
  descend,
  equals,
  filter,
  has,
  includes,
  isEmpty,
  map,
  or,
  pathOr,
  pipe,
  prepend,
  prop,
  propOr,
  sort,
  sortWith,
  toLower,
  uniq,
  values
} from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'
import { snakeToCamel } from 'helpers/toCamelCase'

export const sectionsSelector = createSelector(
  state => state,
  state => pathOr([], ['sections', 'list'], state)
)

export const productsSelector = createSelector(
  ({ search }) => search,
  ({ query }) => query,
  (search, query) =>
    compose(
      sortWith([descend(pipe(prop('sort'), parseFloat))]),
      pathOr([], ['products', query])
    )(search)
)

export const productsIsLoadedSelector = createSelector(
  state => state,
  state => pathOr(false, ['products', 'isLoaded'], state)
)

export const productsCount = createSelector(
  ({ search }) => search,
  ({ query }) => query,
  (search, query) => pathOr(0, ['products', `${query}_cnt`], search)
)

export const sectionsMainSelector = createSelector(sectionsSelector, sections =>
  compose(
    sortWith([descend(pipe(prop('sort'), parseFloat))]),
    filter(item => Number(prop('depth', item)) === 1),
    values
  )(sections)
)

export const entityListSelector = createSelector(
  ({ search }) => search,
  ({ query }) => query,
  (search, query) =>
    isEmpty(query)
      ? compose(
        sortWith([descend(pipe(prop('sort'), parseFloat))]),
        pathOr([], ['entityList', 'list'])
      )(search)
      : compose(
        sortWith([descend(pipe(prop('sort'), parseFloat))]),
        filter(entity => {
          const entityString = toLower(propOr('', 'title', entity))
          const queryString = toLower(query)
          return entityString.startsWith(queryString)
        }),
        pathOr([], ['entityList', 'list'])
      )(search)
)

export const entityListLettersSelector = createSelector(
  entityListSelector,
  entity =>
    compose(
      sort((a, b) => a.localeCompare(b)),
      values,
      uniq,
      map(entityItem => prop('title', entityItem)?.charAt(0))
    )(entity)
)

export const batchSelector = createSelector(
  ({ search }) => search,
  ({ parentId }) => parentId,
  (search, parentId) => pathOr([], ['batch', parentId], search)
)

export const batchLettersSelector = createSelector(batchSelector, batch =>
  compose(
    sort((a, b) => a.localeCompare(b)),
    values,
    uniq,
    map(m => prop('title', m).charAt(0))
  )(batch)
)

export const batchEmptyResultSelector = createSelector(
  state => state,
  state => pathOr('', ['batch', 'emptyResult'], state)
)

export const queriesSelector = createSelector(
  [({ search }) => search, ({ value }) => value],
  (state, value) =>
    compose(
      filter(query => {
        switch (value) {
          case 'all':
            return true
          case 'view':
            return or(
              equals('correct', prop('status', query)),
              equals('not_correct', prop('status', query))
            )
          case 'not_view':
            return equals(prop('status', query), null)
          case 'correct':
            return equals(prop('status', query), 'correct')
          case 'not_correct':
            return equals(prop('status', query), 'not_correct')
          default:
            return true
        }
      }),
      pathOr([], ['queries', 'list'])
    )(state)
)

export const countQueriesSelector = createSelector(
  state => state,
  state => pathOr(0, ['queries', 'count'])(state)
)

export const keywordsSelector = createSelector(
  ({ search }) => search,
  ({ query }) => query,
  (search, query) => {
    if (isEmpty(query)) {
      return pathOr([], ['keywords', 'list'], search)
    }

    const filterFunc = item =>
      compose(
        any(value => includes(query, prop('key', value))),
        pathOr([], ['values'])
      )(item)

    return compose(
      map(item => ({
        ...item,
        values: filter(
          value => includes(query, prop('key', value)),
          propOr([], 'values', item)
        )
      })),
      filter(filterFunc),
      pathOr([], ['keywords', 'list'])
    )(search)
  }
)

export const successAddKeySelector = createSelector(
  state => state,
  state => propOr({}, 'successAddKey', state)
)

export const successDelKeySelector = createSelector(
  state => state,
  state => propOr(false, 'isSuccessDelKey', state)
)

export const successChangeKeySelector = createSelector(
  state => state,
  state => propOr(false, 'isSuccessChangeKey', state)
)

export const errorMessageSelector = createSelector(
  state => state,
  state => propOr('', 'error', state)
)

export const isLoadedSelector = createSelector(
  state => state,
  state => prop('isLoaded', state)
)

export const listLoadedSelector = createSelector(
  state => state,
  state => pathOr(false, ['entityList', 'isLoaded'], state)
)

export const queriesLoadedSelector = createSelector(
  state => state,
  state => pathOr(false, ['queries', 'isLoaded'], state)
)

export const sectionsLoadedSelector = createSelector(
  state => state,
  state => pathOr(false, ['sections', 'isLoaded'], state)
)

export const sitesListSelector = createSelector(
  state => state,
  state =>
    compose(
      addIndex(map)((siteItem, index) => {
        return {
          id: index,
          title: propOr('', 'title', siteItem),
          value: propOr('all', 'id', siteItem)
        }
      }),
      pathOr([], ['sitesList', 'list'])
    )(state)
)

export const sortListSelector = createSelector(
  state => state,
  state => pathOr([], ['sortList', 'list'], state)
)

export const sortListChangeSelector = createSelector(
  state => state,
  state => pathOr(false, ['sortListChange', 'success'], state)
)

export const sortBrandSelector = createSelector(
  ({ search }) => search,
  ({ queryTmKey }) => queryTmKey,
  (search, queryTmKey) => {
    if (isEmpty(queryTmKey)) {
      return pathOr([], ['sortBrand', 'brand'], search)
    }

    return compose(
      map(item => ({ ...item, isQuery: true })),
      filter(brand => {
        const brandString = toLower(propOr('', 'title', brand))
        const queryTmKeyString = toLower(queryTmKey)
        return brandString.startsWith(queryTmKeyString)
      }),
      pathOr([], ['sortBrand', 'brand'])
    )(search)
  }
)

export const sortBrandByCodeSelector = createSelector(
  state => state,
  state => pathOr([], ['sortByCode', 'list'], state)
)

export const successfulChangeByCodeSelector = createSelector(
  state => state,
  state => pathOr(false, ['sortByCodeChange', 'success'], state)
)

export const sortOrderListSelector = createSelector(
  state => state,
  state => pathOr([], ['sortOrderList', 'list'], state)
)

export const sortOrderChangeSuccessSelector = createSelector(
  state => state,
  state => pathOr(false, ['sortOrderChange', 'success'], state)
)

export const searchIndexSelector = createSelector(
  state => state,
  state => pathOr({}, ['searchIndex'], state)
)

export const propertiesListSelector = createSelector(
  state => state,
  state =>
    compose(
      map(renameKeys(snakeToCamel)),
      pathOr([], ['properties', 'list'])
    )(state)
)

export const countPropertiesSelector = createSelector(
  state => state,
  state => pathOr(0, ['properties', 'count'])(state)
)

export const propertiesTypesSelector = createSelector(
  state => state,
  state =>
    compose(
      map(renameKeys(snakeToCamel)),
      addIndex(map)((item, id) => ({
        ...item,
        id,
        value: prop('code', item),
        title: prop('name', item)
      })),
      pathOr([], ['propertiesTypes', 'list'])
    )(state)
)

export const propertiesListTypesSelector = createSelector(
  propertiesTypesSelector,
  list => prepend({ value: '', title: 'Все' }, list)
)

export const propertiesValuesSelector = createSelector(
  state => state,
  state =>
    compose(
      i => ({
        ...i,
        values: compose(map(renameKeys(snakeToCamel)), propOr([], 'values'))(i)
      }),
      renameKeys(snakeToCamel),
      pathOr({}, ['propertiesValues', 'data'])
    )(state)
)

export const propertiesSettingsSelector = createSelector(
  state => state,
  state => pathOr({}, ['propertiesSettings', 'settings'], state)
)

export const productInfoSelector = createSelector(
  state => state,
  state => pathOr([], ['productInfo', 'data'], state)
)

const settingsTabContentSelector = createSelector(
  state => state,
  state => pathOr({}, ['settings', 'data'], state)
)

export const settingsTabContent = createSelector(
  [settingsTabContentSelector],
  ({ ENTITIES = {}, ITEM = {} }) =>
    compose(
      map(item => ({
        ...item,
        value: pathOr(propOr('', 'default', item), [
          'COLUMNS',
          prop('code')(item)
        ])(ITEM),
        [has('values', item) && 'values']:
          has('values', item) &&
          compose(map(renameKeys(toLower)), values)(propOr([], 'values', item))
      })),
      filter(({ type }) =>
        includes(type, ['integer', 'boolean', 'string', 'enumeration', 'file'])
      ),
      map(renameKeys(toLower)),
      propOr([], 'COLUMNS')
    )(ENTITIES)
)

export const synonymsListSelector = createSelector(
  state => state,
  state => pathOr([], ['synonyms', 'list'])(state)
)

export const countSynonymsSelector = createSelector(
  state => state,
  state => pathOr(0, ['synonyms', 'count'])(state)
)

export const synonymsGroupSelector = createSelector(
  state => state,
  state => pathOr({}, ['synonymsGroup', 'group'])(state)
)

export const synonymsUpdateSelector = createSelector(
  state => state,
  state => propOr({}, 'synonymUpdate')(state)
)

export const qDebugDataSelector = createSelector(
  state => state,
  state => pathOr({}, ['queryDebug', 'data'])(state)
)

export const qDebugDataLoadingSelector = createSelector(
  state => state,
  state => pathOr(false, ['queryDebug', 'isLoading'])(state)
)

export const uploadKeysFailureMsgSelector = createSelector(
  state => state,
  state => pathOr('', ['uploadKeys', 'error'])(state)
)