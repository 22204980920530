/* eslint-disable complexity */
import {
  prop,
  propOr,
  pathOr,
  compose,
  pick,
  isEmpty,
  assoc,
  dissoc,
  equals,
  dec,
  keys,
  reduce,
  without,
  filter,
  sum
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { showModal } from 'redux/modules/modal'
import moment from 'utils/moment'

const MARKS_LIMIT = 20
const FIRST_PAGE = 1

const dateFrom = moment().subtract(1, 'month').format('DD.MM.YYYY')
const dateTo = moment().format('DD.MM.YYYY')

const initialState = {
  offline: {
    isLoaded: false,
    isLoading: false,
    data: [],
    page: FIRST_PAGE,
    limit: 10,
    type: 'active',
    dateRange: {
      from: dateFrom,
      to: dateTo
    },
    date_from: dateFrom,
    date_to: dateTo,
    notices: ''
  },
  markdown: {
    data: [],
    count: {},
    sort: [],
    page: FIRST_PAGE,
    limit: MARKS_LIMIT,
    checkedItems: {},
    isLoaded: false,
    isLoading: false
  },
  offerDetail: {},
  markdownDetail: {},
  headerOffers: {
    isLoaded: false,
    isLoading: false,
    offline: {},
    markdown: {}
  }
}

const emptyNotice = 'Нет доступных акций'
const errorNotice = 'Не удалось отправить запрос'
export {
  getIsLoaded,
  getIsLoading,
  getOfferIsLoaded,
  getOffers,
  getType,
  getDateFrom,
  getDateTo,
  getNotices,
  getErrors,
  getLoyaltyPoints,
  newOffersSelector,
  getPickupId,
  prizesToShowSelector,
  pickupPrizeIdSelector,
  errorSelector,
  offerSelector,
  isLoadedSelector,
  statusesSelector,
  offerErrorSelector,
  offersHeaderSelector,
  isLoadedOffersHeader,
  isDataOffersHeader,
  isLoadingOffersHeader,
  markdownsSelector,
  isMarkdownsLoaded,
  offersTabsSelector,
  countSelector,
  limitSelector,
  sortSelector,
  pageSelector,
  checkedItemsSelector,
  isFiltersCheckedSelector,
  markdownPageDateSelector,
  markdownPageDescriptSelector,
  markdownPagePictureSelector,
  markdownPageTitleSelector,
  offersMainPageSelector,
  offersToConfirmSelector,
  offersToGetPointsSelector,
  isPrizeAvailableSelector,
  isGroupOperationLoading,
  isGroupOperationLoaded,
  errorGroupOffersSelector,
  pointsGroupOffersSelector,
  groupOperationNameSelector,
  errorOffersIdsSelector
} from './selector'

export const setParam = createAction('offers/SET_PARAM')
export const setServerParam = createAction('offers/SET_SERVER_PARAM')
export const setSortParam = createAction('offers/SET_SORT_PARAM')
export const resetOffersFilters = createAction('offers/RESET_FILTERS')
export const fetchOffer = createAction('offers/GET')
export const fetch = createAction('offers/GET')
export const fetchSuccess = createAction('offers/GET_SUCCESS')
export const fetchFailure = createAction('offers/GET_FAILURE')
export const acceptConditions = createAction('offers/ACCEPT_CONDITIONS')
export const acceptConditionsSuccess = createAction(
  'offers/ACCEPT_CONDITIONS_SUCCESS'
)
export const acceptConditionsFailure = createAction(
  'offers/ACCEPT_CONDITIONS_FAILURE'
)
export const getPrize = createAction('offers/GET_PRIZE')
export const getPrizeSuccess = createAction('offers/GET_PRIZE_SUCCESS')
export const getPrizeFailure = createAction('offers/GET_PRIZE_FAILURE')
export const acceptConditionsGroup = createAction(
  'offers/ACCEPT_CONDITIONS_GROUP'
)
export const acceptConditionsGroupSuccess = createAction(
  'offers/ACCEPT_CONDITIONS_GROUP_SUCCESS'
)
export const acceptConditionsGroupFailure = createAction(
  'offers/ACCEPT_CONDITIONS_GROUP_FAILURE'
)
export const getPrizeGroup = createAction('offers/GET_PRIZE_GROUP')
export const getPrizeGroupSuccess = createAction(
  'offers/GET_PRIZE_GROUP_SUCCESS'
)
export const getPrizeGroupFailure = createAction(
  'offers/GET_PRIZE_GROUP_FAILURE'
)
export const fetchHeaderOffer = createAction('offers/FETCH_HEADER_OFFER')
export const fetchHeaderOfferSuccess = createAction(
  'offers/FETCH_HEADER_OFFER_SUCCESS'
)
export const fetchHeaderOfferFailure = createAction(
  'offers/FETCH_HEADER_OFFER_FAILURE'
)
export const fetchMarkdown = createAction('offers/GET_MARKDOWN')
export const fetchMarkdownSuccess = createAction('offers/GET_MARKDOWN_SUCCESS')
export const fetchMarkdownFailure = createAction('offers/GET_MARKDOWN_FAILURE')
export const setMarkdownDetail = createAction('offers/SET_MARKDOWN_DETAIL')

export const request =
  ({ clientApi }) =>
    payload => {
      const contractorId = clientApi.getContractorId()
      const id = prop('offer_id', payload)
      const offerType = prop('offerType', payload)
      const filterParams = dissoc('offerType', payload)
      const url = id ? `/v2/offers/offline/${id}/` : '/v2/offers/offline/'
      const params = id ? payload : { ...filterParams, offer_type_id: offerType }
      return clientApi
        .get(url, {
          params: { ...params, contractor_id: contractorId }
        })
        .then(data => fetchSuccess({ data, params: payload }))
        .catch(fetchFailure)
    }

export const requestAccept =
  ({ clientApi }) =>
    ({
      offerId,
      isList = false,
      offerType = 'offline',
      onSuccess = acceptConditionsSuccess
    }) =>
      clientApi
        .post(`/v2/offers/offline/${offerId}/confirm/`, {
          params: { contractor_id: clientApi.getContractorId() }
        })
        .then(data => onSuccess({ ...data, offerId, isList, offerType }))
        .catch(data => acceptConditionsFailure({ data, offerType }))

export const requestGetPrize =
  ({ clientApi }) =>
    ({ onSuccess = getPrizeSuccess, prizeValue, ...payload }) => {
      const offerId = prop('offer_id', payload)
      const offerType = pathOr('', ['params', 'offerType'], payload)
      return clientApi
        .post(`/v2/offers/offline/${offerId}/pickup/`, {
          params: { ...payload, contractor_id: clientApi.getContractorId() }
        })
        .then(data => onSuccess({ data, params: payload, prizeValue }))
        .catch(data => getPrizeFailure({ data, offerType }))
    }

// fetch для вкладки акции/страницы акция детально
export const handleFetch = (state, payload, { clientApi }) => {
  const offerType = propOr('offline', 'offerType', payload)
  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), payload)
  )
}

export const handleFetchOffer = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleFetchSuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'data', 'response'], payload)
  const notices = pathOr(
    emptyNotice,
    ['data', 'data', 'notices', 'data_empty'],
    payload
  )
  const offerId = pathOr('', ['params', 'offer_id'], payload)
  if (offerId) {
    // если страница акция детально
    const data = propOr({}, 'ITEM', response)
    return {
      ...state,
      offerDetail: {
        ...state.offerDetail,
        [offerId]: data
      }
    }
  }
  const offerType = pathOr('offline', ['params', 'offerType'], payload)
  return {
    ...state,
    [offerType]: {
      ...state[offerType],
      isLoading: false,
      isLoaded: true,
      data: propOr([], 'ITEMS', response),
      count: propOr({}, 'NAV', response),
      sort: propOr([], 'AGGREGATIONS', response),
      notices
    }
  }
}

const handleFetchFailure = (state, payload) => {
  const offerType = pathOr('offline', ['params', 'offerType'], payload)
  return {
    ...state,
    [offerType]: {
      ...state[offerType],
      isLoading: false,
      isLoaded: false,
      error: getErrorMessage(payload)
    }
  }
}

// fetch для акций в шапке
export const requestHeader =
  ({ clientApi }) =>
    payload => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/offers/info/', {
          params: { ...payload, contractor_id: contractorId }
        })
        .then(fetchHeaderOfferSuccess)
        .catch(fetchHeaderOfferFailure)
    }

export const handleFetchHeaderOffer = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      headerOffers: {
        ...state.headerOffers,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestHeader({ clientApi }), payload)
  )

const handleFetchHeaderSuccess = (state, payload) => {
  try {
    const offersResponse = pathOr({}, ['data', 'response'], payload)
    const offersByType = reduce(
      (acc, offer) => {
        const offerType = pathOr('MARKDOWN', ['NAV', 'TYPE'], offer)
        const typeName = equals(offerType, 'MARKDOWN')
          ? offerType
          : pathOr('', ['NAV', 'ID'], offer)
        return {
          ...acc,
          [typeName]: offer
        }
      },
      {},
      offersResponse
    )
    return {
      ...state,
      headerOffers: {
        isLoading: false,
        isLoaded: true,
        data: offersByType
      }
    }
  } catch (error) {
    return {
      ...state,
      headerOffers: {
        isLoading: false,
        isLoaded: true,
        data: {},
        error
      }
    }
  }
}

const handleFetchHeaderFailure = (state, payload) => ({
  ...state,
  headerOffers: {
    ...state.headerOffers,
    isLoading: false,
    error: getErrorMessage(payload)
  }
})

// fetch для спецпредложений
export const requestMarkdown =
  ({ clientApi }) =>
    payload => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/catalog/main/marks/offers/', {
          params: { ...payload, contractor_id: contractorId }
        })
        .then(fetchMarkdownSuccess)
        .catch(fetchMarkdownFailure)
    }

export const handleFetchMarkdown = (state, payload, { clientApi }) => {
  const markdown = propOr({}, 'markdown', state)
  const limit = propOr(MARKS_LIMIT, 'limit', markdown)
  const page = propOr(FIRST_PAGE, 'page', markdown)
  const checkedItems = propOr({}, 'checkedItems', markdown)
  let params = {
    limit,
    page,
    offset: dec(page) * limit
  }
  if (!isEmpty(checkedItems)) {
    const filterItems = compose(
      reduce(
        (acc, key) => ({
          ...acc,
          [`filter[${key}]`]: prop(key, checkedItems)
        }),
        {}
      ),
      keys
    )(checkedItems)
    params = {
      ...params,
      ...filterItems
    }
  }
  return loop(
    {
      ...state,
      markdown: {
        ...state.markdown,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestMarkdown({ clientApi }), params)
  )
}

const handleFetchMarkdownSuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'response'], payload)
  return {
    ...state,
    markdown: {
      ...state.markdown,
      isLoading: false,
      isLoaded: true,
      count: propOr({}, 'NAV', response),
      data: propOr([], 'ITEMS', response),
      sort: propOr([], 'AGGREGATIONS', response)
    }
  }
}

const handleFetchMarkdownFailure = (state, payload) => ({
  ...state,
  markdown: {
    ...state.markdown,
    isLoading: false,
    error: getErrorMessage(payload)
  }
})

const handleSetMarkdownDetail = (state, payload) => ({
  ...state,
  markdownDetail: payload
})

// for pagination and dateRange
const handleSetParam = (state, { name, param, type = '' }) => {
  const paramsByType = type
    ? {
      [type]: {
        // for tab Markdown or offline offers
        ...state[type],
        [name]: param
      }
    }
    : {
      [name]: param
    }
  return loop(
    {
      ...state,
      ...paramsByType
    },
    equals(type, 'markdown') ? Effects.call(fetchMarkdown, {}) : Effects.none()
  )
}

// for selectBoxes
const handleSetSortParams = (state, { name, param, type }, { clientApi }) => {
  const offersType = prop(type, state)
  const checkedParams = param ? { [name]: param } : {} // if selected value is not Default
  let params = {
    limit: propOr(MARKS_LIMIT, 'limit', offersType),
    page: 1,
    offset: 0,
    offerType: type
  }
  if (param) {
    // if selected value is not Default
    params = {
      ...params,
      [`filter[${name}]`]: param,
      offerType: type
    }
  }
  return loop(
    {
      ...state,
      [type]: {
        ...offersType,
        checkedItems: {
          ...checkedParams
        },
        page: 1,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(
      equals(type, 'markdown')
        ? requestMarkdown({ clientApi })
        : request({ clientApi }),
      params
    )
  )
}

// акциии: сортировка по дате
const handleSetServerParam = (
  state,
  { name, param, offerType = 'offline' },
  { clientApi }
) => {
  const pickParams = compose(
    pick(['type', 'date_from', 'date_to']),
    propOr({}, offerType)
  )(state)
  pickParams[name] = param
  const dateFromParams = propOr(dateFrom, 'date_from', pickParams)
  const dateToParams = propOr(dateTo, 'date_to', pickParams)
  let requestParams = {
    offerType,
    type: 'all',
    'filter[date_from]': moment(dateFromParams, 'DD.MM.YYYY').unix(),
    'filter[date_to]': moment(dateToParams, 'DD.MM.YYYY').unix()
  }
  const type = prop('type', pickParams)
  if (type === 'all') {
    requestParams = { type, offerType }
  } else if (type === 'active') {
    requestParams = { offerType }
  }
  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        isLoading: true,
        isLoaded: false,
        [name]: param
      }
    },
    Effects.promise(request({ clientApi }), requestParams)
  )
}

// акции оффлайн: сбросить фильтры
const handleResetOffersFilters = (state, params, { clientApi }) => {
  const offerType = propOr('offline', 'offerType', params)
  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        type: 'active',
        checkedItems: {},
        date_from: pathOr(dateFrom, [offerType, 'dateRange', 'from'], state),
        date_to: pathOr(dateTo, [offerType, 'dateRange', 'to'], state)
      }
    },
    Effects.promise(request({ clientApi }), params)
  )
}

const handleAcceptConditions = (state, params, { clientApi }) => {
  const offerType = propOr('offline', 'offerType', params)
  const isGroupOperation = propOr(false, 'isGroupOperation', params)
  return loop(
    isGroupOperation
      ? { ...state }
      : {
        ...state,
        [offerType]: {
          ...state[offerType],
          isLoading: true,
          isLoaded: false
        }
      },
    Effects.promise(requestAccept({ clientApi }), params)
  )
}

// подтверждение участия
const handleAcceptConditionsSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(false, ['data', 'response', 'SUCCESS'], payload)
  const offerId = propOr('', 'offerId', payload)
  const offerType = propOr('offline', 'offerType', payload)
  const isPageOffer = compose(isEmpty, propOr('', 'confirmId'))(state)
  const newObjDetail = isSuccess
    ? compose(dissoc(offerId), propOr({}, 'offerDetail'))(state)
    : propOr({}, 'offerDetail', state)
  if (isPageOffer) {
    // обновляем информацию об акции
    effects.push(Effects.call(fetchOffer, { offer_id: offerId }))
  }

  effects.push(Effects.call(fetchOffer, { offerType }))
  effects.push(Effects.call(fetchHeaderOffer, {})) // обновляем информацию в шапке

  const error = !isSuccess
    ? 'Не удалось отправить подтверждение участия в акции'
    : ''
  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        confirmId: '',
        offerDetail: newObjDetail,
        isLoaded: !isSuccess,
        isLoading: false,
        error
      }
    },
    isSuccess ? Effects.batch(effects) : Effects.call(showModal, 'offerError')
  )
}

const handleShowError = (state, { data = {}, offerType }) =>
  loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        pickupId: '',
        confirmId: '',
        isLoading: false,
        error: pathOr(errorNotice, ['response', 'meta', 'errorDetail'], data)
      }
    },
    Effects.call(showModal, 'offerError')
  )

// получение подарка
const handleGetPrize = (state, params, { clientApi }) => {
  const offerType = propOr('offline', 'offerType', params)
  const isGroupOperation = !isEmpty(propOr('', 'onSuccess', params))
  return loop(
    isGroupOperation
      ? { ...state }
      : {
        ...state,
        [offerType]: {
          ...state[offerType],
          isLoading: true,
          isLoaded: false
        }
      },
    Effects.promise(requestGetPrize({ clientApi }), params)
  )
}

const handleGetPrizeSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(
    false,
    ['data', 'data', 'response', 'SUCCESS'],
    payload
  )
  const offerId = pathOr('', ['params', 'offer_id'], payload)
  const offerType = pathOr('', ['params', 'offerType'], payload)
  const isPageOffer = compose(isEmpty, propOr('', 'pickupId'))(state)
  const newObjDetail = isSuccess
    ? compose(dissoc(offerId), propOr({}, 'offerDetail'))(state)
    : propOr({}, 'offerDetail', state)
  if (isSuccess) {
    effects.push(Effects.call(showModal, 'getPrizeSuccess'))
    if (isPageOffer) {
      effects.push(Effects.call(fetchOffer, { offer_id: offerId }))
    } else {
      effects.push(Effects.call(fetchOffer, { offerType }))
    }
  }
  effects.push(Effects.call(fetchHeaderOffer, {}))
  const error = !isSuccess
    ? 'Не удалось отправить запрос на получение подарка'
    : ''
  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        error,
        offerDetail: newObjDetail,
        isLoaded: !isSuccess,
        isLoading: false
      }
    },
    isSuccess ? Effects.batch(effects) : Effects.call(showModal, 'offerError')
  )
}

// групповая операция - подтверждение участия
const handleAcceptConditionsGroup = (state, params) => {
  const offerType = propOr('offline', 'offerType', params)
  const offers = propOr([], 'offers', params)
  const effects = []
  effects.push(Effects.call(showModal, 'groupOperation'))
  offers.map(offer => {
    return effects.push(
      Effects.call(acceptConditions, {
        offerId: offer,
        isGroupOperation: true,
        onSuccess: acceptConditionsGroupSuccess
      })
    )
  })
  return loop(
    {
      ...state,
      groupOperation: {
        ...state.groupOperation,
        action: 'acceptConditions',
        offerType,
        offersWaiting: offers,
        isLoading: true,
        isLoaded: false,
        errorOffers: {}
      }
    },
    Effects.batch(effects)
  )
}

const handleAcceptConditionsGroupSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(false, ['data', 'response', 'SUCCESS'], payload)
  const offerId = propOr('', 'offerId', payload)
  const newObjDetail = isSuccess
    ? compose(dissoc(offerId), propOr({}, 'offerDetail'))(state)
    : propOr({}, 'offerDetail', state)

  const offerType = pathOr('offline', ['groupOperation', 'offerType'], state)
  const offersWaitingNew = compose(
    without([offerId]),
    pathOr([], ['groupOperation', 'offersWaiting'])
  )(state)

  const errorOfferName = isSuccess
    ? ''
    : compose(
      pathOr('', [0, 'NAME']),
      filter(offer => propOr('', 'ID', offer) === offerId),
      pathOr([], [offerType, 'data'])
    )(state)

  const error = isSuccess
    ? pathOr({}, ['groupOperation', 'errorOffers'], state)
    : compose(
      assoc(offerId, errorOfferName),
      pathOr({}, ['groupOperation', 'errorOffers'])
    )(state)

  // если все запросы прошли
  if (isEmpty(offersWaitingNew)) {
    effects.push(Effects.call(fetchOffer, { offerType })) // обновляем информацию об акции
    effects.push(Effects.call(fetchHeaderOffer, {})) // обновляем информацию в шапке
  }

  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        offerDetail: newObjDetail
      },
      groupOperation: {
        ...state.groupOperation,
        offersWaiting: offersWaitingNew,
        isLoading: !isEmpty(offersWaitingNew),
        isLoaded: isEmpty(offersWaitingNew),
        errorOffers: error
      }
    },
    Effects.batch(effects)
  )
}

// групповая операция - получение подарка
const handleGetPrizeGroup = (state, params, { clientApi }) => {
  const offerType = propOr('offline', 'offerType', params)
  const offers = propOr([], 'offers', params)
  const effects = []
  effects.push(Effects.call(showModal, 'groupOperation'))
  offers.map(offer => {
    return effects.push(
      Effects.promise(requestGetPrize({ clientApi }), {
        offer_id: propOr('', 'id', offer),
        prize_id: propOr('', 'prizeId', offer),
        prizeValue: propOr('', 'prizeValue', offer),
        prize_count: 1,
        onSuccess: getPrizeGroupSuccess
      })
    )
  })
  return loop(
    {
      ...state,
      groupOperation: {
        ...state.groupOperation,
        action: 'getPrize',
        offerType,
        offersWaiting: reduce(
          (acc, item) => [...acc, propOr('', 'id', item)],
          []
        )(offers),
        errorOffers: {},
        points: 0,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.batch(effects)
  )
}

const handleGetPrizeGroupSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(
    false,
    ['data', 'data', 'response', 'SUCCESS'],
    payload
  )
  const offerId = pathOr('', ['params', 'offer_id'], payload)
  const offerType = pathOr('offline', ['groupOperation', 'offerType'], state)

  const newObjDetail = isSuccess
    ? compose(dissoc(offerId), propOr({}, 'offerDetail'))(state)
    : propOr({}, 'offerDetail', state)

  const offersWaitingNew = compose(
    without([offerId]),
    pathOr([], ['groupOperation', 'offersWaiting'])
  )(state)

  const pointsNew = isSuccess
    ? sum([
      propOr(0, 'prizeValue', payload),
      pathOr(0, ['groupOperation', 'points'], state)
    ])
    : pathOr(0, ['groupOperation', 'points'], state)

  const errorOfferName = isSuccess
    ? ''
    : compose(
      pathOr('', [0, 'NAME']),
      filter(offer => propOr('', 'ID', offer) === offerId),
      pathOr([], [offerType, 'data'])
    )(state)
  const error = isSuccess
    ? pathOr({}, ['groupOperation', 'errorOffers'], state)
    : compose(
      assoc(offerId, errorOfferName),
      pathOr({}, ['groupOperation', 'errorOffers'])
    )(state)

  // если все запросы прошли
  if (isEmpty(offersWaitingNew)) {
    effects.push(Effects.call(fetchOffer, { offerType })) // обновляем информацию об акции
    effects.push(Effects.call(fetchHeaderOffer, {})) // обновляем информацию в шапке
  }

  return loop(
    {
      ...state,
      [offerType]: {
        ...state[offerType],
        offerDetail: newObjDetail
      },
      groupOperation: {
        ...state.groupOperation,
        offersWaiting: offersWaitingNew,
        isLoading: !isEmpty(offersWaitingNew),
        isLoaded: isEmpty(offersWaitingNew),
        points: pointsNew,
        errorOffers: error
      }
    },
    Effects.batch(effects)
  )
}

const reducer = createReducer(on => {
  on(setParam, handleSetParam)
  on(setServerParam, handleSetServerParam)
  on(setSortParam, handleSetSortParams)
  on(resetOffersFilters, handleResetOffersFilters)
  on(fetch, handleFetch)
  on(fetchOffer, handleFetchOffer)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(acceptConditions, handleAcceptConditions)
  on(acceptConditionsSuccess, handleAcceptConditionsSuccess)
  on(acceptConditionsFailure, handleShowError)
  on(getPrize, handleGetPrize)
  on(getPrizeSuccess, handleGetPrizeSuccess)
  on(getPrizeFailure, handleShowError)
  on(acceptConditionsGroup, handleAcceptConditionsGroup)
  on(acceptConditionsGroupSuccess, handleAcceptConditionsGroupSuccess)
  on(acceptConditionsGroupFailure, handleShowError)
  on(getPrizeGroup, handleGetPrizeGroup)
  on(getPrizeGroupSuccess, handleGetPrizeGroupSuccess)
  on(getPrizeGroupFailure, handleShowError)
  on(fetchHeaderOffer, handleFetchHeaderOffer)
  on(fetchHeaderOfferSuccess, handleFetchHeaderSuccess)
  on(fetchHeaderOfferFailure, handleFetchHeaderFailure)
  on(fetchMarkdown, handleFetchMarkdown)
  on(fetchMarkdownSuccess, handleFetchMarkdownSuccess)
  on(fetchMarkdownFailure, handleFetchMarkdownFailure)
  on(setMarkdownDetail, handleSetMarkdownDetail)
}, initialState)

export default reducer
