/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import {
  bool,
  number,
  string,
  oneOfType,
  object,
  func,
  arrayOf,
  oneOf
} from 'prop-types'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'

import PrintedList from 'containers/DownloadModal/PrintedList'
import Modal from 'containers/Modal'
import { forAuth } from 'decorators/user'
import { downloadPrinted } from 'redux/modules/downloadModal'
import { hideModal } from 'redux/modules/modal'
import Button from 'UI/Button'
import Preloader from 'UI/Preloader'
import Spacing from 'UI/Spacing'

import s from './DownloadModal.scss'

const getStatusMessage = ({
  message,
  isPending,
  isSuccess,
  isFailure,
  email
}) => {
  if (message) {
    return message
  }
  if (isPending) {
    return 'Через некоторое время мы подготовим архив и Вы сможете его скачать'
  }
  if (isSuccess) {
    return 'Мы подготовили архив, Вы можете его скачать'
  }
  if (isFailure) {
    return 'При получении серфиката возникла ошибка. Пожалуйста, попробуйте позднее'
  }
  return `Архив будет подготовлен через некоторое время, мы отправим ссылку Вам на почту ${email}`
}

const headersMap = {
  certification: 'Сертификаты',
  printed: 'Сопроводительные документы',
  techdoc: 'Техническая документация'
}

@forAuth
@connect(
  ({
    downloadModal: {
      isPending,
      isSuccess,
      isFailure,
      isTimeout,
      isPendingPrinted,
      isSuccessPrinted,
      isFailurePrinted,
      id,
      type,
      file,
      button,
      message,
      docs
    },
    personal: { email }
  }) => ({
    isPending,
    isSuccess,
    isFailure,
    isTimeout,
    isPendingPrinted,
    isSuccessPrinted,
    isFailurePrinted,
    id,
    type,
    file,
    button,
    message,
    docs,
    email
  }),
  {
    hideModal,
    downloadPrinted
  }
)
@inject(({ helpers: { clientApi } }) => ({ clientApi }))
export default class DownloadModal extends PureComponent {
  static propTypes = {
    isPending: bool,
    isSuccess: bool,
    isFailure: bool,
    isTimeout: bool,
    isPendingPrinted: bool,
    isSuccessPrinted: bool,
    isFailurePrinted: bool,
    id: oneOfType([number, string]),
    file: string,
    type: oneOf(['certification', 'printed', 'techdoc']),
    message: string,
    email: string,
    docs: arrayOf(object),
    hideModal: func,
    downloadPrinted: func
  }

  static defaultProps = {
    docs: []
  }

  handleCloseModal = () => this.props.hideModal()

  renderModal = () => {
    const { isPending, isSuccess, file } = this.props
    const message = getStatusMessage(this.props)

    return (
      <div className={s.root}>
        {message}
        {isPending && (
          <Preloader active />
        )}
        {isSuccess && file && (
          <Spacing margin='top'>
            <Button
              href={file}
              icon='download'
              onClick={this.handleCloseModal}
              rel='noopener noreferrer'
              stretched
              target='_blank'
            >
              Скачать
            </Button>
          </Spacing>
        )}
      </div>
    )
  }

  renderPrintedModal = () => {
    const {
      isPending,
      isSuccess,
      isFailure,
      isTimeout,
      isPendingPrinted,
      isSuccessPrinted,
      isFailurePrinted,
      id,
      message,
      file,
      email,
      docs
    } = this.props

    return (
      <PrintedList
        isPending={isPending}
        isSuccess={isSuccess}
        isFailure={isFailure}
        isTimeout={isTimeout}
        isPendingPrinted={isPendingPrinted}
        isSuccessPrinted={isSuccessPrinted}
        isFailurePrinted={isFailurePrinted}
        id={id}
        docs={docs}
        message={message}
        file={file}
        email={email}
        downloadPrinted={this.props.downloadPrinted}
        hideModal={this.props.hideModal}
      />
    )
  }

  render() {
    const { type } = this.props
    const header = headersMap[type]
    return (
      <Modal id='downloadModal'
        title={header}
        size={type === 'printed' ? 'default' : 'small'}>
        {type === 'printed' ? this.renderPrintedModal() : this.renderModal()}
      </Modal>
    )
  }
}
