import ProductPrices, {
  RawPriceRanges,
  PriceRanges,
  getActivePriceFromPriceRanges,
  prepareActivePriceRanges
} from './ProductPrices'

export { RawPriceRanges, PriceRanges, getActivePriceFromPriceRanges, prepareActivePriceRanges }
export default ProductPrices

