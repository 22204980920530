import { propOr, prop, map } from 'ramda'
import { createSelector } from 'reselect'

export const getOffices = createSelector(
  state => propOr([], 'offices', state),
  offices =>
    map(item => ({
      ...item,
      ID: `${prop('MAP_E', item)}-${prop('MAP_N', item)}`
    }))(offices)
)

export const getTitle = createSelector(
  state => state,
  state => prop('title', state)
)

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getActiveOffice = state => state.activeOffice
export const getHoverOffice = state => state.hoverOffice
