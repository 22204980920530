import loadable from '@loadable/component'
import { pathOr, prop, propOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

import parse from 'utils/querystring'

const LIMIT_12 = 12

const ContentItems = loadable(() =>
  import(
    /* webpackChunkName: "ContentItems" */ 'pages/ContentItems/ContentItems'
  )
)

export default asyncConnect([
  {
    key: 'pageContentItems',
    promise: ({ helpers: { updateData }, location, match }) => {
      const type = pathOr('', ['params', 'type'], match)
      const query = parse(prop('search', location))
      const page = Number(propOr(1, 'p', query))
      const limit = propOr(LIMIT_12, 'limit', query)
      const sort = propOr('ACTIVE_FROM', 'sort', query)
      const order = propOr('DESC', 'order', query)
      const offset = (page - 1) * limit
      const filters = prop('filter', query)
      updateData.set({
        view: 'contentItems',
        type,
        sort,
        order,
        offset,
        limit,
        filter: filters
      })

      return updateData.update()
    }
  }
])(ContentItems)
