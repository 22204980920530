import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'
import StickyNode from 'react-stickynode'

export default class Sticky extends PureComponent {
  static propTypes = {
    children: pt.oneOfType([pt.array, pt.object]),
    onBreakpointPassed: pt.func,
    className: pt.string,
    absoluteClassName: pt.string,
    fixedClassName: pt.string,
    innerZ: pt.number,
    top: pt.number
  }

  static defaultProps = {
    onBreakpointPassed: () => {},
    absoluteClassName: 'absolute',
    fixedClassName: 'fixed',
    innerZ: 1,
    top: 0
  }

  handleStateChange = ({ status }) => this.props.onBreakpointPassed(!!status)

  render() {
    const { className, absoluteClassName, fixedClassName, innerZ, top } = this.props
    return (
      <StickyNode innerZ={innerZ}
        top={top}
        onStateChange={this.handleStateChange}>
        {({ status }) => (
          <div
            className={cx({
              [className]: true,
              [absoluteClassName]: status === StickyNode.STATUS_ORIGINAL,
              [fixedClassName]: status === StickyNode.STATUS_FIXED
            })}
          >
            {this.props.children}
          </div>
        )}
      </StickyNode>
    )
  }
}
