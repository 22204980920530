import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'
import R from 'ramda'

import { getIcon } from './getIcon'
import styles from './Notice.scss'
import Icon from '../Icon/Icon'

export default class NoticesTop extends PureComponent {
  static propTypes = {
    items: pt.array,
    setNoticeStatus: pt.func
  }

  handleClose = () => this.props.setNoticeStatus({ name: 'top', status: false })

  renderItem = (item, key) => (
    <div className={styles.top}
      key={key}>
      <div className={styles.topIcon}>
        <Icon
          className={cx({
            [styles.noticeIcon]: true,
            [`styles.noticeIcon${item.CODE}`]: true
          })}
          icon={getIcon(item.CODE)}
        />
      </div>
      <div className={styles.topContent}>{R.propOr('', 'text', item)}</div>
      <div
        role='presentation'
        className={styles.topClose}
        onClick={this.handleClose}
      >
        x
      </div>
    </div>
  )

  render() {
    return (
      <div className={styles.top}>
        {this.props.items.map(this.renderItem, this)}
      </div>
    )
  }
}
