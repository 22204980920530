import { pathOr, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

import { catalogTypeSelector } from 'redux/modules/settings'

export const cartIncSelector = createSelector(
  state => state,
  state => propOr({}, 'cart', state)
)

export const isLoadingSelector = createSelector(cartIncSelector, state =>
  propOr(false, 'isLoading', state)
)

export const isLoadedSelector = createSelector(cartIncSelector, state =>
  propOr(false, 'isLoading', state)
)

export const cartSelector = createSelector(cartIncSelector, state =>
  propOr(0, 'cart', state)
)

export const cartActionSelector = createSelector(cartIncSelector, state =>
  pathOr({}, ['action'], state)
)

export const cartsSelector = createSelector(
  cartIncSelector,
  (_, settings) => catalogTypeSelector(settings),
  (state, catalog) => pathOr([], ['carts', catalog], state)
)

export const cartsMainSelector = createSelector(cartIncSelector, state =>
  pathOr([], ['carts', 'main'], state)
)

export const productsSelector = createSelector(cartIncSelector, state =>
  propOr([], 'products', state)
)

export const removedItemsSelector = createSelector(cartIncSelector, state =>
  propOr({}, 'removedItems', state)
)

export const isLoadingProductsSelector = createSelector(
  cartIncSelector,
  state => propOr(false, 'isLoadingProducts', state)
)

const cartErrorSelector = createSelector(cartIncSelector, state =>
  propOr({}, 'error', state)
)

export const cartErrorTitleError = createSelector(cartErrorSelector, state =>
  propOr('', 'title', state)
)

export const cartErrorMessageError = createSelector(cartErrorSelector, state =>
  propOr('', 'message', state)
)

export const targetContractorIdSelector = createSelector(
  state => state,
  state => prop('targetContractorId', state)
)

const cartsCommonContractorSelector = createSelector(
  state => state,
  targetContractorIdSelector,
  (state, targetContractorId) =>
    pathOr({}, ['cartsContractor', targetContractorId], state)
)

export const cartsContractorSelector = createSelector(
  cartsCommonContractorSelector,
  common => pathOr({}, ['carts'], common)
)

export const cartsContractorLoadingSelector = createSelector(
  cartsCommonContractorSelector,
  common => pathOr(false, ['isLoading'], common)
)

export const modifiersSelector = createSelector(
  state => prop('modifiers', state),
  (_, productId) => productId,
  (modifiers, productId) => pathOr([], [productId, 'modifiers'], modifiers)
)
