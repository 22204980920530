import { connect } from 'react-redux'

import LeftMenu from 'components/LeftMenu'
import { isOptClientSelector } from 'redux/modules/apiAuth'
import { compareCount as compareCountSelector } from 'redux/modules/compare'
import { favoriteCount as favoriteCountSelector } from 'redux/modules/favorite'
import {
  getIsTempUser,
  getManagers,
  menuAccessSelector
} from 'redux/modules/personal'
import { menuSelector, cabinetMenuSelector } from 'redux/modules/settings'
import { clearToken } from 'redux/modules/token'

export default connect(
  ({ settings, personal, favorite, compare, apiAuth, router }) => {
    const isOptClient = isOptClientSelector({ apiAuth, router })
    const access = menuAccessSelector(personal, isOptClient)
    return {
      menuItems: menuSelector(settings, access),
      menuCabinetBottom: cabinetMenuSelector(settings, access),
      managers: getManagers(personal),
      isTempUser: getIsTempUser(personal),
      favoriteCount: favoriteCountSelector(favorite),
      compareCount: compareCountSelector(compare)
    }
  },
  {
    onSignOut: clearToken
  }
)(LeftMenu)
