import React, { useMemo, useEffect, useCallback } from 'react'

import cx from 'classnames'
import { array, object, bool, func, string, oneOf } from 'prop-types'
import { prop, propEq, pathOr } from 'ramda'

import StoreCount from 'components/ProductActions/StoreCount'
import Wrapper from 'components/Wrapper'
import RadioButton from 'UI/RadioButton'
import Text from 'UI/Text'
import noun from 'utils/noun'

import {
  STORE_NAME,
  COUNT,
  MIN
} from './constants'
import styles from './ProductActions.scss'

const StoreList = ({
  mod,
  className,
  stores,
  activeStore,
  storeColName,
  descriptionStores,
  position,
  isOpen,
  onChange,
  onClose
}) => {
  const onHideList = useCallback(() => {
    onClose(false)
  }, [onClose])

  useEffect(() => {
    window.addEventListener('scroll', onHideList)
    window.addEventListener('resize', onHideList)

    return () => {
      window.removeEventListener('scroll', onHideList)
      window.removeEventListener('resize', onHideList)
    }
  }, [onHideList])

  const handleSelectStore = code => () => {
    onChange(code)
    onClose(true)
  }

  const isLeft = useMemo(() => position === 'left', [position])
  const isRight = useMemo(() => position === 'right', [position])

  return (
    <div
      className={cx(styles.stocks, {
        [styles.stocksActive]: isOpen,
        [styles.stocksLeft]: isLeft,
        [styles.stocksRight]: isRight,
        [className]: !!className
      })}
    >
      {isRight && (
        <div
          className={cx(styles.stockIndent, {
            [styles[`stockIndent${mod}`]]: !!mod
          })}
        />
      )}
      <Wrapper>
        <div className={styles.stockHeader}>
          <div className={cx(styles.stockEl, styles.stockHeaderName)}>
            {STORE_NAME}
          </div>
          <div className={cx(styles.stockEl, styles.stockHeaderWait)}>
            {storeColName}
          </div>
          <div className={cx(styles.stockEl, styles.stockHeaderMin)}>
            {MIN}
          </div>
          <div className={cx(styles.stockEl, styles.stockHeaderRemain)}>
            {COUNT}
          </div>
        </div>
        <div className={styles.stockBorder}>
          <div className={styles.stockHr} />
          <div className={styles.stockHr} />
          <div className={styles.stockHr} />
          <div className={styles.stockHr} />
        </div>
        {stores.map(item => {
          const code = prop('CODE', item)
          const isActive = activeStore === code
          const titleStore = (
            <>
              <div className={styles.stockTitle}>
                {STORE_NAME}
              </div>
              <Text semibold>
                {pathOr('', [code, 'NAME'], descriptionStores)}
              </Text>
            </>
          )

          const isDisabled = propEq('REMAIN', 0, item) && propEq('IS_MAIN', false, item)
          const onClick = isDisabled ? false : handleSelectStore(code)
          const days = pathOr(0, ['WAIT'], item)
          return (
            <div
              role='presentation'
              className={cx(styles.stockItem, {
                [styles.stockItemActive]: isActive,
                [styles.stockItemDisabled]: isDisabled
              })}
              key={code}
              onClick={onClick}
            >
              <div className={styles.stockFirst}>
                <div className={cx(styles.stockEl, styles.stockItemName)}>
                  <RadioButton
                    checked={isActive}
                    label={titleStore}
                    value={code}
                  />
                </div>
              </div>
              <div className={styles.stockNext}>
                <div className={cx(styles.stockEl, styles.stockItemWait)}>
                  <div className={styles.stockTitle}>
                    {storeColName}
                  </div>
                  <Text>
                    {days > 0
                      ? (
                        <span className={styles.stockWait}>
                          {days}
                          {' '}
                          {noun(days, ['день', 'дня', 'дней'])}
                        </span>
                      ) : (
                        <span className={styles.stockWaitNull}>
                          &mdash;
                        </span>
                      )
                    }
                  </Text>
                </div>
                <div className={cx(styles.stockEl, styles.stockItemMin)}>
                  <div className={styles.stockTitle}>
                    {MIN}
                  </div>
                  <Text>
                    {pathOr(1, ['MULTIPLICITY', 'MIN'], item)}
                    {' '}
                    шт.
                  </Text>
                </div>
                <div className={cx(styles.stockEl, styles.stockItemRemain)}>
                  <div className={styles.stockTitle}>
                    {COUNT}
                  </div>
                  <StoreCount
                    count={prop('REMAIN', item)}
                    isActive={isActive}
                    isStockPopup
                  />
                </div>
              </div>
            </div>
          )
        })}
      </Wrapper>
      {isLeft && (
        <div
          className={cx(styles.stockIndent, {
            [styles[`stockIndent${mod}`]]: !!mod
          })}
        />
      )}
    </div>
  )
}

StoreList.propTypes = {
  mod: string,
  className: string,
  stores: array,
  activeStore: string,
  storeColName: string,
  descriptionStores: object,
  isOpen: bool,
  position: oneOf(['left', 'right']),
  onChange: func,
  onClose: func
}

StoreList.defaultProps = {
  mod: 'Catalog',
  position: 'left',
  isOpen: false,
  onChange: () => {},
  onClose: () => {}
}

export default StoreList
