/* eslint-disable complexity */
import {
  prop,
  pathOr,
  values,
  map,
  lensProp,
  set,
  props,
  compose,
  defaultTo,
  propOr,
  sortWith,
  descend,
  path,
  isEmpty
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { hideModal } from 'redux/modules/modal'
import { fetchAddress as fetchAddressOrder } from 'redux/modules/order'

const initialState = {
  isLoaded: false,
  isLoading: false,
  isAddingDelivery: false,
  isCheckingDelivery: false,
  isFetchingDelivery: false,
  isDeletingDelivery: false,
  haveAddress: true,
  isExists: false,
  address: [],
  address_law: '',
  work_phone: '',
  personal_phone: '',
  title: '',
  email: '',
  holdings: [],
  error: '',
  errorAddDelivery: '',
  messagePut: '',
  countries: [],
  fullAddress: [],
  delivery: {},
  deliveries: []
}

export {
  getIsLoaded,
  getIsLoadedManager,
  getIsLoadedFullAddress,
  getIsLoading,
  getAddress,
  getDeliveries,
  getDelivery,
  getAddressLaw,
  getWorkPhone,
  getPersonalPhone,
  getEmail,
  getTitle,
  getHoldings,
  getErrorAddDelivery,
  getCountries,
  getIsAddingDelivery,
  getIsExists,
  getIsCheckingDelivery,
  getIsFetchingDelivery,
  getIsDeletingDelivery,
  getIsScheduleForWeek
} from './selector'

export const fetch = createAction('contractors/GET')

export const fetchSuccess = createAction('contractors/GET_SUCCESS')
export const fetchFailure = createAction('contractors/GET_FAILURE')

export const fetchContractorInfo = createAction(
  'contractors/FETCH_CONTRACTOR_INFO'
)

export const fetchAddress = createAction('contractors/GET_ADDRESS')
export const fetchSuccessAddress = createAction(
  'contractors/GET_ADDRESS_SUCCESS'
)

export const fetchDelivery = createAction('contractors/FETCH_COUNTRIES')
const fetchDeliverySuccess = createAction('contractors/FETCH_COUNTRIES_SUCCESS')
const fetchDeliveryFailure = createAction('contractors/FETCH_COUNTRIES_SUCCESS')
export const resetDelivery = createAction('contractors/RESET_COUNTRIES')

export const fetchFullAddress = createAction('contractors/FETCH_FULL_ADDRESS')
const fetchFullAddressSuccess = createAction(
  'contractors/FETCH_FULL_ADDRESS_SUCCESS'
)

export const postDelivery = createAction('contractors/POST_DELIVERY')
export const postDeliverySuccess = createAction(
  'contractors/POST_DELIVERY_SUCCESS'
)
export const postDeliveryFailure = createAction(
  'contractors/POST_DELIVERY_FAILURE'
)

export const deleteDelivery = createAction('contractors/DELETE_DELIVERY')
export const deleteDeliverySuccess = createAction(
  'contractors/DELETE_DELIVERY_SUCCESS'
)
export const deleteDeliveryFailure = createAction(
  'contractors/DELETE_DELIVERY_FAILURE'
)

export const fetchCheckDelivery = createAction(
  'contractors/FETCH_DELIVERY_CHOOSE'
)
const fetchCheckDeliverySuccess = createAction(
  'contractors/FETCH_DELIVERY_SUCCESS'
)
const fetchCheckDeliveryFailure = createAction(
  'contractors/FETCH_DELIVERY_FAILURE'
)

const fetchKladrSuccess = createAction('kladr/FETCH_KLADR_SUCCESS')

const KLADR_LENGTH = 17

const getAddressList = addresses =>
  compose(
    sortWith([descend(prop('selected'))]),
    map(item => ({
      schedule: prop('WORK_SCHEDULE', item),
      comment: prop('COMMENT', item),
      optionValue: prop('UF_ADDRESS', item) || prop('TITLE', item),
      selected: Number(propOr(0, 'SELECTED', item)),
      id: Number(prop('ID', item))
    })),
    defaultTo([])
  )(addresses)

const request =
  ({ clientApi }) =>
    () =>
      clientApi
        .get('/v1/contractors/', {
          params: {
            refresh: true
          }
        })
        .then(fetchSuccess)
        .catch(fetchFailure)

const requestAddress =
  ({ clientApi }) =>
    ({ onSuccess = fetchSuccessAddress, onFailure = fetchFailure, ...params }) =>
      clientApi
        .get('/v3/contractor/address/', { params })
        .then(onSuccess)
        .catch(onFailure)

const requestKladr =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/exchange/proxy/kladr', { params })
        .then(fetchKladrSuccess)
        .catch(fetchDeliveryFailure)

const handleFetchDelivery = (state, payload, { clientApi }) => {
  const id = prop('id', payload)
  const data = {
    contractor_id: propOr(clientApi.getContractorId(), 'contractorId', payload),
    onSuccess: fetchDeliverySuccess,
    onFailure: fetchDeliveryFailure
  }

  if (id === '') data.type = 'country'
  else data.address_id = id

  return loop(
    {
      ...state,
      isFetchingDelivery: true
    },
    Effects.promise(requestAddress({ clientApi }), data)
  )
}

const handleFetchDeliverySuccess = (state, payload, { clientApi }) => {
  const response = pathOr({}, ['data', 'response'], payload)
  const countriesDate = pathOr({}, ['ENTITIES', 'COUNTRIES', 'VALUE'], response)
  const delivery = propOr({}, 'ITEM', response)
  const countries = map(item => ({ title: item.NAME, value: item.ID }))(
    values(countriesDate)
  )

  if (isEmpty(delivery))
    return {
      ...state,
      isFetchingDelivery: false,
      countries
    }

  delivery.REGION = {}
  delivery.CITY = {}
  delivery.STREET = {}
  const kladr = String(propOr('', 'KLADR', delivery))

  return loop(
    {
      ...state,
      delivery,
      countries
    },
    Effects.promise(requestKladr({ clientApi }), {
      contentType: 'street',
      withParent: 1,
      streetId: kladr.slice(0, KLADR_LENGTH)
    })
  )
}

const handleFetchDeliveryFailure = state => ({
  ...state,
  delivery: {},
  isExists: false,
  isFetchingDelivery: false
})

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.batch([Effects.promise(request({ clientApi }))])
  )

const handleFetchContractorInfo = (state, payload, { clientApi }) => {
  const contractorId = clientApi.getContractorId()
  return loop(
    {
      ...state,
      isLoading: true,
      ifContractorInfoLoaded: false,
      isLoaded: false
    },
    Effects.promise(requestAddress({ clientApi }), {
      contractor_id: contractorId
    })
  )
}

const handleFetchSuccess = (state, payload, { clientApi }) => {
  const contractorId = clientApi.getContractorId()
  const addresses = pathOr(
    {},
    ['data', 'response', 'ENTITIES', 'ADDRESS'],
    payload
  )
  const holdings = pathOr({}, ['data', 'response', 'ITEMS'], payload)
  const holdingsObj = map(item =>
    set(lensProp('DELIVERY'), props(item.ADDRESS || [], addresses), item)
  )(holdings)
  const holdingActive = pathOr({}, [contractorId], holdingsObj)
  const address = getAddressList(holdingActive.DELIVERY)
  delete holdingsObj[contractorId]

  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    address_law: holdingActive.ADDRESS_LAW || '',
    work_phone: holdingActive.WORK_PHONE || '',
    personal_phone: holdingActive.PERSONAL_PHONE || '',
    email: holdingActive.EMAIL || '',
    title: holdingActive.TITLE || '',
    address,
    deliveries: holdingActive.DELIVERY || [],
    holdings: values(holdingsObj)
  }
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  error: getErrorMessage(payload)
})

const handleFetchAddress = (state, payload, { clientApi }) => {
  const contractorId = clientApi.getContractorId()
  return loop(
    {
      ...state,
      isLoadingAddress: true,
      isLoadedAddress: false
    },
    Effects.promise(requestAddress({ clientApi }), {
      contractor_id: contractorId
    })
  )
}

const handleFetchFullAddressSuccess = (state, payload) => {
  const fullAddress = compose(
    values,
    pathOr([], ['data', 'response', 'ITEMS'])
  )(payload)
  return {
    ...state,
    fullAddress,
    isLoadingFullAddress: false,
    isLoadedFullAddress: true
  }
}

const handleFetchFullAddress = (state, payload, { clientApi }) => {
  const contractorId = clientApi.getContractorId()
  return loop(
    {
      ...state,
      isLoadingFullAddress: true,
      isLoadedFullAddress: false
    },
    Effects.promise(requestAddress({ clientApi }), {
      contractor_id: contractorId,
      onSuccess: fetchFullAddressSuccess
    })
  )
}

const handleFetchSuccessAddress = (state, payload) => {
  const response = compose(
    values,
    pathOr({}, ['data', 'response', 'ITEMS'])
  )(payload)
  const address = getAddressList(response)
  return {
    ...state,
    isLoadingAddress: false,
    isLoadedAddress: true,
    address
  }
}

const requestPostDelivery =
  ({ clientApi }) =>
    ({ contractorId, isOffersPage, ...params }) => {
      const id = prop('address_id', params)
      const url = id
        ? `/v3/contractor/address/${id}/update/`
        : '/v3/contractor/address/'

      return clientApi
        .post(url, {
          params: {
            ...params,
            contractor_id: contractorId || clientApi.getContractorId()
          }
        })
        .then(res => postDeliverySuccess({ isOffersPage, ...res }))
        .catch(postDeliveryFailure)
    }

const requestCheckDelivery =
  ({ clientApi }) =>
    ({ contractorId, prevId, ...params }) =>
      clientApi
        .get('/v3/contractor/address/check/choose/', {
          params: {
            ...params,
            contractor_id: contractorId || clientApi.getContractorId()
          }
        })
        .then(res => {
          const newId = path(['data', 'response', 'ITEM', 'ID'], res)
          if (prevId === newId) return fetchCheckDeliveryFailure()
          return fetchCheckDeliverySuccess(res)
        })
        .catch(fetchCheckDeliveryFailure)

const handlePostDelivery = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isAddingDelivery: true
    },
    Effects.promise(requestPostDelivery({ clientApi }), payload)
  )

const handlePostDeliverySuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'response'], payload)

  if (prop('SUCCESS', response) !== 'Y') {
    return {
      ...state,
      isAddingDelivery: false,
      errorAddDelivery: getErrorMessage(payload)
    }
  }

  const offersPage = propOr(false, 'isOffersPage', payload)
  const effects = [Effects.call(fetch)]

  if (!offersPage) {
    effects.push(Effects.call(hideModal))
    effects.push(Effects.call(fetchAddressOrder, { setFirst: true }))
  }

  if (offersPage) {
    effects.push(Effects.call(fetchFullAddress))
  }

  return loop(
    {
      ...state,
      isAddingDelivery: false
    },
    Effects.batch(effects)
  )
}

const handlePostDeliveryFailure = (state, payload) => ({
  ...state,
  isAddingDelivery: false,
  errorAddDelivery: getErrorMessage(payload)
})

const requestDeleteDelivery =
  ({ clientApi }) =>
    ({ id, contractorId }) =>
      clientApi
        .post(`/v3/contractor/address/${id}/delete/`, {
          params: {
            contractor_id:
            contractorId === '' ? clientApi.getContractorId() : contractorId
          }
        })
        .then(deleteDeliverySuccess)
        .catch(deleteDeliveryFailure)

const handleDeleteDelivery = (state, payload, { clientApi }) =>
  loop(
    { ...state, isDeletingDelivery: true },
    Effects.promise(requestDeleteDelivery({ clientApi }), payload)
  )

const handleDeleteDeliverySuccess = state =>
  loop(
    {
      ...state,
      isDeletingDelivery: false
    },
    Effects.batch([Effects.call(hideModal), Effects.call(fetch)])
  )

const handleDeleteDeliveryFailure = (state, payload) => ({
  ...state,
  isDeletingDelivery: false,
  errorAddDelivery: getErrorMessage(payload)
})

const handleResetDelivery = state => ({
  ...state,
  delivery: {},
  isExists: false,
  errorAddDelivery: ''
})

const handleFetchCheckDelivery = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isCheckingDelivery: true
    },
    Effects.promise(requestCheckDelivery({ clientApi }), params)
  )

const handleFetchKladrSuccess = (state, payload) => {
  const result = path(['data', 'response', 'result', 0], payload)
  const contentType = propOr('', 'contentType', result).toUpperCase()
  const parents = propOr([], 'parents', result)
  const delivery = propOr({}, 'delivery', state)

  const newState = {
    ...delivery,
    [contentType]: {
      value: propOr('', 'id', result),
      title: propOr('', 'name', result),
      zip: propOr(0, 'zip', result),
      type: ''
    }
  }

  parents.forEach(item => {
    const itemType = propOr('', 'contentType', item).toUpperCase()
    newState[itemType] = {
      value: propOr('', 'id', item),
      title: propOr('', 'name', item),
      type: propOr('', 'type', item),
      zip: propOr(-1, 'zip', result)
    }
  })

  return {
    ...state,
    isFetchingDelivery: false,
    delivery: {
      ...newState
    }
  }
}

const handleFetchCheckDeliverySuccess = (state, payload) => {
  const delivery = path(['data', 'response', 'ITEM'], payload)
  const effects = []
  const newState = { ...state, isCheckingDelivery: false }

  if (delivery) {
    newState.isExists = true

    effects.push(Effects.call(fetchDeliverySuccess, payload))
  } else newState.isExists = false

  return loop(newState, Effects.batch(effects))
}

const handleFetchCheckDeliveryFailure = state => ({
  ...state,
  isExists: false,
  isCheckingDelivery: false
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchContractorInfo, handleFetchContractorInfo)
  on(fetchDelivery, handleFetchDelivery)
  on(fetchDeliverySuccess, handleFetchDeliverySuccess)
  on(fetchDeliveryFailure, handleFetchDeliveryFailure)
  on(fetchAddress, handleFetchAddress)
  on(fetchSuccessAddress, handleFetchSuccessAddress)
  on(postDelivery, handlePostDelivery)
  on(postDeliverySuccess, handlePostDeliverySuccess)
  on(postDeliveryFailure, handlePostDeliveryFailure)
  on(deleteDelivery, handleDeleteDelivery)
  on(deleteDeliverySuccess, handleDeleteDeliverySuccess)
  on(deleteDeliveryFailure, handleDeleteDeliveryFailure)
  on(fetchFullAddress, handleFetchFullAddress)
  on(fetchFullAddressSuccess, handleFetchFullAddressSuccess)
  on(fetchKladrSuccess, handleFetchKladrSuccess)
  on(resetDelivery, handleResetDelivery)
  on(fetchCheckDelivery, handleFetchCheckDelivery)
  on(fetchCheckDeliverySuccess, handleFetchCheckDeliverySuccess)
  on(fetchCheckDeliveryFailure, handleFetchCheckDeliveryFailure)
}, initialState)

export default reducer
