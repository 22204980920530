import React from 'react'

import cx from 'classnames'
import { pathOr } from 'ramda'

import roundBy from 'utils/roundBy'

import s from './Labels.scss'

const PERCENT = 100

const LabelReduce = props => {
  if (!pathOr(false, ['price', 'VALUE'], props)) return null
  const percent = roundBy(
    parseFloat(pathOr(0, ['price', 'PERCENT'], props)) * PERCENT,
    1
  )
  return (
    <div className={s.label}>
      <div className={cx(s.name, s.nameReduce)}>- {percent}%</div>
    </div>
  )
}

export default LabelReduce
