import loadable from '@loadable/component'
import { equals, pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Offers = loadable(() =>
  import(/* webpackChunkName: "Offers" */ 'pages/Offers/Offers')
)

export default asyncConnect([
  {
    key: 'pageOffers',
    promise: ({ helpers: { updateData }, match }) => {
      const offerType = pathOr('markdown', ['params', 'title'], match)
      const params = equals(offerType, 'markdown')
        ? { view: offerType }
        : { view: 'offline', offerType }
      updateData.set(params)
      return updateData.update()
    }
  }
])(Offers)
