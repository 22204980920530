/* eslint-disable complexity */
import React from 'react'

import { bool } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { showModal } from 'redux/modules/modal'
import { isButtonProgessSelector } from 'redux/modules/personal'
import Button from 'UI/Button'

const ButtonProgress = ({ isMiniBasket = false, isInvoice = false }) => {

  const dispatch = useDispatch()

  const isVisible = useSelector(({ personal }) => isButtonProgessSelector(personal))

  const onProgressInvoice = () => () => {
    dispatch(showModal('progress-invoice'))
  }

  if (!isVisible) {
    return null
  }

  let size = 'default'
  let stretched = false
  if (isMiniBasket) {
    size = 'small'
    stretched = true
  } else if (isInvoice) {
    size = 'big'
    stretched = true
  }

  return (
    <Button
      color='blue'
      size={size}
      stretched={stretched}
      onClick={onProgressInvoice()}
      icon='cart-progress'
    >
      Прогрессы по отгрузке заказов
    </Button>
  )
}

ButtonProgress.propTypes = {
  isMiniBasket: bool,
  isInvoice: bool
}

export default ButtonProgress
