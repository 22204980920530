import { compose, filter, prop, path, pathOr, head } from 'ramda'
import { createSelector } from 'reselect'

export const getNoticeTop = state =>
  compose(
    filter(item => item.isOpen === true),
    prop('top')
  )(state)

export const getNotices = createSelector(
  state => state,
  ({ type, notice, page }) => path([`${type}_${page}`, 'items'], notice)
)

export const getNoticeHeader = createSelector(
  state => state,
  ({ type, notice, page }) => path([`${type}_${page}`, 'header'], notice)
)

export const getNotice = createSelector(getNotices, notices => head(notices))

export const getNoticeIsOpen = createSelector(
  state => state,
  ({ type, page, notice }) =>
    pathOr(false, [`${type}_${page}`, 'isOpen'], notice)
)

export const getIsSendingConfirm = createSelector(
  state => state,
  state => pathOr(false, ['confirm_site', 'isSending'], state)
)
