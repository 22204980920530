import React from 'react'

import cx from 'classnames'
import { bool, func, string } from 'prop-types'
import { connect } from 'react-redux'

import {
  cartDelete,
  cartSelector,
  setCartActive
} from 'redux/modules/productList'
import Button from 'UI/Button'
import Spacing from 'UI/Spacing'

import s from './CartChange.scss'

const CartDelete = ({
  name,
  id,
  cart,
  isWide,
  onDelete,
  onCartDelete,
  onSetCartActive,
  onClose
}) => {
  const handleDelete = () => () => {
    onCartDelete({ value: id })
    if (cart === id) {
      onSetCartActive(0)
      onDelete(0)
    }
    onClose()
  }

  const handleClose = () => () => {
    onClose()
  }

  return (
    <div
      className={cx(s.cart, s.action, {
        [s.cartWide]: isWide
      })}
    >
      <div className={s.cartName}>{name}</div>
      <div className={s.desc}>
        Вы действительно <br />
        хотите удалить эту корзину?
      </div>
      <div className={s.buttons}>
        <Button
          color='whiteBlue'
          size='small'
          onClick={handleClose()}
        >
          Отмена
        </Button>
        <Spacing
          margin='left'
          size='small'
        >
          <Button
            color='blue'
            size='small'
            onClick={handleDelete()}
            stretched
          >
            Удалить
          </Button>
        </Spacing>
      </div>
    </div>
  )
}

CartDelete.propTypes = {
  name: string,
  id: string,
  cart: string,
  onCartDelete: func,
  onSetCartActive: func,
  onClose: func,
  onDelete: func,
  isWide: bool
}

export default connect(
  ({ productList }) => ({
    cart: cartSelector(productList)
  }),
  {
    onCartDelete: cartDelete,
    onSetCartActive: setCartActive
  }
)(CartDelete)
