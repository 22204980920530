import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Directory = loadable(() =>
  import(/* webpackChunkName: "Directory" */ 'pages/Directory/Directory')
)

export default asyncConnect([
  {
    promise: ({ helpers: { updateData }, route }) => {
      const type = pathOr('', ['type'], route)
      updateData.set({ view: 'brandsList', type })
      return updateData.update()
    }
  }
])(Directory)
