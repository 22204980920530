import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

const ERROR = 'Ошибка'

const initialState = {
  message: '',
  step: 0,
  isSended: false,
  isConfirmed: false,
  isConfirming: false,
  statusConfirm: false,
  errors: {},
  recoveryMessages: [],
  recoveryErrors: [],
  isSendedRecovery: false,
  directoriesIsLoaded: false,
  directoriesIsLoading: false,
  country: {},
  distribution: {}
}

export {
  getDirectoriesIsLoaded,
  getIsConfirmed,
  getIsConfirming,
  getStatusConfirm,
  getMessage,
  getErrors,
  countriesSelector,
  companiesSelector,
  isSendedSelector,
  suggestionsErrorSelector,
  recoveryMessagesSelector,
  recoveryErrorsSelector,
  isSendedRecoverySelector
} from './selector'

export const setValues = createAction('registration/SET_VALUES')

export const confirm = createAction('registration/CONFIRM')
export const confirmSuccess = createAction('registration/CONFIRM_SUCCESS')
export const confirmFailure = createAction('registration/CONFIRM_FAILURE')

export const putRecovery = createAction('registration/PUT_RECOVERY')
export const putRecoverySuccess = createAction(
  'registration/PUT_RECOVERY_SUCCESS'
)
export const putRecoveryFailure = createAction(
  'registration/PUT_RECOVERY_FAILURE'
)

export const getDirectories = createAction('registration/GET_DIRECTORIES')
export const getDirectoriesSuccess = createAction(
  'registration/GET_DIRECTORIES_SUCCESS'
)
export const getDirectoriesFailure = createAction(
  'registration/GET_DIRECTORIES_FAILURE'
)

export const fetchCompanyData = createAction('registration/GET_DIRECTORIES')
export const fetchCompanyDataSuccess = createAction('registration/GET_DIRECTORIES_SUCCESS')
export const fetchCompanyDataFailure = createAction('registration/GET_DIRECTORIES_FAILURE')

const handleSetValues = (state, values) => ({
  ...state,
  ...values
})

const confirmRequest =
  ({ clientApi }) =>
    values =>
      clientApi
        .post('/v1/login/confirm/', { params: { ...values } })
        .then(confirmSuccess)
        .catch(confirmFailure)

const requestData = ({ clientApi }) => params => {
  return clientApi.post('/v3/exchange/proxy/dadata/', { params })
    .then(fetchCompanyDataSuccess)
    .catch(fetchCompanyDataFailure)
}

const handleFetchCompanyData = (state, params, { clientApi }) => loop(
  {
    ...state
  },
  Effects.promise(requestData({ clientApi }), params)
)

const handleFetchCompanyDataSuccess = (state, payload) => ({
  ...state,
  companySuggestions: R.pathOr([], ['data', 'response', 'suggestions'], payload),
  suggestionsError: false
})

const handleFetchCompanyDataFailure = state => ({
  ...state,
  suggestionsError: true
})

const handleConfirm = (state, values, { clientApi }) => loop(
  {
    ...state,
    isConfirming: true,
    isConfirmed: false
  },
  Effects.promise(confirmRequest({ clientApi }), values)
)

const handleConfirmSuccess = (state, payload) => ({
  ...state,
  isConfirmed: true,
  isConfirming: false,
  statusConfirm: R.equals(
    'Y',
    R.pathOr('N', ['data', 'response', 'STATUS'], payload)
  ),
  message: R.pathOr(ERROR, ['data', 'response', 'MESSAGE'], payload),
  errors: R.pathOr({}, ['data', 'errors'], payload)
})

const handleConfirmFailure = state => ({
  ...state,
  isConfirmed: false,
  isConfirming: false,
  statusConfirm: false,
  message: ERROR
})

const putRequest =
  ({ clientApi }) =>
    values =>
      clientApi
        .post('/v1/login/recovery/', { params: { ...values } })
        .then(putRecoverySuccess)
        .catch(putRecoveryFailure)

const handlePutRecovery = (state, values, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(putRequest({ clientApi }), values)
  )

const handlePutRecoverySuccess = (state, payload) => ({
  ...state,
  recoveryMessages: [R.path(['data', 'response', 'MESSAGE'], payload)],
  recoveryErrors: R.values(R.path(['data', 'errors'], payload)),
  isSendedRecovery: R.equals(
    'Y',
    R.path(['data', 'response', 'SUCCESS'], payload)
  )
})

const handlePutRecoveryFailure = state => ({
  ...state
})

const requestGetDirectories =
  ({ clientApi }) =>
    values =>
      clientApi
        .get('/v1/login/directories/', { params: { ...values } })
        .then(getDirectoriesSuccess)
        .catch(getDirectoriesFailure)

const handleGetDirectories = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      directoriesIsLoaded: false,
      directoriesIsLoading: true
    },
    Effects.promise(requestGetDirectories({ clientApi }))
  )

const handleGetDirectoriesSuccess = (state, payload) => ({
  ...state,
  directoriesIsLoaded: true,
  directoriesIsLoading: false,
  country: R.pathOr({}, ['data', 'response', 'ENTITIES', 'COUNTRY'], payload),
  distribution: R.pathOr(
    {},
    ['data', 'response', 'ENTITIES', 'DISTRIBUTION_CHANNEL'],
    payload
  )
})

const handleGetDirectoriesFailure = (state, payload) => ({
  ...state,
  directoriesIsLoaded: false,
  directoriesIsLoading: false,
  directories_error: R.pathOr('', ['response'], payload)
})

const reducer = createReducer(on => {
  on(setValues, handleSetValues)

  on(confirm, handleConfirm)
  on(confirmSuccess, handleConfirmSuccess)
  on(confirmFailure, handleConfirmFailure)

  on(putRecovery, handlePutRecovery)
  on(putRecoverySuccess, handlePutRecoverySuccess)
  on(putRecoveryFailure, handlePutRecoveryFailure)

  on(getDirectories, handleGetDirectories)
  on(getDirectoriesSuccess, handleGetDirectoriesSuccess)
  on(getDirectoriesFailure, handleGetDirectoriesFailure)

  on(fetchCompanyData, handleFetchCompanyData)
  on(fetchCompanyDataSuccess, handleFetchCompanyDataSuccess)
  on(fetchCompanyDataFailure, handleFetchCompanyDataFailure)
}, initialState)

export default reducer
