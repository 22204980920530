import { forEachObjIndexed } from 'ramda'

const getPropertiesMap = items => {
  const propertiesMap = {}

  forEachObjIndexed(item => {
    const properties = item.PROPERTY
    if (!properties) {
      return
    }

    Object.keys(properties).forEach(propertyItem => {
      const propertyValueName = properties[propertyItem]
      if (!propertyValueName) {
        return
      }

      propertiesMap[propertyItem] = propertiesMap[propertyItem] || {}
      propertiesMap[propertyItem][propertyValueName] = true
    })
  })(items)

  return propertiesMap
}

export default getPropertiesMap
