import React from 'react'

import { array, bool, number, oneOfType } from 'prop-types'
import {
  join,
  path,
  pickAll,
  prop,
  reduce,
  compose,
  propOr,
  values
} from 'ramda'
import { connect } from 'react-redux'

import { cartsSelector } from 'redux/modules/productList'

import s from './CartName.scss'

const getCartName = (cart = 0, carts = []) => {
  const names = reduce(
    (acc, item) => ({ ...acc, [prop('ID', item)]: propOr('', 'TITLE', item) }),
    {},
    carts
  )
  return typeof cart === 'number'
    ? path([cart], names)
    : compose(join(', '), values, pickAll(values(cart)))(names)
}

const CartName = ({ cart = 0, carts = [], isOnlyText = false }) => {
  const cartNames = getCartName(cart, carts)

  if (isOnlyText) {
    return cartNames
  }
  return <div className={s.cartName}>{cartNames}</div>
}

CartName.propTypes = {
  cart: oneOfType([array, number]),
  carts: array,
  isOnlyText: bool
}

export default connect(({ productList, settings }) => ({
  carts: cartsSelector(productList, settings)
}))(CartName)

export { getCartName }
