import loadable from '@loadable/component'
import { path, pathOr, propOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

import getProducts from 'helpers/getProducts'
import parse from 'utils/querystring'

const SearchBookmark = loadable(() =>
  import(
    /* webpackChunkName: "SearchBookmark" */ 'pages/SearchBookmark/SearchBookmark'
  )
)

export default asyncConnect([
  {
    key: 'pageSearchBookmark',
    promise: props =>
      getProducts({
        url: `catalog/bookmark/${pathOr(
          '',
          ['match', 'params', 'bookmark'],
          props
        )}/search`,
        urlParams: {
          bookmark_id: pathOr('', ['match', 'params', 'bookmark'], props),
          correct: propOr(
            'Y',
            'correct',
            parse(path(['location', 'search'], props))
          )
        },
        type: 'search',
        basket: [
          'bookmark',
          pathOr('', ['match', 'params', 'bookmark'], props)
        ],
        useQuery: ['q', 'correct']
      })(props)
  }
])(SearchBookmark)
