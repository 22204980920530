/* eslint-disable complexity */
import React from 'react'

import cx from 'classnames'
import { number, bool, string } from 'prop-types'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'

import CapPreloader from 'components/CapPreloader'
import CurrencyValue from 'components/CurrencyValue'
import Icon from 'components/Icon'
import config from 'config'

import styles from './HeaderCart.scss'

const HeaderCart = ({ basketTitle, isLoading, itemsCount, itemsSum, theme, isDesktop }) => {
  const isEmpty = !itemsCount && !itemsSum

  const cartIcon = pathOr('', ['themes', theme, 'icons', 'cart'], config)

  return (
    <div
      className={cx(styles.cart, {
        [styles.cart_disabled]: isEmpty
      })}
    >
      {isLoading ? (
        <CapPreloader height='50px' />
      ) : (
        <div className={styles.wrapper}>
          <Icon
            className={cx(styles.icon, {
              [styles.iconGray]: isEmpty
            })}
            isActive={!isEmpty}
            icon={cartIcon}
            isToggler
            size='auto'
          />
          {itemsCount !== 0 && (
            <div className={styles.basketCount}>{itemsCount}</div>
          )}
          <div className={cx({
            [styles.wrapInfo]: !isDesktop,
            [styles.wrapInfo_disabled]: !isDesktop && isEmpty
          })}>
            {!isDesktop && (
              <div className={styles.basketTitle}>{basketTitle}</div>
            )}
            <div className={styles.wrapPrice}>
              {isDesktop && itemsCount === 0 ? (
                'Корзина пуста'
              ) : (
                <CurrencyValue price={itemsSum}
                  showZero />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

HeaderCart.propTypes = {
  isDesktop: bool,
  isLoading: bool,
  itemsCount: number,
  itemsSum: number,
  theme: string,
  basketTitle: string
}

HeaderCart.defaultProps = {
  isLoading: false
}

export default connect(() => ({}), {})(HeaderCart)
