/* eslint-disable complexity */
import {
  compose,
  lensPath,
  set,
  reduce,
  keys,
  equals,
  isEmpty,
  uniq,
  concat,
  filter,
  complement,
  over,
  flip,
  merge
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { setPage } from 'redux/modules/productListParams'

import {
  getRangeSliderMin,
  getRangeSliderMax,
  getRangeSliderFrom,
  getRangeSliderTo
} from './selector'

const initialState = {
  isShow: {
    baseFilter: true
  },
  checkedItems: {},
  disabledItems: {},
  showFilterNotificationId: {},
  rangeSlider: {
    from: 0,
    to: 500000,
    min: 0,
    max: 500000,
    left: 0,
    right: 0,
    isDrag: false,
    isReseted: false
  },
  loyaltyBaseFilter: {
    id: 'loyaltyBaseFilter',
    query: 'loyaltyBase',
    logic: 'AND',
    items: [
      {
        disabled: false,
        id: 'affordable',
        title: 'Доступные мне подарки'
      }
    ]
  },
  baseFilter: {
    id: 'baseFilter',
    query: 'base',
    logic: 'AND',
    items: [
      {
        disabled: false,
        id: 'stock',
        title: 'В наличии'
      },
      {
        disabled: false,
        id: 'new',
        title: 'Новинка'
      },
      {
        disabled: false,
        id: 'hot',
        title: 'Хит продаж'
      },
      {
        disabled: false,
        id: 'sale',
        title: 'Распродажа'
      }
      // ,{
      //   disabled: false,
      //   id: 'bookmark',
      //   title: 'Доступно в закладках'
      // }
      // {
      //   disabled: false,
      //   id: '5',
      //   title: 'Скоро подарожают'
      // },
      // {
      //   disabled: false,
      //   id: '6',
      //   title: 'Доступно в закладках'
      // }
    ]
  }
}

export {
  getRangeSlider,
  getRangeSliderMin,
  getRangeSliderMax,
  getRangeSliderFrom,
  getRangeSliderTo,
  getIsShowAll,
  getCheckedItems,
  getCheckedItemsById,
  getRangeSliderIsDrag
} from './selector'

export const setChecked = createAction('productListFilter/SET_CHECKED')
export const setParentId = createAction('productListFilter/SET_PARENT_ID')
export const setRangeProps = createAction('productListFilter/SET_RANGE_PROPS')
export const setRangeExtremals = createAction(
  'productListFilter/SET_RANGE_EXTREMALS'
)
export const setShowAll = createAction('productListFilter/SET_SHOW_ALL')
export const setReseted = createAction('productListFilter/SET_RESETED')
export const setRangeDrag = createAction('productListFilter/SET_CHANGED')
export const setOnLoad = createAction('productListFilter/SET_ON_LOAD')
export const setDisabledItems = createAction(
  'productListFilter/SET_DISABLED_ITEMS'
)

const handleSetRangeDrag = (state, isDrag) => ({
  ...state,
  rangeSlider: {
    ...state.rangeSlider,
    isDrag
  }
})

const handleSetReseted = (state, parentId) => ({
  ...initialState,
  parentId,
  rangeSlider: {
    min: state.rangeSlider.min,
    max: state.rangeSlider.max,
    from: 0,
    to: state.rangeSlider.max,
    isReseted: true
  }
})

const handleSetShowAll = (state, { id, showAll }) => ({
  ...state,
  showFilterNotificationId: {},
  isShow: {
    ...state.isShow,
    [id]: showAll
  }
})

const handleSetRangeExtremals = (state, { min, max, from, to }) => {
  const currentMin = getRangeSliderMin(state)
  const currentMax = getRangeSliderMax(state)

  if (currentMin === min && currentMax === max) {
    return state
  }

  return {
    ...state,
    rangeSlider: {
      ...state.rangeSlider,
      min,
      max,
      from: from || min,
      to: to || max,
      isReseted: false
    }
  }
}

const handleSetRangeProps = (state, { from, to }) => {
  const currentFrom = getRangeSliderFrom(state)
  const currentTo = getRangeSliderTo(state)
  const rangeSliderLens = lensPath(['rangeSlider'])
  const filterNotificationLens = lensPath(['showFilterNotificationId'])
  if (currentFrom === from && currentTo === to) {
    return state
  }

  const newState = compose(
    over(rangeSliderLens, flip(merge)({ from, to, isReseted: false })),
    set(filterNotificationLens, {
      filterId: 'rangeSlider',
      itemId: 0
    })
  )(state)

  return loop(newState, Effects.call(setPage, 1))
}

const handleSetParentId = (state, parentId) =>
  loop(
    {
      ...state
    },
    Effects.batch([
      Effects.call(setReseted, parentId),
      Effects.call(setPage, 1)
    ])
  )

const handleSetChecked = (state, { filterId, itemId, checked }) => {
  const checkedItems = state.checkedItems[filterId] || []
  let newCheckedItems

  if (checked) {
    newCheckedItems = uniq(concat(checkedItems, [itemId]))
  } else {
    newCheckedItems = filter(complement(equals(itemId)), checkedItems)
  }
  const checkedItemsLens = lensPath(['checkedItems', filterId])
  const filterNotificationLens = lensPath(['showFilterNotificationId'])

  if (equals(checkedItems, newCheckedItems)) {
    return state
  }

  const filterNotificationId = isEmpty(newCheckedItems)
    ? {}
    : { filterId, itemId }

  const newState = compose(
    set(checkedItemsLens, newCheckedItems),
    set(filterNotificationLens, filterNotificationId)
  )(state)

  return loop(newState, Effects.call(setPage, 1))
}

const handleSetOnLoad = (
  state,
  { checkedItems: items, rangeFrom, rangeTo, page }
) => {
  let newState = { ...state }
  if (items) {
    newState.checkedItems = reduce(
      (acc, key) => (items[key].length ? { ...acc, [key]: items[key] } : acc),
      {},
      keys(items)
    )
  }
  if (rangeFrom) {
    newState = compose(set(lensPath(['rangeSlider', 'from']), rangeFrom))(
      newState
    )
  }
  if (rangeTo) {
    newState = compose(set(lensPath(['rangeSlider', 'to']), rangeTo))(newState)
  }

  return loop(newState, page ? Effects.call(setPage, page) : Effects.none())
}

const handleSetDisabledItems = (state, disabledItems) => ({
  ...state,
  disabledItems
})

export default createReducer(on => {
  on(setRangeDrag, handleSetRangeDrag)
  on(setChecked, handleSetChecked)
  on(setParentId, handleSetParentId)
  on(setRangeProps, handleSetRangeProps)
  on(setRangeExtremals, handleSetRangeExtremals)
  on(setShowAll, handleSetShowAll)
  on(setReseted, handleSetReseted)
  on(setOnLoad, handleSetOnLoad)
  on(setDisabledItems, handleSetDisabledItems)
}, initialState)
