import React, { useEffect } from 'react'

import { func, number, oneOfType, string } from 'prop-types'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'
import { compose } from 'redux'

import Portal from 'components/Portal'
import Modal from 'containers/Modal'
import ProductQuickView from 'containers/ProductQuickView'
import { forDesktop } from 'decorators/device'
import { setProductModal, productModalSelector } from 'redux/modules/products'

const PopupProduct = ({ id, location, onSetProductModal }) => {
  const handleHidePopup = () => () => {
    onSetProductModal('')
  }

  useEffect(() => {
    if (id) {
      onSetProductModal('')
    }
  }, [location])

  if (!id) return null

  return (
    <Portal>
      <Modal id='quickView'
        size='big'
        onClose={handleHidePopup()}>
        <ProductQuickView id={id}
          hideModal={handleHidePopup()} />
      </Modal>
    </Portal>
  )
}

PopupProduct.propTypes = {
  id: oneOfType([number, string]),
  location: string,
  onSetProductModal: func
}

export default compose(
  connect(
    ({ products, router }) => ({
      id: productModalSelector(products),
      location: pathOr({}, ['location', 'pathname'], router)
    }),
    {
      onSetProductModal: setProductModal
    }
  ),
  forDesktop
)(PopupProduct)
