/* eslint-disable complexity */
import {
  prop,
  propOr,
  path,
  pathOr,
  toUpper,
  length,
  compose,
  replace,
  filter,
  propEq,
  findLast,
  toLower,
  or,
  map,
  isEmpty
} from 'ramda'
import { createSelector } from 'reselect'

import config from 'config'
import renameKeys from 'helpers/objectModify'

const ROLE_DEFAULT = 'Пользователь'
const ROLES_RUS = {
  USER: ROLE_DEFAULT,
  ADMINISTRATOR: 'Администратор',
  SUPER_ADMINISTRATOR: 'Супер-администратор'
}

export const getIsLoaded = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

export const getIsLoading = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const getPersonal = createSelector(
  state => state,
  state => propOr({}, 'personal', state)
)
export const getFormData = createSelector(
  (form, props) => ({ form, props }),
  ({ form, props }) => prop(form, props)
)
export const getIsSupportLoaded = state => state.supportLoaded
export const getTitle = state => state.title
export const getRole = state => state.role
export const getContractors = state => state.contractors
export const getActiveContractor = state => state.activeContractor
export const getContractorCode = state => state.contractorCode
export const userIdSelector = createSelector(
  state => state,
  state => propOr(0, 'userId', state)
)
export const getUserEmail = state => state.email
export const getCompanyEmail = state => state.companyEmail
export const getIsChangingContractor = state => state.isChangingContractor
export const getIsUser = state => state.isUser
export const getIsTempUser = state => state.isTempUser
export const getMessage = state => state.message
export const getIsAllowed = state => state.isAllowed
export const getIsSuperuser = state =>
  ['ADMIN', 'CHIEF', 'SUPPORT', 'MANAGER'].indexOf(state.role) !== -1
export const getDateRegister = state => state.dateRegister
export const getAddress = state => state.address
export const getName = state => state.name
export const getDescription = state => state.description
export const getSiteUrl = state => state.siteUrl
export const getSupports = state => propOr([], 'support', state)
export const getSearchedContractors = state => state.searchedContractors
export const getIsExistsSuperuser = state => state.isExistsSuperuser
export const getIsLoyaltyVisible = state =>
  propOr(false, ['status', 'ACCESSES', 'ACCESS'], state)
export const getManager = state => state.manager

export const adminTokenSelector = createSelector(
  state => state,
  state => prop('adminToken', state)
)

export const personalNameSelector = createSelector(
  state => state,
  state => propOr('', 'title', state)
)

export const roleSelector = createSelector(
  state => state,
  state => propOr('', 'role', state)
)

export const accessNameSelector = createSelector(
  roleSelector,
  role => pathOr(ROLE_DEFAULT, [role], ROLES_RUS)
)

export const getPhone = createSelector(
  state => prop('phone', state),
  phone => replace(/,/, '<br />', phone)
)

export const getAccess = createSelector(
  (state, access) => path(['accesses', toUpper(access)], state),
  state => state
)

const getAccessSelector = createSelector(
  state => state,
  state => pathOr({}, ['accesses'], state)
)

export const getOfficesAccess = createSelector(
  getAccessSelector,
  state => pathOr(false, ['OFFICES'], state)
)

export const getRegistrationAccess = createSelector(
  getAccessSelector,
  state => pathOr(false, ['REGISTRATION'], state)
)

export const discountsAccessSelector = createSelector(
  getAccessSelector,
  state => pathOr(false, ['SYSTEM_DISCOUNT'], state)
)

export const isButtonProgessSelector = createSelector(
  getAccessSelector,
  state => pathOr(false, ['COMBINING_ORDER'], state)
)

export const offersAccessSelector = createSelector(
  state => pathOr({}, ['accesses', 'OFFERS'], state),
  offers => filter(offer => !!offer)(offers)
)

export const isOffersVisibleSelector = createSelector(
  offersAccessSelector,
  offers => !isEmpty(offers)
)

export const getManagers = createSelector(
  state => propOr([], 'managers', state),
  state => propOr([], 'support', state),
  (managers, support) => (length(managers) > 0 ? managers : support)
)

export const costMultipleSelector = createSelector(
  state => state,
  state => prop('costMultiple', state)
)

export const basketsLoadingSelector = createSelector(
  state => state,
  (_, type) => type,
  (state, type) => propOr(false, `isLoading${type}`, state)
)

export const basketsLoadedSelector = createSelector(
  state => state,
  (_, type) => type,
  (state, type) => propOr(false, `isLoaded${type}`, state)
)

export const getKancobozId = createSelector(
  state => state,
  state => pathOr('', ['counters', 'KANCOBOZ'], state)
)

export const gtmIdSelector = createSelector(
  state => state,
  state => pathOr('', ['counters', 'GTM'], state)
)

export const gaIdSelector = createSelector(
  state => state,
  state => pathOr('', ['counters', 'GA'], state)
)

export const metaTagsSelector = createSelector(
  state => state,
  state => propOr([], 'metaTags', state)
)

export const metaHeadSelector = createSelector(
  state => state,
  state => propOr({}, 'metaHead', state)
)

export const metaTitleSelector = createSelector(metaHeadSelector, state =>
  propOr('', 'TITLE', state)
)

export const metaDescSelector = createSelector(metaHeadSelector, state =>
  propOr('', 'DESCRIPTION', state)
)

export const isRHSelector = createSelector(
  state => state,
  state => propOr(false, 'isRH', state)
)

export const onlyRubSelector = createSelector(
  state => state,
  state => propOr(true, 'onlyRub', state)
)

export const feedbackFormSelector = createSelector(
  state => state,
  state => propOr(false, 'feedbackForm', state)
)

export const socialSelector = createSelector(
  state => state,
  state => propOr({}, 'social', state)
)

export const vkUrlSelector = createSelector(socialSelector, state =>
  propOr('', 'vk', state)
)

export const fbUrlSelector = createSelector(socialSelector, state =>
  propOr('', 'fb', state)
)

export const instagramUrlSelector = createSelector(socialSelector, state =>
  propOr('', 'instagram', state)
)

export const apiAccessSelector = createSelector(
  state => state,
  state => propOr(false, 'apiAccess', state)
)

export const tempUserSelector = createSelector(
  state => state,
  state => propOr(false, 'isTempUser', state)
)

export const isTableViewSelector = createSelector(
  state => state,
  state => propOr(false, 'isTableView', state)
)

export const bookkeepingAccessSelector = createSelector(
  getAccessSelector,
  state => pathOr(false, ['BOOKKEEPING'], state)
)

export const contractorNameSelectedSelector = createSelector(
  state => prop('contractors', state),
  contractors =>
    compose(
      propOr('', 'TITLE'),
      findLast(propEq('SELECTED', true))
    )(contractors)
)

export const pretensionAccessSelector = createSelector(
  getAccessSelector,
  state => pathOr(false, ['PRETENSION'], state)
)

export const addAddressAccessSelector = createSelector(
  state => state,
  state => pathOr(false, ['accesses', 'ADD_ADDRESS'], state)
)

export const linkCabinet918Selector = createSelector(
  state => state,
  state => pathOr('', ['linkCabinet918'], state)
)

export const storesSelector = createSelector(
  state => state,
  state => pathOr([], ['stores'], state)
)

export const isStoresSelector = createSelector(
  storesSelector,
  stores => length(stores) > 1
)

export const searchAccessSelector = createSelector(
  state => state,
  state =>
    compose(
      ({ SORT, SEARCH }) => or(SORT, SEARCH),
      pathOr({}, ['accesses', 'MODERATOR'])
    )(state)
)

export const currentContractorIdSelector = createSelector(
  state => state,
  state => pathOr('', ['activeContractor'], state)
)

const contractorListSelector = createSelector(
  state => state,
  state => pathOr([], ['contractors'], state)
)

export const contractorsSelector = createSelector(
  contractorListSelector,
  state =>
    map(
      item => ({
        value: prop('ID', item),
        title: prop('TITLE', item),
        hint: prop('EDO', item) ? 'Подключен к ЭДО' : null
      }),
      state
    )
)

const currentContractorSelector = createSelector(
  currentContractorIdSelector,
  contractorListSelector,
  (id, contractors) => findLast(propEq('ID', id))(contractors)
)

export const currentContractorGuidSelector = createSelector(
  currentContractorSelector,
  currentContractor => prop('XML_ID', currentContractor)
)

export const currentContractorNameSelector = createSelector(
  currentContractorSelector,
  currentContractor => prop('TITLE', currentContractor)
)

export const moderationAccessesSelector = createSelector(
  state => state,
  state =>
    compose(renameKeys(toLower), pathOr({}, ['accesses', 'MODERATOR']))(state)
)

export const toContractorIdSelector = createSelector(
  state => state,
  state => prop('toContractorIdSelector', state)
)

export const isLoyaltyMember = createSelector(
  state => state,
  state => pathOr(false, ['status', 'MEMBER'], state)
)

export const isEdoSelector = createSelector(
  currentContractorSelector,
  contractor => propOr(false, 'EDO', contractor)
)

export const isPartnerSelector = createSelector(
  state => state,
  personal => propOr(false, 'ok918', personal)
)

export const scheduleAccessSelector = createSelector(
  state => state,
  access => propOr(false, 'isShowSchedule', access)
)

export const menuAccessSelector = createSelector(
  apiAccessSelector,
  discountsAccessSelector,
  tempUserSelector,
  bookkeepingAccessSelector,
  pretensionAccessSelector,
  searchAccessSelector,
  scheduleAccessSelector,
  (_, isOptClient) => isOptClient,
  (isApi, isDiscountsVisible, isTempUser, isBookkeeping, isPretension, isSearch, isShowSchedule, isOptClient) => filter(
    item => !!item,
    [
      isApi ? 'api' : false,
      isDiscountsVisible ? 'discounts' : false,
      isOptClient ? 'client' : false,
      isTempUser ? false : 'user',
      isBookkeeping ? 'bookkeeping' : false,
      isPretension ? 'pretension' : false,
      isSearch ? 'search' : false,
      isShowSchedule ? 'schedule' : false,
      config.formIo.isActive ? 'formio' : false
    ]
  )
)