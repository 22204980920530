import React, { PureComponent } from 'react'

import { object } from 'prop-types'
import { omit } from 'ramda'

const flattenInput = WrappedComponent => {
  class FlattenInput extends PureComponent {
    static displayName = `FlattenInput(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      input: object
    }

    render() {
      const { input = {}, ...rest } = this.props
      const spread = { ...input, ...omit(['meta'], rest) }
      return <WrappedComponent {...spread} />
    }
  }

  return FlattenInput
}

export default flattenInput
