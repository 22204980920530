import { pathOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsSending = state => state.isSending
export const getIsSent = state => state.isSent
export const getFilename = state => state.filename
export const getErrorMessage = state => state.errorMessage
export const getSuccessMessage = state => state.successMessage

export const typeModalSelector = createSelector(
  state => state,
  state => pathOr('', ['typeModal'], state)
)

export const changeStoreSelector = createSelector(
  state => state,
  state => pathOr({}, ['changeStore'], state)
)

export const uploadExcelSelector = createSelector(
  state => state,
  state => pathOr({}, ['uploadExcel'], state)
)
