const SCALE_10: number = 10
const roundBy = (
  num: number,
  decimalPlaces: number = 0,
  method: string = ''
): number => {
  const scale: number = SCALE_10 ** decimalPlaces

  if (method === 'ceil') {
    return Math.ceil(scale * num) / scale
  }

  if (method === 'floor') {
    return Math.floor(scale * num) / scale
  }

  return Math.round(scale * num) / scale
}

export default roundBy
