import { createAction, createReducer } from 'redux-act'

export { isLoadingSelector, isLoadedSelector } from './selector'

const initialState = {
  view: 'app',
  loaded: false
}

export const setParams = createAction('updateData/SET_PARAMS')
export const setLoaded = createAction('updateData/SET_LOADED')

const handleSetParams = (state, payload) => ({
  ...state,
  ...payload
})
const handleSetLoaded = (state, payload) => ({
  ...state,
  loaded: payload
})

const reducer = createReducer(on => {
  on(setParams, handleSetParams)
  on(setLoaded, handleSetLoaded)
}, initialState)

export default reducer
