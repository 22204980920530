/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useRef, useState } from 'react'

import cx from 'classnames'
import { array, func } from 'prop-types'
import { includes, prepend, prop, without } from 'ramda'
import { connect, useSelector } from 'react-redux'
import { useClickAway } from 'react-use'

import Icon from 'components/Icon/Icon'
import Portal from 'components/Portal'
import Scrollbar from 'components/Scrollbar'
import Wrapper from 'components/Wrapper'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import { isDesktopSelector } from 'redux/modules/device'
import { cartSelector, cartsSelector } from 'redux/modules/productList'

import s from './ProductActions.scss'

const HEIGHT = 120

const StoreCarts = ({ carts = [], cartsActive = [], setCartsActive, baskets }) => {
  const refButton = useRef(null)

  const refPopup = useRef(null)

  const refActions = useRef(null)

  const isDesktop = useSelector(({device}) => isDesktopSelector(device))

  const [isShow, setIsShow] = useState(false)

  const onHidePopup = useCallback(() => isShow && setIsShow(false), [isShow])

  const onShowPopup = useCallback(() => !isShow && setIsShow(true), [isShow])

  const Tag = isDesktop ? 'div' : DisableBodyScroll

  useEffect(() => {
    window.addEventListener('scroll', onHidePopup)
    window.addEventListener('resize', onHidePopup)

    return () => {
      window.removeEventListener('scroll', onHidePopup)
      window.removeEventListener('resize', onHidePopup)
    }
  }, [onHidePopup])

  useEffect(() => {
    const coords =
      refButton.current && refButton.current.getBoundingClientRect()
    if (refPopup.current && isDesktop) {
      refPopup.current.style.left = `${coords.left}px`
      refPopup.current.style.top = `${coords.bottom}px`
      refPopup.current.style.width = `${coords.width}px`
    }
  }, [isDesktop, isShow])

  useClickAway(isDesktop ? refPopup : refActions, onHidePopup)

  const onSetCarts = id => () => {
    if (includes(id, baskets)) {
      return true
    }
    if (includes(id, cartsActive)) {
      setCartsActive(without([id], cartsActive))
    } else {
      setCartsActive(prepend(id, cartsActive))
    }
    return true
  }

  return (
    <div className={s.cart}>
      <div
        role='presentation'
        onClick={onShowPopup}
        ref={refButton}
        className={cx(s.buttonCart, {
          [s.buttonCartActive]: isShow
        })}
      >
        <Icon icon='cart-change'
          className={s.iconCart} />
      </div>
      {isShow && (
        <Tag>
          <Portal>
            <div
              className={s.content}
              ref={refPopup}
            >
              <div className={s.actions}
                ref={refActions}>
                <Wrapper>
                  <div className={s.actionsHint}>Добавьте товар в корзину:</div>
                  <div className={s.actionsCarts}>
                    <Scrollbar maxHeight={HEIGHT}
                      minScrollHeight={15}>
                      {carts.map(item => {
                        let icon = 'checkbox'
                        if (includes(prop('ID', item), baskets)) {
                          icon = 'checkbox-gray'
                        } else if (includes(prop('ID', item), cartsActive)) {
                          icon = 'checkbox-checked'
                        }
                        return (
                          <div
                            role='presentation'
                            className={s.actionsCart}
                            key={prop('ID', item)}
                            onClick={onSetCarts(prop('ID', item))}
                          >
                            <Icon icon={icon} />
                            <div className={s.actionsCartName}>
                              {prop('TITLE', item)}
                            </div>
                          </div>
                        )
                      })}
                    </Scrollbar>
                  </div>
                </Wrapper>
              </div>
            </div>
          </Portal>
        </Tag>
      )}
    </div>
  )
}

StoreCarts.propTypes = {
  carts: array,
  baskets: array,
  cartsActive: array,
  setCartsActive: func
}

export default connect(
  ({ productList, settings }) => ({
    carts: cartsSelector(productList, settings),
    cart: cartSelector(productList)
  }),
  {}
)(StoreCarts)
