import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, func, object, string } from 'prop-types'
import { path, pathOr, prop } from 'ramda'

import Modal from 'containers/Modal'
import Button from 'UI/Button'
import Preloader from 'UI/Preloader'

import styles from './Notice.scss'

export default class NoticeConfirm extends PureComponent {
  static propTypes = {
    item: object,
    confirm: func,
    header: string,
    isSending: bool
  }

  static defaultProps = {
    confirm: () => {},
    item: {},
    isSending: false
  }

  handleConfirm = status => () => {
    const { item, confirm } = this.props
    const operations = prop('CODE', item)
    return confirm({ status, operations })
  }

  handleCloseAlert = () => {
    const { item } = this.props
    const failedCode = pathOr(false, ['FAILED', 'CODE'], item)
    if (failedCode) {
      this.handleConfirm(false)()
    }
  }

  render() {
    const { item, header, isSending } = this.props
    return (
      <Modal
        id='confirm_site'
        title={header}
        onClose={this.handleCloseAlert}
      >
        <>
          <Preloader active={isSending} />
          <div className={cx({ [styles.confirmContentHidden]: isSending })}>
            {prop('TITLE', item) && (
              <h2 className={styles.confirmTitle}>{prop('TITLE', item)}</h2>
            )}
            <div
              className={cx(styles.confirmText)}
              dangerouslySetInnerHTML={{ __html: prop('TEXT', item) }} // eslint-disable-line react/no-danger
            />
            <div className={styles.confirmControl}>
              {path(['BUTTON', 'CONFIRM', 'TITLE'], item) && (
                <Button
                  color='blue'
                  onClick={this.handleConfirm(true)}
                  stretched
                >
                  {path(['BUTTON', 'CONFIRM', 'TITLE'], item)}
                </Button>
              )}
              {path(['BUTTON', 'FAILED', 'TITLE'], item) && (
                <Button
                  color='whiteGray'
                  onClick={this.handleConfirm(false)}
                  stretched
                >
                  {path(['BUTTON', 'FAILED', 'TITLE'], item)}
                </Button>
              )}
            </div>
          </div>
        </>
      </Modal>
    )
  }
}
