/* eslint-disable max-len, no-useless-escape */

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]){6,}/
const phoneRegexp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){11,14}(\s*)?$/

const urlRegexp =
/(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,13}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/i

export const phoneNumberRegex = /^\+?(?<country>994|374|375|995|7|8|996|373|976|992|993|998|380)(?<operator>\d{3})(?<userGroup3>\d{3})(?<userGroup2>\d{1,})(?<userGroup1>\d{2,})$/
export const phoneCodeList = ['+994','+374','+375','+995','+7','+996','+373','+976','+992','+993','+998','+380'].map(code => ({ title: code, value: code }))

export const testEmail = string => emailRegex.test(string)
export const testPassword = string => passwordRegexp.test(string)
export const testPhone = string => phoneRegexp.test(string)
export const testUrl = string => urlRegexp.test(string)

export const errorTestPassword = `Пароль должен соответствовать правилам: <br />
<ul>
  <li>длиннее 6 символов</li>
  <li>состоять из букв латинского алфавита </li>
  <li>содержать хотя бы один символ в верхней/нижней раскладке </li>
  <li>содержать хотя бы одну цифру</li>
</ul>
`
export const errorTestEmail = 'Правильно введите электронную почту'
export const errorTestPhone = 'Правильно введите телефон'
