/* eslint-disable complexity */
import { pathOr, prop, path, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { clearCompare as productListClearCompare } from '../productList'
import {
  fetchSuccess as productsFetchSuccess,
  setCount as setCountInProducts
} from '../products'

export { getIsLoading, getIsLoaded, compareCount } from './selector'

const DEFAULT_CHECKED_PROPS = ['PRICE', 'TM', 'RULER', 'COUNTRY']
const ITEMS_PER_PAGE = 16

const initialState = {
  isLoading: false,
  items: [],
  totalCount: 0,
  sections: [],
  properties: [],
  sectionId: null,
  checkedProperties: DEFAULT_CHECKED_PROPS,
  onlyDifference: false
}

const processProperties = properties => [
  {
    CODE: 'PRICE',
    TITLE: 'Цена'
  },
  ...properties
]

const sortCheckedProperties = (checkedProperies, properties) =>
  checkedProperies.sort((a, b) => {
    const value1 = properties.findIndex(item => item.CODE === a)
    const value2 = properties.findIndex(item => item.CODE === b)
    return value1 - value2
  })

export const fetch = createAction('compare/FETCH')
export const fetchSuccess = createAction('compare/FETCH_SUCCESS')
const fetchFailure = createAction('compare/FETCH_FAILURE')
export const fetchCountSuccess = createAction('compare/FETCH_COUNT_SUCCESS')

export const fetchCount = createAction('compare/FETCH_COUNT')

export const updateItems = createAction('compare/REMOVE_ITEMS')

export const resetCheckedProperties = createAction(
  'compare/RESET_CHECKED_PROPERTIES'
)
export const setCheckedProperties = createAction(
  'compare/SET_CHECKED_PROPERTIES'
)
export const setOnlyDifference = createAction('compare/SET_ONLY_DIFFERENCE')

export const setSection = createAction('compare/SET_SECTION')

export const clearCompare = createAction('compare/CLEAR_COMPARE')
export const clearCompareSuccess = createAction('compare/CLEAR_COMPARE_SUCCESS')
const clearCompareFailure = createAction('compare/CLEAR_COMPARE_FAILURE')

export const setCount = createAction('compare/SET_COUNT')

const request =
  ({ clientApi }, params) =>
    () =>
      clientApi
        .get('/v3/personal/compare/catalog/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            offset: params.offset,
            section_id: params.sectionId,
            limit: ITEMS_PER_PAGE
          }
        })
        .then(result => fetchSuccess({ result, ...params }))
        .catch(fetchFailure)

const requestClear =
  ({ clientApi }) =>
    () =>
      clientApi
        .post('/v3/personal/compare/delete/')
        .then(clearCompareSuccess)
        .catch(clearCompareFailure)

const handleFetch = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true
    },
    Effects.promise(request({ clientApi }, params))
  )

const handleFetchSuccess = (
  state,
  { result, sectionId, offset, direction, position }
) => {
  const effects = []
  const data = pathOr({}, ['data', 'response'], result)
  const items = path(['ITEMS'], data)
  const count = items
    ? pathOr(state.totalCount, ['NAV', 'CNT'], data) // if fetch items
    : propOr(0, 'CNT', data) // if action add/delete
  if (items) {
    effects.push(Effects.call(productsFetchSuccess, { dataPayload: result }))
  }

  return loop(
    {
      ...state,
      sectionId: count > 0 ? sectionId : null,
      offset: offset || state.offset,
      direction,
      position:
        position !== undefined ? position - (offset - state.offset) : position,
      isLoading: false,
      items,
      sections: count > 0 ? propOr(state.sections, 'SECTIONS', data) : [],
      properties: prop('AGGREGATIONS', data)
        ? processProperties(prop('AGGREGATIONS', data))
        : state.properties,
      totalCount: sectionId ? state.totalCount : count,
      isLoaded: true
    },
    Effects.batch(effects)
  )
}

const handleFetchFailure = (state, { data }) => ({
  ...state,
  isLoading: false,
  error: data
})

const handleFetchCountSuccess = (state, payload) => ({
  ...state,
  totalCount: pathOr(
    state.totalCount,
    ['data', 'response', 'NAV', 'COMPARE', 'CNT'],
    payload
  )
})

const handleUpdateItems = state => {
  const { offset, sectionId } = state
  return loop(
    state,
    Effects.call(fetch, { offset, sectionId, updateProducts: true })
  )
}

const handleResetCheckedProperties = state => ({
  ...state,
  properties: [...state.properties],
  checkedProperties: DEFAULT_CHECKED_PROPS
})

const handleSetCheckedProperties = (state, checkedProperties) => ({
  ...state,
  checkedProperties: sortCheckedProperties(checkedProperties, state.properties)
})

const handleSetOnlyDifference = (state, onlyDifference) => ({
  ...state,
  onlyDifference
})

const handleSetSection = (state, sectionId) =>
  loop(
    {
      ...state
    },
    Effects.call(fetch, { sectionId, offset: 0 })
  )

const handleClearCompare = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true
    },
    Effects.promise(requestClear({ clientApi }))
  )

const handleClearCompareSuccess = state =>
  loop(
    {
      ...state,
      isLoading: false,
      sections: [],
      sectionId: null,
      properties: [],
      items: [],
      totalCount: 0
    },
    Effects.call(productListClearCompare)
  )

const handleClearCompareFailure = (state, { data }) => ({
  ...state,
  isLoading: false,
  error: data
})

const handleSetCount = (state, { id, amount }) =>
  loop(
    {
      ...state,
      items: state.items.map(item =>
        item.ID === id ? { ...item, amount } : item
      )
    },
    Effects.call(setCountInProducts, { id, amount })
  )

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchCountSuccess, handleFetchCountSuccess)
  on(updateItems, handleUpdateItems)
  on(resetCheckedProperties, handleResetCheckedProperties)
  on(setCheckedProperties, handleSetCheckedProperties)
  on(setOnlyDifference, handleSetOnlyDifference)
  on(setSection, handleSetSection)
  on(clearCompare, handleClearCompare)
  on(clearCompareSuccess, handleClearCompareSuccess)
  on(clearCompareFailure, handleClearCompareFailure)
  on(setCount, handleSetCount)
}, initialState)

export default reducer
