/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'
import R from 'ramda'

import Icon from 'components/Icon'
import formatAmount from 'utils/formatAmount'

import styles from './ProductBalance.scss'

const stockTooltip = ({ MAIN = {}, CENTRAL = {} }) => {
  const mainTitle = MAIN.TITLE || ''
  const delimetr = CENTRAL.TITLE ? '/' : ''
  const centralTitle = CENTRAL.TITLE || ''
  return `${mainTitle} ${delimetr} ${centralTitle}`
}

export default class ProductBalance extends PureComponent {
  static propTypes = {
    isLoyalty: pt.bool,
    stock: pt.object,
    remains: pt.number,
    mini: pt.bool,
    onlyText: pt.bool,
    mode: pt.oneOf(['Base', 'Basket', 'ProductOne'])
  }

  static defaultProps = {
    mode: 'Base'
  }

  getMeta = (isRemainsInvalid, onlyText, isLoyalty) => {
    let state = ''

    if (onlyText) state = 'InStock'
    if (isRemainsInvalid) state = 'OutOfStock'
    if (isLoyalty) state = 'PreOrder'

    return (
      <meta itemProp='availability'
        content={`http://schema.org/${state}`} />
    )
  }

  renderStockAmount = () => {
    const { stock, mini } = this.props
    if (!stock || !stock.CENTRAL || !mini) return null

    const { VALUE } = stock.CENTRAL
    const amount = formatAmount(VALUE)
    const parsedValue = Number(VALUE)

    return (
      <span
        className={cx({
          [styles.stockAmountOutOfBase]: parsedValue <= 0,
          [styles.stockAmountOther]: parsedValue > 0
        })}
      >
        {parsedValue > 0 ? `${amount} шт.` : 'Отсутствует'}
      </span>
    )
  }

  render() {
    const { mini, onlyText, mode, stock, isLoyalty } = this.props
    const mainStockData = R.path(['MAIN'], stock)
    const otherStockData = R.path(['CENTRAL'], stock)
    const remains = mainStockData ? mainStockData.VALUE : this.props.remains
    const isRemainsInvalid =
      remains <= 0 || remains === null || Number.isNaN(Number(remains))
    const isEmpty = isLoyalty && mode === 'ProductOne' && isRemainsInvalid

    let toolTipText
    let mainStockAmount
    let textClassName = styles[`restAmount${mode}`]

    if (isRemainsInvalid) {
      textClassName = cx(textClassName, styles.restAmountBase_state_null, {
        [styles.restAmountBasket_state_null]: mode === 'Basket'
      })
      mainStockAmount = 'Отсутствует'
      if (isLoyalty) {
        mainStockAmount = 'Под заказ'
        toolTipText = 'Доставка подарка займет чуть \nбольше времени'
      }
    } else {
      if (isLoyalty) {
        // eslint-disable-next-line max-len
        toolTipText =
          'Подарок будет доставлен с центрального \nсклада. Для уточнения ' +
          'сроков доставки \nобратитесь к вашему менеджеру'
      }
      if (onlyText) {
        mainStockAmount = 'В наличии'
      } else {
        mainStockAmount = `${formatAmount(remains)} шт.`
        if (!mini) mainStockAmount = `В наличии: ${mainStockAmount}`
      }
    }

    if (!!mainStockData && !!otherStockData && !isLoyalty) {
      toolTipText = stockTooltip(stock)
    }
    const mainTitleText = !isLoyalty ? toolTipText : ''

    return (
      <div className={styles.wrapper}>
        {this.getMeta(isRemainsInvalid, onlyText, isLoyalty)}
        <div
          className={cx(styles.productBalance, {
            [styles.productBalance_emptyLoyalty]: isEmpty
          })}
          title={mainTitleText}
        >
          <div
            className={cx(styles[`icon${mode}`], {
              [styles[`icon${mode}_null`]]: isRemainsInvalid
            })}
          />
          <span className={textClassName}
            itemProp='offerCount'>
            {mainStockAmount}
            {otherStockData && !isLoyalty && (
              <span className={styles.amountSeparator}> / </span>
            )}
            {!isLoyalty && this.renderStockAmount()}
          </span>
        </div>
        {isLoyalty && (
          <div className={styles.iconInfo}
            title={toolTipText}>
            <Icon
              className={styles.formIcon}
              icon={isRemainsInvalid ? 'gift' : 'car'}
            />
          </div>
        )}
      </div>
    )
  }
}
