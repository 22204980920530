/* Этот метод работает путём вычисления разницы между шириной элемента 
включая скроллбар 1 и шириной содержимого элемента 2. 
Разница между этими двумя значениями даст ширину скроллбара. 
*/
export const getScrollbarWidth = () => {
  // Создаём новый элемент div
  const div = document.createElement('div')

  // Задаём стили для div, чтобы он был достаточно большим и вызвал появление скроллбара
  div.style.overflow = 'scroll'
  div.style.visibility = 'hidden'
  div.style.position = 'absolute'
  div.style.width = '100px'
  div.style.height = '100px'

  // Добавляем div в DOM (например, в body)
  document.body.appendChild(div)

  // Вычисляем ширину скроллбара
  const scrollbarWidth = div.offsetWidth - div.clientWidth

  // Удаляем div из DOM
  document.body.removeChild(div)

  // Возвращаем ширину скроллбара
  return scrollbarWidth
}

// Использование функции
// const scrollbarWidth = getScrollbarWidth();
// console.log(`Текущая ширина скроллбара: ${scrollbarWidth}px`);
