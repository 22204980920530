/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { push } from 'connected-react-router'
import { number, string, bool, object, func } from 'prop-types'
import { prop, propOr, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import CurrencyValue from 'components/CurrencyValue'
import styles from 'components/Header/Header.scss'
import HeaderCart from 'components/Header/HeaderCart'
import HeaderGift from 'components/Header/HeaderGift'
import Icon from 'components/Icon'
import Interactions from 'components/Interactions'
import Link from 'components/Link'
import WrapperBasket from 'components/WrapperBasket'
import CartInfo from 'containers/Cart/CartInfo'
import CartName from 'containers/Cart/CartName'
import { forClientRendering } from 'decorators/window'
import { basketSelector, isRefetchSelector } from 'redux/modules/basket'
import { isDesktopSelector } from 'redux/modules/device'
import {
  basketsLoadingSelector,
  fetchBaskets as fetchBasketsStore
} from 'redux/modules/personal'
import { cartSelector, getBasketParams } from 'redux/modules/productList'
import {
  bookmarkSelector,
  isBookmarkSelector,
  isLoyaltySelector,
  pageLoadedSelector
} from 'redux/modules/settings'

const loyaltyStatusSelector = createSelector(
  state => state,
  state => propOr({}, 'status', state)
)

const TIMEOUT = 100

@connect(
  ({
    basket,
    personal,
    settings,
    loyalty,
    theme: { theme },
    productList,
    reduxAsyncConnect,
    device
  }) => {
    const mode = prop('mode', settings)
    const isBookmark = isBookmarkSelector(settings)
    const isLoyalty = isLoyaltySelector(settings)
    let basketId = cartSelector(productList)
    let catalog
    switch (mode) {
      case 'loyalty':
        catalog = 'loyalty'
        basketId = 0
        break
      case 'bookmark':
        catalog = 'bookmark'
        basketId = bookmarkSelector(settings)
        break
      default:
        catalog = 'main'
        break
    }
    const { cnt, sum, payments, title } = basketSelector({
      basket,
      basketType: catalog,
      basketId
    })

    const sumRouble = pathOr(0, ['CASHLESS', 'SUM'], payments)

    return {
      basketTitle: title,
      itemsCount: cnt,
      itemsSum: isLoyalty ? sum : sumRouble,
      mode,
      catalog,
      loyaltyStatus: loyaltyStatusSelector(loyalty),
      bookmark: basketId,
      isBookmark,
      isLoyalty,
      isRefetch: isRefetchSelector(basket),
      isBasketsLoading: basketsLoadingSelector(personal, 'count'),
      basketParams: getBasketParams(productList),
      isPageLoaded: pageLoadedSelector(reduxAsyncConnect),
      theme,
      isDesktop: isDesktopSelector(device)
    }
  },
  {
    push,
    fetchBaskets: fetchBasketsStore
  }
)
export default class HeaderBasket extends PureComponent {
  static propTypes = {
    itemsCount: number,
    itemsSum: number,
    basketTitle: string,
    mode: string,
    catalog: string,
    loyaltyStatus: object,
    basketParams: object,
    bookmark: number,
    isBookmark: bool,
    isLoyalty: bool,
    isBasketsLoading: bool,
    isPageLoaded: bool,
    isFixed: bool,
    isRefetch: bool,
    isMiniBasketVisible: bool,
    isDesktop: bool,
    theme: string,
    onDelayedMouseEnter: func,
    onGetThemeIcon: func,
    fetchBaskets: func,
    onHideMiniBasket: func
  }

  static defaultProps = {
    bookmark: 0,
    basketTitle: '',
    isLoyalty: false,
    isBasketsLoading: true,
    isPageLoaded: false,
    isBookmark: false,
    isFixed: false,
    isRefetch: false,
    isMiniBasketVisible: false,
    onDelayedMouseEnter: () => {},
    onHideMiniBasket: () => {}
  }

  componentDidMount() {
    this.onFetchData('mini')
  }

  componentDidUpdate(prevProps) {
    if (
      (this.props.catalog && prevProps.catalog !== this.props.catalog) ||
      (this.props.isRefetch && prevProps.isRefetch !== this.props.isRefetch)
    ) {
      this.onFetchData('mini')
    }
  }

  onFetchData = params => this.fetchData(params)

  @forClientRendering
  fetchData(type = 'mini') {
    const { catalog, bookmark, isBookmark, basketParams, isPageLoaded } =
      this.props
    if (isPageLoaded) {
      const id = isBookmark ? [catalog, bookmark] : catalog
      const params = {
        params: { catalog, bookmark, type: 'basket', id },
        urlParams: {
          catalog,
          value: isBookmark ? bookmark : 0,
          ...basketParams
        },
        type
      }
      this.props.fetchBaskets(params)
    } else {
      setTimeout(() => this.fetchData(type), TIMEOUT)
    }
  }

  render() {
    const {
      basketTitle,
      itemsCount,
      itemsSum,
      mode,
      loyaltyStatus,
      bookmark,
      isLoyalty,
      isBookmark,
      isBasketsLoading,
      theme,
      isFixed,
      isMiniBasketVisible,
      onDelayedMouseEnter,
      onHideMiniBasket,
      onGetThemeIcon,
      catalog,
      isDesktop
    } = this.props

    let to = `/basket/main/${bookmark}`

    if (isLoyalty) {
      to = '/loyalty/basket'
    }

    if (isBookmark) {
      to = `/basket/bookmark/${bookmark}`
    }

    return (
      <div
        className={cx({
          [styles.fixedBasket]: isFixed,
          [styles.fixedBasketLoyalty]: isFixed && isLoyalty,
          [styles.fixedBasketShow]: isFixed
        })}
      >
        <Interactions
          onDelayedMouseEnter={onDelayedMouseEnter}
          onDelayedMouseLeave={onHideMiniBasket}
          className={styles.interactions}
        >
          {isFixed ? (
            <Link to={to}>
              <div className={styles.cartFixed}>
                <div className={styles.cartFixedCount}>
                  <span className={styles.fixedCount}>{itemsCount}</span>
                  <Icon
                    className={styles.fixedBasketIcon}
                    icon={onGetThemeIcon('cart')}
                    size='auto'
                  />
                </div>
                <div className={styles.currency}>
                  <CartName cart={bookmark} />
                  <CurrencyValue
                    className={styles.currencySum}
                    price={itemsSum}
                    isPoints={isLoyalty}
                    showZero
                  />
                </div>
              </div>
            </Link>
          ) : (
            <Link to={to}>
              <div className={styles.cartLink}>
                {isLoyalty ? (
                  <HeaderGift
                    mode={mode}
                    itemsCount={itemsCount}
                    itemsSum={itemsSum}
                    status={loyaltyStatus}
                    bookmark={bookmark}
                    theme={theme}
                    isDesktop={isDesktop}
                  />
                ) : (
                  <HeaderCart
                    basketTitle={basketTitle}
                    itemsCount={itemsCount}
                    itemsSum={itemsSum}
                    theme={theme}
                    isLoading={isBasketsLoading}
                    isDesktop={isDesktop}
                  />
                )}
              </div>
            </Link>
          )}

          {isMiniBasketVisible && (
            <WrapperBasket
              isLoyalty={isLoyalty}
              cartLink={to}
              itemsCount={itemsCount}
              itemsSum={itemsSum}
              catalog={catalog}
              isFixed={isFixed}
              fetchData={this.onFetchData}
            />
          )}
        </Interactions>
        {!isLoyalty && (
          <CartInfo
            isFixed={isFixed}
            catalog={catalog}
            isShowMove={isMiniBasketVisible}
          />
        )}
      </div>
    )
  }
}
