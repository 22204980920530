import React from 'react'

import { useSelector } from 'react-redux'

import { isAuthSelector } from 'redux/modules/token'
/**
 *
 * Как использовать декоратор
 * 1.forAuth(Component)
 * 2.compose(connect(...),...,forAuth)(Component)
 * 3.@forAuth
 *   export default Component = (...) => ...
 */
const forAuth = WrapperComponent => props => {
  const isAuth = useSelector(({ token }) => isAuthSelector(token))
  if (isAuth) {
    return <WrapperComponent {...props} />
  }
  return null
}

const forUnauth = WrapperComponent => props => {
  const isAuth = useSelector(({ token }) => isAuthSelector(token))
  if (!isAuth) {
    return <WrapperComponent {...props} />
  }
  return null
}

export { forAuth, forUnauth }
