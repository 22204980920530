/* eslint-disable complexity */
import { path, prop, propOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import {
  fetchAggregation,
  fetchFailure,
  fetchProductUpdateSuccess,
  fetchCategoryAdd
} from 'redux/modules/claimEdit'

export { getIsLoading } from './selector'

const initialState = {
  isLoading: false
}

export const fetchImageAdd = createAction('claimImages/FETCH_iMAGE_ADD')
export const fetchImageDelete = createAction('claimImages/FETCH_iMAGE_DELETE')
const fetchCategoryImageSuccess = createAction(
  'claimImages/FETCH_CATEGORY_iMAGE_SUCCESS'
)
const fetchProductImageSuccess = createAction(
  'claimImages/FETCH_PRODUCT_IMAGE_SUCCESS'
)

const fetchImageFailure = createAction('claimImages/FETCH_iMAGE_FAILURE')

const requestImageAdd =
  ({ clientApi }) =>
    params => {
      const category = prop('category', params)
      const id = prop('id', params)
      const productId = prop('productId', params)
      const method = productId
        ? `/product/${category}/${productId}/`
        : `/category/${category}/`
      const images = propOr([], 'images', params)
      const onSuccess = productId
        ? fetchProductImageSuccess
        : fetchCategoryImageSuccess
      const isPreview = prop('isPreview', params)

      return clientApi
        .post(
          `/v3/contractor/pretension/${id}/image/add/${category ? method : ''}`,
          {
            params: {
              name: prop('name', params),
              base64: prop('base64', params),
              contractor_id: clientApi.getContractorId()
            }
          }
        )
        .then(res =>
          onSuccess({ category, id, productId, images, isPreview, ...res })
        )
        .catch(fetchImageFailure)
    }

const requestImageDelete =
  ({ clientApi }) =>
    params => {
      const category = prop('category', params)
      const id = prop('id', params)
      const productId = prop('productId', params)
      const imageId = prop('imageId', params)
      const mainImage = imageId ? `${imageId}/` : ''
      const categoryImage = `category/${
        imageId ? `${category}/${imageId}` : category
      }/`
      const productImage = `product/${category}/${
        imageId ? `${productId}/${imageId}` : productId
      }/`
      const images = propOr([], 'images', params)
      const isPreview = prop('isPreview', params)
      const onSuccess = productId
        ? fetchProductImageSuccess
        : fetchCategoryImageSuccess
      const method = () => {
        if (productId) return productImage
        if (category) return categoryImage
        return mainImage
      }
      return clientApi
        .post(`/v3/contractor/pretension/${id}/image/delete/${method()}`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(res =>
          onSuccess({
            category,
            id,
            productId,
            images,
            imageId,
            isPreview,
            ...res
          })
        )
        .catch(fetchImageFailure)
    }

const handleFetchImageAdd = (state, params, { clientApi }) => {
  const request = requestImageAdd({ clientApi })

  return loop(
    {
      ...state,
      isLoading: true
    },
    prop('newCategory', params)
      ? Effects.call(fetchCategoryAdd, {
        ...params,
        callback: Effects.promise(request, params),
        onError: fetchImageFailure
      })
      : Effects.promise(request, params)
  )
}

const handleFetchImageDelete = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true
    },
    Effects.promise(requestImageDelete({ clientApi }), params)
  )

const handleFetchCategoryImageSuccess = (state, payload) => {
  const isSuccess = path(['data', 'response', 'SUCCESS'], payload) === 'Y'
  const params = {
    id: prop('id', payload)
  }

  if (prop('isPreview', payload)) params.category = prop('category', payload)

  return loop(
    {
      ...state,
      isLoading: false
    },
    isSuccess
      ? Effects.call(fetchAggregation, params)
      : Effects.call(fetchFailure, payload)
  )
}

const handleFetchProductImageSuccess = (state, payload) => {
  const newData = {}
  const image = path(['data', 'response', 'IMAGE'], payload)
  const images = propOr([], 'images', payload)
  const imageId = prop('imageId', payload)

  if (image) newData.images = [...images, image]
  else
    newData.images = imageId
      ? images.filter(item => prop('ID', item) !== imageId)
      : []

  return loop(
    {
      ...state,
      isLoading: false
    },
    Effects.call(fetchProductUpdateSuccess, { ...payload, newData })
  )
}

const handleFetchImageFailure = (state, payload) =>
  loop(
    {
      ...state,
      isLoading: false
    },
    Effects.call(fetchFailure, payload)
  )

export default createReducer(on => {
  on(fetchImageAdd, handleFetchImageAdd)
  on(fetchImageDelete, handleFetchImageDelete)
  on(fetchCategoryImageSuccess, handleFetchCategoryImageSuccess)
  on(fetchImageFailure, handleFetchImageFailure)
  on(fetchProductImageSuccess, handleFetchProductImageSuccess)
}, initialState)
