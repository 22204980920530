/* eslint-disable complexity */
import axios from 'axios'
import qs from 'qs'
import { compose, filter, keys, map, path, once, includes, has } from 'ramda'

import config from 'config'
import { init } from 'helpers/authNew'
import { initContractor } from 'helpers/contractorNew'
import { setToken, checkToken } from 'redux/modules/token'

const STATUS_401 = 401

const clearBitrixCookie = ({ cookie }) => {
  const cookiesObject = cookie.select()
  compose(
    map(keyName => cookie.remove(keyName, { path: '/' })),
    filter(
      keyName =>
        keyName.indexOf('BITRIX') !== -1 ||
        keyName.indexOf('BX') !== -1 ||
        keyName.indexOf('PHP') !== -1
    ),
    keys
  )(cookiesObject)
}

export const initClientApi = ({ cookie, apiAuth, router }) => {
  const contractorUtils = initContractor({ cookie })
  const {
    getTokenBefore,
    clearToken,
    isTokenExpired,
    getTokenFromCookies,
    getTokenByPassword,
    getNewToken,
    saveToCookies,
    saveToAdminCookies,
    getIsAdminCookie,
    getAdminTokenFromCookies,
    clearAdminToken
  } = init({ cookie, apiAuth, router })
  clearBitrixCookie({ cookie })
  const toolbox = {}
  const methods = ['get', 'put', 'post', 'patch', 'delete']

  const dispatchTokens = tokens => {
    const {
      store: { dispatch }
    } = toolbox

    return dispatch(setToken(tokens.isUserWithoutPassword))
  }

  const isTokenValid = once(() => {
    const { store } = toolbox
    if (isTokenExpired()) {
      return Promise.resolve()
    }
    const tokens = getTokenFromCookies()
    return store.dispatch(checkToken(tokens.accessToken)).then(() => {
      if (store.getState().token.isInvalid) {
        clearToken()
      }
      return true
    })
  })

  const formatParams = ({ type, url, options, accessToken, requestParams }) => {
    const { params = {}, ...rest } = options
    const paramsWithBaseUrl = {
      baseURL: SERVER ? config.apiServer : undefined,
      ...rest
    }

    if (type === 'get') {
      return {
        url,
        timeout: 30000,
        params: {
          ...paramsWithBaseUrl,
          params: {
            ...params,
            ...requestParams
          },
          headers: {
            Authorization: `Bearer ${params.accessToken || accessToken}`
          }
        },
        options: undefined
      }
    }
    const delimiter = /\?/g.test(url) ? '&' : '?'
    // eslint-disable-next-line no-undef
    if (
      params instanceof FormData ||
      path(['headers', 'Content-type']) === 'text/plain'
    ) {
      return {
        url: `${url}${delimiter}${qs.stringify({
          ...params,
          ...requestParams
        })}`,
        timeout: 30000,
        params,
        options: {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Bearer ${params.accessToken || accessToken}`
          },
          ...paramsWithBaseUrl
        }
      }
    }

    if (has('base64', params)) {
      const { base64, ...queryParams } = params
      return {
        url: `${url}${delimiter}${qs.stringify(queryParams)}`,
        timeout: 30000,
        params: base64,
        options: {
          headers: {
            'Content-Type': 'text/plain',
            Authorization: `Bearer ${params.accessToken || accessToken}`
          },
          ...paramsWithBaseUrl
        }
      }
    }

    return {
      url,
      timeout: 30000,
      params: qs.stringify({ ...params }),
      options: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${params.accessToken || accessToken}`
        },
        ...paramsWithBaseUrl
      }
    }
  }

  const getTimestamp = () => Date.now()

  toolbox.requestInfoStore = {}
  toolbox.requestId = 0
  toolbox.beginTimestamp = getTimestamp()
  toolbox.isLoading = false

  const request = ({ type, url, options, accessToken }) => {
    const params = formatParams({
      type,
      url,
      options,
      accessToken
    })
    const requestPromise = axios[type](
      params.url,
      params.params,
      params.options
    )

    requestPromise.catch(error => {
      const status = path(['response', 'status'], error)
      const errorDetail = path(
        ['response', 'data', 'meta', 'errorDetail'],
        error
      )
      const errorType = path(['response', 'data', 'meta', 'errorType'], error)
      if (status === STATUS_401 && includes('expired', errorDetail)) {
        clearToken()
      }
      if (
        status === STATUS_401 &&
        includes(errorType, ['invalid_token', 'token_warning'])
      ) {
        getNewToken()
      }
    })

    return requestPromise
  }

  const delayRequest =
    ({ resolve, reject, type, url, p1 = {} }) =>
      ({ accessToken }) => {
        request({
          url,
          type,
          options: p1,
          accessToken
        })
          .then(resolve)
          .catch(reject)
      }

  const resolveQueue = (queue, { accessToken }) => {
    if (queue.isLoading) {
      return
    }

    queue.stack.forEach(fn =>
      fn({
        accessToken
      })
    )

    queue.stack = []
  }

  const performRequestWithToken = (queue, type) => (url, p1) =>
    new Promise((resolve, reject) => {
      queue.stack.push(
        delayRequest({
          resolve,
          reject,
          type,
          url,
          p1
        })
      )

      if (queue.isLoading) {
        return
      }

      queue.isLoading = true

      getTokenBefore(toolbox.store)
        .then(tokens => {

          queue.isLoading = false

          resolveQueue(queue, {
            accessToken: tokens.accessToken
          })
        })
        .catch(reject)
    })

  const getTokenFirstTime = () =>
    isTokenValid().then(getTokenBefore).then(dispatchTokens)

  const apiClientToken = () => {
    const queue = {
      stack: [],
      isLoading: false
    }

    return {
      ...methods.reduce(
        (acc, methodName) => ({
          ...acc,
          [methodName]: performRequestWithToken(queue, methodName)
        }),
        {}
      ),
      setStore: store => {
        toolbox.store = store
      },
      ...contractorUtils,
      getTokenByPassword,
      clearToken,
      getTokenFromCookies,
      saveToCookies,
      saveToAdminCookies,
      getIsAdminCookie,
      clearAdminToken,
      getAdminTokenFromCookies,
      getNewToken,
      getTokenFirstTime,
      getTokenBefore
    }
  }
  return apiClientToken()
}
