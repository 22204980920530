/* eslint-disable complexity */
/* eslint-disable max-len */
import {
  propOr,
  pathOr,
  path,
  map,
  prop,
  length,
  filter,
  values,
  prepend,
  compose,
  reduce,
  reverse,
  head,
  flatten,
  sortWith,
  ascend,
  takeWhile,
  propEq,
  add
} from 'ramda'
import { createSelector } from 'reselect'

import moment from 'utils/moment'
import noun from 'utils/noun'

const secInHour = 3600
const hourInDay = 24
const getDate = sec => Math.round(sec / secInHour / hourInDay)
const NUMBER_100 = 100

export const getPaymentsAgg = _payments =>
  reduce(
    (acc, item) => ({
      sum:
        propOr(0, 'sum', acc) +
        propOr([], 'payments', item).reduce(
          (ai, i) => ai + propOr(0, 'sum', i),
          0
        ),
      pay:
        propOr(0, 'pay', acc) +
        propOr([], 'payments', item).reduce(
          (ai, i) => ai + propOr(0, 'pay', i),
          0
        ),
      debt:
        propOr(0, 'debt', acc) +
        propOr([], 'payments', item).reduce(
          (ai, i) => ai + propOr(0, 'debt', i),
          0
        ),
      sumMain:
        propOr(0, 'sumMain', acc) +
        (propOr('main', 'type', item) === 'main'
          ? propOr([], 'payments', item).reduce(
            (ai, i) => ai + propOr(0, 'sum', i),
            0
          )
          : 0),
      payMain:
        propOr(0, 'payMain', acc) +
        (propOr('main', 'type', item) === 'main'
          ? propOr([], 'payments', item).reduce(
            (ai, i) => ai + propOr(0, 'pay', i),
            0
          )
          : 0),
      debtMain:
        propOr(0, 'debtMain', acc) +
        (propOr('main', 'type', item) === 'main'
          ? propOr([], 'payments', item).reduce(
            (ai, i) => ai + propOr(0, 'debt', i),
            0
          )
          : 0),
      sumBookmark:
        propOr(0, 'sumBookmark', acc) +
        (propOr('main', 'type', item) === 'bookmark'
          ? propOr([], 'payments', item).reduce(
            (ai, i) => ai + propOr(0, 'sum', i),
            0
          )
          : 0),
      payBookmark:
        propOr(0, 'payBookmark', acc) +
        (propOr('main', 'type', item) === 'bookmark'
          ? propOr([], 'payments', item).reduce(
            (ai, i) => ai + propOr(0, 'pay', i),
            0
          )
          : 0),
      debtBookmark:
        propOr(0, 'debtBookmark', acc) +
        (propOr('main', 'type', item) === 'bookmark'
          ? propOr([], 'payments', item).reduce(
            (ai, i) => ai + propOr(0, 'debt', i),
            0
          )
          : 0)
    }),
    {
      sum: 0,
      pay: 0,
      debt: 0,
      sumMain: 0,
      payMain: 0,
      debtMain: 0,
      sumBookmark: 0,
      payBookmark: 0,
      debtBookmark: 0
    },
    _payments
  )

export const getFlatPaymentsAgg = _payments =>
  reduce(
    (acc, item) => ({
      sum: propOr(0, 'sum', acc) + propOr(0, 'sum', item),
      pay: propOr(0, 'pay', acc) + propOr(0, 'pay', item),
      debt: propOr(0, 'debt', acc) + propOr(0, 'debt', item)
    }),
    {
      sum: 0,
      pay: 0,
      debt: 0
    },
    _payments
  )

const entitiesSelector = createSelector(
  state => state,
  state => propOr({}, 'entities', state)
)

const contractorsNameSelector = createSelector(entitiesSelector, state =>
  propOr({}, 'CONTRACTORS', state)
)

export const paymentsSelector = createSelector(
  state => prop('payments', state),
  contractorsNameSelector,
  (items, contractors) => map(item => ({
    contractorName: pathOr('', [pathOr('', ['CONTRACTOR'], item), 'TITLE'], contractors),
    contractor: pathOr('', ['CONTRACTOR'], item),
    num: pathOr('', ['BILL', 'TITLE'], item),
    type: path(['BILL', 'BOOKMARK'], item) ? 'bookmark' : 'main',
    bookmark: pathOr('', ['BILL', 'BOOKMARK'], item),
    dateShipment: propOr('', 'DATE', item),
    payments: prop('PAYMENTS', item).map(i => ({
      datePay: moment(propOr('', 'DATE', i), 'X').endOf('day').unix(),
      delay: propOr(0, 'SUM', i) - propOr(0, 'PAID', i) > 0 ? getDate(
        moment().startOf('day').unix() - moment(propOr(0, 'DATE', i), 'X').startOf('day').unix()
      ) : 0,
      sum: propOr(0, 'SUM', i),
      pay: propOr(0, 'PAID', i),
      debt: propOr(0, 'SUM', i) - propOr(0, 'PAID', i)
    }))
  }), items)
)

export const sumDelaySelector = createSelector(paymentsSelector, items =>
  reduce(
    (acc, item) =>
      acc +
      compose(
        reduce(
          (accPayment, itemPayment) =>
            accPayment +
            (propOr(0, 'delay', itemPayment) > 0
              ? propOr(0, 'debt', itemPayment)
              : 0),
          0
        ),
        propOr([], 'payments')
      )(item),
    0,
    items
  )
)

export const dateUpdateSelector = createSelector(
  state => state,
  state => prop('dateUpdate', state)
)

export const messageSelector = createSelector(
  state => state,
  state => prop('message', state)
)

const conditionSelector = createSelector(
  state => state,
  state => propOr({}, 'condition', state)
)

export const basketSelector = createSelector(
  conditionSelector,
  paymentsSelector,
  (condition, payments) => {
    // отсрочка считается на фронте
    const paymentsAgg = getPaymentsAgg(payments)
    const paymentsAggDelay = getPaymentsAgg(
      payments.map(item => ({
        ...item,
        payments: propOr([], 'payments', item).filter(i => prop('delay', i) > 0)
      }))
    )
    return {
      title: `Лимит отсрочки ${pathOr('', ['MAIN', 'NAME'], condition)}`,
      limit: pathOr(0, ['MAIN', 'LIMIT'], condition),
      debts: [
        { name: 'Текущий долг:', value: prop('debtMain', paymentsAgg) },
        {
          name: 'Просроченный долг:',
          value: prop('debtMain', paymentsAggDelay)
        }
      ]
    }
  }
)

export const bookmarksSelector = createSelector(
  conditionSelector,
  paymentsSelector,
  (condition, payments) => compose(
    reverse,
    map(item => ({
      title: `Лимит закладки ${pathOr('', ['NAME'], item)}`,
      limit: pathOr('', ['LIMIT'], item),
      text: `оплата в ${length(propOr([], 'PAYMENTS', item))} ${noun(
        length(propOr([], 'PAYMENTS', item)),
        ['транш', 'транша', 'траншей']
      )}
      ${propOr([], 'PAYMENTS', item)
        .map(i => `${prop('VALUE', i) * NUMBER_100}%`)
        .join(' / ')}
      `,
      debts: [
        {
          name: 'Текущий долг:',
          value: prop('debtBookmark', getPaymentsAgg(
            filter(pi => prop('bookmark', pi) === pathOr('', ['ID'], item), payments)
          ))
        },
        {
          name: 'Просроченный долг:',
          value: prop('debtBookmark', getPaymentsAgg(
            compose(
              map(pi => ({
                ...pi,
                payments: propOr([], 'payments', pi).filter(fi => prop('delay', fi) > 0)
              })),
              filter(pi => prop('bookmark', pi) === pathOr('', ['ID'], item))
            )(payments)
          ))
        }
      ]
    })),
    propOr([], 'BOOKMARK')
  )(condition)
)

export const contractorSelector = createSelector(entitiesSelector, state =>
  compose(
    prepend({ value: '', title: 'Все' }),
    map(item => ({
      value: propOr('', 'ID', item),
      title: propOr('', 'TITLE', item)
    })),
    values,
    propOr({}, 'CONTRACTORS')
  )(state)
)

export const typesSelector = createSelector(
  state => state,
  () => [
    { value: '', title: 'Все' },
    { value: 'main', title: 'Отсрочка' },
    { value: 'bookmark', title: 'Закладка' }
  ]
)

export const billTypesSelector = createSelector(
  state => state,
  () => [
    { value: '', title: 'Все' },
    { value: 'unpaid', title: 'Неоплаченные' },
    { value: 'expired', title: 'Просроченные' }
  ]
)

export const periodTypesSelector = createSelector(
  state => state,
  () => [
    { value: '', title: 'За все время' },
    { value: 'period', title: 'За период' }
  ]
)

export const periodsSelector = createSelector(paymentsSelector, items =>
  reduce(
    (acc, item) => ({
      min:
        prop('dateShipment', item) < prop('min', acc) || prop('min', acc) === 0
          ? prop('dateShipment', item)
          : prop('min', acc),
      max:
        prop('dateShipment', item) > prop('max', acc)
          ? prop('dateShipment', item)
          : prop('max', acc)
    }),
    { max: 0, min: 0 },
    items
  )
)

export const datePaySelector = createSelector(paymentsSelector, items =>
  reduce(
    (acc, item) => ({
      min:
        path(['payments', 0, 'datePay'], item) < prop('min', acc) ||
        prop('min', acc) === 0
          ? path(['payments', 0, 'datePay'], item)
          : prop('min', acc),
      max:
        path(['payments', 0, 'datePay'], item) > prop('max', acc)
          ? path(['payments', 0, 'datePay'], item)
          : prop('max', acc)
    }),
    { max: 0, min: 0 },
    items
  )
)

export const sortTypesSelector = createSelector(
  state => state,
  () => [
    { value: 'old', title: 'Старые' },
    { value: 'new', title: 'Новые' }
  ]
)

export const isLoadingSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const isLoadedSelector = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

// Сортируем платежи по возрастанию даты платежа
const ascendDatePaySelector = createSelector(
  paymentsSelector,
  compose(
    sortWith([ascend(propOr(0, 'datePay'))]), // Сортируем по возрастанию даты платежа
    filter(item => propOr(0, 'datePay', item) > moment().startOf('day').unix()), // Оставляем только платежи, дата которых больше текущей
    flatten, // Получаем массив платежей всех на одном уровне
    map(propOr([], 'payments')) // Получаем массив массивов платежей
  )
)

// Получаем даты последних платежей
export const latestDatesSelector = createSelector(
  ascendDatePaySelector,
  compose(
    payment =>
      payment
        ? {
          datePayInitial: propOr(0, 'datePay', payment),
          delay: Math.abs(propOr(0, 'delay', payment)),
          datePay: moment(propOr(0, 'datePay', payment), 'X')
            .locale('ru')
            .format('DD MMMM YYYY')
        }
        : null,
    head // Возвращаем первый элемент массива
  )
)

// Суммируем платежи до последнего платежа
export const latestPaymentSelector = createSelector(
  ascendDatePaySelector,
  latestDatesSelector,
  (payments, latestDates) => compose(
    reduce((acc, payment) => ({
      sum: add(prop('sum', acc))(propOr(0, 'sum', payment)),
      pay: add(prop('pay', acc))(propOr(0, 'pay', payment)),
      debt: add(prop('debt', acc))(propOr(0, 'debt', payment))
    }), { sum: 0, pay: 0, debt: 0 }),
    takeWhile(propEq('datePay', prop('datePayInitial', latestDates)))
  )(payments)
)

