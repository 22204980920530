import {
  filter,
  prop,
  propOr,
  intersection,
  includes,
  length,
  find,
  pathOr
} from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getIsVoted = (state, id) => pathOr(false, ['isVoted', id], state)
export const getIsVoting = state => state.isVoting
export const getQuestion = (id, { questions }) =>
  find(item => prop('ID', item) === id, questions)
export const getBreadcrumbs = (id, { questions }) => [
  { title: 'Помощь', pathname: '/faq' },
  {
    title: prop('NAME', getQuestion(id, { questions })),
    pathname: `/faq/${id}`
  }
]

export const allQuestions = createSelector(
  faq => faq,
  faq => propOr([], 'questions', faq)
)

export const popularQuestionsSelector = createSelector(
  allQuestions,
  faq => prop('populars', faq),
  (questions, populars) =>
    filter(question => includes(prop('ID', question), populars), questions)
)

export const questionsSelector = createSelector(
  allQuestions,
  faq => prop('checkedTags', faq),
  (questions, checkedTags) =>
    filter(
      question =>
        length(intersection(propOr([], 'TAGS', question), checkedTags)) > 0,
      questions
    )
)

export const tagsSelector = createSelector(
  faq => faq,
  faq => prop('tags', faq)
)

export const checkedTagsSelector = createSelector(
  faq => faq,
  faq => prop('checkedTags', faq)
)

export const sectionsSelector = createSelector(
  faq => faq,
  faq => prop('sections', faq)
)

export const filesSelector = createSelector(
  faq => faq,
  faq => prop('files', faq)
)
