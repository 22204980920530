/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import debounce from 'lodash.debounce'
import { func, number } from 'prop-types'
import { omit } from 'ramda'

const TIMEOUT_DEBOUNCE = 300

class Interactions extends PureComponent {
  static propTypes = {
    onClickOutside: func,
    onDelayedMouseEnter: func,
    onDelayedMouseLeave: func,
    mouseEnterDelay: number,
    onWindowResize: func,
    closePortal: func,
    domRef: func
  }

  static defaultProps = {
    mouseEnterDelay: TIMEOUT_DEBOUNCE,
    onDelayedMouseEnter: () => {},
    onDelayedMouseLeave: () => {}
  }

  componentDidMount() {
    if (this.props.onClickOutside) this.registerClickOutside()
    if (this.props.onDelayedMouseEnter) this.registerDelayedMouseEvents()
    if (this.props.onWindowResize) this.registerWindowResize()
  }

  componentDidUpdate(prevProps) {
    if (this.props.onClickOutside !== prevProps.onClickOutside) {
      if (this.props.onClickOutside) {
        this.registerClickOutside()
      } else {
        this.unregisterClickOutside()
      }
    }

    if (this.props.onDelayedMouseEnter !== prevProps.onDelayedMouseEnter) {
      if (this.props.onDelayedMouseEnter) {
        this.registerDelayedMouseEvents()
      } else {
        this.unregisterDelayedMouseEvents()
      }
    }

    if (this.props.onWindowResize !== prevProps.onWindowResize) {
      if (this.props.onWindowResize) {
        this.registerWindowResize()
      } else {
        this.unregisterWindowResize()
      }
    }
  }

  componentWillUnmount() {
    if (this.props.onClickOutside) this.unregisterClickOutside()
    if (this.props.onDelayedMouseEnter) this.unregisterDelayedMouseEvents()
    if (this.props.onWindowResize) this.unregisterWindowResize()
  }

  handleClickOutside = event => {
    if (this.element && !this.element.contains(event.target)) {
      this.props.onClickOutside(event)
    }
  }

  handleDelayedMouseEnter = () => {
    this.timeout = setTimeout(() => {
      this.props.onDelayedMouseEnter()
      this.timeout = null
    }, this.props.mouseEnterDelay)
  }

  handleDelayedMouseLeave = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
    } else {
      this.props.onDelayedMouseLeave()
    }
  }

  getContainer = el => {
    this.element = el
    if (this.props.domRef) this.props.domRef(el)
  }

  registerClickOutside = () =>
    window.addEventListener('click', this.handleClickOutside, true)

  unregisterClickOutside = () =>
    window.removeEventListener('click', this.handleClickOutside, true)

  registerDelayedMouseEvents = () => {
    this.element.addEventListener(
      'mouseenter',
      this.handleDelayedMouseEnter,
      false
    )
    this.element.addEventListener(
      'mouseleave',
      this.handleDelayedMouseLeave,
      false
    )
  }

  unregisterDelayedMouseEvents = () => {
    this.element.removeEventListener(
      'mouseenter',
      this.handleDelayedMouseEnter,
      false
    )
    this.element.removeEventListener(
      'mouseleave',
      this.handleDelayedMouseLeave,
      false
    )
  }

  registerWindowResize = () => {
    this.handleWindowResize = debounce(
      this.props.onWindowResize,
      TIMEOUT_DEBOUNCE
    )
    window.addEventListener('resize', this.handleWindowResize, false)
  }

  unregisterWindowResize = () =>
    window.removeEventListener('resize', this.handleWindowResize, false)

  render() {
    return (
      <div
        ref={this.getContainer}
        {...omit([
          'onClickOutside',
          'onDelayedMouseEnter',
          'onDelayedMouseLeave',
          'mouseEnterDelay',
          'onWindowResize',
          'domRef',
          'closePortal'
        ], this.props)}
      />
    )
  }
}

export default Interactions
