/* eslint-disable complexity */
import React, { Component } from 'react'

import cx from 'classnames'
import { oneOfType, node, string, number, arrayOf } from 'prop-types'
import { is, isEmpty } from 'ramda'

import styles from './Scrollbar.scss'

const NUMBER_100 = 100
const NUMBER_2 = 2

export default class Scrollbar extends Component {
  static propTypes = {
    children: oneOfType([node, arrayOf(node)]),
    maxHeight: oneOfType([number, string]),
    minScrollHeight: oneOfType([number, string])
  }

  static defaultProps = {
    maxHeight: 'none',
    minScrollHeight: ''
  }

  state = {
    scrollX: 0,
    scrollY: 0
  }

  componentDidMount() {
    this.updateScroll()
  }

  componentDidUpdate() {
    this.updateScroll()
  }

  handleRefInner = ref => {
    this.innerContainer = ref
  }

  handleRefY = ref => {
    this.scrollY = ref
  }

  handleRefX = ref => {
    this.scrollX = ref
  }

  handleScroll = event => {
    const positionY = event.currentTarget.scrollTop
    const positionX = event.currentTarget.scrollLeft
    this.setState({
      scrollY: positionY / this.contentHeight,
      scrollX: positionX / this.contentWidth
    })
  }

  handleYmove = event => {
    const rect = this.scrollY.getBoundingClientRect()
    this.innerContainer.scrollTop =
      (this.contentHeight * (event.clientY - rect.top)) / rect.height -
      this.visibleHeight / NUMBER_2
  }

  handleXmove = event => {
    const rect = this.scrollX.getBoundingClientRect()
    this.innerContainer.scrollLeft =
      (this.contentWidth * (event.clientX - rect.left)) / rect.width -
      this.visibleWidth / NUMBER_2
  }

  handleStopMove = () => {
    document.removeEventListener('mousemove', this.handleYmove)
    document.removeEventListener('mousemove', this.handleXmove)
    document.removeEventListener('mouseup', this.handleStopMove)
  }

  handleYMouseDown = event => {
    this.handleYmove({ clientY: event.clientY })
    document.addEventListener('mousemove', this.handleYmove)
    document.addEventListener('mouseup', this.handleStopMove)
  }

  handleXMouseDown = event => {
    this.handleYmove({ clientX: event.clientX })
    document.addEventListener('mousemove', this.handleXmove)
    document.addEventListener('mouseup', this.handleStopMove)
  }

  setScrollHeight = () => {
    const { minScrollHeight, maxHeight } = this.props
    let scrollHeight = `${this.scrollYRatio * NUMBER_100}%`
    if (is(Number, maxHeight)) {
      const scrollY = this.scrollYRatio * maxHeight
      if (!isEmpty(minScrollHeight) && scrollY < minScrollHeight) {
        scrollHeight = `${minScrollHeight}px`
      }
    }
    return scrollHeight
  }

  updateScroll() {
    const { innerContainer } = this
    const oldContentHeight = this.contentHeight
    const oldVisibleHeight = this.visibleHeight
    const oldContentWidth = this.contentWidth
    const oldVisibleWidth = this.visibleWidth
    this.contentHeight = innerContainer.scrollHeight
    this.visibleHeight = innerContainer.clientHeight
    this.contentWidth = innerContainer.scrollWidth
    this.visibleWidth = innerContainer.clientWidth
    if (
      oldContentHeight !== this.contentHeight ||
      oldVisibleHeight !== this.visibleHeight ||
      oldContentWidth !== this.contentWidth ||
      oldVisibleWidth !== this.visibleWidth
    ) {
      this.scrollbarWidth = innerContainer.offsetWidth - this.visibleWidth
      this.scrollYRatio = this.contentHeight
        ? this.visibleHeight / this.contentHeight
        : 1

      this.scrollbarHeight = innerContainer.offsetHeight - this.visibleHeight
      this.scrollXRatio = this.contentWidth
        ? this.visibleWidth / this.contentWidth
        : 1
    }
  }

  render() {
    const { children, maxHeight } = this.props
    const { scrollY, scrollX } = this.state

    return (
      <div className={cx(styles.scrollbar)}>
        <div
          ref={this.handleRefInner}
          className={cx(styles.inner)}
          onScroll={this.handleScroll}
          style={{
            marginRight: `-${this.scrollbarWidth}px`,
            marginBottom: `-${this.scrollbarHeight}px`,
            paddingRight: this.scrollbarWidth ? '18px' : 0,
            paddingBottom: this.scrollbarHeight ? '18px' : 0,
            maxHeight
          }}
        >
          {children}
        </div>
        {this.visibleHeight !== this.contentHeight && (
          <div
            role='presentation'
            className={cx(styles.scrollYWrapper)}
            ref={this.handleRefY}
            onMouseDown={this.handleYMouseDown}
          >
            <div
              className={cx(styles.scrollY)}
              style={{
                top: `${scrollY * NUMBER_100}%`,
                height: this.setScrollHeight()
              }}
            />
          </div>
        )}
        {this.visibleWidth !== this.contentWidth && (
          <div
            role='presentation'
            ref={this.handleRefX}
            className={cx(styles.scrollXWrapper)}
            onMouseDown={this.handleXMouseDown}
          >
            <div
              className={cx(styles.scrollX)}
              style={{
                left: `${scrollX * NUMBER_100}%`,
                width: `${this.scrollXRatio * NUMBER_100}%`
              }}
            />
          </div>
        )}
      </div>
    )
  }
}
