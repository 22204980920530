import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import utc from 'dayjs/plugin/utc'
import 'dayjs/locale/ru'

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat)
dayjs.extend(utc)

export default (date: Date | string, format?: string) => format ? dayjs(date, format) : dayjs(date)