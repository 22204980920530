import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, number, object, func } from 'prop-types'
import { propOr } from 'ramda'

import Image from 'components/Image'
import Link from 'components/Link'
import { getMenuLink } from 'helpers/catalog'

import styles from './MenuMainColumn.scss'

class ItemMenu extends PureComponent {
  static propTypes = {
    item: object,
    onHideCatalog: func,
    isMobile: bool,
    isBookmark: bool,
    isSelected: bool,
    bookmark: number,
    onSelect: func
  }

  static defaultProps = {
    isMobile: false,
    isSelected: false,
    item: {},
    onHideCatalog: () => {},
    onSelect: () => {}
  }

  handleAction = () => {
    const { item, isMobile, onSelect } = this.props
    return !isMobile ? onSelect(propOr('', 'ID', item)) : undefined
  }

  handlePush = () => {
    const { isMobile, item, onHideCatalog, onSelect } = this.props
    if (!isMobile) {
      onHideCatalog()
    } else { onSelect(propOr('', 'ID', item)) }
  }

  render() {
    const { isSelected, item, isBookmark, bookmark, isMobile } = this.props
    const className = cx(styles.title, {
      [styles.title_active]: isSelected
    })

    const link = getMenuLink({ isBookmark, bookmark, item })
    const Tag = isMobile ? 'div' : Link

    return (
      <li
        className={styles.item}
        itemProp='itemListElement'
        itemScope
        itemType='http://schema.org/ItemList'
      >
        <meta itemProp='position'
          content={item.ID} />
        <Tag
          to={link}
          onClick={this.handlePush}
          onMouseOver={this.handleAction}
          onFocus={this.handleAction}
        >
          <div className={className}>
            <span className={styles.titleImageWrapper}>
              <Image
                src={propOr('', 'PICTURE_PATH', item)}
                imgClassName={styles.titleImage}
                backgroundSize='contain'
              />
            </span>
            <span className={styles.titleName}>{propOr('', 'NAME', item)}</span>
          </div>
        </Tag>
      </li>
    )
  }
}

export default ItemMenu
