/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { push } from 'connected-react-router'
import hoistStatics from 'hoist-non-react-statics'
import { object, func, string } from 'prop-types'
import {
  isEmpty,
  prop,
  propOr,
  path,
  pathOr,
  compose,
  filter,
  equals
} from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import parse from 'utils/querystring'

const NUMBER_20 = 20
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60

const accessTokenUrlSelector = createSelector(
  state => state,
  state =>
    compose(
      propOr('', 'access_token'),
      parse,
      pathOr('', ['location', 'search'])
    )(state)
)

export default () => WrappedComponent => {
  @connect(
    ({ router }) => ({
      location: propOr({}, 'location', router),
      tokenFromUrl: accessTokenUrlSelector(router)
    }),
    {
      navigateTo: push
    }
  )
  class AuthFromUrl extends PureComponent {
    static displayName = `AuthFromUrl(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      tokenFromUrl: string,
      location: object,
      clientApi: object,
      navigateTo: func
    }

    static defaultProps = {
      tokenFromUrl: '',
      navigateTo: () => {}
    }

    componentDidMount() {
      const { tokenFromUrl } = this.props
      if (!isEmpty(tokenFromUrl)) {
        this.autoAuth()
      }
    }

    componentDidUpdate(prevProps) {
      if (
        prevProps.tokenFromUrl !== this.props.tokenFromUrl &&
        !isEmpty(this.props.tokenFromUrl)
      ) {
        this.autoAuth()
      }
    }

    autoAuth = () => {
      const { tokenFromUrl, clientApi, location } = this.props
      return clientApi
        .get('/v2/personal/info/', {
          params: { access_token: tokenFromUrl }
        })
        .then(data => {
          const isAuth = pathOr(false, ['data', 'response', 'IS_AUTH'], data)
          if (isAuth) {
            const userId = pathOr(0, ['data', 'response', 'USER', 'ID'], data)
            const contractors = pathOr(
              [],
              ['data', 'response', 'CONTRACTORS'],
              data
            )

            const contractorId = compose(
              path([0, 'ID']),
              filter(item => equals(true, prop('SELECTED', item)))
            )(contractors)

            clientApi.setContractorId(contractorId, userId)
            clientApi.saveToCookies({
              access_token: tokenFromUrl,
              refresh_token: tokenFromUrl,
              expires_in: NUMBER_20 * MINUTES_IN_HOUR * SECONDS_IN_MINUTE
            })
          }
          if (typeof window !== 'undefined' && window.location) {
            window.location.replace(propOr('/', 'pathname', location))
          }
        })
        .catch(this.props.navigateTo(propOr('/', 'pathname', location)))
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistStatics(AuthFromUrl, WrappedComponent)
}
