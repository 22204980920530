import { connect } from 'react-redux'

import { MenuSecondLevel } from 'components/MenuCatalogMobile'
import { sectionsByIdSelector } from 'redux/modules/sections'

export default connect(({ sections }, { selectedItemId, bookmark, isBookmark }) => ({
  sections: sectionsByIdSelector({
    id: selectedItemId,
    catalogType: isBookmark ? 'bookmark' : 'main',
    sections,
    bookmark
  })
}))(MenuSecondLevel)