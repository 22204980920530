// @ts-nocheck
import React, { FC } from 'react'

import { modalActions } from 'decorators/modal'
import Button from 'UI/Button'

import CallBackModal from './CallBackModal'
import s from './FormCallBack.scss'

interface ICallBackButton {
  showModal: (str: string) => void
}

const CallBackButton: FC<ICallBackButton> = ({ showModal, ...rest }) => {
  const showModalCallback = () => () => {
    showModal('callbackForm')
  }

  return (
    <>
      <div className={s.callBack}>
        <Button
          onClick={showModalCallback()}
          icon='phone-callback'
          iconSize='big'
          shape='circle'
          size='big'
          type='button'
          {...rest}
        />
      </div>
      <CallBackModal />
    </>
  )
}
export default modalActions(CallBackButton)
