/* eslint-disable complexity */
import { pathOr, propOr, isEmpty, equals } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { cabinetAnalyticsFilters } from 'helpers/cabinetConstants'

const MIN_LIMIT = 48
const FIRST_PAGE = 1

const initialState = {
  sort: 'DATE',
  isLoading: false,
  isLoaded: false
}

export {
  isDateRangeSelected,
  aggregationSelector,
  graphSelector,
  diagramSelector,
  tableListSelector,
  tableItemsCountSelector,
  diagramViewSelector,
  groupBySelector,
  orderSelector,
  limitSelector,
  pageSelector,
  isLoadingGraphSelector,
  isLoadingDiagramSelector
} from './selector'

export const fetch = createAction('cabinetAnalytics/FETCH')
export const fetchSuccess = createAction('cabinetAnalytics/FETCH_SUCCESS')
export const fetchFailure = createAction('cabinetAnalytics/FETCH_FAILURE')
export const setParam = createAction('cabinetAnalytics/SET_PARAM')
export const setTableParam = createAction('cabinetAnalytics/SET_TABLE_PARAM')

const request =
  ({ clientApi }) =>
    ({ paramsReq, type }) => {
      const params = equals(type, 'aggregation')
        ? {
          contractor_id: clientApi.getContractorId()
        }
        : {
          contractor_id: clientApi.getContractorId(),
          ...paramsReq
        }
      return clientApi
        .get(`/v3/contractor/analytics/${type}/`, { params })
        .then(data => fetchSuccess({ data, type }))
        .catch(data => fetchFailure({ data, type }))
    }

const handleFetch = (state, { params, type }, { clientApi }) => {
  const limit = propOr(MIN_LIMIT, 'limit', state)
  const page = propOr(FIRST_PAGE, 'p', state)
  const payload = cabinetAnalyticsFilters(params)
  const paramsReq = equals(type, 'list')
    ? {
      sort: 'DATE',
      order: propOr('DESC', 'order', state),
      offset: limit * (page - 1),
      limit,
      ...payload
    }
    : payload
  return loop(
    {
      ...state,
      [type]: {
        ...state[type],
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), { paramsReq, type })
  )
}

const handleFetchSuccess = (state, payload) => {
  const type = propOr('list', 'type', payload)
  const defaultData =
    equals(type, 'aggregation') || equals(type, 'graph') ? {} : []
  const items = pathOr(
    defaultData,
    ['data', 'data', 'response', 'ITEMS'],
    payload
  )
  return {
    ...state,
    [type]: {
      items,
      message: isEmpty(items)
        ? pathOr('', ['data', 'data', 'response', 'MESSAGE'], payload)
        : '',
      nav: pathOr(0, ['data', 'data', 'response', 'NAV', 'CNT'], payload),
      periodType: equals(type, 'graph')
        ? pathOr(
          'MONTH',
          ['data', 'data', 'response', 'NAV', 'PERIOD'],
          payload
        )
        : '',
      isLoading: false,
      isLoaded: true
    }
  }
}

const handleFetchFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false
})

const handleSetParam = (state, { name, param }) => ({
  ...state,
  [name]: param
})

const handleSetTableParam = (state, { params, query }) =>
  loop(
    {
      ...state,
      ...params
    },
    Effects.call(fetch, { params: query, type: 'list' })
  )

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(setParam, handleSetParam)
  on(setTableParam, handleSetTableParam)
}, initialState)

export default reducer
