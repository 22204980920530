/* eslint-disable complexity */
import React, { useEffect, useState, useCallback, useReducer } from 'react'

import { func, string } from 'prop-types'

import Preloader from 'UI/Preloader'
import Text from 'UI/Text'

import s from './FormIo.scss'

const TIMEOUT = 4000
const TEXT = 'Успешно отправлено'
let Form = null

const FormWrapper = ({ onClose, submitText = TEXT, ...rest }) => {
  const [isMounted, setIsMounted] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)
  const [isDone, setIsDoneState] = useReducer(o => !o, false)
  const importForm = async () => {
    const resImport = await import('@formio/react')
    Form = resImport.Form
    setIsMounted(true)
  }

  useEffect(() => {
    importForm()
  }, [])

  const onRender = useCallback(() => {
    setIsLoaded(true)
  }, [])

  const handleSubmit = useCallback(() => {
    setIsDoneState()
    setTimeout(() => onClose(), TIMEOUT)
  }, [])

  return (
    <div className={s.formWrapper}>
      <div className='wrapper'>
        <Preloader active={!isLoaded} />
        {isMounted && !isDone ? (
          <Form
            onSubmitDone={handleSubmit}
            onRender={onRender}
            options={{
              language: 'ru',
              i18n: {
                ru: {
                  complete: 'Успешно отправлено',
                  required: 'Поле \'{{field}}\' обязательное',
                  'Drop files to attach,': 'Перетащите',
                  'or': 'или',
                  'browse': 'нажмите'
                }
              }
            }}
            {...rest}
          />
        ) : null}
        {isDone ? <Text color='green'>{submitText}</Text> : null}
      </div>
    </div>
  )
}

FormWrapper.propTypes = {
  onClose: func,
  submitText: string
}

export default FormWrapper
