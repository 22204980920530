import React, { useMemo } from 'react'

import { array, string, func, bool } from 'prop-types'
import { equals } from 'ramda'
import { Field, formValues } from 'redux-form'

import InputWithErrors from 'components/InputWithErrors'
import s from 'components/Registration/Registration.scss'
import RegistrationAutocomplete from 'components/Registration/RegistrationAutocomplete'
import Spacing from 'UI/Spacing'

const COUNTRY_RUSSIA = '643'

const RegistrationRussiaSpecific = formValues({ countryCode: 'country_code' })(({
  isShowAddress,
  isReadOnly,
  countryCode,
  suggestionItems,
  onChangeAutocomplete,
  onClearAutocomplete
}) => {
  const isRussia = useMemo(() => equals(countryCode, COUNTRY_RUSSIA), [countryCode])

  const addressProps = useMemo(() => isShowAddress ? {} : { type: 'hidden' }, [isShowAddress])

  return isRussia ? (
    <div className={s.registration__address}>
      <Spacing margin='bottom'
        size='small'>
        <Field
          name='title'
          component={RegistrationAutocomplete}
          items={suggestionItems}
          isReadOnly={isReadOnly}
          placeholder='ИНН или название организации'
          onChangeAutocomplete={onChangeAutocomplete}
          onClearAutocomplete={onClearAutocomplete}
        />
      </Spacing>
      {isShowAddress && (
        <Spacing margin='bottom'
          size='small'>
          <Field
            name='address'
            component={InputWithErrors}
            placeholder='Город, область, улица, дом'
            { ...addressProps }
          />
        </Spacing>
      )}
      <Field
        name='inn'
        component={InputWithErrors}
        placeholder='ИНН'
        type='hidden'
      />
      <Field
        name='ogrn'
        component={InputWithErrors}
        placeholder='ОГРН'
        type='hidden'
      />
      <Field
        name='kladr'
        component={InputWithErrors}
        type='hidden'
      />
    </div>
  ) : (
    <div className={s.registration_address}>
      <Spacing margin='bottom'
        size='small'>
        <Field
          name='title'
          component={InputWithErrors}
          placeholder='Название организации'
        />
      </Spacing>
      <Spacing margin='bottom'
        size='small'>
        <Field
          name='address'
          component={InputWithErrors}
          placeholder='Город, область, улица, дом'
        />
      </Spacing>
    </div>
  )
})

RegistrationRussiaSpecific.propTypes = {
  isShowAddress: bool,
  isReadOnly: bool,
  countryCode: string,
  suggestionItems: array,
  onChangeAutocomplete: func,
  onClearAutocomplete: func
}

export default RegistrationRussiaSpecific
