/* eslint-disable complexity */
import { split, length, last, head } from 'ramda'

const PRICE_FILTER_LENGTH = 2

const objectToQuery = (obj = {}, name = '') => {
  if (!obj || !Object.keys(obj).length) {
    return ''
  }
  const { page, ...params } = obj
  if (page && params.limit) {
    params.offset = (page - 1) * params.limit
  }
  return `${!name ? '?' : ''}${Object.keys(params)
    .reduce((acc, queryKey) => {
      const value = params[queryKey]
      if (!value && value !== 0 && value !== false) {
        return acc
      }
      let param = ''
      const arrKey = split('_', queryKey)
      const isFilterByPrice =
        length(arrKey) === PRICE_FILTER_LENGTH && last(arrKey) === 'PRICE'
      if (isFilterByPrice) {
        param = `${name}[PRICE][${head(arrKey)}]`
      } else {
        const queryKeyInner =
          parseInt(queryKey, 10) || parseInt(queryKey, 10) === 0 ? '' : queryKey
        param = name ? `${name}[${queryKeyInner}]` : queryKey
      }
      if (queryKey === 'MARKDOWN') {
        param = name ? `${name}[MARKS]` : 'MARKS'
      }
      const str =
        typeof value === 'object'
          ? objectToQuery(value, param)
          : `${param}=${value}`
      return str ? acc.concat([str]) : acc
    }, [])
    .filter(item => item)
    .join('&')}`
}

export default objectToQuery
