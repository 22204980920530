/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { number, object, string, array, func } from 'prop-types'
import {
  append,
  head,
  last,
  pathOr,
  propOr,
  compose,
  inc,
  dec,
  isEmpty
} from 'ramda'

import Image from 'components/Image'
import Preloader from 'UI/Preloader'

import styles from './ImageRotate.scss'

const IMG_WIDTH = 1024
let mainImageWidth = 0
const STEPS_COUNT = 60
let mouseLocs = []
const AUTO_PLAY_DELAY = 50

export default class ImageRotate extends PureComponent {
  static propTypes = {
    maxImageSize: number,
    maxWidth: number,
    previewSize: number,
    spriteIcon: string,
    photos: array,
    spriteData: object,
    onChangeSpriteIcon: func
  }

  static defaultProps = {
    maxImageSize: 350,
    previewSize: 350,
    photos: [],
    spriteIcon: 'rotate',
    onChangeSpriteIcon: () => {}
  }

  state = {
    step: 0
  }

  componentDidMount() {
    this.autoPlay()
  }

  componentDidUpdate(prevProps) {
    const { spriteIcon } = this.props
    if (
      prevProps.spriteIcon !== spriteIcon &&
      prevProps.spriteIcon === 'rotate-paused'
    ) {
      this.autoPlay()
    }
  }

  componentWillUnmount() {
    if (this.autoPlayTimer) {
      clearInterval(this.autoPlayTimer)
    }
  }

  handleMouseDown = () => {
    if (this.autoPlayTimer) {
      clearInterval(this.autoPlayTimer)
    }
    if (typeof document !== 'undefined') {
      document.addEventListener('mousemove', this.handleMouseMoveDocument)
    }
  }

  handleMouseUp = () => {
    if (typeof document !== 'undefined') {
      document.removeEventListener('mousemove', this.handleMouseMoveDocument)
      mouseLocs = []
    }
    this.autoPlay()
  }

  handleMouseMoveDocument = e => {
    const { spriteData } = this.props
    // кол-во кадров в спрайте
    const stepsCount = pathOr(
      STEPS_COUNT,
      ['data', 'frame', 'count'],
      spriteData
    )
    // координаты мышки
    const locs = e.pageX
    mouseLocs = append(locs, mouseLocs)
    const maxDelta = mainImageWidth / stepsCount
    if (mouseLocs.length > Math.floor(maxDelta)) {
      mouseLocs.shift()
    }
    const prevPosition = head(mouseLocs)
    const nextPosition = last(mouseLocs)
    const delta = prevPosition - nextPosition

    // определяем, в каком направлении менять положение спрайта
    if (delta > 0 && delta > maxDelta) {
      const newStep = compose(dec, pathOr(0, ['state', 'step']))(this)
      this.setState({ step: newStep })
    }
    if (delta < 0 && Math.abs(delta) > maxDelta) {
      const newStep = compose(inc, pathOr(0, ['state', 'step']))(this)
      this.setState({ step: newStep })
    }
  }

  handleClick = () => {
    const { onChangeSpriteIcon } = this.props
    if (this.autoPlayTimer) {
      clearInterval(this.autoPlayTimer)
      this.autoPlayTimer = null
      onChangeSpriteIcon('rotate-paused')
    } else {
      this.autoPlay()
    }
  }

  autoPlay = () => {
    if (this.autoPlayTimer) {
      clearInterval(this.autoPlayTimer)
    }
    this.autoPlayTimer = setInterval(this.autoRotation, AUTO_PLAY_DELAY)
  }

  autoRotation = () => {
    const newStep = compose(inc, pathOr(0, ['state', 'step']))(this)
    this.setState({ step: newStep })
  }

  render() {
    const { maxImageSize, maxWidth, spriteData, photos, previewSize } =
      this.props
    const spriteWidth = pathOr(
      IMG_WIDTH,
      ['data', 'frame', 'width'],
      spriteData
    )

    // считаем размер отображаемой части спрайта (первый кадр из спрайта) в соответствии с размером экрана
    // берем величину за ширину шага спрайта в пикселях
    mainImageWidth = maxImageSize > spriteWidth ? spriteWidth : maxImageSize

    const src = pathOr('', ['data', 'path'], spriteData)
    const isLoaded = propOr(false, 'isLoaded', spriteData)
    return (
      <div
        role='presentation'
        className={styles.imageWrapper}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        onClick={this.handleClick}
        style={{ width: maxWidth }}
      >
        {!isLoaded ? (
          <div
            style={{ height: maxImageSize, width: previewSize }}
            className={styles.imageLoader}
          >
            {!isEmpty(photos) && (
              <Image
                backgroundSize='contain'
                width={previewSize}
                height={previewSize}
                src={head(photos)}
              />
            )}
            <Preloader active={!isLoaded} />
          </div>
        ) : (
          <div
            style={{
              height: mainImageWidth,
              width: mainImageWidth,
              backgroundImage: `url(${src})`,
              backgroundPosition: `${mainImageWidth * this.state.step}px 0`
            }}
            className={styles.imageContainer}
          />
        )}
      </div>
    )
  }
}
