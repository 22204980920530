import React, { memo } from 'react'

import { useSelector } from 'react-redux'

import { isDesktopSelector, isMobileSelector, isTabletSelector } from 'redux/modules/device'

/*
# Как использовать декоратор (например показывать компонент только для mobile)

1 вариант - при экспорте
```
@forMobile
export default Component = (...) => ...
```

2 вариант - при экспорте
```
export default forMobile(Component)
```

3 вариант - при экспорте с compose (из redux, справа-налево)
```
compose(
  connect(...),
  ...,
  forMobile
)(Component)

4. Если нужно прокинуть props isTablet, isMobile, isDesktop
display(Component)

```
*/
const forMobile = WrapperComponent => memo(props => {
  const isMobile = useSelector(({ device }) => isMobileSelector(device))
  if (isMobile) {
    return <WrapperComponent {...props} />
  }
  return null
})

const forTablet = WrapperComponent => memo(props => {
  const isTablet = useSelector(({ device }) => isTabletSelector(device))
  if (isTablet) {
    return <WrapperComponent {...props} />
  }
  return null
})

const forDesktop = WrapperComponent => memo(props => {
  const isDesktop = useSelector(({ device }) => isDesktopSelector(device))
  if (isDesktop) {
    return <WrapperComponent {...props} />
  }
  return null
})

const forTabletMobile = WrapperComponent => memo(props => {
  const isTablet = useSelector(({ device }) => isTabletSelector(device))
  const isMobile = useSelector(({ device }) => isMobileSelector(device))
  if (isTablet || isMobile) {
    return <WrapperComponent {...props} />
  }
  return null
})

const display = WrapperComponent => memo(props => {
  const isTablet = useSelector(({ device }) => isTabletSelector(device))
  const isMobile = useSelector(({ device }) => isMobileSelector(device))
  const isDesktop = useSelector(({ device }) => isDesktopSelector(device))

  return (
    <WrapperComponent
      {...props}
      isTablet={isTablet}
      isMobile={isMobile}
      isDesktop={isDesktop}
    />
  )
})

export { forMobile, forTablet, forDesktop, forTabletMobile, display }
