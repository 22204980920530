import React from 'react'

import { bool, node } from 'prop-types'

import Portal from 'components/Portal'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import { display } from 'decorators/device'

import s from './DimmerWrapper.scss'

const DimmerWrapper = ({ children, isMobile }) => isMobile
  ? (
    <DisableBodyScroll>
      <Portal>
        <div className={s.wrapper}>
          {children}
        </div>
      </Portal>
    </DisableBodyScroll>
  )
  : children

DimmerWrapper.propTypes = {
  children: node,
  isMobile: bool
}

export default display(DimmerWrapper)