import React from 'react'

import { bool } from 'prop-types'
import { head, keys, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { compose } from 'redux'

import { display } from 'decorators/device'

import Label from './Label'
import LabelReduce from './LabelReduce'
import s from './Labels.scss'

const LabelsMobile = props => {
  const type = head(keys(pathOr({}, ['item', 'MARKS'], props)))

  if (!type) {
    return null
  }

  return (
    <div className={s.labels}>
      <Label
        type={type}
        values={pathOr([], ['item', 'MARKS', type], props)}
        entities={pathOr({}, ['entities', 'MARKS'], props)}
      />
    </div>
  )
}
const LabelsDesktop = props => (
  <div className={s.labels}>
    {keys(pathOr({}, ['item', 'MARKS'], props))
      .sort()
      .map(type => (
        <Label
          key={type}
          type={type}
          values={pathOr([], ['item', 'MARKS', type], props)}
          entities={pathOr({}, ['entities', 'MARKS'], props)}
        />
      ))}
    <LabelReduce
      price={pathOr({}, ['item', 'PRICES_PROMO', 'REDUCE'], props)}
    />
  </div>
)

const LabelsWrapper = ({ isDesktop, ...props}) => isDesktop ? <LabelsDesktop {...props}/> : <LabelsMobile {...props} />

LabelsWrapper.propTypes = {
  isDesktop: bool
}

export default compose(
  connect(({ products }) => ({
    entities: pathOr({}, ['metadata', 'ENTITIES'], products)
  })),
  display
)(LabelsWrapper)
