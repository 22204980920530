import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Invoices = loadable(() => import(/* webpackChunkName: "Invoices" */'pages/Invoices/Invoices'))

export default asyncConnect([
  {
    key: 'pageInvoicesContainer',
    promise: ({ helpers: { updateData }}) => {
      updateData.set({ view: 'invoicesContainer' })

      return updateData.update()
    }
  }
])(Invoices)