import { propOr, pathOr } from 'ramda'
import { connect } from 'react-redux'

import StorageModal from 'components/StorageModal'

export default connect(({ storage: { modalItem } }) => ({
  title: propOr('', 'TITLE', modalItem),
  detailText: propOr('', 'DETAIL_TEXT', modalItem),
  filePath: pathOr('', ['UP_FILE', 'PATH'], modalItem),
  fileSize: pathOr('0', ['UP_FILE', 'SIZE'], modalItem)
}))(StorageModal)
