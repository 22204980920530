import loadable from '@loadable/component'
import { propOr, prop } from 'ramda'
import { asyncConnect } from 'redux-connect'

import { NUMBER_12, END_DATE, START_DATE } from 'pages/Notifications/const'
import moment from 'utils/moment'
import parse from 'utils/querystring'

const Notifications = loadable(() => import(/* webpackChunkName: "Notifications" */'pages/Notifications/Notifications'))

export default asyncConnect([
  {
    key: 'pageNotifications',
    promise: ({ helpers: { updateData }, location }) => {
      const query = parse(prop('search', location))
      const page = Number(propOr(1, 'page', query))
      const type = propOr('', 'type', query)
      const sort = prop('sort', query)
      const order = prop('order', query)
      const limit = parseInt(propOr(NUMBER_12, 'limit', query), 10)
      const startDate = propOr(START_DATE, 'startDate', query)
      const endDate = propOr(END_DATE, 'endDate', query)
      const filter = type ? {
        'filter[DATE_FROM]': moment(startDate, 'DD.MM.YYYY').unix(),
        'filter[DATE_TO]': moment(endDate, 'DD.MM.YYYY').unix(),
        'filter[TYPE]': type
      } : {
        'filter[DATE_FROM]': moment(startDate, 'DD.MM.YYYY').unix(),
        'filter[DATE_TO]': moment(endDate, 'DD.MM.YYYY').unix()
      }
      const offset = (page - 1)* limit
      updateData.set({
        view: 'notifications',
        page,
        limit,
        offset,
        sort,
        order,
        filter
      })
      return updateData.update()
    }
  }
])(Notifications)
