/* eslint-disable complexity */
import { pathOr, prop, filter } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

export {
  getSuggests,
  getExtended,
  getHistory,
  getSearchProducts,
  getSearchCount,
  getIsSearch
} from './selector'

const HISTORY_LENGTH = 3

const initialState = {
  isSearch: false,
  suggests: [],
  extended: [],
  history: [],
  searchProducts: [],
  searchCount: 0
}

export const fetchSuggests = createAction('claim/FETCH_INVOICE_SUGGEST')
const fetchSuggestsSuccess = createAction('claim/FETCH_SUGGESTS_SUCCESS')
const fetchSuggestsFailure = createAction('claim/FETCH_SUGGESTS_FAILURE')
const fetchExtendedSuccess = createAction('claim/FETCH_EXTENDED_SUCCESS')
const fetchExtendedFailure = createAction('claim/FETCH_EXTENDED_FAILURE')
export const pushHistory = createAction('claim/PUSH_HISTORY')
export const clearHistory = createAction('claim/CLEAR_HISTORY')
export const fetchSearchProducts = createAction('claim/FETCH_PRODUCTS')
const fetchSearchProductsSuccess = createAction('claim/FETCH_PRODUCTS_SUCCESS')
const fetchSearchProductsFailure = createAction('claim/FETCH_PRODUCTS_FAILURE')

const requestExtended =
  ({ clientApi }) =>
    q =>
      clientApi
        .get('/v3/catalog/main/search/suggests/extended/', {
          params: { q }
        })
        .then(fetchExtendedSuccess)
        .catch(fetchExtendedFailure)

const requestSuggests =
  ({ clientApi }) =>
    q =>
      clientApi
        .get('/v3/catalog/main/search/suggests/', {
          params: { q }
        })
        .then(fetchSuggestsSuccess)
        .catch(fetchSuggestsFailure)

const requestProducts =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/sale/product/search/', {
          params
        })
        .then(fetchSearchProductsSuccess)
        .catch(fetchSearchProductsFailure)

const handleFetchSuggests = (state, params, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.batch([
      Effects.promise(requestSuggests({ clientApi }), params),
      Effects.promise(requestExtended({ clientApi }), params)
    ])
  )

const handleFetchExtendedSuccess = (state, payload) => {
  const list = pathOr([], ['data', 'response', 'SECTIONS'], payload)
  return {
    ...state,
    extended: list.map(item => ({ ...item, type: 'extended' }))
  }
}

const handleFetchSuggestsSuccess = (state, payload) => {
  const list = pathOr([], ['data', 'response', 'SUGGESTS'], payload)

  return {
    ...state,
    suggests: list.map(item => ({ NAME: item, ID: item, type: 'suggest' }))
  }
}

const handleFetchExtendedFailure = state => ({
  ...state,
  extended: []
})

const handleFetchSuggestsFailure = state => ({
  ...state,
  suggests: []
})

const handlePushHistory = (state, value) => {
  const history = prop('history', state)
  const newElement = value.trim()

  const list = history.slice()

  const isIncludes = history.some(item => prop('NAME', item) === value)

  if (newElement === '' || isIncludes) return { ...state }

  list.unshift({
    NAME: value
  })

  if (list.length > HISTORY_LENGTH) list.pop()

  return {
    ...state,
    history: list.map((item, i) => ({ ...item, ID: i, type: 'history' }), list)
  }
}

const handleClearHistory = (state, value) => ({
  ...state,
  history:
    value === -1
      ? []
      : filter(item => prop('ID', item) !== value, prop('history', state))
})

const handleFetchSearchProducts = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isSearch: true
    },
    Effects.promise(requestProducts({ clientApi }), params)
  )

const handleFetchSearchProductsSuccess = (state, payload) => ({
  ...state,
  isSearch: false,
  searchProducts: pathOr([], ['data', 'response', 'ITEMS'], payload),
  searchCount: /* pathOr([], ['data', 'response', 'NAV', 'CNT'], payload) */ 5
})

const handleFetchSearchProductsFailure = state => ({
  ...state,
  isSearch: false,
  searchProducts: [],
  searchCount: 0
})

export default createReducer(on => {
  on(fetchSuggests, handleFetchSuggests)
  on(fetchExtendedSuccess, handleFetchExtendedSuccess)
  on(fetchExtendedFailure, handleFetchExtendedFailure)
  on(fetchSuggestsSuccess, handleFetchSuggestsSuccess)
  on(fetchSuggestsFailure, handleFetchSuggestsFailure)
  on(pushHistory, handlePushHistory)
  on(clearHistory, handleClearHistory)
  on(fetchSearchProducts, handleFetchSearchProducts)
  on(fetchSearchProductsSuccess, handleFetchSearchProductsSuccess)
  on(fetchSearchProductsFailure, handleFetchSearchProductsFailure)
}, initialState)
