import { lensPath, set } from 'ramda'
import { createAction, createReducer } from 'redux-act'

export { getActive } from './selector'

export const activate = createAction('tabs/ACTIVATE')

const initialState = {}

const handleActivate = (state, { name, tab }) =>
  set(lensPath([name]), tab, state)

const reducer = createReducer(on => {
  on(activate, handleActivate)
}, initialState)

export default reducer
