/* eslint-disable complexity */
import React, { useMemo } from 'react'

import cx from 'classnames'
import { object, bool, func } from 'prop-types'
import { propOr, isEmpty, compose } from 'ramda'
import { formValues } from 'redux-form'

import Icon from 'components/Icon'
import s from 'components/Registration/Registration.scss'
import { setAnalyticsProps } from 'helpers/metaTags'
import Button from 'UI/Button'

const SubmitMessage = formValues({ email: 'email' })(
  ({ isError, errors, email, onSubmit }) => {
    const isCommonError = useMemo(() => compose(
      isEmpty,
      propOr('', 'inn')
    )(errors), [errors])

    return (
      <div className={s.registration__form}>
        <div className={s.message__wrapper}>
          <div className={cx(s.registration__field, s.alignCenter)}>
            <div className={cx(s.message__icon_wrap, {
              [s.message__icon_wrap_error]: isError
            })}>
              <Icon
                icon={isError ? 'status-error' : 'check'}
                className={cx(s.message__icon, {
                  [s.message__icon_error]: isError
                })}
              />
            </div>
            {isError && (
              <div className={s.registration__label}>
                {isCommonError ? 'Приносим свои извинения!' : 'Ваша организация уже зарегистрирована.'}
              </div>
            )}
            <div
              className={s.messageText}
              { ...setAnalyticsProps({
                type: 'action',
                group: 'registration',
                action: `${isError ? 'error' : 'success'}`
              }) }
            >
              {isError && isCommonError && (
                <>Произошла ошибка. Повторите попытку.</>
              )}
              {isError && !isCommonError && (
                <>Если Вы забыли свой пароль от личного кабинета, воспользуйтесь
                  <div
                    role='presentation'
                    className={s.link}
                    onClick={onSubmit(isCommonError)}
                  >
                    формой восстановления пароля
                  </div>
                </>
              )}
              {!isError && (
                <>
                  Заявка принята. На адрес {email} отправлено письмо с логином и паролем.
                  Для подтверждения регистрации перейдите по ссылке в письме.
                  Наш менеджер свяжется с Вами в течение 2-х рабочих дней для заключения договора поставки.
                </>
              )}
            </div>
          </div>
        </div>
        {isError && isCommonError && (
          <Button
            color='blue'
            size='big'
            onClick={onSubmit(isCommonError)}
            stretched
          >
            Заполнить снова
          </Button>
        )}
      </div>
    )
  }
)

SubmitMessage.propTypes = {
  isError: bool,
  errors: object,
  onSubmit: func
}

SubmitMessage.defaultProps = {
  isError: false
}

export default SubmitMessage
