import React from 'react'

import cx from 'classnames'
import { bool, number, string, oneOf } from 'prop-types'
import { equals } from 'ramda'

import s from './RadialProgress.scss'

const HUNDRED_PERCENT = 100

const RadialProgress = ({ progress, size, text, isHovered }) => {
  return (
    <div className={cx(s.radialProgress, s[`radialProgress${size}`])}>
      <div className={s.circle}>
        <div className={cx(s.mask, s[`rotate-${progress}`])}>
          <div
            className={cx(s.fill, s[`rotate-${progress}`], {
              [s.fillCompleted]: equals(progress, HUNDRED_PERCENT),
              [s.fillHovered]: isHovered
            })}
          />
        </div>
        <div className={s.mask}>
          <div
            className={cx(s.fill, s[`rotate-${progress}`], {
              [s.fillCompleted]: equals(progress, HUNDRED_PERCENT),
              [s.fillHovered]: isHovered
            })}
          />
          <div
            className={cx(s.fill, s[`rotateFix-${progress}`], {
              [s.fillCompleted]: equals(progress, HUNDRED_PERCENT),
              [s.fillHovered]: isHovered
            })}
          />
        </div>
      </div>
      <div className={s.inset}>
        <div
          className={cx(s.percentage, {
            [s.percentageHovered]: isHovered
          })}
        >
          {text || `${progress}%`}
        </div>
      </div>
    </div>
  )
}

RadialProgress.propTypes = {
  progress: number,
  text: string,
  size: oneOf(['small', 'medium']),
  isHovered: bool
}

RadialProgress.defaultProps = {
  progress: 0,
  text: '',
  size: 'medium',
  isHovered: false
}

export default RadialProgress
