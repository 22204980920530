import React, { PureComponent } from 'react'

import cx from 'classnames'
import { array, bool, func, string } from 'prop-types'
import { length } from 'ramda'

import Icon from 'components/Icon'

import styles from './ProductActions.scss'

class StoreSelect extends PureComponent {
  static propTypes = {
    stores: array,
    storeTitle: string,
    isOpen: bool,
    onOpen: func
  }

  static defaultProps = {
    isOpen: false,
    onOpen: () => {}
  }

  handleOpen = e => {
    this.props.onOpen(true)(e)
  }

  render() {
    const { stores, storeTitle, isOpen } = this.props
    return (
      <div className={styles.stockSelect}>
        <div className={styles.stockName}>
          <Icon icon='store'
            className={styles.stockIcon} />
          <span className={styles.stockSelectName}>Со склада:</span>
        </div>
        <div
          role='presentation'
          className={styles.stockSelectValue}
          onClick={this.handleOpen}
        >
          <span className={styles.stockSelectTitle}>{storeTitle}</span>
          {length(stores) > 1 && (
            <div className={styles.iconIndent}>
              <Icon
                icon='down'
                size='small'
                className={cx(styles.iconDropdown, {
                  [styles.iconUp]: isOpen,
                  [styles.iconDown]: !isOpen
                })}
              />
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default StoreSelect
