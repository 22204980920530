import React, { memo, useCallback, useMemo } from 'react'

import cx from 'classnames'
import debounce from 'lodash.debounce'
import { bool, func, number } from 'prop-types'
import { divide, map, multiply, pathOr } from 'ramda'

import Icon from 'components/Icon'

import s from './RatingStar.scss'

const NUMBER_100 = 100
const DEFAULT_STARS = 5
const DEBOUNCE_DELAY = 300

const getProgress = ({ rating, stars }) =>
  multiply(divide(rating, stars), NUMBER_100)

const RatingStar = ({
  handleClickStar,
  isInteractive = false,
  rating,
  stars = DEFAULT_STARS
}) => {
  const handleClick = useCallback(e => {
    // Используем e.target.parentNode.children, чтобы получить все звезды
    // Используем indexOf, чтобы получить индекс звезды, которую нажали
    const starIndex = Array.from(pathOr([], ['parentNode', 'children'], e.target)).indexOf(e.target)

    if (starIndex !== -1) {
      handleClickStar(starIndex + 1)
    }
  }, [handleClickStar])

  const debouncedHandleClick = useMemo(
    () => debounce(handleClick, DEBOUNCE_DELAY),
    [handleClick]
  )

  return (
    <div
      className={s.rating}
      onClick={isInteractive ? debouncedHandleClick : undefined}
      role='presentation'
    >
      <div className={s.ratingLine}>
        {map((_, index) => (
          <Icon
            className={cx(s.ratingLineStar, {
              [s.ratingLineStarInteractive]: isInteractive
            })}
            icon='star-status'
            key={index}
            size='middle'
          />
        ))([...Array(stars)])}
      </div>
      <div
        className={cx(s.ratingLine, s.ratingLineCurrent)}
        itemProp='ratingValue'
        style={{ width: `${getProgress({ rating, stars })}%` }}
      >
        {map((_, index) => (
          <Icon
            className={cx(s.ratingLineStarCurrent, {
              [s.ratingLineStarInteractive]: isInteractive
            })}
            icon='star-status'
            key={index}
            size='middle'
          />
        ))([...Array(stars)])}
      </div>
    </div>
  )
}

RatingStar.propTypes = {
  handleClickStar: func,
  isInteractive: bool,
  rating: number,
  stars: number
}

export default memo(RatingStar)
