/* eslint-disable complexity */
import {
  propOr,
  isEmpty,
  map,
  toUpper,
  compose,
  keys,
  prop,
  mergeAll,
  join,
  reduce,
  filter,
  split,
  fromPairs
} from 'ramda'

import moment from 'utils/moment'

export const MAX_PRODUCT_SHOW = 50
export const MIN_PRODUCT_SHOW = 5

export const getCabinetBreadcrumbs = (items = []) => [
  { title: 'Личный кабинет', pathname: '/cabinet' },
  ...items
]

export const cabinetFiltersFromQuery = (queryFilters = {}, stateFilters = {}) => {
  const { limit } = stateFilters

  const filtersFromQuery = isEmpty(queryFilters)
    ? {}
    : compose(
      mergeAll,
      map(key => {
        switch (key) {
          case 'p':
            return { offset: limit * (prop(key, queryFilters) - 1) }
          case 'order':
            return { order: prop(key, queryFilters) }
          case 'sortBy':
            return { sort: prop(key, queryFilters) }
          case 'startDate':
            return {
              'filter[DATE_FROM]': moment(
                prop(key, queryFilters),
                'DD.MM.YYYY'
              ).unix()
            }
          case 'endDate':
            return {
              'filter[DATE_TO]': moment(prop(key, queryFilters), 'DD.MM.YYYY')
                .endOf('day')
                .unix()
            }
          default:
            return { [`filter[${toUpper(key)}]`]: prop(key, queryFilters) }
        }
      }),
      keys
    )(queryFilters)
  return {
    ...filtersFromQuery
  }
}

export const parseFilters = query => {
  const filterList = propOr({}, 'filter', query)
  if (isEmpty(filterList)) return query
  const filterObj = compose(fromPairs, map(split(':')), split(';'))(filterList)
  const result = compose(
    mergeAll,
    map(key => ({
      [key]: split(',', propOr('', key, filterObj))
    })),
    keys
  )(filterObj)
  return { ...query, filter: result }
}

export const cabinetAnalyticsFilters = (queryFilters = {}) => {
  const filtersFromQuery = isEmpty(queryFilters)
    ? {}
    : compose(
      mergeAll,
      map(key => {
        switch (key) {
          case 'groupBy':
            return { group_by: prop(key, queryFilters) }
          case 'dateFrom':
            return {
              'filter[DATE_FROM]': moment(
                prop(key, queryFilters),
                'DD.MM.YYYY'
              )
                .utc()
                .unix()
            }
          case 'dateTo':
            return {
              'filter[DATE_TO]': moment(prop(key, queryFilters), 'DD.MM.YYYY')
                .utc()
                .endOf('day')
                .unix()

            }
          case 'period':
            const yesterday = moment().subtract(1, 'day').format('DD.MM.YYYY')
            const dateTo = moment(yesterday, 'DD.MM.YYYY')
              .utc()
              .endOf('day')
              .unix()
            const periodName = prop(key, queryFilters)
            switch (periodName) {
              case 'year':
                const yearAgo = moment()
                  .subtract(1, 'year')
                  .format('DD.MM.YYYY')
                return {
                  'filter[DATE_FROM]': moment(yearAgo, 'DD.MM.YYYY')
                    .utc()
                    .unix(),
                  'filter[DATE_TO]': dateTo
                }
              case 'month':
                const monthAgo = moment()
                  .subtract(1, 'month')
                  .format('DD.MM.YYYY')
                return {
                  'filter[DATE_FROM]': moment(monthAgo, 'DD.MM.YYYY')
                    .utc()
                    .unix(),
                  'filter[DATE_TO]': dateTo
                }
              case 'week':
                const weekAgo = moment()
                  .subtract(1, 'week')
                  .format('DD.MM.YYYY')
                return {
                  'filter[DATE_FROM]': moment(weekAgo, 'DD.MM.YYYY')
                    .utc()
                    .unix(),
                  'filter[DATE_TO]': dateTo
                }
              default:
                return {
                  'filter[DATE_FROM]': moment(yesterday, 'DD.MM.YYYY')
                    .utc()
                    .unix(),
                  'filter[DATE_TO]': dateTo
                }
            }
          case 'filter':
            const filters = propOr({}, key, queryFilters)
            const filtersObj = compose(
              mergeAll,
              map(keyFilter => ({
                [`filter[${toUpper(keyFilter)}]`]: propOr(
                  [],
                  keyFilter,
                  filters
                )
              })),
              keys
            )(filters)
            return filtersObj
          default:
            return { [`filter[${toUpper(key)}]`]: prop(key, queryFilters) }
        }
      }),
      keys
    )(queryFilters)
  return {
    'filter[DATE_FROM]': moment().utc().subtract(1, 'year').startOf('day').unix(),
    'filter[DATE_TO]': moment().utc().subtract(1, 'day').endOf('day').unix(),
    ...filtersFromQuery
  }
}

export const prepareFilters = query => {
  const filterList = compose(
    filter(item => !isEmpty(item)),
    propOr({}, 'filter')
  )(query)
  const filterForUrl = compose(
    join(';'),
    filter(item => !isEmpty(item)),
    reduce(
      (acc, key) => [
        ...acc,
        `${key}:${join(',', propOr([], key, filterList))}`
      ],
      ''
    ),
    keys
  )(filterList)
  const filterItems = isEmpty(filterForUrl)
    ? query
    : { ...query, filter: filterForUrl }
  return filterItems
}
