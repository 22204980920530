import React, { PureComponent } from 'react'

import pt from 'prop-types'
import R from 'ramda'

import Link from 'components/Link'
import Modal from 'containers/Modal'

import styles from './Notice.scss'

export default class NoticeAlertCatalog extends PureComponent {
  static propTypes = {
    items: pt.array,
    products: pt.object,
    hideModal: pt.func,
    setNoticeStatus: pt.func
  }

  static defaultProps = {
    items: [],
    setNoticeStatus: () => {}
  }

  handleClose = () =>
    this.props.setNoticeStatus({
      name: 'alert',
      page: 'catalog',
      status: false
    })

  handleClickLink = () => {
    this.handleClose()
    this.props.hideModal('alert_catalog')
    return true
  }

  render() {
    const { items, products } = this.props
    return (
      <Modal
        id='alert_catalog'
        title='Ошибка'
        size='middle'
        onClose={this.handleClose}
      >
        <div className={styles.table}>
          <div className={styles.tableHead}>
            <div className={styles.tableRow}>
              <div className={styles.tableColumnCode}>Код</div>
              <div className={styles.tableColumnName}>Наименование</div>
              <div className={styles.tableColumnMessage}>Описание ошибки</div>
            </div>
          </div>
          <div className={styles.tableBody}>
            {items.map(item => {
              const id = R.prop('id', item)
              const code = R.path([id, 'CODE_1C'], products)
              const name = R.path([id, 'NAME'], products)
              const message = R.prop('message', item)
              return (
                <div key={id}
                  className={styles.tableRow}>
                  <div className={styles.table__column}>
                    <span className={styles.column__name}>Код товара</span>
                    <span className={styles.column__value}>{code}</span>
                  </div>
                  <div className={styles.table__column}>
                    <span className={styles.column__name}>Наименование</span>
                    <span className={styles.column__value}>
                      <Link
                        to={`/catalog/product/${id}`}
                        onClick={this.handleClickLink}
                        isUnderline
                      >
                        {name}
                      </Link>
                    </span>
                  </div>
                  <div className={styles.table__column}>
                    <span className={styles.column__name}>Описание ошибки</span>
                    <span className={styles.column__value}>
                      <span
                        className={styles.messageError}
                        dangerouslySetInnerHTML={{ __html: message }} // eslint-disable-line react/no-danger
                      />
                    </span>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </Modal>
    )
  }
}
