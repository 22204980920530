/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string } from 'prop-types'

import Modal from 'containers/Modal'
import { display } from 'decorators/device'
import formatFileSize from 'helpers/formatFileSize'
import Button from 'UI/Button'
import Spacing from 'UI/Spacing'
import Title from 'UI/Title'

import style from './StorageModal.scss'

class StorageModal extends PureComponent {
  static propTypes = {
    isDesktop: bool,
    title: string,
    detailText: string,
    filePath: string,
    fileSize: string
  }

  render() {
    const { title, detailText, filePath, fileSize, isDesktop } = this.props

    const hasFile = filePath !== ''
    const fileSizeFormated = formatFileSize(Number.parseInt(fileSize, 10))
    const buttonTitle = `Скачать ${fileSizeFormated}`

    return (
      <Modal id='storage'
        size='middle'>
        <div
          className={cx(style.storageModal, {
            [style.storageModal_hasFile]: hasFile
          })}
        >
          <div className={style.header}>
            <Title
              size={16}>
              {title}
            </Title>
            {hasFile && (
              <Spacing margin={isDesktop ? 'left' : ''}>
                <Button
                  className={style.headerButton}
                  href={filePath}
                  target='_blank'
                  rel='noopener noreferrer'
                  icon='download'
                  color='whiteBlue'
                  size='tiny'
                >
                  {buttonTitle}
                </Button>
              </Spacing>
            )}
          </div>

          <div
            className={style.content}
            dangerouslySetInnerHTML={{ __html: detailText }} // eslint-disable-line react/no-danger
          />
          {hasFile && (
            <Spacing margin='top'>
              <Button
                className={style.downloadButton}
                href={filePath}
                target='_blank'
                rel='noopener noreferrer'
                icon='download'
                stretched={!isDesktop}
              >
                {buttonTitle}
              </Button>
            </Spacing>
          )}
        </div>
      </Modal>
    )
  }
}

export default display(StorageModal)
