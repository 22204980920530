import { pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import moment from 'utils/moment'

export {
  getMarksMenu,
  getIsLoaded,
  marksSuppliersSelector,
  isLoadedSuppliersSelector,
  isShowSuppliersSelector
} from './selector'

const initialState = {
  marks: [],
  suppliers: {
    data: {},
    isLoading: false,
    isLoaded: false
  },
  isLoading: false,
  isLoaded: false
}

export const fetch = createAction('marksMenu/FETCH')
export const fetchSuccess = createAction('marksMenu/FETCH_SUCCESS')
export const fetchFailure = createAction('marksMenu/FETCH_FAILURE')

export const fetchMarksSuppliers = createAction('marksMenu/FETCH_SUPPLIERS')
export const fetchMarksSuppliersSuccess = createAction(
  'marksMenu/FETCH_PRODUCTS_SUPPLIERS'
)
export const fetchMarksSuppliersFailure = createAction(
  'marksMenu/FETCH_PRODUCTS_SUPPLIERS'
)

const request =
  ({ clientApi }) =>
    ({ type = 'menu' }) => {
      const params = {
        contractor_id: clientApi.getContractorId(),
        mode: 'cache',
        datereq: moment().format('HHDDMMYYYY')
      }
      return clientApi
        .get(`/v3/catalog/main/marks/${type}/`, { params })
        .then(fetchSuccess)
        .catch(fetchFailure)
    }

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleFetchSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  marks: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  marks: []
})

const requestSuppliers =
  ({ clientApi }) =>
    () => {
      const params = {
        contractor_id: clientApi.getContractorId()
      }
      return clientApi
        .get('/v3/catalog/main/marks/products/top/', { params })
        .then(fetchMarksSuppliersSuccess)
        .catch(fetchMarksSuppliersFailure)
    }

const handleFetchMarksSuppliers = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      suppliers: {
        ...state.suppliers,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSuppliers({ clientApi }), payload)
  )

const handleFetchMarksSuppliersSuccess = (state, payload) => ({
  ...state,
  suppliers: {
    data: pathOr({}, ['data', 'response'], payload),
    isLoading: false,
    isLoaded: true
  }
})

const handleFetchMarksSuppliersFailure = state => ({
  ...state,
  suppliers: {
    ...state.suppliers,
    isLoading: false,
    isLoaded: false
  }
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(fetchMarksSuppliers, handleFetchMarksSuppliers)
  on(fetchMarksSuppliersSuccess, handleFetchMarksSuppliersSuccess)
  on(fetchMarksSuppliersFailure, handleFetchMarksSuppliersFailure)
}, initialState)

export default reducer
