import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const DEFAULT_TYPE = 'post'
const Content = loadable(() =>
  import(/* webpackChunkName: "Content" */ 'pages/Content/PageContent')
)

export default asyncConnect([
  {
    key: 'pageContent',
    promise: ({ helpers: { updateData }, match }) => {
      const id = pathOr('', ['params', 'id'], match)
      const type = pathOr(DEFAULT_TYPE, ['params', 'type'], match)
      updateData.set({ view: 'content', id, type })

      return updateData.update()
    }
  }
])(Content)
