import React, { PureComponent } from 'react'

import {
  bool,
  number,
  string,
  array,
  object,
  func,
  shape,
  any
} from 'prop-types'
import { append, pathOr, length, isEmpty, propOr } from 'ramda'

import Content from 'components/Content'
import Icon from 'components/Icon'
import Link from 'components/Link'
import MenuMainColumn from 'components/MenuMainColumn'
import RightColumn from 'components/MenuRightColumn'
import Portal from 'components/Portal'
import DisableBodyScroll from 'containers/DisableBodyScroll'
import { getMenuLink } from 'helpers/catalog'
import { setAnalyticsProps } from 'helpers/metaTags'

import styles from './MenuCatalog.scss'

let mousemoveListener = 0
let mouseLocs = []
const outerWidth = 1190
const DELAY_MOUSEMOVE = 350
const MOUSEMOVE_FRAMES = 3
const MARGIN_TOP = 10
const TOP_BUTTONS_HEIGHT = 66

export default class MenuCatalog extends PureComponent {
  static propTypes = {
    catalog: object,
    bookmarkData: array,
    isBookmark: bool,
    bookmark: number,
    selectedItemId: number,
    catalogHeight: number,
    isUserWithoutPassword: bool,
    activeSection: string,
    setCatalogHeight: func,
    setCatalogVisible: func,
    selectCatalogItem: func,
    setListening: func,
    bookmarkAccess: bool,
    isListening: bool,
    buttonCatalog: shape({ current: any })
  }

  static defaultProps = {
    catalogHeight: 0,
    bookmarkData: [],
    catalog: {
      favoriteItems: [],
      catalogItems: []
    },
    setCatalogHeight: () => {},
    setCatalogVisible: () => {},
    selectCatalogItem: () => {},
    setListening: () => {}
  }

  componentDidMount() {
    const { catalog } = this.props
    const itemId = pathOr(0, ['catalogItems', 0, 'ID'], catalog)
    this.props.selectCatalogItem(itemId)
    if (typeof window !== 'undefined') {
      setTimeout(this.init, 0)
      window.addEventListener('click', this.handleClickOutside, true)
    }
    if (typeof document !== 'undefined' && mousemoveListener === 0) {
      document.addEventListener('mousemove', this.handleMouseMoveDocument)
    }

    mousemoveListener += 1
  }

  componentDidUpdate(prevProps) {
    if (
      length(this.props.catalog.favoriteItems) !==
        length(prevProps.catalog.favoriteItems) ||
      length(this.props.catalog.catalogItems) !==
        length(prevProps.catalog.catalogItems)
    ) {
      setTimeout(this.init, 0)
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('click', this.handleClickOutside, true)
    }
    mousemoveListener -= 1
    if (typeof document !== 'undefined' && mousemoveListener === 0) {
      document.removeEventListener('mousemove', this.handleMouseMoveDocument)
      mouseLocs = []
    }

    this.menuTimer = null
  }

  handleMouseMoveDocument = e => {
    const locs = {
      x: e.pageX,
      y: e.pageY
    }
    mouseLocs = append(locs, mouseLocs)
    if (mouseLocs.length > MOUSEMOVE_FRAMES) {
      mouseLocs.shift()
    }
  }

  handleSelect = id => {
    if (!id) {
      this.props.setCatalogVisible(false)
      this.props.setListening(false)
      return
    }
    if (!this.props.isListening) {
      this.props.setListening(true)
      this.props.selectCatalogItem(id)
    } else {
      if (this.menuTimer) {
        clearTimeout(this.menuTimer)
      }
      this.possiblyActivate(id)
    }
  }

  handleHideCatalog = () => {
    this.props.setCatalogVisible(false)
    this.props.selectCatalogItem(undefined)
    this.props.setListening(false)
  }

  handleClickOutside = event => {
    const { buttonCatalog } = this.props
    if (
      this.menuCatalog &&
      !this.menuCatalog.contains(event.target) &&
      !buttonCatalog.contains(event.target)
    ) {
      this.handleHideCatalog()
    }
  }

  setDelay = () => {
    if (!this.menuCatalog || !this.mainColumn) {
      return null
    }
    const itemOffset = this.offset(this.menuCatalog)
    const mainColumnOffset = this.offset(this.mainColumn)
    const scrollTop = typeof window === 'undefined' ? 0 : window.pageYOffset
    const outerHeight = pathOr(0, ['menuCatalog', 'offsetHeight'], this)
    const upperRight = {
      x: itemOffset.left + outerWidth,
      y: itemOffset.top
    }
    const lowerRight = {
      x: upperRight.x,
      y: itemOffset.top + outerHeight + scrollTop
    }

    const loc = mouseLocs[mouseLocs.length - 1]
    let prevLoc = mouseLocs[0]

    if (!loc) {
      return 0
    }

    if (!prevLoc) {
      prevLoc = loc
    }

    // если курсор за пределами меню
    if (
      prevLoc.x < itemOffset.left ||
      prevLoc.x > lowerRight.x ||
      prevLoc.y < itemOffset.top ||
      prevLoc.y > lowerRight.y
    ) {
      return 0
    }
    // если курсор в rightColumn
    if (
      loc.x > prevLoc.x &&
      loc.x > this.mainColumn.offsetWidth + mainColumnOffset.left
    ) {
      return null
    }
    // если курсор не поменял локации
    if (
      this.lastDelayLoc &&
      loc.x === this.lastDelayLoc.x &&
      loc.y === this.lastDelayLoc.y
    ) {
      return 0
    }
    // если курсор перемещается вправо в rightColumn, устанавливаем задержку
    if (loc.x > prevLoc.x) {
      this.lastDelayLoc = loc
      return DELAY_MOUSEMOVE
    }
    this.lastDelayLoc = null
    return 0
  }

  offset = el => {
    const rect = el.getBoundingClientRect()
    return {
      top: rect.top + document.body.scrollTop,
      left: rect.left + document.body.scrollLeft
    }
  }

  init = () => {
    if (!this.menuCatalog) {
      return
    }

    if (!this.mainColumn) {
      return
    }

    const catalogHeight = this.menuCatalog.offsetHeight
    this.props.setCatalogHeight(catalogHeight)
  }

  possiblyActivate = id => {
    const delay = this.setDelay()
    if (delay === 0) {
      this.props.selectCatalogItem(id)
    } else if (!delay && this.menuTimer) {
      clearTimeout(this.menuTimer)
    } else {
      this.menuTimer = setTimeout(() => {
        this.possiblyActivate(id)
      }, delay)
    }
  }

  renderFavoriteItem = item => {
    const { isBookmark, bookmark } = this.props
    const link = getMenuLink({ isBookmark, bookmark, item })
    return (
      <div
        key={propOr('', 'ID', item)}
        className={styles.favoriteItem}
        {...setAnalyticsProps({ type: 'action', group: 'menuFavoriteItem' })}
      >
        <Link
          to={link}
          onClick={this.handleHideCatalog}
        >
          <span className={styles.link}>
            <Icon icon='favorite-catalog'
              className={styles.favoriteItem_icon} />
            <span className={styles.favoriteItem_title}>
              {propOr('', 'NAME', item)}
            </span>
          </span>
        </Link>
      </div>
    )
  }

  render() {
    const {
      selectedItemId,
      activeSection,
      isBookmark,
      bookmark,
      selectCatalogItem,
      bookmarkAccess,
      bookmarkData,
      isUserWithoutPassword,
      catalogHeight,
      catalog: { favoriteItems, catalogItems }
    } = this.props

    return (
      <DisableBodyScroll>
        <Portal>
          <div className={styles.catalogOverlay}>
            <div
              className={styles.menuCatalog}
              ref={ref => {
                this.menuCatalog = ref
              }}
            >
              <Content isMainMenu>
                <div
                  className={styles.columnLeft}
                  ref={ref => {
                    this.mainColumn = ref
                  }}
                >
                  <MenuMainColumn
                    catalogItems={catalogItems}
                    bookmarkData={bookmarkData}
                    bookmarkAccess={bookmarkAccess}
                    selectedItemId={selectedItemId}
                    activeSection={activeSection}
                    isUserWithoutPassword={isUserWithoutPassword}
                    mainColumnHeight={catalogHeight - TOP_BUTTONS_HEIGHT}
                    onSelect={this.handleSelect}
                    isBookmark={isBookmark}
                    bookmark={bookmark}
                    onHideCatalog={this.handleHideCatalog}
                    setItemLink={this.setItemLink}
                    selectCatalogItem={selectCatalogItem}
                  />
                </div>
                <div
                  className={styles.rightColumnWrapper}
                  style={{ height: catalogHeight - MARGIN_TOP }}
                >
                  {!isEmpty(favoriteItems) && (
                    <div className={styles.favoriteItemWrapper}>
                      {favoriteItems.map(this.renderFavoriteItem)}
                    </div>
                  )}
                  <RightColumn
                    favoriteItems={favoriteItems}
                    selectedItemId={selectedItemId}
                    isBookmark={isBookmark}
                    bookmark={bookmark}
                    catalogHeight={
                      isEmpty(favoriteItems)
                        ? catalogHeight - MARGIN_TOP
                        : catalogHeight - TOP_BUTTONS_HEIGHT
                    }
                    onHideCatalog={this.handleHideCatalog}
                  />
                </div>
              </Content>
            </div>
          </div>
        </Portal>
      </DisableBodyScroll>
    )
  }
}
