/* eslint-disable complexity */
import {
  has,
  pathOr,
  prop,
  propOr,
  isEmpty,
  path,
  compose,
  join,
  map,
  filter,
  keys
} from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'
import { snakeToCamel } from 'helpers/toCamelCase'

const tabs = [
  {
    id: 'shortfall',
    title: 'Недостача',
    count: 0,
    images: [],
    comment: ''
  },
  {
    id: 'marriage',
    title: 'Брак',
    count: 0,
    images: [],
    comment: ''
  },
  {
    id: 'excess',
    title: 'Излишек',
    count: 0,
    images: [],
    comment: ''
  },
  {
    id: 'refusal',
    title: 'Отказ от товара',
    count: 0,
    images: [],
    comment: ''
  },
  {
    id: 'notorder',
    title: 'Излишек не из накладной',
    count: 0,
    images: [],
    comment: ''
  }
]

export const getInvoice = createSelector(
  state => state,
  state => prop('invoice', state)
)

export const getProducts = createSelector(
  state => state,
  state => prop('products', state)
)

export const getIsLoading = createSelector(
  state => state,
  state => prop('isLoading', state)
)

export const getIsLoaded = createSelector(
  state => state,
  state => prop('isLoaded', state)
)

export const getSortList = createSelector(
  state => state,
  state =>
    compose(
      map(item =>
        renameKeys(snakeToCamel)({
          ...item,
          value: `${prop('SORT', item)}_${prop('ORDER', item)}`
        })
      ),
      prop('sortList')
    )(state)
)

export const getCount = createSelector(
  state => state,
  state => prop('count', state)
)

export const getWithoutInfo = createSelector(
  state => state,
  state => prop('withoutInfo', state)
)

export const getIsError = createSelector(
  state => state,
  state => prop('isError', state)
)

export const getSubmitErrors = createSelector(
  state => state,
  state => prop('submitErrors', state)
)

export const getLoadingProductsIds = createSelector(
  state => state,
  state => prop('loadingProductsIds', state)
)

export const getIsLoadingCategory = createSelector(
  state => state,
  state => prop('isLoadingCategory', state)
)

export const getCategories = createSelector(
  state => state,
  state => prop('categories', state)
)

export const getIsSubmitError = createSelector(getCategories, categories =>
  Object.keys(categories).some(key => !pathOr(true, [key, 'VALID'], categories))
)

export const getIsSubmitErrorText = createSelector(getCategories, categories =>
  compose(
    join(', '),
    map(key => path([key, 'DESCRIPTIONS'], categories)),
    filter(key => !pathOr(true, [key, 'VALID'], categories)),
    keys
  )(categories)
)

export const getIsFilled = createSelector(getCategories, categories =>
  Object.keys(categories).some(key => {
    const category = propOr({}, key, categories)
    const count = propOr(0, 'CNT', category)
    if (key === 'notorder') {
      const comment = propOr('', 'COMMENT', category)
      const images = propOr([], 'IMAGES', category)

      return count > 0 || comment !== '' || !isEmpty(images)
    }
    return count > 0
  })
)

export const getTabs = createSelector(getCategories, categories =>
  tabs.map(item => {
    const id = prop('id', item)
    if (has(id, categories)) {
      const category = propOr({}, id, categories)
      const newTab = {
        ...item,
        count: propOr(0, 'CNT', category),
        images: propOr([], 'IMAGES', category),
        comment: propOr('', 'COMMENT', category)
      }

      if (!prop('VALID', category)) {
        newTab.type = 'error'
        newTab.tooltip = 'Заполните обязательные\u00a0поля'
      }

      return newTab
    }
    return item
  })
)

export const getUpdatedCategories = createSelector(
  state => state,
  state => prop('updatedCategories', state)
)

export const getCheckedTabs = createSelector(
  getTabs,
  getUpdatedCategories,
  (list, updatedCategories) =>
    list.filter(
      item =>
        prop('count', item) !== 0 ||
        prop('comment', item) !== '' ||
        !isEmpty(prop('images', item)) ||
        updatedCategories.includes(prop('id', item))
    )
)

export const getStatus = createSelector(
  state => state,
  state => prop('status', state)
)

export const getIsLoadingComments = createSelector(
  state => state,
  state => prop('isLoadingComments', state)
)

export const getOperations = createSelector(
  state => state,
  state => prop('operations', state)
)

export const getAddedProductsIds = createSelector(
  state => state,
  state => prop('addedProductsIds', state)
)

export const getIsLoadingPreview = createSelector(
  state => state,
  state => prop('isLoadingPreview', state)
)

export const getPreviewProducts = createSelector(
  state => state,
  state => prop('previewProducts', state)
)

export const getMainImages = createSelector(
  state => state,
  state => prop('mainImages', state)
)

export const getMainComment = createSelector(
  state => state,
  state => prop('mainComment', state)
)
