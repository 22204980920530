/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { push } from 'connected-react-router'
import { string, number, array, func, bool } from 'prop-types'
import { prop, path, slice } from 'ramda'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { createSelector } from 'reselect'

import Icon from 'components/Icon'
import BasketItem from 'containers/MiniBasket/BasketItem'
import { setAnalyticsProps } from 'helpers/metaTags'
import { basketsLoadingSelector } from 'redux/modules/personal'
import { getItemsByLoyaltyBasket } from 'redux/modules/productList'
import Preloader from 'UI/Preloader'
import noun from 'utils/noun'

import styles from './MiniBasket.scss'

const COUNT_MINI_BASKET = 5

const basketItemsSelector = createSelector(
  productList => getItemsByLoyaltyBasket(productList),
  items => slice(0, COUNT_MINI_BASKET, items)
)

@connect(
  ({ loyalty, personal, productList }) => {
    const points = path(['status', 'BALANCE'], personal)
    const pointsDimension = `${noun(points, ['балл', 'балла', 'баллов'])}`
    const items = basketItemsSelector(productList)
    return {
      loyalty,
      isLoading: basketsLoadingSelector(personal, 'mini'),
      items,
      points,
      pointsDimension
    }
  },
  {
    push
  }
)
class MiniBasket extends PureComponent {
  static propTypes = {
    fetchData: func,
    isBookmark: bool,
    isFixed: bool,
    isLoading: bool,
    items: array,
    itemsCount: number,
    itemsSum: number,
    favoriteCount: number,
    compareCount: number,
    bookmark: number,
    sumProductPoints: number,
    cntProductPoints: number,
    catalog: string,
    cartLink: string,
    pointsDimension: string
  }

  static defaultProps = {
    fetchData: () => {},
    isBookmark: false,
    isFixed: false,
    isLoading: false,
    items: [],
    bookmark: 0,
    cntProductPoints: 0,
    sumProductPoints: 0,
    catalog: 'main'
  }

  componentDidMount() {
    this.props.fetchData('mini')
  }

  renderItems = () => {
    const { items, pointsDimension, catalog } = this.props
    return items.length > 0 ? (
      <div className={styles.miniBasketItems}>
        {items.map(item => (
          <BasketItem
            key={prop('ID', item)}
            id={prop('ID', item)}
            isLoyalty
            pointsDimension={pointsDimension}
            catalog={catalog}
          />
        ))}
      </div>
    ) : (
      <div className={styles.emptyBasketMessage}>
        <span>Ваша корзина пуста</span>
      </div>
    )
  }

  render() {
    const { items, itemsCount, cartLink, isFixed, isLoading } = this.props
    const hiddenItemsCount =
      itemsCount - items.length > 0 ? itemsCount - items.length : 0
    return (
      <div
        className={cx(styles.headerDropDown, styles.headerDropDownCart, {
          [styles.dropDownCartEmpty]: items.length === 0,
          [styles.fixedBasket]: isFixed,
          [styles.loadingBasket]: isLoading
        })}
      >
        {isLoading ? (
          <Preloader active={isLoading} />
        ) : (
          <div
            className={cx(styles.miniBasket, {
              [styles.miniBasketEmpty]: items.length === 0
            })}
          >
            {this.renderItems()}
            <div
              className={cx(
                styles.moreMiniBasketItems,
                styles.moreMiniBasketItems_loyalty
              )}
            >
              {itemsCount > items.length && (
                <Link to={cartLink}>
                  <div className={styles.miniBasketLinkBasket}>
                    <Icon
                      icon='cart-loyalty'
                      className={styles.miniBasketCartIcon}
                      size='big'
                    />
                    {hiddenItemsCount > 0 && (
                      <span className={styles.moreMiniBasketItemsLink}>
                        Показать еще {hiddenItemsCount}{' '}
                        {noun(hiddenItemsCount, [
                          'подарок',
                          'подарка',
                          'подарков'
                        ])}{' '}
                        &rarr;
                      </span>
                    )}
                  </div>
                </Link>
              )}
            </div>
            <div
              className={cx(styles.miniBasketActions, {
                [styles.isEmpty]: items.length === 0
              })}
            >
              {items.length !== 0 && (
                <Link
                  to={cartLink}
                  {...setAnalyticsProps({
                    type: 'action',
                    group: 'miniBasket',
                    action: 'goBasketLoyalty'
                  })}
                >
                  <span className={styles.basketLink}>В корзину</span>
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    )
  }
}
export default MiniBasket
