/* eslint-disable complexity */
import React from 'react'

import cx from 'classnames'
import { array, bool, func, number, oneOfType, string } from 'prop-types'
import { findLast, length, propEq, propOr } from 'ramda'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

import ButtonProgress from 'components/ButtonProgress'
import Icon from 'components/Icon'
import { CartLoading, CartAction } from 'components/Placeholder'
import { compareCount as compareCountSelector } from 'redux/modules/compare'
import { favoriteCount as favoriteCountSelector } from 'redux/modules/favorite'
import { showModal } from 'redux/modules/modal'
import {
  costMultipleSelector,
  basketsLoadingSelector
} from 'redux/modules/personal'
import { cartSelector, cartsSelector } from 'redux/modules/productList'
import { bookmarkSelector, isBookmarkSelector } from 'redux/modules/settings'
import Button from 'UI/Button'

import s from './CartMini.scss'
import Products from './Products'
import CartItem from '../CartChange/CartItem'

const CartMini = ({
  isFixed = false,
  isLoading = false,
  isBookmark,
  catalog,
  cart = 0,
  compareCount = 0,
  favoriteCount = 0,
  carts = [],
  onShowModal = () => {}
}) => {
  const cartLink = `/basket/${catalog}/${cart}`

  const isManyCarts = length(carts) > 1

  const onChange = () => () => onShowModal('cart-change')

  const currentCart = findLast(propEq('ID', Number(cart)), carts)

  const productCnt = propOr(0, 'CNT', currentCart)

  return (
    <div
      className={cx(s.cart, {
        [s.cartFixed]: isFixed
      })}
    >
      <div className={s.wrapper}>
        <ButtonProgress isMiniBasket />
        <div className={s.current}>
          {isLoading ? <CartLoading /> : <CartItem item={currentCart}
            isWide />}
        </div>
        <div className={s.action}>
          {isLoading ? (
            <CartAction />
          ) : (
            <>
              {!isBookmark && (
                <Button
                  color='main'
                  size='tiny'
                  icon={isManyCarts ? 'cart-change-mini' : 'cart-add-mini'}
                  onClick={onChange()}
                >
                  {isManyCarts ? 'Сменить корзину' : 'Добавить новую'}
                </Button>
              )}
              <Link to='/favorite'>
                <div className={cx(s.favorite, {
                  [s.favoriteDisabled]: favoriteCount <= 0
                })}>
                  <Icon icon='heart'
                    className={s.icon} />
                  <span className={s.favoriteName}>
                    Избранное ({favoriteCount})
                  </span>
                </div>
              </Link>
              <Link to='/compare'>
                <div className={cx(s.favorite, {
                  [s.favoriteDisabled]: compareCount <= 0
                })}>
                  <Icon icon='compare'
                    className={s.icon} />
                  <span className={s.favoriteName}>
                    Сравнение ({compareCount})
                  </span>
                </div>
              </Link>
            </>
          )}
        </div>
        <div className={s.hr} />
        <Products catalog={catalog}
          cart={cart}
          productCnt={productCnt} />
        <div className={s.hr} />
        <div className={s.link}>
          <Link to={cartLink}>
            <span className={s.basketLink}>{isBookmark ? 'Перейти в закладку' : 'Перейти в корзину'}</span>
          </Link>
        </div>
      </div>
    </div>
  )
}

CartMini.propTypes = {
  onShowModal: func,
  carts: array,
  isFixed: bool,
  isLoading: bool,
  isBookmark: bool,
  cart: oneOfType([string, number]),
  catalog: string,
  compareCount: number,
  favoriteCount: number
}

export default connect(
  ({ productList, favorite, compare, settings, personal }) => ({
    carts: cartsSelector(productList, settings),
    cart: cartSelector(productList),
    bookmark: bookmarkSelector(settings),
    compareCount: compareCountSelector(compare),
    favoriteCount: favoriteCountSelector(favorite),
    costMultiple: costMultipleSelector(personal),
    isLoading: basketsLoadingSelector(personal, 'mini'),
    isBookmark: isBookmarkSelector(settings)
  }),
  {
    onShowModal: showModal
  }
)(CartMini)
