/* eslint-disable complexity */
import React from 'react'

import { bool, string } from 'prop-types'
import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'

import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import Link from 'components/Link'
import config from 'config'
import { isDesktopSelector } from 'redux/modules/device'
import {
  getAddress,
  getName,
  getOfficesAccess,
  getPhone
} from 'redux/modules/personal'
import { getTheme } from 'redux/modules/theme'
import { isAuthSelector } from 'redux/modules/token'
import Spacing from 'UI/Spacing'

import s from './HeaderPhone.scss'

const HeaderPhone = ({ className, isHideTitle }) => {
  const isDesktop = useSelector(({ device }) => isDesktopSelector(device))
  const address = useSelector(({ personal }) => getAddress(personal))
  const name = useSelector(({ personal }) => getName(personal))
  const phone = useSelector(({ personal }) => getPhone(personal))
  const hasAccessOffices = useSelector(({ personal }) =>
    getOfficesAccess(personal)
  )
  const { theme } = useSelector(state => getTheme(state))
  const isAuth = useSelector(({ token }) => isAuthSelector(token))

  const link = hasAccessOffices ? '/offices' : null
  const handleGetThemeIcon = icon => {
    return pathOr('', ['themes', theme, 'icons', icon], config)
  }

  return (
    <HeaderMenuItem
      iconPath={handleGetThemeIcon('phone')}
      iconWrapper='phone'
      linkTo={link}
      text={phone}
      dropDownClassName={s.dropdownPhone}
      className={className}
      emptyTextLine={isHideTitle}
      renderWhenUserIsNotLogged={!isAuth}
    >
      {isDesktop && (
        <>
          <span className={s.dropdownPhoneName}>{name}</span>
          <span className={s.dropdownPhoneAddress}>{address}</span>
          {hasAccessOffices && (
            <Link to='/offices'
              color='blue'>
              <Spacing margin='top'>Больше информации &rarr;</Spacing>
            </Link>
          )}
        </>
      )}
    </HeaderMenuItem>
  )
}

HeaderPhone.propTypes = {
  isHideTitle: bool,
  className: string
}

export default HeaderPhone
