/* eslint-disable complexity */
import { createAction, createReducer } from 'redux-act'

const initialState = {
  isVisible: false,
  isLoading: false,
  isLoaded: false,
  selectedItemId: undefined,
  catalogHeight: 0,
  data: {},
  isFixed: false,
  isListening: false,
  rightMenuTop: 0,
  top: 0
}

export {
  getCatalogIsVisible,
  getSelectedItemId,
  getCatalogHeight
} from './selector'

export const setCatalogVisible = createAction('catalog/SET_CATALOG_VISIBLE')
export const setCatalogHeight = createAction('catalog/SET_CATALOG_HEIGHT')
export const setCatalogVisibleBoard = createAction(
  'catalog/SET_CATALOG_VISIBLE_BOARD'
)
export const selectCatalogItem = createAction('catalog/SELECT_CATALOG_ITEM')
export const setIsFixed = createAction('catalog/SET_IS_FIXED')
export const setTop = createAction('catalog/SET_TOP')
export const setTopRightMenu = createAction('catalog/SET_TOP_RIGHT_MENU')
export const setListening = createAction('catalog/SET_LISTENING')

const handleSetVisible = (state, isVisible) =>
  state.isVisible === isVisible && state.selectedItemId === undefined
    ? state
    : {
      ...state,
      isVisible,
      selectedItemId: isVisible ? state.selectedItemId : undefined
    }

const handleSetHeight = (state, catalogHeight) =>
  state.catalogHeight === catalogHeight
    ? state
    : {
      ...state,
      catalogHeight
    }

const handleSelectCatalogItem = (state, payload) =>
  state.selectedItemId === payload
    ? state
    : {
      ...state,
      selectedItemId: payload
    }

const handleIsFixed = (state, payload) =>
  state.isFixed === payload
    ? state
    : {
      ...state,
      isFixed: payload
    }

const handleTop = (state, payload) =>
  state.top === payload
    ? state
    : {
      ...state,
      top: payload
    }

const handleTopRightMenu = (state, payload) =>
  state.rightMenuTop === payload
    ? state
    : {
      ...state,
      rightMenuTop: payload
    }
const handleSetListening = (state, payload) =>
  state.isListening === payload
    ? state
    : {
      ...state,
      isListening: payload
    }

const reducer = createReducer(on => {
  on(setCatalogVisible, handleSetVisible)
  on(setCatalogHeight, handleSetHeight)
  on(selectCatalogItem, handleSelectCatalogItem)
  on(setIsFixed, handleIsFixed)
  on(setTop, handleTop)
  on(setTopRightMenu, handleTopRightMenu)
  on(setListening, handleSetListening)
}, initialState)

export default reducer
