import React, { memo } from 'react'

import { useLocation } from 'react-router'

import LeftMenu from 'containers/LeftMenu'

const PersonalContent = () => {
  const location = useLocation()
  return (
    <LeftMenu
      location={location}
      isMobileMenu
    />
  )
}

export default memo(PersonalContent)
