import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const Checkout = loadable(() =>
  import(/* webpackChunkName: "Checkout" */ 'pages/Checkout/Checkout')
)

const BillsInfoStore = loadable(
  () =>
    import(/* webpackChunkName: "BillsInfoStore" */ 'pages/Checkout/Checkout'),
  {
    resolveComponent: components => components.BillsInfoStore
  }
)

const CommentInfo = loadable(
  () => import(/* webpackChunkName: "CommentInfo" */ 'pages/Checkout/Checkout'),
  {
    resolveComponent: components => components.CommentInfoStore
  }
)

const DeliveryInfo = loadable(
  () =>
    import(/* webpackChunkName: "DeliveryInfo" */ 'pages/Checkout/Checkout'),
  {
    resolveComponent: components => components.DeliveryInfoStore
  }
)

const CheckoutInfoStore = loadable(
  () =>
    import(
      /* webpackChunkName: "CheckoutInfoStore" */ 'pages/Checkout/Checkout'
    ),
  {
    resolveComponent: components => components.CheckoutInfoStore
  }
)

const CheckoutInfo = loadable(
  () =>
    import(/* webpackChunkName: "CheckoutInfo" */ 'pages/Checkout/Checkout'),
  {
    resolveComponent: components => components.CheckoutInfo
  }
)

export {
  BillsInfoStore,
  DeliveryInfo,
  CommentInfo,
  CheckoutInfoStore,
  CheckoutInfo
}

export default asyncConnect([
  {
    key: 'pageCheckout',
    promise: ({ helpers: { updateData }, match }) => {
      const id = pathOr('', ['params', 'id'], match)
      updateData.set({
        view: 'checkout',
        id
      })

      return updateData.update()
    }
  }
])(Checkout)
