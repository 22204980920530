/* eslint-disable complexity */
import React, { useMemo, useCallback } from 'react'

import cx from 'classnames'
import { func, bool, array, object, string } from 'prop-types'
import { propOr, pathOr } from 'ramda'
import { formValues } from 'redux-form'

import InputError from 'components/InputError'
import Autocomplete from 'UI/Autocomplete'
import Button from 'UI/Button'

import s from '../Registration.scss'

const AutocompleteItem = item => (
  <div
    key={propOr('', 'inn', item)}
    className={s.company__info}
  >
    <p className={s.company__title}>{propOr('', 'value', item)}</p>
    <div>
      <span className={s.company__inn}>{pathOr('', ['data', 'inn'], item)}</span>
      <span>{pathOr('', ['data', 'address', 'value'], item)}</span>
    </div>
  </div>
)

const RegistrationAutocomplete = formValues({ address: 'address', inn: 'inn' })(({
  input, meta, onChangeAutocomplete, onClearAutocomplete, address, inn, isReadOnly, ...rest
}) => {

  const isError = useMemo(() => meta.invalid && meta.visited && meta.error, [meta])
  
  const handleCheckAutocomplete = useCallback(() => {
    const inputValue = propOr('', 'value', input)
    // если необходимо выбрать из списка, чтобы заполнить все скрытые поля
    // но пользователь ввел данные вручную и пошел дальше
    // стираем то, что он ввел
    if (inputValue && (!address || !inn)) {
      onClearAutocomplete()
    }
  }, [input, address, inn, onClearAutocomplete])

  return (
    <div className={s.autocomplete__wrapper}>
      <div className={cx({
        [s.autocomplete__wrapper_readOnly]: isReadOnly
      })}>
        <Autocomplete
          {...input}
          {...rest}
          error={isError}
          renderItem={AutocompleteItem}
          onChange={onChangeAutocomplete(input)}
          onClickOutside={handleCheckAutocomplete}
        />
      </div>
      {isReadOnly && (
        <div className={s.autocomplete__clear}>
          <Button
            icon='close'
            onClick={onClearAutocomplete}
            shape='circle'
            color='transparent'
          />
        </div>
      )}
      {isError && (
        <InputError message={meta.error} />
      )}
    </div>
  )
})

RegistrationAutocomplete.propTypes = {
  onChangeAutocomplete: func,
  onClearAutocomplete: func,
  onBlur: func,
  fetch: func,
  disabled: bool,
  isReadOnly: bool,
  items: array,
  input: object,
  address: string,
  inn: string,
  meta: object
}

RegistrationAutocomplete.defaultProps = {
  onChangeAutocomplete: () => {},
  onClearAutocomplete: () => {},
  onBlur: () => {},
  items: [],
  isReadOnly: false
}

export default RegistrationAutocomplete
