/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from 'react'

import cx from 'classnames'
import { object } from 'prop-types'
import { filter, path, pathOr, prop } from 'ramda'
import { useDispatch, useSelector } from 'react-redux'

import Icon from 'components/Icon'
import Modal from 'containers/Modal'
import { forAuth } from 'decorators/user'
import ProgressLine from 'pages/Checkout/ProgressLine'
import {
  allInvoicesAddressSelector,
  allInvoicesContractorsSelector,
  allInvoicesErrorSelector,
  allInvoicesIsErrorSelector,
  allInvoicesIsLoadingSelector,
  allInvoicesItemsSelector,
  allInvoicesTypeDeliverySelector,
  fetchInvoice
} from 'redux/modules/order'
import Preloader from 'UI/Preloader'
import Select from 'UI/Select'
import moment from 'utils/moment'

import s from './ProgressInvoice.scss'

const Invoice = ({ invoice = {} }) => {
  return (
    <div className={s.invoice}>
      <div className={s.invoiceHead}>
        <div className={s.invoiceHeadTitle}>{prop('TITLE', invoice)}</div>
        <div className={s.invoiceHeadStatus}>
          <Icon
            icon='clock'
            className={cx(s.icon, s.iconClock)}
          />
          <span className={s.invoiceHeadWaitText}>Ожидание заказов до:</span>
          {moment(path(['DATE_END'], invoice), 'X').locale('ru').format('DD.MM.YYYY HH:mm')}
        </div>
      </div>
      <div className={s.invoiceProgress}>
        <ProgressLine
          isPay={pathOr(false, ['ALREADY_PAID'], invoice)}
          sum={Number(path(['AMOUNT'], invoice))}
          sumMax={Number(path(['MIN_AMOUNT'], invoice))}
        />
      </div>
      <div className={s.invoiceTable}>
        <div className={cx(s.invoiceTableRow, s.invoiceTableHead)}>
          <div className={s.invoiceTableCol}>Контрагент</div>
          <div className={s.invoiceTableCol}>Адрес</div>
          <div className={s.invoiceTableCol}>Способ доставки</div>
          <div className={s.invoiceTableCol}>Дата доставки</div>
        </div>
        <div className={s.invoiceTableRow}>
          <div className={s.invoiceTableCol}>
            {path(['CONTRACTOR_TITLE'], invoice)}
          </div>
          <div className={s.invoiceTableCol}>
            {path(['ADDRESS_TITLE'], invoice)}
          </div>
          <div className={s.invoiceTableCol}>
            {path(['DELIVERY_TITLE'], invoice)}
          </div>
          <div className={s.invoiceTableCol}>
            {path(['DELIVERY_DATE'], invoice) ?
              moment(path(['DELIVERY_DATE'], invoice), 'X').locale('ru').format('DD MMMM YYYY')
              : 'Определяется'
            }
          </div>
        </div>
      </div>
    </div>
  )
}

Invoice.propTypes = {
  invoice: object
}

const ProgressInvoice = () => {
  const dispatch = useDispatch()

  const [contractor, setContractor] = useState('')

  const [addr, setAddr] = useState('')

  const [typeDeliveryValue, setTypeDeliveryValue] = useState('')

  const isLoading = useSelector(({ order }) =>
    allInvoicesIsLoadingSelector({ order })
  )

  const isError = useSelector(({ order }) =>
    allInvoicesIsErrorSelector({ order })
  )

  const error = useSelector(({ order }) =>
    allInvoicesErrorSelector({ order })
  )

  const invoices = useSelector(({ order }) =>
    allInvoicesItemsSelector({ order })
  )

  const contractors = useSelector(({ order }) =>
    allInvoicesContractorsSelector({ order })
  )

  const address = useSelector(({ order }) =>
    allInvoicesAddressSelector({ order })
  )

  const typeDelivery = useSelector(({ order }) =>
    allInvoicesTypeDeliverySelector({ order })
  )

  useEffect(() => {
    dispatch(fetchInvoice({}))
  }, [dispatch])

  const onSetContractor = useCallback(v => {
    setContractor(v)
  }, [])

  const onSetAddress = useCallback(v => {
    setAddr(v)
  }, [])

  const onSetTypeDelivery = useCallback(v => {
    setTypeDeliveryValue(v)
  }, [])

  if (isLoading) {
    return (
      <div className={s.preloader}>
        <Preloader active={isLoading} />
      </div>
    )
  }

  if (isError) {
    return (
      <div className={s.container}>
        {error}
      </div>
    )
  }

  const invoicesPrepare = filter(
    item => {
      if (contractor && prop('CONTRACTOR', item) !== contractor) {
        return false
      }

      if (addr && prop('ADDRESS', item) !== addr) {
        return false
      }

      if (typeDeliveryValue && path(['DELIVERY_TYPE'], item) !== typeDeliveryValue) {
        return false
      }
      return true
    }
  )(invoices)

  return (
    <>
      <div className={s.hint}>
        Уважаемый клиент, для отгрузки товаров необходимо пройти пороговую сумму отгрузки до времени передачи в сборку.
        Обращаем ваше внимание, что пороговые суммы разные для разных дат доставки, адресов и складов отгрузки.
      </div>
      <div className={s.filter}>
        <div className={s.contractor}>
          <span className={s.selectName}>Контрагент:</span>
          <div className={s.select}>
            <Select
              onSelect={onSetContractor}
              options={contractors}
              selectedValue={contractor}
            />
          </div>
        </div>
        <div className={s.address}>
          <span className={s.selectName}>Способ доставки:</span>
          <div className={s.select}>
            <Select
              onSelect={onSetTypeDelivery}
              options={typeDelivery}
              selectedValue={typeDeliveryValue}
            />
          </div>
        </div>
        <div className={s.address}>
          <span className={s.selectName}>Адрес:</span>
          <div className={s.select}>
            <Select
              options={address}
              onSelect={onSetAddress}
              selectedValue={addr}
            />
          </div>
        </div>
      </div>
      <div className={s.invoices}>
        {invoicesPrepare.map(item => (
          <Invoice
            key={prop('ID', item)}
            invoice={item}
          />
        ))}
      </div>
    </>
  )
}

const ProgressInvoiceWrapper = () => {
  return (
    <Modal
      id='progress-invoice'
      size='big'
      title='Текущие прогрессы по отгрузке заказов'
    >
      <ProgressInvoice />
    </Modal>
  )
}

export default forAuth(ProgressInvoiceWrapper)
