import React from 'react'

import { compose } from 'redux'

import FormAuth from 'containers/FormAuth'
import Modal from 'containers/Modal'
import { forTabletMobile } from 'decorators/device'
import { forUnauth } from 'decorators/user'

const PopupAuth = () => (
  <Modal
    id='auth'
    size='big'
  >
    <FormAuth
      mode='pageAuth'
      isPageAuth
    />
  </Modal>
)

export default compose(
  forTabletMobile,
  forUnauth
)(PopupAuth)
