/* eslint-disable complexity */
import {
  compose,
  equals,
  filter,
  gt,
  inc,
  length,
  lt,
  map,
  or,
  pathOr,
  prepend,
  prop,
  propOr,
  range,
  reduce,
  reverse,
  toUpper,
  values
} from 'ramda'
import { createSelector } from 'reselect'

import moment from 'utils/moment'
import noun from 'utils/noun'
import { testUrl } from 'utils/testRegexp'

const NUMBER_OF_STARS = 5

export const formsTypesSelector = createSelector(
  state => state,
  () => [
    {
      id: 0,
      title: 'Нашли дешевле',
      value: 'foundcheaper'
    },
    {
      id: 1,
      title: 'Обратная связь',
      value: 'footerfeedback'
    },
    {
      id: 2,
      title: 'Отзыв на товар',
      value: 'productreview'
    },
    {
      id: 3,
      title: 'Расширение ассортимента',
      value: 'rangeexpansion'
    }
  ]
)

export const sortTypesSelector = createSelector(
  state => state,
  () => [
    {
      title: 'Сначала новые',
      value: '-created'
    },
    {
      title: 'Сначала старые',
      value: 'created'
    }
  ]
)

export const ratingTypesSelector = createSelector(
  state => state,
  () =>
    compose(
      prepend({ id: 0, value: '', title: 'Все' }),
      reverse,
      map(i => ({
        title: `${i} ${noun(i, ['звезда', 'звезды', 'звезд'])}`,
        value: `${i}-value`,
        id: inc(i),
        count: 0
      }))
    )(range(1, inc(NUMBER_OF_STARS)))
)

export const periodTypesSelector = createSelector(
  state => state,
  () => [
    { value: '', title: 'За все время' },
    { value: 'period', title: 'За период' }
  ]
)

export const statusSelector = createSelector(
  state => state,
  () => [
    { value: '', title: 'Все' },
    { value: 'work', title: 'В обработке' },
    { value: 'resolved', title: 'Разрешено' },
    { value: 'rejected', title: 'Отклонено' }
  ]
)

export const getLocationSelector = createSelector(
  state => state,
  state => propOr({}, 'location', state)
)

const defaultContractorsSelector = createSelector(
  ({ personal }) => propOr([], 'contractors')(personal),
  contractors =>
    reduce(
      (acc, contractor) => {
        const key = propOr('', 'XML_ID', contractor)
        return {
          ...acc,
          [key]: {
            key,
            value: propOr('', 'ID', contractor),
            title: propOr('', 'TITLE', contractor),
            selected: propOr(false, 'SELECTED', contractor)
          }
        }
      },
      {},
      contractors
    )
)

export const contractorsSelector = createSelector(
  defaultContractorsSelector,
  contractors => compose(prepend({ value: '', title: 'Все' }), values)(contractors)
)

const defaultFeedbackSelector = createSelector(
  ({ feedback }) => feedback,
  state => propOr([], 'feedback', state)
)

export const dateSelector = createSelector(
  defaultFeedbackSelector,
  feedbackItems =>
    compose(
      ({ min, max }) => ({
        min: min ? moment(min, 'X').startOf('day').format('DD.MM.YYYY') : '',
        max: max ? moment(max, 'X').endOf('day').format('DD.MM.YYYY') : ''
      }),
      reduce(
        (acc, item) => {
          const { created } = item
          const createdPrepare = moment(created).unix()
          return {
            min: or(
              lt(createdPrepare, prop('min', acc)),
              equals(prop('min', acc), 0)
            )
              ? createdPrepare
              : prop('min', acc),
            max: gt(createdPrepare, prop('max', acc))
              ? createdPrepare
              : prop('max', acc)
          }
        },
        { min: 0, max: 0 }
      )
    )(feedbackItems)
)

export const feedbackSelector = createSelector(
  defaultFeedbackSelector,
  defaultContractorsSelector,
  ({ type }) => type,
  (feedback, contractors, formType) =>
    compose(
      map(feedbackItem => {
        // general data
        const { created, data, modified, _id } = feedbackItem
        const contractor = propOr('', 'contractor', data)
        const contractorName = propOr('', 'value', contractors[contractor])
        const createdDate = moment(created).format('DD.MM.YYYY')
        const createdTime = moment(created).format('HH:mm')
        const employeeResponse = propOr('', 'employee_response', data)
        const modifiedDate = moment(modified).format('DD.MM.YYYY')
        const modifiedTime = moment(modified).format('HH:mm')
        const response = gt(length(employeeResponse), 1) ? employeeResponse : ''

        // form data
        let newData = {}
        switch (formType) {
          case 'foundcheaper':
            const productLink = pathOr('', ['container', 'productLink'], data)
            newData = {
              comment: propOr('', 'cheaper_comment', data),
              images: propOr([], 'cheaper_images', data),
              productId: propOr('', 'product_id', data),
              productLink: testUrl(productLink) ? productLink : '',
              productPrice: pathOr('', ['container', 'productPrice'], data)
            }
            break
          case 'footerfeedback':
            newData = {
              list: propOr([], 'list_documents', data),
              message: propOr('', 'feedback_message', data),
              theme: propOr('', 'feedback_theme', data),
              type: propOr('', 'feedback_type', data)
            }
            break
          case 'productreview':
            newData = {
              advantages: propOr('', 'product_advantages', data),
              anonymity: propOr(false, 'review_anonymity', data),
              comment: propOr('', 'product_comment', data),
              disadvantages: propOr('', 'product_disadvantages', data),
              productId: propOr('', 'product_id', data),
              rating: propOr(0, 'product_rating', data)
            }
            break
          case 'rangeexpansion':
            newData = {
              brands: toUpper(propOr('', 'range_brands', data)),
              comment: propOr('', 'range_comment', data),
              items: propOr('', 'commodity_items', data),
              turnover: propOr(0, 'planned_turnover', data)
            }
            break
          default:
            break
        }

        return {
          ...newData,
          contractor: contractorName,
          createdDate,
          createdTime,
          id: _id,
          modifiedDate,
          modifiedTime,
          requestNumber: propOr('', 'BnzCaseId', data),
          response,
          status: prop('reference_status', data)
        }
      }),
      filter(feedbackItem =>
        gt(length(pathOr(null, ['data', 'reference_status'], feedbackItem)), 1)
      )
    )(feedback)
)

export const getFeedbackProduct = state => state.product
