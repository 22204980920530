import React from 'react'

import { object } from 'prop-types'
import { length, map } from 'ramda'

import RatingStar from 'components/RatingStar'
import Text from 'UI/Text'

import s from './ProductReview.scss'

const StatisticsBlock = ({ ratingData }) => {
  const { meanRating, ratingInfo } = ratingData
  const stars = length(ratingInfo)

  return (
    <div className={s.statisticsBlock}>
      <div className={s.statisticsBlockHead}>
        <RatingStar rating={meanRating}
          stars={stars} />
        <span>
          <Text
            bold>{`${meanRating.toFixed(
              1
            )}/${stars}`}</Text>
        </span>
      </div>
      <ul className={s.statisticsBlockContainer}>
        {map(ratingString => {
          const { value, progress, count } = ratingString
          return (
            <li
              itemProp='itemListElement'
              itemScope
              itemType='http://schema.org/ItemList'
              key={value}
              className={s.statisticsBlockContainerItem}
            >
              <meta itemProp='position'
                content={value} />
              <Text>{value}</Text>
              <div
                className={s.statisticsBlockContainerItemProgress}
                style={{
                  background: `linear-gradient(to right, #FFAD00 ${progress}%, #E5E5E5 ${progress}%)`
                }}
              />
              <Text>{count}</Text>
            </li>
          )
        }, ratingInfo)}
      </ul>
    </div>
  )
}

StatisticsBlock.propTypes = {
  ratingData: object
}

export default StatisticsBlock
