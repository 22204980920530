import React, { FC } from 'react'

import cx from 'classnames'

import { ITooltip } from 'components/Tooltip/Tooltip'

import styles from './Tooltip.scss'

const TooltipDesktop: FC<ITooltip> = ({
  children,
  disabled,
  tag = 'div',
  tooltip,
  tooltipNode
}) => {
  const Tag = tag

  return (
    <Tag
      className={cx({
        [styles.tooltip]: !disabled,
        [styles.tooltipNode]: tooltipNode
      })}
      data-tooltip={tooltip}
    >
      {children}
      {tooltipNode && (
        <div className={styles.tooltipContent}>{tooltipNode}</div>
      )}
    </Tag>
  )
}

export default TooltipDesktop
