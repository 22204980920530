import React, { PureComponent } from 'react'

import { useSelector } from 'react-redux'

import { isRHSelector } from 'redux/modules/personal'

import style from './Redhelper.scss'

const TIMEOUT = 1000

class Redhelper extends PureComponent {
  state = {
    scriptIsAdded: false
  }

  handleLoadRedhelper = () => {
    if (!this.state.scriptIsAdded) {
      this.addScript()
    }
  }

  addScript = () => {
    setTimeout(
      () =>
        this.setState({ scriptIsAdded: true }, () => {
          if (typeof window !== 'undefined' && window.RedHelper) {
            window.RedHelper.openChat()
          }
        }),
      TIMEOUT
    )
    const el = document.createElement('script')
    const s = document.getElementsByTagName('script')[0]
    el.id = 'rhlpscrtg'
    el.type = 'text/javascript'
    el.charSet = 'utf-8'
    el.async = true
    el.src = 'https://web.redhelper.ru/service/main.js?c=relef'
    return s.parentNode.insertBefore(el, s)
  }

  render() {
    if (this.state.scriptIsAdded) return null

    return (
      <div
        role='presentation'
        className={style.redhelper}
        onClick={this.handleLoadRedhelper}
      >
        <img
          className={style.imageBubble}
          src='/images/redhelper/bubble_dark.svg'
          alt='Redhelper'
        />
        <img
          className={style.imageHelper}
          src='/images/redhelper/redhelper.svg'
          alt='Задать вопрос'
        />
      </div>
    )
  }
}

const RedhelperWrapper = () => {
  const redhelper = useSelector(({ personal }) => isRHSelector(personal))

  if (redhelper) {
    return <Redhelper />
  }
  return null
}

export default RedhelperWrapper

