import { propOr, pathOr, curry } from 'ramda'
import { createAction, createReducer } from 'redux-act'

export {
  getActive,
  isLoadedTheme,
  getTheme
} from './selector'

export const setTheme = createAction('theme/SET')
export const fetch = createAction('theme/GET')
export const fetchSuccess = createAction('theme/GET_SUCCESS')
export const fetchFailure = createAction('theme/GET_FAILURE')

export const getItem = curry((template, page, state) =>
  pathOr({}, [template, page], state)
)
export const getStatus = curry((status, template, page, state) =>
  propOr(false, status, getItem(template, page, state))
)
export const getBackground = curry((template, page, state) =>
  propOr({}, 'background', getItem(template, page, state))
)
export const isLoaded = getStatus('isLoaded')
export const isLoading = getStatus('isLoading')

const initialState = {
  theme: 'default'
}

const handleSetTheme = (state, theme) => ({ ...state, theme })

const reducer = createReducer(on => {
  on(setTheme, handleSetTheme)
}, initialState)

export default reducer
