import React from 'react'

import { array, bool } from 'prop-types'
import { prop, pathOr, includes, equals } from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Scrollbar from 'components/Scrollbar'
import CartAdd from 'containers/Cart/CartChange/CartAdd'
import CartItem from 'containers/Cart/CartChange/CartItem'
import Modal from 'containers/Modal'
import { cartsSelector } from 'redux/modules/productList'

import s from './CartModal.scss'

const HEIGHT = 600

// если меняем корзину из модалки, находясь в первой вкладке корзины
const isBasketMainSelector = createSelector(
  state => pathOr('/', ['location', 'pathname'], state),
  pathname => includes('/basket/main', pathname) || equals('/basket', pathname)
)

const CartModal = ({ carts = [], isBasketMain }) => {
  return (
    <Modal title='Сменить корзину'
      id='cart-change'>
      <Scrollbar maxHeight={HEIGHT}
        minScrollHeight={15}>
        <div className={s.container}>
          <div className={s.desc}>
            Выберите корзину для последующего добавления товара.
          </div>
          <div className={s.wrapper}>
            <div className={s.carts}>
              {carts.map(item => (
                <CartItem key={prop('ID', item)}
                  item={item}
                  isRoute={isBasketMain}
                  isWide />
              ))}
              <CartAdd isAdd
                isWide />
            </div>
          </div>
        </div>
      </Scrollbar>
    </Modal>
  )
}

CartModal.propTypes = {
  carts: array,
  isBasketMain: bool
}

CartModal.defaultProps = {
  carts: [],
  isBasketMain: false
}

export default connect(({ productList, settings, router }) => {
  return {
    carts: cartsSelector(productList, settings),
    isBasketMain: isBasketMainSelector(router)
  }
}, {})(CartModal)
