import {
  socketEmit,
  socketConnect,
  socketDisconnect,
  socketData,
  socketError
} from 'redux/modules/socket'

const createSocketMiddleware =
  socket =>
    ({ dispatch }) => {
      socket.on('to_disconnect', () => {
        socket.close()
        dispatch(socketDisconnect())
      })
      socket.on('error', error => {
        dispatch(socketError(error))
      })
      socket.on('disconnect', () => {
        dispatch(socketDisconnect())
      })
      socket.on('data', payload => {
        dispatch(socketData(payload))
      })
      return next => action => {
        if (action.type === socketEmit().type) {
          socket.emit('data', action.payload)
        }
        if (action.type === socketConnect().type) {
          socket.open()
        }
        return next(action)
      }
    }

export default createSocketMiddleware
