/* eslint-disable complexity */
const NUMBER_100: number = 100
const NUMBER_20: number = 20
const NUMBER_10: number = 10
const NUMBER_5: number = 5

const noun = (
  num: number = 0,
  words: string[] = ['рубль', 'рубля', 'рублей']
): string => {
  const number: number = Math.abs(num) % NUMBER_100
  const numberDecimal: number = number % NUMBER_10
  const numberDecimalFraction: number = (number * NUMBER_10) % NUMBER_10

  switch (true) {
    // numberDecimalFraction - дробное число. Например:
    // 1,3 рубля
    // 0,8 рубля
    // 190 348,5 рубля
    case numberDecimalFraction !== 0:
      return words[1]
    case number > NUMBER_10 && number < NUMBER_20:
      return words[2]
    case numberDecimal > 1 && numberDecimal < NUMBER_5:
      return words[1]
    case numberDecimal === 1:
      return words[0]
    default:
      return words[2]
  }
}

export default noun
