import React from 'react'

import { bool, element, oneOfType } from 'prop-types'

import s from './LayoutDesktop.scss'

const LayoutDesktop = ({ children, after }) => (
  <main className={s.layout}>
    <div className={s.layers}>
      <div className={s.layer}>
        <div className={s.beforeLayer}>{children}</div>
      </div>
      <div className={s.layer}>
        <div className={s.afterLayer}>{after}</div>
      </div>
    </div>
  </main>
)

LayoutDesktop.propTypes = {
  children: element.isRequired,
  after: oneOfType([element, bool])
}

export default LayoutDesktop
