import { pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import moment from 'utils/moment'

export const fetch = createAction('brands/GET_OPERATION_HISTORY')
export const fetchSuccess = createAction('brands/GET_OPERATION_HISTORY_SUCCESS')
export const fetchFailure = createAction('brands/GET_OPERATION_HISTORY_FAILURE')

const initialState = {
  isLoading: false,
  isLoaded: false,
  data: [],
  error: ''
}

export const request =
  ({ clientApi }) =>
    params =>
      clientApi
        .get('/v3/loyalty/history/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            begin_date: moment().startOf('month').unix(),
            end_date: 0,
            ...params
          }
        })
        .then(fetchSuccess)
        .catch(fetchFailure)

const handleFetchLoyaltyOperationHistory = (
  state,
  params,
  { clientApi }
) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), params)
  )

const handleFetchLoyaltyOperationHistorySuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  data: pathOr({}, ['data', 'response', 'ITEMS'], payload),
  entity: pathOr({}, ['data', 'response', 'ENTITIES'], payload)
})
const handleFetchLoyaltyOperationHistoryFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  error: getErrorMessage(payload)
})

const reducer = createReducer(on => {
  on(fetch, handleFetchLoyaltyOperationHistory)
  on(fetchSuccess, handleFetchLoyaltyOperationHistorySuccess)
  on(fetchFailure, handleFetchLoyaltyOperationHistoryFailure)
}, initialState)

export default reducer
