/* eslint-disable complexity */
import { push } from 'connected-react-router'
import { pathOr, values, map, propOr, compose, prop, lensPath, set, isEmpty, not } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import localforage from 'helpers/localforage'
import { showModal } from 'redux/modules/modal'
import { put } from 'redux/modules/orders'
import { fetchBaskets } from 'redux/modules/personal'

import { billIdsSelector } from './selector'

const initialState = {
  confirm: {},
  bills: {},
  isComment: false,
  delivery: [],
  deliveryId: null,
  address: [],
  addressId: null,
  addressSearch: '',
  orderType: [],
  orderTypeId: null,
  deliveryDate: null,
  comment: '',
  tk: [],
  tk_contact: [],
  tk_type: [],
  tk_address: [],
  tk_transport: [],
  allInvoices: {}
}

export * from './selector'

export const STEP_SELECT = 1
export const STEP_INFO = 2
export const PROGRESS_0 = 0
export const PROGRESS_1 = 1
export const PROGRESS_2 = 2
export const PROGRESS_3 = 3
export const PROGRESS_4 = 4
export const PROGRESS_5 = 5
export const PROGRESS_10 = 10

export const init = createAction('order/INIT')
export const initSuccess = createAction('order/INIT_SUCCESS')
export const initFailure = createAction('order/INIT_FAILURE')
export const fetchAddress = createAction('order/FETCH_ADDRESS')
const fetchAddressSuccess = createAction('order/FETCH_ADDRESS_SUCCESS')
const fetchAddressFailure = createAction('order/FETCH_ADDRESS_FAILURE')
export const fetchDelivery = createAction('order/FETCH_DELIVERY')
const fetchDeliverySuccess = createAction('order/FETCH_DELIVERY_SUCCESS')
const fetchDeliveryFailure = createAction('order/FETCH_DELIVERY_FAILURE')
export const fetchDeliveryDate = createAction('order/FETCH_DELIVERY_DATE')
const fetchDeliveryDateSuccess = createAction(
  'order/FETCH_DELIVERY_DATE_SUCCESS'
)
const fetchDeliveryDateFailure = createAction(
  'order/FETCH_DELIVERY_DATE_FAILURE'
)
export const clearValues = createAction('order/CLEAR_VALUES')
export const setValue = createAction('order/SET_VALUE')
export const sendOrder = createAction('order/SEND_ORDER')
const sendOrderSuccess = createAction('order/SEND_ORDER_SUCCESS')
const sendOrderFailure = createAction('order/SEND_ORDER_FAILURE')
export const confirmOrder = createAction('order/CONFIRM_ORDER')
const confirmOrderSuccess = createAction('order/CONFIRM_ORDER_SUCCESS')
const confirmOrderFailure = createAction('order/CONFIRM_ORDER_FAILURE')
export const setAddressSearch = createAction('order/SET_ADDRESS_SEARCH')
export const fetchTk = createAction('order/FETCH_TK')
const fetchTkSuccess = createAction('order/FETCH_TK_SUCCESS')
const fetchTkFailure = createAction('order/FETCH_TK_FAILURE')
export const fetchTkAddress = createAction('order/FETCH_TK_ADDRESS')
const fetchTkAddressSuccess = createAction('order/FETCH_TK_ADDRESS_SUCCESS')
const fetchTkAddressFailure = createAction('order/FETCH_TK_ADDRESS_FAILURE')
export const fetchTkContact = createAction('order/FETCH_CONTACT')
const fetchTkContactSuccess = createAction('order/FETCH_CONTACT_SUCCESS')
const fetchTkContactFailure = createAction('order/FETCH_CONTACT_FAILURE')
export const fetchTkTransport = createAction('order/FETCH_TRANSPORT')
const fetchTkTransportSuccess = createAction('order/FETCH_TRANSPORT_SUCCESS')
const fetchTkTransportFailure = createAction('order/FETCH_TRANSPORT_FAILURE')
export const fetchTkType = createAction('order/FETCH_TYPE')
const fetchTkTypeSuccess = createAction('order/FETCH_TYPE_SUCCESS')
const fetchTkTypeFailure = createAction('order/FETCH_TYPE_FAILURE')
export const fetchTkService = createAction('order/FETCH_SERVICE')
const fetchTkServiceSuccess = createAction('order/FETCH_SERVICE_SUCCESS')
const fetchTkServiceFailure = createAction('order/FETCH_SERVICE_FAILURE')
export const fetchBill = createAction('order/FETCH_BILL')
const fetchBillSuccess = createAction('order/FETCH_BILL_SUCCESS')
const fetchBillFailure = createAction('order/FETCH_BILL_FAILURE')
export const fetchOrderInfo = createAction('order/FETCH_ORDER_INFO')
const fetchOrderInfoSuccess = createAction('order/FETCH_ORDER_INFO_SUCCESS')
const fetchOrderInfoFailure = createAction('order/FETCH_ORDER_INFO_FAILURE')
export const checkout = createAction('order/CHECKOUT')
const checkoutSuccess = createAction('order/CHECKOUT_SUCCESS')
const checkoutFailure = createAction('order/CHECKOUT_FAILURE')
export const invoiceAction = createAction('order/INVOICE_ACTION')
const invoiceActionSuccess = createAction('order/INVOICE_ACTION_SUCCESS')
const invoiceActionFailure = createAction('order/INVOICE_ACTION_FAILURE')
export const setBufferInfo = createAction('order/SET_BUFFER')
export const setInvoiceInfo = createAction('order/SET_INVOICE')
export const setInvoiceInfoFailure = createAction('order/SET_INVOICE_FAILURE')
export const printed = createAction('order/PRINTED')
export const cert = createAction('order/CERT')
export const bill = createAction('order/BILL')
export const fetchInvoice = createAction('order/FETCH_INVOICE')
const fetchInvoiceSuccess = createAction('order/FETCH_INVOICE_SUCCESS')
const fetchInvoiceFailure = createAction('order/FETCH_INVOICE_FAILURE')
export const productUpdate = createAction('order/PRODUCT_UPDATE')
const productUpdateSuccess = createAction('order/PRODUCT_UPDATE_SUCCESS')
const productUpdateFailure = createAction('order/PRODUCT_UPDATE_FAILURE')
const checkForInvoice = createAction('order/CHECK_FOR_INVOICE')
export const forInvoice = createAction('order/FOR_INVOICE')
const forInvoiceSuccess = createAction('order/FOR_INVOICE_SUCCESS')
const forInvoiceFailure = createAction('order/FOR_INVOICE_FAILURE')

export const ORDER = 'order'

const reqInit = ({ catalog, bookmark }) =>
  localforage
    .getItem(`${ORDER}_${catalog}_${bookmark}`)
    .then(data => initSuccess({ data, catalog, bookmark }))
    .catch(initFailure)

const handleInit = (state, { catalog, bookmark }) => {
  const effects = []
  effects.push(Effects.promise(reqInit, { catalog, bookmark }))
  effects.push(Effects.call(fetchDelivery, {}))
  effects.push(Effects.call(fetchAddress, {}))
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleInitSuccess = (state, { data, catalog, bookmark }) => ({
  ...state,
  confirm: {
    ...state.confirm,
    [`${ORDER}_${catalog}_${bookmark}`]: data
  }
})

const handleInitFailure = state => ({
  ...state
})

const reqAddress =
  ({ clientApi }) =>
    params => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/contractor/address/', {
          params: {
            contractor_id: contractorId
          }
        })
        .then(data =>
          fetchAddressSuccess({
            ...data,
            setFirst: propOr(false, 'setFirst', params)
          })
        )
        .catch(fetchAddressFailure)
    }

const handleFetchAddress = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(reqAddress({ clientApi }), payload)
  )

const handleFetchAddressSuccess = (state, payload) => {
  const effects = []
  const address = compose(
    values,
    pathOr([], ['data', 'response', 'ITEMS'])
  )(payload)

  const setFirst = propOr(false, 'setFirst', payload)
  if (setFirst) {
    const value = pathOr(0, [0, 'ID'], address)
    effects.push(Effects.call(setValue, { key: 'addressId', value }))
  }

  return loop(
    {
      ...state,
      address
    },
    Effects.batch(effects)
  )
}

const handleFetchAddressFailure = state => ({
  ...state
})

const reqDelivery =
  ({ clientApi }) =>
    () => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/sale/deliveries/', {
          params: {
            contractor_id: contractorId
          }
        })
        .then(fetchDeliverySuccess)
        .catch(fetchDeliveryFailure)
    }

const handleFetchDelivery = (state, _, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(reqDelivery({ clientApi }), {})
  )

const handleFetchDeliverySuccess = (state, payload) => ({
  ...state,
  delivery: values(pathOr([], ['data', 'response', 'ITEMS'], payload))
})

const handleFetchDeliveryFailure = state => ({
  ...state,
  delivery: []
})

const requestDeliveryDate =
  ({ clientApi }) =>
    ({ addressId }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/contractor/addressPossibleDates/${addressId}/`, {
          params: {
            contractor_id: contractorId
          }
        })
        .then(fetchDeliveryDateSuccess)
        .catch(fetchDeliveryDateFailure)
    }

const handleFetchDeliveryDate = (state, { addressId }, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestDeliveryDate({ clientApi }), { addressId })
  )

const handleFetchDeliveryDateSuccess = (state, payload) => ({
  ...state,
  deliveryDates: values(pathOr([], ['data', 'response', 'ITEMS'], payload))
})

const handleFetchDeliveryDateFailure = state => ({
  ...state,
  deliveryDates: []
})

const handleClearValues = (state, payload = []) => {
  const effects = []
  map(
    item => effects.push(Effects.call(setValue, { key: item, value: '' })),
    payload
  )
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleSetValue = (state, { key, value }) => ({
  ...state,
  [key]: value
})

const handleSendOrder = (state, params) =>
  loop(
    {
      ...state
    },
    Effects.call(put, params)
  )

const handleSendOrderSuccess = state => ({
  ...state
})

const handleSendOrderFailure = state => ({
  ...state
})

const reqConfirm = ({ sum, catalog, bookmark }) =>
  localforage
    .setItem(`${ORDER}_${catalog}_${bookmark}`, sum)
    .then(() => confirmOrderSuccess({ sum, catalog, bookmark }))
    .catch(confirmOrderSuccess)

const handleConfirmOrder = (state, { sum, catalog, bookmark }) =>
  loop(
    {
      ...state
    },
    Effects.promise(reqConfirm, { sum, catalog, bookmark })
  )

const handleConfirmOrderSuccess = (state, { sum, catalog, bookmark }) => ({
  ...state,
  confirm: {
    ...state.confirm,
    [`${ORDER}_${catalog}_${bookmark}`]: sum
  }
})

const handleConfirmOrderFailure = state => ({
  ...state
})

const handleSetAddressSearch = (state, { value }) => ({
  ...state,
  addressSearch: value,
  addressId: null
})

const requestTk =
  ({ clientApi }) =>
    params => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/sale/delivery/tk/', {
          params: { contractor_id: contractorId, ...params }
        })
        .then(fetchTkSuccess)
        .catch(fetchTkFailure)
    }

const handleFetchTk = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTk({ clientApi }), payload)
  )

const handleFetchTkSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  tk: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchTkFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  tk: []
})

const requestTkAddress =
  ({ clientApi }) =>
    ({ id, ...params }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/sale/delivery/tk/${id}/address/`, {
          params: { contractor_id: contractorId, ...params }
        })
        .then(fetchTkAddressSuccess)
        .catch(fetchTkAddressFailure)
    }

const handleFetchTkAddress = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTkAddress({ clientApi }), payload)
  )

const handleFetchTkAddressSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  tk_address: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchTkAddressFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  tk_address: []
})

const requestTkContact =
  ({ clientApi }) =>
    params => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/contractor/contacts/', {
          params: { contractor_id: contractorId, ...params }
        })
        .then(fetchTkContactSuccess)
        .catch(fetchTkContactFailure)
    }

const handleFetchTkContact = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTkContact({ clientApi }), payload)
  )

const handleFetchTkContactSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  tk_contact: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchTkContactFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  tk_contact: []
})

const requestTkTransport =
  ({ clientApi }) =>
    ({ params }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/sale/delivery/transportMethod/', {
          params: { contractor_id: contractorId, ...params }
        })
        .then(fetchTkTransportSuccess)
        .catch(fetchTkTransportFailure)
    }

const handleFetchTkTransport = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTkTransport({ clientApi }), payload)
  )

const handleFetchTkTransportSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  tk_transport: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchTkTransportFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  tk_transport: []
})

const requestTkType =
  ({ clientApi }) =>
    ({ params }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/sale/delivery/type/', {
          params: { contractor_id: contractorId, ...params }
        })
        .then(fetchTkTypeSuccess)
        .catch(fetchTkTypeFailure)
    }

const handleFetchTkType = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTkType({ clientApi }), payload)
  )

const handleFetchTkTypeSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  tk_type: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchTkTypeFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  tk_type: []
})
const requestTkService =
  ({ clientApi }) =>
    ({ params }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/sale/delivery/service/', {
          params: { contractor_id: contractorId, ...params }
        })
        .then(fetchTkServiceSuccess)
        .catch(fetchTkServiceFailure)
    }

const handleFetchTkService = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(requestTkService({ clientApi }), payload)
  )

const handleFetchTkServiceSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  tk_service: pathOr([], ['data', 'response', 'ITEMS'], payload)
})

const handleFetchTkServiceFailure = state => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  tk_service: []
})

const requestOrderInfo =
  ({ clientApi }) =>
    ({ guid, type = '', billIds }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/invoice/group/${guid}/${type ? `${type}/` : ''}`, {
          params: {
            contractor_id: contractorId,
            bill_id: billIds
          }
        })
        .then(data => fetchOrderInfoSuccess({ guid, ...data }))
        .catch(error => fetchOrderInfoFailure({ guid, error }))
    }

const handleFetchOrderInfo = (state, payload, { clientApi }) => {
  const billIds = billIdsSelector({
    order: state,
    guid: prop('guid', payload)
  })
  return loop(
    {
      ...state,
      orders: {
        ...state.orders,
        [prop('guid', payload)]: {
          isLoading: true,
          isLoaded: false,
          data: {}
        }
      }
    },
    Effects.promise(requestOrderInfo({ clientApi }), { ...payload, billIds })
  )
}

const handleFetchOrderInfoSuccess = (state, payload) => ({
  ...state,
  orders: {
    ...state.orders,
    [prop('guid', payload)]: {
      isLoading: false,
      isLoaded: true,
      data: pathOr({}, ['data', 'response'], payload)
    }
  }
})

const handleFetchOrderInfoFailure = (state, payload) => {
  return {
    ...state,
    orders: {
      ...state.orders,
      [prop('guid', payload)]: {
        isLoading: false,
        isLoaded: true,
        isErrorPage: true,
        error: 'Ошибка'
      }
    }
  }
}

const requestBill =
  ({ clientApi }) =>
    ({ billId }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get(`/v3/invoice/bill/${billId}/product/`, {
          params: { contractor_id: contractorId }
        })
        .then(data => fetchBillSuccess({ billId, ...data }))
        .catch(error => fetchBillFailure({ billId, error }))
    }

const handleFetchBill = (state, payload, { clientApi }) => {
  return loop(
    {
      ...state,
      bills: {
        ...state.bills,
        [prop('billId', payload)]: {
          isLoading: true,
          isLoaded: false
        }
      }
    },
    Effects.promise(requestBill({ clientApi }), payload)
  )
}

const handleFetchBillSuccess = (state, payload) => ({
  ...state,
  bills: {
    ...state.bills,
    [prop('billId', payload)]: {
      isLoading: false,
      isLoaded: true,
      ...pathOr({}, ['data', 'response'], payload)
    }
  }
})

const handleFetchBillFailure = (state, payload) => {
  return {
    ...state,
    bills: {
      ...state.bills,
      [prop('billId', payload)]: {
        isLoading: false,
        isLoaded: true,
        error: 'Ошибка'
      }
    }
  }
}

const requestCheckout =
  ({ clientApi }) =>
    ({ guid, step = STEP_SELECT, ...etc }) => {
      const contractorId = clientApi.getContractorId()
      const action = step === STEP_INFO ? 'confirm' : 'update'
      return clientApi
        .post(`/v3/invoice/group/${guid}/${action}/`, {
          params: { contractor_id: contractorId, ...etc }
        })
        .then(data => checkoutSuccess({ guid, step, ...data }))
        .catch(error => checkoutFailure({ guid, ...error }))
    }

const handleCheckout = (state, payload, { clientApi }) => {
  const isLoadingPath = lensPath(['orders', prop('guid', payload), 'isLoading'])
  const isLoadedPath = lensPath(['orders', prop('guid', payload), 'isLoading'])
  const isSendingPath = lensPath(['orders', prop('guid', payload), 'isSending'])
  return loop(
    compose(
      set(isLoadingPath, true),
      set(isLoadedPath, false),
      set(isSendingPath, true)
    )(state),
    Effects.promise(requestCheckout({ clientApi }), payload)
  )
}

const handleCheckoutSuccess = (state, payload) => {
  const effects = []
  const step = Number(propOr(1, 'step', payload))
  if (step === STEP_SELECT) {
    effects.push(
      Effects.call(
        push,
        `/orders/checkout/${prop('guid', payload)}?step=${STEP_INFO}`
      )
    )
  }

  if (step === STEP_INFO) {
    effects.push(Effects.call(push, `/orders/thanks/${prop('guid', payload)}`))
  }

  return loop(
    {
      ...state,
      orders: {
        ...state.orders,
        [prop('guid', payload)]: {
          ...state.orders[prop('guid', payload)],
          isLoading: false,
          isLoaded: true,
          isSending: false
        }
      }
    },
    Effects.batch(effects)
  )
}

const handleCheckoutFailure = (state, payload) => {
  const isLoadingPath = lensPath(['orders', prop('guid', payload), 'isLoading'])
  const isLoadedPath = lensPath(['orders', prop('guid', payload), 'isLoading'])
  const isErrorPath = lensPath(['orders', prop('guid', payload), 'error'])
  const isSendingPath = lensPath(['orders', prop('guid', payload), 'isSending'])
  return compose(
    set(isLoadingPath, false),
    set(isLoadedPath, true),
    set(isSendingPath, false),
    set(isErrorPath, 'Ошибка')
  )(state)
}

const requestInvoiceAction =
  ({ clientApi }) =>
    ({ guid, action, ...etc }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post(`/v3/invoice/group/${guid}/${action}/`, {
          params: { contractor_id: contractorId, ...etc }
        })
        .then(data => invoiceActionSuccess({ guid, ...data }))
        .catch(error => invoiceActionFailure({ guid, error }))
    }

const handleInvoiceAction = (state, payload, { clientApi }) => {
  return loop(
    {
      ...state
    },
    Effects.promise(requestInvoiceAction({ clientApi }), payload)
  )
}

const handleInvoiceActionSuccess = state => {
  const effects = []
  effects.push(
    Effects.call(push, '/basket/main/0')
  )
  effects.push(
    Effects.call(fetchBaskets, {})
  )
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleInvoiceActionFailure = state =>
  loop(
    {
      ...state
    },
    Effects.call(showModal, 'invoice-bill-failure')
  )

const requestFetchInvoice = ({ clientApi }) => ({ ...etc }) => {
  const contractorId = clientApi.getContractorId()
  return clientApi
    .get('/v3/invoice/buffer/list/', {
      params: { contractor_id: contractorId, ...etc }
    })
    .then(fetchInvoiceSuccess)
    .catch(fetchInvoiceFailure)
}

const handleFetchInvoice = (state, payload, { clientApi }) => {
  return loop(
    {
      ...state,
      allInvoices: {
        isLoaded: false,
        isLoading: true
      }
    },
    Effects.promise(requestFetchInvoice({ clientApi }), payload)
  )
}

const handleFetchInvoiceSuccess = (state, payload) => ({
  ...state,
  allInvoices: {
    isLoaded: true,
    isLoading: false,
    data: pathOr({}, ['data', 'response'], payload)
  }
})

const handleFetchInvoiceFailure = (state, payload) => ({
  ...state,
  allInvoices: {
    isLoaded: true,
    isLoading: false,
    isError: true,
    data: {},
    error: getErrorMessage(payload)
  }
})

const requestSetInvoice = ({ clientApi }) => ({ guid, bufferId, serviceId, isSet, type = 'bill', ...etc }) => {
  const contractorId = clientApi.getContractorId()
  return clientApi
    .post(`/v3/invoice/group/${guid}/${type}/${bufferId}/${serviceId}/${isSet}/`, {
      params: { contractor_id: contractorId, ...etc }
    })
    .then(() => fetchOrderInfo({ guid }))
    .catch(fetchOrderInfo({ guid }))
}

const handleSetInvoiceInfo = (state, payload, { clientApi }) => {
  return loop(
    compose(
      set(lensPath(['orders', prop('guid', payload), 'isLoading']), true),
      set(lensPath(['orders', prop('guid', payload), 'isLoaded']), false)
    )(state),
    Effects.promise(requestSetInvoice({ clientApi }), payload)
  )
}

const handleSetInvoiceInfoFailure = state => ({
  ...state
})

const requestProductUpdate = ({ clientApi }) => ({ billId, ...etc }) => {
  const contractorId = clientApi.getContractorId()
  return clientApi
    .post(`/v3/invoice/bill/${billId}/product/update/`, {
      params: { contractor_id: contractorId, ...etc }
    })
    .then(() => productUpdateSuccess({ billId }))
    .catch(() => productUpdateFailure({ billId }))
}

const handleProductUpdate = (state, payload, { clientApi }) => {
  return loop({
    ...state
  },
  Effects.promise(requestProductUpdate({ clientApi }), payload)
  )
}

const handleProductUpdateSuccess = (state, { billId }) => loop({
  ...state
},
Effects.call(fetchBill, { billId })
)

const handleProductUpdateFailure = (state, { billId }) => loop({
  ...state
},
Effects.call(fetchBill, { billId })
)

const requestForInvoice = ({ clientApi }) => ({ guid, type }) => {
  const contractorId = clientApi.getContractorId()
  return clientApi
    .get(`/v3/invoice/group/${guid}/${type === 'all' ? `${type}/` : ''}`, {
      params: { contractor_id: contractorId }
    })
    .then(data => forInvoiceSuccess({ guid, type, ...data }))
    .catch(() => forInvoiceFailure({ guid, type }))
}

const handleForInvoice = (state, payload, { clientApi }) => {
  const effects = []
  const guid = prop('guid', payload)
  effects.push(Effects.promise(requestForInvoice({ clientApi }), {
    guid,
    type: 'one'
  }))
  effects.push(Effects.promise(requestForInvoice({ clientApi }), {
    guid,
    type: 'all'
  }))
  return loop({
    ...state,
    forInvoice: {
      [guid]: {
        isLoading: true,
        isLoaded: false
      }
    }
  },
  Effects.batch(effects)
  )
}

const handleForInvoiceSuccess = (state, { guid, type, ...payload }) => loop({
  ...state,
  forInvoice: {
    [guid]: {
      ...pathOr({}, ['forInvoice', guid], state),
      [type] : {
        ...pathOr({}, ['forInvoice', guid, type], state),
        isLoading: false,
        isLoaded: true,
        data: pathOr({}, ['data', 'response'], payload)
      }
    }
  }
},
Effects.call(checkForInvoice, { guid } ))

const handleForInvoiceFailure = (state, { guid, type}) => loop({
  ...state,
  forInvoice: {
    [guid]: {
      ...pathOr({}, ['forInvoice', guid], state),
      [type] : {
        isLoading: false,
        isLoaded: true,
        isError: true
      }
    }
  }
},
Effects.call(checkForInvoice, { guid } ))

const handleCheckForInvoice = (state, { guid }) => {
  const notEmptyAll = not(isEmpty(pathOr({}, ['forInvoice', guid, 'all', 'data'], state)))
  const isLoadedAll = pathOr(false, ['forInvoice', guid, 'all', 'isLoaded'], state)
  const notEmptyOne = not(isEmpty(pathOr({}, ['forInvoice', guid, 'one', 'data'], state)))
  const isLoadedOne = pathOr(false, ['forInvoice', guid, 'one', 'isLoaded'], state)
  if ((notEmptyAll || notEmptyOne) && (isLoadedAll && isLoadedOne)) {
    return loop({
      ...state,
      forInvoice: {
        [guid]: {
          isLoading: false,
          isLoaded: true
        }
      }
    },
    Effects.call(push, notEmptyOne ? `/orders/checkout/${guid}?step=1` : `/orders/thanks/${guid}`)
    )
  }

  if (isLoadedAll && isLoadedOne) {
    return {
      ...state,
      forInvoice: {
        [guid]: {
          isLoading: false,
          isLoaded: true,
          isError: true,
          error: `Заказа ${guid} не существует`
        }
      }
    }
  }

  return {
    ...state
  }
}

const reducer = createReducer(on => {
  on(init, handleInit)
  on(initSuccess, handleInitSuccess)
  on(initFailure, handleInitFailure)
  on(fetchAddress, handleFetchAddress)
  on(fetchAddressSuccess, handleFetchAddressSuccess)
  on(fetchAddressFailure, handleFetchAddressFailure)
  on(fetchDelivery, handleFetchDelivery)
  on(fetchDeliverySuccess, handleFetchDeliverySuccess)
  on(fetchDeliveryFailure, handleFetchDeliveryFailure)
  on(fetchDeliveryDate, handleFetchDeliveryDate)
  on(fetchDeliveryDateSuccess, handleFetchDeliveryDateSuccess)
  on(fetchDeliveryDateFailure, handleFetchDeliveryDateFailure)
  on(clearValues, handleClearValues)
  on(setValue, handleSetValue)
  on(sendOrder, handleSendOrder)
  on(sendOrderSuccess, handleSendOrderSuccess)
  on(sendOrderFailure, handleSendOrderFailure)
  on(confirmOrder, handleConfirmOrder)
  on(confirmOrderSuccess, handleConfirmOrderSuccess)
  on(confirmOrderFailure, handleConfirmOrderFailure)
  on(setAddressSearch, handleSetAddressSearch)
  on(fetchTkContact, handleFetchTkContact)
  on(fetchTkContactSuccess, handleFetchTkContactSuccess)
  on(fetchTkContactFailure, handleFetchTkContactFailure)
  on(fetchTk, handleFetchTk)
  on(fetchTkSuccess, handleFetchTkSuccess)
  on(fetchTkFailure, handleFetchTkFailure)
  on(fetchTkAddress, handleFetchTkAddress)
  on(fetchTkAddressSuccess, handleFetchTkAddressSuccess)
  on(fetchTkAddressFailure, handleFetchTkAddressFailure)
  on(fetchTkTransport, handleFetchTkTransport)
  on(fetchTkTransportSuccess, handleFetchTkTransportSuccess)
  on(fetchTkTransportFailure, handleFetchTkTransportFailure)
  on(fetchTkType, handleFetchTkType)
  on(fetchTkTypeSuccess, handleFetchTkTypeSuccess)
  on(fetchTkTypeFailure, handleFetchTkTypeFailure)
  on(fetchTkService, handleFetchTkService)
  on(fetchTkServiceSuccess, handleFetchTkServiceSuccess)
  on(fetchTkServiceFailure, handleFetchTkServiceFailure)
  on(fetchOrderInfo, handleFetchOrderInfo)
  on(fetchOrderInfoSuccess, handleFetchOrderInfoSuccess)
  on(fetchOrderInfoFailure, handleFetchOrderInfoFailure)
  on(fetchBill, handleFetchBill)
  on(fetchBillSuccess, handleFetchBillSuccess)
  on(fetchBillFailure, handleFetchBillFailure)
  on(checkout, handleCheckout)
  on(checkoutSuccess, handleCheckoutSuccess)
  on(checkoutFailure, handleCheckoutFailure)
  on(invoiceAction, handleInvoiceAction)
  on(invoiceActionSuccess, handleInvoiceActionSuccess)
  on(invoiceActionFailure, handleInvoiceActionFailure)
  on(fetchInvoice, handleFetchInvoice)
  on(fetchInvoiceSuccess, handleFetchInvoiceSuccess)
  on(fetchInvoiceFailure, handleFetchInvoiceFailure)
  on(setInvoiceInfo, handleSetInvoiceInfo)
  on(setInvoiceInfoFailure, handleSetInvoiceInfoFailure)
  on(productUpdate, handleProductUpdate)
  on(productUpdateSuccess, handleProductUpdateSuccess)
  on(productUpdateFailure, handleProductUpdateFailure)
  on(checkForInvoice, handleCheckForInvoice)
  on(forInvoice, handleForInvoice)
  on(forInvoiceSuccess, handleForInvoiceSuccess)
  on(forInvoiceFailure, handleForInvoiceFailure)
}, initialState)

export default reducer
