/* eslint-disable complexity */
import { path, pick, identity, equals, isEmpty, pathOr, compose, omit, pickBy, values } from 'ramda'
import { SubmissionError } from 'redux-form'

import { setValues, putRecoverySuccess } from 'redux/modules/registration'
import { getTokenByPassword } from 'redux/modules/token'
import { testEmail, errorTestEmail } from 'utils/testRegexp'

const NUMBER_1 = 1
const NUMBER_2 = 2
const NUMBER_3 = 3

export const settings = {
  citiesQueryMinLength: 3
}

export const data = {
  phone: '8 (4912) 958 000'
}

export const flagsByCode = {
  '643': 'flag-rus',
  '112': 'flag-blr',
  '398': 'flag-kaz',
  '031': 'flag-aze',
  '051': 'flag-arm',
  '417': 'flag-kgz',
  '498': 'flag-mda',
  '762': 'flag-tjk',
  '795': 'flag-tkm',
  '860': 'flag-uzb',
  '268': 'flag-geo',
  '804': 'flag-ua',
  '496': 'flag-mng'
}

export const phoneCodes = {
  '643': '+7',
  '112': '+375',
  '398': '+7',
  '031': '+994',
  '051': '+374',
  '417': '+996',
  '498': '+373',
  '762': '+992',
  '795': '+993',
  '860': '+998',
  '268': '+995',
  '804': '+380',
  '496': '+976'
}

export const conditions = [
  {
    title: 'Порядок действий',
    steps : [
      {
        icon: NUMBER_1,
        text: 'Заполните поля в форме регистрации и нажмите отправить заявку'
      }, {
        icon: NUMBER_2,
        text: 'Наш менеджер свяжется с Вами в течение 2-х рабочих дней'
      }, {
        icon: NUMBER_3,
        text: 'До этого момента мы предоставим Вам демо-доступ для знакомства с основными функциями интернет-магазина'
      }
    ]
  }, {
    title: 'Преимущества работы с нами',
    steps : [
      {
        icon: '24hours',
        text: 'Принимаем заказы 24 часа в сутки, 7 дней в неделю'
      }, {
        icon: 'truck-registration',
        text: 'Осуществляем доставку по России и в страны СНГ'
      }, {
        icon: 'gift-registration',
        text: 'Дарим подарки и устраиваем специальные акции для постоянных покупателей'
      }
    ]
  }
]

export const defaults = {
  phoneCode: '+7',
  country_code: '643',
  personal: false
}

export const registrationForm = {
  form: 'registration',
  initialValues: {
    ...defaults
  },
  validate: val => {
    const errors = {}

    if (!val.email) {
      errors.email = 'Вы не указали почту'
    }

    if (!val.new_password) {
      errors.new_password = 'Вы не указали пароль'
    }

    if (!val.phone) {
      errors.phone = 'Вы не указали телефон'
    }

    if (!val.name) {
      errors.name = 'Вы не указали имя'
    }

    if (!val.last_name) {
      errors.last_name = 'Вы не указали фамилию'
    }

    if (!val.title) {
      errors.title = 'Вы не указали название вашей организации'
    }

    if (!val.address) {
      errors.address = 'Вы не указали адрес'
    }

    if (!val.personal) {
      errors.personal = 'Вы не подтвердили согласие на обработку персональных данных'
    }

    return errors
  },
  asyncValidate: (val, dispatch, { clientApi }) => {
    if (val.phone) {
      // eslint-disable-next-line no-useless-escape
      val.phone = val.phone.replace(/[ ()\-]/gi, '')
    }
    return clientApi.get('/v1/login/validation/', { params: { ...val } })
      .then(res => {
        const success = path(['data', 'response', 'SUCCESS'], res)
        if (success === 'N') {
          const errors = path(['data', 'errors'], res)
          if (errors.email && !equals('Указан некорректный email. ', errors.email)) {
            errors.email = 'На данную почту уже производилась регистрация'
          }
          throw errors
        }
      })
  },
  asyncBlurFields: [
    'email',
    'new_password',
    'phone',
    'name',
    'last_name',
    'title',
    'address',
    'country_code',
    'kladr',
    'comment',
    'ogrn',
    'inn'
  ],
  enableReinitialize: true,
  destroyOnUnmount: false,
  keepDirtyOnReinitialize: true
}

export const onSubmit = ({ clientApi }) => (val, dispatch) => {
  const fields = pickBy(identity, val)
  const {
    phone, phoneCode, address
  } = fields
  return clientApi.post('/v1/login/registration/', {
    params: {
      ...fields,
      // eslint-disable-next-line no-useless-escape
      phone: `${phoneCode ? phoneCode.replace(/[\+]/gi, '') : ''}${phone ? phone.replace(/[ ()\-]/gi, '') : ''}`,
      address:`${address || ''}`
    }
  }).then(res => {
    const success = path(['data', 'response', 'SUCCESS'], res)
    if (success === 'N') {
      const errors = pathOr({}, ['data', 'errors'], res)
      const message = pathOr('', ['data', 'response', 'MESSAGE'], res)
      if (message) {
        dispatch(setValues({ isSended: false, message, errors }))
      }
      if (!isEmpty(errors)) {
        throw new SubmissionError({ ...errors })
      }
    } else {
      const message = path(['data', 'response', 'MESSAGE'], res)
      dispatch(setValues({ isSended: true, message }))
    }
  })
    .catch(error => {
      dispatch(setValues({ isSended: false, message: error.message }))
    })
}

const isEmailRegistered = error => error.indexOf('уже зарегистрирован') !== -1

export const recoveryForm = {
  form: 'recovery',
  asyncBlurFields: ['email'],
  validate: val => {
    const errors = {}
    const prepareEmail = val.email ? val.email.trim() : ''
    if (!prepareEmail) {
      errors.email = 'Вы не указали почту'
    } else if (!testEmail(prepareEmail)) {
      errors.email = errorTestEmail
    }

    return errors
  },
  asyncValidate: (val, dispatch, { clientApi }) => clientApi.get(
    '/v1/login/validation/', { params: { ...val, email: (val.email || '').trim() } }
  )
    .then(res => {
      const success = path(['data', 'response', 'SUCCESS'], res)
      if (success === 'Y') {
        dispatch(setValues({ recoveryErrors: [], recoveryMessages: [] }))
      } else {
        dispatch(setValues({ recoveryErrors: [], recoveryMessages: [] }))
        const errors = path(['data', 'errors'], res)
        if (!isEmailRegistered(errors.email)) {
          throw errors
        }
      }
    })
}

export const onRecoverySubmit = ({ clientApi }) => (val, dispatch) => clientApi.post(
  '/v1/login/recovery/', { params: { ...val, email: (val.email || '').trim() } }
)
  .then(res => {
    const success = path(['data', 'response', 'SUCCESS'], res)
    if (success === 'N') {
      const errors = path(['data', 'errors'], res)
      throw new SubmissionError({ ...errors })
    } else {
      const recoveryMessage = path(['data', 'response', 'MESSAGE'], res)
      dispatch(setValues({ isSendedRecovery: true, recoveryMessages: [recoveryMessage] }))
    }
  })

export const recoveryFormFromEmail = {
  form: 'recoveryFromMail',
  asyncBlurFields: ['new_password', 'confirm_password'],
  validate: val => {
    const errors = {}
    if (!val.new_password) {
      errors.new_password = 'Вы не ввели пароль'
    }
    if (!val.confirm_password) {
      errors.confirm_password = 'Вы не ввели подтверждение пароля'
    }
    return errors
  },
  asyncValidate: (val, dispatch, { clientApi }) => {
    const params = pick(['new_password', 'confirm_password'], val)
    return clientApi.get('/v1/login/validation/', { params })
      .then(result => {
        const errors = pathOr({}, ['data', 'errors'], result)
        if (!isEmpty(errors)) {
          throw errors
        }
      })
  }
}

export const onRecoveryFormFromEmailSubmit = ({ clientApi }) => (val, dispatch) => clientApi.post(
  '/v1/login/recovery_confirm/', { params: { ...val } }
)
  .then(res => {
    const success = path(['data', 'response', 'SUCCESS'], res)
    if (success === 'N') {
      const fieldsCheck = ['new_password', 'confirm_password']
      const errors = compose(
        pick(fieldsCheck),
        pathOr({}, ['data', 'errors'])
      )(res)
      if (!isEmpty(errors)) {
        throw new SubmissionError({ ...errors })
      }
      const recoveryErrors = compose(
        values,
        omit(fieldsCheck),
        pathOr({}, ['data', 'errors'])
      )(res)
      const message = path(['data', 'response', 'MESSAGE'], res)
      dispatch(setValues({ isSendedRecovery: false, recoveryErrors, recoveryMessages: [message] }))
    } else {
      dispatch(putRecoverySuccess(res)).then(() => dispatch(
        getTokenByPassword({ username: val.email, password: val.new_password })
      ))
    }
  })
