import { useCallback, useEffect } from 'react'

import { useDispatch } from 'react-redux'

import {
  mobileMediaQuery,
  desktopMediaQuery,
  tabletMediaQuery,
  setDevice
} from 'redux/modules/device'

const Device = () => {
  const dispatch = useDispatch()

  const detectDevice = useCallback(
    data => dispatch(setDevice(data)),
    [dispatch]
  )

  const handleDeviceChange = e => e.matches && detectDevice(e.media)

  useEffect(() => {
    handleDeviceChange(mobileMediaQuery)
    handleDeviceChange(tabletMediaQuery)
    handleDeviceChange(desktopMediaQuery)
    mobileMediaQuery.addEventListener('change', handleDeviceChange)
    tabletMediaQuery.addEventListener('change', handleDeviceChange)
    desktopMediaQuery.addEventListener('change', handleDeviceChange)
    return () => {
      mobileMediaQuery.removeEventListener('change', handleDeviceChange)
      tabletMediaQuery.removeEventListener('change', handleDeviceChange)
      desktopMediaQuery.removeEventListener('change', handleDeviceChange)
    }
  }, [])

  return null
}

export default Device
