import { pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

export const setActive = createAction('offices/SET_ACTIVE')
export const setHover = createAction('offices/SET_HOVER')
export const fetch = createAction('offices/GET')
export const fetchSuccess = createAction('offices/GET_SUCCESS')
export const fetchFailure = createAction('offices/GET_FAILURE')

export {
  getIsLoaded,
  getIsLoading,
  getOffices,
  getActiveOffice,
  getHoverOffice,
  getTitle
} from './selector'

const initialState = {
  title: '',
  offices: [],
  isLoading: false,
  isLoaded: false,
  activeOffice: 0,
  hoverOffice: 0
}

const handleSetActive = (state, payload) => ({
  ...state,
  activeOffice: payload
})

const handleSetHover = (state, payload) => ({
  ...state,
  hoverOffice: payload
})

export const request =
  ({ clientApi }) =>
    () => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v2/company/list/', {
          params: { contractor_id: contractorId }
        })
        .then(fetchSuccess)
        .catch(fetchFailure)
    }

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }))
  )

const handleFetchSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  offices: pathOr([], ['data', 'response', 'COMPANIES'], payload),
  title: pathOr('', ['data', 'response', 'NAV', 'TITLE'], payload)
})

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  error: getErrorMessage(payload)
})

const reducer = createReducer(on => {
  on(setActive, handleSetActive)
  on(setHover, handleSetHover)
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
}, initialState)

export default reducer
