import Registration from 'containers/Registration'
import PageOffersMain from 'pages/OffersMain'

import AuthContent from './AuthContent'
import CatalogContent from './CatalogContent'
import CatalogRc from './CatalogRc'
import LoyaltyCatalog from './LoyaltyCatalog'
import LoyaltyOptions from './LoyaltyOptions'
import PersonalContent from './PersonalContent'
import SearchContent from './SearchContent'
import SettingsContent from './SettingsContent'

export const ABOUT_LINKS = [
  { title: 'Адреса филиалов', link: '/offices' },
  { title: 'Новости', link: '/content/news' }
]

export const FAQ_LINKS = [
  { title: 'Вопросы и ответы', link: '/faq' },
  { title: 'Карта сайта', link: '/sitemap' },
  { title: 'Политика конфиденциальности', link: '/documents/policy' }
]

export const tabContent = {
  catalog: CatalogContent,
  search: SearchContent,
  cabinet : PersonalContent,
  offers: PageOffersMain,
  auth: AuthContent,
  registration: Registration,
  options: SettingsContent,
  catalogRc: CatalogRc,
  catalogLoyalty: LoyaltyCatalog,
  optionsLoyalty: LoyaltyOptions
}

export const LOYALTY_LINKS = [
  {
    to: '/loyalty/morepoints',
    title: 'Кешбэк',
    icon: 'loyaltyCashback',
    semibold: false
  },
  {
    to: '/loyalty/operations',
    title: 'История операций',
    icon: 'history',
    semibold: false
  },
  {
    to: '/loyalty/statuses',
    title: '',
    icon: 'loyaltyStatus',
    semibold: true
  },
  {
    to: '/loyalty/how_it_works',
    title: 'Как это работает',
    icon: 'iconGear',
    semibold: true
  },
  {
    to: '/loyalty/conditions',
    title: 'Условия участия',
    icon: '',
    semibold: false
  },
  {
    to: '/loyalty/faq',
    title: 'Часто задаваемые вопросы',
    icon: '',
    semibold: false
  }
]
