import React, { PureComponent } from 'react'

import cx from 'classnames'
import { func, object, bool, number, oneOf } from 'prop-types'
import R from 'ramda'

import OrderButtonLoyalty from 'components/OrderButtonLoyalty'
import ProductBalance from 'components/Product/ProductBalance'
import ProductLoyaltyButtons from 'components/Product/ProductLoyaltyButtons'
import ProductPrices from 'components/ProductPrices'
import config from 'config'
import basket from 'decorators/basket'
import product from 'decorators/product'

import styles from './ProductOneActions.scss'

const BUTTON_WIDTH_LOYALTY = '100%'
const IS_SHOW_REMAIN = false

@basket
@product
class ProductOneActions extends PureComponent {
  static propTypes = {
    item: object,
    itemsCount: number,
    pushToBasket: func.isRequired,
    deleteTarget: func.isRequired,
    isUpdating: object,
    onItemCountChange: func,
    disableToLoyalty: bool,
    isTarget: bool,
    setTarget: func,
    isRightBlock: bool,
    hasLoyaltyAccess: bool,
    onKeyUpAmount: func,
    mode: oneOf(['table', 'one', 'card']),
    remain: number
  }

  static defaultProps = {
    setTarget: () => {},
    onKeyUpAmount: () => {},
    hasLoyaltyAccess: true,
    disableToLoyalty: false,
    isTarget: false,
    isRightBlock: false
  }

  render() {
    const {
      item,
      isRightBlock,
      mode,
      hasLoyaltyAccess,
      remain,
      isUpdating: isUpdatingStore,
      itemsCount
    } = this.props
    const basketCount = Number(
      R.pathOr(0, ['BASKETS', 'LOYALTY', 0, 'QUANTITY'], item)
    )
    const isUpdating = R.propOr(false, 'loyaltyBasket', isUpdatingStore)
    const amount = itemsCount || basketCount || 1

    if (mode === 'card') {
      return (
        <div className={styles.loyaltyButtons}>
          <div className={styles.loyaltyButtonsTop}>
            <ProductPrices item={item}
              isLoyalty />
            {IS_SHOW_REMAIN && (
              <div className={styles.loyaltyLine}>
                <ProductBalance
                  remains={remain}
                  stock={item.REMAINS}
                  isLoyalty
                />
              </div>
            )}
          </div>
          <div className={styles.loyaltyLine}>
            <OrderButtonLoyalty
              amount={amount}
              min={1}
              max={config.loyaltyOrderMaxLimit}
              step={1}
              width={BUTTON_WIDTH_LOYALTY}
              pushToBasket={this.props.pushToBasket}
              onChange={this.props.onItemCountChange}
              onKeyUp={this.handleLoyaltyAmountKeyUp}
              disabled={isUpdating}
              mode='Base'
            />
          </div>
          <div className={styles.loyaltyProductButtons}>
            <ProductLoyaltyButtons
              id={item.ID}
              amount={amount}
              isBasket={basketCount > 0}
              isBasketChange={basketCount !== amount}
              inBasket={isUpdating}
              isTarget={this.props.isTarget}
              setTarget={this.props.setTarget}
              deleteTarget={this.props.deleteTarget}
              pushToBasket={this.props.pushToBasket}
              disableToLoyalty={this.props.disableToLoyalty}
              hasLoyaltyAccess={hasLoyaltyAccess}
            />
          </div>
        </div>
      )
    }

    return (
      <div
        className={cx(styles.productOneActions, {
          [styles[`productOneActions_${mode}`]]: !!mode
        })}
        itemProp='offers'
        itemScope
        itemType='http://schema.org/AggregateOffer'
      >
        <div
          className={cx(styles.rightBlockWrapper, styles.priceWrapperLoyalty)}
        >
          <div className={styles.wrapLeftBlock}>
            <ProductPrices item={item}
              isLoyalty />
            {IS_SHOW_REMAIN && (
              <span className={cx(styles.restMini, styles.restMiniLoyalty)}>
                <div
                  className={cx({
                    [styles.productsBalance]: remain <= 0,
                    [styles.productsBalancePlus]: remain > 0
                  })}
                >
                  <ProductBalance
                    mini={false}
                    remains={remain}
                    isLoyalty
                    mode='ProductOne'
                    stock={item.REMAINS}
                    onlyText
                  />
                </div>
              </span>
            )}
          </div>
        </div>

        {isRightBlock && (
          <div className={styles.wrapRightBlock}>
            <div className={styles.wrapButtons}>
              <div className={styles.orderButtonWrapper}>
                <OrderButtonLoyalty
                  amount={amount}
                  width={BUTTON_WIDTH_LOYALTY}
                  min={1}
                  max={config.loyaltyOrderMaxLimit}
                  step={1}
                  onChange={this.props.onItemCountChange}
                  onKeyUp={this.props.onKeyUpAmount}
                  disabled={isUpdating}
                />
              </div>
              <ProductLoyaltyButtons
                amount={amount}
                id={item.ID}
                isBasket={basketCount > 0}
                isBasketChange={basketCount !== amount}
                pushToBasket={this.props.pushToBasket}
                setTarget={this.props.setTarget}
                isTarget={this.props.isTarget}
                deleteTarget={this.props.deleteTarget}
                disableToLoyalty={this.props.disableToLoyalty}
                inBasket={isUpdating}
                hasLoyaltyAccess={hasLoyaltyAccess}
              />
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default ProductOneActions
