/* eslint-disable complexity */
import { push } from 'connected-react-router'
import {
  propOr,
  pathOr,
  path,
  isEmpty,
  map,
  values,
  equals,
  add,
  reduce
} from 'ramda'
import { compose } from 'redux'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal, hideModal } from 'redux/modules/modal'
import { changeLoyaltyStatus } from 'redux/modules/personal'
import moment from 'utils/moment'

const emptyObject = {}
const fileErrorText = 'Произошла техническая ошибка, повторите операцию позже.'

const initialState = {
  status: {
    isLoaded: false,
    isLoading: false
  },
  sections: {
    isLoaded: false,
    isLoading: false,
    items: []
  },
  target: {
    isLoaded: false,
    isLoading: false
  },
  content: {
    isLoaded: false,
    isLoading: false,
    items: []
  },
  file: {
    isLoaded: false,
    isLoading: false,
    data: {}
  },
  availableTargets: {
    isLoaded: false,
    isLoading: false,
    items: []
  },
  cashback: {
    isLoaded: false,
    isLoading: false,
    currentMonth: {},
    feautureMonth: {}
  },
  barColor: 0,
  acceptConditions: false,
  acceptResult: 'Y',
  acceptSending: false
}

export {
  changeStatusSuccessSelector,
  getAvailableTargets,
  getBalance,
  getChangeStatusSending,
  getCurrentCard,
  getDate,
  getDefaultExpirationInfo,
  getExpirationInfo,
  getForecastObj,
  getIsLoaded,
  getLastCompletedGuid,
  getLoyaltyCards,
  loyaltyHeaderSelector,
  pointsToText,
  currentMonthItemsSelector,
  feautureMonthItemsSelector,
  availableItemsCountSelector,
  isLoadingCashbackSelector,
  checkedItemIdsSelector,
  cashbackDatesSelector,
  itemsFromManagerSelector,
  isLoadingSetActionSelector,
  setCaskbackError,
  selectedItemIdsSelector,
  loyaltyAccessSelector,
  expirationSelector,
  sectionsSelector
} from './selector'

const defaultOnRequest = state => ({ ...state })

export const fetchStatus = createAction('loyalty/FETCH_STATUS')
export const fetchStatusSuccess = createAction('loyalty/FETCH_STATUS_SUCCESS')
const fetchStatusFailure = createAction('loyalty/FETCH_STATUS_FAILURE')

export const deleteTarget = createAction('loyalty/DELETE_TARGET')
const deleteTargetSuccess = createAction('loyalty/DELETE_TARGET_SUCCESS')
const deleteTargetFailure = createAction('loyalty/DELETE_TARGET_FAILURE')

export const setTarget = createAction('loyalty/SET_TARGET')
export const setTargetSuccess = createAction('loyalty/SET_TARGET_SUCCESS')
export const setTargetFailure = createAction('loyalty/SET_TARGET_FAILURE')

export const setBarColor = createAction('loyalty/SET_BAR_COLOR')

export const fetchTarget = createAction('loyalty/FETCH_TARGET')
export const fetchTargetSuccess = createAction('loyalty/FETCH_TARGET_SUCCESS')
const fetchTargetFailure = createAction('loyalty/FETCH_TARGET_FAILURE')

export const fetchContent = createAction('loyalty/FETCH_CONTENT')
const fetchContentSuccess = createAction('loyalty/FETCH_CONTENT_SUCCESS')
const fetchContentFailure = createAction('loyalty/FETCH_CONTENT_FAILURE')

export const acceptConditions = createAction('loyalty/ACCEPT_CONDITIONS')
const acceptConditionsSuccess = createAction(
  'loyalty/ACCEPT_CONDITIONS_SUCCESS'
)

export const fetchSections = createAction('sections/GET')
export const fetchSectionsSuccess = createAction('sections/GET_SUCCESS')

export const fetchFileConditions = createAction('loyalty/FETCH_FILE')
export const fetchFileConditionsSuccess = createAction(
  'loyalty/FETCH_FILE_SUCCESS'
)
export const fetchFileConditionsFailure = createAction(
  'loyalty/FETCH_FILE_FAILURE'
)

export const fetchExpiration = createAction('loyalty/FETCH_EXPIRATION')
export const fetchExpirationSuccess = createAction(
  'loyalty/FETCH_EXPIRATION_SUCCESS'
)
export const fetchExpirationFailure = createAction(
  'loyalty/FETCH_EXPIRATION_FAILURE'
)

export const changeStatus = createAction('loyalty/CHANGE_STATUS')
const changeStatusSuccess = createAction('loyalty/CHANGE_STATUS_SUCCESS')

export const fetchCashback = createAction('loyalty/FETCH_CASHBACK')
export const fetchCashbackSuccess = createAction(
  'loyalty/FETCH_CASHBACK_SUCCESS'
)
export const fetchCashbackFailure = createAction(
  'loyalty/FETCH_CASHBACK_FAILURE'
)
export const setCashbackChecked = createAction('loyalty/SET_CASHBACK_CHECKED')
export const setCashback = createAction('loyalty/SET_CASHBACK')
export const setCashbackSuccess = createAction('loyalty/SET_CASHBACK_SUCCESS')
export const setCashbackFailure = createAction('loyalty/SET_CASHBACK_FAILURE')

export const request =
  ({ clientApi }) =>
    ({
      method = 'get',
      url = '/v3/loyalty/personal/',
      params = {},
      onSuccess = defaultOnRequest,
      onFailure = defaultOnRequest,
      ...rest
    }) =>
      clientApi[method](url, {
        params: { ...params, contractor_id: clientApi.getContractorId() }
      })
        .then(data => onSuccess({ data, ...rest }))
        .catch(data => onFailure({ data, ...rest }))

const handleFetchStatus = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      status: {
        ...pathOr({}, ['status'], state),
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      params,
      onSuccess: fetchStatusSuccess,
      onFailure: fetchStatusFailure
    })
  )

const handleFetchStatusSuccess = (state, payload) => {
  const effects = []
  const loyaltyTarget = pathOr(
    {},
    ['data', 'data', 'response', 'TARGET'],
    payload
  )
  if (!isEmpty(loyaltyTarget)) {
    const targetResponse = {
      data: { data: { response: { ITEM: loyaltyTarget } } }
    }
    effects.push(Effects.call(fetchTargetSuccess, targetResponse))
  }
  const stateMember = pathOr(1, ['status', 'ACCESSES', 'MEMBER'], state)
  const responseMember = pathOr(
    false,
    ['data', 'data', 'response', 'ACCESSES', 'MEMBER'],
    payload
  )
  if (!stateMember && responseMember) {
    const balance = pathOr(
      0,
      ['data', 'data', 'response', 'BALANCE', 'CURRENT'],
      payload
    )
    effects.push(
      Effects.call(changeLoyaltyStatus, { balance, isMember: responseMember })
    )
  }

  effects.push(Effects.call(fetchExpiration, {}))
  return loop(
    {
      ...state,
      status: {
        ...pathOr({}, ['data', 'data', 'response'], payload),
        ACCESS: pathOr(
          {},
          ['data', 'data', 'response', 'ACCESSES', 'ACCESS'],
          payload
        )
          ? '1'
          : false,
        isLoading: false,
        isLoaded: true,
        isFailed: false
      }
    },
    Effects.batch(effects)
  )
}

const handleFetchStatusFailure = state => ({
  ...state,
  status: {
    isLoading: false,
    isLoaded: false,
    isFailed: true
  }
})

const handleFetchExpiration = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      expiration: {
        ...state.expiration,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      url: '/v3/loyalty/expiration/',
      onSuccess: fetchExpirationSuccess,
      onFailure: fetchExpirationFailure
    })
  )

const handleFetchExpirationSuccess = (state, payload) => ({
  ...state,
  expiration: {
    isLoading: false,
    isLoaded: true,
    items: pathOr(
      emptyObject,
      ['data', 'data', 'response', 'EXPIRATION'],
      payload
    )
  }
})

const handleFetchExpirationFailure = state => ({
  ...state,
  expiration: {
    isLoading: false,
    isLoaded: false
  }
})

const handleFetchSections = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      sections: {
        ...state.sections,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      url: '/v3/catalog/loyalty/sections/',
      onSuccess: fetchSectionsSuccess
    })
  )

const handleFetchSectionsSuccess = (state, payload) => {
  const items = pathOr([], ['data', 'data', 'response', 'ITEMS'], payload)

  return {
    ...state,
    sections: {
      isLoading: false,
      isLoaded: true,
      items
    }
  }
}

const handleSetBarColor = (state, barColor) => ({
  ...state,
  barColor
})

const handleFetchTarget = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      target: {
        ...state.target,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      url: '/v3/catalog/loyalty/target/',
      onSuccess: fetchTargetSuccess,
      onFailure: fetchTargetFailure
    })
  )

const handleFetchTargetSuccess = (state, payload) => ({
  ...state,
  target: {
    isLoading: false,
    isLoaded: true,
    item: pathOr(emptyObject, ['data', 'data', 'response', 'ITEM'], payload)
  }
})

const handleFetchTargetFailure = state => ({
  ...state,
  target: {
    isLoading: false,
    isLoaded: false
  }
})

const handleFetchContent = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      content: {
        ...state.content,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      url: '/v1/content/loyalty/',
      onSuccess: fetchContentSuccess,
      onFailure: fetchContentFailure
    })
  )

const handleFetchContentSuccess = (state, payload) => {
  const data = pathOr({}, ['data', 'data', 'response', 'ITEMS'], payload)
  const items = map(item => ({
    id: item.ID,
    to: `/loyalty/${item.CODE}`,
    content: item.NAME
  }))(values(data))

  return {
    ...state,
    content: {
      isLoading: false,
      isLoaded: true,
      data,
      items
    }
  }
}

const handleFetchContentFailure = state => ({
  ...state,
  content: {
    isLoading: false,
    isLoaded: false
  }
})

const handleSetTarget = (state, { id, count = 1 }, { clientApi }) =>
  loop(
    {
      ...state,
      target: {
        ...state.target,
        isLoading: id,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      url: `/v3/catalog/loyalty/target/add/${id}/`,
      method: 'post',
      params: {
        count
      },
      onSuccess: setTargetSuccess,
      onFailure: setTargetFailure
    })
  )

const handleSetTargetSuccess = (state, payload) => {
  const effects = []
  const isSuccess = pathOr(
    false,
    ['data', 'data', 'response', 'SUCCESS'],
    payload
  )
  if (isSuccess) {
    effects.push(Effects.call(fetchTargetSuccess, payload))
  }
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleSetTargetFailure = state => ({ ...state })

const handleDeleteTarget = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      target: {
        ...state.target,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      method: 'post',
      url: '/v3/catalog/loyalty/target/delete/',
      onSuccess: () => deleteTargetSuccess(payload),
      onFailure: deleteTargetFailure
    })
  )

const handleDeleteTargetSuccess = (state, isRedirect) =>
  loop(
    {
      ...state,
      target: {
        isLoading: false
      }
    },
    isRedirect ? Effects.call(push, '/loyalty/basket') : Effects.none()
  )

const handleDeleteTargetFailure = state => ({
  ...state,
  target: {
    isLoading: false
  }
})

const handleAcceptConditions = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      acceptConditions: true,
      acceptSending: true
    },
    Effects.promise(request({ clientApi }), {
      method: 'post',
      url: '/v1/loyalty/join/',
      onSuccess: acceptConditionsSuccess,
      onFailure: acceptConditionsSuccess
    })
  )

const handleAcceptConditionsSuccess = (state, payload) => {
  const acceptSuccess = pathOr(
    'N',
    ['data', 'data', 'response', 'SUCCESS'],
    payload
  )
  return loop(
    {
      ...state,
      acceptResult: acceptSuccess,
      acceptSending: false
    },
    acceptSuccess === 'Y' ? Effects.call(fetchStatus) : Effects.none()
  )
}

const handleFetchFileConditions = (state, id, { clientApi }) =>
  loop(
    {
      ...state,
      file: {
        ...state.file,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(request({ clientApi }), {
      url: `/v1/content/loyalty/${id}/download/`,
      onSuccess: fetchFileConditionsSuccess,
      onFailure: fetchFileConditionsFailure
    })
  )

const handleFetchFileConditionsSuccess = (state, payload) => {
  const effects = []
  const pathToDest = pathOr(
    '',
    ['data', 'data', 'response', 'ITEM', 'PATH'],
    payload
  )
  let error = ''
  if (!isEmpty(path)) {
    window.location.href = pathToDest
  } else {
    effects.push(Effects.call(showModal, 'conditionsFileError'))
    const errorFromPayload = pathOr(
      '',
      ['data', 'data', 'response', 'ITEM', 'TEXT'],
      payload
    )
    error = errorFromPayload || fileErrorText
  }
  return loop(
    {
      ...state,
      file: {
        isLoading: false,
        isLoaded: true,
        data: {
          path,
          error
        }
      }
    },
    Effects.batch(effects)
  )
}

const handleFetchFileConditionsFailure = state =>
  loop(
    {
      ...state,
      file: {
        isLoading: false,
        isLoaded: false,
        data: {
          error: fileErrorText
        }
      }
    },
    Effects.call(showModal, 'conditionsFileError')
  )

const handleChangeStatus = (state, card, { clientApi }) =>
  loop(
    {
      ...state,
      changeStatus: false,
      changeStatusSending: true
    },
    Effects.promise(request({ clientApi }), {
      method: 'post',
      url: '/v3/loyalty/change/',
      params: { card },
      onSuccess: changeStatusSuccess,
      onFailure: changeStatusSuccess
    })
  )

const handleChangeStatusSuccess = (state, payload) => {
  const effects = []
  const changeSuccess = pathOr(
    false,
    ['data', 'data', 'response', 'SUCCESS'],
    payload
  )
  if (changeSuccess) {
    effects.push(Effects.call(hideModal))
    effects.push(Effects.call(showModal, 'changeStatusSuccess'))
  } else effects.push(Effects.call(showModal, 'changeStatusError'))
  return loop(
    {
      ...state,
      changeStatus: changeSuccess,
      changeStatusSending: false
    },
    Effects.batch(effects)
  )
}

const handleFetchCashback = (
  state,
  { period = 'currentMonth' },
  { clientApi }
) => {
  const effects = []
  const dateFrom = equals(period, 'currentMonth')
    ? moment().utc().startOf('month')
    : moment().utc().add(1, 'month').startOf('month')

  const dateTo = equals(period, 'currentMonth')
    ? moment().utc().endOf('month')
    : moment().utc().add(1, 'month').endOf('month')

  const paramsDateFrom = dateFrom.unix()
  const paramsDateTo = dateTo.unix()

  const params = {
    dateBegin: paramsDateFrom,
    dateEnd: paramsDateTo
  }

  if (!equals(period, 'currentMonth')) {
    // список сегментов на следующий месяц, доступных для выбора
    effects.push(
      Effects.promise(request({ clientApi }), {
        url: '/v3/loyalty/segment/selection/',
        params,
        period,
        type: 'notChecked',
        onSuccess: fetchCashbackSuccess,
        onFailure: fetchCashbackFailure
      })
    )
  }
  // список выбранных сегментов на текущий или следующий месяц
  effects.push(
    Effects.promise(request({ clientApi }), {
      url: '/v3/loyalty/segment/',
      period,
      params: {
        ...params,
        withManagerSelection: equals(period, 'currentMonth') // возвращать сегменты, назначенные менеджером
      },
      onSuccess: fetchCashbackSuccess,
      onFailure: fetchCashbackFailure
    })
  )
  return loop(
    {
      ...state,
      cashback: {
        ...state.cashback,
        [period]: {
          items: [],
          notChecked: [],
          cnt: 0,
          dateFrom: dateFrom.format('DD.MM.YYYY'),
          dateTo: dateTo.format('DD.MM.YYYY'),
          paramsDateFrom,
          paramsDateTo
        },
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.batch(effects)
  )
}

const handleFetchCashbackSuccess = (state, payload) => {
  const period = propOr('currentMonth', 'period', payload)
  const itemsType = propOr('', 'type', payload)
  const periodData = pathOr(emptyObject, ['cashback', period], state)
  const itemsName = !itemsType ? 'items' : itemsType
  const items = !itemsType
    ? pathOr([], ['data', 'data', 'response'], payload)
    : pathOr([], ['data', 'data', 'response', 'ITEMS'], payload)
  const selectedItems =
    !itemsType && !equals(period, 'currentMonth')
      ? compose(
        reduce((acc, item) => [...acc, propOr('', 'GUID', item)], []),
        pathOr([], ['data', 'data', 'response'])
      )(payload)
      : []
  const cnt = equals(period, 'currentMonth')
    ? 0
    : add(
      pathOr(0, ['data', 'data', 'response', 'COUNT'], payload),
      pathOr(0, ['cashback', period, 'cnt'], state)
    )
  return {
    ...state,
    cashback: {
      ...state.cashback,
      [period]: {
        ...periodData,
        [itemsName]: items,
        cnt,
        selectedItems,
        checkedItems: selectedItems
      },
      isLoading: false,
      isLoaded: true
    }
  }
}

const handleFetchCashbackFailure = state => ({
  ...state,
  cashback: {
    ...state.cashback,
    isLoading: false,
    isLoaded: false
  }
})

const handleSetCashback = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      cashback: {
        ...state.cashback,
        isLoadingSetAction: true
      }
    },
    Effects.promise(request({ clientApi }), {
      method: 'post',
      url: '/v3/loyalty/segment/selection/',
      params: {
        segments: propOr([], 'items', params),
        dateBegin: pathOr(
          '',
          ['cashback', 'feautureMonth', 'paramsDateFrom'],
          state
        ),
        dateEnd: pathOr(
          '',
          ['cashback', 'feautureMonth', 'paramsDateTo'],
          state
        )
      },
      onSuccess: setCashbackSuccess,
      onFailure: setCashbackFailure
    })
  )

const handleSetCashbackChecked = (state, items) => ({
  ...state,
  cashback: {
    ...state.cashback,
    feautureMonth: {
      ...state.cashback.feautureMonth,
      checkedItems: items
    }
  }
})

const handleSetCashbackSuccess = state =>
  loop(
    {
      ...state,
      cashback: {
        ...state.cashback,
        isLoadingSetAction: false
      }
    },
    Effects.call(fetchCashback, { period: 'feautureMonth' })
  )

const handleSetCashbackFailure = state =>
  loop(
    {
      ...state,
      cashback: {
        ...state.cashback,
        isLoadingSetAction: false,
        error: fileErrorText
      }
    },
    Effects.call(showModal, 'setCashbackError')
  )

const reducer = createReducer(on => {
  on(fetchStatus, handleFetchStatus)
  on(fetchStatusSuccess, handleFetchStatusSuccess)
  on(fetchStatusFailure, handleFetchStatusFailure)

  on(deleteTarget, handleDeleteTarget)
  on(deleteTargetSuccess, handleDeleteTargetSuccess)
  on(deleteTargetFailure, handleDeleteTargetFailure)

  on(setBarColor, handleSetBarColor)

  on(setTarget, handleSetTarget)
  on(setTargetSuccess, handleSetTargetSuccess)
  on(setTargetFailure, handleSetTargetFailure)

  on(fetchTarget, handleFetchTarget)
  on(fetchTargetSuccess, handleFetchTargetSuccess)
  on(fetchTargetFailure, handleFetchTargetFailure)

  on(fetchContent, handleFetchContent)
  on(fetchContentSuccess, handleFetchContentSuccess)
  on(fetchContentFailure, handleFetchContentFailure)

  on(acceptConditions, handleAcceptConditions)
  on(acceptConditionsSuccess, handleAcceptConditionsSuccess)

  on(fetchSections, handleFetchSections)
  on(fetchSectionsSuccess, handleFetchSectionsSuccess)

  on(fetchFileConditions, handleFetchFileConditions)
  on(fetchFileConditionsSuccess, handleFetchFileConditionsSuccess)
  on(fetchFileConditionsFailure, handleFetchFileConditionsFailure)

  on(fetchExpiration, handleFetchExpiration)
  on(fetchExpirationSuccess, handleFetchExpirationSuccess)
  on(fetchExpirationFailure, handleFetchExpirationFailure)

  on(changeStatus, handleChangeStatus)
  on(changeStatusSuccess, handleChangeStatusSuccess)

  on(fetchCashback, handleFetchCashback)
  on(fetchCashbackSuccess, handleFetchCashbackSuccess)
  on(fetchCashbackFailure, handleFetchCashbackFailure)
  on(setCashbackChecked, handleSetCashbackChecked)
  on(setCashback, handleSetCashback)
  on(setCashbackSuccess, handleSetCashbackSuccess)
  on(setCashbackFailure, handleSetCashbackFailure)
}, initialState)

export default reducer
