import React from 'react'

import cx from 'classnames'
import { array, bool, number, func } from 'prop-types'
import { prop } from 'ramda'

import CatalogListBlock from 'components/CatalogListBlock'
import { display } from 'decorators/device'

import styles from './CatalogList.scss'

const WIDTH_ONE_COLUMN = 288
const DEFAULT_COLUMN_COUNT = 3

const CatalogList = ({
  catalogArray = [],
  columnCount = DEFAULT_COLUMN_COUNT,
  isPageCatalog = false,
  isBookmark = false,
  bookmark = 0,
  withoutImages = false,
  isDesktop = true,
  onHideCatalog = () => {}
}) => {
  const width = isPageCatalog ? '100%' : `${WIDTH_ONE_COLUMN * columnCount}px`
  return (
    <ul
      className={cx(styles.catalog, {
        [styles.catalog_pageCatalog]: isPageCatalog
      })}
      style={isDesktop ? { columnCount, width } : {}}
      itemScope
      itemType='http://schema.org/ItemList'
    >
      {catalogArray.map(item => (
        <CatalogListBlock
          key={prop('ID', item)}
          list={item}
          withoutImages={withoutImages}
          isBookmark={isBookmark}
          bookmark={bookmark}
          onHideCatalog={onHideCatalog}
          isPageCatalog
        />
      ))}
    </ul>
  )
}

CatalogList.propTypes = {
  catalogArray: array,
  columnCount: number,
  isPageCatalog: bool,
  isBookmark: bool,
  bookmark: number,
  withoutImages: bool,
  isDesktop: bool,
  onHideCatalog: func
}

CatalogList.defaultProps = {
  catalogArray: [],
  columnCount: 3,
  isPageCatalog: false
}

export default display(CatalogList)
