import { set, lensPath, pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'

const emptyArray = []
const initialState = {
  groupedSelectedIndex: 0,
  sortedSelectedIndex: 0,
  sortOptions: emptyArray
}

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAY_IN_MONTH = 31

const cookieOpts = {
  path: '/',
  expires: new Date(
    Date.now() +
      DAY_IN_MONTH *
        HOURS_IN_DAY *
        MINUTES_IN_HOUR *
        SECONDS_IN_MINUTE *
        MILLISECONDS_IN_SECOND
  )
}

export {
  getGroupedSelectedIndex,
  getSortedSelectedIndex,
  getSortOptions
} from './selector'

export const setSortedSelectedIndex = createAction(
  'basket/SET_SORTED_SELECTED_INDEX'
)
export const fetchSuccess = createAction('basket/FETCH_SUCCESS')

const handleSortedSelectedIndex = (state, payload, { cookie }) => {
  cookie.save(
    'params_basket_sort_index',
    { selectedIndex: payload },
    cookieOpts
  )
  return set(lensPath(['sortedSelectedIndex']), payload, state)
}
const handleFetchSuccess = (state, payload) => ({
  ...state,
  sortOptions: pathOr(emptyArray, ['data', 'response', 'NAV', 'SORT'], payload)
})

const reducer = createReducer(on => {
  on(setSortedSelectedIndex, handleSortedSelectedIndex)
  on(fetchSuccess, handleFetchSuccess)
}, initialState)

export default reducer
