import React, { useEffect } from 'react'

import { func, string } from 'prop-types'
import { isEmpty } from 'ramda'
import { connect } from 'react-redux'

import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import { fetchCabinet918, linkCabinet918Selector } from 'redux/modules/personal'

const HeaderMenu918 = ({
  onFetchCabinet918 = () => {},
  linkTo = '',
  ...props
}) => {
  useEffect(() => {
    onFetchCabinet918()
  }, [])

  if (isEmpty(linkTo)) {
    return null
  }

  return <HeaderMenuItem linkTo={linkTo}
    {...props} />
}

HeaderMenu918.propTypes = {
  onFetchCabinet918: func,
  linkTo: string
}

export default connect(
  ({ personal }) => ({ linkTo: linkCabinet918Selector(personal) }),
  { onFetchCabinet918: fetchCabinet918 }
)(HeaderMenu918)
