import { prop, map, propOr } from 'ramda'
import { createSelector } from 'reselect'

const tabs = [
  {
    id: 'Draft',
    title: 'Не отправлено',
    type: 'error'
  },
  {
    id: 'InProgress',
    title: 'Отправлено'
  },
  {
    id: 'Completed',
    title: 'Завершено',
    type: 'primary'
  }
]

export const getIsLoading = createSelector(
  state => state,
  state => prop('isLoading', state)
)

export const getIsError = createSelector(
  state => state,
  state => prop('isError', state)
)

export const getClaims = createSelector(
  state => state,
  state => prop('claims', state)
)

export const getErrorMessages = createSelector(
  state => state,
  state => prop('errorMessages', state)
)

export const getIsDeleting = createSelector(
  state => state,
  state => prop('isDeleting', state)
)

export const getTabs = createSelector(
  state => state,
  state => prop('tabs', state),
  (_, tabCounts) =>
    map(item => {
      const id = prop('id', item)
      return {
        ...item,
        count: propOr(0, id, tabCounts)
      }
    }, tabs)
)

export const getListIsEmpty = createSelector(getTabs, items =>
  items.every(item => prop('count', item) === 0)
)

export const getIsLoadingReport = createSelector(
  state => state,
  state => prop('isLoadingReport', state)
)
