/* eslint-disable complexity */
import R from 'ramda'

import parse from 'utils/querystring'

const view = 'products'
const getProducts =
  ({ url, urlParams = {}, type, basket = 'main', useQuery = [] }) =>
    ({ store, location, helpers }) => {
      const { cookie, updateData } = helpers
      const preventNavigation = R.path(
        ['products', 'preventNavigation'],
        store.getState()
      )
      if (preventNavigation) {
        return updateData.preventNavigation()
      }
      const sectionKey = 'section_id'
      const queryPick = parse(R.prop('search', location))
      const section = R.prop('section', queryPick)
      const query = {
        ...R.pick(useQuery, queryPick),
        [sectionKey]: section,
        ...urlParams,
        type: 'items'
      }
      if (
        url === updateData.get().url &&
      R.equals(query, updateData.get().query)
      ) {
        updateData.set({ view, basket, forceUpdate: !location.state })
        return updateData.updateView()
      }
      const isSameQuery = query.q === R.path(['query', 'q'], updateData.get())
      const cookieParams = cookie.load('params_catalog')
      const pageId = `${url}${[sectionKey]
        .concat(useQuery.sort())
        .map((key, i) => `${!i ? '?' : ''}${key}=${query[key]}`)
        .join('&')}`
      updateData.set({
        view,
        url,
        pageId,
        type,
        cookieParams,
        query,
        isSameQuery,
        basket,
        forceUpdate: false
      })
      return updateData.update()
    }

export default getProducts
