/* eslint-disable complexity */
import React from 'react'

import { array } from 'prop-types'
import { map } from 'ramda'

import RatingStar from 'components/RatingStar'
import Text from 'UI/Text'

import s from './ProductReview.scss'

const NUMBER_OF_STARS = 5

const ReviewList = ({ feedbackPart }) => {
  return (
    <ul className={s.reviewList}>
      {map(feedbackItem => {
        const {
          advantages,
          anonymity,
          comment,
          disadvantages,
          rating,
          contractor,
          createdDate,
          modifiedDate,
          modifiedTime,
          response
        } = feedbackItem
        return (
          <li
            itemProp='itemListElement'
            itemScope
            itemType='http://schema.org/ItemList'
            key={Math.random()}
            className={s.reviewListItem}
          >
            <div className={s.reviewListItemHead}
              itemProp='author'>
              {contractor ? (
                <Text bold>
                  {anonymity ? 'Анонимный отзыв' : contractor}
                </Text>
              ) : (
                ''
              )}
              <span className={s.reviewListItemHeadInfo}>
                <RatingStar rating={rating}
                  stars={NUMBER_OF_STARS} />
                <Text>{createdDate}</Text>
              </span>
            </div>
            <div className={s.reviewListItemBody}
              itemProp='description'>
              {(advantages || disadvantages || comment) && (
                <div className={s.reviewListItemBodyContent}>
                  {advantages && (
                    <div className={s.reviewListItemBodyContentText}>
                      <Text semibold>
                        Достоинства товара
                      </Text>
                      <Text>{advantages}</Text>
                    </div>
                  )}
                  {disadvantages && (
                    <div className={s.reviewListItemBodyContentText}>
                      <Text semibold>
                        Недостатки товара
                      </Text>
                      <Text>{disadvantages}</Text>
                    </div>
                  )}
                  {comment && (
                    <div className={s.reviewListItemBodyContentText}>
                      <Text semibold>
                        Комментарий к товару
                      </Text>
                      <Text>{comment}</Text>
                    </div>
                  )}
                </div>
              )}
              {response && (
                <div className={s.reviewListItemBodyContent}>
                  <div className={s.reviewListItemBodyContentText}>
                    <div className={s.reviewListItemBodyContentTextTitle}>
                      <Text semibold>
                        Ответ от сотрудника
                      </Text>
                      <span
                        className={s.reviewListItemBodyContentTextTitleDate}
                      >
                        <Text>{modifiedDate}</Text>
                        <Text semibold>
                          {modifiedTime}
                        </Text>
                      </span>
                    </div>
                    <p
                      className={s.reviewListItemBodyContentTextResponse}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: response }}
                    />
                  </div>
                </div>
              )}
            </div>
          </li>
        )
      }, feedbackPart)}
    </ul>
  )
}

ReviewList.propTypes = {
  feedbackPart: array
}

export default ReviewList
