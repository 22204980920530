import React from 'react'

import cx from 'classnames'
import { bool } from 'prop-types'

import Icon from 'components/Icon'

import s from './IconCheckbox.scss'

const IconCheckbox = ({
  checked = false,
  disabled = false,
  hoverState = false
}) => {
  let iconName = 'checkbox'
  if (checked) iconName = 'checkbox-checked'
  if (checked && disabled) iconName = 'checkbox-gray'
  return (
    <Icon
      className={cx({
        [s.icon_gray]: (disabled) && !checked && !hoverState,
        [s.icon_hover]: hoverState && !disabled && !checked
      })}
      icon={iconName}
    />
  )
}

IconCheckbox.propTypes = {
  checked: bool,
  disabled: bool,
  hoverState: bool
}

export default IconCheckbox
