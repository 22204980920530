import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const LoyaltyCashback = loadable(() =>
  import(
    /* webpackChunkName: "LoyaltyCashback" */ 'pages/LoyaltyCashback/LoyaltyCashback'
  )
)

export default asyncConnect([
  {
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'loyaltyCashback' })
      return updateData.update()
    }
  }
])(LoyaltyCashback)
