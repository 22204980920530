import { propOr, prop, pathOr, values, toLower } from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'

export const getBrand = (state, { section = 'data', id }, def = {}) =>
  (state[section] || {})[id] || def
export const isBrandLoading = (state, { section = 'data' }) =>
  (state[section] || {}).isLoading
export const isBrandLoaded = (state, { section = 'data', id }) =>
  !!getBrand(state, { section, id }, null)
export const isBrandSectionsLoaded = (state, { id }) =>
  pathOr(false, ['sections', id], state)
export const isBrandProductsLoaded = (state, { id }) =>
  pathOr(false, ['products', id], state)

export const brandSelector = createSelector(
  brands => brands,
  brands => brands
)
export const brandDataSelector = createSelector(
  brandSelector,
  (_, id) => id,
  (brand, id) => pathOr({}, ['data', id], brand)
)
export const brandLoadedSelector = createSelector(brandSelector, brand =>
  propOr(false, 'isLoaded', brand)
)

export const brandLoadingSelector = createSelector(brandSelector, brand =>
  propOr(false, 'isLoading', brand)
)

export const getBrandData = createSelector(
  state => state.brandsDetail,
  state => state.updateData.id,
  (brandsDetail, id) => getBrand(brandsDetail, { id })
)

export const brandSectionsLoadedSelector = createSelector(
  state => state.brandsDetail,
  state => state.updateData.id,
  (brandsDetail, id) => isBrandLoaded(brandsDetail, { id, section: 'sections' })
)

export const brandSectionsLoadingSelector = createSelector(
  state => state.brandsDetail,
  state => state.updateData.id,
  (brandsDetail, id) =>
    isBrandLoading(brandsDetail, { id, section: 'sections' })
)

export const allSectionsSelector = createSelector(
  state => state.brandsDetail,
  state => state.updateData.id,
  (brandsDetail, id) => getBrand(brandsDetail, { id, section: 'sections' })
)

export const brandProductsSelector = createSelector(
  state => state.brandsDetail,
  state => state.updateData.id,
  (brandsDetail, id) => getBrand(brandsDetail, { id, section: 'products' })
)

export const brandSectionsSelector = createSelector(
  state => state.brandsDetail,
  state => state.sections,
  brandSectionsLoadedSelector,
  brandSectionsLoadingSelector,
  allSectionsSelector,
  (
    brandsDetail,
    sections,
    brandSectionsLoaded,
    brandSectionsLoading,
    allSections
  ) => {
    const brandSections = {}
    if (brandSectionsLoaded) {
      values(allSections).map(item => {
        if (item.PARENT_ID === 0) {
          brandSections[item.ID] = {
            ...item,
            items: []
          }
        } else if (brandSections[item.PARENT_ID]) {
          brandSections[item.PARENT_ID].items.push(item)
        }
        return item
      })
    }
    return brandSections
  }
)

export const getBrandCode = (state, id) =>
  pathOr(false, ['brandsDetail', 'data', id, 'CODE'], state)

export const titleSelector = createSelector(pathOr('', ['meta']), meta =>
  prop('title', renameKeys(toLower, meta))
)

export const headSelector = createSelector(
  state => state,
  state => propOr('', 'title', state)
)

export const manufactureDetailSelector = createSelector(
  state => state,
  brandData => propOr({}, 'manufacture', brandData)
)
