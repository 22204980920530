/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { object, func } from 'prop-types'
import { propOr } from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Modal from 'containers/Modal'
import { forAuth } from 'decorators/user'
import { setAnalyticsProps } from 'helpers/metaTags'
import { hideModal } from 'redux/modules/modal'
import Button from 'UI/Button'
import Preloader from 'UI/Preloader'

import s from './PopupDownloadOrder.scss'

const docSelector = createSelector(
  orders => orders,
  orders => propOr({}, 'doc', orders)
)

@forAuth
@connect(
  ({ orders }) => ({
    doc: docSelector(orders)
  }),
  {
    hideModal
  }
)
class PopupDownloadOrder extends PureComponent {
  static propTypes = {
    hideModal: func,
    doc: object
  }

  static defaultProps = {
    hideModal: () => {}
  }

  handleClose = () => {
    this.props.hideModal()
  }

  render() {
    const { doc } = this.props
    return (
      <Modal id='downloadDoc'
        title='Просмотр счета'
        size='small'>
        <div className={s.modal}>
          {doc && propOr(false, 'isLoading', doc) ? (
            <Preloader active />
          ) : (
            <>
              {propOr(false, 'docError', doc) && (
                <div className={cx(s.doc, s.docError)}>
                  <div className={s.docItem}>{propOr('', 'docError', doc)}</div>
                </div>
              )}
              {propOr(false, 'docPath', doc) && (
                <div className={s.doc}>
                  <div className={s.docItem}>
                    Архив сформирован, Вы можете его скачать
                  </div>
                  <div
                    className={cx(s.docItem, s.docLink)}
                    {...setAnalyticsProps({
                      type: 'action',
                      group: 'basketActions',
                      action: 'downloadBill'
                    })}
                  >
                    <Button onClick={this.handleClose}
                      href={propOr('', 'docPath', doc)}
                      target='_blank'
                      rel='noopener noreferrer'
                      stretched
                      icon='download'
                    >
                      Скачать
                    </Button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </Modal>
    )
  }
}

export default PopupDownloadOrder
