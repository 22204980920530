import { propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getIsUserWithoutPassword = state => state.isUserWithoutPassword
export const getError = state => state.error

export const isAuthSelector = createSelector(
  state => state,
  token => !propOr(true, 'isUserWithoutPassword', token)
)

export const tokenErrorSelector = createSelector(
  state => state,
  token => propOr('', 'error', token)
)
