import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Schedule = loadable(() => import(/* webpackChunkName: "Graphic" */'pages/Schedule/PageSchedule'))

export default asyncConnect([
  {
    key: 'pageCabinetGraphic',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({
        view: 'cabinetGraphic'
      })

      return updateData.update()
    }
  }
])(Schedule)