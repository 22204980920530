/* eslint-disable complexity */
import React, { useEffect, useRef, FC } from 'react'

import cx from 'classnames'
import { propOr } from 'ramda'
import { Link } from 'react-router-dom'
import SliderSlick from 'react-slick'

import Icon, { IconTypeProps } from 'components/Icon'

import styles from './Tabs.scss'

const FONT_SIZE_16 = 16

interface IItem {
  id: string,
  title: string,
  url?: string,
  icon?: IconTypeProps
}

interface ITabsProps {
  active: string,
  className?: string,
  fontSize?: number,
  handleClick?: (item: IItem) => void,
  handleMouseEnter?: (item: IItem) => void,
  isActiveCounter?: boolean,
  isLoyalty?: boolean,
  isQuickView?: boolean,
  isSearchModeration?: boolean,
  isInvoices?: boolean,
  items: IItem[],
  name?: string,
  isDesktop?: boolean
}

const Tabs: FC<ITabsProps> = ({
  active = '',
  className = '',
  fontSize = FONT_SIZE_16,
  handleClick = () => {},
  handleMouseEnter = () => {},
  isActiveCounter = false,
  isLoyalty = false,
  isQuickView = false,
  items = [],
  isDesktop = true
}) => {
  const refSlider = useRef<SliderSlick & { slickGoTo: (indexSlider: number) => void }>(null)

  useEffect(() => {
    if (isDesktop) return
    const indexSlider = items.findLastIndex(item => propOr('', 'id', item) === active)

    if (refSlider && refSlider.current) {
      refSlider.current.slickGoTo(indexSlider)
    }
  }, [active, isDesktop, items])

  const renderCount = (item: IItem) => {
    const count: number = propOr(0, 'count', item)
    if (!count) {
      return null
    }

    const type = propOr('default', 'type', item)
    return (
      <span
        className={cx(styles.count, {
          [styles[`count_${type}`]]: true
        })}
      >
        {count}
      </span>
    )
  }

  const renderTab = (item: IItem, key: number) => {
    const isActive = active === item.id
    let Tag: React.JSX.ElementType = 'div'
    const props = {
      to: item.url,
      onClick: handleClick(item),
      onMouseEnter: handleMouseEnter(item)
    }
    if (item.url) {
      Tag = Link
    }

    return (
      <li
        key={item.id || key}
        itemProp='itemListElement'
        itemScope
        itemType='http://schema.org/ItemList'
      >
        <meta itemProp='position'
          content={item.id} />
        <Tag
          className={cx(styles.tabItem, {
            [styles.tabActive]: isActive,
            [styles.tabActive_disabled]: isActive && isActiveCounter
          })}
          {...props}
        >
          <span className={styles.title}
            style={{ fontSize }}>
            {item.icon && (
              <b className={styles.iconWrapper}>
                <Icon className={styles.icon}
                  icon={item.icon} />
              </b>
            )}
            {item.title}
          </span>

          {renderCount(item)}
        </Tag>
      </li>
    )
  }

  return (
    <div
      className={cx(styles.tabs, {
        [styles.tabs_quickView]: isQuickView,
        [styles.tabs_loyalty]: isLoyalty,
        [className]: className
      })}
    >
      <div
        className={styles.tabListWrapper}
      >
        <SliderSlick
          ref={refSlider}
          dots={false}
          infinite={false}
          draggable={false}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          variableWidth
        >
          {items.map(renderTab, this)}
        </SliderSlick >
      </div>
    </div>
  )
}

export default Tabs
