import loadable from '@loadable/component'
import { mergeDeepRight, pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const BasketLoyalty = loadable(() =>
  import(
    /* webpackChunkName: "BasketLoyalty" */ 'pages/BasketLoyalty/BasketLoyalty'
  )
)

export default asyncConnect([
  {
    key: 'pageBasketLoyalty',
    promise: ({ helpers: { updateData, cookie }, store }) => {
      const state = store.getState()
      let basketParams = pathOr({}, ['productList', 'basketParams'], state)
      const cookieBasketParams = cookie.load('params_basket') || {}
      basketParams = mergeDeepRight(basketParams, cookieBasketParams)
      updateData.set({
        view: 'basket',
        catalog: 'loyalty',
        value: 0,
        page: 1,
        basketParams
      })
      return updateData.update()
    }
  }
])(BasketLoyalty)
