import React, { PureComponent } from 'react'

import cx from 'classnames'
import { number, bool, string } from 'prop-types'

import formatAmount from 'utils/formatAmount'

import styles from './ProductActions.scss'

class StoreCount extends PureComponent {
  static propTypes = {
    count: number,
    isHeader: bool,
    isActive: bool,
    isAvailable: bool,
    isStockPopup: bool,
    tooltip: string
  }

  static defaultProps = {
    count: 0,
    isHeader: false,
    isActive: false,
    isStockPopup: false,
    isAvailable: false
  }

  render() {
    const { count, isHeader, isActive, isAvailable, tooltip, isStockPopup } =
      this.props
    const isItemActive = isActive || isHeader
    return (
      <div
        className={cx(styles.count, {
          [styles.countTooltip]: !!tooltip,
          [styles.countAvailable]: isAvailable,
          [styles.countHeader]: isHeader,
          [styles.countZero]: count === 0,
          [styles.countPositive]: count > 0
        })}
        title={tooltip}
      >
        <div
          className={cx(styles.countIcon, {
            [styles.countIcon_active]: isItemActive,
            [styles.countIcon_stock]: isStockPopup,
            [styles.countIcon_stockActive]: isStockPopup && isItemActive
          })}
        />
        <span className={styles.countValue}>{formatAmount(count)} шт.</span>
      </div>
    )
  }
}

export default StoreCount
