import React, { FC } from 'react'

import { animateScroll as scroll } from 'react-scroll'

import Wrapper from 'components/Wrapper'
import { forDesktop } from 'decorators/device'
import Button, { ButtonShapeProps, ButtonColorProps } from 'UI/Button'

import s from './ScrollUpBtn.scss'

interface IScrollUpBtnProps {
  isBookmark?: boolean;
  isLoyalty?: boolean;
}

const ScrollUpBtn: FC<IScrollUpBtnProps> = ({ isBookmark, isLoyalty }) => {
  const handleClick = () => () => {
    scroll.scrollToTop({ duration: 1000 })
  }

  let shape: ButtonShapeProps = 'flat'
  let color: ButtonColorProps = 'blue'

  if (isLoyalty) {
    color = 'purple'
    shape = 'default'
  }
  if (isBookmark) {
    color = 'orange'
  }

  return (
    <Wrapper>
      <div className={s.scrollUpBtn}>
        <Button
          color={color}
          icon='up'
          onClick={handleClick()}
          shape={shape}
          size='default'
          stretched
        >
          Наверх
        </Button>
      </div>
    </Wrapper>
  )
}

export default forDesktop(ScrollUpBtn)
