// @ts-nocheck
import React, { FC, FormEventHandler } from 'react'

import cx from 'classnames'
import { compose, has, propOr } from 'ramda'
import { connect } from 'react-redux'
import { inject } from 'react-tunnel-16'
import { Field, reduxForm } from 'redux-form'

import Icon from 'components/Icon'
import { RatingStarField } from 'components/RatingStar'
import submit from 'containers/Forms/submit'
import { invoiceData } from 'redux/modules/invoiceDetails'
import { invoiceByIdSelector } from 'redux/modules/invoices/selector'
import {
  currentContractorGuidSelector,
  currentContractorIdSelector,
  currentContractorNameSelector
} from 'redux/modules/personal'
import Button from 'UI/Button'
import Text from 'UI/Text'
import { FieldTextarea } from 'UI/Textarea'

import s from './FormShipment.scss'

export interface IFormShipment {
  billsCount: number
  clientApi: object
  contractorID: string
  contractorGUID: string
  contractorName: string
  dateDelivery: string
  handleSubmit: FormEventHandler<HTMLFormElement>
  invalid: boolean
  paymentType: string
  popupSuccess: string
  shipmentGuid: string
  shipmentNum: string
  shipmentSum: string
  submitting?: boolean
}

const FormShipment: FC<IFormShipment> = ({
  billsCount,
  clientApi,
  contractorID,
  contractorGUID,
  contractorName,
  dateDelivery,
  handleSubmit,
  invalid,
  paymentType,
  popupSuccess,
  shipmentGuid,
  shipmentNum,
  shipmentSum,
  submitting
}) => (
  <form
    className={s.form}
    onSubmit={handleSubmit(
      submit({
        clientApi,
        formName: 'shipment_feedback',
        contractor_id: contractorID,
        contractor_guid: contractorGUID,
        contractor_code: contractorGUID,
        contractor_name: contractorName,
        popupSuccess,
        shipment_guid: shipmentGuid,
        shipment_num: shipmentNum,
        shipment_sum: shipmentSum,
        shipmentGuid
      })
    )}
  >
    <fieldset className={s.fieldset}>
      <div className={cx(s.block, s.block__shipment)}>
        <Text semibold>Накладная № {shipmentNum}</Text>
        <div className={s.row}>
          <div className={s.truck}>
            <Icon
              icon='truck'
              size='auto'
            />
            <span className={s.truck__count}>
              <Text semibold>{billsCount}</Text>
            </span>
          </div>
          <div className={s.column}>
            <Text semibold>Дата доставки</Text>
            <Text>{dateDelivery}</Text>
          </div>
          <div className={s.column}>
            <Text semibold>Сумма заказа</Text>
            <Text>{`${shipmentSum} ${paymentType}`}</Text>
          </div>
        </div>
      </div>
      <div className={cx(s.block, s.block__rating)}>
        <Text semibold>Ваша оценка:</Text>
        <Field component={RatingStarField}
          name='rating'
          required />
      </div>
      <div className={cx(s.block, s.block__comment)}>
        <Text semibold>Комментарий</Text>
        <Field
          component={FieldTextarea}
          name='comment'
          placeholder='Расскажите подробнее о вашей оценке: что понравилось, а что
          не оправдало ваших ожиданий'
        />
      </div>
    </fieldset>
    <Button type='submit'
      disabled={submitting || invalid}>
      Отправить
    </Button>
  </form>
)

export default compose(
  reduxForm({
    form: 'shipmentForm',
    // eslint-disable-next-line complexity
    validate: values => {
      const errors = {
        rating: !has('rating', values) ? 'Обязательное поле' : ''
      }

      return errors
    }
  }),
  connect(({ personal, invoices, invoiceDetails }, { shipmentId = null }) => {
    const invoice = shipmentId ? invoiceByIdSelector({ invoices, shipmentId }) : invoiceData(invoiceDetails, 'shipment')

    return {
      billsCount: propOr(0, 'billsCount', invoice),
      dateDelivery: propOr('', 'dateDelivery', invoice),
      shipmentSum: propOr('', 'sum', invoice),
      paymentType: propOr('', 'paymentType', invoice),
      shipmentGuid: propOr(shipmentId, 'id', invoice),
      shipmentNum: propOr('', 'num', invoice),
      contractorID: currentContractorIdSelector(personal),
      contractorGUID: currentContractorGuidSelector(personal),
      contractorName: currentContractorNameSelector(personal)
    }
  }),
  inject(({ helpers: { clientApi } }) => ({ clientApi }))
)(FormShipment)
