import { compose, map, prop, propOr } from 'ramda'
import { createSelector } from 'reselect'

export const getGroupedSelectedIndex = state => state.groupedSelectedIndex
export const getSortedSelectedIndex = state => state.sortedSelectedIndex

export const getSortOptions = createSelector(
  state => state,
  state => compose(
    map(option => ({
      title: prop('TITLE', option),
      value: `${prop('SORT', option)}_${prop('ORDER', option)}`
    })),
    propOr([], 'sortOptions')
  )(state)
)
