/* eslint-disable complexity */
import {
  values,
  filter,
  map,
  prop,
  propOr,
  path,
  compose,
  prepend,
  is,
  pathOr,
  sortWith,
  descend,
  ascend,
  isEmpty,
  slice
} from 'ramda'
import { createSelector } from 'reselect'

import { getMenuLink } from 'helpers/catalog'
import { sortSection } from 'helpers/sortProductList'

const MAX_FAVORITE_ITEMS_COUNT = 4

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getSections = state => state.data
export const getSectionById = (id, catalogType = 'main', state = {}) =>
  path([catalogType, 'data', id], state)

export const sectionsSelector = createSelector(
  ({ catalogType = 'main' }) => catalogType,
  ({ sections }) => sections,
  ({ bookmark = 0 }) =>
    is(String, bookmark) ? parseInt(bookmark, 10) : bookmark,
  (catalogType, sections, bookmark) =>
    catalogType !== 'bookmark'
      ? propOr({}, catalogType, sections)
      : pathOr({}, [catalogType, bookmark], sections)
)

export const sectionsDataSelector = createSelector(
  sectionsSelector,
  sections =>
    propOr(false, 'isLoaded', sections) && propOr({}, 'data', sections)
)

export const sectionsIsLoadedSelector = createSelector(
  sectionsSelector,
  sections => propOr(false, 'isLoaded', sections)
)

export const sectionsIsLoadingSelector = createSelector(
  sectionsSelector,
  sections => propOr(false, 'isLoading', sections)
)

export const sectionsMainSelector = createSelector(
  sectionsDataSelector,
  sections => ({
    favoriteItems: compose(
      slice(0, MAX_FAVORITE_ITEMS_COUNT),
      sortSection,
      filter(item => item.FAVORITE === true),
      values
    )(sections),
    catalogItems: compose(
      sortSection,
      filter(item => Number(item.DEPTH) === 1),
      values
    )(sections)
  })
)

export const sectionsFavoriteSelector = createSelector(
  sectionsDataSelector,
  sections =>
    compose(
      sortSection,
      filter(item => item.FAVORITE === true),
      values
    )(sections)
)

export const idSelector = createSelector(
  state => state,
  state => Number(prop('id', state))
)

export const sectionsByIdSelector = createSelector(
  sectionsDataSelector,
  idSelector,
  (sections, id) =>
    compose(
      sortWith([descend(prop('SORT')), ascend(prop('NAME'))]),
      map(item => {
        let items = []
        const isLast = propOr(false, 'IS_LAST', item)
        if (!isLast) {
          items = compose(
            sortWith([descend(prop('SORT')), ascend(prop('NAME'))]),
            filter(
              itm => Number(prop('PARENT', itm)) === Number(prop('ID', item))
            ),
            values
          )(sections)
        }
        return {
          ...item,
          items
        }
      }),
      filter(item => Number(prop('PARENT', item) || 0) === id),
      values
    )(sections)
)

export const sectionByIdSelector = createSelector(
  sectionsDataSelector,
  ({ id = 0 }) => id,
  (sections, id) => propOr({}, id, sections)
)

export const favoriteHomeSelector = createSelector(
  state => pathOr({}, ['favorite', 'data'], state),
  favorite => compose(slice(0, MAX_FAVORITE_ITEMS_COUNT), values)(favorite)
)

export const sectionDetailSelector = createSelector(
  state => state,
  ({ state, sectionId }) => pathOr({}, ['sectionsDetail', sectionId], state)
)

export const isLoadedSectionDetailSelector = createSelector(
  sectionDetailSelector,
  section => !isEmpty(section)
)

export const sectionBreadcrumbsSelector = createSelector(
  sectionsDataSelector,
  ({ id = 0 }) => id,
  ({ bookmark = undefined }) => bookmark,
  (sections, id, bookmark) => {
    let breadCrumbs = []
    let sectionId = id
    let section

    if (!id || !sections) {
      return []
    }

    do {
      section = pathOr({}, [sectionId], sections)
      sectionId = prop('PARENT', section)

      if (section.ID) {
        const query = {}
        const pathname = getMenuLink({
          isBookmark: !!bookmark,
          bookmark,
          item: section
        })

        breadCrumbs = [
          {
            id: section.ID,
            pathname,
            code: propOr('', 'CODE', section),
            query,
            title: propOr('', 'NAME', section)
          }
        ].concat(breadCrumbs)
      }
    } while (sectionId)
    if (bookmark) {
      breadCrumbs = prepend(
        {
          title: 'Закладки',
          pathname: '/bookmark'
        },
        breadCrumbs
      )
    }
    return prepend(
      {
        title: 'Каталог',
        pathname: '/catalog'
      },
      breadCrumbs
    )
  }
)
