import React, { useEffect, useRef, useState } from 'react'

import { string } from 'prop-types'
import {
  compose,
  defaultTo,
  filter,
  groupBy,
  head,
  includes,
  length,
  map,
  omit,
  prop,
  values
} from 'ramda'

import Image from 'components/Image'

import s from './Picture.scss'

const OMIT_ATTR = ['isLazy', 'imgClassName']

// todo браузеры не поддерживают picture->source
const Picture = ({ src, ...props }) => {
  const [width, setWidth] = useState(0)

  const refPicture = useRef(null)

  useEffect(() => {
    setWidth(refPicture.current ? refPicture.current.offsetWidth : 0)
  }, [refPicture.current])

  if (!src) {
    return null
  }

  let prepareSrc = src

  let isArray = false

  try {
    prepareSrc = JSON.parse(src)
    if (typeof prepareSrc === 'object') {
      isArray = true
    }
  } catch (e) {
    prepareSrc = src
  }

  let srcFiltered =
    isArray &&
    compose(
      filter(item => width <= prop('WIDTH_MAX', item)),
      defaultTo([])
    )(prepareSrc)

  if (isArray && length(srcFiltered) <= 0) {
    srcFiltered = prepareSrc
  }

  if (isArray && length(srcFiltered) > 0) {
    srcFiltered = compose(
      values,
      map(item => head(item)),
      groupBy(item => prop('TYPE', item))
    )(srcFiltered)
  }

  return (
    <picture ref={refPicture}
      className={s.picture}>
      {isArray &&
        srcFiltered.map(item => {
          if (includes(prop('TYPE', item), ['jpg', 'jpeg', 'png'])) {
            return (
              <Image
                {...omit(OMIT_ATTR, props)}
                key={prop('URL', item)}
                src={prop('URL', item)}
                isLazy={false}
              />
            )
          }
          return (
            <source
              {...omit(OMIT_ATTR, props)}
              key={prop('URL', item)}
              srcSet={prop('URL', item)}
              type={`image/${prop('TYPE', item)}`}
            />
          )
        })}
      {!isArray && <Image {...props}
        src={src} />}
    </picture>
  )
}

Picture.propTypes = {
  src: string
}

export default Picture
