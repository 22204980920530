import React, {useCallback } from 'react'

import { bool, number, array, func } from 'prop-types'
import { propOr } from 'ramda'
import CustomScroll from 'react-custom-scroll'

import Link from 'components/Link'
import { getMenuLink } from 'helpers/catalog'
import Spacing from 'UI/Spacing'

import s from './MenuCatalogMobile.scss'

const MenuSecondLevel = ({
  sections,
  onHideCatalog,
  isBookmark,
  bookmark,
  catalogHeight
}) => {

  const handleClick = useCallback(() => onHideCatalog(), [onHideCatalog])

  const getLink = item => {
    const link = propOr('', 'link', item)
    if (link) {
      return link
    }
    return getMenuLink({ isBookmark, bookmark, item })
  }

  const renderLink = link => (
    <li
      key={propOr('', 'ID', link)}
      itemProp='itemListElement'
      itemScope
      itemType='http://schema.org/ItemList'
    >
      <meta itemProp='position'
        content={propOr('', 'ID', link)} />
      <Link
        to={getLink(link)}
        onClick={handleClick}
      >
        <div className={s.menuCatalog__second_subitems}>
          {propOr('', 'NAME', link)}
          {propOr('', 'CNT', link) && (
            <span className={s.menuCatalog__second_count}>{propOr('', 'CNT', link)}</span>
          )}
        </div>
      </Link>
    </li>
  )

  const renderItem = item => (
    <div
      key={propOr('', 'ID', item)}
      className={s.menuCatalog__second_block}
    >
      <Link
        to={getLink(item)}
        onClick={handleClick}
        weight='bold'
      >
        <Spacing
          margin='bottom'
          size='small'
        >
          {propOr('', 'NAME', item)}
          <span className={s.count}>
            {propOr('', 'COUNT', item)}
          </span>
        </Spacing>
      </Link>
      <ul>
        {propOr([], 'items', item).map(renderLink)}
      </ul>
    </div>
  )

  return (
    <CustomScroll
      heightRelativeToParent={`${catalogHeight}px`}
      className={s.menuCatalog__second_scroll}
    >
      <div className={s.menuCatalog__second_content}>
        {sections.map(renderItem)}
      </div>
    </CustomScroll>
  )
}

MenuSecondLevel.propTypes = {
  sections: array,
  onHideCatalog: func,
  isBookmark: bool,
  bookmark: number,
  catalogHeight: number
}

MenuSecondLevel.defaultProps = {
  sections: []
}

export default MenuSecondLevel
