import React from 'react'

import FormAuth from 'containers/FormAuth'

const AuthContent = () => <FormAuth
  isPageAuth
  isRegistration={false}
  mode='pageAuth'
/>

export default AuthContent