import React, { FC, useCallback } from 'react'

import Checkbox from 'UI/Checkbox'

import s from './Buy.scss'

interface IBuy {
  isPoints: boolean
  setForPoints: ({ status }: { status: boolean }) => void
}

const Buy: FC<IBuy> = ({ setForPoints, isPoints = false }) => {
  const handleSetForPoints = useCallback(
    (checked: boolean) => setForPoints({ status: checked }),
    [setForPoints]
  )

  return (
    <div className={s.buy}>
      <Checkbox
        checked={isPoints}
        onChange={handleSetForPoints}
        title='За баллы'
      />
    </div>
  )
}

export default Buy
