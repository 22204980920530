import { lensPath, set, prop } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal, hideModal } from 'redux/modules/modal'
import { put } from 'redux/modules/orders'

export {
  getNoticeTop,
  getNoticeIsOpen,
  getNotices,
  getNoticeHeader,
  getNotice,
  getIsSendingConfirm
} from './selector'

const initialState = {
  alert_catalog: {
    items: [],
    isOpen: false
  },
  alert_basket: {
    items: [],
    isOpen: false
  },
  confirm_site: {
    items: [],
    isSending: false
  },
  confirm_basket: {
    items: [],
    isOpen: false
  },
  body_basket: {
    items: []
  },
  checkout_basket: {
    items: []
  },
  action: {}
}

export const setNotice = createAction('notice/SET_NOTICE')
export const confirm = createAction('notice/CONFIRM')
export const setSendingConfirm = createAction('notice/SET_SENDING_CONFIRM')
export const setNoticeStatus = createAction('notice/SET_NOTICE_STATUS')
export const setNoticeAction = createAction('notice/SET_NOTICE_ACTION')

const handleSetNotice = (
  state,
  { name, page = 'site', value = [], header = '' }
) => {
  const effects = []
  const newState = {
    items: value
  }
  if (['top', 'alert', 'confirm'].indexOf(name) !== -1) {
    effects.push(Effects.call(showModal, `${name}_${page}`))
    newState.header = header
  }

  return loop(
    set(lensPath([`${name}_${page}`]), newState, state),
    Effects.batch(effects)
  )
}

const handleSetNoticeStatus = (
  state,
  { name, page = 'site', status = false }
) => {
  const effects = []
  if (status) {
    effects.push(Effects.call(showModal, name))
  } else {
    effects.push(Effects.call(hideModal, name))
    if (name === 'confirm') {
      effects.push(Effects.call(setNoticeAction, {}))
    }
  }
  return loop(
    set(lensPath([`${name}_${page}`, 'isOpen']), status, state),
    Effects.batch(effects)
  )
}

const handleConfirm = (state, { status, operations }) => {
  const effects = []
  const operationVal = status ? 'Y' : 'N'
  const stateAction = prop('action', state)

  const action = {
    ...stateAction,
    operations: { [operations]: operationVal }
  }
  effects.push(Effects.call(put, action))
  effects.push(
    Effects.call(setSendingConfirm, { name: 'confirm', isSending: true })
  )
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleSetSendingConfirm = (state, { name, page = 'site', isSending }) =>
  set(lensPath([`${name}_${page}`, 'isSending']), isSending, state)

const handleSetNoticeAction = (state, payload) => ({
  ...state,
  action: payload
})

const reducer = createReducer(on => {
  on(setNotice, handleSetNotice)
  on(setNoticeStatus, handleSetNoticeStatus)
  on(setNoticeAction, handleSetNoticeAction)
  on(setSendingConfirm, handleSetSendingConfirm)
  on(confirm, handleConfirm)
}, initialState)

export default reducer
