import loadable from '@loadable/component'
import { pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

const CatalogListItem = loadable(() =>
  import(
    /* webpackChunkName: "CatalogListItem" */ 'pages/CatalogListItem/CatalogListItem'
  )
)

export default asyncConnect([
  {
    key: 'pageCatalogItem',
    promise: ({
      match,
      helpers: { updateData },
      location: { pathname = '' }
    }) => {
      const id = pathOr(0, ['params', 'id'], match)
      const bookmark = pathOr(0, ['params', 'bookmark'], match)
      updateData.set({ id, bookmark, view: 'catalogListItem', url: pathname })
      return updateData.update()
    }
  }
])(CatalogListItem)
