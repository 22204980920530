import loadable from '@loadable/component'
import { path, propOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

import getProducts from 'helpers/getProducts'
import parse from 'utils/querystring'

const SearchLoyalty = loadable(() =>
  import(
    /* webpackChunkName: "SearchLoyalty" */ 'pages/SearchLoyalty/SearchLoyalty'
  )
)

export default asyncConnect([
  {
    key: 'pageSearchLoyalty',
    promise: props =>
      getProducts({
        url: 'catalog/loyalty/search',
        urlParams: {
          correct: propOr(
            'Y',
            'correct',
            parse(path(['location', 'search'], props))
          )
        },
        type: 'search',
        useQuery: ['q', 'correct']
      })(props)
  }
])(SearchLoyalty)
