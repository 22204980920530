import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NoticeTop } from 'components/Notice'
import { getNoticeTop, setNoticeStatus } from 'redux/modules/notice'

export default connect(
  ({ notice }) => ({
    items: getNoticeTop(notice)
  }),
  dispatch =>
    bindActionCreators(
      {
        setNoticeStatus
      },
      dispatch
    )
)(NoticeTop)
