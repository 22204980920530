import React, { FC } from 'react'

import FormSecurity from 'containers/Forms/FormSecurity/FormSecurity'
import Modal from 'containers/Modal'

const SecurityModal: FC = () => (
  <>
    {/* @ts-ignore */}
    <Modal
      id='securityForm'
      title='Прямое обращение в Службу
      Безопасности компании «Рельеф-Центр»'
    >
      <FormSecurity />
    </Modal>
  </>
)

export default SecurityModal
