import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, object } from 'prop-types'

import Icon from 'components/Icon'

import { getIcon } from './getIcon'
import styles from './Notice.scss'

export default class NoticeCheckout extends PureComponent {
  static propTypes = {
    item: object,
    type: string
  }

  static defaultProps = {
    item: {},
    type: ''
  }

  render() {
    const {
      item: { TYPE, TEXT, BUTTON },
      type
    } = this.props
    if (!TEXT) {
      return (
        <div
          className={cx(styles.noticeCheckout, styles[`noticeCheckout${type}`])}
        />
      )
    }
    return (
      <div
        className={cx(
          styles[`notice${TYPE}`],
          styles.noticeCheckout,
          styles[`noticeCheckout${type}`]
        )}
      >
        <div className={styles.noticeDescription}>
          <div
            className={cx(styles.noticeLogo, {
              [styles[`noticeLogo${TYPE}`]]: TYPE
            })}
          >
            <Icon
              className={cx({
                [styles.noticeIcon]: true,
                [styles[`noticeIcon${TYPE}`]]: true
              })}
              icon={getIcon(TYPE, BUTTON)}
              size='default'
            />
          </div>
          <div
            className={styles.noticeTextCheckout}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: TEXT }}
          />
        </div>
      </div>
    )
  }
}
