import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

import getProducts from 'helpers/getProducts'

const CatalogFavorite = loadable(() =>
  import(
    /* webpackChunkName: "CatalogFavorite" */ 'pages/CatalogFavorite/CatalogFavorite'
  )
)

export default asyncConnect([
  {
    key: 'pageCatalogFavorite',
    promise: props =>
      getProducts({
        url: 'personal/favorite/',
        type: 'favorite'
      })(props)
  }
])(CatalogFavorite)
