import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'

import styles from './TextTruncate.scss'

export default class TextTruncate extends PureComponent {
  static propTypes = {
    className: pt.string,
    component: pt.oneOfType([pt.string, pt.func]),
    color: pt.oneOf(['alabaster', 'white', 'errorBg']),
    children: pt.oneOfType([pt.arrayOf(pt.node), pt.node, pt.string])
  }

  static defaultProps = {
    component: 'div'
  }

  render() {
    const { component: Comp, className, color, ...rest } = this.props
    return (
      <Comp
        {...rest}
        className={cx(
          styles.truncateText,
          styles[`truncateText_color_${color}`],
          className
        )}
      />
    )
  }
}
