import React, { FC, useCallback } from 'react'

import { map, range } from 'ramda'

import RadioButton from 'UI/RadioButton'
import Text from 'UI/Text'

import s from './ReviewButton.scss'

interface IRadioGroup {
  input: {
    value: number
    onChange: (value: number) => void
  }
  meta: {
    error?: string
  }
}

const FROM_1 = 1
const TO_6 = 6

const RadioGroup: FC<IRadioGroup> = ({ input: { value, onChange }, meta: { error } }) => {
  const handleChange = useCallback((i: number) => () => onChange(i), [onChange])
  return (
    <>
      <ul className={s.radio}>
        {
          map((i: number) => (
            <li key={i}>
              <RadioButton
                checked={i === value}
                label={`${i}`}
                onChange={handleChange(i)} />
            </li>
          ))(
            range(FROM_1, TO_6)
          )
        }
      </ul>
      {error && <Text color='red'>{error}</Text>}
    </>
  )
}

export default RadioGroup
