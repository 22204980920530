import loadable from '@loadable/component'
import { prop, pathOr } from 'ramda'
import { asyncConnect } from 'redux-connect'

import parse from 'utils/querystring'

const CabinetAnalytics = loadable(() =>
  import(/* webpackChunkName: "CabinetAnalytics" */ 'pages/Analytics/Analytics')
)

export default asyncConnect([
  {
    promise: ({ helpers: { updateData }, location, store }) => {
      const query = parse(prop('search', location))

      const state = store.getState()
      const isDesktop = pathOr(false, ['device', 'isDesktop'], state)

      updateData.set({ view: 'cabinetAnalytics', query, isDesktop })
      return updateData.update()
    }
  }
])(CabinetAnalytics)
