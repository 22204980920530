/* eslint-disable complexity */
import R, { includes, lensProp, prop, propOr, set } from 'ramda'
import { createAction } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { setCount, setNav, setSendStatus } from 'redux/modules/basket'
import { fetchSuccess as compareFetchSuccess } from 'redux/modules/compare'
import { fetchSuccess as favoriteFetchSuccess } from 'redux/modules/favorite'
import { setNotice } from 'redux/modules/notice'
import { setChecked, updateItemsOrder } from 'redux/modules/products'
import { setLoaded } from 'redux/modules/updateData'

import { getBasketParams } from './basketParams'

import {
  getItemsIdByGroupAndId,
  deleteProduct,
  setProduct,
  setCartAction,
  setCartActionStatus,
  setCarts
} from './index'

export const switchItemInGroup = createAction(
  'productList/SWITCH_ITEM_IN_GROUP'
)
export const switchItemInGroupSuccess = createAction(
  'productList/SWITCH_ITEM_IN_GROUP_SUCCESS'
)
export const switchItemInGroupFailed = createAction(
  'productList/SWITCH_ITEM_IN_GROUP_FAILED'
)

const switchInList =
  ({ clientApi }) =>
    ({
      action,
      id,
      type,
      items,
      params = {},
      settings = {},
      onSuccess = () => {}
    }) => {
      const contractorId = clientApi.getContractorId()
      const full = R.propOr('item', 'full', params)
      const groupAdd = !!R.prop('from', params) && R.equals(action, 'add')
      let catalog =
      R.prop('catalog', settings) || R.prop('catalog', params) || 'main'
      if (Array.isArray(catalog)) {
        catalog = R.head(catalog)
      }
      const method = 'post'

      const url = {
        waitList: `/v3/sale/basket/delay/${
          action === 'remove' ? 'delete' : action
        }/${full}/`,
        basket:
        action === 'correct'
          ? `/v3/sale/basket/${catalog}/correct/${params.error_code}/`
          : `/v3/sale/basket/${catalog}/${
            action === 'remove' ? 'delete' : action
          }/${full}/`,
        loyaltyBasket: `/v3/sale/basket/loyalty/${
          action === 'add' ? 'add' : 'delete'
        }/${full}/`,
        bookmarkBasket: '/v3/sale/basket/bookmark/',
        favorite: `/v3/personal/favorite/${action === 'add' ? 'add' : 'delete'}/`,
        compare: `/v3/personal/compare/${action === 'add' ? 'add' : 'delete'}/`,
        target: `/v3/loyalty/target/${id}/${
          action === 'remove' ? 'delete' : 'add'
        }`,
        move: `/v3/sale/basket/${R.prop('to', params)}/move/`,
        copy: `/v3/sale/basket/${R.prop('to', params)}/copy/`
      }
      const commonParams = {
        loyaltyBasket: {
          value: 'loyalty'
        },
        favorite: {
          products: R.keys(items)
        },
        compare: {
          products: R.keys(items)
        },
        bookmarkBasket: {
          value: id
        },
        move: {
          from: propOr('main', 'from', params),
          products: R.keys(items)
        },
        copy: {
          from: propOr('main', 'from', params),
          products: R.keys(items)
        }
      }
      const paramsByActive = {
        add: {
          waitList: {
            action: 'add',
            products: items
          },
          basket: {
            action: 'add',
            products: !groupAdd ? items : {}
          },
          loyaltyBasket: {
            action: 'add',
            products: items
          }
        },
        remove: {
          waitList: {
            action: 'delete',
            products: R.keys(items)
          },
          basket: {
            action: 'delete',
            products: R.keys(items)
          },
          loyaltyBasket: {
            products: R.keys(items)
          }
        },
        correct: {
          basket: {
            action: 'correct',
            products: R.keys(items)
          }
        }
      }

      if (action === 'replace') {
        const pickProductId = fn => R.compose(R.head, R.keys, R.filter(fn))(items)
        const productFromId = R.prop('originalProduct', params)
        const productToId = pickProductId(Boolean)
        url.basket = `/v3/sale/basket/${catalog}/replace/?products[${productFromId}][${productToId}]=${R.path(
          [productToId, 'QUANTITY'],
          items
        )}`
      }

      let moveType = false
      if (
        includes(action, ['move', 'copy']) &&
      (params.from || params.from === 0) &&
      (params.to || params.to === 0)
      ) {
        moveType = action
      }

      const data = {
        ...commonParams[moveType || type],
        ...R.pathOr({}, [action, type], paramsByActive),
        ...R.omit(['full'], params),
        contractor_id: contractorId
      }
      // удалим вспомогательные параметры из запроса
      if (moveType) {
        delete data.move
        delete data.to
        delete data.type
        if (data.from_value) {
        // todo хардкод для закладок - перемещаем с параметром from_value, а делаем запрос потом с value
        // по другому пока никак
          params.value = data.from_value
          delete params.from_value
        }
        params.catalog = data.from
      }

      delete data.full
      return clientApi[method](url[moveType || type], {
        params: data
      })
        .then(response =>
          switchItemInGroupSuccess({
            data: response,
            type,
            id,
            items,
            action,
            params,
            settings,
            sideEffect: onSuccess(response)
          })
        )
        .catch(response =>
          switchItemInGroupFailed({
            data: response,
            type,
            id,
            items,
            action
          })
        )
    }

const prepareParams = (state, { type, params = {} }) => {
  if (type === 'basket') {
    const { limit, sort, order } = getBasketParams(state)
    const offset = R.identical(NaN, R.prop('page', params) - 1)
      ? 0
      : (R.prop('page', params) - 1) * limit
    const values = R.prop('value', params)
      ? [R.prop('value', params)]
      : R.propOr([], 'values', params)
    delete params.value
    return {
      ...params,
      values,
      sort,
      order,
      limit,
      offset
    }
  }
  return params
}

const getCatalogNameSpace = ({ type, params = {}, settings = {} }) => {
  const catalog = settings.catalog || params.catalog || 'main'

  if (catalog === 'catalog') {
    return {
      catalog: 'main',
      catalogSelector: 0
    }
  }

  if (catalog === 'bookmark') {
    const bookmark = R.propOr(0, 'value', params)
    return {
      catalog,
      catalogSelector: `bookmark_${bookmark}`
    }
  }

  if (type === 'loyaltyBasket') {
    return {
      catalog: 'loyalty',
      catalogSelector: 0
    }
  }

  return {
    catalog,
    catalogSelector: catalog
  }
}

const handleSwitchItemInGroup = (
  state,
  { id, type, items = [], params = {}, settings = {}, onSuccess },
  { clientApi }
) => {
  let settingsPrepare = { ...settings }
  const effects = []
  const value =
    R.propOr(0, 'value', params) || R.pathOr(0, ['values', 0], params) || 0
  const filterAddToList = R.filter(
    item => R.gt(R.prop('QUANTITY', item), 0) || R.gt(item, 0)
  )
  const filterRemoveFromList = R.filter(
    item => R.lte(R.prop('QUANTITY', item), 0) || R.lte(item, 0)
  )
  const add = filterAddToList(items)
  const remove = filterRemoveFromList(items)
  const isReplaceAction = params.action === 'replace'
  const isCorrectAction = params.action === 'correct'
  const isMoveAction = params.action === 'move'
  const isCopyAction = params.action === 'copy'
  const paramsPrepare = prepareParams(state, { type, params })
  const groupAdd = !!R.prop('from', params) && R.propEq('action', 'add', params)

  if (
    type === 'basket' &&
    (!R.isEmpty(remove) || isMoveAction || isCopyAction)
  ) {
    const itemsPrepare = R.compose(
      R.values,
      R.pick(R.keys(items)),
      R.propOr({}, 'itemsById')
    )(state)

    effects.push(
      Effects.call(setCartAction, {
        isActive: false,
        cart: value,
        action: params.action || 'remove',
        type: prop('catalog', settings),
        items: itemsPrepare,
        from: prop('from_value', params)
      })
    )

    settingsPrepare = set(
      lensProp('setCartActionActive'),
      true,
      settingsPrepare
    )
  }

  if (isReplaceAction || isCorrectAction || isMoveAction || isCopyAction) {
    effects.push(
      Effects.promise(switchInList({ clientApi }), {
        id,
        type,
        items,
        action: params.action,
        params: paramsPrepare,
        settings: settingsPrepare,
        onSuccess
      })
    )
  }

  if (
    !R.isEmpty(add) &&
    !(isReplaceAction || isMoveAction || isCopyAction || isCorrectAction)
  ) {
    effects.push(
      Effects.promise(switchInList({ clientApi }), {
        id,
        type,
        items: add,
        action: params.action || 'add',
        params: paramsPrepare,
        settings: settingsPrepare,
        onSuccess
      })
    )
    const unselectItems = R.map(() => R.identity(false), add)
    effects.push(Effects.call(setChecked, unselectItems))
  }
  if (
    !R.isEmpty(remove) &&
    !(
      groupAdd ||
      isReplaceAction ||
      isMoveAction ||
      isCopyAction ||
      isCorrectAction
    )
  ) {
    effects.push(
      Effects.promise(switchInList({ clientApi }), {
        id,
        type,
        items: remove,
        action: 'remove',
        params: {
          ...paramsPrepare
          // full: 'full'
        },
        settings: settingsPrepare,
        onSuccess
      })
    )
    if (type === 'basket') {
      const unselectRemoveItems = R.map(() => R.identity(false), remove)
      effects.push(Effects.call(setChecked, unselectRemoveItems))
    }
  }

  effects.push(Effects.call(setLoaded, false))
  return loop(state, Effects.batch(effects))
}

const handleSwitchItemInGroupSuccess = (
  state,
  { id, type, items, data, action, params, settings = {}, sideEffect }
) => {
  const effects = []
  const isEqualAction = R.equals(action)
  const isReplaceAction = isEqualAction('replace')
  const isCorrectAction = isEqualAction('correct')
  const isRemoveAction = isEqualAction('remove')
  const isMoveAction = isEqualAction('move')
  const isCopyAction = isEqualAction('copy')
  const isAddAction = isEqualAction('add')
  const isCheckoutAction = isEqualAction('checkout')
  const isBasketType = R.equals(type, 'basket')
  const isFull = !!R.propOr(false, 'full', params)
  const isShowAction = R.propOr(true, 'isShowAction', settings)
  const isSetCartActionActive = R.propOr(false, 'setCartActionActive', settings)
  const { catalog, catalogSelector } = getCatalogNameSpace({
    type,
    params,
    settings
  })
  const response = R.path(['data', 'response'], data)
  const isSuccess = R.propOr(true, 'SUCCESS', response)
  const currentItems = getItemsIdByGroupAndId(type, catalogSelector, state)
  const value =
    R.propOr(0, 'value', params) || R.pathOr(0, ['values', 0], params) || 0
  const catalogPrepare = R.is(Array, id) ? R.head(id) : id
  const itemType =
    value > 0
      ? `bookmark_${value}`
      : catalogPrepare || R.propOr('main', 'catalog', settings)
  let newState = state
  const newItems = items
    ? R.uniq([...currentItems, ...Object.keys(items)]).filter(
      itemId =>
        !R.isEmpty(items[itemId]) || R.path([itemId, 'QUANTITY'], items) > 0
    )
    : currentItems

  const itemsGroupLens = R.lensPath(['itemsIdByGroup', type, catalogSelector])

  // исключение для "копирование и перемещение к другому КА"
  if ((isMoveAction || isCopyAction) && prop('to_contractor_id', params)) {
    return {
      ...state
    }
  }

  if (
    isBasketType &&
    (isReplaceAction ||
      isCorrectAction ||
      isMoveAction ||
      isCopyAction ||
      isRemoveAction ||
      isAddAction ||
      isFull)
  ) {
    const message = R.propOr({}, 'MESSAGE', response)
    const messageCheckout = R.pathOr({}, ['CHECKOUT', 'MESSAGE'], response)
    const messages = R.compose(R.values, R.pathOr([], ['MESSAGES']))(response)
    effects.push(
      Effects.call(setNotice, { name: 'body', page: value, value: messages })
    )
    if (!R.isEmpty(message)) {
      effects.push(
        Effects.call(setNotice, {
          name: 'alert',
          page: 'basket',
          value: [message]
        })
      )
    }
    effects.push(
      Effects.call(setNotice, {
        name: 'checkout',
        page: type,
        value: [messageCheckout]
      })
    )
  }
  if (
    isBasketType &&
    action === 'add' &&
    isShowAction &&
    prop('catalog', settings) !== 'delay'
  ) {
    effects.push(
      Effects.call(setCartAction, {
        isActive: true,
        cart: R.pathOr([0], ['values'], params) || value,
        action,
        type: prop('catalog', settings),
        items: R.propOr([], 'ITEMS', response)
      })
    )
  }
  if (isSetCartActionActive) {
    effects.push(Effects.call(setCartActionStatus, true))
  }

  newState = R.set(itemsGroupLens, newItems, newState)

  const setProp = _prop =>
    R.reduce((acc, itemId) => {
      const newProp = {
        [_prop]: action === 'remove' ? 'N' : 'Y'
      }

      const lens = R.lensPath(['itemsById', itemId])

      return R.over(lens, R.merge(R.__, newProp), acc)
    })
  // если неуспешное добавление -> notice
  if (isAddAction && isSuccess === false) {
    const responseNoticeProducts = R.pathOr(
      {},
      ['RESULT', 'PRODUCTS'],
      response
    )
    const messages = R.keys(responseNoticeProducts).map(key => ({
      id: key,
      message: responseNoticeProducts[key]
    }))
    effects.push(
      Effects.call(setNotice, {
        name: 'alert',
        page: 'catalog',
        value: messages
      })
    )
  }

  const nav = R.prop('NAV', response)
  if (!R.isEmpty(nav)) {
    effects.push(Effects.call(setNav, { nav }))
    const isBasketCounts = R.pathOr({}, ['NAV', 'BASKETS'], response)
    if (!R.isEmpty(isBasketCounts)) {
      effects.push(
        Effects.call(setCarts, { carts: R.pathOr({}, ['BASKETS'], nav) })
      )
      effects.push(Effects.call(setCount, { data: data.data, params }))
    }
  }

  const updateItems = R.prop('ITEMS', response)
  if (updateItems) {
    // todo т.к. бэк при добавлении\удалении\изменении товара присылает не все св-ва
    const itemsPrepare = R.reduce(
      (acc, cur) =>
        R.assoc(
          R.prop('ID', cur),
          R.mergeRight(
            R.omit(
              ['INFO'],
              R.pathOr({}, ['itemsById', R.prop('ID', cur)], newState)
            ),
            R.omit(['DETAIL_TEXT', 'PROPERTIES', 'TECH_DOC'], cur)
          ),
          acc
        ),
      {}
    )(updateItems)
    const itemsAll = R.mergeRight(R.prop('itemsById', newState), itemsPrepare)
    newState = R.set(R.lensPath(['itemsById']), itemsAll, newState)
  }

  if (type === 'favorite') {
    effects.push(Effects.call(favoriteFetchSuccess, data))
    newState = setProp('FAVORITE')(newState, R.keys(items))
  } else if (type === 'compare') {
    effects.push(
      Effects.call(compareFetchSuccess, { result: data, updateProducts: false })
    )
    newState = setProp('COMPARE')(newState, R.keys(items))
  }

  if (
    isBasketType &&
    (isRemoveAction || isCorrectAction || isReplaceAction || isCheckoutAction)
  ) {
    const itemsId = R.keys(items)
    if (isFull || isCorrectAction || isReplaceAction || isCheckoutAction) {
      const ids = R.pluck('ID', updateItems) || []
      effects.push(Effects.call(setProduct, { id: ids, type: itemType }))
    } else {
      effects.push(Effects.call(deleteProduct, { id: itemsId, type: itemType }))
    }
  }

  if (isBasketType) {
    const statusSendActive = R.pathOr(
      false,
      ['CHECKOUT', 'AVAILABILITY'],
      response
    )
    const itemsOrder = R.reduce(
      (acc, cur) => ({
        ...acc,
        [R.prop('ID', cur)]: R.filter(c => c)({
          activeStore: R.path(
            ['BASKETS', R.toUpper(catalog), value, 'STORE'],
            cur
          ),
          amount: Number(
            R.path(['BASKETS', R.toUpper(catalog), value, 'QUANTITY'], cur)
          ),
          isForPoints: Boolean(
            R.pathOr(
              false,
              ['BASKETS', R.toUpper(catalog), value, 'FOR_POINTS'],
              cur
            )
          )
        })
      }),
      {}
    )(updateItems)
    if (!(isMoveAction || isCopyAction)) {
      effects.push(Effects.call(updateItemsOrder, { items: itemsOrder }))
    }
    effects.push(Effects.call(setSendStatus, statusSendActive))
  }

  effects.push(Effects.call(setLoaded, true))

  if (sideEffect && !isMoveAction) {
    effects.push(sideEffect)
  }
  return loop(newState, Effects.batch(effects))
}

const handleSwitchItemInGroupFailed = state => state

export {
  handleSwitchItemInGroup,
  handleSwitchItemInGroupSuccess,
  handleSwitchItemInGroupFailed
}
