/* eslint-disable complexity */
const LENGTH_4: number = 4

const formatAmount = (num: number = 0, addZeros: boolean = false): string => {
  const str: string[] = num.toString().split('.')

  if (str[0].length >= LENGTH_4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1 ')
  }

  if (str[1]) {
    str[1] = `${str[1][0]}${str[1][1] ? str[1][1] : '0'}`
  } else if (addZeros) {
    str[1] = '00'
  }

  return str.join(',')
}

export default formatAmount
