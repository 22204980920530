/* eslint-disable complexity */
import {
  pathOr,
  prop,
  propOr,
  filter,
  compose,
  map,
  reduce,
  head,
  equals,
  keys,
  prepend,
  indexOf,
  length,
  last,
  dec,
  inc,
  findIndex
} from 'ramda'
import { createSelector } from 'reselect'

import formatAmount from 'utils/formatAmount'
import moment from 'utils/moment'
import noun from 'utils/noun'

const MILLISECONDS_IN_SECOND = 1000
const HUNDRED = 100

const getMonth = date =>
  moment(date * MILLISECONDS_IN_SECOND)
    .locale('ru')
    .format('MMMM')
    .replace('.', '')

export const isLoadedDiscounts = createSelector(
  state => state,
  state => propOr(false, 'isLoaded', state)
)

export const isLoadingDiscounts = createSelector(
  state => state,
  state => propOr(false, 'isLoading', state)
)

export const errorMessageSelector = createSelector(
  state => state,
  state => propOr('', 'errorMessage', state)
)

export const errorTargetSelector = createSelector(
  state => state,
  state => propOr('', 'errorMessageTarget', state)
)

export const isLoadedModalSelector = createSelector(
  state => state,
  state => pathOr(false, ['modalHtml', 'isLoaded'], state)
)

export const modalHtmlSelector = createSelector(
  state => state,
  state => pathOr({}, ['modalHtml', 'item'], state)
)

// относятся ли данные к текущему расчетному периоду
export const isCurrentPeriodSelector = createSelector(
  state => state,
  state => pathOr(false, ['item', 'PERIOD', 'IN_PERIOD'], state)
)

export const getDateFrom = createSelector(
  state => pathOr(0, ['item', 'PERIOD', 'DATE_FROM'], state),
  date => moment(date, 'X')
)

export const getDateTo = createSelector(
  state => pathOr(0, ['item', 'PERIOD', 'DATE_TO'], state),
  date => moment(date, 'X')
)

export const updatingDateSelector = createSelector(
  state => pathOr(0, ['item', 'DATE'], state),
  date => (date ? moment(date, 'X').format('DD.MM.YYYY') : '')
)

export const getPeriod = createSelector(
  getDateFrom,
  getDateTo,
  (dateFrom, dateTo) => {
    const from = dateFrom.format('YYYY-MM-DD')
    const to = dateTo.format('YYYY-MM-DD')
    const period = moment(to).diff(moment(from), 'month')
    return {
      dateFrom: dateFrom.format('DD.MM.YYYY'),
      dateTo: dateTo.format('DD.MM.YYYY'),
      period: `${period} ${noun(period, ['месяц', 'месяца', 'месяцев'])}`
    }
  }
)

export const estimateDaysSelector = createSelector(
  () => {
    const endOfMonth = moment().endOf('month')
    const estimateDays = endOfMonth.diff(moment(), 'day')
    return `${estimateDays} ${noun(estimateDays, ['день', 'дня', 'дней'])}`
  },
  days => days
)

// month - название месяца
// targetSum - целевая сумма
// currentSum - набранная сумма
// percent - процент выполнения месячного оборота
// isComplete - целевая сумма достигнута
// isEmpty - не заполненный месяц - если месяц не наступил
// isShowPercent - если цель за месяц равна 0 - сценарий, когда цель достигнута в предыдущих месяцах
// isProgress - текущий месяц

export const getMonths = createSelector(
  state => pathOr([], ['item', 'BY_PERIOD'], state),
  months =>
    map(month => {
      const date = propOr(0, 'DATE', month)
      const targetSum = propOr(0, 'TARGET_SUM', month)
      const currentSum = propOr(0, 'CURRENT_SUM', month)
      const percent = targetSum
        ? Math.floor((currentSum / targetSum) * HUNDRED)
        : 0
      const isComplete = percent >= HUNDRED
      const monthDate = moment(date, 'X').format('YYYY-MM-DD')
      const isPreviousMonth = moment().isAfter(monthDate, 'month')
      const isProgress = moment().isSame(monthDate, 'month')
      return {
        month: date ? getMonth(date) : '',
        targetSum,
        currentSum,
        needSum: isComplete ? 0 : targetSum - currentSum,
        percent,
        isComplete,
        isShowPercent: !!targetSum,
        isEmpty: !isProgress && !isPreviousMonth,
        isProgress,
        isPreviousMonth
      }
    })(months)
)

export const monthsDataSelector = createSelector(getMonths, months =>
  map(month => ({
    ...month,
    targetSum: formatAmount(propOr(0, 'targetSum', month)),
    currentSum: formatAmount(propOr(0, 'currentSum', month)),
    needSum: formatAmount(propOr(0, 'needSum', month))
  }))(months)
)

export const currentMonthSelector = createSelector(
  months => filter(month => propOr(false, 'isProgress', month), months),
  month => head(month)
)

export const getCurrentSum = createSelector(getMonths, months =>
  compose(
    propOr(0, 'sum'),
    reduce(
      (acc, month) => ({
        ...acc,
        sum: propOr(0, 'sum', acc) + propOr(0, 'currentSum', month)
      }),
      {}
    )
  )(months)
)

const prepareStep = step => ({
  id: prop('ID', step),
  name: prop('NAME', step),
  current: propOr(false, 'CURRENT', step),
  target: propOr(false, 'TARGET', step),
  sum: parseInt(prop('SUM', step), 10)
})

const targetStepSelector = createSelector(
  state => state,
  state => {
    const steps = pathOr([], ['item', 'STEPS'], state)
    const target = compose(
      head,
      filter(step => propOr(false, 'TARGET', step))
    )(steps)
    const maxStepIndex = compose(dec, length)(steps)
    const targetStepIndex = indexOf(target, steps)
    return {
      ...target,
      isMaxStep: equals(maxStepIndex, targetStepIndex)
    }
  }
)

export const getCurrentStep = createSelector(
  state =>
    compose(
      head,
      filter(step => propOr(false, 'CURRENT', step)),
      pathOr([], ['item', 'STEPS'])
    )(state),
  step => prepareStep(step)
)

export const getProgressStep = createSelector(
  targetStepSelector,
  getCurrentSum,
  (step, currentSum) => {
    const progressStep = prepareStep(step)
    const targetSum = propOr(0, 'sum', progressStep)
    const percent = targetSum
      ? Math.floor((currentSum / targetSum) * HUNDRED)
      : 0
    return {
      ...progressStep,
      percent,
      currentSum,
      isCompleted: percent >= HUNDRED,
      needSum: targetSum ? targetSum - currentSum : 0,
      isMaxStep: propOr(false, 'isMaxStep', step)
    }
  }
)

export const getSteps = createSelector(
  state => pathOr([], ['item', 'STEPS'], state),
  steps => map(prepareStep)(steps)
)

export const stepsSelector = createSelector(
  getSteps,
  getCurrentSum,
  (steps, sum) =>
    map(step => {
      const shortName = propOr('', 'name', step)
      const stepSum = propOr(0, 'sum', step)
      return {
        ...step,
        shortName: propOr('', 'name', step),
        name: prop('sum', step) ? `Уровень ${shortName}` : shortName,
        sum: formatAmount(stepSum),
        completed: sum >= stepSum
      }
    })(steps)
)

export const basketStepsSelector = createSelector(
  getSteps,
  getProgressStep,
  (steps, targetStep) => {
    const isTargetStepCompleted = propOr(false, 'isCompleted', targetStep)
    const isMaxStepCompleted =
      isTargetStepCompleted && propOr(false, 'isMaxStep', targetStep)
    const nextStepIndex = isMaxStepCompleted
      ? ''
      : compose(
        inc,
        findIndex(step => propOr(false, 'target', step))
      )(steps)
    return {
      completedStep: isTargetStepCompleted
        ? propOr('', 'name', targetStep)
        : '',
      isTargetStepCompleted,
      isMaxStepCompleted,
      nextStepId: nextStepIndex ? pathOr('', [nextStepIndex, 'id'], steps) : '',
      nextStepName: nextStepIndex
        ? pathOr('', [nextStepIndex, 'name'], steps)
        : ''
    }
  }
)

// селектор для графика
// ticks - значения левой оси Y [0, 1, 2, ..., n], n - кол-во этапов (STEPS)
// ticksLabels - значение каждого шага (оборот для каждого уровня скидки)
// ticksNames - текст для правой оси Y - название и статус этапа, соответствующего шагу
// data - данные для построения графиков, строится на основе данных по месяцам (BY_PERIOD):
// Прогнозируемый оборот - график на основе целевой суммы по месяцам
// Ваш оборот - оборот клиента по прошедшим и текущему месяцам
// currentPointPosition - координата точки текущего оборота

const getYPointValue = createSelector(
  // положение точки на линейной шкале
  state => state,
  ({ sum, scale }) => {
    // topTickLabel - значение верхней границы диапазона, в котором находится точка
    // bottomTickLabel - значение нижней границы диапазона, в котором находится точка
    // currentRange - сумма, относительно которой необходимо определить положение точки sumInRange
    const topTickLabel = compose(
      head,
      filter(item => sum < item)
    )(scale)
    const maxScaleIndex = compose(dec, length)(scale)
    const topIndexFromScale = indexOf(topTickLabel, scale)
    // если индекс -1, то набранная клиентом сумма выше максимального оборота
    const topTickIndex =
      topIndexFromScale === -1 ? inc(maxScaleIndex) : topIndexFromScale
    const bottomTickIndex = dec(topTickIndex)
    const bottomTickLabel = propOr(0, bottomTickIndex, scale)
    const currentRange = topTickLabel - bottomTickLabel
    const sumInRange = sum - bottomTickLabel
    return bottomTickIndex + (sumInRange / currentRange || 0)
  }
)

const getYScaleInfo = createSelector(
  state => state,
  ({ steps, progressStep, sum }) => ({
    ticksNames: reduce((acc, step) => {
      const stepSum = propOr(0, 'sum', step)
      const name = propOr('', 'name', step)
      const text = stepSum ? `Уровень ${name}` : name
      const note = `${
        propOr(false, 'current', step) && !propOr(false, 'target', step)
          ? '(Текущий)'
          : ''
      }${propOr(false, 'target', step) ? '(Ваша цель)' : ''}`
      const isCompleted =
        equals(name, propOr('', 'name', progressStep)) &&
        propOr(false, 'isCompleted', progressStep)
      const isTarget = propOr(false, 'target', step)
      return [
        ...acc,
        {
          name,
          text,
          note: isTarget && isCompleted ? '(Достигнут)' : note,
          isCurrent: propOr(false, 'current', step),
          isTarget: propOr(false, 'target', step),
          isCompleted: isTarget && isCompleted, // цель достигнута
          isOver: sum >= stepSum // уровень пройден
        }
      ]
    }, [])(steps),
    tickLabels: reduce(
      (acc, step) => [...acc, propOr(0, 'sum', step)],
      []
    )(steps)
  })
)
export const diagramDataSelector = createSelector(
  getMonths,
  getSteps,
  getProgressStep,
  getCurrentSum,
  (months, steps, progressStep, sum) => {
    const { ticksNames, tickLabels } = getYScaleInfo({
      steps,
      progressStep,
      sum
    })

    const targetSum = propOr(0, 'sum', progressStep)
    const lastMonth = last(months)

    // fullCurrentSum - для построения графика фактического оборота по месяцам, к обороту за каждый месяц необходимо
    // прибавлять оборот за все предыдущие месяцы

    // targetLine - Линия прогноза
    // значение = сумма фактического оборота за предыдущие месяцы + цель на текущий месяц
    // если месяц не наступил, то не учитываем фактический оборот, суммируем цели с предыдущими значениями
    // если сумма больше целевой, или месяц является последним, подставляется значение целевой суммы
    const progressByMonths = reduce((acc, month) => {
      const prevMonthCurrentSum = compose(
        propOr(0, 'fullCurrentSum'),
        last
      )(acc)
      const prevMonthTargetLine = compose(propOr(0, 'targetLine'), last)(acc)

      let targetValue = prevMonthCurrentSum + propOr(0, 'targetSum', month)
      if (!month.isPreviousMonth && !month.isProgress) {
        // месяц не наступил
        targetValue = prevMonthTargetLine + propOr(0, 'targetSum', month)
      }

      if (
        targetValue > targetSum || // сумма больше целевой
        month.month === lastMonth.month
      ) {
        // последний месяц
        targetValue = targetSum
      }
      return [
        ...acc,
        {
          ...month,
          fullCurrentSum: propOr(0, 'currentSum', month) + prevMonthCurrentSum,
          targetLine: targetValue
        }
      ]
    }, [])(months)
    const data = compose(
      prepend({
        name: '',
        'Прогнозируемый оборот': 0,
        'Ваш оборот': 0
      }),
      map(month => {
        const currentProgress =
          propOr(false, 'isProgress', month) ||
          propOr(false, 'isPreviousMonth', month)
            ? {
              'Ваш оборот': getYPointValue({
                sum: propOr(0, 'fullCurrentSum', month),
                scale: tickLabels
              })
            }
            : {}
        return {
          name: propOr('', 'month', month),
          'Прогнозируемый оборот': getYPointValue({
            sum: propOr(0, 'targetLine', month),
            scale: tickLabels
          }),
          ...currentProgress
        }
      })
    )(progressByMonths)
    const currentMonth = currentMonthSelector(months)
    return {
      data,
      ticksNames,
      tickLabels,
      currentPointPosition: compose(
        pathOr(0, [0, 'Ваш оборот']),
        filter(
          pointData =>
            propOr('', 'name', pointData) === propOr('', 'month', currentMonth)
        )
      )(data),
      currentPointIndex: inc(indexOf(currentMonth, months)), // индекс +1, т.к. в data добавляем нулевые значения
      ticks: compose(
        map(key => parseInt(key, 10)),
        keys
      )(tickLabels),
      currentMonth: compose(
        head,
        filter(month => propOr(false, 'isProgress', month))
      )(progressByMonths),
      lastMonth
    }
  }
)
