import React from 'react'

import { useSelector } from 'react-redux'

import { accessNameSelector, currentContractorNameSelector, personalNameSelector } from 'redux/modules/personal'
import Text from 'UI/Text'
import Title from 'UI/Title'

import s from './ContractorInfo.scss'

const ContractorInfo = () => {
  const contractorName = useSelector(({ personal }) => currentContractorNameSelector(personal))
  const personalName = useSelector(({ personal }) => personalNameSelector(personal))
  const access = useSelector(({ personal }) => accessNameSelector(personal))

  return (
    <div className={s.contrator}>
      <Title>{contractorName}</Title>
      <Text>{personalName}, {access}</Text>
    </div>
  )
}

export default ContractorInfo
