import React from 'react'

import { propOr } from 'ramda'
import { useSelector } from 'react-redux'
import { asyncConnect } from 'redux-connect'

import Link from 'components/Link/Link'
import { sectionsSelector } from 'redux/modules/loyalty'
import Text from 'UI/Text'

import s from './TabBar.scss'

const LoyaltyCatalog = () => {
  const sections = useSelector(({ loyalty }) => sectionsSelector(loyalty))
  return (
    <ul className={s.loyaltyCatalog}>
      {sections.map((section, index) => {
        const id = propOr(index, 'ID', section)
        const count = propOr(0, 'COUNT', section)
        const name = propOr('', 'NAME', section)
        return (
          <li key={id}
            className={s.loyaltyCatalog__item}>
            <Link to={id ? `/loyalty/catalog/products/${id}` : '/loyalty/catalog'}>
              <Text>{`${name} (${count})`}</Text>
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default asyncConnect([
  {
    key: 'pageLoyalty',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'loyalty', loyaltyBasket: true })
      return updateData.update()
    }
  }
])(LoyaltyCatalog)
