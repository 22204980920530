import { pathOr } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { showModal, hideModal } from 'redux/modules/modal'

const initialState = {
  payments: [],
  notifications: [],
  bannerBookmarks: [],
  bannerBakset: {},
  isLoading: false,
  isLoaded: false
}

export {
  paymentsSelector,
  sumDelaySelector,
  dateUpdateSelector,
  messageSelector,
  basketSelector,
  bookmarksSelector,
  contractorSelector,
  typesSelector,
  billTypesSelector,
  periodTypesSelector,
  periodsSelector,
  datePaySelector,
  isLoadingSelector,
  isLoadedSelector,
  getPaymentsAgg,
  getFlatPaymentsAgg
} from './selector'

export const fetch = createAction('payments/FETCH')
export const fetchSuccess = createAction('payments/FETCH_SUCCESS')
export const fetchFailure = createAction('payments/FETCH_FAILURE')

const request =
  ({ clientApi }) =>
    params => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .get('/v3/contractor/payments/plan/', {
          params: {
            contractor_id: contractorId,
            ...params
          }
        })
        .then(fetchSuccess)
        .catch(fetchFailure)
    }

const handleFetch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(request({ clientApi }), payload)
  )

const handleFetchSuccess = (state, payload) =>
  loop(
    {
      ...state,
      dateUpdate: pathOr('', ['data', 'response', 'DATE_UPDATE'], payload),
      payments: pathOr([], ['data', 'response', 'ITEMS'], payload),
      message: pathOr('', ['data', 'response', 'MESSAGE'], payload),
      entities: pathOr({}, ['data', 'response', 'ENTITIES'], payload),
      condition: pathOr({}, ['data', 'response', 'CONDITION'], payload),
      isLoaded: true,
      isLoading: false
    },
    Effects.call(hideModal)
  )

const handleFetchFailure = state =>
  loop(
    {
      ...state,
      isLoaded: false,
      isLoading: false
    },
    Effects.call(showModal, 'refresh_failure')
  )

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
}, initialState)

export default reducer
