import React,{ useEffect } from 'react'

import { string, number, oneOfType } from 'prop-types'
import TagManager from 'react-gtm-module'
import { useSelector } from 'react-redux'

import config from 'config'
import { gtmIdSelector, userIdSelector } from 'redux/modules/personal'

const Gtm = ({ gtmId, userId}) => {
  useEffect(() => {
    const dataLayer = userId ? { userId } : {}
    TagManager.initialize({
      gtmId,
      dataLayer
    })
  }, [gtmId, userId])

  return null
}

Gtm.propTypes = {
  gtmId: string,
  userId: oneOfType([string, number])
}

const GtmWrapper = () => {
  const gtmId = useSelector(({ personal }) => gtmIdSelector(personal))
  const userId = useSelector(({ personal }) => userIdSelector(personal))
  if (config.metrics.isActive && gtmId) {
    return (
      <Gtm
        gtmId={gtmId}
        userId={userId}
      />
    )
  }

  return null
}

export default GtmWrapper
