/* eslint-disable complexity */
import { push } from 'connected-react-router'
import { pick, whereEq, isEmpty, pathOr, propOr, prop, equals } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { updateItem } from 'redux/modules/invoice'
import { showModal } from 'redux/modules/modal'

const MILLISECONDS_IN_SECOND = 1000
const SECONDS_IN_MINUTE = 60
const MINUTES_IN_HOUR = 60
const HOURS_IN_DAY = 24
const DAY_IN_MONTH = 31

const initialState = {
  isLoading: false,
  isLoaded: false,
  order: {},
  products: [],
  productsTotalCount: 0,
  sortOptions: [],
  params: {
    isCookieLoaded: false,
    offset: 0,
    limit: 48
  }
}

export {
  getIsLoaded,
  getIsLoading,
  getOrderData,
  getOrderId,
  selectOrderInfo,
  selectBreadcrumbs,
  selectParams,
  selectSortOptions,
  getOrderItems,
  isPointsSelector,
  errorSelector
} from './selector'

const cookieOpts = {
  path: '/',
  expires: new Date(
    Date.now() +
      DAY_IN_MONTH *
        HOURS_IN_DAY *
        MINUTES_IN_HOUR *
        SECONDS_IN_MINUTE *
        MILLISECONDS_IN_SECOND
  )
}
const cookieParamsKeys = ['limit', 'order', 'sort']
const updateCookies = (cookie, params) => {
  const cookieParams = pick(cookieParamsKeys, params)
  cookie.save('params_orderSummary', cookieParams, cookieOpts)
}
const ERROR_TEXT = 'Недопустимая операция'

export const fetch = createAction('orderSummary/GET')
export const fetchSuccess = createAction('orderSummary/GET_SUCCESS')
export const fetchFailure = createAction('orderSummary/GET_FAILURE')
export const orderAction = createAction('orderSummary/ORDER_ACTION')
export const orderActionSuccess = createAction('orderSummary/GET_SUCCESS')
export const orderActionFailure = createAction('orderSummary/GET_FAILURE')

export const setParams = createAction('orderSummary/SET_PARAMS')

export const request =
  ({ clientApi }) =>
    ({ orderId, catalog = 'main', params }) =>
      clientApi
        .get(`/v3/sale/bills/${catalog}/${orderId}/`, {
          params: {
            contractor_id: clientApi.getContractorId(),
            ...params
          }
        })
        .then(fetchSuccess)
        .catch(fetchFailure)

const requestAction =
  ({ clientApi }) =>
    ({ orderId, catalog = 'main', actionType, value = 0 }) => {
      const contractorId = clientApi.getContractorId()
      const params = equals(actionType, 'delete') ? { deactivate: false } : {}
      return clientApi
        .post(`/v3/sale/bills/${catalog}/${orderId}/action/${actionType}/`, {
          params: {
            contractor_id: contractorId,
            value,
            ...params
          }
        })
        .then(data =>
          orderActionSuccess({ data, orderId, catalog, actionType, value })
        )
        .catch(orderActionFailure)
    }

const requestRepeat =
  ({ clientApi }) =>
    ({ orderId, catalog = 'main', actionType, value = 0 }) => {
      const contractorId = clientApi.getContractorId()
      return clientApi
        .post(`/v3/sale/basket/${catalog}/repeat/`, {
          params: {
            check: 'N',
            order_id: orderId,
            contractor_id: contractorId,
            value
          }
        })
        .then(data =>
          orderActionSuccess({ data, orderId, catalog, actionType, value })
        )
        .catch(orderActionFailure)
    }

const handleSetParams = (state, payload, { cookie }) => {
  if (whereEq(payload)(state.params)) {
    return state
  }

  const params = { ...state.params, ...payload }
  if (
    state.params.isCookieLoaded &&
    !isEmpty(pick(cookieParamsKeys, payload))
  ) {
    updateCookies(cookie, params)
  }
  return {
    ...state,
    params
  }
}

const handleFetch = (state, payload, { clientApi, cookie }) => {
  const effects = []
  const paramsFromCookie = cookie.load('params_orderSummary')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isCookieLoaded, ...currentParams } = propOr({}, 'params', state)
  const page = propOr(1, 'page', payload)
  const params = {
    ...currentParams,
    ...paramsFromCookie
  }
  effects.push(
    Effects.call(setParams, { ...paramsFromCookie, isCookieLoaded: true })
  )
  const reqParams = {
    ...params,
    page,
    offset: (page - 1) * params.limit
  }
  effects.push(
    Effects.promise(request({ clientApi }), {
      ...payload,
      params: reqParams
    })
  )
  return loop(
    {
      ...state,
      params: reqParams,
      isLoading: true,
      isLoaded: false
    },
    Effects.batch(effects)
  )
}

const handleFetchSuccess = (state, payload) => {
  const response = pathOr({}, ['data', 'response'], payload)
  const order = propOr({}, 'BILL', response)
  const nav = propOr({}, 'NAV', response)

  return {
    ...state,
    order: response,
    states: propOr({}, 'STATE', response),
    products: propOr([], 'ITEMS', response),
    isPoints: pathOr('', ['PAYMENTS', 'CODE'], order) === 'LOYALTY',
    productsTotalCount: propOr(0, 'CNT', nav),
    sortOptions: propOr([], 'SORT', nav),
    isLoading: false,
    isLoaded: true
  }
}

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errorMessage: getErrorMessage(payload)
})

const handleOrderAction = (state, payload, { clientApi }) => {
  const actionType = prop('actionType', payload)
  const effects = []
  if (equals(actionType, 'repeat')) {
    effects.push(Effects.promise(requestRepeat({ clientApi }), payload))
  } else {
    effects.push(Effects.promise(requestAction({ clientApi }), payload))
  }
  return loop(
    {
      ...state
    },
    Effects.batch(effects)
  )
}

const handleOrderActionSuccess = (
  state,
  { data, orderId, catalog, actionType, value = 0 }
) => {
  const effects = []
  const response = pathOr({}, ['data', 'response'], data)
  const isSuccess = propOr(false, 'SUCCESS', response)
  if (isSuccess) {
    const statusCode = pathOr('', ['BILL', 'STATUS'], response)
    const statusesList = pathOr({}, ['ENTITIES', 'STATUS'], response)
    const itemActions = pathOr([], ['BILL', 'ACTIONS'], response)
    if (!equals(actionType, 'repeat')) {
      effects.push(
        Effects.call(updateItem, {
          orderId,
          statusCode,
          itemActions,
          statusesList
        })
      )
      effects.push(Effects.call(fetch, { orderId, catalog }))
    } else {
      effects.push(Effects.call(push, `/basket/${catalog}/${value}`))
    }
  }
  return loop(
    {
      ...state,
      error: isSuccess ? '' : pathOr(ERROR_TEXT, ['MESSAGE', 'TEXT'], response)
    },
    isSuccess ? Effects.batch(effects) : Effects.call(showModal, 'invoiceError')
  )
}

const handleOrderActionFailure = (state, payload) =>
  loop(
    {
      ...state,
      error: pathOr(
        ERROR_TEXT,
        ['data', 'response', 'MESSAGE', 'TEXT'],
        payload
      )
    },
    Effects.call(showModal, 'invoiceError')
  )

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(orderAction, handleOrderAction)
  on(orderActionSuccess, handleOrderActionSuccess)
  on(orderActionFailure, handleOrderActionFailure)

  on(setParams, handleSetParams)
}, initialState)

export default reducer
