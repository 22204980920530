/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, oneOfType, element, bool, node, number } from 'prop-types'
import { prop, pathOr, indexOf, is } from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import Icon from 'components/Icon'

import styles from './HeaderMenuItem.scss'
import HeaderMenuLink from './HeaderMenuLink'

const pathnameSelector = createSelector(
  state => state,
  state => pathOr('/', ['location', 'pathname'], state)
)

@connect(({ router }, ownProps) => {
  const linkTo = prop('linkTo', ownProps)
  const pathname = pathnameSelector(router)
  return {
    isCurrent: linkTo !== '/' && indexOf(linkTo, pathname) === 0
  }
})
class HeaderMenuItem extends PureComponent {
  static propTypes = {
    className: string,
    iconPath: string,
    text: oneOfType([string, element]),
    renderPopup: bool,
    renderWhenUserIsNotLogged: bool,
    dropDownClassName: string,
    children: node,
    linkTo: string,
    iconWrapper: string,
    statusCode: string,
    disabled: bool,
    pureLink: bool,
    emptyTextLine: bool,
    target: string,
    isCurrent: bool,
    isLoyalty: bool,
    isMenuColor: bool,
    counter: number
  }

  static defaultProps = {
    className: '',
    counter: 0,
    renderPopup: true,
    renderWhenUserIsNotLogged: true,
    linkTo: null,
    target: null,
    disabled: false,
    isMenuColor: false,
    isLoyalty: false
  }

  render() {
    const {
      className,
      iconPath,
      text,
      dropDownClassName,
      renderWhenUserIsNotLogged,
      renderPopup,
      linkTo,
      iconWrapper,
      statusCode,
      disabled,
      pureLink,
      target,
      emptyTextLine,
      counter,
      isCurrent,
      children,
      isMenuColor,
      isLoyalty
    } = this.props

    if (!renderWhenUserIsNotLogged) {
      return null
    }

    const headerTitle = emptyTextLine ? '' : text
    return (
      <div
        className={cx(styles.headerMenuItem, {
          [className]: true,
          [styles.headerMenuColor]: isMenuColor,
          [styles.headerMenuItem_active]: isCurrent
        })}
      >
        <HeaderMenuLink
          linkTo={linkTo}
          disabled={disabled}
          pureLink={pureLink}
          target={target}
          className={styles.headerMenuBlock}
          itemClassName={styles.headerMenuBlockItem}
        >
          <>
            {iconPath && (
              <div
                className={cx(styles.iconWrapper, {
                  [styles[`iconWrapper_${iconWrapper}`]]: iconWrapper,
                  [styles[`iconWrapper_${statusCode}`]]: statusCode,
                  [styles.iconWrapper_disabled]: disabled,
                  [styles.iconWrapperLoyalty]: isLoyalty
                })}
              >
                {!!counter && (
                  <div className={styles.menuItemsCount}>{counter}</div>
                )}
                {iconPath && (
                  <Icon
                    className={cx(styles.headerIcon, {
                      [styles.headerIconLoyalty]: isLoyalty
                    })}
                    icon={iconPath}
                  />
                )}
              </div>
            )}

            <div
              className={cx(styles.headerLink, {
                [styles.headerLinkDisabled]: disabled,
                [styles.headerLinkEmpty]: emptyTextLine,
                [styles.headerLinkLoyalty]: isLoyalty
              })}
            >
              {is(String, headerTitle) ? (
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: headerTitle }}
                />
              ) : (
                headerTitle
              )}
            </div>
          </>
        </HeaderMenuLink>

        {children && renderPopup && (
          <div
            className={cx(styles.headerDropDown, {
              [dropDownClassName]: true
            })}
          >
            {this.props.children}
          </div>
        )}
      </div>
    )
  }
}

export default HeaderMenuItem
