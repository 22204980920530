import React, { FC, ReactNode } from 'react'

import cx from 'classnames'

import s from './Title.scss'

const Level = {
  Level_1: 1,
  Level_2: 2,
  Level_3: 3,
  Level_4: 4,
  Level_5: 5,
  Level_6: 6
} as const

const Size = {
  Size_13: 13,
  Size_16: 16,
  Size_18: 18
} as const

type SizeEnum = typeof Size[keyof typeof Size]
type LevelEnum = typeof Level[keyof typeof Level]

interface ITitleProps {
  level?: LevelEnum
  size?: SizeEnum
  spacing?: 'none' | 'big'
  children?: ReactNode
}

const Title: FC<ITitleProps> = ({
  level = Level.Level_1,
  size = Size.Size_18,
  spacing,
  children
}: ITitleProps) => {
  const Tag = `h${level}` as const

  return (
    <Tag
      className={cx(s.title, {
        [s[`${Tag}__spacing`]]: level,
        [s[`title__spacing_${spacing}`]]: spacing,
        [s[`title__size_${size}`]]: size
      })}
    >
      {children}
    </Tag>
  )
}

export default Title
