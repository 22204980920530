import React from 'react'

import { pathOr } from 'ramda'
import { useSelector } from 'react-redux'

import HeaderMenuItem from 'components/Header/HeaderMenuItem'
import config from 'config'
import { getSiteUrl } from 'redux/modules/personal'
import { getTheme } from 'redux/modules/theme'

const HeaderCompany = () => {
  const landingUrl = useSelector(({ personal }) => getSiteUrl(personal))
  const { theme } = useSelector(state => getTheme(state))

  const handleGetThemeIcon = icon => pathOr('', ['themes', theme, 'icons', icon], config)

  return (
    <HeaderMenuItem
      linkTo={landingUrl}
      iconWrapper='whyWe'
      text='О компании'
      iconPath={handleGetThemeIcon('whyMe')}
      pureLink
    />
  )
}

export default HeaderCompany
