import {
  path,
  prop,
  propOr,
  compose,
  length,
  values,
  pathOr,
  toUpper,
  lt,
  filter,
  gt
} from 'ramda'
import { createSelector } from 'reselect'

import renameKeys from 'helpers/objectModify'
import { snakeToCamel } from 'helpers/toCamelCase'

const DEFAULT_BASKET = { count: 0, sum: 0, vol: 0, wg: 0 }

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading
export const getBasketByType = (type, state) => path(['types', type], state)
export const getSendActive = state => state.sendActive

export const basketSelector = createSelector(
  ({ basket }) => path(['types', 'BASKETS'], basket),
  ({ basketType = 'main' }) => toUpper(basketType),
  ({ basketId = 0 }) => Number(basketId),
  (type, basketType, basketId) =>
    compose(
      renameKeys(snakeToCamel),
      pathOr(DEFAULT_BASKET, [basketType, 'VALUES', basketId])
    )(type)
)

export const choiseDeliveryDateSelector = createSelector(
  basketCurrent => basketCurrent,
  state => pathOr(false, ['possible_delivery_date'], state)
)

export const isRefetchSelector = createSelector(
  state => state,
  state => propOr(false, 'isRefetch', state)
)

export const bookmarkAccessSelector = createSelector(
  state => state,
  state =>
    compose(
      lt(0),
      length,
      filter(item => gt(prop('CNT', item), 0) || gt(prop('SUM', item), 0)),
      values,
      pathOr({}, ['types', 'BASKETS', 'BOOKMARK', 'VALUES'])
    )(state)
)
