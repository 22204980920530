import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Faq = loadable(() =>
  import(/* webpackChunkName: "Faq" */ 'pages/Faq/Faq')
)

export default asyncConnect([
  {
    key: 'pageFaq',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'faq' })
      return updateData.update()
    }
  }
])(Faq)
