import {
  prop,
  cond,
  always,
  equals,
  propOr,
  T,
  intersection,
  length,
  filter,
  or,
  compose,
  keys
} from 'ramda'
import { createSelector } from 'reselect'

export const getScrollPosition = state => state.scrollPosition
export const getHeadersHeight = state => state.headersHeight
export const getBottomPanelVisible = state => state.bottomPanelVisible
export const catalogTypeSelector = createSelector(
  state => prop('mode', state),
  mode =>
    cond([
      [equals('bookmark'), always('bookmark')],
      [equals('loyalty'), always('loyalty')],
      [T, always('main')]
    ])(mode)
)

export const bookmarkSelector = createSelector(
  state => prop('mode', state),
  state => prop('bookmark', state),
  (mode, bookmarkId) =>
    mode === 'bookmark' && bookmarkId ? Number(bookmarkId) : undefined
)

const menuItemsSelector = createSelector(
  state => state,
  state => prop('menuItems', state)
)

const cabinetMenuItemsSelector = createSelector(
  state => state,
  state => prop('cabinetMenuItems', state)
)

export const menuSelector = createSelector(
  menuItemsSelector,
  (_, access) => access,
  (items, access) =>
    filter(
      item =>
        or(
          equals(
            length(intersection(access, propOr([], 'access', item))),
            length(propOr([], 'access', item))
          ),
          equals(length(propOr([], 'access', item)), 0)
        ),
      items
    )
)

export const cabinetMenuSelector = createSelector(
  cabinetMenuItemsSelector,
  (_, access) => access,
  (items, access) =>
    filter(
      item =>
        or(
          equals(
            length(intersection(access, propOr([], 'access', item))),
            length(propOr([], 'access', item))
          ),
          equals(length(propOr([], 'access', item)), 0)
        ),
      items
    )
)

export const modeSelector = createSelector(
  settings => settings,
  settings => prop('mode', settings)
)

export const isBookmarkSelector = createSelector(modeSelector, mode =>
  equals('bookmark', mode)
)

export const isLoyaltySelector = createSelector(modeSelector, mode =>
  equals('loyalty', mode)
)

export const isDisabledBodyScrollSelector = createSelector(
  settings => settings,
  settings => prop('isDisabledBodyScroll', settings)
)

export const bottomPanelVisibleSelector = createSelector(
  settings => settings,
  settings => prop('bottomPanelVisible', settings)
)

export const notificationsSelector = createSelector(
  settings => settings,
  settings => propOr({}, 'notifications', settings)
)

export const localStorageSelector = createSelector(
  settings => settings,
  settings => propOr({}, 'localStorage', settings)
)

export const countClickSelector = createSelector(localStorageSelector, ls =>
  propOr(0, 'countClick', ls)
)

export const pageLoadedSelector = createSelector(
  state => state,
  state => propOr(false, 'loaded', state)
)

const locationSelector = createSelector(
  state => state,
  state => prop('location', state)
)

export const pathnameSelector = createSelector(
  locationSelector,
  location => propOr('/', 'pathname', location)
)

export const pathnameSearchSelector = createSelector(
  locationSelector,
  location => prop('search', location)
)

export const tabActiveSelector = createSelector(
  state => state,
  state => propOr('', 'tabActive', state)
)

const tabBarListSelector = createSelector(
  state => state,
  state => prop('tabBarList', state)
)

const tabBarAccessSelector = createSelector(
  (_, access) => access,
  access => compose(
    keys,
    filter(item => item)
  )(access)
)

export const tabBarSelector = createSelector(
  tabBarListSelector,
  tabBarAccessSelector,
  (items, access) =>
  //  сравнимаем длину пересечений, если access пункта меню === access - то отображаем в таббаре пункт меню
    filter(item => equals(
      length(intersection(prop('access', item), access)),
      length(prop('access', item))
    ), items)
)
