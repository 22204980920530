import React, {
  useRef,
  useMemo,
  useCallback,
  useEffect
} from 'react'

import {
  bool,
  number,
  array,
  object,
  func
} from 'prop-types'
import { pathOr, path, propOr } from 'ramda'

import SecondLevelHeader from 'components/MenuCatalogMobile/SecondLevelHeader'
import MenuMainColumn from 'components/MenuMainColumn'
import { MenuSecondLevel } from 'containers/MenuCatalogMobile'

import s from './MenuCatalogMobile.scss'

const SECOND_LEVEL_HEADER = 78

const MenuCatalogMobile = ({
  isTablet,
  isMobile,
  catalog,
  bookmarkData,
  isBookmark,
  bookmark,
  selectedItemId,
  catalogHeight,
  isAuth,
  setCatalogHeight,
  selectCatalogItem,
  bookmarkAccess
}) => {

  const menuMainRef = useRef(null)

  const handleHideCatalog = useCallback(() => selectCatalogItem(undefined), [selectCatalogItem])

  useEffect(() => {
    setCatalogHeight(pathOr(0, ['current', 'offsetHeight'], menuMainRef))
    return () => {
      setCatalogHeight(0)
      selectCatalogItem(undefined)
    }
  }, [setCatalogHeight, selectCatalogItem])

  const catalogItems = useMemo(() => propOr({}, 'catalogItems', catalog), [catalog])
  const selectedItem = useMemo(() => 
    (isTablet && !selectedItemId) ? path([0, 'ID'], catalogItems) : selectedItemId,
  [catalogItems, selectedItemId, isTablet])

  const handleSelect = useCallback(id => selectCatalogItem(id), [selectCatalogItem])

  const isHideFirstLevel = isMobile && !!selectedItemId

  return (
    <div
      ref={menuMainRef}
      className={s.menuCatalog}
    >
      {!isHideFirstLevel && (
        <div className={s.menuCatalog__first}>
          <MenuMainColumn
            catalogItems={catalogItems}
            bookmarkData={bookmarkData}
            bookmarkAccess={bookmarkAccess}
            selectedItemId={selectedItem}
            isUserWithoutPassword={!isAuth}
            mainColumnHeight={catalogHeight}
            onSelect={handleSelect}
            isBookmark={isBookmark}
            bookmark={bookmark}
            onHideCatalog={handleHideCatalog}
            selectCatalogItem={selectCatalogItem}
            isMobile
          />
        </div>
      )}
      {(isTablet || isHideFirstLevel) && (
        <div className={s.menuCatalog__second}>
          {isMobile && (
            <SecondLevelHeader
              selectedItemId={selectedItemId}
              catalogItems={catalogItems}
              isBookmark={isBookmark}
              bookmark={bookmark}
              onHideCatalog={handleHideCatalog}
            />
          )}
          <MenuSecondLevel
            selectedItemId={selectedItem}
            isBookmark={isBookmark}
            bookmark={bookmark}
            catalogHeight={isMobile ? catalogHeight - SECOND_LEVEL_HEADER : catalogHeight}
            onHideCatalog={handleHideCatalog}
          />
        </div>
      )}
    </div>
  )
}

MenuCatalogMobile.propTypes = {
  catalog: object,
  bookmarkData: array,
  isTablet: bool,
  isMobile: bool,
  isBookmark: bool,
  bookmarkAccess: bool,
  isAuth: bool,
  bookmark: number,
  selectedItemId: number,
  catalogHeight: number,
  setCatalogHeight: func,
  selectCatalogItem: func
}

export default MenuCatalogMobile