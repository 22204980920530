import { prop } from 'ramda'
import { createSelector } from 'reselect'

export const getSuggests = createSelector(
  state => state,
  state => prop('suggests', state)
)

export const getExtended = createSelector(
  state => state,
  state => prop('extended', state)
)

export const getHistory = createSelector(
  state => state,
  state => prop('history', state)
)

export const getSearchProducts = createSelector(
  state => state,
  state => prop('searchProducts', state)
)

export const getSearchCount = createSelector(
  state => state,
  state => prop('searchCount', state)
)

export const getIsSearch = createSelector(
  state => state,
  state => prop('isSearch', state)
)
