import React, { PureComponent } from 'react'

import cx from 'classnames'
import pt from 'prop-types'

import styles from './SocialLinks.scss'

export default class SocialLinks extends PureComponent {
  static propTypes = {
    className: pt.string
  }

  render() {
    const { className } = this.props
    return (
      <div
        className={cx({
          [className]: true,
          [styles.socials]: true
        })}
      >
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='http://vk.com/relefcentr'
          className={styles.vk}
        >
          vkontakte
        </a>
      </div>
    )
  }
}
