/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { string, bool, func, number } from 'prop-types'

import Button from 'UI/Button'

import styles from './ProductLoyaltyButtons.scss'

class ProductLoyaltyButtons extends PureComponent {
  static propTypes = {
    id: string,
    amount: number,
    pushToBasket: func,
    setTarget: func,
    deleteTarget: func,
    isTarget: bool,
    disableToLoyalty: bool,
    isAddingToTarget: bool,
    inBasket: bool,
    hasLoyaltyAccess: bool,
    size: string,
    isBasket: bool,
    isBasketChange: bool
  }

  static defaultProps = {
    pushToBasket: () => {},
    deleteTarget: () => {},
    setTarget: () => {}
  }

  handlePushToTarget = () => {
    const { id, setTarget, deleteTarget, isTarget, amount } = this.props
    if (isTarget) {
      deleteTarget()
    } else {
      setTarget(id, amount)
    }
  }

  render() {
    const {
      pushToBasket,
      isTarget,
      disableToLoyalty,
      inBasket,
      size,
      isAddingToTarget,
      hasLoyaltyAccess,
      isBasket,
      isBasketChange
    } = this.props
    let baseText = 'Получить'
    const updateText = isBasket ? 'Обновляется' : 'Добавляется'
    if (isBasket) {
      baseText = isBasketChange ? 'Изменить' : 'В корзине'
    }
    return (
      <div className={styles.ProductLoyaltyButtons}>
        {!hasLoyaltyAccess && (
          <Button
            color='purple'
            size='small'
            to='/loyalty/conditions'
            stretched
          >
            Принять условия
          </Button>
        )}
        {hasLoyaltyAccess && !disableToLoyalty && (
          <button
            type='button'
            onClick={pushToBasket}
            className={cx(styles.button, styles.toBasket, {
              [styles.button_large]: size === 'large',
              [styles.button_off]: isBasket && !isBasketChange,
              [styles.toBasket_success]: isBasket && !isBasketChange
            })}
            disabled={inBasket}
          >
            <span>{inBasket ? updateText : baseText}</span>
          </button>
        )}
        {hasLoyaltyAccess && (
          <button
            type='button'
            onClick={this.handlePushToTarget}
            className={cx(styles.button, styles.toTarget, {
              [styles.button_large]: size === 'large',
              [styles.toTarget_active]: isTarget
            })}
            disabled={isAddingToTarget}
          >
            <span>{isTarget ? 'Убрать цель' : 'Цель'}</span>
          </button>
        )}
      </div>
    )
  }
}

export default ProductLoyaltyButtons
