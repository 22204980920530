import React, { FC } from 'react'

import cx from 'classnames'

import s from './Preloader.scss'
import { PreloaderModeProps } from './types'

interface IPreloaderProps {
  mode?: PreloaderModeProps;
  active: boolean;
}

const Preloader: FC<IPreloaderProps> = ({ mode = 'loading', active = false }) =>
  active ? (
    <div
      className={cx(s.preloader, s.preloader_active, {
        [s[`preloader_type_${mode}`]]: !!mode
      })}
    >
      <div
        className={cx(s.preloader__wrap, {
          [s[`preloader__wrap_${mode}`]]: !!mode
        })}
      >
        <div className={s[`preloader_${mode}`]} />
        {mode === 'basket' && (
          <div className={s[`preloader__text_${mode}`]}>
            Не закрывайте страницу, идет переход на оформление заказа
          </div>
        )}
      </div>
    </div>
  ) : null

export default Preloader
