import {
  prop,
  map,
  propOr,
  filter,
  isEmpty,
  slice,
  compose,
  addIndex
} from 'ramda'
import { createSelector } from 'reselect'
// import Fuse from 'fuse.js'

const NUMBER_5 = 5

export const getIsLoaded = state => state.isLoaded
export const getIsLoading = state => state.isLoading

export const querySelector = createSelector(
  (_, ownProps) => ownProps,
  ownProps => propOr('query', ownProps)
)

export const querySuccessSelector = createSelector(
  state => state,
  state => propOr('', 'searchSuccess', state)
)

export const searchValueSelector = createSelector(
  state => state,
  state => prop('searchValue', state)
)

export const historySelector = createSelector(
  state => state,
  state => propOr([], 'history', state)
)

const historyPrepareSelector = createSelector(
  historySelector,
  querySuccessSelector,
  (history, query) => {
    if (query) {
      return null
    }
    const items = compose(
      slice(0, NUMBER_5),
      addIndex(map)((item, key) => ({
        id: item,
        code: 'history',
        name: item,
        image: '',
        key
      }))
    )(history)
    // const fuseHistory = new Fuse(itemsHistory, { keys: ['name'], threshold: 0.5 })
    // const itemsSearch = query ? fuseHistory.search(query) : itemsHistory
    return isEmpty(items)
      ? null
      : {
        title: 'История поиска',
        code: 'history',
        items
      }
  }
)

export const suggestsSelector = createSelector(
  state => state,
  state => propOr([], 'suggests', state)
)

export const suggestsPrepareSelector = createSelector(
  suggestsSelector,
  suggests =>
    isEmpty(suggests)
      ? null
      : {
        title: 'Похожие результаты',
        items: addIndex(map)((item, key) => ({
          id: item,
          code: 'suggests',
          name: item,
          image: '',
          key
        }))(suggests)
      }
)

export const sectionsSelector = createSelector(
  state => state,
  state => propOr([], 'sections', state)
)
export const sectionsPrepareSelector = createSelector(
  sectionsSelector,
  sections =>
    isEmpty(sections)
      ? null
      : {
        title: 'Разделы',
        items: addIndex(map)(
          (item, key) => ({
            id: prop('ID', item),
            code: 'sections',
            codeItem: prop('CODE', item),
            name: prop('NAME', item),
            image: prop('PICTURE_PATH', item),
            key
          }),
          sections
        )
      }
)

export const productsSelector = createSelector(
  state => state,
  state => propOr([], 'products', state)
)

export const productsPrepareSelector = createSelector(
  productsSelector,
  products =>
    isEmpty(products)
      ? null
      : {
        title: 'Товары',
        items: addIndex(map)(
          (item, key) => ({
            id: prop('ID', item),
            code: 'products',
            code1c: prop('CODE_1C', item),
            codeItem: prop('CODE', item),
            name: prop('NAME', item),
            image: prop('PREVIEW_PICTURE_PATH', item),
            price: prop('PRICE', item),
            key
          }),
          products
        )
      }
)

export const suggestionsSelector = createSelector(
  historyPrepareSelector,
  suggestsPrepareSelector,
  sectionsPrepareSelector,
  productsPrepareSelector,
  (...suggests) => filter(item => item, suggests)
)
