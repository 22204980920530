import { replace } from 'connected-react-router'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'

import { NoticesBody } from 'components/Notice'
import { showModal } from 'redux/modules/modal'
import { getNotices, setNoticeStatus } from 'redux/modules/notice'
import { switchItemInGroup } from 'redux/modules/productList'
import { setChecked } from 'redux/modules/products'

export default connect(
  ({ notice, router }, ownProps) => ({
    items: getNotices({
      type: 'body',
      page: ownProps.bookmark,
      notice
    }),
    pageType: ownProps.type,
    page: pathOr(1, ['location', 'query', 'p'], router),
    bookmark: ownProps.bookmark
  }),
  {
    onNavigate: replace,
    onShowModal: showModal,
    onSetChecked: setChecked,
    setNoticeStatus,
    switchItemInGroup
  }
)(NoticesBody)
