/* eslint-disable complexity */
import React, { FC, useReducer } from 'react'

import DimmerWrapper from 'components/DimmerWrapper'
import { ITooltip } from 'components/Tooltip/Tooltip'

import s from './Tooltip.scss'

const TooltipMobile: FC<ITooltip> = ({
  children,
  disabled,
  tag = 'div',
  tooltip,
  tooltipNode
}) => {
  const [isOpen, setIsOpen] = useReducer(o => !o, false)
  const Tag = tag

  return (
    <Tag onClick={!disabled ? setIsOpen : null}>
      {children}
      {isOpen && (
        <DimmerWrapper>
          <div className={s.container}>{tooltip || tooltipNode}</div>
        </DimmerWrapper>
      )}
    </Tag>
  )
}

export default TooltipMobile
