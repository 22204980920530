/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string, object, func } from 'prop-types'
import { pathOr, propOr } from 'ramda'
import { connect } from 'react-redux'

import RadialProgress from 'components/Discounts/RadialProgress/RadialProgress'
import Link from 'components/Link'
import { forClientRendering } from 'decorators/window'
import {
  fetch,
  getProgressStep,
  isLoadedDiscounts,
  isLoadingDiscounts
} from 'redux/modules/discounts'
import { pageLoadedSelector } from 'redux/modules/settings'

import s from './HeaderDiscounts.scss'

const HUNDRED = 100

@connect(({
  discounts,
  router,
  reduxAsyncConnect
}) => ({
  targetStep: getProgressStep(discounts),
  isLoaded: isLoadedDiscounts(discounts),
  isLoading: isLoadingDiscounts(discounts),
  pathName: pathOr('/', ['location', 'pathname'], router),
  isPageLoaded: pageLoadedSelector(reduxAsyncConnect)
}), {
  fetchDiscounts: fetch
})
export default class HeaderDiscounts extends PureComponent {
  static propTypes = {
    isLoaded: bool,
    isPageLoaded: bool,
    pathName: string,
    targetStep: object,
    fetchDiscounts: func
  }

  static defaultProps = {
    isLoaded: false,
    isPageLoaded: false,
    targetStep: {}
  }

  state = {
    isHovered: false
  }

  componentDidMount() {
    const { isLoaded } = this.props
    if (!isLoaded) {
      this.fetchData()
    }
  }

  handleMouseEnter = () => this.setState({ isHovered: true })

  handleMouseLeave = () => this.setState({ isHovered: false })

  @forClientRendering
  fetchData() {
    const { isPageLoaded } = this.props
    if (isPageLoaded) {
      this.props.fetchDiscounts()
    } else {
      setTimeout(() => this.fetchData(), HUNDRED)
    }
  }

  render() {
    const { isLoaded, targetStep, pathName } = this.props
    const progress = propOr(0, 'percent', targetStep)
    const isCompleted = propOr(false, 'isCompleted', targetStep)
    const linkTo = '/cabinet/discount'
    const isActiveItem = pathName === linkTo
    return isLoaded && (
      <div className={s.headerDiscount}>
        <Link to={linkTo}>
          <div
            role='presentation'
            className={s.menuItem}
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <div className={s.targetIcon}>
              {isCompleted ? (
                <div className={cx(s.completedTargetIcon, {
                  [s.completedTargetIconActive]: this.state.isHovered || isActiveItem
                })}>
                  {propOr('', 'name', targetStep)}
                </div>
              ) : (
                <RadialProgress
                  progress={isCompleted ? HUNDRED : progress}
                  text={propOr('', 'name', targetStep)}
                  isHovered={this.state.isHovered || isActiveItem}
                  size='small'
                />
              )}
              <div className={cx(s.counter, {
                [s.counterCompleted]: isCompleted
              })}>
                {progress}%
              </div>
            </div>
            <div className={cx(s.menuItemText, {
              [s.menuItemTextActive]: isActiveItem
            })}>
              {isCompleted ? 'Цель достигнута' : 'Выполнение цели'}
            </div>
          </div>
        </Link>
      </div>
    )
  }
}
