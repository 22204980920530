import { connect } from 'react-redux'

import Footer from 'components/Footer'
import { showModal } from 'redux/modules/modal'
import {
  getPhone,
  getAddress,
  getName,
  getDescription,
  getSiteUrl,
  getOfficesAccess,
  getRegistrationAccess,
  getKancobozId,
  vkUrlSelector,
  fbUrlSelector,
  instagramUrlSelector,
  feedbackFormSelector
} from 'redux/modules/personal'
import {
  isBookmarkSelector,
  isDisabledBodyScrollSelector,
  isLoyaltySelector
} from 'redux/modules/settings'

export default connect(
  ({
    settings,
    token: { isUserWithoutPassword },
    catalog: { isVisible, isFixed },
    personal,
    marksMenu: { marks }
  }) => {

    return {
      isUserWithoutPassword,
      hasAccessOffices: getOfficesAccess(personal),
      hasAccessRegistration: getRegistrationAccess(personal),
      kancobozId: getKancobozId(personal),
      phone: getPhone(personal),
      address: getAddress(personal),
      name: getName(personal),
      description: getDescription(personal),
      vkUrl: vkUrlSelector(personal),
      fbUrl: fbUrlSelector(personal),
      feedbackForm: feedbackFormSelector(personal),
      instagramUrl: instagramUrlSelector(personal),
      landingUrl: getSiteUrl(personal),
      catalogMenuIsVisible: isVisible,
      catalogMenuIsFixed: isFixed,
      isDisabledBodyScroll: isDisabledBodyScrollSelector(settings),
      isLoyalty: isLoyaltySelector(settings),
      isBookmark: isBookmarkSelector(settings),
      marks
    }
  },
  {
    showModal
  }
)(Footer)
