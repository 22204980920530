/* eslint-disable complexity */
import {
  path,
  propOr,
  pathOr,
  values,
  has,
  and,
  gt,
  equals,
  or,
  prop,
  not
} from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { Effects, loop } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { hideModal, showModal } from 'redux/modules/modal'

const LIMIT_16 = 16
const LIMIT_24 = 24
const LIMIT_50 = 50

const initialState = {
  sections: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  products: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    query: '',
    error: ''
  },
  productInfo: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    error: '',
    data: []
  },
  entityList: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  batch: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    error: '',
    emptyResult: '',
    parentId: null
  },
  keywords: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  queries: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: '',
    params: {}
  },
  isSuccessChangeKey: false,
  successAddKey: {
    isSuccessAddKey: false,
    addKeyId: null
  },
  isLoading: false,
  isLoaded: false,
  isError: false,
  isSuccessChangeQueryStatus: false,
  error: '',
  sitesList: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: []
  },
  sortList: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  sortListChange: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    success: false,
    error: ''
  },
  sortBrand: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    brand: [],
    error: ''
  },
  sortByCode: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  sortByCodeChange: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    id: null,
    success: false,
    error: ''
  },
  sortOrderChange: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    success: false,
    error: ''
  },
  searchIndex: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    error: '',
    count: 0,
    limit: 0,
    offset: 0,
    warning: ''
  },
  sortOrderList: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    error: '',
    list: []
  },
  properties: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  propertiesTypes: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  propertiesValues: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    data: {},
    error: ''
  },
  propertiesSettings: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    settings: {},
    error: ''
  },
  settingsModify: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    success: false,
    error: ''
  },
  settings: {
    data: {},
    isLoading: false,
    isLoaded: false,
    isError: false
  },
  settingsUpdate: {
    success: false,
    isLoading: false,
    isLoaded: false,
    isError: false
  },
  propertyId: '',
  synonyms: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    list: [],
    error: ''
  },
  synonymsGroup: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    group: {},
    error: ''
  },
  synonymUpdate: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    success: false,
    error: '',
    id: null
  },
  queryDebug: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    data: {},
    error: ''
  },
  uploadKeys: {
    isLoading: false,
    isLoaded: false,
    isError: false,
    success: false,
    error: ''
  }
}
export * from './selector'

export const fetchSection = createAction('search/FETCH_SECTION')
const fetchSectionSuccess = createAction('search/FETCH_SECTION_SUCCESS')
const fetchSectionFailure = createAction('search/FETCH_SECTION_FAILURE')

export const fetchProducts = createAction('search/FETCH_PRODUCTS')
const fetchProductsSuccess = createAction('search/FETCH_PRODUCTS_SUCCESS')
const fetchProductsFailure = createAction('search/FETCH_PRODUCTS_FAILURE')

export const fetchProductInfo = createAction('search/FETCH_PRODUCT_INFO')
const fetchProductInfoSuccess = createAction('search/FETCH_PRODUCT_INFO_SUCCESS')
const fetchProductInfoFailure = createAction('search/FETCH_PRODUCT_INFO_FAILURE')

export const fetchList = createAction('search/FETCH_LIST')
const fetchListSuccess = createAction('search/FETCH_LIST_SUCCESS')
const fetchListFailure = createAction('search/FETCH_LIST_FAILURE')

export const fetchBatch = createAction('search/FETCH_BATCH')
const fetchBatchSuccess = createAction('search/FETCH_BATCH_SUCCESS')
const fetchBatchFailure = createAction('search/FETCH_BATCH_FAILURE')

export const fetchKeywords = createAction('search/FETCH_KEYWORDS')
const fetchKeywordsSuccess = createAction('search/FETCH_KEYWORDS_SUCCESS')
const fetchKeywordsFailure = createAction('search/FETCH_KEYWORDS_FAILURE')

export const changeKey = createAction('search/CHANGE_KEY')
const changeKeySuccess = createAction('search/CHANGE_KEY_SUCCESS')
const changeKeyFailure = createAction('search/CHANGE_KEY_FAILURE')

export const addKey = createAction('search/ADD_KEY')
const addKeySuccess = createAction('search/ADD_KEY_SUCCESS')
const addKeyFailure = createAction('search/ADD_KEY_FAILURE')

export const clearAddKeySuccess = createAction('search/ADD_KEY_CLEAR')

export const delKey = createAction('search/DEL_KEY')
const delKeySuccess = createAction('search/DEL_KEY_SUCCESS')
const delKeyFailure = createAction('search/DEL_KEY_FAILURE')

export const fetchQueries = createAction('search/FETCH_QUERIES')
const fetchQueriesSuccess = createAction('search/FETCH_QUERIES_SUCCESS')
const fetchQueriesFailure = createAction('search/FETCH_QUERIES_FAILURE')

export const fetchQueryStatus = createAction('search/FETCH_QUERY_STATUS')
const fetchQueryStatusSuccess = createAction('search/FETCH_QUERY_STATUS_SUCCESS')
const fetchQueryStatusFailure = createAction('search/FETCH_QUERY_STATUS_FAILURE')

export const fetchSites = createAction('search/FETCH_SITES')
const fetchSitesSuccess = createAction('search/FETCH_SITES_SUCCESS')
const fetchSitesFailure = createAction('search/FETCH_SITES_FAILURE')

export const fetchSortList = createAction('search/FETCH_SORT_LIST')
const fetchSortListSuccess = createAction('search/FETCH_SORT_LIST_SUCCESS')
const fetchSortListFailure = createAction('search/FETCH_SORT_LIST_FAILURE')

export const fetchSortAdd = createAction('search/FETCH_SORT_ADD')
const fetchSortAddSuccess = createAction('search/FETCH_SORT_ADD_SUCCESS')
const fetchSortAddFailure = createAction('search/FETCH_SORT_ADD_FAILURE')

export const fetchSortEdit = createAction('search/FETCH_SORT_EDIT')
const fetchSortEditSuccess = createAction('search/FETCH_SORT_EDIT_SUCCESS')
const fetchSortEditFailure = createAction('search/FETCH_SORT_EDIT_FAILURE')

export const fetchSortDelete = createAction('search/FETCH_SORT_DELETE')
const fetchSortDeleteSuccess = createAction('search/FETCH_SORT_DELETE_SUCCESS')
const fetchSortDeleteFailure = createAction('search/FETCH_SORT_DELETE_FAILURE')

export const fetchSortBrand = createAction('search/FETCH_SORT_BRAND')
const fetchSortBrandSuccess = createAction('search/FETCH_SORT_BRAND_SUCCESS')
const fetchSortBrandFailure = createAction('search/FETCH_SORT_BRAND_FAILURE')

export const fetchSortByCode = createAction('search/FETCH_SORT_BY_CODE')
const fetchSortByCodeSuccess = createAction('search/FETCH_SORT_BY_CODE_SUCCESS')
const fetchSortByCodeFailure = createAction('search/FETCH_SORT_BY_CODE_FAILURE')

export const fetchSortByCodeChange = createAction('search/FETCH_SORT_BY_CODE_CHANGE')
const fetchSortByCodeChangeSuccess = createAction('search/FETCH_SORT_BY_CODE_CHANGE_SUCCESS')
const fetchSortByCodeChangeFailure = createAction('search/FETCH_SORT_BY_CODE_CHANGE_FAILURE')

export const fetchSearchIndex = createAction('search/FETCH_SEARCH_INDEX')
const fetchSearchIndexSuccess = createAction('search/FETCH_SEARCH_INDEX_SUCCESS')
const fetchSearchIndexFailure = createAction('search/FETCH_SEARCH_INDEX_FAILURE')

export const fetchSortOrder = createAction('search/FETCH_SORT_ORDER')
const fetchSortOrderSuccess = createAction('search/FETCH_SORT_ORDER_SUCCESS')
const fetchSortOrderFailure = createAction('search/FETCH_SORT_ORDER_FAILURE')

export const fetchSortOrderChange = createAction('search/FETCH_SORT_ORDER_CHANGE')
const fetchSortOrderChangeSuccess = createAction('search/FETCH_SORT_ORDER_CHANGE_SUCCESS')
const fetchSortOrderChangeFailure = createAction('search/FETCH_SORT_ORDER_CHANGE_FAILURE')

export const fetchProperties = createAction('search/FETCH_PROPERTIES')
const fetchPropertiesSuccess = createAction('search/FETCH_PROPERTIES_SUCCESS')
const fetchPropertiesFailure = createAction('search/FETCH_PROPERTIES_FAILURE')

export const fetchPropertiesTypes = createAction('search/FETCH_PROPERTIES_TYPES')
const fetchPropertiesTypesSuccess = createAction('search/FETCH_PROPERTIES_TYPES_SUCCESS')
const fetchPropertiesTypesFailure = createAction('search/FETCH_PROPERTIES_TYPES_FAILURE')

export const fetchPropertiesValues = createAction('search/FETCH_PROPERTIES_VALUES')
const fetchPropertiesValuesSuccess = createAction('search/FETCH_PROPERTIES_VALUES_SUCCESS')
const fetchPropertiesValuesFailure = createAction('search/FETCH_PROPERTIES_VALUES_FAILURE')

export const fetchPropertiesValuesType = createAction('search/FETCH_PROPERTIES_VALUES_TYPE')
const fetchPropertiesValuesTypeSuccess = createAction('search/FETCH_PROPERTIES_VALUES_TYPE_SUCCESS')
const fetchPropertiesValuesTypeFailure = createAction('search/FETCH_PROPERTIES_VALUES_TYPE_FAILURE')

export const fetchPropertyModify = createAction('search/FETCH_PROPERTY_MODIFY')
const fetchPropertyModifySuccess = createAction('search/FETCH_PROPERTY_MODIFY_SUCCESS')
const fetchPropertyModifyFailure = createAction('search/FETCH_PROPERTY_MODIFY_FAILURE')

export const fetchValueModify = createAction('search/FETCH_VALUE_MODIFY')
const fetchValueModifySuccess = createAction('search/FETCH_VALUE_MODIFY_SUCCESS')
const fetchValueModifyFailure = createAction('search/FETCH_VALUE_MODIFY_FAILURE')

export const fetchPropertiesSettings = createAction('search/FETCH_PROPERTIES_SETTINGS')
const fetchPropertiesSettingsSuccess = createAction('search/FETCH_PROPERTIES_SETTINGS_SUCCESS')
const fetchPropertiesSettingsFailure = createAction('search/FETCH_PROPERTIES_SETTINGS_FAILURE')

export const fetchSettingsModify = createAction('search/FETCH_SETTINGS_MODIFY')
const fetchSettingsModifySuccess = createAction('search/FETCH_SETTINGS_MODIFY_SUCCESS')
const fetchSettingsModifyFailure = createAction('search/FETCH_SETTINGS_MODIFY_FAILURE')

export const fetchSettings = createAction('search/FETCH_SETTINGS')
const fetchSettingsSuccess = createAction('search/FETCH_SETTINGS_SUCCESS')
const fetchSettingsFailure = createAction('search/FETCH_SETTINGS_FAILURE')

export const fetchSettingsUpdate = createAction('search/FETCH_SETTINGS_UPDATE')
const fetchSettingsUpdateSuccess = createAction('search/FETCH_SETTINGS_UPDATE_SUCCESS')
const fetchSettingsUpdateFailure = createAction('search/FETCH_SETTINGS_UPDATE_FAILURE')

export const fetchSynonyms = createAction('search/FETCH_SYNONYMS')
const fetchSynonymsSuccess = createAction('search/FETCH_SYNONYMS_SUCCESS')
const fetchSynonymsFailure = createAction('search/FETCH_SYNONYMS_FAILURE')

export const fetchSynonymsGroup = createAction('search/FETCH_SYNONYMS_GROUP')
const fetchSynonymsGroupSuccess = createAction('search/FETCH_SYNONYMS_GROUP_SUCCESS')
const fetchSynonymsGroupFailure = createAction('search/FETCH_SYNONYMS_GROUP_FAILURE')

export const fetchSynonymAdd = createAction('search/FETCH_SYNONYM_ADD')
const fetchSynonymAddSuccess = createAction('search/FETCH_SYNONYM_ADD_SUCCESS')
const fetchSynonymAddFailure = createAction('search/FETCH_SYNONYM_ADD_FAILURE')

export const fetchSynonymChange = createAction('search/FETCH_SYNONYM_CHANGE')
const fetchSynonymChangeSuccess = createAction('search/FETCH_SYNONYM_CHANGE_SUCCESS')
const fetchSynonymChangeFailure = createAction('search/FETCH_SYNONYM_CHANGE_FAILURE')

export const fetchSynonymsDelete = createAction('search/FETCH_SYNONYMS_DELETE')
const fetchSynonymsDeleteSuccess = createAction('search/FETCH_SYNONYMS_DELETE_SUCCESS')
const fetchSynonymsDeleteFailure = createAction('search/FETCH_SYNONYMS_DELETE_FAILURE')

export const fetchSynonymsCreate = createAction('search/FETCH_SYNONYMS_CREATE')
const fetchSynonymsCreateSuccess = createAction('search/FETCH_SYNONYMS_CREATE_SUCCESS')
const fetchSynonymsCreateFailure = createAction('search/FETCH_SYNONYMS_CREATE_FAILURE')

export const fetchQueryDebug = createAction('search/FETCH_QUERY_DEBUG')
const fetchQueryDebugSuccess = createAction('search/FETCH_QUERY_DEBUG_SUCCESS')
const fetchQueryDebugFailure = createAction('search/FETCH_QUERY_DEBUG_FAILURE')

export const fetchUploadKeys = createAction('search/FETCH_UPLOAD_KEYS')
const fetchUploadKeysSuccess = createAction('search/FETCH_UPLOAD_KEYS_SUCCESS')
const fetchUploadKeysFailure = createAction('search/FETCH_UPLOAD_KEYS_FAILURE')

export const saveBatchParentId = createAction('search/SAVE_BATCH_PARENT_ID')

// Получение сайтов (Выпадающий список)
const requestSites = ({ clientApi }) => () =>
  clientApi
    .get('/v3/search/site/')
    .then(fetchSitesSuccess)
    .catch(fetchSitesFailure)

const handleFetchSites = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sitesList: {
        isLoaded: false,
        isLoading: true,
        isError: false
      }
    },
    Effects.promise(requestSites({ clientApi }))
  )
}

const handleFetchSitesSuccess = (state, payload) => ({
  ...state,
  sitesList: {
    list: path(['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSitesFailure = (state, payload) => ({
  ...state,
  sitesList: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Получение всех разделов
const requestSection = ({ clientApi }) => params => {
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get('/v3/search/section/', {
      params: {
        [key]: siteId
      }
    })
    .then(fetchSectionSuccess)
    .catch(fetchSectionFailure)
}

const handleFetchSection = (state, payload, { clientApi }) => {
  return loop(
    {
      ...state,
      sections: {
        ...state.sections,
        isLoaded: false,
        isLoading: true,
        isError: false
      }
    },
    Effects.promise(requestSection({ clientApi }), payload)
  )
}

const handleFetchSectionSuccess = (state, payload) => ({
  ...state,
  sections: {
    ...state.sections,
    list: path(['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSectionFailure = (state, payload) => ({
  ...state,
  sections: {
    ...state.sections,
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Товары с фильтром по разделу // Товары через поиск
const requestProducts = ({ clientApi }) => params =>
  clientApi
    .get('/v3/search/product/', {
      params: {
        query: propOr(null, 'query', params),
        section_id: propOr(null, 'sectionId', params),
        limit: propOr(0, 'limit', params),
        offset: propOr(0, 'offset', params),
        correct: 'Y'
      }
    })
    .then(fetchProductsSuccess)
    .catch(fetchProductsFailure)

export const handleFetchProducts = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      products: {
        ...state.products,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestProducts({ clientApi }), payload)
  )

const handleFetchProductsSuccess = (state, payload) => {
  const key = or(
    pathOr('', ['config', 'params', 'section_id'], payload),
    pathOr('', ['config', 'params', 'query'], payload)
  )
  const offset = pathOr(0, ['config', 'params', 'offset'], payload)
  const list = values(pathOr([], ['data', 'response', 'list'], payload))
  return {
    ...state,
    products: {
      ...state.products,
      isLoading: false,
      isLoaded: true,
      isError: false,
      error: '',
      query: key,
      [key]: and(has(key, state.products), gt(offset, 0))
        ? [...state.products[key], ...list]
        : [...list],
      [`${key}_cnt`]: pathOr(0, ['data', 'response', 'count'], payload)
    }
  }
}

const handleFetchProductsFailure = (state, payload) => ({
  ...state,
  products: {
    isLoading: false,
    isLoaded: true,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Информация о товаре
const requestProductInfo = ({ clientApi }) => ({ productId }) =>
  clientApi
    .get(`/v3/search/product/${productId}/index/`)
    .then(fetchProductInfoSuccess)
    .catch(fetchProductInfoFailure)

export const handleFetchProductInfo = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      productInfo: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestProductInfo({ clientApi }), payload)
  )

const handleFetchProductInfoSuccess = (state, payload) => ({
  ...state,
  productInfo: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    data: pathOr([], ['data', 'response'], payload)
  }
})

const handleFetchProductInfoFailure = (state, payload) => ({
  ...state,
  productInfo: {
    isLoading: false,
    isLoaded: true,
    isError: true,
    error: getErrorMessage(payload),
    data: []
  }
})

// Получение всех серий конкретного бренда
const requestBatch = ({ clientApi }) => brandId =>
  clientApi
    .get('/v3/search/rule/', {
      params: {
        brand_id: brandId
      }
    })
    .then(data => fetchBatchSuccess({data, brandId}))
    .catch(fetchBatchFailure)

const handleFetchBatch = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      batch: {
        ...state.batch,
        emptyResult: '',
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestBatch({ clientApi }), payload)
  )

const handleFetchBatchSuccess = (state, { data, brandId }) => ({
  ...state,
  batch: {
    ...state.batch,
    emptyResult: pathOr('', ['data', 'notices', 'result_empty'], data),
    isLoading: false,
    isLoaded: true,
    isError: false,
    [brandId]: values(pathOr([], ['data', 'response'], data))
  }
})

const handleFetchBatchFailure = (state, payload) => ({
  ...state,
  batch: {
    emptyResult: '',
    isLoading: false,
    isLoaded: true,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Получить список всех ТМ / всех стран / всех производителей
const requestList = ({ clientApi }) => params => {
  const entity = prop('entity', params)
  const siteId = prop('siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get(`/v3/search/${entity}/`, {
      params: {
        [key]: siteId
      }
    })
    .then(fetchListSuccess)
    .catch(fetchListFailure)
}

const handleFetchList = (state, params, { clientApi }) => loop(
  {
    ...state,
    entityList: {
      isLoaded: false,
      isLoading: true,
      isError: false
    }
  },
  Effects.promise(requestList({ clientApi }), params)
)

const handleFetchListSuccess = (state, payload) => ({
  ...state,
  entityList: {
    list: path(['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchListFailure = (state, payload) => ({
  ...state,
  entityList: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Ключи
// Ключевики раздела/бренда/серии/производителя/страны
const requestKeywords = ({ clientApi }) => params => {
  const { directoryCode, directoryId } = params
  const siteId = prop('siteId', params)
  const key = siteId ? 'site_id' : ''

  return clientApi
    .get(`/v3/search/key/${directoryCode}/${directoryId}/`, {
      params: {
        [key]: siteId
      }
    })
    .then(fetchKeywordsSuccess)
    .catch(fetchKeywordsFailure)
}

const handleFetchKeywords = (state, params, { clientApi }) => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const siteId = prop('siteId', params)

  return loop(
    {
      ...state,
      keywords: {
        ...state.keywords,
        directoryCode,
        directoryId,
        siteId,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestKeywords({ clientApi }), params)
  )
}

const handleFetchKeywordsSuccess = (state, payload) =>
  loop(
    {
      ...state,
      keywords: {
        ...state.keywords,
        list: values(pathOr([], ['data', 'response'], payload)),
        isLoading: false,
        isLoaded: true,
        isError: false
      }
    },
    Effects.call(showModal, 'searchModeration')
  )

const handleFetchKeywordsFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  isError: true,
  error: getErrorMessage(payload)
})
// Настройка ключей
// Редактировать ключ
const requestChangeKey = ({ clientApi }) => params => {
  const keyId = propOr('', 'keyId', params)
  const newValue = propOr('', 'value', params)
  return clientApi
    .post(`/v3/search/key/${keyId}/`, {
      params: {
        value: newValue
      }
    })
    .then(data => changeKeySuccess({ data, params }))
    .catch(changeKeyFailure)
}

const handleChangeKey = (state, params, { clientApi }) => loop({
  ...state,
  isSuccessChangeKey: false,
  isLoading: true,
  isError: false
},
Effects.promise(requestChangeKey({ clientApi }), params))

const handleChangeKeySuccess = (state, { data, params }) => {
  const directoryCode = pathOr('', ['keywords', 'directoryCode'], state)
  const directoryId = pathOr('', ['keywords', 'directoryId'], state)
  const siteId = propOr('', 'siteId', params)

  return loop({
    ...state,
    isLoading: false,
    isLoaded: true,
    isError: false,
    isSuccessChangeKey: path(['data', 'response', 'success'], data)
  }, Effects.call(fetchKeywords, { directoryCode, directoryId, siteId }))}

const handleChangeKeyFailure = (state, payload) => ({
  ...state,
  isSuccessChangeKey: false,
  isLoading: false,
  isLoaded: true,
  isError: true,
  error: getErrorMessage(payload)
})
// Добавить ключ
const requestAddKey = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const newValue = propOr('', 'value', params)
  const siteId = propOr('', 'siteId', params)

  return clientApi
    .post('/v3/search/key/', {
      params: {
        directory_code: directoryCode,
        directory_id: directoryId,
        value: newValue
      }
    })
    .then(data => addKeySuccess({ data, newValue, siteId }))
    .catch(addKeyFailure)
}

const handleAddKey = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      successAddKey: {},
      isLoading: true,
      isError: false
    },
    Effects.promise(requestAddKey({ clientApi }), params)
  )
}

const handleAddKeySuccess = (state, { data, newValue, siteId }) => {
  const directoryCode = pathOr('', ['keywords', 'directoryCode'], state)
  const directoryId = pathOr('', ['keywords', 'directoryId'], state)
  const currentParentId = pathOr(null, ['batch', 'parentId'], state)

  const effects = Array.of()

  effects.push(Effects.call(fetchKeywords, { directoryCode, directoryId, siteId }))

  switch (directoryCode) {
    case 'section':
      effects.push(Effects.call(fetchSection, { siteId }))
      break
    case 'rule':
      effects.push(Effects.call(fetchBatch, currentParentId))
      break
    default:
      effects.push(Effects.call(fetchList, { entity: directoryCode, siteId }))
  }

  return loop({
    ...state,
    isLoading: false,
    isLoaded: true,
    isError: false,
    successAddKey: {
      addKeyId: path(['data', 'response', 'id'], data),
      directoryId,
      error: path(['data', 'response', 'error'], data),
      isSuccessAddKey: path(['data', 'response', 'success'], data),
      value: newValue
    }
  }, Effects.batch(effects))}

const handleAddKeyFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  isError: true,
  error: getErrorMessage(payload)
})
// Очистить значения для повтороного добавления
const handleClearAddKeySuccess = state => ({
  ...state,
  successAddKey: {}
})
// Удалить ключ
const requestDelKey = ({ clientApi }) => params => {
  const keyId = propOr('', 'keyId', params)
  const siteId = propOr('', 'siteId', params)

  return clientApi
    .post(`/v3/search/key/${keyId}/delete/`)
    .then(payload => delKeySuccess({payload, siteId}))
    .catch(delKeyFailure)
}

const handleDelKey = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      isSuccessDelKey: false,
      isLoading: true,
      isError: false
    },
    Effects.promise(requestDelKey({ clientApi }), params)
  )
}

const handleDelKeySuccess = (state, {payload, siteId}) => {
  const directoryCode = pathOr('', ['keywords', 'directoryCode'], state)
  const directoryId = pathOr('', ['keywords', 'directoryId'], state)
  const currentParentId = pathOr(null, ['batch', 'parentId'], state)

  const effects = Array.of()

  effects.push(Effects.call(fetchKeywords, { directoryCode, directoryId, siteId}))

  switch (directoryCode) {
    case 'section':
      effects.push(Effects.call(fetchSection, { siteId }))
      break
    case 'rule':
      effects.push(Effects.call(fetchBatch, currentParentId))
      break
    default:
      effects.push(Effects.call(fetchList, { entity: directoryCode, siteId }))
  }

  return loop({
    ...state,
    isLoading: false,
    isLoaded: true,
    isError: false,
    isSuccessDelKey: path(['data', 'response', 'success'], payload)
  }, Effects.batch(effects))}

const handleDelKeyFailure = (state, payload) => ({
  ...state,
  isSuccessDelKey: false,
  isLoading: false,
  isLoaded: true,
  isError: true,
  error: getErrorMessage(payload)
})

// Поисковые запросы
// Настройки запросов. Получить список.
const requestSearchQueries = ({ clientApi }) => params =>
  clientApi
    .get('/v3/search/query/', {
      params: {
        limit: propOr(0, 'limit', params),
        offset: propOr(0, 'offset', params),
        query: propOr('', 'query', params),
        site_id: propOr('all', 'siteId', params),
        state: propOr('', 'state', params)
      }
    })
    .then(fetchQueriesSuccess)
    .catch(fetchQueriesFailure)

export const handleFetchQueries = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      queries: {
        ...state.queries,
        isLoading: true,
        isLoaded: false,
        isError: false,
        params
      }
    },
    Effects.promise(requestSearchQueries({ clientApi }), params)
  )

const handleFetchQueriesSuccess = (state, payload) => ({
  ...state,
  queries: {
    ...state.queries,
    list: values(pathOr([], ['data', 'response', 'list'], payload)),
    count: pathOr(0, ['data', 'response', 'count'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchQueriesFailure = (state, payload) => ({
  ...state,
  queries: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload),
    count: 0
  }
})
// Определение корректности запроса (чекбокс)
const requestStatusQuery = ({ clientApi }) => params => {
  const queryId = propOr('', 'queryId', params)
  const status = propOr('', 'newStatus', params)
  return clientApi
    .post(`/v3/search/query/${queryId}/`, {
      params: {
        status
      }
    })
    .then(fetchQueryStatusSuccess)
    .catch(fetchQueryStatusFailure)
}

const handleQueryStatus = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      isLoading: true,
      isError: false
    },
    Effects.promise(requestStatusQuery({ clientApi }), params)
  )

const handleQueryStatusSuccess = (state, payload) => {
  const isSuccess = pathOr(false, ['data', 'response', 'success'], payload)
  const effects = Array.of()
  if (isSuccess) effects.push(Effects.call(fetchQueries, state.queries.params))
  return loop({
    ...state,
    isLoading: false,
    isLoaded: true,
    isError: false,
    isSuccessChangeQueryStatus: isSuccess
  }, Effects.batch(effects))
}

const handleQueryStatusFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  isError: true,
  error: getErrorMessage(payload)
})

// загрузка ключей по товарам
const requestUploadKeys = ({ clientApi }) => params => {
  const siteId = propOr('', 'siteId', params)
  const formData = propOr({}, 'formData', params)
  if (formData instanceof FormData) formData.append('site_id', siteId)

  return clientApi
    .post('/v3/search/key/import/', { params: formData})
    .then(fetchUploadKeysSuccess)
    .catch(fetchUploadKeysFailure)
}

const handleUploadKeys = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      uploadKeys: {
        isLoading: true,
        isError: false
      }

    },
    Effects.promise(requestUploadKeys({ clientApi }), params)
  )

const handleUploadKeysSuccess = (state, payload) => {
  const effects = Array.of()
  const success = pathOr(false, ['data', 'response', 'success'], payload)

  if (success) {
    effects.push(Effects.call(showModal, 'upload-keys-success'))
  } else {
    effects.push(Effects.call(showModal, 'upload-keys-failure'))
  }

  return loop({
    ...state,
    uploadKeys: {
      isLoading: false,
      isLoaded: true,
      isError: false,
      error: pathOr('ошибка при загрузке файла', ['data', 'errors', 'params_error'], payload),
      success
    }
  }, Effects.batch(effects))
}

const handleUploadKeysFailure = (state, payload) => ({
  ...state,
  uploadKeys: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// данные для отладки
const requestQueryDebug = ({ clientApi }) => params => {
  const query = propOr('', 'query', params)
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get('/v3/search/query/debug/', {
      params: {
        [key]: siteId,
        query
      }
    })
    .then(fetchQueryDebugSuccess)
    .catch(fetchQueryDebugFailure)
}

const handleFetchQueryDebug = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      queryDebug: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestQueryDebug({ clientApi }), params)
  )
}

const handleFetchQueryDebugSuccess = (state, payload) =>
  loop(
    {
      ...state,
      queryDebug: {
        data: pathOr([], ['data', 'response'], payload),
        isLoading: false,
        isLoaded: true,
        isError: false
      }
    },
    Effects.call(showModal, 'searchModerationDebug')
  )

const handleFetchQueryDebugFailure = (state, payload) => ({
  ...state,
  queryDebug: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Механизм сортировки
// Получение значений сортировки выбранной сущности
// вкладка СОРТИРОВКА
const requestSortList = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get(`/v3/search/sort/${directoryCode}/${directoryId}/`, {
      params: {
        [key]: siteId
      }
    })
    .then(fetchSortListSuccess)
    .catch(fetchSortListFailure)
}

const handleFetchSortList = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortList: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortList({ clientApi }), params)
  )
}

const handleFetchSortListSuccess = (state, payload) => ({
  ...state,
  sortList: {
    list: values(pathOr([], ['data', 'response'], payload)),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSortListFailure = (state, payload) => ({
  ...state,
  sortList: {
    list: values(pathOr([], ['data', 'response'], payload)),
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Добавление значений сортировки выбранной сущности выбранного сайта
const requestSortAdd = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .post(`/v3/search/sort/${directoryCode}/${directoryId}/`, {
      params: {
        [key]: siteId,
        value: propOr('', 'value', params)
      }
    })
    .then(payload => fetchSortAddSuccess({payload, params}))
    .catch(fetchSortAddFailure)
}

const handleFetchSortAdd = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortListChange: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortAdd({ clientApi }), params)
  )
}

const handleFetchSortAddSuccess = (state, {payload, params}) => {
  const {directoryCode, currentSite} = params

  const effects = Array.of()

  // При изменении значения сортировки (добавить. изменить. удалить) выполнить запрос
  // в зависимости от директории в которой меняется сортировка
  switch (directoryCode) {
    case 'section': 
      effects.push(Effects.call(fetchSection, { siteId: currentSite }))
      break
    case 'product':
      effects.push(Effects.call(fetchProducts, { query: pathOr('', ['products', 'query'], state)}))
      break
    default:
      effects.push(Effects.call(fetchList, { entity: directoryCode, siteId: currentSite }))
  }

  return loop({
    ...state,
    sortAdd: {
      success: pathOr(false, ['data', 'response', 'success'], payload),
      isLoading: false,
      isLoaded: true,
      isError: false
    }
  }, Effects.batch(effects))}

const handleFetchSortAddFailure = (state, payload) => ({
  ...state,
  sortListChange: {
    success: pathOr(false, ['data', 'response', 'success'], payload),
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Редактирование значений сортировки выбранной сущности выбранного сайта
const requestSortEdit = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const sortId = propOr('', 'sortId', params)
  return clientApi
    .post(`/v3/search/sort/${directoryCode}/${directoryId}/${sortId}/`, {
      params: {
        value: propOr('', 'value', params)
      }
    })
    .then(payload => fetchSortEditSuccess({payload, params}))
    .catch(fetchSortEditFailure)
}

const handleFetchSortEdit = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortListChange: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortEdit({ clientApi }), params)
  )
}

const handleFetchSortEditSuccess = (state, {payload, params}) => {
  const {directoryCode, currentSite} = params

  const effects = Array.of()

  switch (directoryCode) {
    case 'section': 
      effects.push(Effects.call(fetchSection, { siteId: currentSite }))
      break
    case 'product':
      effects.push(Effects.call(fetchProducts, { query: pathOr('', ['products', 'query'], state)}))
      break
    default:
      effects.push(Effects.call(fetchList, { entity: directoryCode, siteId: currentSite }))
  }

  return loop({
    ...state,
    sortListChange: {
      success: pathOr(false, ['data', 'response', 'success'], payload),
      isLoading: false,
      isLoaded: true,
      isError: false
    }
  }, Effects.batch(effects))}

const handleFetchSortEditFailure = (state, payload) => ({
  ...state,
  sortListChange: {
    success: pathOr(false, ['data', 'response', 'success'], payload),
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Удаление значений сортировки выбранной сущности выбранного сайта
const requestSortDelete = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const sortId = propOr('', 'sortId', params)
  return clientApi
    .post(`/v3/search/sort/${directoryCode}/${directoryId}/${sortId}/delete/`)
    .then(payload => fetchSortDeleteSuccess({payload, params}))
    .catch(fetchSortDeleteFailure)
}

const handleFetchSortDelete = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortListChange: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortDelete({ clientApi }), params)
  )
}

const handleFetchSortDeleteSuccess = (state, {payload, params}) => {
  const {directoryCode, currentSite} = params

  const effects = Array.of()

  switch (directoryCode) {
    case 'section': 
      effects.push(Effects.call(fetchSection, { siteId: currentSite }))
      break
    case 'product':
      effects.push(Effects.call(fetchProducts, { query: pathOr('', ['products', 'query'], state)}))
      break
    default:
      effects.push(Effects.call(fetchList, { entity: directoryCode, siteId: currentSite }))
  }

  return loop({
    ...state,
    sortListChange: {
      success: pathOr(false, ['data', 'response', 'success'], payload),
      isLoading: false,
      isLoaded: true,
      isError: false
    }
  }, Effects.batch(effects))}

const handleFetchSortDeleteFailure = (state, payload) => ({
  ...state,
  sortListChange: {
    success: pathOr(false, ['data', 'response', 'success'], payload),
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// вкладка ПО ТМ
// Получение списка ТМ со значениями сортировки по умолчанию (выпадающий список)
const requestSortBrand = ({ clientApi }) => params => {
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get('/v3/search/brand/', {
      params: {
        [key]: siteId
      }
    })
    .then(fetchSortBrandSuccess)
    .catch(fetchSortBrandFailure)
}

const handleFetchSortBrand = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortBrand: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortBrand({ clientApi }), params)
  )
}

const handleFetchSortBrandSuccess = (state, payload) => ({
  ...state,
  sortBrand: {
    brand: pathOr([], ['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSortBrandFailure = (state, payload) => ({
  ...state,
  sortBrand: {
    brand: [],
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// получение настроек
const requestSortByCode = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const directoryCodeBy = propOr('', 'directoryCodeBy', params)
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get(
      `/v3/search/sort/${directoryCode}/${directoryId}/${directoryCodeBy}/`,
      {
        params: {
          [key]: siteId
        }
      }
    )
    .then(fetchSortByCodeSuccess)
    .catch(fetchSortByCodeFailure)
}

const handleFetchSortByCode = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortByCode: {
        ...state.sortByCode,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortByCode({ clientApi }), params)
  )
}

const handleFetchSortByCodeSuccess = (state, payload) => ({
  ...state,
  sortByCode: {
    ...state.sortByCode,
    list: pathOr([], ['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSortByCodeFailure = (state, payload) => ({
  ...state,
  sortByCode: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// добавление
// редактирование (удаление) значения сортировки выбранного
// бренда/раздела для выбранного раздела/бренда для выбранного сайта
const requestSortByCodeChange = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const directoryCodeBy = propOr('brand', 'directoryCodeBy', params)
  const directoryIdBy = propOr('', 'directoryIdBy', params)
  const value = propOr('', 'value', params)
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  const action = propOr('add', 'action', params)
  const isAdd = equals(action, 'add')
  return clientApi
    .post(
      `/v3/search/sort/${directoryCode}/${directoryId}/${directoryCodeBy}/${action}/${isAdd ? '' : directoryIdBy}/`,
      {
        params: {
          directory_id_by: directoryIdBy,
          value,
          [key]: siteId
        }
      }
    )
    .then(fetchSortByCodeChangeSuccess)
    .catch(fetchSortByCodeChangeFailure)
}

const handleFetchSortByCodeChange = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortByCodeChange: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortByCodeChange({ clientApi }), params)
  )
}

const handleFetchSortByCodeChangeSuccess = (state, payload) => ({
  ...state,
  sortByCodeChange: {
    success: pathOr(false, ['data', 'response', 'success'], payload),
    id: pathOr(null, ['data', 'response', 'id'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSortByCodeChangeFailure = (state, payload) => ({
  ...state,
  sortByCodeChange: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// функционал хранения/получения/обновления полей сущностей
// получение настроек
const requestSettings = ({ clientApi }) => ({
  directoryCode = '',
  directoryId = '',
  siteId = ''
}) =>
  clientApi
    .get(`/v3/search/${directoryCode}/${directoryId}/`, {
      params: {
        site_id: siteId
      }
    })
    .then(fetchSettingsSuccess)
    .catch(fetchSettingsFailure)

const handleFetchSettings = (state, params, { clientApi }) => loop(
  {
    ...state,
    settings: {
      // TODO: поправить когда устаканится бэк(2)
      // ...state.settings,
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestSettings({ clientApi }), params)
)

const handleFetchSettingsSuccess = (state, payload) => ({
  ...state,
  settings: {
    // TODO: поправить когда устаканится бэк(3)
    // ...state.settings,
    data: pathOr({}, ['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSettingsFailure = (state, payload) => ({
  ...state,
  settings: {
    data: {},
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// обновление/сброс настроек
const requestSettingsUpdate = ({ clientApi }) => params => {
  const directoryCode = propOr('', 'directoryCode', params)
  const directoryId = propOr('', 'directoryId', params)
  const siteId = propOr('', 'siteId', params)
  const action = propOr('', 'action', params)
  const code = propOr('', 'code', params)
  const p = propOr('', 'p', params)

  let objectParams = {}
  if (p instanceof FormData) {
    p.append('site_id', siteId)
    objectParams = p
  } else {
    objectParams = { value: p, site_id: siteId }
  }

  return clientApi
    .post(
      `/v3/search/${directoryCode}/${directoryId}/field/${code}/${action}/`,
      { params: objectParams }
    )
    .then(fetchSettingsUpdateSuccess)
    .catch(fetchSettingsUpdateFailure)
}

const handleSettingsUpdate = (state, params, { clientApi }) => loop(
  {
    ...state,
    settingsUpdate: {
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestSettingsUpdate({ clientApi }), params)
)

const handleSettingsUpdateSuccess = (state, payload) => {
  const directoryCode = pathOr('', ['keywords', 'directoryCode'], state)
  const directoryId = pathOr('', ['keywords', 'directoryId'], state)
  const siteId = pathOr('', ['keywords', 'siteId'], state)
  const success = pathOr(false, ['data', 'response', 'success'], payload)
  const effects = Array.of()

  if (success) effects.push(Effects.call(fetchSettings, {directoryCode, directoryId, siteId}))

  return loop({
    ...state,
    settingsUpdate: {
      success,
      isLoading: false,
      isLoaded: true,
      isError: false
    }
  }, Effects.batch(effects))}

const handleSettingsUpdateFailure = (state, payload) => ({
  ...state,
  settingsUpdate: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Переиндексация
const requestSearchIndex = ({ clientApi }) => params =>
  clientApi
    .get('/v3/search/index/', {
      params: {
        limit: propOr(LIMIT_16, 'limit', params),
        offset: propOr(0, 'offset', params)
      }
    })
    .then(fetchSearchIndexSuccess)
    .catch(fetchSearchIndexFailure)

export const handleFetchSearchIndex = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      searchIndex: {
        isLoading: true,
        isLoaded: false,
        isError: false
      }
    },
    Effects.promise(requestSearchIndex({ clientApi }), payload)
  )

const handleFetchSearchIndexSuccess = (state, payload) => ({
  ...state,
  searchIndex: {
    ...pathOr({}, ['data', 'response'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSearchIndexFailure = (state, payload) => ({
  ...state,
  searchIndex: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Порядок сортировки
const requestSortOrder = ({ clientApi }) => params => {
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .get('/v3/search/sort_number/', {
      params: {
        [key]: siteId
      }
    })
    .then(fetchSortOrderSuccess)
    .catch(fetchSortOrderFailure)
}

const handleFetchSortOrder  = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortOrderList : {
        isLoaded: false,
        isLoading: true,
        isError: false
      }
    },
    Effects.promise(requestSortOrder({ clientApi }), params)
  )
}

const handleFetchSortOrderSuccess = (state, payload) => ({
  ...state,
  sortOrderList: {
    list: values(pathOr([], ['data', 'response'], payload)),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSortOrderFailure = (state, payload) => ({
  ...state,
  sortOrderList: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Изменение порядка сортировки при перетаскивании
const requestSortOrderChange = ({ clientApi }) => params => {
  const code = propOr('', 'code', params)
  const sortValue = propOr('', 'sortValue', params)
  const siteId = propOr('', 'siteId', params)
  const key = siteId ? 'site_id' : ''
  return clientApi
    .post(`/v3/search/sort_number/${code}/${sortValue}/`, {
      params: {
        [key]: siteId
      }
    })
    .then(fetchSortOrderChangeSuccess)
    .catch(fetchSortOrderChangeFailure)
}

const handleFetchSortOrderChange = (state, params, { clientApi }) => {
  return loop(
    {
      ...state,
      sortOrderChange: {
        success: false,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSortOrderChange({ clientApi }), params)
  )
}

const handleFetchSortOrderChangeSuccess = (state, payload) => ({
  ...state,
  sortOrderChange: {
    success: pathOr(false, ['data', 'response', 'success'], payload),
    isLoading: false,
    isLoaded: true,
    isError: false
  }
})

const handleFetchSortOrderChangeFailure = (state, payload) => ({
  ...state,
  sortOrderChange: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// свойства
const requestProperties = ({ clientApi }) => params =>
  clientApi
    .get('/v3/search/properties/', {
      params: {
        limit: propOr(LIMIT_50, 'limit', params),
        offset: propOr(0, 'offset', params),
        [propOr('', 'query', params) ? 'query' : '']: propOr('', 'query', params),
        [propOr('', 'typeCode', params) ? 'type_code' : '']: propOr('', 'typeCode', params),
        [propOr('', 'siteId', params) ? 'site_id' : '']: propOr('', 'siteId', params)
      }
    })
    .then(fetchPropertiesSuccess)
    .catch(fetchPropertiesFailure)

const handleFetchProperties = (state, params, { clientApi }) => loop(
  {
    ...state,
    properties: {
      ...state.properties,
      params,
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestProperties({ clientApi }), params)
)

const handleFetchPropertiesSuccess = (state, payload) => (
  {
    ...state,
    properties: {
      ...state.properties,
      isLoading: false,
      isLoaded: true,
      isError: false,
      list: pathOr([], ['data', 'response', 'list'], payload),
      count: pathOr(0, ['data', 'response', 'count'], payload)
    }
  }
)

const handleFetchPropertiesFailure = (state, payload) => ({
  ...state,
  properties: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// изменение типа
const requestPropertiesValuesType = ({ clientApi }) => ({propertyId, type}) =>
  clientApi
    .post(`/v3/search/properties/${propertyId}/additional_values/type/${type}/`)
    .then(fetchPropertiesValuesTypeSuccess)
    .catch(fetchPropertiesValuesTypeFailure)

const handleFetchPropertiesValuesType = (state, {type}, { clientApi }) => loop(
  {
    ...state,
    propertiesValues: {
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestPropertiesValuesType({ clientApi }), {propertyId: state.propertyId, type})
)

const handleFetchPropertiesValuesTypeSuccess = (state, payload) =>
  loop(
    {
      ...state,
      propertiesValues: {
        isLoading: false,
        isLoaded: true,
        isError: false,
        success: true,
        data: pathOr({}, ['data', 'response'], payload)
      }
    },
    Effects.batch([Effects.call(fetchProperties, { ...state.properties.params })])
  )

const handleFetchPropertiesValuesTypeFailure = (state, payload) => ({
  ...state,
  propertiesValues: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    success: false,
    error: getErrorMessage(payload)
  }
})

// список типов свойств
const requestPropertiesTypes = ({ clientApi }) => () =>
  clientApi
    .get('/v3/search/properties_types/')
    .then(fetchPropertiesTypesSuccess)
    .catch(fetchPropertiesTypesFailure)

const handleFetchPropertiesTypes = (state, params, { clientApi }) => loop(
  {
    ...state,
    propertiesTypes: {
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestPropertiesTypes({ clientApi }), params)
)

const handleFetchPropertiesTypesSuccess = (state, { data }) => ({
  ...state,
  propertiesTypes: {
    isError: false,
    isLoaded: true,
    isLoading: false,
    list: propOr([], 'response', data)
  }
})

const handleFetchPropertiesTypesFailure = (state, payload) => ({
  ...state,
  propertiesTypes: {
    error: getErrorMessage(payload),
    isError: true,
    isLoaded: false,
    isLoading: false
  }
})

// Доп значения
// контент зависит от типа
const requestPropertiesValues = ({ clientApi }) => ({ propertyId }) =>
  clientApi
    .get(`/v3/search/properties/${propertyId}/additional_values/`)
    .then(({ data }) => fetchPropertiesValuesSuccess({data, propertyId}))
    .catch(fetchPropertiesValuesFailure)

const handleFetchPropertiesValues = (state, params, { clientApi }) => loop(
  {
    ...state,
    propertyId: '',
    propertiesValues: {
      isLoaded: false,
      isLoading: true
    }
  },
  Effects.promise(requestPropertiesValues({ clientApi }), params)
)

const handleFetchPropertiesValuesSuccess = (state, {data, propertyId}) =>
  loop(
    {
      ...state,
      propertyId,
      propertiesValues: {
        isError: false,
        isLoaded: true,
        isLoading: false,
        data: propOr({}, 'response', data)
      }
    },
    Effects.batch([
      Effects.call(showModal, 'searchModeration')
    ])
  )

const handleFetchPropertiesValuesFailure = (state, payload) => ({
  ...state,
  propertyId: '',
  propertiesValues: {
    error: getErrorMessage(payload),
    isError: true,
    isLoaded: false,
    isLoading: false
  }
})

// Запрос добавления/изменения/удаления доп значения к свойству
const requestPropertyModify = ({ clientApi }) => ({
  action,
  id,
  propertyId,
  value
}) =>
  clientApi
    .post(
      `/v3/search/properties/${propertyId}/additional_values/property/${
        or(equals(action, 'edit'), equals(action, 'delete'))
          ? `${id}/${action}/`
          : ''
      }`,
      {
        params: {
          value
        }
      }
    )
    .then(fetchPropertyModifySuccess)
    .catch(fetchPropertyModifyFailure)

const handleFetchPropertyModify = (state, params, { clientApi }) => loop(
  {
    ...state,
    propertiesValues: {
      ...state.propertiesValues,
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestPropertyModify({ clientApi }), {...params, propertyId: state.propertyId})
)

const handleFetchPropertyModifySuccess = (state, payload) => ({
  ...state,
  propertiesValues: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    success: true,
    data: pathOr({}, ['data', 'response', 'data'], payload)
  }
})

const handleFetchPropertyModifyFailure = (state, payload) => ({
  ...state,
  propertiesValues: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    success: false,
    errors: {},
    error: getErrorMessage(payload)
  }
})

// Запрос добавления/изменения/удаления доп значения к значению
const requestValueModify = ({ clientApi }) => ({
  action,
  id,
  propertyId,
  valueId,
  value
}) =>
  clientApi
    .post(
      `/v3/search/properties/${propertyId}/additional_values/value/${valueId}/${
        or(equals(action, 'edit'), equals(action, 'delete'))
          ? `${id}/${action}/`
          : ''
      }`,
      {
        params: {
          value
        }
      }
    )
    .then(fetchValueModifySuccess)
    .catch(fetchValueModifyFailure)

const handleFetchValueModify = (state, params, { clientApi }) => loop(
  {
    ...state,
    propertiesValues: {
      ...state.propertiesValues,
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestValueModify({ clientApi }), {...params, propertyId: state.propertyId})
)

const handleFetchValueModifySuccess = (state, payload) => ({
  ...state,
  propertiesValues: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    success: true,
    data: pathOr({}, ['data', 'response', 'data'], payload)
  }
})

const handleFetchValueModifyFailure = (state, payload) => ({
  ...state,
  propertiesValues: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    success: false,
    error: getErrorMessage(payload)
  }
})

// Настройки по разделам
const requestPropertiesSettings = ({ clientApi }) => ({propertyId}) =>
  clientApi
    .get(`/v3/search/properties/${propertyId}/settings/`)
    .then(fetchPropertiesSettingsSuccess)
    .catch(fetchPropertiesSettingsFailure)

const handleFetchPropertiesSettings = (state, params, { clientApi }) => loop(
  {
    ...state,
    propertiesSettings: {
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestPropertiesSettings({ clientApi }), {propertyId: state.propertyId})
)

const handleFetchPropertiesSettingsSuccess = (state, payload) => ({
  ...state,
  propertiesSettings: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    settings: pathOr({}, ['data', 'response'], payload)
  }
})

const handleFetchPropertiesSettingsFailure = (state, payload) => ({
  ...state,
  propertiesSettings: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// Запрос на изменение значения в таблице
const requestSettingsModify = ({ clientApi }) => ({
  code,
  propertyId,
  settingId,
  value
}) =>
  clientApi
    .post(`/v3/search/properties/${propertyId}/settings/${settingId}/${code}/`, {
      params: {
        value
      }
    })
    .then(fetchSettingsModifySuccess)
    .catch(fetchSettingsModifyFailure)

const handleFetchSettingsModify = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      settingsModify: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSettingsModify({ clientApi }), {...params, propertyId: state.propertyId})
  )

const handleFetchSettingsModifySuccess = (state, payload) => ({
  ...state,
  settingsModify: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    success: pathOr(false, ['data', 'response', 'success'], payload)
  }
})

const handleFetchSettingsModifyFailure = (state, payload) => ({
  ...state,
  settingsModify: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// синонимы
// получить список всех групп синонимов
const requestSynonyms = ({ clientApi }) => params =>
  clientApi
    .get('/v3/search/synonyms/', {
      params: {
        ...params,
        limit: propOr(LIMIT_24, 'limit', params),
        offset: propOr(0, 'offset', params)
      }
    })
    .then(fetchSynonymsSuccess)
    .catch(fetchSynonymsFailure)

const handleFetchSynonyms = (state, params, { clientApi }) => loop(
  {
    ...state,
    synonyms: {
      ...state.synonyms,
      isLoading: true,
      isLoaded: false
    }
  },
  Effects.promise(requestSynonyms({ clientApi }), params)
)

const handleFetchSynonymsSuccess = (state, payload) => {
  return ({
    ...state,
    synonyms: {
      ...state.synonyms,
      isLoading: false,
      isLoaded: true,
      isError: false,
      list: pathOr([], ['data', 'response', 'list'], payload),
      count: pathOr(0, ['data', 'response', 'count'], payload)
    }
  })}

const handleFetchSynonymsFailure = (state, payload) => ({
  ...state,
  synonyms: {
    ...state.synonyms,
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload),
    count: 0
  }
})

// получить список синонимов для редактирования (по группе)
const requestSynonymsGroup = ({ clientApi }) => ({ groupId = null }) => {
  const key = groupId ? 'group_id' : ''
  return clientApi
    .get('/v3/search/synonyms/', {
      params: {
        [key]: groupId
      }
    })
    .then(fetchSynonymsGroupSuccess)
    .catch(fetchSynonymsGroupFailure)
}

const handleFetchSynonymsGroup = (state, params, { clientApi }) => {
  let newState
  const hasId = propOr(false, 'groupId', params)

  if (not(hasId)) {
    newState = {...state}
    newState.synonymsGroup = {
      group: {},
      isLoading: false,
      isLoaded: true
    }

    return loop({...newState}, Effects.call(showModal, 'searchModerationSynonyms'))
  }

  return loop(
    {
      ...state,
      synonymsGroup: {
        ...state.synonymsGroup,
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSynonymsGroup({ clientApi }), params)
  )
}

const handleFetchSynonymsGroupSuccess = (state, payload) =>
  loop(
    {
      ...state,
      synonymsGroup: {
        ...state.synonymsGroup,
        isLoading: false,
        isLoaded: true,
        isError: false,
        group: pathOr({}, ['data', 'response'], payload)
      }
    },
    Effects.call(showModal, 'searchModerationSynonyms')
  )

const handleFetchSynonymsGroupFailure = (state, payload) => ({
  ...state,
  synonymsGroup: {
    ...state.synonymsGroup,
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// добавление нового синонима
const requestSynonymAdd = ({ clientApi }) => ({
  groupId = null,
  ...params
}) =>
  clientApi
    .post(`/v3/search/synonyms/${groupId}/`, {
      params
    })
    .then(data => fetchSynonymAddSuccess({...data, groupId}))
    .catch(fetchSynonymAddFailure)

const handleFetchSynonymAdd = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      synonymUpdate: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSynonymAdd({ clientApi }), params)
  )

const handleFetchSynonymAddSuccess = (state, {data, groupId}) => loop({
  ...state,
  synonymUpdate: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    success: pathOr(false, ['response', 'success'], data)
  }
},
Effects.call(fetchSynonymsGroup, { groupId })
)

const handleFetchSynonymAddFailure = (state, payload) => ({
  ...state,
  synonymUpdate: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// изменить синоним в группе
const requestSynonymChange = ({ clientApi }) => ({
  action = 'update' || 'clear',
  contraction,
  groupId = null,
  synonymId = null,
  synonym = ''
}) =>
  clientApi
    .post(`/v3/search/synonyms/${groupId}/${action}/${synonymId}/`, {
      params: {
        synonym,
        contraction
      }
    })
    .then(data => fetchSynonymChangeSuccess({...data, groupId}))
    .catch(fetchSynonymChangeFailure)

const handleFetchSynonymChange = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      synonymUpdate: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSynonymChange({ clientApi }), params)
  )

const handleFetchSynonymChangeSuccess = (state, {data, groupId}) => loop({
  ...state,
  synonymUpdate: {
    isLoading: false,
    isLoaded: true,
    isError: false,
    success: pathOr(false, ['response', 'success'], data)
  }
},
Effects.call(fetchSynonymsGroup, { groupId })
)

const handleFetchSynonymChangeFailure = (state, payload) => ({
  ...state,
  synonymUpdate: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// удалить группу синонимов
const requestSynonymsDelete = ({ clientApi }) => ({
  groupId = null
}) =>
  clientApi
    .post(`/v3/search/synonyms/${groupId}/delete/`)
    .then(fetchSynonymsDeleteSuccess)
    .catch(fetchSynonymsDeleteFailure)

const handleFetchSynonymsDelete = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      synonymUpdate: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSynonymsDelete({ clientApi }), params)
  )

const handleFetchSynonymsDeleteSuccess = (state, payload) =>
  loop(
    {
      ...state,
      synonymUpdate: {
        isLoading: false,
        isLoaded: true,
        isError: false,
        success: pathOr(false, ['data', 'response', 'success'], payload)
      }
    },
    Effects.batch([
      Effects.call(fetchSynonyms, { offset: 0 }),
      Effects.call(hideModal, 'searchModerationSynonyms')
    ])
  )

const handleFetchSynonymsDeleteFailure = (state, payload) => ({
  ...state,
  synonymUpdate: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

// создать группу синонимов
const requestSynonymsCreate = ({ clientApi }) => params =>
  clientApi
    .post('/v3/search/synonyms/', {
      params
    })
    .then(fetchSynonymsCreateSuccess)
    .catch(fetchSynonymsCreateFailure)

const handleFetchSynonymsCreate = (state, params, { clientApi }) =>
  loop(
    {
      ...state,
      synonymUpdate: {
        isLoading: true,
        isLoaded: false
      }
    },
    Effects.promise(requestSynonymsCreate({ clientApi }), params)
  )

const handleFetchSynonymsCreateSuccess = (state, payload) =>
  loop(
    {
      ...state,
      synonymUpdate: {
        isLoading: false,
        isLoaded: true,
        isError: false,
        success: pathOr(false, ['data', 'response', 'success'], payload),
        id: pathOr(null, ['data', 'response', 'id'], payload)
      }
    },
    Effects.batch([
      Effects.call(fetchSynonyms, { offset: 0 })
    ])
  )

const handleFetchSynonymsCreateFailure = (state, payload) => ({
  ...state,
  synonymUpdate: {
    isLoading: false,
    isLoaded: false,
    isError: true,
    error: getErrorMessage(payload)
  }
})

const handleSaveBatchParentId = (state, payload) => ({
  ...state,
  batch: {
    ...state.batch,
    parentId: payload
  }
})

export default createReducer(on => {
  on(fetchSection, handleFetchSection)
  on(fetchSectionSuccess, handleFetchSectionSuccess)
  on(fetchSectionFailure, handleFetchSectionFailure)
  on(fetchProducts, handleFetchProducts)
  on(fetchProductsSuccess, handleFetchProductsSuccess)
  on(fetchProductsFailure, handleFetchProductsFailure)
  on(fetchProductInfo, handleFetchProductInfo)
  on(fetchProductInfoSuccess, handleFetchProductInfoSuccess)
  on(fetchProductInfoFailure, handleFetchProductInfoFailure)
  on(fetchList, handleFetchList)
  on(fetchListSuccess, handleFetchListSuccess)
  on(fetchListFailure, handleFetchListFailure)
  on(fetchBatch, handleFetchBatch)
  on(fetchBatchSuccess, handleFetchBatchSuccess)
  on(fetchBatchFailure, handleFetchBatchFailure)
  on(fetchKeywords, handleFetchKeywords)
  on(fetchKeywordsSuccess, handleFetchKeywordsSuccess)
  on(fetchKeywordsFailure, handleFetchKeywordsFailure)
  on(changeKey, handleChangeKey)
  on(changeKeySuccess, handleChangeKeySuccess)
  on(changeKeyFailure, handleChangeKeyFailure)
  on(addKey, handleAddKey)
  on(addKeySuccess, handleAddKeySuccess)
  on(addKeyFailure, handleAddKeyFailure)
  on(clearAddKeySuccess, handleClearAddKeySuccess)
  on(delKey, handleDelKey)
  on(delKeySuccess, handleDelKeySuccess)
  on(delKeyFailure, handleDelKeyFailure)
  on(fetchQueries, handleFetchQueries)
  on(fetchQueriesSuccess, handleFetchQueriesSuccess)
  on(fetchQueriesFailure, handleFetchQueriesFailure)
  on(fetchQueryStatus, handleQueryStatus)
  on(fetchQueryStatusSuccess, handleQueryStatusSuccess)
  on(fetchQueryStatusFailure, handleQueryStatusFailure)
  on(fetchQueryDebug, handleFetchQueryDebug)
  on(fetchQueryDebugSuccess, handleFetchQueryDebugSuccess)
  on(fetchQueryDebugFailure, handleFetchQueryDebugFailure)
  on(fetchSites, handleFetchSites)
  on(fetchSitesSuccess, handleFetchSitesSuccess)
  on(fetchSitesFailure, handleFetchSitesFailure)
  on(fetchSortList, handleFetchSortList)
  on(fetchSortListSuccess, handleFetchSortListSuccess)
  on(fetchSortListFailure, handleFetchSortListFailure)
  on(fetchSortAdd, handleFetchSortAdd)
  on(fetchSortAddSuccess, handleFetchSortAddSuccess)
  on(fetchSortAddFailure, handleFetchSortAddFailure)
  on(fetchSortEdit, handleFetchSortEdit)
  on(fetchSortEditSuccess, handleFetchSortEditSuccess)
  on(fetchSortEditFailure, handleFetchSortEditFailure)
  on(fetchSortDelete, handleFetchSortDelete)
  on(fetchSortDeleteSuccess, handleFetchSortDeleteSuccess)
  on(fetchSortDeleteFailure, handleFetchSortDeleteFailure)
  on(fetchSortBrand, handleFetchSortBrand)
  on(fetchSortBrandSuccess, handleFetchSortBrandSuccess)
  on(fetchSortBrandFailure, handleFetchSortBrandFailure)
  on(fetchSortByCode, handleFetchSortByCode)
  on(fetchSortByCodeSuccess, handleFetchSortByCodeSuccess)
  on(fetchSortByCodeFailure, handleFetchSortByCodeFailure)
  on(fetchSortByCodeChange, handleFetchSortByCodeChange)
  on(fetchSortByCodeChangeSuccess, handleFetchSortByCodeChangeSuccess)
  on(fetchSortByCodeChangeFailure, handleFetchSortByCodeChangeFailure)
  on(fetchSearchIndex, handleFetchSearchIndex)
  on(fetchSearchIndexSuccess, handleFetchSearchIndexSuccess)
  on(fetchSearchIndexFailure, handleFetchSearchIndexFailure)
  on(fetchSortOrder, handleFetchSortOrder)
  on(fetchSortOrderSuccess, handleFetchSortOrderSuccess)
  on(fetchSortOrderFailure, handleFetchSortOrderFailure)
  on(fetchSortOrderChange, handleFetchSortOrderChange)
  on(fetchSortOrderChangeSuccess, handleFetchSortOrderChangeSuccess)
  on(fetchSortOrderChangeFailure, handleFetchSortOrderChangeFailure)
  on(fetchProperties, handleFetchProperties)
  on(fetchPropertiesSuccess, handleFetchPropertiesSuccess)
  on(fetchPropertiesFailure, handleFetchPropertiesFailure)
  on(fetchPropertiesTypes, handleFetchPropertiesTypes)
  on(fetchPropertiesTypesSuccess, handleFetchPropertiesTypesSuccess)
  on(fetchPropertiesTypesFailure, handleFetchPropertiesTypesFailure)
  on(fetchPropertiesValues, handleFetchPropertiesValues)
  on(fetchPropertiesValuesSuccess, handleFetchPropertiesValuesSuccess)
  on(fetchPropertiesValuesFailure, handleFetchPropertiesValuesFailure)
  on(fetchPropertiesValuesType, handleFetchPropertiesValuesType)
  on(fetchPropertiesValuesTypeSuccess, handleFetchPropertiesValuesTypeSuccess)
  on(fetchPropertiesValuesTypeFailure, handleFetchPropertiesValuesTypeFailure)
  on(fetchPropertyModify, handleFetchPropertyModify)
  on(fetchPropertyModifySuccess, handleFetchPropertyModifySuccess)
  on(fetchPropertyModifyFailure, handleFetchPropertyModifyFailure)
  on(fetchValueModify, handleFetchValueModify)
  on(fetchValueModifySuccess, handleFetchValueModifySuccess)
  on(fetchValueModifyFailure, handleFetchValueModifyFailure)
  on(fetchPropertiesSettings, handleFetchPropertiesSettings)
  on(fetchPropertiesSettingsSuccess, handleFetchPropertiesSettingsSuccess)
  on(fetchPropertiesSettingsFailure, handleFetchPropertiesSettingsFailure)
  on(fetchSettingsModify, handleFetchSettingsModify)
  on(fetchSettingsModifySuccess, handleFetchSettingsModifySuccess)
  on(fetchSettingsModifyFailure, handleFetchSettingsModifyFailure)
  on(fetchSettings, handleFetchSettings)
  on(fetchSettingsSuccess, handleFetchSettingsSuccess)
  on(fetchSettingsFailure, handleFetchSettingsFailure)
  on(fetchSettingsUpdate, handleSettingsUpdate)
  on(fetchSettingsUpdateSuccess, handleSettingsUpdateSuccess)
  on(fetchSettingsUpdateFailure, handleSettingsUpdateFailure)
  on(fetchSynonyms, handleFetchSynonyms)
  on(fetchSynonymsSuccess, handleFetchSynonymsSuccess)
  on(fetchSynonymsFailure, handleFetchSynonymsFailure)
  on(fetchSynonymsGroup, handleFetchSynonymsGroup)
  on(fetchSynonymsGroupSuccess, handleFetchSynonymsGroupSuccess)
  on(fetchSynonymsGroupFailure, handleFetchSynonymsGroupFailure)
  on(fetchSynonymAdd, handleFetchSynonymAdd)
  on(fetchSynonymAddSuccess, handleFetchSynonymAddSuccess)
  on(fetchSynonymAddFailure, handleFetchSynonymAddFailure)
  on(fetchSynonymChange, handleFetchSynonymChange)
  on(fetchSynonymChangeSuccess, handleFetchSynonymChangeSuccess)
  on(fetchSynonymChangeFailure, handleFetchSynonymChangeFailure)
  on(fetchSynonymsDelete, handleFetchSynonymsDelete)
  on(fetchSynonymsDeleteSuccess, handleFetchSynonymsDeleteSuccess)
  on(fetchSynonymsDeleteFailure, handleFetchSynonymsDeleteFailure)
  on(fetchSynonymsCreate, handleFetchSynonymsCreate)
  on(fetchSynonymsCreateSuccess, handleFetchSynonymsCreateSuccess)
  on(fetchSynonymsCreateFailure, handleFetchSynonymsCreateFailure)
  on(fetchUploadKeys, handleUploadKeys)
  on(fetchUploadKeysSuccess, handleUploadKeysSuccess)
  on(fetchUploadKeysFailure, handleUploadKeysFailure)
  on(saveBatchParentId, handleSaveBatchParentId)
}, initialState)
