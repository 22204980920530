import { pathOr, compose, values } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import moment from 'utils/moment'

export const fetch = createAction('giftsHistory/FETCH')
export const fetchSuccess = createAction('giftsHistory/FETCH_SUCCESS')
export const fetchFailure = createAction('giftsHistory/FETCH_FAILURE')

export const setParams = createAction('giftsHistory/SET_PARAMS')

export { getIsLoaded } from './selector'

const initialState = {
  orders: [],
  totalCount: 0,
  contractors: [],
  params: {
    contractorIndex: 0,
    selectedPage: 1,
    limit: 48
  }
}

export const request =
  ({ clientApi }) =>
    ({ params }) =>
      clientApi
        .get('/v1/orders/', { params })
        .then(fetchSuccess)
        .catch(fetchFailure)

const handleFetch = (state, payload, { clientApi }) => {
  const paramsToSend = {
    'filter[loyalty]': 'Y'
  }

  return loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.promise(request({ clientApi }), { params: paramsToSend })
  )
}

const handleFetchSuccess = (state, payload) => {
  const dateUnixMin = pathOr(
    initialState.minDate,
    ['data', 'response', 'FILTER', 'DATE', 'MIN_DATE'],
    payload
  )
  const dateUnixMax = pathOr(
    initialState.maxDate,
    ['data', 'response', 'FILTER', 'DATE', 'MAX_DATE'],
    payload
  )
  return {
    ...state,
    isLoading: false,
    isLoaded: true,
    orders: compose(
      values,
      pathOr(initialState.orders, ['data', 'response', 'ORDERS'])
    )(payload),
    totalCount: pathOr(
      initialState.totalCount,
      ['data', 'response', 'NAV', 'CNT'],
      payload
    ),
    contractors: compose(
      values,
      pathOr(initialState.contractors, [
        'data',
        'response',
        'FILTER',
        'CONTRACTORS'
      ])
    )(payload),
    params: {
      ...state.params,
      dateMin: moment(dateUnixMin, 'X').format('DD.MM.YYYY'),
      dateMax: moment(dateUnixMax, 'X').format('DD.MM.YYYY'),
      dateUnixMin,
      dateUnixMax
    }
  }
}

const handleSetParams = (state, payload) =>
  loop(
    {
      ...state,
      params: {
        ...state.params,
        ...payload
      }
    },
    Effects.none()
    // Effects.call(fetch)
  )

const handleFetchFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  errorMessage: getErrorMessage(payload)
})

const reducer = createReducer(on => {
  on(fetch, handleFetch)
  on(fetchSuccess, handleFetchSuccess)
  on(fetchFailure, handleFetchFailure)
  on(setParams, handleSetParams)
}, initialState)

export default reducer
