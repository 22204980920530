import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Discounts = loadable(() =>
  import(/* webpackChunkName: "Discounts" */ 'pages/Discounts/Discounts')
)

export default asyncConnect([
  {
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'cabinetDiscounts' })
      return updateData.update()
    }
  }
])(Discounts)
