import React, { FC } from 'react'

import { pathOr } from 'ramda'

import Checkbox from 'UI/Checkbox'

const RegistrationCheckbox: FC = props => (
  <Checkbox 
    checked={pathOr(false, ['input', 'value'], props)}
    onChange={pathOr(false, ['input', 'onChange'], props)}
    {...props}
  />
)

export default RegistrationCheckbox