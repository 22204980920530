import React, { memo, useCallback } from 'react'

import { useDispatch } from 'react-redux'

import { hideModal, showModal } from 'redux/modules/modal'

/*
# Для чего использовать декоратор
Если нужно прокинуть props showModal, hideModal в компонент

```
modalActions(Component)
```
*/

const modalActions = WrapperComponent => memo(props => {
  const dispatch = useDispatch()
  const onShowModal = useCallback(
    params => dispatch(showModal(params)),
    [dispatch]
  )

  const onHideModal = useCallback(
    params => dispatch(hideModal(params)),
    [dispatch]
  )

  return (
    <WrapperComponent
      {...props}
      showModal={onShowModal}
      hideModal={onHideModal}
    />
  )
})

export { modalActions }
