/* eslint-disable complexity */
import { prop, pathOr, isEmpty, path, length, compose, values } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'
import { showModal } from 'redux/modules/modal'
import { setNotice } from 'redux/modules/notice'

const initialState = {
  isSending: false,
  isSent: false,
  email: [''],
  description: '',
  isDownloading: false,
  isDownloaded: true,
  errorMessage: '',
  successMessage: '',
  typeModal: '',
  downloader: {
    isLoaded: false,
    isLoading: false,
    isError: false,
    items: [],
    errorMessage: ''
  },
  sender: {
    isLoaded: false,
    isLoading: false,
    isError: false,
    items: [],
    errorMessage: ''
  },
  changeStore: {
    isLoaded: false,
    isLoading: false,
    isError: false,
    items: [],
    errorMessage: ''
  },
  uploadExcel: {
    isLoaded: false,
    isLoading: false,
    isError: false,
    items: [],
    errorMessage: ''
  }
}

export {
  getIsSending,
  getIsSent,
  getFilename,
  getErrorMessage,
  getSuccessMessage,
  typeModalSelector,
  changeStoreSelector,
  uploadExcelSelector
} from './selector'

export const reset = createAction('sender/RESET')
export const send = createAction('sender/SEND')
export const sendSuccess = createAction('sender/SEND_SUCCESS')
export const sendFailure = createAction('sender/SEND_FAILURE')
export const downloadBasket = createAction('sender/DOWNLOAD_BASKET')
export const downloadProducts = createAction('sender/DOWNLOAD_PRODUCTS')
export const downloadSuccess = createAction('sender/DOWNLOAD_PRODUCTS_SUCCESS')
export const downloadFailure = createAction('sender/DOWNLOAD_PRODUCTS_FAILURE')
export const changeStore = createAction('sender/CHANGE_STORE')
export const changeStoreSuccess = createAction('sender/CHANGE_STORE_SUCCESS')
export const changeStoreFailure = createAction('sender/CHANGE_STORE_FAILURE')
export const uploadExcel = createAction('sender/UPLOAD_EXCEL')
export const uploadExcelSuccess = createAction('sender/UPLOAD_EXCEL_SUCCESS')
export const uploadExcelFailure = createAction('sender/UPLOAD_EXCEL_FAILURE')
export const setTypeModal = createAction('sender/SET_TYPE_MODAL')
export const fetchBasket = createAction('sender/FETCH_BASKET')
export const fetchBasketSuccess = createAction('sender/FETCH_BASKET_SUCCESS')
export const fetchBasketFailure = createAction('sender/FETCH_BASKET_FAILURE')

export const requestSend =
  ({ clientApi }) =>
    payload => {
      const params = {
        ...payload.params,
        contractor_id: clientApi.getContractorId()
      }
      return clientApi
        .get(`/v3/sale/basket/${payload.type}/send/`, { params })
        .then(sendSuccess)
        .catch(sendFailure)
    }

const handleSend = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isSending: true,
      isSent: false
    },
    Effects.promise(requestSend({ clientApi }), payload)
  )

const handleSendSuccess = (state, payload) => {
  const effects = []
  const isSendSuccess = pathOr(false, ['data', 'response', 'SUCCESS'], payload)
  const message = pathOr({}, ['data', 'response', 'MESSAGE'], payload)
  if (isSendSuccess) {
    effects.push(Effects.call(showModal, 'alertSend'))
  } else if (!isEmpty(message)) {
    effects.push(
      Effects.call(setNotice, {
        name: 'alert',
        page: 'basket',
        value: [message]
      })
    )
  }
  return loop(
    {
      ...state,
      isSendSuccess,
      isSent: true,
      isSending: false
    },
    Effects.batch(effects)
  )
}

const handleSendFailure = (state, payload) => ({
  ...state,
  isSending: false,
  isSent: false,
  errorMessage: getErrorMessage(payload)
})

export const requestDownloadBasket =
  ({ clientApi }) =>
    ({ type = 'main', value }) => {
      const data = {
        contractor_id: clientApi.getContractorId(),
        value
      }
      return clientApi
        .get(`/v3/sale/basket/${type}/export/`, { params: data })
        .then(downloadSuccess)
        .catch(downloadFailure)
    }

const handleDownloadBasket = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isDownloading: true,
      isDownload: false
    },
    Effects.promise(requestDownloadBasket({ clientApi }), payload)
  )

export const requestDownloadTechDescription =
  ({ clientApi }) =>
    ({ type = 'main' }) =>
      clientApi
        .post(`/v3/sale/basket/${type}/techdoc/`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(downloadSuccess)
        .catch(downloadFailure)

export const requestDownloadProducts =
  ({ clientApi }) =>
    params =>
      clientApi
        .get(`/v1/catalog/section/?type=export&${params}`)
        .then(downloadSuccess)
        .catch(downloadFailure)

const handleDownloadProducts = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      isDownloading: true,
      isDownload: false
    },
    Effects.promise(requestDownloadProducts({ clientApi }), payload)
  )

const handleDownloadSuccess = (state, payload) => {
  const response = path(['data', 'response'], payload)
  const effects = []
  const filename = path(['RESULT', 'PATH'], response)
  const isSuccess = path(['SUCCESS'], response)
  if (filename) {
    const messageAlert = pathOr('', ['MESSAGE'], response)
    if (!isEmpty(messageAlert)) {
      effects.push(
        Effects.call(setNotice, {
          name: 'alert',
          page: 'basket',
          value: [messageAlert],
          header: isSuccess ? 'Удача' : 'Ошибка'
        })
      )
    }
    if (typeof window !== 'undefined') {
      window.location.href = filename
    }
  }
  return loop(
    {
      ...state,
      isDownloading: false,
      isDownloaded: true,
      filename
    },
    Effects.batch(effects)
  )
}

const handleDownloadFailure = (state, payload) =>
  loop(
    {
      ...state,
      isDownloading: false,
      isDownloaded: false,
      filename: '',
      errorMessage: getErrorMessage(payload)
    },
    Effects.call(showModal, 'errorDownload')
  )

const requestBasketProducts =
  ({ clientApi }) =>
    ({ catalog = 'main', value, type }) =>
      clientApi
        .get(`/v3/sale/basket/${catalog}/get/`, {
          params: {
            contractor_id: clientApi.getContractorId(),
            value,
            limit: 1000
          }
        })
        .then(data => fetchBasketSuccess({ ...data, catalog, value, type }))
        .catch(error => fetchBasketFailure({ ...error }))

const requestChangeStore =
  ({ clientApi }) =>
    ({ catalog = 'main', value, products, store, onSuccess, onFailure }) =>
      clientApi
        .post(`/v3/sale/basket/${catalog}/change/store/`, {
          params: {
            contractor_id: clientApi.getContractorId(),
            value,
            products,
            store
          }
        })
        .then(data =>
          changeStoreSuccess({ ...data, catalog, value, sideEffect: onSuccess() })
        )
        .catch(error => changeStoreFailure({ ...error, sideEffect: onFailure() }))

const handleChangeStore = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      changeStore: {
        ...state.changeStore,
        isLoaded: false,
        isLoading: true
      }
    },
    Effects.promise(requestChangeStore({ clientApi }), payload)
  )

const handleChangeStoreSuccess = (state, payload) => {
  const effects = []
  const response = path(['data', 'response'], payload)
  const sideEffect = path(['sideEffect'], payload)
  const items = path(['ITEMS'], response)
  const errorMessage = pathOr('', ['data', 'errors'], payload)
  const isError = !!errorMessage

  effects.push(
    Effects.call(showModal, isError ? 'basket-items-error' : 'basket-items')
  )
  effects.push(Effects.call(setTypeModal, 'changeStore'))
  if (sideEffect) {
    effects.push(sideEffect)
  }
  const count = pathOr(0, ['COUNT'], response)
  const added = compose(length, values, pathOr({}, ['ADDED']))(response)

  const notFound = count - added

  return loop(
    {
      ...state,
      changeStore: {
        ...state.changeStore,
        isLoaded: true,
        isLoading: false,
        messages: pathOr([], ['MESSAGES'], response),
        count,
        warnings: pathOr(0, ['WARNINGS'], response),
        added,
        notFound,
        items
      }
    },
    Effects.batch(effects)
  )
}

const handleChangeStoreFailure = (state, payload) => {
  const effects = []
  const sideEffect = path(['sideEffect'], payload)
  if (sideEffect) {
    effects.push(sideEffect)
  }
  return loop(
    {
      ...state,
      changeStore: {
        ...state.changeStore,
        isLoaded: false,
        isLoading: false,
        items: [],
        errorMessage: getErrorMessage(payload)
      }
    },
    Effects.batch(effects)
  )
}

const requestUploadExcel =
  ({ clientApi }) =>
    ({ formData, catalog = 'main' }) => {
      formData.contractor_id = clientApi.getContractorId()
      return clientApi
        .post(`/v3/sale/basket/${catalog}/import/`, { params: formData })
        .then(payload =>
          uploadExcelSuccess({
            ...payload,
            value: prop('value', formData),
            catalog
          })
        )
        .catch(uploadExcelFailure)
    }

const handleUploadExcel = (state, payload, { clientApi }) =>
  loop(
    {
      ...state,
      uploadExcel: {
        ...state.uploadExcel,
        isLoading: true,
        isLoaded: false,
        isError: false
      }
    },
    Effects.promise(requestUploadExcel({ clientApi }), payload)
  )

const handleUploadExcelSuccess = (state, payload) => {
  const effects = []
  const errorMessage = pathOr(
    '',
    ['data', 'errors', 'error_upload_file'],
    payload
  )
  const isError = !!errorMessage
  const isLoaded = !isError

  effects.push(
    Effects.call(showModal, isError ? 'basket-items-error' : 'basket-items')
  )
  effects.push(Effects.call(setTypeModal, 'uploadExcel'))
  if (!isError) {
    effects.push(
      Effects.call(fetchBasket, {
        catalog: prop('catalog', payload),
        value: prop('value', payload),
        type: 'uploadExcel'
      })
    )
  }
  const count = pathOr(0, ['data', 'response', 'COUNT'], payload)
  const added = compose(
    length,
    values,
    pathOr({}, ['data', 'response', 'ADDED'])
  )(payload)
  const notFound = count - added

  return loop(
    {
      ...state,
      uploadExcel: {
        ...state.uploadExcel,
        isLoading: false,
        isLoaded,
        isError,
        errorMessage,
        added,
        messages: pathOr([], ['data', 'response', 'MESSAGES'], payload),
        count,
        warnings: pathOr(0, ['data', 'response', 'WARNINGS'], payload),
        notFound,
        items: pathOr([], ['data', 'response', 'ITEMS'], payload)
      }
    },
    Effects.batch(effects)
  )
}

const handleUploadExcelFailure = (state, payload) =>
  loop(
    {
      ...state,
      uploadExcel: {
        ...state.uploadExcel,
        isLoading: false,
        isLoaded: false,
        isError: true,
        errorMessage: getErrorMessage(payload)
      }
    },
    Effects.call(showModal, 'basket-items-error')
  )

const handleReset = state => ({
  ...state,
  ...initialState
})

const handleSetTypeModal = (state, typeModal) => ({
  ...state,
  typeModal
})

const handleFetchBasket = (state, payload, { clientApi }) =>
  loop(
    {
      ...state
    },
    Effects.promise(requestBasketProducts({ clientApi }), payload)
  )

const handleFetchBasketSuccess = (state, payload) => ({
  ...state,
  [prop('type', payload)]: {
    ...state[prop('type', payload)],
    items: pathOr([], ['data', 'response', 'ITEMS'], payload)
  }
})

const handleFetchBasketFailure = (state, payload) => ({
  ...state,
  [prop('type', payload)]: {
    ...state[prop('type', payload)],
    items: []
  }
})

export default createReducer(on => {
  on(send, handleSend)
  on(sendSuccess, handleSendSuccess)
  on(sendFailure, handleSendFailure)
  on(reset, handleReset)
  on(downloadBasket, handleDownloadBasket)
  on(downloadProducts, handleDownloadProducts)
  on(downloadSuccess, handleDownloadSuccess)
  on(downloadFailure, handleDownloadFailure)
  on(changeStore, handleChangeStore)
  on(changeStoreSuccess, handleChangeStoreSuccess)
  on(changeStoreFailure, handleChangeStoreFailure)
  on(uploadExcel, handleUploadExcel)
  on(uploadExcelSuccess, handleUploadExcelSuccess)
  on(uploadExcelFailure, handleUploadExcelFailure)
  on(setTypeModal, handleSetTypeModal)
  on(fetchBasket, handleFetchBasket)
  on(fetchBasketSuccess, handleFetchBasketSuccess)
  on(fetchBasketFailure, handleFetchBasketFailure)
}, initialState)
