/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import hoistStatics from 'hoist-non-react-statics'
import { object } from 'prop-types'
import { compose, pathOr, propOr } from 'ramda'
import { connect } from 'react-redux'
import { animateScroll as scroll } from 'react-scroll'

import parse from 'utils/querystring'

export default () => WrappedComponent => {
  @connect(({ router }) => ({
    location: propOr({}, 'location', router)
  }))
  class Scroll extends PureComponent {
    static displayName = `Redirect(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      location: object
    }

    componentDidUpdate(prevProps) {
      if (
        typeof window !== 'undefined' &&
        this.props.location !== prevProps.location
      ) {
        const currentFilters = this.getFiltersValues(this.props)
        const prevFilters = this.getFiltersValues(prevProps)
        if (currentFilters !== prevFilters) {
          return
        }
        scroll.scrollToTop({ duration: 500 })
      }
    }

    getFiltersValues = search =>
      compose(
        propOr('', 'filters'),
        parse,
        pathOr('', ['location', 'search'])
      )(search)

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return hoistStatics(Scroll, WrappedComponent)
}
