/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import cx from 'classnames'
import { bool, string, object, func, oneOf } from 'prop-types'
import { propOr, pathOr, keys } from 'ramda'
import { connect } from 'react-redux'
import { reduxForm, Field, getFormValues } from 'redux-form'

import Icon from 'components/Icon'
import {display} from 'decorators/device'
import { resetError } from 'redux/modules/token'
import Button from 'UI/Button'
import { FieldInput } from 'UI/Input'
import Title from 'UI/Title'

import s from './FormAuth.scss'

const TAB_INDEX_1 = 2000
const TAB_INDEX_2 = 2100
const TAB_INDEX_SUBMIT = 2200

@connect(
  state => ({
    formsAuth: getFormValues('auth')(state),
    location: pathOr({}, ['router', 'location'], state)
  }),
  { onResetError: resetError }
)
@reduxForm({
  form: 'auth',
  initialValues: {
    email: '',
    password: ''
  },
  validate: values =>
    keys(values).reduce(
      (errors, name) => ({
        ...errors,
        [name]: values[name] ? undefined : 'Обязательное поле'
      }),
      {}
    ),
  enableReinitialize: true
})
@display
export default class FormAuth extends PureComponent {
  static propTypes = {
    email: object,
    location: object,
    onSubmit: func,
    handleSubmit: func,
    change: func,
    onResetError: func,
    authError: string,
    errors: object,
    formsAuth: object,
    invalid: bool,
    pristine: bool,
    submitting: bool,
    isDesktop: bool,
    isLoyalty: bool,
    isPageAuth: bool,
    isRegistration: bool,
    showModal: func,
    password: object,
    mode: oneOf(['pageAuth', 'headerAuth']),
    hasAccessRegistration: bool,
    logoIcon: string
  }

  static defaultProps = {
    showModal: () => {},
    onSubmit: () => {},
    handleSubmit: () => {},
    change: () => {},
    isLoyalty: false,
    isPageAuth: false,
    isRegistration: true,
    hasAccessRegistration: true,
    mode: 'pageAuth',
    logoIcon: '',
    errors: {}
  }

  state = {
    changedAfterError: false,
    showPassword: false
  }

  componentDidUpdate(prevProps) {
    if (this.props.authError !== prevProps.authError && this.props.authError) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ changedAfterError: false })
    }
    if (this.props.location !== prevProps.location && this.props.authError) {
      this.props.onResetError()
    }
  }

  handleChange = name => event => {
    if (this.props.authError && !this.state.changedAfterError) {
      this.setState({ changedAfterError: true })
    }
    const value = pathOr('', ['target', 'value'], event)
    this.props.change(name, value)
  }

  handleEventMetrika = () => {}

  handleShowPopupRegistration = () => {
    const { hasAccessRegistration, showModal } = this.props
    return hasAccessRegistration ? showModal('registration') : false
  }

  handleOpenRecoveryPassword = () => this.props.showModal('recoveryPassword')

  handleTogglePassword = () => {
    this.setState(({showPassword}) => ({ showPassword: !showPassword }))
  }

  renderRegistration() {
    return (
      <Button
        onClick={this.handleShowPopupRegistration}
        stretched
        type='button'
      >
        Регистрация
      </Button>
    )
  }

  renderSubmit() {
    const { invalid, isPageAuth, pristine, submitting } = this.props
    const size = isPageAuth ? 'default' : 'small'
    return (
      <Button
        disabled={invalid || pristine || submitting}
        icon='enter'
        onClick={this.handleEventMetrika}
        size={size}
        stretched
        tabIndex={TAB_INDEX_SUBMIT}
        title='Вход'
        type='submit'
      />
    )
  }

  renderRestore() {
    const { isPageAuth } = this.props
    const color = isPageAuth ? 'transparentBlue' : 'transparent'
    return (
      <Button
        color={color}
        onClick={this.handleOpenRecoveryPassword}
        size='micro'
        title='Забыли пароль?'
        type='button'
      />
    )
  }

  renderField = field => {
    const {
      placeholder,
      input,
      meta: { invalid },
      tabIndex,
      type
    } = field
    const { authError, isPageAuth } = this.props
    const inputName = propOr('', 'name', input)
    const isError = authError && !this.state.changedAfterError
    return (
      <FieldInput
        autoFocus
        {...input}
        onChange={this.handleChange(inputName)}
        error={isError && isPageAuth}
        success={invalid}
        tabIndex={tabIndex}
        placeholder={placeholder}
        type={type}
        size='small'
      />
    )
  }

  renderHeaderAuthErrors = () => {
    const { isLoyalty } = this.props
    return (
      <div className={cx(s.headerDropDown, s.headerAuthErrors)}>
        {!isLoyalty && <div className={s.tooltip} />}
        <span className={s.tooltip_errorTitle}>
          Неверная пара логин и пароль
        </span>
        <br />
        Проверьте раскладку клавиатуры
        и посмотрите, не включен ли
        режим «Caps Lock»
      </div>
    )
  }

  renderAuthErrors = () => (
    <div className={cx(s.headerDropDown, s.authErrors)}>
      <span className={s.authErrorTitle}>
        Неверная пара логин и пароль
      </span>
    </div>
  )

  render() {
    const { handleSubmit, onSubmit, formsAuth, authError, isPageAuth, mode, isRegistration } =
      this.props
    const isError = authError && !this.state.changedAfterError
    const hasPassword = !!propOr('', 'password', formsAuth)
    return (
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={cx(s[`${mode}`])}
      >
        {isPageAuth && (
          <Title
            level={2}
            size={16}
          >
            Вход
          </Title>
        )}
        <div className={cx(s[`${mode}__fields`])}>
          <Field
            name='email'
            component={this.renderField}
            placeholder='Введите логин'
            type='text'
            tabIndex={TAB_INDEX_1}
          />
          <Field
            name='password'
            component={this.renderField}
            placeholder='Введите пароль'
            type={!this.state.showPassword ? 'password' : 'text'}
            tabIndex={TAB_INDEX_2}
          />
          {hasPassword && (
            <div
              role='presentation'
              className={cx(s.pageAuth__showPassword, {
                [s.pageAuth__showPassword_header]: !isPageAuth,
                [s.pageAuth__showPassword_pageAuth]: isPageAuth
              })}
              onClick={this.handleTogglePassword}
            >
              <Icon
                icon='eye'
              />
            </div>
          )}
          {isError && isPageAuth && this.renderAuthErrors()}
          {isError && !isPageAuth && this.renderHeaderAuthErrors()}
        </div>
        <div className={cx(s[`${mode}__buttons`])}>
          {this.renderSubmit()}
          {this.renderRestore()}
        </div>
        <div className={s.pageAuth__regWrap}>
          {isPageAuth && isRegistration && this.renderRegistration()}
        </div>
      </form>
    )
  }
}
