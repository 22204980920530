/* eslint-disable complexity */
import R from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import { getLimitsCount } from 'helpers/products'

export { getIsLoaded, getIsLoading, getInvoice } from './selector'

const emptyItem = { code: '', amount: 0, isValid: false }
const initialState = {
  items: [emptyItem],
  itemsByCode: {}
}
const CODE_LENGTH = 6

export const updateItem = createAction('basketAddByCode/UPDATE_ITEM')
export const removeItem = createAction('basketAddByCode/REMOVE_ITEM')
export const insertEmptyItem = createAction('basketAddByCode/CREATE_ITEM')

export const checkErrors = createAction('basketAddByCode/CHECK_ERRORS')
export const fetchCheckErrors = createAction(
  'basketAddByCode/FETCH_CHECK_ERRORS'
)
export const fetchCheckErrorsSuccess = createAction(
  'basketAddByCode/FETCH_CHECK_ERRORS_SUCCESS'
)

export const loadByCode = createAction('basketAddByCode/LOAD_BY_CODE')

export const reset = createAction('basketAddByCode/RESET')

const byCode = (items = [], code = '') => items.filter(item => item.code === code)

const getError = (
  items,
  index,
  product = {},
  basketPath = ['BASKETS', 'MAIN', 0, 'QUANTITY']
) => {
  const { code } = items[index]

  if (code.length === 0) {
    return false
  }

  if (code.length > 0 && code.length < CODE_LENGTH) {
    return {
      name: 'no_code',
      title: 'Некорректный код товара'
    }
  }

  if (product.isFailed) {
    return {
      name: 'no_product',
      title: 'Товар не найден'
    }
  }

  if (byCode(items, code).length > 1) {
    let correctIndex = index
    items.forEach((item, i) => {
      if (item.code === code && item.isValid) {
        correctIndex = i
      }
    })
    if (correctIndex !== index) {
      return {
        name: 'already_in_list',
        title: 'Данный товар уже добавлен в список'
      }
    }
  }

  const remain = R.compose(
    R.pathOr(0, [0, 'REMAIN']),
    R.filter(item => R.propEq('IS_MAIN', true, item)),
    R.propOr([], 'STORES')
  )(product)
  if (remain === 0) {
    return {
      name: 'not_in_stock',
      title: 'Товар отсутствует на складе'
    }
  }

  const basket = parseInt(R.pathOr(0, basketPath, product), 10)
  if (basket > 0 && basket >= remain) {
    return {
      name: 'max_amount',
      title: 'В корзине находится максимальное количество данного товара'
    }
  }

  return false
}

const getErrorsFromServer = (errors = {}, item = {}) =>
  R.propOr(null, item.ID, errors)

const getIsValid = (items = [], index = 0, product = {}) => {
  const { code } = items[index]
  if (!code || !product || product.isFailed) {
    return false
  }

  let correctIndex = index
  items.forEach((item, i) => {
    if (item.code === code && item.isValid) {
      correctIndex = i
    }
  })
  return correctIndex === index
}

const correctSameCodes = items =>
  items.map(item => {
    const { code, error } = item
    if (
      error &&
      error.name === 'already_in_list' &&
      byCode(items, code).length === 1
    ) {
      return {
        ...item,
        isValid: true,
        error: false
      }
    }

    return item
  })

const correctAmount = (
  items,
  products,
  basketPath = ['BASKETS', 'MAIN', 0, 'QUANTITY']
) =>
  items.map(item => {
    if (!item.isValid) {
      return {
        ...item,
        amount: emptyItem.amount
      }
    }
    if (item.amount) {
      return item
    }

    const product = products[item.code]
    const basket = parseInt(R.pathOr(0, basketPath, product), 10) || 0
    const storeParams = R.compose(
      R.head,
      R.filter(itm => R.propEq('IS_MAIN', true, itm)),
      R.propOr([], 'STORES')
    )(product)

    const { min, max } = getLimitsCount(storeParams)
    const maxCount = max - basket
    return {
      ...item,
      amount: maxCount >= min ? R.clamp(min, maxCount, item.amount) : maxCount
    }
  })

// TODO бэк не работает с запросом закладки
// ${bookmark ? `&filter[BOOKMARK]=${bookmark}` : ''}
const requestByCode =
  ({ clientApi }) =>
    ({ index, code, bookmark }) =>
      clientApi
        .get(`/v3/catalog/main/products/catalog/?filter[CODE_1C]=${code}`, {
          params: {
            contractor_id: clientApi.getContractorId()
          }
        })
        .then(data =>
          loadByCode({
            data,
            index,
            code,
            bookmark
          })
        )
        .catch(data =>
          loadByCode({
            data,
            index,
            code,
            bookmark
          })
        )

const handleLoadByCode = (state, { data, index, code, bookmark }) => {
  const items = R.values(R.path(['data', 'response', 'ITEMS'])(data))
  const formattedItems = R.map(item => item, items)
  const result = formattedItems.length ? formattedItems[0] : { isFailed: true }
  const effects = []
  effects.push(Effects.call(checkErrors, { index, bookmark }))
  const storeMain = R.compose(
    R.head,
    R.filter(item => R.propEq('IS_MAIN', true, item)),
    R.propOr([], 'STORES'),
    R.head
  )(items)
  const { min, max } = getLimitsCount(storeMain)
  const amount = R.clamp(Math.min(min, max), Math.max(min, max), 1)
  const store = R.prop('CODE', storeMain)
  const code1c = R.path([0, 'CODE_1C'], items)
  const indexItem = R.findLastIndex(R.propEq('code', code1c))(state.items)

  let newState = { ...state }

  newState = R.set(
    R.lensPath(['items', indexItem]),
    { amount, store, remain: max, code: code1c },
    newState
  )
  return loop(
    {
      ...newState,
      itemsByCode: {
        ...newState.itemsByCode,
        [code]: result
      }
    },
    Effects.batch(effects)
  )
}

const handleUpdateItem = (state, data, { clientApi }) => {
  const { index, bookmark, ...payload } = data
  if (!state.items[index]) {
    return { ...state }
  }

  const { itemsByCode } = state
  const items = [].concat(state.items)

  items[index] = {
    ...items[index],
    ...payload,
    isValid: false,
    isLoading: false
  }
  if (data.amount !== undefined) {
    const newState = {
      ...state
    }
    const newItems = newState.items.slice(0)
    newItems[index].amount = data.amount
    newState.items = newItems
    return newState
  }

  items[index].amount = 0
  let effects = Effects.call(checkErrors, { index, bookmark })
  const { code } = items[index]
  if (code.length === CODE_LENGTH && !itemsByCode[code]) {
    items[index].isLoading = true
    effects = Effects.promise(requestByCode({ clientApi }), {
      index,
      code,
      bookmark
    })
  }

  return loop(
    {
      ...state,
      items
    },
    effects
  )
}

const handleRemoveItem = (state, index) =>
  loop(
    {
      ...state,
      items: correctSameCodes(R.remove(index, 1, state.items))
    },
    Effects.call(insertEmptyItem)
  )

const requestCheckErrors =
  ({ clientApi }) =>
    ({ id, amount, index }) =>
      clientApi
        .get(`/v3/sale/basket/main/check/?products[${id}][QUANTITY]=${amount}`, {
          params: {
            contractor_id: clientApi.getContractorId(),
            type: 'catalog',
            action: 'check'
          }
        })
        .then(({ data }) => fetchCheckErrorsSuccess({ data, index }))
        .catch(({ data }) => fetchCheckErrorsSuccess({ data, index }))

const handleFetchCheckErrors = (state, payload, { clientApi }) => {
  const { items, itemsByCode } = state
  const { index, amount } = payload
  const itemCode = R.pathOr('', [index, 'code'], items)
  const item = itemsByCode[itemCode]

  if (!item) return { ...state }

  return loop(
    {
      ...state
    },
    Effects.promise(requestCheckErrors({ clientApi }), {
      amount,
      id: item.ID,
      index
    })
  )
}

const handleFetchCheckErrorsSuccess = (state, payload = {}) => {
  const items = [].concat(state.items)
  const { itemsByCode } = state
  const { index, data } = payload
  const itemCode = R.pathOr('', [index, 'code'], items)
  const errors = R.propOr({}, 'warnings', data)
  const error = getErrorsFromServer(errors, itemsByCode[itemCode])

  if (error) {
    items[index] = {
      ...items[index],
      error: {
        title: error
      }
    }
  } else {
    items[index] = {
      ...items[index],
      error: undefined
    }
  }

  return {
    ...state,
    items
  }
}

const handleCheckErrors = (state, { index, bookmark = 0 }) => {
  let items = [].concat(state.items)
  const { code } = items[index]
  const product = state.itemsByCode[code]
  const basketPath = [
    'BASKETS',
    bookmark ? 'BOOKMARK' : 'MAIN',
    bookmark,
    'QUANTITY'
  ]

  items[index] = {
    ...items[index],
    isLoading: false,
    isValid: getIsValid(items, index, product),
    error: getError(items, index, product, basketPath)
  }

  items = correctAmount(correctSameCodes(items), state.itemsByCode, basketPath)

  const emptyIndex =
    code === '' ? index : R.findLastIndex(R.propEq('code', ''))(items)
  if (emptyIndex !== -1) {
    items = items.filter((item, i) => i === emptyIndex || item.code)
  }

  return loop(
    {
      ...state,
      items
    },
    Effects.call(insertEmptyItem)
  )
}

const handleInsertEmptyItem = state => {
  const items = [].concat(state.items)
  if (
    R.findIndex(R.propEq('code', ''), items) === -1 &&
    R.all(R.propEq('isValid', true), items)
  ) {
    items.push(emptyItem)
  }
  return {
    ...state,
    items
  }
}

const handleReset = () => ({ ...initialState })

const reducer = createReducer(on => {
  on(updateItem, handleUpdateItem)
  on(removeItem, handleRemoveItem)
  on(insertEmptyItem, handleInsertEmptyItem)

  on(fetchCheckErrors, handleFetchCheckErrors)
  on(fetchCheckErrorsSuccess, handleFetchCheckErrorsSuccess)
  on(checkErrors, handleCheckErrors)

  on(loadByCode, handleLoadByCode)

  on(reset, handleReset)
}, initialState)

export default reducer
