import { connect } from 'react-redux'

import MenuCatalogMobile from 'components/MenuCatalogMobile'
import { getBookmarks } from 'redux/modules/bookmarks'
import {
  setCatalogHeight,
  selectCatalogItem
} from 'redux/modules/catalog'
import { isMobileSelector, isTabletSelector } from 'redux/modules/device'
import { getAccess } from 'redux/modules/personal'
import { sectionsMainSelector } from 'redux/modules/sections'
import {
  bookmarkSelector,
  catalogTypeSelector,
  isBookmarkSelector
} from 'redux/modules/settings'
import { isAuthSelector } from 'redux/modules/token'

export default connect(
  ({
    device,
    bookmarks,
    settings,
    token,
    personal,
    sections,
    catalog: { selectedItemId, catalogHeight }
  }) => {
    const bookmark = bookmarkSelector(settings)
    const catalogType = catalogTypeSelector(settings)
    return {
      catalog: sectionsMainSelector({ catalogType, sections, bookmark }),
      bookmarkData: getBookmarks(bookmarks),
      isBookmark: isBookmarkSelector(settings),
      bookmark,
      bookmarkAccess: getAccess(personal, 'BOOKMARKS'),
      selectedItemId,
      isAuth: isAuthSelector(token),
      catalogHeight,
      isTablet: isTabletSelector(device),
      isMobile: isMobileSelector(device)
    }
  },
  {
    setCatalogHeight,
    selectCatalogItem
  }
)(MenuCatalogMobile)
