import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Mark = loadable(() =>
  import(/* webpackChunkName: "Mark" */ 'pages/Mark/Mark')
)

export default asyncConnect([
  {
    key: 'pageMark',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'mark' })
      return updateData.update()
    }
  }
])(Mark)
