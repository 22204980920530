/* eslint-disable complexity */
import React, { useEffect, useState } from 'react'

import cx from 'classnames'
import { bool, func, string } from 'prop-types'
import { defaultTo, length } from 'ramda'
import { connect } from 'react-redux'

import Icon from 'components/Icon'
import { cartAdd, cartUpdate } from 'redux/modules/productList'
import { catalogTypeSelector } from 'redux/modules/settings'
import Button from 'UI/Button'
import Input from 'UI/Input'
import Spacing from 'UI/Spacing'

import s from './CartChange.scss'

const CartAdd = ({
  id,
  name,
  isWide,
  isAdd,
  onCartAdd,
  onCartUpdate,
  onClose,
  className,
  catalog = 'main'
}) => {
  const [value, setValue] = useState('')

  const [isAction, setIsAction] = useState(false)

  const onChange = () => e => {
    setValue(e.target.value)
  }

  const handleSave = () => () => {
    if (id) {
      onCartUpdate({ value: id, title: value, catalog })
    } else {
      onCartAdd({ title: value, catalog })
    }
    setIsAction(false)
    setValue('')
    onClose()
  }

  const handleClose = () => () => {
    setIsAction(false)
    setValue('')
    onClose()
  }

  const onSetAction = () => () => {
    setIsAction(true)
  }

  useEffect(() => {
    setValue(name)
  }, [])

  const isDisabled = length(defaultTo('', value)) === 0

  if (!isAction && isAdd) {
    return (
      <div
        role='presentation'
        className={cx(s.cart, s.action, s.actionEmpty, {
          [s.cartWide]: isWide,
          [className]: className
        })}
        onClick={onSetAction()}
      >
        <div className={s.act}>
          <div className={s.addIcon}>
            <Icon icon='add-basket'
              className={s.icon} />
          </div>
          <div className={s.addName}>Добавить новую</div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={cx(s.cart, s.action, {
        [s.cartWide]: isWide,
        [className]: className
      })}
    >
      <Input value={value}
        onChange={onChange()}
        maxLength={32} />
      <div className={s.desc}>
        {id ? 'Замените имя корзины' : 'Создайте новую корзину'}
      </div>
      <div className={s.buttons}>
        <Button
          color='whiteBlue'
          size='small'
          onClick={handleClose()}
        >
          Отмена
        </Button>
        <Spacing
          margin='left'
          size='small'
        >
          <Button
            color='blue'
            size='small'
            disabled={isDisabled}
            onClick={handleSave()}
            stretched
          >
            {id ? 'Сохранить' : 'Добавить'}
          </Button>
        </Spacing>
      </div>
    </div>
  )
}

CartAdd.propTypes = {
  onCartAdd: func,
  onCartUpdate: func,
  onClose: func,
  isAdd: bool,
  isWide: bool,
  id: string,
  name: string,
  catalog: string,
  className: string
}

CartAdd.defaultProps = {
  onCartAdd: () => {},
  onCartUpdate: () => {},
  onClose: () => {},
  isAdd: false,
  isWide: false
}

export default connect(
  ({ settings }) => ({
    catalog: catalogTypeSelector(settings)
  }),
  {
    onCartAdd: cartAdd,
    onCartUpdate: cartUpdate
  }
)(CartAdd)
