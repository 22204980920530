import { path } from 'ramda'
import { createAction, createReducer } from 'redux-act'
import { loop, Effects } from 'redux-loop'

import getErrorMessage from 'helpers/getErrorMessage'

const initialState = {
  isLoading: false,
  isLoaded: false,
  firstCall: true,
  types: {}
}

export {
  getIsLoaded,
  getIsLoading,
  getBanners,
  getBannerType,
  bannerMenuSelector
} from './selector'

export const resetBanners = createAction('banners/RESET_BANNERS')
export const fetchBanners = createAction('banners/GET_BANNERS')
export const fetchBannersSuccess = createAction('banners/GET_BANNERS_SUCCESS')
export const fetchBannersFailure = createAction('banners/GET_BANNERS_FAILURE')

export const request =
  ({ clientApi }) =>
    (params = {}) =>
      clientApi
        .get('/v2/marketing/promo/', {
          params: {
            contractor_id: clientApi.getContractorId(),
            ...params
          }
        })
        .then(fetchBannersSuccess)
        .catch(fetchBannersFailure)

const handleFetchBanners = (
  state,
  { params, discardPreviousBanners },
  { clientApi }
) => {
  const effects = []

  if (discardPreviousBanners) effects.push(Effects.call(resetBanners))
  effects.push(Effects.promise(request({ clientApi }), params))

  return loop(
    {
      ...state,
      isLoading: true,
      isLoaded: false
    },
    Effects.batch(effects)
  )
}

const handleFetchBannersSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: true,
  firstCall: false,
  types: {
    ...state.types,
    ...path(['data', 'response'], payload)
  }
})

const handleFetchBannersFailure = (state, payload) => ({
  ...state,
  isLoading: false,
  isLoaded: false,
  error: getErrorMessage(payload)
})

const handleResetBanners = state => ({
  ...state,
  types: initialState.types
})

const reducer = createReducer(on => {
  on(fetchBanners, handleFetchBanners)
  on(fetchBannersSuccess, handleFetchBannersSuccess)
  on(fetchBannersFailure, handleFetchBannersFailure)
  on(resetBanners, handleResetBanners)
}, initialState)

export default reducer
