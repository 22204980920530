/* eslint-disable complexity */
import React, { useCallback, useState, useMemo, useEffect } from 'react'

import { array, func, string } from 'prop-types'
import {
  add,
  compose,
  divide,
  drop,
  equals,
  filter,
  forEach,
  gt,
  inc,
  isEmpty,
  length,
  lt,
  lte,
  map,
  mean,
  multiply,
  pathOr,
  prop,
  take
} from 'ramda'

import Select from 'UI/Select'
import Text from 'UI/Text'

import s from './ProductReview.scss'
import ReviewList from './ReviewList'
import StatisticsBlock from './StatisticsBlock'

const NUMBER_100 = 100
const STEP = 10

const ProductReview = ({
  feedback,
  fetchProductReview,
  productId,
  ratingTypes,
  sortTypes
}) => {
  const noFeedback = isEmpty(feedback)
  const [sortType, setSortType] = useState(pathOr('', ['0', 'value'], sortTypes))
  const [ratingType, setRatingType] = useState(pathOr('', ['0', 'value'], ratingTypes))
  const [stepLoad, setStepLoad] = useState(STEP)

  const onSetSortType = useCallback(value => setSortType(value), [])
  const onSetRatingType = useCallback(value => setRatingType(value), [])

  const newParams = useMemo(
    () => ({
      sort: sortType,
      rating: ratingType
    }),
    [ratingType, sortType]
  )

  const showButton = useMemo(
    () => lte(stepLoad, length(feedback)),
    [feedback, stepLoad]
  )

  const feedbackPart = useMemo(
    () => take(stepLoad, feedback),
    [feedback, stepLoad]
  )
  const ratingData = useMemo(() => {
    const meanRating = noFeedback ? 0 : compose(mean, map(prop('rating')))(feedback)

    const totalVotes = compose(
      length,
      filter(i => gt(i, 0)),
      map(prop('rating'))
    )(feedback)

    let rating = drop(1, ratingTypes)

    forEach(i => {
      const itemRating = prop('rating', i)
      rating = map(
        star =>
          equals(prop('type', star), itemRating)
            ? { ...star, count: inc(prop('count', star)) }
            : { ...star },
        rating
      )
    }, feedback)

    rating = map(
      i => ({
        ...i,
        progress: multiply(divide(prop('count', i), totalVotes), NUMBER_100)
      }),
      rating
    )

    return {
      totalVotes,
      meanRating,
      ratingInfo: rating
    }
  }, [feedback, ratingTypes])

  useEffect(() => {
    fetchProductReview({
      ...newParams,
      form: 'productreview',
      productId,
      status: 'Разрешено'
    })
  }, [fetchProductReview, newParams, productId])

  const handleClickMore = useCallback(() => {
    setStepLoad(add(stepLoad, STEP))
  }, [stepLoad])

  return (
    <div>
      <div className={s.filter}>
        <div className={s.filterItem}>
          <span className={s.selectName}>Рейтинг:</span>
          <div className={s.select}>
            <Select
              onSelect={onSetRatingType}
              options={ratingTypes}
              selectedValue={ratingType}
            />
          </div>
        </div>
        <div className={s.filterItem}>
          <span className={s.selectName}>Сортировать:</span>
          <div className={s.select}>
            <Select
              onSelect={onSetSortType}
              options={sortTypes}
              selectedValue={sortType}
            />
          </div>
        </div>
      </div>

      <div className={s.review}
        itemScope
        itemType='http://schema.org/Rating'>
        {noFeedback ? (
          <Text semibold>
            Журнал отзывов пуст
          </Text>
        ) : (
          <ReviewList feedbackPart={feedbackPart} />
        )}
        <div className={s.statistics}>
          {lt(prop('totalVotes', ratingData), 1) ? (
            ''
          ) : (
            <StatisticsBlock ratingData={ratingData} />
          )}
        </div>
      </div>

      {showButton && (
        <button type='button'
          onClick={handleClickMore}
          className={s.button}>
          Загрузить еще
        </button>
      )}
    </div>
  )
}

ProductReview.propTypes = {
  feedback: array,
  fetchProductReview: func,
  productId: string,
  ratingTypes: array,
  sortTypes: array
}

export default ProductReview
