import loadable from '@loadable/component'
import { asyncConnect } from 'redux-connect'

const Storage = loadable(() =>
  import(/* webpackChunkName: "Storage" */ 'pages/Storage/Storage')
)

export default asyncConnect([
  {
    key: 'pageStorage',
    promise: ({ helpers: { updateData } }) => {
      updateData.set({ view: 'storage' })
      return updateData.update()
    }
  }
])(Storage)
