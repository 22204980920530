/* eslint-disable complexity */
import React, { PureComponent } from 'react'

import { replace } from 'connected-react-router'
import hoistStatics from 'hoist-non-react-statics'
import {
  func,
  string,
  array,
  object,
  bool,
  number,
  oneOfType
} from 'prop-types'
import R from 'ramda'
import { connect } from 'react-redux'
import { createSelector } from 'reselect'

import { getBasket } from 'helpers/products'
import { download } from 'redux/modules/downloadModal'
import { setTarget, deleteTarget } from 'redux/modules/loyalty'
import {
  getItemById,
  getSectionItemCountById,
  getLoyaltySectionItemCountById,
  cartSelector,
  setRemovedItems
} from 'redux/modules/productList'
import {
  updateGroup,
  getItemById as getItemByIdNew,
  getItemCountById,
  clearCheckedItems,
  getItemMinById,
  productSelector,
  amountSelector,
  remainSelector,
  activeStoreSelector
} from 'redux/modules/products'
import { downloadBasket, downloadProducts } from 'redux/modules/sender'

const emptyObject = {}
const emptyArray = []

export const catalogSelector = createSelector(
  ({ settings }) => R.prop('mode', settings),
  ({ productList }) => R.prop('basketCatalog', productList),
  ({ type = 'section' }) => type === 'basket',
  (modeSettings, modeProduct, isBasketType) => {
    if (modeSettings === 'loyalty') {
      return 'loyalty'
    }
    if (modeSettings === 'bookmark') {
      return 'bookmark'
    }

    if (modeProduct === '918ok' && isBasketType) {
      return '918ok'
    }
    if (modeProduct === 'delay' && isBasketType) {
      return 'delay'
    }

    return 'main'
  }
)

export default WrappedComponent => {
  @connect(
    (
      { productList, products, loyalty, personal, router, settings },
      { id, type }
    ) => ({
      products,
      productList,
      cartId: cartSelector(productList),
      basketType: catalogSelector({ settings, productList, type }),
      catalog: catalogSelector({ settings, productList, type }),
      isLoyalty: catalogSelector({ settings, productList, type }) === 'loyalty',
      isUpdating: R.pathOr(
        emptyObject,
        ['itemsOrderById', id, 'isUpdating'],
        products
      ),
      targetId: R.pathOr(undefined, ['target', 'item', 'ID'], loyalty),
      addingToTarget: R.pathOr(emptyObject, ['target', 'isLoading'], loyalty),
      page: Number(R.pathOr(1, ['location', 'query', 'p'], router)),
      query: R.pathOr({}, ['location', 'query'], router),
      onlyMultiple: R.propOr(false, 'onlyMultiple', personal)
    }),
    {
      downloadBasket,
      downloadProducts,
      download,
      setTarget,
      deleteTarget,
      replace,
      setRemovedItems,
      updateGroup,
      clearCheckedItems
    }
  )
  class BasketUtils extends PureComponent {
    static displayName = `BasketUtils(${
      WrappedComponent.displayName || WrappedComponent.name
    })`

    static propTypes = {
      updateGroup: func,
      fetchBasket: func,
      replace: func,
      downloadBasket: func,
      downloadProducts: func,
      download: func,
      setTarget: func,
      deleteTarget: func,
      setRemovedItems: func,
      clearCheckedItems: func,
      checked: bool,
      bookmark: number,
      isLoyalty: bool,
      isBasket: bool,
      isBookmark: bool,
      isNewApi: bool,
      onlyMultiple: bool,
      addingToTarget: bool,
      basketType: oneOfType([string, array]),
      id: string,
      targetId: string,
      cartId: number,
      amount: number,
      groupType: string,
      catalog: string,
      type: string,
      productList: object,
      products: object,
      items: array,
      itemsAll: array,
      selectedItemMap: object,
      isUpdating: object,
      query: object,
      page: number
    }

    static defaultProps = {
      type: 'section',
      catalog: 'main',
      bookmark: 0,
      checked: false,
      isLoyalty: false,
      isBasket: false,
      isBookmark: false,
      addingToTarget: false,
      isNewApi: false,
      onlyMultiple: false,
      items: emptyArray,
      itemsAll: emptyArray,
      fetchBasket: () => {},
      download: () => {},
      setRemovedItems: () => {},
      updateGroup: () => {},
      page: 1
    }

    getFormattedItems = items => {
      const { isLoyalty, isNewApi } = this.props
      const store = isNewApi ? this.props.products : this.props.productList
      const getItem = isNewApi ? getItemByIdNew : getItemById
      return items.map(itemId => {
        const item = getItem(itemId, store)
        let amount
        if (isNewApi) {
          amount = isLoyalty
            ? getLoyaltySectionItemCountById(itemId, this.props.productList)
            : getItemCountById(itemId, store)
        } else {
          amount = isLoyalty
            ? getLoyaltySectionItemCountById(itemId, this.props.productList)
            : getSectionItemCountById(itemId, this.props.productList)
        }
        return {
          item,
          amount
        }
      })
    }

    setTarget = (id, count) => {
      this.props.setTarget({ id, count })
    }

    downloadTechDoc =
      (type = 'catalog') =>
        () => {
          const { catalog = 'main', bookmark, id, productList } = this.props
          const order = R.pathOr('', ['basketParams', 'order'])(productList)
          const sort = R.pathOr('', ['basketParams', 'sort'])(productList)
          const params = { value: bookmark, sort, order}
          const link =
          type === 'basket'
            ? `/v3/sale/basket/${catalog}/techdoc/`
            : `/v3/catalog/main/product/${id}/techdoc/`
          return this.props.download(link, {
            type: 'techdoc',
            method: 'get',
            params
          })
        }

    switchItemInGroup = options =>
      this.props.updateGroup({
        ...options,
        settings: {
          skipWarnings: true,
          ...options.settings
        }
      })

    pushTo(itemsObj, type, id, params, settings = {}) {
      const items = [].concat(itemsObj)
      const isMove = params && params.action === 'move'
      const productsToDelay = {}
      const products = items.reduce((acc, { item, amount, store, remain }) => {
        if (!isMove && type === 'basket' && remain === 0) {
          productsToDelay[item.ID] = { QUANTITY: amount, STORE: store }
          return acc
        }
        return {
          ...acc,
          [item.ID]: {
            QUANTITY: amount,
            STORE: store,
            // товары лояльность - всегда за баллы
            FOR_POINTS:
              R.propOr(false, 'isForPoints', item) || type === 'loyaltyBasket'
          }
        }
      }, {})
      const effects = []
      if (!R.isEmpty(products)) {
        effects.push(
          this.switchItemInGroup({
            id,
            type,
            items: products,
            params,
            settings
          })
        )
      }

      if (!R.isEmpty(productsToDelay)) {
        effects.push(
          this.switchItemInGroup({
            id,
            type,
            items: productsToDelay,
            params,
            settings: {
              ...settings,
              catalog: 'delay'
            }
          })
        )
      }

      return Promise.all(effects)
    }

    deleteTarget = () => {
      this.props.deleteTarget()
    }

    formattedItems = ({
      items,
      products,
      productList,
      isLoyalty = false,
      catalog = 'main'
    }) =>
      items.map(itemId => {
        const item = productSelector({ products, productList }, { id: itemId })
        const amount = isLoyalty
          ? getLoyaltySectionItemCountById(itemId, productList)
          : amountSelector(products, { id: itemId })

        const activeStore = activeStoreSelector(
          { products, productList },
          { catalog, id: itemId }
        )
        const remain = R.propOr(0, activeStore, remainSelector(item))
        return {
          item,
          amount,
          store: activeStore,
          remain
        }
      })

    pushToBasket = (...args) => {
      const { isLoyalty, page, id, products, productList, catalog, cartId } =
        this.props
      const items = typeof args[0] === 'string' ? args : [id]
      if (!items[0]) {
        return
      }

      let type = 'basket'
      const params = {
        value: cartId,
        page,
        full: 'items'
      }

      if (isLoyalty) {
        type = 'loyaltyBasket'
      }

      const formattedItems = this.formattedItems({
        items,
        products,
        productList,
        catalog,
        isLoyalty
      })
      this.pushTo(formattedItems, type, 0, params).then(() => {
        if (typeof args[0] === 'string') {
          this.unselectAll()
        }
      })
    }

    changeByAnalog = ({ original, catalog = 'main', bookmark = 0 }) => {
      const { page, id } = this.props
      const type = 'basket'
      const items = this.getFormattedItems([id, original])
      const params = { action: 'replace', page }
      const settings = {
        catalog
      }
      if (bookmark && bookmark > 0) {
        params.value = bookmark
      }
      const itemMap = items.reduce(
        (acc, { amount, item: { ID } }) => ({
          ...acc,
          [ID]: ID === original ? 0 : amount
        }),
        {}
      )
      return this.switchItemInGroup({
        items: itemMap,
        type,
        params,
        settings
      })
    }

    pushToBookmark = (...args) => {
      const { id, productList, products, bookmark, page } = this.props
      const items = typeof args[0] === 'string' ? args : [id]
      if (!items[0]) {
        return
      }
      const formattedItems = this.formattedItems({
        items,
        productList,
        products,
        catalog: 'bookmark'
      })
      const params = {
        value: bookmark,
        page
      }
      const settings = {
        catalog: 'bookmark'
      }
      this.pushTo(
        formattedItems,
        'basket',
        `bookmark_${bookmark}`,
        params,
        settings
      ).then(() => {
        if (typeof args[0] === 'string') {
          this.unselectAll()
        }
      })
    }

    pushToDelay = (...args) => {
      const { isNewApi, id, productList, products } = this.props
      const items = typeof args[0] === 'string' ? args : [id]
      const store = isNewApi ? products : productList
      const getItem = isNewApi ? getItemByIdNew : getItemById
      const formattedItems = items.map(itemId => ({
        item: getItem(itemId, store),
        amount:
          getItemMinById(itemId, store) === 0
            ? 1
            : getItemMinById(itemId, store)
      }))
      this.pushTo(formattedItems, 'basket', 'delay', { catalog: 'delay' })
    }

    pushToFavorite = (...args) => {
      const { isNewApi, id, productList, products } = this.props
      const items = typeof args[0] === 'string' ? args : [id]
      const store = isNewApi ? products : productList
      const getItem = isNewApi ? getItemByIdNew : getItemById
      const formattedItems = items.map(itemId => ({
        item: getItem(itemId, store),
        amount: 1
      }))

      this.pushTo(formattedItems, 'favorite', 0)
    }

    pushToCompare = (...args) => {
      const { isNewApi, id, productList, products } = this.props
      const items = typeof args[0] === 'string' ? args : [id]
      const store = isNewApi ? products : productList
      const getItem = isNewApi ? getItemByIdNew : getItemById
      const formattedItems = items.map(itemId => ({
        item: getItem(itemId, store),
        amount: 1
      }))

      this.pushTo(formattedItems, 'compare', 0)
    }

    removeFromBasket = (productId, params = {}) => {
      const {
        isLoyalty,
        selectedItemMap,
        catalog,
        isBookmark,
        bookmark,
        productList,
        page,
        id
      } = this.props
      const productsList = id ? [productId || id] : Object.keys(selectedItemMap)
      if (productsList.length === 0) {
        return
      }

      const type = isLoyalty ? 'loyaltyBasket' : 'basket'
      const items = productsList.reduce(
        (acc, itemId) => ({ ...acc, [itemId]: false }),
        {}
      )
      const pathToBasket = ['BASKETS', R.toUpper(catalog), bookmark, 'QUANTITY']
      const restoreItems = productsList.reduce(
        (acc, itemId) => ({
          ...acc,
          [itemId]: parseInt(
            R.path(R.flatten(['itemsById', itemId, pathToBasket]), productList),
            10
          )
        }),
        {}
      )
      this.props.setRemovedItems(restoreItems)
      let idCatalog = catalog
      params.value = bookmark
      if (isBookmark) {
        idCatalog = `bookmark_${bookmark}`
      }
      if (type === 'basket') {
        params.full = 'full'
      }
      params.page = page
      this.switchItemInGroup({
        id: idCatalog,
        type,
        items,
        params,
        settings: {
          catalog
        }
      }).then(() => {
        this.props.replace({ pathname: `/basket/${catalog}/${bookmark}` })
      })

      this.props.clearCheckedItems(false)
      this.unselectAll()
    }

    removeFromFavorite = (...args) => {
      const { groupType, items } = this.props
      const productsList = typeof args[0] === 'string' ? args : [this.props.id]
      return this.switchItemInGroup({
        id: 0,
        type: 'favorite',
        items: productsList.reduce(
          (acc, id) => ({
            ...acc,
            [id]: false
          }),
          {}
        )
      }).then(() => {
        if (groupType === 'favorite' || R.isEmpty(items)) {
          const query = R.mergeDeepRight(this.props.query, { p: 1 })
          this.props.replace({ pathname: '/favorite', query })
        }
      })
    }

    removeFromCompare = (...args) => {
      const { groupType } = this.props
      const productsList = typeof args[0] === 'string' ? args : [this.props.id]

      this.switchItemInGroup({
        id: 0,
        type: 'compare',
        items: productsList.reduce(
          (acc, id) => ({
            ...acc,
            [id]: false
          }),
          {}
        )
      }).then(() => {
        if (groupType === 'compare') {
          const { query } = this.props
          this.props.replace({ pathname: '/compare', query })
        }
        this.unselectAll()
      })
    }

    moveProducts = params => () => {
      const { selectedItemMap, page, id, catalog, bookmark } = this.props

      const prepareParams = { ...params, page }
      const productsList = id ? [id] : Object.keys(selectedItemMap)
      if (productsList.length === 0) {
        return
      }
      this.pushTo(this.getFormattedItems(productsList), 'basket', 0, {
        ...prepareParams,
        action: 'move'
      }).then(() => {
        this.unselectAll()
        this.props.replace({ pathname: `/basket/${catalog}/${bookmark}` })
      })
    }

    downloadExcelBasket = data => {
      const { catalog } = this.props
      this.props.downloadBasket({ ...data, type: catalog })
      this.unselectAll()
    }

    downloadExcel = (...args) => {
      const productsList = typeof args[0] === 'string' ? args : [this.props.id]
      const params = productsList
        .map(item => `filter[products][]=${item}`)
        .join('&')
      this.props.downloadProducts(params)
      this.unselectAll()
    }

    unselectAll = () => {
      this.props.clearCheckedItems(false)
    }

    render() {
      const { productList, id, isUpdating, catalog, bookmark } = this.props
      const item = getItemById(id, productList)
      const props = R.omit(
        ['products', 'productList', 'itemsUpdating'],
        this.props
      )

      return (
        <WrappedComponent
          {...props}
          pushToBasket={this.pushToBasket}
          pushToDelay={this.pushToDelay}
          pushToFavorite={this.pushToFavorite}
          pushToCompare={this.pushToCompare}
          pushToBookmark={this.pushToBookmark}
          changeByAnalog={this.changeByAnalog}
          removeFromBasket={this.removeFromBasket}
          removeFromBookmark={this.removeFromBookmark}
          moveProducts={this.moveProducts}
          removeFromFavorite={this.removeFromFavorite}
          removeFromCompare={this.removeFromCompare}
          downloadExcel={this.downloadExcel}
          downloadExcelBasket={this.downloadExcelBasket}
          getTechDoc={this.downloadTechDoc}
          switchItemInGroup={this.switchItemInGroup}
          setTarget={this.setTarget}
          deleteTarget={this.deleteTarget}
          inBasket={!!getBasket(item, [catalog, bookmark])}
          delay={getBasket(item, ['delay', 0])}
          inFavorite={item.FAVORITE === 'Y' || item.FAVORITE === true}
          inCompare={item.COMPARE === 'Y' || item.COMPARE === true}
          checked={this.props.checked}
          isUpdating={isUpdating}
          isTarget={this.props.targetId === this.props.id}
          isAddingToTarget={this.props.addingToTarget}
        />
      )
    }
  }

  return hoistStatics(BasketUtils, WrappedComponent)
}
