/* eslint-disable import/no-import-module-exports */
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React from 'react'

import { loadableReady } from '@loadable/component'
import * as Sentry from '@sentry/react'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory, createMemoryHistory } from 'history'
import { propOr } from 'ramda'
import cookie from 'react-cookie'
import { hydrateRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { Provider as ProviderTunnel } from 'react-tunnel-16'
import { ReduxAsyncConnect } from 'redux-connect'

import config from 'config'
import { initClientApi } from 'helpers/apiClientNew'
import initUpdateData from 'helpers/updateData'
import configureStore from 'redux/create'

import routes from './routes'

if (config.sentry.isActive) {
  Sentry.init({
    dsn: config.sentry.dsn,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 0.1
  })
}

const history = typeof window === 'undefined'
  ? createMemoryHistory({ initialEntries: ['/'] })
  : createBrowserHistory()
cookie.clientDate = Date.now()
const data = propOr({}, '__data', window)
const apiAuth = propOr({}, 'apiAuth', data)
const router = propOr({}, 'router', data)
const clientApi = initClientApi({ cookie, apiAuth, router })
clientApi.clientDate = Date.now()
const updateData = initUpdateData({ clientApi })

const store = configureStore(data, history, { clientApi, cookie, updateData })
const render = () => hydrateRoot(
  document.getElementById('content'),
  <Provider
    store={store}
    key='provider'
  >
    <ConnectedRouter history={history}>
      <ProviderTunnel
        /* eslint-disable-next-line */
        provide={() => ({ helpers: { clientApi, cookie, updateData } })}
      >
        <ReduxAsyncConnect
          routes={routes}
          helpers={{ clientApi, cookie, updateData }}
        />
      </ProviderTunnel>
    </ConnectedRouter>
  </Provider>
)

loadableReady(() => {
  render()
})
