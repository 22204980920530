import React from 'react'

import pt from 'prop-types'
import { pathOr } from 'ramda'
import { connect } from 'react-redux'

import config from 'config'
import Modal from 'containers/Modal'
import { setAnalyticsProps } from 'helpers/metaTags'
import { showModal } from 'redux/modules/modal'
import {
  currentContractorGuidSelector,
  currentContractorNameSelector,
  getUserEmail
} from 'redux/modules/personal'
import { isAuthSelector } from 'redux/modules/token'
import Text from 'UI/Text'
import Title from 'UI/Title'

import s from './FormIo.scss'
import Form from './FormWrapper'

const ModalForm = ({
  path,
  contractorGuid,
  contractorName,
  userMail,
  userAuth,
  onShowModal,
  onClose = () => {},
  selectedText = ''
}) => {
  const [showError, setErrorState] = React.useState(false)
  React.useEffect(() => {
    if (!userAuth) return
    onShowModal('foundmistakeForm')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const onReady = () => formInstance => {
    const instanceContractor = formInstance.getComponent('contractor')
    const instanceContractorName = formInstance.getComponent('contractor_name')
    const instanceUrl = formInstance.getComponent('url')
    const instanceUserMail = formInstance.getComponent('user_mail')
    const instanceSelectedText = formInstance.getComponent('selectedText')
    const url = typeof window === 'undefined' ? `${config.domain}${path}` :  window.location.href

    instanceContractor.setValue(contractorGuid)
    instanceContractorName.setValue(contractorName)
    instanceUrl.setValue(url)
    instanceUserMail.setValue(userMail)
    instanceSelectedText.setValue(selectedText)

    setErrorState(false)
    if(!formInstance.checkValidity()) {
      formInstance.redraw()
      setErrorState(true)
    }
  }

  return (
    <Modal id='foundmistakeForm'
      onClose={onClose}>
      <div
        className={s.container}
        {...setAnalyticsProps({
          type: 'action',
          group: 'notification',
          action: 'textError',
          pos: 'view'
        })}
      >
        <Title size={18}>Нашли ошибку в тексте?</Title>
        <Form
          formReady={onReady()}
          submitText='Спасибо! Мы получили Ваше сообщение, исправим в ближайшее время'
          onClose={onClose}
          src={`${config.formIo.host}/foundmistake`}
        />

        {showError && (
          <span className={s.mistakeErrorBlock}>
            <Text color='red'>
              Вы выбрали слишком большой объем текста. Пожалуйста, уменьшите
              количество слов до 120 символов
            </Text>
          </span>
        )}
      </div>
    </Modal>

  )
}

ModalForm.propTypes = {
  contractorGuid: pt.string,
  contractorName: pt.string,
  userMail: pt.string,
  userAuth: pt.bool,
  path: pt.string,
  onClose: pt.func,
  onShowModal: pt.func,
  selectedText: pt.string
}

export default connect(
  ({ personal, router, token }) => ({
    path: pathOr({}, ['location', 'pathname'])(router),
    contractorGuid: currentContractorGuidSelector(personal),
    contractorName: currentContractorNameSelector(personal),
    userMail: getUserEmail(personal),
    userAuth: isAuthSelector(token)
  }),
  {
    onShowModal: showModal
  }
)(ModalForm)
