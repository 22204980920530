import React from 'react'

import RatingStar from './RatingStar'

type RatingStarFieldProps = {
  input: {
    value: number
    onChange: (rating: number) => void
  }
}

const RatingStarField = ({
  input: { value, onChange },
  ...rest
}: RatingStarFieldProps) => {
  const handleClickStar = () => (rating: number) => {
    onChange(rating)
  }

  return (
    <RatingStar
      {...rest}
      rating={value}
      handleClickStar={handleClickStar()}
      isInteractive
    />
  )
}

export default RatingStarField
