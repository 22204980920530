import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { NoticeAlert } from 'components/Notice'
import { showModal } from 'redux/modules/modal'
import {
  getNotices,
  getNoticeHeader,
  setNoticeStatus,
  getNoticeIsOpen
} from 'redux/modules/notice'

export default connect(
  (state, ownProps) => ({
    items: getNotices({
      type: 'alert',
      page: ownProps.type,
      notice: state.notice
    }),
    type: ownProps.type,
    header: getNoticeHeader({
      type: 'alert',
      page: ownProps.type,
      notice: state.notice
    }),
    isOpen: getNoticeIsOpen({
      type: 'alert',
      page: ownProps.type,
      notice: state.notice
    })
  }),
  dispatch =>
    bindActionCreators(
      {
        setNoticeStatus,
        showModal
      },
      dispatch
    )
)(NoticeAlert)
